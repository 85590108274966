import React, {
  FC, PropsWithChildren, useState
} from 'react';
import {
  useNavigate
} from 'react-router-dom';

import {
  QuestionCard, HeaderRow
} from 'src/entities/Questions';
import {
  ActionButtonProps, ActionButton, Star
} from 'src/widgets';
import {
  Card
} from 'src/shared/components/Card';
import {
  ROUTE,
  formatDateRange,
  getSimpleCardGapValue,
  themeColors,
} from 'src/shared/utils';
import {
  ButtonTitles
} from 'src/shared/api/types';
import {
  useTypedSelector
} from 'src/redux';
import {
  languageSelectors
} from 'src/redux/language';
import {
  Spinner, Tooltip
} from 'src/shared/components';
import {
  useMediaQuery, useToggleFavQuestion
} from 'src/shared/hooks';

import {
  DelegateButton
} from '../../DelegateButton';

import * as Style from './SimpleCard.styled';

interface SimpleCardProps extends PropsWithChildren {
  card: QuestionCard;
  buttonProps?: ActionButtonProps;
}

type StateParams = {
  isOnboarding?: boolean;
  isDeactivated?: boolean;
};

export const SimpleCard: FC<SimpleCardProps> = ({
  children,
  card,
  buttonProps,
}) => {
  const {
    title,
    questionSet,
    startDate,
    endDate,
    id,
    status,
    activeSurveyQuestion,
  } = card;

  const isAvailable = 'onboardingText' in card;

  const onboardingText = isAvailable ? card.onboardingText : null;

  const isFavorite = 'isFavorite' in card && card.isFavorite;
  const isSurvey = card.type === 'SURVEY';

  const canDelegate = 'hasDelegate' in card && card.status !== 'DEACTIVATED';

  const hasDelegate = canDelegate && card.hasDelegate;

  const navigate = useNavigate();

  const handleOnboardClick = () => {
    const stateParams: StateParams = {};

    if (!children) {
      stateParams.isOnboarding = true;
    } else if (buttonProps?.buttonTitle === ButtonTitles.Deactivated) {
      stateParams.isDeactivated = true;
    }

    navigate(
      `/${ROUTE.QUESTION}/${id}`,
      {
        state: stateParams,
      }
    );
  };

  const language = useTypedSelector(languageSelectors.getLanguage);

  const isMobile = useMediaQuery(
    'md',
    'down'
  );

  const gapValue = getSimpleCardGapValue(
    !!children,
    !!onboardingText,
    isMobile,
  );

  const isOnboardQuestion = status === 'AVAILABLE' && isAvailable;

  const [isQuestionFavorite, setIsQuestionFavorite] = useState(isFavorite);

  const toggleFavQuestion = () => setIsQuestionFavorite((prev) => !prev);

  const {
    handleChangeFavQuestion, isChangingFavQuestion
  } = useToggleFavQuestion(
    isQuestionFavorite,
    toggleFavQuestion
  );

  const handleChangeFavorite = () => {
    handleChangeFavQuestion(id);
  };

  const date = formatDateRange({
    start: activeSurveyQuestion?.startDate || startDate,
    end: activeSurveyQuestion?.endDate || endDate,
    language,
  });

  return (
    <Card>
      <Style.ContentContainer
        $gap={gapValue}
        $isOnboardText={!!onboardingText}
        $isOnboarding={!buttonProps?.buttonTitle}
      >
        <HeaderRow
          question={questionSet?.title || ''}
          date={date}
          datePrefix={activeSurveyQuestion?.title}
          isSurvey={isSurvey}
        />

        <Style.CardInfoBlock>
          <Style.Title
            onClick={handleOnboardClick}
            $withChildren={!!children}
            $withOnboardText={!!onboardingText}
            $isMobile={isMobile}
          >
            <Style.Span>{title}</Style.Span>
          </Style.Title>

          {!!onboardingText && (
            <Tooltip
              variant="black"
              opacity={0.8}
              disabled={isMobile}
              message={(
                <Style.OnboardingTextTooltip>
                  {onboardingText}
                </Style.OnboardingTextTooltip>
              )}
            >
              <Style.OnboardingText>{onboardingText}</Style.OnboardingText>
            </Tooltip>
          )}
        </Style.CardInfoBlock>

        {children}

        {!children && <Style.Divider />}

        <Style.BottomRow $withChildren={!!children}>
          {!isSurvey && (
            <Style.BottomButtons>
              {canDelegate && (
                <DelegateButton
                  hasDelegate={hasDelegate}
                  questionId={id}
                />
              )}

              {!isOnboardQuestion && (
                <button
                  type="button"
                  onClick={handleChangeFavorite}
                  disabled={isChangingFavQuestion}
                >
                  {isChangingFavQuestion ? (
                    <Spinner
                      size={20}
                      color={themeColors['button-blue']}
                    />
                  ) : (
                    <Star isFilled={isQuestionFavorite} />
                  )}
                </button>
              )}
            </Style.BottomButtons>
          )}

          <ActionButton
            onClick={handleOnboardClick}
            {...buttonProps}
            className="ml-auto"
          />
        </Style.BottomRow>
      </Style.ContentContainer>
    </Card>
  );
};
