import React from 'react';
import {
  toast
} from 'react-toastify';

import {
  ToastBlockContainer
} from '../ToastBlockContainer';

import 'react-toastify/dist/ReactToastify.min.css';
import '../ToastBlockContainer/style.css';

export const successfulToast = (text?: string) => {
  toast(
    <ToastBlockContainer
      isSuccess
      text={text}
    />,
  );
};
