import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const PaginationContainer = styled.div`
  ${tw`flex gap-2 items-center`}
`;

export const PaginationButton = styled.button`
  ${tw`w-8 h-8 text-dark-gray flex items-center justify-center disabled:text-light-grey`}
`;

export const PaginationPageButton = styled.button<{ $isActive?: boolean }>`
  ${tw`w-8 h-8 rounded font-medium text-dim-gray text-sm hover:(bg-cultured text-gray7) transition`}

  ${({
    $isActive
  }) => $isActive && tw`bg-alice-blue text-button-blue`}
`;
