import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const DelegateContainer = styled.div`
  ${tw`mt-6`}
`;

export const UnderlineContainer = styled.div`
  ${tw`h-1 rounded-full bg-gray-whisper relative mb-6`}
`;

export const UnderlineActive = styled.div<{ $isActive: boolean }>`
  ${tw`h-1 rounded-full w-371px bg-button-blue absolute transition-transform duration-300 ease-in-out`}

  ${({
    $isActive
  }) => ($isActive ? tw`transform translate-x-0` : tw`transform translate-x-full`)}
`;

export const TabContainer = styled.div`
  ${tw`flex items-center mb-2`}
`;

export const TabButton = styled.button<{ $isActive: boolean }>`
  ${tw`flex-1 text-center text-xl`}

  ${({
    $isActive
  }) => ($isActive ? tw`font-bold text-gray7` : tw`font-medium text-dim-gray`)}
`;
