import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const NotificationContainer = styled.div<{ $isNew?: boolean }>`
  ${tw`p-6 border-b border-b-gray94 flex gap-2 flex-col`}
  ${({
    $isNew
  }) => ($isNew ? tw`bg-alice-blue` : tw`bg-white`)}
`;

export const NotificationGroupContainer = styled.div<{
  $wasRead?: boolean;
  $isClickable?: boolean;
  $width?: number;
  $mediumFont?: boolean;
  $leftShift?: number;
}>`
  ${tw`
    p-3 rounded-md border border-gray94 shadow-card-shadow-default
    text-base text-gray7
  `}

  ${({
    $wasRead
  }) => ($wasRead ? tw`bg-alice-blue` : tw`bg-white`)}

  ${({
    $isClickable
  }) => $isClickable && tw`transition cursor-pointer hover:bg-list-blue`}

  ${({
    $mediumFont
  }) => $mediumFont && tw`font-medium`}

  ${({
    $width
  }) => $width && `width: ${$width}px;`}

  ${({
    $leftShift
  }) => $leftShift && `margin-left: ${$leftShift}px;`}
`;
