import React, {
  FC, useState
} from 'react';
import {
  Controller, useForm
} from 'react-hook-form';
import {
  useNavigate, useSearchParams
} from 'react-router-dom';
import {
  zodResolver
} from '@hookform/resolvers/zod';
import {
  useTranslation
} from 'react-i18next';

import {
  AcceptTermsBlock,
  Input,
  LoginSocialsInvite,
} from 'src/shared/components';
import {
  NavigateBackButton
} from 'src/widgets';
import {
  ROUTE, invitedGroupName, invitedUserEmail
} from 'src/shared/utils';
import {
  useGetInputValidation
} from 'src/shared/hooks';

import {
  InputMessages, InputNames
} from '../types';

import {
  SubmitButton
} from './SubmitButton';
import {
  MainContainer, Title, BackButtonContainer
} from './Login.styles';

interface UserNameForm {
  username: string;
}

export const SocialSignUpPage: FC = () => {
  const {
    t
  } = useTranslation();

  const navigate = useNavigate();
  const [termsAccepted, setTermsAccepted] = useState(false);
  // TODO: replace isLoading state with isLoading from the RTK query hook value
  const [isLoading, setIsLoading] = useState(false);

  const [searchParams] = useSearchParams();
  const invitedGroupTitle = searchParams.get(invitedGroupName) || '';
  const invitedEmail = searchParams.get(invitedUserEmail);

  const handleTermsAccept = () => {
    setTermsAccepted((prev) => !prev);
  };

  const {
    signUpSocialSchema
  } = useGetInputValidation();

  const {
    handleSubmit,
    control,
    formState: {
      errors
    },
  } = useForm<UserNameForm>({
    mode: 'all',
    defaultValues: {
      [InputNames.USERNAME]: '',
    },
    resolver: zodResolver(signUpSocialSchema),
  });

  const onSubmit = handleSubmit((val) => {
    // TODO: send new values to server
    setIsLoading(true);

    if (val.username) {
      setIsLoading(false);
      navigate(`/${ROUTE.SOCIAL_ENTER_PASSWORD}`);
    }
  });

  return (
    <>
      <BackButtonContainer>
        <NavigateBackButton />
      </BackButtonContainer>

      <MainContainer>
        <div className="flex flex-col gap-4 md:gap-6 w-full">
          <Title>{t('login.createUsername')}</Title>

          <LoginSocialsInvite
            email={invitedEmail}
            groupName={invitedGroupTitle}
          />
        </div>

        <div className="w-full flex flex-col gap-5">
          <div className="flex flex-col gap-5">
            <Controller
              name={InputNames.USERNAME}
              control={control}
              render={({
                field
              }) => {
                return (
                  <Input
                    label={t('common.username')}
                    value={field.value}
                    onChange={(e) => field.onChange(e)}
                    errorMessage={errors[InputNames.USERNAME]?.message}
                    bottomMessage={InputMessages.BOTTOM_USERNAME_MSG}
                    onBlur={field.onBlur}
                  />
                );
              }}
            />
          </div>
        </div>

        <AcceptTermsBlock
          termsAccepted={termsAccepted}
          handleTermsAccept={handleTermsAccept}
        />

        <SubmitButton
          title={t('common.logIn')}
          onClick={onSubmit}
          isLoading={isLoading}
        />
      </MainContainer>
    </>
  );
};
