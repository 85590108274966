import React, {
  FC
} from 'react';

import * as Style from './Avatar.styles';

interface AvatarProps {
  size?: number;
  src?: string | null;
  userName: string;
  isClickable?: boolean;
  isLogo?: boolean;
  containerClassName?: string;
}

export const Avatar: FC<AvatarProps> = ({
  size = 40,
  src,
  userName,
  isClickable,
  isLogo,
  containerClassName,
}) => {
  if (!src) {
    const formattedName = userName
      .split(' ')
      .map((word) => word[0]?.toUpperCase())
      .join('');

    return (
      <Style.EmptyContainer
        size={size}
        $isClickable={isClickable}
        $isLogo={isLogo}
        className={containerClassName}
      >
        {formattedName}
      </Style.EmptyContainer>
    );
  }

  return (
    <Style.ImageContainer
      src={src}
      alt={`${userName} avatar`}
      size={size}
      $isClickable={isClickable}
      $isLogo={isLogo}
      className={containerClassName}
    />
  );
};
