import React, {
  FC
} from 'react';
import clsx from 'clsx';
import {
  Link, useLocation
} from 'react-router-dom';
import './styles.css';
import {
  useTranslation
} from 'react-i18next';

import {
  ROUTE
} from 'src/shared/utils';
import {
  Tooltip
} from 'src/shared/components';
import {
  useTypedSelector
} from 'src/redux';
import {
  userSelectors
} from 'src/redux/user';
import {
  ArrowUpWideIcon
} from 'src/shared/icons';

import {
  tabs, tabsGeneral
} from './utils';
import {
  SubTabList
} from './SubTabList';
import * as Style from './Navigation.style';

interface NavListProps {
  className?: string;
  isGeneral?: boolean;
}

export const NavList: FC<NavListProps> = ({
  className = '', isGeneral
}) => {
  const location = useLocation();
  const isNewUser = location.pathname.startsWith(`/${ROUTE.CREATE_GROUP}`);

  const {
    role: userRole
  } = useTypedSelector(userSelectors.user);

  const isCurrentPage = (pathname: string) => {
    if (pathname === ROUTE.QUESTION && location.pathname === '/') {
      return true;
    }

    return location.pathname.startsWith(`/${pathname}`);
  };

  const isAdmin = userRole === 'ADMIN';

  const tabsList = isAdmin ? tabs : tabs.filter((tab) => !tab.forAdminsOnly);

  const {
    t
  } = useTranslation();

  return (
    <ul className={clsx(
      'nav-list',
      className
    )}
    >
      {!isGeneral
        ? tabsList.map(({
          value: tabText, url, subTabs
        }) => {
          const isTooltipOff = !isNewUser || url === ROUTE.GROUPS;
          const isPageActive = isCurrentPage(url);

          const subTabsToShow = isAdmin
            ? subTabs
            : subTabs?.filter(({
              forAdminsOnly
            }) => !forAdminsOnly);

          return (
            <li
              className={clsx(
                'nav-item',
                {
                  'nav-item--selected':
                    isPageActive || (isNewUser && url === ROUTE.GROUPS),
                  'text-gray-whisper': isNewUser && url !== ROUTE.GROUPS,
                  'hover:text-gray7 transition-colors':
                    !isNewUser && !isPageActive,
                  group: !!subTabs,
                }
              )}
              key={url}
            >
              <Tooltip
                disabled={isTooltipOff}
                message={(
                  <Style.TooltipInfo>
                    {t('header.createGroupToReceiveAccess')}
                  </Style.TooltipInfo>
                  )}
              >
                {subTabsToShow ? (
                  <Style.Span>
                    {t(`header.${tabText}`)}

                    <ArrowUpWideIcon className="group-hover:rotate-180" />
                  </Style.Span>
                ) : (
                  <Link
                    to={isNewUser ? `/${ROUTE.CREATE_GROUP}` : `/${url}`}
                    className={clsx({
                      'cursor-default': isNewUser,
                    })}
                  >
                    {t(`header.${tabText}`)}
                  </Link>
                )}
              </Tooltip>

              {subTabsToShow && <SubTabList subTabList={subTabsToShow} />}
            </li>
          );
        })
        : tabsGeneral.map(({
          value: tabText, url
        }) => (
          <li
            className={clsx(
              'nav-item',
              {
                'nav-item--selected': isCurrentPage(url),
              }
            )}
            key={url}
          >
            <Link to={`/${url}`}>{t(`header.${tabText}`)}</Link>
          </li>
        ))}
    </ul>
  );
};
