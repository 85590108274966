import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const EditButton = styled.button<{ $isOpen: boolean }>`
  ${tw`h-min flex items-center`}

  ${({
    $isOpen
  }) => $isOpen && tw`text-button-blue`}
`;
