import {
  Control, FieldErrors, UseFormRegister
} from 'react-hook-form';

import {
  QuestionSet
} from 'src/redux/openapi/endpoints/questionSets';
import {
  GroupInvitedPerson
} from 'src/redux/openapi';
import {
  UseNestedSelect
} from 'src/shared/hooks/useNestedSelect';

export interface SelectedItem {
  id: string;
  selectedQuestionsId: string[];
}

export enum FormField {
  GroupName = 'groupName',
  GroupLocation = 'groupLocation',
  GroupQuestions = 'groupQuestions',
}

export interface CreateGroupForm {
  [FormField.GroupName]: string;
  [FormField.GroupLocation]: {
    value: string;
    label: string;
  };
}

interface Step {
  id: string;
  label: string;
}

export type CompletedStatus = { [key: string]: boolean };

export interface UseCreateGroupResult {
  steps: Step[];
  handleNextStep: () => void;
  completedStatus: CompletedStatus;
  isLastStep: boolean;
  isLastStepCompleted: boolean;
  currentStep: number;
  control: Control<CreateGroupForm>;
  register: UseFormRegister<CreateGroupForm>;
  onPeopleInvite: () => Promise<void>;
  activeStepId: string;
  errors: FieldErrors<CreateGroupForm>;
  newGroupId: string;
  invitedPeople: GroupInvitedPerson[];
  questionSets: QuestionSet[];
  isQuestionSetsLoading: boolean;
  refetchInvitedUserData: () => void;
  selectConfig: UseNestedSelect;
}
