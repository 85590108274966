import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  MoreIcon, PersonDeleteIcon
} from 'src/shared/icons';
import {
  MenuItem, PopoverMenu, MenuContainer
} from 'src/shared/components';
import {
  getTimeAgo, themeColors
} from 'src/shared/utils';

import * as Style from './Menu.styles';

interface MenuProps {
  lastVerification: string | null;
  onRemoveClick: () => void;
  isInactive: boolean;
}

export const Menu: FC<MenuProps> = ({
  lastVerification,
  onRemoveClick,
  isInactive,
}) => {
  const lastVerificationValue = lastVerification
    ? getTimeAgo(lastVerification)
    : null;

  const {
    t
  } = useTranslation();

  return (
    <PopoverMenu
      placement="bottom-end"
      renderCustomButton={({
        isOpen, toggleMenu, triggerProps
      }) => (
        <Style.EditButton
          type="button"
          onClick={toggleMenu}
          {...triggerProps}
          $isOpen={isOpen}
        >
          <MoreIcon className="w-6 h-6" />
        </Style.EditButton>
      )}
    >
      <MenuContainer $width={300}>
        {lastVerificationValue && (
          <Style.LastVerification>
            <p>{`${t('verification.lastVerification')}:`}</p>

            <Style.LastVerificationValue>
              {`${lastVerificationValue} ${t('date.ago')}`}
            </Style.LastVerificationValue>
          </Style.LastVerification>
        )}

        {!!isInactive && (
          <MenuItem
            onClick={onRemoveClick}
            $color={themeColors['red-text-error']}
          >
            <PersonDeleteIcon />

            <p>{t('group.removeInactiveUsers')}</p>
          </MenuItem>
        )}
      </MenuContainer>
    </PopoverMenu>
  );
};
