import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const OverviewContainer = styled.div`
  ${tw`flex gap-3 mb-8 pt-2`}
`;

export const OverviewItem = styled.div`
  ${tw`flex gap-4 items-center`}
`;

export const OverviewItemDescription = styled.div`
  ${tw`flex flex-col gap-1`}
`;

export const OverviewItemTitle = styled.div`
  ${tw`text-xl font-bold text-gray7`}
`;

export const OverviewItemSubTitle = styled.div`
  ${tw`text-base font-medium text-dark-gray`}
`;

export const CardContainer = styled.div`
  ${tw`
    p-4 flex flex-col gap-6 items-center rounded-md w-182px
    border border-gray94 shadow-card-shadow-default shrink-0
  `}
`;

export const CardDescription = styled.div`
  ${tw`flex flex-col items-center gap-1`}
`;

export const CardTitle = styled.div`
  ${tw`text-17-26 font-bold text-gray7 capitalize`}
`;

export const CardSubTitle = styled.div`
  ${tw`text-13-21 font-medium text-dark-gray h-42px text-center line-clamp-2`}
`;

export const BadgesStatus = styled.div`
  ${tw`text-xl font-bold text-gray7 mb-4`}
`;

export const BadgesSection = styled.div`
  ${tw`mb-6`}
`;

export const AchievedCards = styled.div`
  ${tw`overflow-y-scroll flex gap-4 flex-nowrap justify-center md:justify-start -mx-6 px-6`}
`;

export const AchievedCardDateWrapper = styled.div`
  ${tw`
    flex gap-1 items-center text-xs leading-5 font-medium text-dim-gray w-full flex-auto
    before:(h-px w-full bg-button-blue) after:(h-px w-full bg-button-blue)
  `}
`;

export const AchievedCardDate = styled.div`
  ${tw`grow shrink-0`}
`;

export const LockedCards = styled.div`
  ${tw`flex gap-4 flex-wrap justify-center md:justify-start`}
`;

export const AchievedProgressWrapper = styled.div`
  ${tw`flex flex-col gap-2 items-center w-full`}
`;
