import {
  createApi
} from '@reduxjs/toolkit/query/react';

import {
  GetUsersCurrentArg,
  GetUsersCurrentResponse,
  GetUserCurrentScoresApiArg,
  GetUserCurrentScoresResponse,
  PostUserCurrentAvatarResponse,
} from 'src/redux/openapi';

import {
  baseQuery
} from '../baseQuery';

export const usersApi = createApi({
  baseQuery,
  reducerPath: 'usersApi',
  endpoints: (builder) => ({
    getCurrentUser: builder.mutation<
    GetUsersCurrentResponse,
    GetUsersCurrentArg
    >({
      query: () => ({
        url: `/api/users/current`,
      }),
    }),
    postApiUsersCurrentAvatar: builder.mutation<
    PostUserCurrentAvatarResponse,
    FormData
    >({
      query: (queryArg) => ({
        url: `/api/users/current/avatar`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    getApiUsersCurrentScores: builder.mutation<
    GetUserCurrentScoresResponse,
    GetUserCurrentScoresApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/current/scores`,
        params: {
          filters: queryArg.filters,
          page: queryArg.page,
          limit: queryArg.limit,
        },
      }),
    }),
  }),
});

export const {
  useGetCurrentUserMutation,
  usePostApiUsersCurrentAvatarMutation,
  useGetApiUsersCurrentScoresMutation,
} = usersApi;
