import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MainContainer = styled.div`
  ${tw`grid grid-cols-[minmax(0, 1fr),max-content] grid-rows-[min-content,1fr] md:gap-x-6 h-full pb-6`}
`;

export const Title = styled.p`
  ${tw`font-bold text-22-30 mb-6`}
`;

export const FiltersContainer = styled.div`
  ${tw`h-10 mb-4 flex gap-2 md:(gap-3 justify-between mb-10 h-11) items-center`}
`;

export const Filters = styled.div`
  ${tw`flex gap-2 md:(gap-3) overflow-auto`}
`;

export const FilterTile = styled.button<{ $isActive?: boolean }>`
  ${tw`py-3 px-4 bg-white text-gray7 text-15-20 font-medium rounded-1000px`}

  ${({
    $isActive
  }) => $isActive && tw`bg-gray7 text-white`}
`;

export const ContentContainer = styled.div<{ $isExpanded: boolean }>`
  ${tw`row-start-2`}

  ${({
    $isExpanded
  }) => !$isExpanded && tw`col-span-2`}
`;

export const SectionContainer = styled.div`
  ${tw`pt-6`}
`;

export const FeedContainer = styled.div<{ $isExpanded: boolean }>`
  ${tw`row-start-1 col-start-2 hidden md:block`}

  ${({
    $isExpanded
  }) => ($isExpanded ? tw`row-span-2` : tw`row-span-1`)}
`;
