import {
  User
} from 'src/redux/openapi';

export enum SelectOptions {
  All = 'all',
  LikeComments = 'likeComments',
  LeftComments = 'leftComments',
  UpdateForecast = 'updateForecast',
  RemoveFromGroup = 'removeFromGroup',
  InviteToGroup = 'inviteToGroup',
  JoinToGroup = 'joinToGroup',
  ReportUsers = 'reportUsers',
  ReportComments = 'reportComments',
}

export type SelectOption = {
  label: string;
  value: SelectOptions;
};

export type CommentLog =
  | {
    type: SelectOptions.LikeComments | SelectOptions.LeftComments;
    date: Date;
    user: Pick<User, 'avatarUrl' | 'name'>;
    questionTitle: string;
    comment: string;
  }
  | {
    type: SelectOptions.UpdateForecast;
    date: Date;
    questionSetTitle: string;
    questionTitle: string;
    statisticPercent: number;
    updatedPercent: number;
    comment: string;
  }
  | {
    type: SelectOptions.RemoveFromGroup;
    date: Date;
    user: Pick<User, 'avatarUrl' | 'name'>;
    expireTime: Date;
    removedUserName: string;
    groupName: string;
  }
  | {
    type: SelectOptions.InviteToGroup;
    date: Date;
    user: Pick<User, 'avatarUrl' | 'name'>;
    invitedAmount: number;
    inviteMessage: string;
  }
  | {
    type: SelectOptions.JoinToGroup;
    date: Date;
    user: Pick<User, 'avatarUrl' | 'name'>;
    groupName: string;
  }
  | {
    type: SelectOptions.ReportUsers;
    date: Date;
    user: Pick<User, 'avatarUrl' | 'name'>;
    reportedUser: Pick<User, 'avatarUrl' | 'name'>;
    reportName: string;
    describedReason: string;
  }
  | {
    type: SelectOptions.ReportComments;
    date: Date;
    user: Pick<User, 'avatarUrl' | 'name'>;
    reportedUser: Pick<User, 'avatarUrl' | 'name'>;
    reportName: string;
    describedReason: string;
    comment: string;
  };
