import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

export const PublicPin: FC = () => {
  const {
    t
  } = useTranslation();

  return (
    <div className="rounded-100px px-1 bg-alice-blue text-center text-xs leading-5 font-medium text-dark-gray">
      {t('common.public')}
    </div>
  );
};
