import React, {
  FC, useEffect, useMemo, useState
} from 'react';
import {
  Trans, useTranslation
} from 'react-i18next';
import {
  useNavigate, useParams
} from 'react-router-dom';

import {
  DeleteOrganizationModal, Filters
} from 'src/features';
import {
  PageLayout, OrganisationSettings
} from 'src/widgets';
import {
  SettingOptions
} from 'src/widgets/OrganisationSettings/OrganisationSettings';
import {
  Button,
  ModalLeave,
  OrganizationSettingsModal,
  Spinner,
} from 'src/shared/components';
import {
  ROUTE
} from 'src/shared/utils';
import {
  useGetApiOrganizationsQuery, UserGroupRole
} from 'src/redux/openapi';

import * as Style from './Organisation.styles';
import {
  CurrentOrganization
} from './CurrentOrganization';

export const Organisation: FC = () => {
  const {
    t
  } = useTranslation();

  const [orgIdToModify, setOrgIdToModify] = useState('');

  const [actionWithOrg, setActionWithOrg] = useState<SettingOptions>(
    '' as SettingOptions,
  );

  const params = useParams();

  const {
    data: organizationsData,
    isLoading,
    refetch,
  } = useGetApiOrganizationsQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const organizations = useMemo(
    () => organizationsData?.data.organizations || [],
    [organizationsData],
  );

  const activeFilterId = params.organizationId
    || organizationsData?.data?.organizations[0]?.id
    || null;

  const currentOrganization = organizations.find(
    (organization) => organization.id === activeFilterId,
  );

  const organizationsForFilter = organizations.map(({
    id, name, role
  }) => ({
    id,
    label: name,
    isAdmin: role === UserGroupRole.SUPERADMIN,
  }));

  const navigate = useNavigate();

  const onFilterClick = (id: string | null) => {
    if (!id) {
      navigate(`/${ROUTE.ORGANISATION}`);
    } else {
      navigate(`/${ROUTE.ORGANISATION}/${id}`);
    }
  };

  const orgToModify = organizations.find(({
    id
  }) => id === orgIdToModify);

  const handleOpenModalConfirm = (id: string, action: SettingOptions) => {
    setOrgIdToModify(id);
    setActionWithOrg(action);
  };

  const handleCloseModal = () => {
    setOrgIdToModify('');
    setActionWithOrg('' as SettingOptions);
  };

  const handleSetUpOrganizationClick = (idPath: string) => {
    navigate(`/${ROUTE.ORGANISATION}/${idPath}/${ROUTE.SETUP_ORGANISATION}`);
  };

  // TODO: delete navigation if all org page will be enabled
  useEffect(
    () => {
      if (organizations.length && !params.organizationId) {
        navigate(
          `/${ROUTE.ORGANISATION}/${organizations[0].id}`,
          {
            replace: true,
          }
        );
      }
    },
    [params.organizationId, organizations]
  );

  return (
    <PageLayout>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {!organizations.length && (
            <Style.EmptyMessageContainer>
              <Style.EmptyMessageTitle>
                {t('organisation.notMemberOfOrganisation')}
              </Style.EmptyMessageTitle>

              <Style.EmptyMessageSubtitle>
                <Trans i18nKey="organisation.submitRequestToPromote" />
              </Style.EmptyMessageSubtitle>

              <Style.EmptyMessageLink to={`/${ROUTE.GROUPS}`}>
                {t('organisation.goBackToGroups')}
              </Style.EmptyMessageLink>
            </Style.EmptyMessageContainer>
          )}

          {!!organizations.length && (
            <>
              {currentOrganization && currentOrganization.isSetUp && (
                <Filters
                  items={organizationsForFilter}
                  onFilterClick={onFilterClick}
                  activeFilterId={activeFilterId}
                  renderMenuContent={(el) => {
                    if (!el.id) {
                      return null;
                    }

                    return (
                      <OrganisationSettings
                        isAdmin={el.isAdmin}
                        openModalConfirm={(action: SettingOptions) => {
                          handleOpenModalConfirm(
                            el.id,
                            action
                          );
                        }}
                      />
                    );
                  }}
                />
              )}

              {currentOrganization && !currentOrganization.isSetUp && (
                <Style.EmptyMessageContainer>
                  <Style.CreateMessageContainer>
                    <Style.EmptyMessageTitle>
                      {t('organisation.createYourOrganisation')}
                    </Style.EmptyMessageTitle>

                    <Style.EmptyMessageSubtitle>
                      <Trans i18nKey="organisation.organizationApproved" />
                    </Style.EmptyMessageSubtitle>

                    <Button
                      variant="big-blue"
                      className="font-bold text-xl w-max m-auto"
                      onClick={() => handleSetUpOrganizationClick(currentOrganization.id)}
                    >
                      {t('organisation.setUpOrganisation')}
                    </Button>
                  </Style.CreateMessageContainer>
                </Style.EmptyMessageContainer>
              )}

              {currentOrganization && currentOrganization.isSetUp && (
                <CurrentOrganization organization={currentOrganization} />
              )}

              <ModalLeave
                isOpen={actionWithOrg === SettingOptions.Leave}
                closeModal={handleCloseModal}
                organization={orgToModify}
                onLeave={refetch}
              />

              <DeleteOrganizationModal
                isOpen={actionWithOrg === SettingOptions.Delete}
                closeModal={handleCloseModal}
                id={orgToModify?.id}
                onSuccessDelete={refetch}
              />

              {actionWithOrg === SettingOptions.Settings && orgToModify && (
                <OrganizationSettingsModal
                  handleCloseModal={handleCloseModal}
                  organization={orgToModify}
                />
              )}
            </>
          )}
        </>
      )}
    </PageLayout>
  );
};
