import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import './styles.css';
import {
  useLocation
} from 'react-router-dom';

import {
  Tab
} from './utils';
import * as Style from './Navigation.style';

interface SubTabListProps {
  subTabList: Tab[];
}

export const SubTabList: FC<SubTabListProps> = ({
  subTabList
}) => {
  const {
    t
  } = useTranslation();

  const location = useLocation();

  return (
    <Style.SubTabs>
      {subTabList.map(({
        value: subTabText, url: subTabUrl, description
      }) => {
        const isExternalSubLink = subTabUrl.startsWith('http');
        const linkPath = isExternalSubLink ? subTabUrl : `/${subTabUrl}`;
        const linkTarget = isExternalSubLink ? '_blank' : undefined;
        const isCurrentPage = location.pathname.startsWith(`/${subTabUrl}`);

        return (
          <li key={subTabUrl}>
            <Style.SubTabLink
              to={linkPath}
              target={linkTarget}
            >
              <Style.SubTabText $isActive={isCurrentPage}>
                {t(`header.${subTabText}`)}
              </Style.SubTabText>

              {description && (
                <Style.SubTabInfo>
                  {t(`header.${description}`)}
                </Style.SubTabInfo>
              )}
            </Style.SubTabLink>
          </li>
        );
      })}
    </Style.SubTabs>
  );
};
