import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const ButtonsContainer = styled.div`
  ${tw`border-t border-gray-whisper mt-3 p-4 flex gap-1.5`}
`;

export const ResetButton = styled.button`
  ${tw`
    grow p-2 flex items-center justify-center rounded-100px border border-gray90
    text-15-20 font-bold text-dark-gray transition
    hover:(bg-gray90 text-white)
  `}
`;

export const ApplyButton = styled.button`
  ${tw`
    grow p-2 flex items-center justify-center rounded-100px border border-button-blue
    text-15-20 font-bold text-white bg-button-blue transition
    hover:not-disabled:(bg-white text-button-blue)
  `}
`;
