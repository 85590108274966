import React, {
  FC, useState
} from 'react';
import {
  Trans
} from 'react-i18next';

import {
  getTimeAgo,
  showWarningFromServer,
  themeColors,
} from 'src/shared/utils';
import {
  CloseIcon
} from 'src/shared/icons';
import {
  Spinner
} from 'src/shared/components';
import {
  GroupInvitedPerson
} from 'src/redux/openapi';

import * as Style from './GroupRoute.styles';

interface InvitedEmailBlockProps {
  person: GroupInvitedPerson;
  index: number;
  removeUserInvitation: (userId: string) => Promise<void>;
}

export const InvitedEmailBlock: FC<InvitedEmailBlockProps> = ({
  person,
  index,
  removeUserInvitation,
}) => {
  const [isRemoving, setIsRemoving] = useState(false);

  const timeAgo = getTimeAgo(Date.parse(person.sentAt));

  const onRemove = async () => {
    if (!person.id) {
      return;
    }

    setIsRemoving(true);

    try {
      await removeUserInvitation(person.id);
    } catch (error) {
      showWarningFromServer(error);
    } finally {
      setIsRemoving(false);
    }
  };

  return (
    <Style.InvitedEmailsBlock>
      <Style.InvitedEmailsList>
        <Style.InviteEmail>{`${index}.`}</Style.InviteEmail>

        <Style.InvitedEmailContainer>
          <Style.InviteEmail $isSmall>
            <Trans
              i18nKey="group.sentTimeAgo"
              values={{
                timeAgo,
              }}
            />
          </Style.InviteEmail>

          <Style.InviteEmail $isTitle>{person.email}</Style.InviteEmail>
        </Style.InvitedEmailContainer>
      </Style.InvitedEmailsList>

      {isRemoving ? (
        <Spinner
          color={themeColors['dim-gray']}
          size={24}
          centered={false}
        />
      ) : (
        <button
          type="button"
          onClick={onRemove}
        >
          <CloseIcon className="text-dim-gray" />
        </button>
      )}
    </Style.InvitedEmailsBlock>
  );
};
