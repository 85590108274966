import {
  createSlice
} from '@reduxjs/toolkit';

import {
  arqApiUsers
} from 'src/redux/openapi';
import {
  LocalizationService
} from 'src/shared/services';

const initialState: string = 'en';

export interface LanguageReducer {
  language: string;
}

export const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setEnglishLanguage: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      arqApiUsers.endpoints.patchApiUsersCurrentLanguage.matchFulfilled,
      (_, {
        payload
      }) => {
        LocalizationService.onChangeLanguage(payload.data.languageCode);

        return payload.data.languageCode;
      },
    );

    builder.addMatcher(
      arqApiUsers.endpoints.getApiUsersCurrentLanguage.matchFulfilled,
      (_, {
        payload
      }) => {
        LocalizationService.onChangeLanguage(payload.data.languageCode);

        return payload.data.languageCode;
      },
    );
  },
});

export const {
  setEnglishLanguage
} = languageSlice.actions;

export const languageReducer = languageSlice.reducer;
