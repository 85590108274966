import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MainContainer = styled.div`
  ${tw`md:flex-1 bg-white relative mx-auto`}
`;

export const LogoContainer = styled.div`
  ${tw`absolute top-6 left-8`}
`;

export const ChartImageContainer = styled.div<{ $backgroundImage?: string }>`
  ${tw`flex md:flex-1 h-250px md:h-screen w-250px md:w-full bg-no-repeat bg-cover bg-white`}

  background-image: url(${({
    $backgroundImage
  }) => $backgroundImage});
`;

export const ImageContainer = styled.img`
  ${tw`object-contain w-full h-full`}
`;
