import {
  createSlice
} from '@reduxjs/toolkit';
import type {
  PayloadAction
} from '@reduxjs/toolkit';

import {
  arqApiAuth
} from 'src/redux/openapi';

const initialState = {
  emailToVerify: '',
};

export interface VerificationReducer {
  verification: {
    emailToVerify: string;
  };
}

export const verificationSlice = createSlice({
  name: 'verification',
  initialState,
  reducers: {
    setEmailToVerify: (state, action: PayloadAction<string>) => {
      state.emailToVerify = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      arqApiAuth.endpoints.postApiAuthSignIn.matchFulfilled,
      (state) => {
        state.emailToVerify = '';
      },
    );
  },
});

export const {
  setEmailToVerify
} = verificationSlice.actions;

export const verificationReducer = verificationSlice.reducer;
