import React, {
  FC, ReactNode
} from 'react';
import clsx from 'clsx';

import {
  AlertTriangleIcon,
  CheckmarkCircleEmptyIcon,
  CloseIcon,
  InfoIcon,
} from 'src/shared/icons';

import 'react-toastify/dist/ReactToastify.min.css';
import './style.css';

interface ToastBlockContainerProps {
  isSuccess?: boolean;
  text?: ReactNode;
  isInfo?: boolean;
  isError?: boolean;
  isWarning?: boolean;
  fullWidth?: boolean;
  filledBackground?: boolean;
  onCustomOnClose?: () => void;
  containerClassName?: string;
  showIndicator?: boolean;
}

export const ToastBlockContainer: FC<ToastBlockContainerProps> = ({
  isSuccess,
  text,
  isInfo,
  isError,
  isWarning,
  fullWidth = false,
  filledBackground,
  onCustomOnClose,
  containerClassName,
  showIndicator = true,
}) => {
  return (
    <div
      className={clsx(
        'Toastify__toast mb-0 flex gap-3 shrink-0',
        {
          'max-w-555px': !fullWidth,
          'w-full': fullWidth,
          'bg-alice-blue': isInfo && filledBackground,
        },
        containerClassName,
      )}
    >
      {showIndicator && (
        <div
          className={clsx(
            'w-2',
            {
              'bg-orange-alert': isWarning,
              'bg-forest-green': isSuccess,
              'bg-accent80': isInfo,
              'bg-red-text-error': isError,
            }
          )}
        />
      )}

      <div className="flex flex-1 items-center py-3 pr-3 gap-3">
        {isWarning && <AlertTriangleIcon className="text-orange-alert" />}

        {isSuccess && (
          <CheckmarkCircleEmptyIcon className="text-forest-green" />
        )}

        {isInfo && <InfoIcon className="text-accent80" />}

        {isError && <InfoIcon className="text-red-text-error rotate-180" />}

        <div className="flex-1 text-dark-gray text-base font-medium font-['Urbanist']">
          {text || 'toast text to show'}
        </div>
      </div>

      {onCustomOnClose && (
        <button
          type="button"
          onClick={onCustomOnClose}
          className="p-3 text-dark-gray h-full"
        >
          <CloseIcon />
        </button>
      )}
    </div>
  );
};
