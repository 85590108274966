import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  ArrowRightIcon,
  KeyAdminIcon,
  MessageIcon,
  PeopleIcon,
  TrendingUpIcon,
} from 'src/shared/icons';
import {
  numberWithPeriods
} from 'src/shared/utils/functions';
import {
  GroupCardQuestionBlock
} from 'src/entities/GroupsRoute';
import {
  Tooltip
} from 'src/shared/components';
import {
  Group, UserGroupRole
} from 'src/redux/openapi';
import {
  formatDateRange
} from 'src/shared/utils';
import {
  PublicPin
} from 'src/widgets';

import {
  ActivityTile
} from '../Questions';

import * as Style from './GroupRoute.styles';
import {
  getGroupUserRole
} from './utils';

interface GroupCardContainerProps {
  group: Group;
  setFilter: (id: string | null) => void;
}

export const GroupCardContainer: FC<GroupCardContainerProps> = ({
  group,
  setFilter,
}) => {
  const {
    location,
    name,
    groupRole,
    memberCount: numberOfGroupMembers,
    commentCount: groupCommentsPerDay,
    forecastCount: activeGroupMembersPerDay,
    id,
    questionSets,
    organizationRole,
    userRole,
    isPublic,
  } = group;

  const role = getGroupUserRole({
    groupRole,
    organizationRole,
    userRole,
  });

  const isAdmin = role === UserGroupRole.ADMIN;
  const isSuperAdmin = role === UserGroupRole.SUPERADMIN;

  const visibleSets = (questionSets?.length ?? 0) < 2 ? questionSets : questionSets?.slice(
    0,
    2
  );

  const {
    t, i18n
  } = useTranslation();

  return (
    <Style.GroupContainer>
      <Style.GroupHeaderContainer>
        <Style.GroupHeaderLeftSide>
          {(isAdmin || isSuperAdmin) && (
            <Style.GroupIsAdminContainer>
              <Tooltip
                variant="black"
                opacity={0.8}
                message={(
                  <p className="text-center max-w-128px">
                    {t('group.youAreAdminInGroup')}
                  </p>
                )}
              >
                <KeyAdminIcon />
              </Tooltip>
            </Style.GroupIsAdminContainer>
          )}

          <div>
            <Style.GroupLocation>{location}</Style.GroupLocation>

            <Style.GroupTile>{name}</Style.GroupTile>
          </div>
        </Style.GroupHeaderLeftSide>

        <Style.GroupHeaderRightSide>
          {isPublic && (
            <Style.PinContainer>
              <PublicPin />
            </Style.PinContainer>
          )}

          <Style.GroupDetailsButton onClick={() => setFilter(id)}>
            {t('buttons.details')}

            <ArrowRightIcon />
          </Style.GroupDetailsButton>
        </Style.GroupHeaderRightSide>
      </Style.GroupHeaderContainer>

      <Style.CardStatisticsRow>
        <Style.CardStatistics>
          <ActivityTile
            icon={<PeopleIcon />}
            amount={numberWithPeriods(numberOfGroupMembers)}
          />
        </Style.CardStatistics>

        <Style.CardStatisticsRowRight>
          <Style.CardStatistics>
            <ActivityTile
              icon={<MessageIcon className="text-dim-gray" />}
              amount={numberWithPeriods(groupCommentsPerDay)}
              timeRange="upToday"
            />
          </Style.CardStatistics>

          <Style.CardStatistics>
            <ActivityTile
              icon={<TrendingUpIcon className="text-dim-gray" />}
              amount={`${activeGroupMembersPerDay}`}
              timeRange="upToday"
            />
          </Style.CardStatistics>
        </Style.CardStatisticsRowRight>
      </Style.CardStatisticsRow>

      <Style.VisibleSetContainer>
        {visibleSets?.map(
          ({
            id: questionId, title: questionTitle, startDate, endDate
          }) => {
            const dates = formatDateRange({
              start: startDate,
              end: endDate,
              language: i18n.language,
            });

            return (
              <GroupCardQuestionBlock
                key={questionId}
                data={{
                  dates,
                  questionTitle,
                }}
              />
            );
          },
        )}
      </Style.VisibleSetContainer>
    </Style.GroupContainer>
  );
};
