import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const ModalTitle = styled.div`
  ${tw`text-28-36 font-bold text-gray7 text-center`}
`;

export const ModalText = styled.div`
  ${tw`text-base font-medium text-dim-gray text-left`}
`;

export const ModalSpan = styled.span`
  ${tw`text-xl text-white font-bold`}
`;

export const ContentContainer = styled.div`
  ${tw`flex flex-col gap-6`}
`;
