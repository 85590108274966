import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MainContainer = styled.div`
  ${tw`
    flex justify-between items-center
    flex-1 px-4 py-3px border rounded-md border-gray90
    text-17-26 text-gray7 font-medium h-11
  `}
`;

export const Placeholder = styled.p`
  ${tw`text-dim-gray`}
`;
