import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const Filters = styled.div<{
  $canShrink?: boolean;
  $isMobile: boolean;
  $maxWidth: number;
}>`
  ${tw`flex items-center gap-2 md:gap-3 pr-2 md:pr-0 overflow-x-auto`}

  ${({
    $canShrink
  }) => ($canShrink ? tw`shrink` : tw`shrink-0`)}

  ${({
    $isMobile, $maxWidth
  }) => $isMobile
    && $maxWidth
    && `
    max-width: ${$maxWidth}px;
  `}

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const FilterTile = styled.a<{
  $isActive?: boolean;
  $withMenu?: boolean;
  $disabled?: boolean;
}>`
  ${tw`
    flex shrink-0 gap-2 items-center py-2.5 md:py-3 px-4 bg-white 
    text-gray7 text-15-20 font-medium 
    rounded-1000px transition-all ease-in duration-300 
    border border-gray90 cursor-pointer relative
  `}

  ${({
    $isActive
  }) => $isActive && tw`bg-gray7 text-white border-gray7`}

  ${({
    $disabled
  }) => $disabled && tw`text-grey-dark-40 border-gray94 cursor-default`}
  
  ${({
    $withMenu
  }) => $withMenu && tw`pr-0`}
`;

export const FilterAmount = styled.div<{ $isActive: boolean }>`
  ${tw`rounded-100px bg-alice-blue font-medium text-15-20 px-1 min-w-28px text-center`}

  ${({
    $isActive
  }) => ($isActive ? tw`text-gray7` : tw`text-dim-gray`)}
`;

export const FilterAmountContainer = styled.div`
  ${tw`bg-white w-max rounded-100px`}
`;
