import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MainContainer = styled.div`
  ${tw`p-2 flex flex-col gap-1`}
`;

export const Divider = styled.div`
  ${tw`h-px bg-gray-whisper w-full`}
`;
