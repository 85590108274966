import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const ContentContainer = styled.div`
  ${tw`flex flex-col gap-6 min-h-50px`}
`;

export const GroupInfoContainer = styled.div`
  ${tw`flex items-center justify-center gap-2`}
`;

export const GroupIcon = styled.div`
  ${tw`w-14 h-14 bg-alice-blue flex items-center justify-center rounded-full`}
`;

export const GroupInfo = styled.div`
  ${tw`flex flex-col gap-1 grow text-base`}
`;

export const GroupInfoHeader = styled.div`
  ${tw`flex justify-between font-semibold text-gray7`}
`;

export const GroupAdmin = styled.div`
  ${tw`text-13-21 font-medium text-dark-gray`}
`;

export const MemberSpan = styled.span`
  ${tw`font-medium text-dim-gray`}
`;

export const FieldTitle = styled.div`
  ${tw`font-medium text-grey-dark-40 text-15-20 mb-2`}
`;

export const FieldContainer = styled.div`
  ${tw`p-2 border border-input-blue rounded font-medium text-17-26 text-gray7`}
`;

export const Description = styled.p<{ $isEmpty?: boolean }>`
  ${tw`p-2 text-17-26 text-gray7`}

  ${({
    $isEmpty
  }) => ($isEmpty ? tw`text-light-grey` : tw`text-gray7`)}
`;

export const ButtonsContainer = styled.div`
  ${tw`flex justify-between`}
`;

export const ActionButtons = styled.div`
  ${tw`flex gap-4`}
`;

export const ButtonsText = styled.p`
  ${tw`font-bold text-xl`}
`;
