import React, {
  FC, FormEvent
} from 'react';
import {
  zodResolver
} from '@hookform/resolvers/zod';
import {
  Controller, useForm
} from 'react-hook-form';
import {
  useNavigate
} from 'react-router-dom';
import {
  useTranslation
} from 'react-i18next';

import {
  Input, InputType, successfulToast
} from 'src/shared/components';
import {
  NavigateBackButton
} from 'src/widgets';
import {
  ROUTE, showWarningFromServer
} from 'src/shared/utils';
import {
  useCreatePasswordMutation
} from 'src/shared/api/auth/authApi';
import {
  useGetInputValidation
} from 'src/shared/hooks';

import {
  InputNames
} from '../types';

import {
  SubmitButton
} from './SubmitButton';
import {
  MainContainer, Title, BackButtonContainer
} from './Login.styles';

interface PasswordForm {
  [InputNames.PASSWORD]: string;
  [InputNames.CONFIRM_PASS]: string;
}

const formId = 'CreatePasswordForm';

export const CreatePasswordPage: FC = () => {
  const {
    t
  } = useTranslation();

  const {
    createPasswordSchema
  } = useGetInputValidation();

  const navigate = useNavigate();

  const [createPassword, {
    isLoading
  }] = useCreatePasswordMutation();

  const {
    handleSubmit,
    control,
    formState: {
      errors
    },
  } = useForm<PasswordForm>({
    mode: 'onTouched',
    defaultValues: {
      [InputNames.PASSWORD]: '',
      [InputNames.CONFIRM_PASS]: '',
    },
    resolver: zodResolver(createPasswordSchema),
  });

  const onSubmit = handleSubmit(async (val) => {
    if (val.password && val.confirmPass) {
      try {
        const response = await createPassword({
          password: val.password,
        }).unwrap();

        successfulToast(response.message);

        navigate(`/${ROUTE.ENTER_PASSWORD}`);
      } catch (error) {
        showWarningFromServer(error);
      }
    }
  });

  const onFormSubmit = (event: FormEvent) => {
    event.preventDefault();
    onSubmit();
  };

  return (
    <>
      <BackButtonContainer>
        <NavigateBackButton />
      </BackButtonContainer>

      <MainContainer>
        <Title>{t('login.createPassword')}</Title>

        <form
          autoComplete="off"
          className="w-full"
          id={formId}
          onSubmit={onFormSubmit}
        >
          <div className="w-full flex flex-col gap-5">
            <div className="flex flex-col gap-5">
              <Controller
                name={InputNames.PASSWORD}
                control={control}
                render={({
                  field
                }) => {
                  return (
                    <Input
                      label={t('login.createNewPassword')}
                      isRequired
                      value={field.value}
                      type={InputType.PASSWORD}
                      onChange={(e) => field.onChange(e)}
                      errorMessage={errors[InputNames.PASSWORD]?.message}
                      onBlur={field.onBlur}
                      placeholder={t('login.passwordPlaceholder')}
                    />
                  );
                }}
              />

              <Controller
                name={InputNames.CONFIRM_PASS}
                control={control}
                render={({
                  field
                }) => {
                  return (
                    <Input
                      label={t('login.repeatPassword')}
                      isRequired
                      value={field.value}
                      type={InputType.PASSWORD}
                      onChange={(e) => field.onChange(e)}
                      errorMessage={errors[InputNames.CONFIRM_PASS]?.message}
                      onBlur={field.onBlur}
                    />
                  );
                }}
              />
            </div>
          </div>
        </form>

        <SubmitButton
          title={t('buttons.updatePassword')}
          isLoading={isLoading}
          onClick={onSubmit}
          formId={formId}
        />
      </MainContainer>
    </>
  );
};
