import clsx from 'clsx';
import {
  t
} from 'i18next';
import React, {
  FC, useEffect, useState
} from 'react';

import {
  GroupStats,
  useGetNotificationGroupsStatisticsQuery,
} from 'src/redux/openapi';
import {
  Button, Spinner
} from 'src/shared/components';
import {
  showWarningFromServer
} from 'src/shared/utils';

interface ShowDetailsButtonProps {
  withShift?: boolean;
  notificationId: string;
  onDetailsLoaded: (groupStats: GroupStats) => void;
}

export const ShowDetailsButton: FC<ShowDetailsButtonProps> = ({
  withShift,
  notificationId,
  onDetailsLoaded,
}) => {
  const [showStatistics, setShowStatistics] = useState(false);

  const {
    data: statisticsData,
    isLoading: isStatisticsLoading,
    error,
  } = useGetNotificationGroupsStatisticsQuery(
    {
      id: notificationId,
    },
    {
      skip: !showStatistics,
    },
  );

  useEffect(
    () => {
      if (statisticsData) {
        onDetailsLoaded(statisticsData.data.group);
      }
    },
    [statisticsData]
  );

  useEffect(
    () => {
      if (error) {
        showWarningFromServer(error);
        setShowStatistics(false);
      }
    },
    [error]
  );

  const handleShowDetails = () => setShowStatistics(true);

  return (
    <Button
      variant="big-grey-bordered"
      className={clsx(
        'w-max text-15-20 font-bold !px-4 !py-2 !h-9',
        {
          'ml-68px': withShift,
        }
      )}
      onClick={handleShowDetails}
    >
      <p className="shrink-0">{t('buttons.showDetails')}</p>

      {isStatisticsLoading && (
        <Spinner
          size={24}
          color="inherit"
        />
      )}
    </Button>
  );
};
