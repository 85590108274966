import {
  emptySplitApi as api
} from '../emptyApi';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiOrganizations: build.query<
    GetApiOrganizationsApiResponse,
    GetApiOrganizationsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
        },
      }),
      providesTags: ['Organization', 'Invites'],
    }),
    getApiOrganizationsByIdFeed: build.query<
    GetApiOrganizationsByIdFeedApiResponse,
    GetApiOrganizationsByIdFeedApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}/feed`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
          filters: queryArg.filters,
        },
      }),
    }),
    patchApiOrganizationsById: build.mutation<
    PatchApiOrganizationsByIdApiResponse,
    PatchApiOrganizationsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateOrganizationBodySchema,
      }),
      invalidatesTags: ['Organization'],
    }),
    getApiOrganizationsById: build.query<
    GetApiOrganizationsByIdApiResponse,
    GetApiOrganizationsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}`,
      }),
    }),
    deleteApiOrganizationsById: build.mutation<
    DeleteApiOrganizationsByIdApiResponse,
    DeleteApiOrganizationsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    postApiOrganizationsByIdLogo: build.mutation<
    PostApiOrganizationsByIdLogoApiResponse,
    PostApiOrganizationsByIdLogoApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}/logo`,
        method: 'POST',
        headers: {
          'content-length': queryArg['content-length'].toString(),
        },
      }),
    }),
    getApiOrganizationsByIdThemes: build.query<
    GetApiOrganizationsByIdThemesApiResponse,
    GetApiOrganizationsByIdThemesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}/themes`,
      }),
    }),
    deleteApiOrganizationsByIdThemesAndThemeId: build.mutation<
    DeleteApiOrganizationsByIdThemesAndThemeIdApiResponse,
    DeleteApiOrganizationsByIdThemesAndThemeIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}/themes/${queryArg.themeId}`,
        method: 'DELETE',
      }),
    }),
    putApiOrganizationsByIdThemesActive: build.mutation<
    PutApiOrganizationsByIdThemesActiveApiResponse,
    PutApiOrganizationsByIdThemesActiveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}/themes/active`,
        method: 'PUT',
        body: queryArg.updateOrganizationThemeBodySchema,
      }),
      invalidatesTags: ['Theme'],
    }),
    postApiOrganizationsByIdThemesActive: build.mutation<
    PostApiOrganizationsByIdThemesActiveApiResponse,
    PostApiOrganizationsByIdThemesActiveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}/themes/active`,
        method: 'POST',
        body: queryArg.createOrganizationThemeBodySchema,
      }),
      invalidatesTags: ['Theme'],
    }),
    postApiOrganizationsByIdGroups: build.mutation<
    PostApiOrganizationsByIdGroupsApiResponse,
    PostApiOrganizationsByIdGroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}/groups`,
        method: 'POST',
        body: queryArg.createOrganizationGroupBodySchema,
      }),
    }),
    getApiOrganizationsByIdGroups: build.query<
    GetApiOrganizationsByIdGroupsApiResponse,
    GetApiOrganizationsByIdGroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}/groups`,
        params: {
          query: queryArg.query,
          page: queryArg.page,
          limit: queryArg.limit,
        },
      }),
    }),
    postApiOrganizationsByIdGroupsFromGroup: build.mutation<
    PostApiOrganizationsByIdGroupsFromGroupApiResponse,
    PostApiOrganizationsByIdGroupsFromGroupApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}/groups/from-group`,
        method: 'POST',
        body: queryArg.createOrganizationGroupFromGroupBodySchema,
      }),
    }),
    postApiOrganizationsByIdGroupsUsersMerge: build.mutation<
    PostApiOrganizationsByIdGroupsUsersMergeApiResponse,
    PostApiOrganizationsByIdGroupsUsersMergeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}/groups/users/merge`,
        method: 'POST',
        body: queryArg.mergeGroupsIntoGroupBodySchema,
      }),
    }),
    getApiOrganizationsByIdGroupsMerge: build.query<
    GetApiOrganizationsByIdGroupsMergeApiResponse,
    GetApiOrganizationsByIdGroupsMergeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}/groups/merge`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
        },
      }),
    }),
    putApiOrganizationsByIdGroupsAndGroupIdStatuses: build.mutation<
    PutApiOrganizationsByIdGroupsAndGroupIdStatusesApiResponse,
    PutApiOrganizationsByIdGroupsAndGroupIdStatusesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}/groups/${queryArg.groupId}/statuses`,
        method: 'PUT',
        body: queryArg.updateOrganizationGroupStatusBodySchema,
      }),
    }),
    getApiOrganizationsByIdGroupsQuestionsActive: build.query<
    GetApiOrganizationsByIdGroupsQuestionsActiveApiResponse,
    GetApiOrganizationsByIdGroupsQuestionsActiveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}/groups/questions/active`,
        params: {
          query: queryArg.query,
          page: queryArg.page,
          limit: queryArg.limit,
        },
      }),
    }),
    getApiOrganizationsByIdGroupsInvitations: build.query<
    GetApiOrganizationsByIdGroupsInvitationsApiResponse,
    GetApiOrganizationsByIdGroupsInvitationsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}/groups/invitations`,
        params: {
          query: queryArg.query,
          page: queryArg.page,
          limit: queryArg.limit,
          lastCreatedAt: queryArg.lastCreatedAt,
          lastId: queryArg.lastId,
          status: queryArg.status,
        },
      }),
      providesTags: ['Invites'],
    }),
    getApiOrganizationsByIdGroupsInvitationsUsers: build.query<
    GetApiOrganizationsByIdGroupsInvitationsUsersApiResponse,
    GetApiOrganizationsByIdGroupsInvitationsUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}/groups/invitations/users`,
      }),
      providesTags: ['Template'],
    }),
    postApiOrganizationsByIdGroupsInvitationsUsers: build.mutation<
    PostApiOrganizationsByIdGroupsInvitationsUsersApiResponse,
    PostApiOrganizationsByIdGroupsInvitationsUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}/groups/invitations/users`,
        method: 'POST',
        body: queryArg.updateOrganizationGroupsInvitationsBodySchema,
      }),
    }),
    getApiOrganizationsByIdGroupsUsers: build.query<
    GetApiOrganizationsByIdGroupsUsersApiResponse,
    GetApiOrganizationsByIdGroupsUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}/groups/users`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
        },
      }),
      providesTags: ['Template'],
    }),
    postApiOrganizationsByIdGroupsUsers: build.mutation<
    PostApiOrganizationsByIdGroupsUsersApiResponse,
    PostApiOrganizationsByIdGroupsUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}/groups/users`,
        method: 'POST',
        body: queryArg.createGroupsUsersBodySchema,
      }),
    }),
    deleteApiOrganizationsByIdGroupsUsersAndUserId: build.mutation<
    DeleteApiOrganizationsByIdGroupsUsersAndUserIdApiResponse,
    DeleteApiOrganizationsByIdGroupsUsersAndUserIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}/groups/users/${queryArg.userId}`,
        method: 'DELETE',
        params: {
          reason: queryArg.reason,
        },
      }),
    }),
    getApiOrganizationsByIdUsers: build.query<
    GetApiOrganizationsByIdUsersApiResponse,
    GetApiOrganizationsByIdUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}/users`,
        params: {
          query: queryArg.query,
          page: queryArg.page,
          limit: queryArg.limit,
        },
      }),
      providesTags: ['Members'],
    }),
    deleteApiOrganizationsByIdUsers: build.mutation<
    DeleteApiOrganizationsByIdUsersApiResponse,
    DeleteApiOrganizationsByIdUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}/users`,
        method: 'DELETE',
        params: {
          userId: queryArg.userId,
        },
      }),
    }),
    getApiOrganizationsByIdUsersAdmins: build.query<
    GetApiOrganizationsByIdUsersAdminsApiResponse,
    GetApiOrganizationsByIdUsersAdminsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}/users/admins`,
        params: {
          query: queryArg.query,
        },
      }),
    }),
    postApiOrganizationsByIdUsersMessages: build.mutation<
    PostApiOrganizationsByIdUsersMessagesApiResponse,
    PostApiOrganizationsByIdUsersMessagesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}/users/messages`,
        method: 'POST',
        body: queryArg.sendOrganizationUsersMessageBodySchema,
      }),
    }),
    getApiOrganizationsByIdGroupsUsersInvitedCount: build.query<
    GetApiOrganizationsByIdGroupsUsersInvitedCountApiResponse,
    GetApiOrganizationsByIdGroupsUsersInvitedCountApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}/groups/users/invited/count`,
      }),
    }),
    getApiOrganizationsByIdGroupsUsersDownload: build.mutation<
    GetApiOrganizationsByIdGroupsUsersDownloadApiResponse,
    GetApiOrganizationsByIdGroupsUsersDownloadApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}/groups/users/download`,
        method: 'GET',
        params: {
          type: queryArg.type,
        },
        responseHandler: (response) => {
          if (response.ok) {
            return response.blob();
          }

          throw new Error();
        },
      }),
    }),
    getApiOrganizationsByIdUsersInactiveCount: build.query<
    GetApiOrganizationsByIdUsersInactiveCountApiResponse,
    GetApiOrganizationsByIdUsersInactiveCountApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}/users/inactive/count`,
        params: {
          oldInactiveOnly: queryArg.oldInactiveOnly,
        },
      }),
    }),
    deleteApiOrganizationsByIdUsersInactive: build.mutation<
    DeleteApiOrganizationsByIdUsersInactiveApiResponse,
    DeleteApiOrganizationsByIdUsersInactiveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}/users/inactive`,
        method: 'DELETE',
        params: {
          oldInactiveOnly: queryArg.oldInactiveOnly,
        },
      }),
      invalidatesTags: ['Members'],
    }),
    postApiOrganizationsByIdUsersInvitesFilesTemplate: build.mutation<
    PostApiOrganizationsByIdUsersInvitesFilesTemplateApiResponse,
    PostApiOrganizationsByIdUsersInvitesFilesTemplateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}/users/invites/files/template`,
        method: 'POST',
      }),
    }),
    patchApiOrganizationsByIdUsersRolesPromote: build.mutation<
    PatchApiOrganizationsByIdUsersRolesPromoteApiResponse,
    PatchApiOrganizationsByIdUsersRolesPromoteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}/users/roles/promote`,
        method: 'PATCH',
        body: queryArg.promoteGroupUserBodySchema,
      }),
    }),
    patchApiOrganizationsByIdUsersRolesDemote: build.mutation<
    PatchApiOrganizationsByIdUsersRolesDemoteApiResponse,
    PatchApiOrganizationsByIdUsersRolesDemoteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}/users/roles/demote`,
        method: 'PATCH',
        body: queryArg.demoteGroupUserBodySchema,
      }),
    }),
    patchApiOrganizationsByIdUsersRolesSuperadmin: build.mutation<
    PatchApiOrganizationsByIdUsersRolesSuperadminApiResponse,
    PatchApiOrganizationsByIdUsersRolesSuperadminApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}/users/roles/superadmin`,
        method: 'PATCH',
        body: queryArg.assignSuperadminBodySchema,
      }),
    }),
    getApiOrganizationsAccept: build.query<
    GetApiOrganizationsAcceptApiResponse,
    GetApiOrganizationsAcceptApiArg
    >({
      query: () => ({
        url: `/api/organizations/accept`,
      }),
    }),
    patchApiOrganizationsAccept: build.mutation<
    PatchApiOrganizationsAcceptApiResponse,
    PatchApiOrganizationsAcceptApiArg
    >({
      query: () => ({
        url: `/api/organizations/accept`,
        method: 'PATCH',
      }),
    }),
    getApiOrganizationsDecline: build.query<
    GetApiOrganizationsDeclineApiResponse,
    GetApiOrganizationsDeclineApiArg
    >({
      query: () => ({
        url: `/api/organizations/decline`,
      }),
    }),
    patchApiOrganizationsDecline: build.mutation<
    PatchApiOrganizationsDeclineApiResponse,
    PatchApiOrganizationsDeclineApiArg
    >({
      query: () => ({
        url: `/api/organizations/decline`,
        method: 'PATCH',
      }),
    }),
    deleteApiOrganizationsByIdUsersCurrent: build.mutation<
    DeleteApiOrganizationsByIdUsersCurrentApiResponse,
    DeleteApiOrganizationsByIdUsersCurrentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}/users/current`,
        method: 'DELETE',
      }),
    }),
    getApiOrganizationsByIdQuestionsAndQuestionIdOnboardingText: build.query<
    GetApiOrganizationsByIdQuestionsAndQuestionIdOnboardingTextApiResponse,
    GetApiOrganizationsByIdQuestionsAndQuestionIdOnboardingTextApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}/questions/${queryArg.questionId}/onboarding-text`,
      }),
    }),
    putApiOrganizationsByIdQuestionsAndQuestionIdOnboardingText: build.mutation<
    PutApiOrganizationsByIdQuestionsAndQuestionIdOnboardingTextApiResponse,
    PutApiOrganizationsByIdQuestionsAndQuestionIdOnboardingTextApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}/questions/${queryArg.questionId}/onboarding-text`,
        method: 'PUT',
        body: queryArg.updateOrganizationQuestionOnboardingTextBodySchema,
      }),
    }),
    getApiOrganizationsByIdQuestionsOnboardingText: build.query<
    GetApiOrganizationsByIdQuestionsOnboardingTextApiResponse,
    GetApiOrganizationsByIdQuestionsOnboardingTextApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}/questions/onboarding-text`,
        params: {
          questionIds: queryArg.questionIds,
          sortBy: queryArg.sortBy,
          sortOrder: queryArg.sortOrder,
          page: queryArg.page,
          limit: queryArg.limit,
        },
      }),
    }),
    putApiOrganizationsByIdQuestionsOnboardingText: build.mutation<
    PutApiOrganizationsByIdQuestionsOnboardingTextApiResponse,
    PutApiOrganizationsByIdQuestionsOnboardingTextApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}/questions/onboarding-text`,
        method: 'PUT',
        body: queryArg.updateOrganizationQuestionsOnboardingTextBodySchema,
      }),
    }),
    putApiOrganizationsByIdQuestionsAndQuestionIdPostEstimation: build.mutation<
    PutApiOrganizationsByIdQuestionsAndQuestionIdPostEstimationApiResponse,
    PutApiOrganizationsByIdQuestionsAndQuestionIdPostEstimationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}/questions/${queryArg.questionId}/post-estimation`,
        method: 'PUT',
        body: queryArg.updateOrganizationQuestionPostEstimationBodySchema,
      }),
    }),
    getApiOrganizationsByIdQuestionsAndQuestionIdPostEstimation: build.query<
    GetApiOrganizationsByIdQuestionsAndQuestionIdPostEstimationApiResponse,
    GetApiOrganizationsByIdQuestionsAndQuestionIdPostEstimationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}/questions/${queryArg.questionId}/post-estimation`,
      }),
    }),
    postApiOrganizationsByIdQuestionsAddition: build.mutation<
    PostApiOrganizationsByIdQuestionsAdditionApiResponse,
    PostApiOrganizationsByIdQuestionsAdditionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}/questions/addition`,
        method: 'POST',
        body: queryArg.addBatchOrganizationQuestionsBodySchema,
      }),
    }),
    postApiOrganizationsByIdQuestionsRemovalDeactivated: build.mutation<
    PostApiOrganizationsByIdQuestionsRemovalDeactivatedApiResponse,
    PostApiOrganizationsByIdQuestionsRemovalDeactivatedApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}/questions/removal/deactivated`,
        method: 'POST',
        body: queryArg.fetchBatchRemoveOrganizationsQuestionsToDeactivateBodySchema,
      }),
    }),
    postApiOrganizationsByIdQuestionsRemoval: build.mutation<
    PostApiOrganizationsByIdQuestionsRemovalApiResponse,
    PostApiOrganizationsByIdQuestionsRemovalApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}/questions/removal`,
        method: 'POST',
        body: queryArg.removeBatchOrganizationQuestionsBodySchema,
      }),
    }),
    getApiOrganizationsByIdVerifications: build.mutation<
    GetApiOrganizationsByIdVerificationsApiResponse,
    GetApiOrganizationsByIdVerificationsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}/verifications`,
        method: 'GET',
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
          include: queryArg.include,
        },
      }),
    }),
    postApiOrganizationsByIdVerificationsManual: build.mutation<
    PostApiOrganizationsByIdVerificationsManualApiResponse,
    PostApiOrganizationsByIdVerificationsManualApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}/verifications/manual`,
        method: 'POST',
        body: queryArg.createManualVerificationBodySchema,
      }),
      invalidatesTags: ['Members'],
    }),
    putApiOrganizationsByIdVerificationsAutomatic: build.mutation<
    PutApiOrganizationsByIdVerificationsAutomaticApiResponse,
    PutApiOrganizationsByIdVerificationsAutomaticApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}/verifications/automatic`,
        method: 'PUT',
        body: queryArg.setOrganizationAutomaticVerificationBodySchema,
      }),
    }),
    getApiOrganizationsUsersVerificationAccept: build.query<
    GetApiOrganizationsUsersVerificationAcceptApiResponse,
    GetApiOrganizationsUsersVerificationAcceptApiArg
    >({
      query: () => ({
        url: `/api/organizations/users/verification/accept`,
      }),
    }),
    putApiOrganizationsUsersVerificationAccept: build.mutation<
    PutApiOrganizationsUsersVerificationAcceptApiResponse,
    PutApiOrganizationsUsersVerificationAcceptApiArg
    >({
      query: () => ({
        url: `/api/organizations/users/verification/accept`,
        method: 'PUT',
      }),
    }),
    getApiOrganizationsByIdWhitelistEmails: build.query<
    GetApiOrganizationsByIdWhitelistEmailsApiResponse,
    GetApiOrganizationsByIdWhitelistEmailsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}/whitelist/emails`,
      }),
    }),
    postApiOrganizationsByIdWhitelistEmails: build.mutation<
    PostApiOrganizationsByIdWhitelistEmailsApiResponse,
    PostApiOrganizationsByIdWhitelistEmailsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}/whitelist/emails`,
        method: 'POST',
        body: queryArg.createOrganizationEmailDomainBodySchema,
      }),
    }),
    deleteApiOrganizationsByIdWhitelistEmailsAndDomainId: build.mutation<
    DeleteApiOrganizationsByIdWhitelistEmailsAndDomainIdApiResponse,
    DeleteApiOrganizationsByIdWhitelistEmailsAndDomainIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}/whitelist/emails/${queryArg.domainId}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});

export {
  injectedRtkApi as arqApiOrganization
};
export type GetApiOrganizationsApiResponse =
  /** status 200 Default Response */ OrganizationsResponseSchema;
export type GetApiOrganizationsApiArg = {
  page?: Page;
  limit?: Limit;
};
export type GetApiOrganizationsByIdFeedApiResponse =
  /** status 200 Default Response */ OrganizationFeedResponseSchema;
export type GetApiOrganizationsByIdFeedApiArg = {
  page?: Page;
  limit?: Limit;
  filters?: {
    sortBy?: 'createdAt' | 'commentsCount' | 'commendationsCount';
    sortOrder?: 'asc' | 'desc';
    userOnly?: boolean;
    dateStart?: string;
    dateEnd?: DateStart;
    questionId?: string;
  };
  id: string;
};
export type PatchApiOrganizationsByIdApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PatchApiOrganizationsByIdApiArg = {
  id: string;
  updateOrganizationBodySchema: UpdateOrganizationBodySchema;
};
export type GetApiOrganizationsByIdApiResponse =
  /** status 200 Default Response */ GetOrganizationResponseSchema;
export type GetApiOrganizationsByIdApiArg = {
  id: string;
};
export type DeleteApiOrganizationsByIdApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type DeleteApiOrganizationsByIdApiArg = {
  id: string;
};
export type PostApiOrganizationsByIdLogoApiResponse =
  /** status 200 Default Response */ OrganizationLogoUploadResponseSchema;
export type PostApiOrganizationsByIdLogoApiArg = {
  'content-length': number;
  'id': string;
};
export type GetApiOrganizationsByIdThemesApiResponse =
  /** status 200 Default Response */ GetOrganizationThemesResponseSchema;
export type GetApiOrganizationsByIdThemesApiArg = {
  id: string;
};
export type DeleteApiOrganizationsByIdThemesAndThemeIdApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type DeleteApiOrganizationsByIdThemesAndThemeIdApiArg = {
  id: string;
  themeId: string;
};
export type PutApiOrganizationsByIdThemesActiveApiResponse =
  /** status 200 Default Response */ UpdateOrganizationThemeResponseSchema;
export type PutApiOrganizationsByIdThemesActiveApiArg = {
  id: string;
  updateOrganizationThemeBodySchema: UpdateOrganizationThemeBodySchema;
};
export type PostApiOrganizationsByIdThemesActiveApiResponse =
  /** status 200 Default Response */ CreateOrganizationThemeResponseSchema;
export type PostApiOrganizationsByIdThemesActiveApiArg = {
  id: string;
  createOrganizationThemeBodySchema: CreateOrganizationThemeBodySchema;
};
export type PostApiOrganizationsByIdGroupsApiResponse =
  /** status 201 Default Response */ CreateOrganizationGroupResponseSchema;
export type PostApiOrganizationsByIdGroupsApiArg = {
  id: string;
  createOrganizationGroupBodySchema: CreateOrganizationGroupBodySchema;
};
export type GetApiOrganizationsByIdGroupsApiResponse =
  /** status 200 Default Response */ OrganizationGroupsResponseSchema;
export type GetApiOrganizationsByIdGroupsApiArg = {
  query?: string;
  page?: number;
  limit?: number;
  id: string;
};
export type PostApiOrganizationsByIdGroupsFromGroupApiResponse =
  /** status 201 Default Response */ MessageResponseSchema;
export type PostApiOrganizationsByIdGroupsFromGroupApiArg = {
  id: string;
  createOrganizationGroupFromGroupBodySchema: CreateOrganizationGroupFromGroupBodySchema;
};
export type PostApiOrganizationsByIdGroupsUsersMergeApiResponse =
  /** status 201 Default Response */ MessageResponseSchema;
export type PostApiOrganizationsByIdGroupsUsersMergeApiArg = {
  id: string;
  mergeGroupsIntoGroupBodySchema: MergeGroupsIntoGroupBodySchema;
};
export type GetApiOrganizationsByIdGroupsMergeApiResponse =
  /** status 201 Default Response */ GetMergeGroupsResponseSchema;
export type GetApiOrganizationsByIdGroupsMergeApiArg = {
  page?: Page;
  limit?: Limit;
  id: string;
};
export type PutApiOrganizationsByIdGroupsAndGroupIdStatusesApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PutApiOrganizationsByIdGroupsAndGroupIdStatusesApiArg = {
  id: string;
  groupId: string;
  updateOrganizationGroupStatusBodySchema: UpdateOrganizationGroupStatusBodySchema;
};
export type GetApiOrganizationsByIdGroupsQuestionsActiveApiResponse =
  /** status 200 Default Response */ OrganizationGroupsWithActiveQuestionsCountResponseSchema;
export type GetApiOrganizationsByIdGroupsQuestionsActiveApiArg = {
  query?: string;
  page?: Page;
  limit?: Limit;
  id: string;
};
export type GetApiOrganizationsByIdGroupsInvitationsApiResponse =
  /** status 200 Default Response */ OrganizationInvitationsGroupsResponseSchema;
export type GetApiOrganizationsByIdGroupsInvitationsApiArg = {
  query?: string;
  page?: Page;
  limit?: Limit;
  lastCreatedAt?: string;
  lastId?: string;
  status?: 'ACTIVATED' | 'DEACTIVATED';
  id: string;
};
export type GetApiOrganizationsByIdGroupsInvitationsUsersApiResponse =
  /** status 200 Default Response */ OrganizationInvitationsGroupsWithUsersResponseSchema;
export type GetApiOrganizationsByIdGroupsInvitationsUsersApiArg = {
  id: string;
};
export type PostApiOrganizationsByIdGroupsInvitationsUsersApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PostApiOrganizationsByIdGroupsInvitationsUsersApiArg = {
  id: string;
  updateOrganizationGroupsInvitationsBodySchema: UpdateOrganizationGroupsInvitationsBodySchema;
};
export type GetApiOrganizationsByIdGroupsUsersApiResponse =
  /** status 200 Default Response */ OrganizationGroupsWithUsersResponseSchema;
export type GetApiOrganizationsByIdGroupsUsersApiArg = {
  page?: Page;
  limit?: Limit;
  id: string;
};
export type PostApiOrganizationsByIdGroupsUsersApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PostApiOrganizationsByIdGroupsUsersApiArg = {
  id: string;
  createGroupsUsersBodySchema: CreateGroupsUsersBodySchema;
};
export type DeleteApiOrganizationsByIdGroupsUsersAndUserIdApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type DeleteApiOrganizationsByIdGroupsUsersAndUserIdApiArg = {
  reason?: string;
  userId: string;
  id: string;
};
export type GetApiOrganizationsByIdUsersApiResponse =
  /** status 200 Default Response */ OrganizationUsersResponseSchema;
export type GetApiOrganizationsByIdUsersApiArg = {
  query?: string;
  page?: Page;
  limit?: Limit;
  id: string;
};
export type DeleteApiOrganizationsByIdUsersApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type DeleteApiOrganizationsByIdUsersApiArg = {
  userId: string;
  id: string;
};
export type GetApiOrganizationsByIdUsersAdminsApiResponse =
  /** status 200 Default Response */ OrganizationAdminsResponseSchema;
export type GetApiOrganizationsByIdUsersAdminsApiArg = {
  query?: string;
  id: string;
};
export type PostApiOrganizationsByIdUsersMessagesApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PostApiOrganizationsByIdUsersMessagesApiArg = {
  id: string;
  sendOrganizationUsersMessageBodySchema: SendOrganizationUsersMessageBodySchema;
};
export type GetApiOrganizationsByIdGroupsUsersInvitedCountApiResponse =
  /** status 200 Default Response */ OrganizationInvitedUsersCountResponseSchema;
export type GetApiOrganizationsByIdGroupsUsersInvitedCountApiArg = {
  id: string;
};
export type GetApiOrganizationsByIdGroupsUsersDownloadApiResponse = unknown;
export type GetApiOrganizationsByIdGroupsUsersDownloadApiArg = {
  type?: 'csv' | 'xlsx';
  id: string;
};
export type GetApiOrganizationsByIdUsersInactiveCountApiResponse =
  /** status 200 Default Response */ GetOrganizationInactiveUsersCountResponseSchema;
export type GetApiOrganizationsByIdUsersInactiveCountApiArg = {
  oldInactiveOnly?: boolean;
  id: string;
};
export type DeleteApiOrganizationsByIdUsersInactiveApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type DeleteApiOrganizationsByIdUsersInactiveApiArg = {
  oldInactiveOnly?: boolean;
  id: string;
};
export type PostApiOrganizationsByIdUsersInvitesFilesTemplateApiResponse =
  /** status 200 Default Response */ UsersTemplateFileUploadResponseSchema;
export type PostApiOrganizationsByIdUsersInvitesFilesTemplateApiArg = {
  id: string;
};
export type PatchApiOrganizationsByIdUsersRolesPromoteApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PatchApiOrganizationsByIdUsersRolesPromoteApiArg = {
  id: string;
  promoteGroupUserBodySchema: PromoteGroupUserBodySchema;
};
export type PatchApiOrganizationsByIdUsersRolesDemoteApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PatchApiOrganizationsByIdUsersRolesDemoteApiArg = {
  id: string;
  demoteGroupUserBodySchema: DemoteGroupUserBodySchema;
};
export type PatchApiOrganizationsByIdUsersRolesSuperadminApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PatchApiOrganizationsByIdUsersRolesSuperadminApiArg = {
  id: string;
  assignSuperadminBodySchema: AssignSuperadminBodySchema;
};
export type GetApiOrganizationsAcceptApiResponse = unknown;
export type GetApiOrganizationsAcceptApiArg = void;
export type PatchApiOrganizationsAcceptApiResponse =
  /** status 200 Default Response */ AcceptGroupUpgradeToOrganizationResponseSchema;
export type PatchApiOrganizationsAcceptApiArg = void;
export type GetApiOrganizationsDeclineApiResponse = unknown;
export type GetApiOrganizationsDeclineApiArg = void;
export type PatchApiOrganizationsDeclineApiResponse =
  /** status 200 Default Response */ DeclineGroupUpgradeToOrganizationResponseSchema;
export type PatchApiOrganizationsDeclineApiArg = void;
export type DeleteApiOrganizationsByIdUsersCurrentApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type DeleteApiOrganizationsByIdUsersCurrentApiArg = {
  id: string;
};
export type GetApiOrganizationsByIdQuestionsAndQuestionIdOnboardingTextApiResponse =
  /** status 200 Default Response */ OrganizationQuestionOnboardingTextResponseSchema;
export type GetApiOrganizationsByIdQuestionsAndQuestionIdOnboardingTextApiArg =
  {
    id: string;
    questionId: string;
  };
export type PutApiOrganizationsByIdQuestionsAndQuestionIdOnboardingTextApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PutApiOrganizationsByIdQuestionsAndQuestionIdOnboardingTextApiArg =
  {
    id: string;
    questionId: string;
    updateOrganizationQuestionOnboardingTextBodySchema: UpdateOrganizationQuestionOnboardingTextBodySchema;
  };
export type GetApiOrganizationsByIdQuestionsOnboardingTextApiResponse =
  /** status 200 Default Response */ OrganizationQuestionsOnboardingTextResponseSchema;
export type GetApiOrganizationsByIdQuestionsOnboardingTextApiArg = {
  questionIds?: string[];
  sortBy?: 'createdAt' | 'startDate';
  sortOrder?: SortOrder;
  page?: Page;
  limit?: Limit;
  id: string;
};
export type PutApiOrganizationsByIdQuestionsOnboardingTextApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PutApiOrganizationsByIdQuestionsOnboardingTextApiArg = {
  id: string;
  updateOrganizationQuestionsOnboardingTextBodySchema: UpdateOrganizationQuestionsOnboardingTextBodySchema;
};
export type PutApiOrganizationsByIdQuestionsAndQuestionIdPostEstimationApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PutApiOrganizationsByIdQuestionsAndQuestionIdPostEstimationApiArg =
  {
    id: string;
    questionId: string;
    updateOrganizationQuestionPostEstimationBodySchema: UpdateOrganizationQuestionPostEstimationBodySchema;
  };
export type GetApiOrganizationsByIdQuestionsAndQuestionIdPostEstimationApiResponse =
  /** status 200 Default Response */ GetOrganizationQuestionPostEstimationResponseSchema;
export type GetApiOrganizationsByIdQuestionsAndQuestionIdPostEstimationApiArg =
  {
    id: string;
    questionId: string;
  };
export type PostApiOrganizationsByIdQuestionsAdditionApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PostApiOrganizationsByIdQuestionsAdditionApiArg = {
  id: string;
  addBatchOrganizationQuestionsBodySchema: AddBatchOrganizationQuestionsBodySchema;
};
export type PostApiOrganizationsByIdQuestionsRemovalDeactivatedApiResponse =
  /** status 200 Default Response */ FetchBatchRemovedOrganizationsQuestionsToDeactivateResponseSchema;
export type PostApiOrganizationsByIdQuestionsRemovalDeactivatedApiArg = {
  id: string;
  fetchBatchRemoveOrganizationsQuestionsToDeactivateBodySchema: FetchBatchRemoveOrganizationsQuestionsToDeactivateBodySchema;
};
export type PostApiOrganizationsByIdQuestionsRemovalApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PostApiOrganizationsByIdQuestionsRemovalApiArg = {
  id: string;
  removeBatchOrganizationQuestionsBodySchema: RemoveBatchOrganizationQuestionsBodySchema;
};
export type GetApiOrganizationsByIdVerificationsApiResponse =
  /** status 200 Default Response */ OrganizationVerificationsResponseSchema;
export type GetApiOrganizationsByIdVerificationsApiArg = {
  page?: Page;
  limit?: Limit;
  include?: 'settings'[];
  id: string;
};
export type PostApiOrganizationsByIdVerificationsManualApiResponse =
  /** status 200 Default Response */ CreateManualVerificationResponseSchema;
export type PostApiOrganizationsByIdVerificationsManualApiArg = {
  id: string;
  createManualVerificationBodySchema: CreateManualVerificationBodySchema;
};
export type PutApiOrganizationsByIdVerificationsAutomaticApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PutApiOrganizationsByIdVerificationsAutomaticApiArg = {
  id: string;
  setOrganizationAutomaticVerificationBodySchema: SetOrganizationAutomaticVerificationBodySchema;
};
export type GetApiOrganizationsUsersVerificationAcceptApiResponse = unknown;
export type GetApiOrganizationsUsersVerificationAcceptApiArg = void;
export type PutApiOrganizationsUsersVerificationAcceptApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PutApiOrganizationsUsersVerificationAcceptApiArg = void;
export type GetApiOrganizationsByIdWhitelistEmailsApiResponse =
  /** status 200 Default Response */ ListOrganizationEmailDomainsResponseSchema;
export type GetApiOrganizationsByIdWhitelistEmailsApiArg = {
  id: string;
};
export type PostApiOrganizationsByIdWhitelistEmailsApiResponse =
  /** status 200 Default Response */ CreateOrganizationEmailDomainResponseSchema;
export type PostApiOrganizationsByIdWhitelistEmailsApiArg = {
  id: string;
  createOrganizationEmailDomainBodySchema: CreateOrganizationEmailDomainBodySchema;
};
export type DeleteApiOrganizationsByIdWhitelistEmailsAndDomainIdApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type DeleteApiOrganizationsByIdWhitelistEmailsAndDomainIdApiArg = {
  id: string;
  domainId: string;
};
export type OrganizationsResponseSchema = {
  message: string;
  data: {
    total: number;
    organizations: {
      id: string;
      logoUrl: string | null;
      name: string;
      isSetUp: boolean;
      role: 'ADMIN' | 'SUPERADMIN' | 'USER';
      memberCount: number;
      groupCount: number;
      invitedCount: number;
      communicationDay: number | null;
    }[];
  };
};
export type Page = number;
export type Limit = number;
export type Author = {
  id: string;
  name: string;
  avatarUrl: string | null;
  displayName: string | null;
  isFollowed: boolean;
};
export type OrganizationFeedResponseSchema = {
  message: string;
  data: {
    feed: {
      id: string;
      createdAt: string;
      updatedAt: string;
      text: string;
      isPrivate: boolean;
      commendationsCount: number;
      userCommendation:
      | ('LIKE' | 'RELEVANT' | 'INSIGHTFUL' | 'INSPIRING')
      | null;
      commendationTypes:
      | ('LIKE' | 'RELEVANT' | 'INSIGHTFUL' | 'INSPIRING')[]
      | null;
      groupRemoveAccess?: boolean;
      author: {
        id: string;
        name: string;
        avatarUrl: string | null;
        displayName: string | null;
        isFollowed: boolean;
      };
      forecast: {
        id: string;
        estimates: {
          title: string;
          value: number;
        }[];
      } | null;
      question: {
        id: string;
        title: string;
        hasUserOnboarded?: boolean;
      };
      commentsCount: number;
      comments: {
        id: string;
        createdAt: string;
        updatedAt: string;
        text: string;
        author: Author;
        likes: number;
        isLiked: boolean;
      }[];
      estimatesDiff: number | null;
    }[];
    total: number;
    hasAvailable: boolean;
  };
};
export type DateStart = string;
export type MessageResponseSchema = {
  message: string;
};
export type UpdateOrganizationBodySchema = {
  name?: string;
  domain?: string | null;
  isSetUp?: boolean;
  communicationDay?: number | null;
};
export type GetOrganizationResponseSchema = {
  message: string;
  data: {
    organization: {
      id: string;
      name: string;
      logoUrl: string | null;
      createdAt: string;
      updatedAt: string;
      domain: string | null;
      isSetUp: boolean;
      communicationDay: number | null;
      activeTheme: {
        id: string;
        name: string;
        colorPrimary: string;
        isDefault: null | true;
      } | null;
    };
  };
};
export type OrganizationLogoUploadResponseSchema = {
  message: string;
  data: {
    organization: {
      id: string;
      logoUrl: string;
      name: string;
    };
  };
};
export type GetOrganizationThemesResponseSchema = {
  message: string;
  data: {
    themes: {
      id: string;
      isDefault: null | true;
      isActive: boolean;
      colorPrimary: string;
      name: string;
    }[];
  };
};
export type UpdateOrganizationThemeResponseSchema = {
  message: string;
  data: {
    theme: {
      colorPrimary: string;
      name: string;
      id: string;
      isDefault: null | true;
    } | null;
  };
};
export type UpdateOrganizationThemeBodySchema = {
  themeId: string;
};
export type CreateOrganizationThemeResponseSchema = {
  message: string;
  data: {
    theme: {
      colorPrimary: string;
      name: string;
      id: string;
    } | null;
  };
};
export type CreateOrganizationThemeBodySchema = {
  colorPrimary: string | string;
  name: string;
};
export type CreateOrganizationGroupResponseSchema = {
  message: string;
  data: {
    group: {
      name: string;
      location: string | null;
      id: string;
    };
  };
};
export type CreateOrganizationGroupBodySchema = {
  name: string;
  location?: string;
  questionIds?: string[];
  isPublic: boolean;
  emails?: string[];
  invitationMessage: string | null;
};
export type OrganizationGroupsResponseSchema = {
  message: string;
  data: {
    groups: {
      id: string;
      name: string;
      status: 'ACTIVATED' | 'DEACTIVATED';
      isPublic: boolean | null;
      location: string | null;
      role: 'SUPERADMIN' | 'ADMIN' | 'USER';
      memberCount: number;
      forecastCount: number;
      commentCount: number;
      questionSets:
      | {
        id: string;
        title: string;
        startDate: string;
        endDate: string;
        questionsCount: number | null;
        activeQuestionsCount: number | null;
      }[]
      | null;
    }[];
    total: number;
  };
};
export type CreateOrganizationGroupFromGroupBodySchema = {
  group: {
    name: string;
    location: string | null;
  };
  fromGroupId: string;
  userIds: string[];
  inactivateChosenUsers: boolean;
};
export type MergeGroupsIntoGroupBodySchema = {
  fromGroups: {
    id: string;
    userIds: string[];
  }[];
  toGroupId: string;
  inactivateChosenUsers: boolean;
};
export type GetMergeGroupsResponseSchema = {
  message: string;
  data: {
    groups: {
      id: string;
      name: string;
      location: string | null;
      activeUsersCount: number;
    }[];
    total: number;
  };
};
export type UpdateOrganizationGroupStatusBodySchema = {
  status: 'ACTIVATED' | 'DEACTIVATED';
};
export type OrganizationGroupsWithActiveQuestionsCountResponseSchema = {
  message: string;
  data: {
    groups: {
      id: string;
      name: string;
      activeQuestionCount: number;
    }[];
    total: number;
  };
};
export type OrganizationInvitationsGroupsResponseSchema = {
  message: string;
  data: {
    groups: {
      id: string;
      name: string;
      isPublic: boolean | null;
      createdAt: string;
      status: 'ACTIVATED' | 'DEACTIVATED';
      location: string | null;
      invitedCount: number;
      activeUsersCount: number;
    }[];
    total: number;
  };
};
export type OrganizationInvitationsGroupsWithUsersResponseSchema = {
  message: string;
  data: {
    groups: {
      id: string;
      name: string;
      location: string | null;
      isPublic: boolean | null;
      invitedCount: number;
      characteristics: {
        key: string;
        value: string | null;
      }[];
      users: {
        id: string;
        email: string;
        characteristics: {
          key: string;
          value: string | null;
        }[];
      }[];
    }[];
  };
};
export type UpdateOrganizationGroupsInvitationsBodySchema = {
  groups: {
    id: string;
    users: {
      id?: string;
      email: string;
    }[];
  }[];
};
export type OrganizationGroupsWithUsersResponseSchema = {
  message: string;
  data: {
    groups: {
      id: string;
      name: string;
      location: string | null;
      isPublic: boolean | null;
      characteristics: {
        key: string;
        value: string | null;
      }[];
      users: {
        id: string;
        email: string;
        characteristics: {
          key: string;
          value: string | null;
        }[];
      }[];
    }[];
    total: number;
  };
};
export type CreateGroupsUsersBodySchema = {
  groups: {
    id: string;
    users: {
      id?: string;
      email: string;
    }[];
  }[];
};
export type OrganizationUsersResponseSchema = {
  message: string;
  data: {
    members: {
      id: string;
      name: string;
      displayName: string | null;
      avatarUrl: string | null;
      email: string | null;
      forecastCount: number;
      commentCount: number;
    }[];
    total: number;
  };
};
export type OrganizationAdminsResponseSchema = {
  message: string;
  data: {
    admins: {
      id: string;
      name: string;
      email: string | null;
      role: 'SUPERADMIN' | 'ADMIN';
      displayName: string | null;
      avatarUrl: string | null;
      forecastCount: number;
      commentCount: number;
    }[];
    status: {
      lastVerificationAt: string | null;
      count: {
        removed: number;
        registered: number;
        active: number;
        inactive: number;
        invited: number;
      };
    };
  };
};
export type SendOrganizationUsersMessageBodySchema = {
  message: string;
  emailIncluded: boolean;
};
export type OrganizationInvitedUsersCountResponseSchema = {
  data: {
    total: number;
  };
};
export type GetOrganizationInactiveUsersCountResponseSchema = {
  message: string;
  data: {
    count: number;
  };
};
export type UsersTemplateFileUploadResponseSchema = {
  message: string;
};
export type PromoteGroupUserBodySchema = {
  userId: string;
};
export type DemoteGroupUserBodySchema = {
  userId: string;
};
export type AssignSuperadminBodySchema = {
  userId: string;
};
export type AcceptGroupUpgradeToOrganizationResponseSchema = {
  message: string;
  data: {
    groupName: string;
    organizationName: string;
  };
};
export type DeclineGroupUpgradeToOrganizationResponseSchema = {
  message: string;
  data: {
    groupName: string;
  };
};
export type OrganizationQuestionOnboardingTextResponseSchema = {
  message: string;
  data: {
    onboardingText: string | null;
  };
};
export type UpdateOrganizationQuestionOnboardingTextBodySchema = {
  onboardingText: string | null;
};
export type OrganizationQuestionsOnboardingTextResponseSchema = {
  message: string;
  data: {
    total: number;
    questions: {
      status: 'AVAILABLE' | 'UNAVAILABLE' | 'DEACTIVATED';
      id: string;
      title: string;
      startDate: string;
      endDate: string;
      onboardingText: string | null;
    }[];
  };
};
export type SortOrder = 'asc' | 'desc';
export type UpdateOrganizationQuestionsOnboardingTextBodySchema = {
  questions: {
    id: string;
    onboardingText: string | null;
  }[];
};
export type UpdateOrganizationQuestionPostEstimationBodySchema = {
  postDebasing: boolean;
};
export type GetOrganizationQuestionPostEstimationResponseSchema = {
  message: string;
  data: {
    postDebasing: boolean;
  };
};
export type AddBatchOrganizationQuestionsBodySchema = {
  questions: {
    id: string;
    onboardingText: string | null;
  }[];
  groupIds: string[];
};
export type FetchBatchRemovedOrganizationsQuestionsToDeactivateResponseSchema =
  {
    message: string;
    data: {
      deactivationList: {
        groupNames: string[];
        sets?: {
          title: string;
          startDate: string;
          endDate: string;
          questionsCount: number;
        }[];
        questions?: string[];
      }[];
    };
  };
export type FetchBatchRemoveOrganizationsQuestionsToDeactivateBodySchema = {
  questionIds: string[];
  groupIds: string[];
};
export type RemoveBatchOrganizationQuestionsBodySchema = {
  questionIds: string[];
  groupIds: string[];
};
export type OrganizationVerificationsResponseSchema = {
  message: string;
  data: {
    verifications: {
      id: string;
      createdAt: string;
      updatedAt: string;
      type: 'AUTOMATIC' | 'MANUAL';
    }[];
    total: number;
    verificationSettings: {
      lastManualVerificationAt: string | null;
      automaticVerificationFrequency: number | null;
      lastAutomaticVerificationAt: string | null;
      nextManualVerificationAt: string | null;
    } | null;
  };
};
export type Items = {
  id: string;
  createdAt: string;
  updatedAt: string;
  type: 'AUTOMATIC' | 'MANUAL';
};
export type CreateManualVerificationResponseSchema = {
  message: string;
  data: {
    verification: Items;
  };
};
export type CreateManualVerificationBodySchema = {
  reverifyInactiveOnly: boolean;
};
export type SetOrganizationAutomaticVerificationBodySchema = {
  automaticVerificationFrequencyMs: number | null;
  automaticVerificationEnabled: boolean;
};
export type Domain = {
  id: string;
  createdAt: string;
  updatedAt: string;
  domain: string;
};
export type ListOrganizationEmailDomainsResponseSchema = {
  message: string;
  data: {
    domains: Domain[];
  };
};
export type CreateOrganizationEmailDomainResponseSchema = {
  message: string;
  data: {
    domain: {
      id: string;
      createdAt: string;
      updatedAt: string;
      domain: string;
    };
  };
};
export type CreateOrganizationEmailDomainBodySchema = {
  domain: string;
};
export const {
  useGetApiOrganizationsQuery,
  useGetApiOrganizationsByIdFeedQuery,
  usePatchApiOrganizationsByIdMutation,
  useGetApiOrganizationsByIdQuery,
  useDeleteApiOrganizationsByIdMutation,
  usePostApiOrganizationsByIdLogoMutation,
  useGetApiOrganizationsByIdThemesQuery,
  useDeleteApiOrganizationsByIdThemesAndThemeIdMutation,
  usePutApiOrganizationsByIdThemesActiveMutation,
  usePostApiOrganizationsByIdThemesActiveMutation,
  usePostApiOrganizationsByIdGroupsMutation,
  useGetApiOrganizationsByIdGroupsQuery,
  usePostApiOrganizationsByIdGroupsFromGroupMutation,
  usePostApiOrganizationsByIdGroupsUsersMergeMutation,
  useGetApiOrganizationsByIdGroupsMergeQuery,
  usePutApiOrganizationsByIdGroupsAndGroupIdStatusesMutation,
  useGetApiOrganizationsByIdGroupsQuestionsActiveQuery,
  useGetApiOrganizationsByIdGroupsInvitationsQuery,
  useGetApiOrganizationsByIdGroupsInvitationsUsersQuery,
  usePostApiOrganizationsByIdGroupsInvitationsUsersMutation,
  useGetApiOrganizationsByIdGroupsUsersQuery,
  usePostApiOrganizationsByIdGroupsUsersMutation,
  useDeleteApiOrganizationsByIdGroupsUsersAndUserIdMutation,
  useGetApiOrganizationsByIdUsersQuery,
  useDeleteApiOrganizationsByIdUsersMutation,
  useGetApiOrganizationsByIdUsersAdminsQuery,
  usePostApiOrganizationsByIdUsersMessagesMutation,
  useGetApiOrganizationsByIdGroupsUsersInvitedCountQuery,
  useGetApiOrganizationsByIdGroupsUsersDownloadMutation,
  useGetApiOrganizationsByIdUsersInactiveCountQuery,
  useDeleteApiOrganizationsByIdUsersInactiveMutation,
  usePostApiOrganizationsByIdUsersInvitesFilesTemplateMutation,
  usePatchApiOrganizationsByIdUsersRolesPromoteMutation,
  usePatchApiOrganizationsByIdUsersRolesDemoteMutation,
  usePatchApiOrganizationsByIdUsersRolesSuperadminMutation,
  useGetApiOrganizationsAcceptQuery,
  usePatchApiOrganizationsAcceptMutation,
  useGetApiOrganizationsDeclineQuery,
  usePatchApiOrganizationsDeclineMutation,
  useDeleteApiOrganizationsByIdUsersCurrentMutation,
  useGetApiOrganizationsByIdQuestionsAndQuestionIdOnboardingTextQuery,
  usePutApiOrganizationsByIdQuestionsAndQuestionIdOnboardingTextMutation,
  useGetApiOrganizationsByIdQuestionsOnboardingTextQuery,
  usePutApiOrganizationsByIdQuestionsOnboardingTextMutation,
  usePutApiOrganizationsByIdQuestionsAndQuestionIdPostEstimationMutation,
  useGetApiOrganizationsByIdQuestionsAndQuestionIdPostEstimationQuery,
  usePostApiOrganizationsByIdQuestionsAdditionMutation,
  usePostApiOrganizationsByIdQuestionsRemovalDeactivatedMutation,
  usePostApiOrganizationsByIdQuestionsRemovalMutation,
  useGetApiOrganizationsByIdVerificationsMutation,
  usePostApiOrganizationsByIdVerificationsManualMutation,
  usePutApiOrganizationsByIdVerificationsAutomaticMutation,
  useGetApiOrganizationsUsersVerificationAcceptQuery,
  usePutApiOrganizationsUsersVerificationAcceptMutation,
  useGetApiOrganizationsByIdWhitelistEmailsQuery,
  usePostApiOrganizationsByIdWhitelistEmailsMutation,
  useDeleteApiOrganizationsByIdWhitelistEmailsAndDomainIdMutation,
} = injectedRtkApi;
