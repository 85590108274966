import React, {
  FC
} from 'react';
import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import i18next from 'i18next';
import {
  useTranslation
} from 'react-i18next';

import {
  ArrowUpIcon
} from 'src/shared/icons';
import {
  CircleButton, Spinner
} from 'src/shared/components';
import {
  CommentsFilterContext, SendCommentBlock
} from 'src/widgets';
import {
  commentID, groupId, questionId, ROUTE
} from 'src/shared/utils';
import {
  useGetComments,
  useGetPollingComments,
  useMediaQuery,
  useSendComment,
} from 'src/shared/hooks';
import {
  useTypedSelector
} from 'src/redux';
import {
  commentsListSelectors
} from 'src/redux/commentsList';

import {
  CommentList
} from './components';
import * as Style from './YourFeed.styles';

interface YourFeedProps {
  isExpanded: boolean;
  onExpand?: () => void;
  headerTitle?: string;
}

const ICON_SIZE = 24;

export const YourFeed: FC<YourFeedProps> = ({
  isExpanded,
  onExpand,
  headerTitle = i18next.t('feed.yourFeed'),
}) => {
  const location = useLocation();

  const isMobile = useMediaQuery(
    'md',
    'down'
  );

  const {
    t
  } = useTranslation();

  const {
    addReplyHandler, addNewCommentHandler
  } = useSendComment();

  const {
    hasAvailable
  } = useGetPollingComments();

  const {
    data, isLoading: isLoadingComments, hasOnboarded
  } = useGetComments();

  const [searchParams] = useSearchParams();
  const comments = useTypedSelector(commentsListSelectors.selectCommentsList);

  const activeQuestionId = searchParams.get(questionId) || null;
  const groupIdSearch = searchParams.get(groupId);
  const params = useParams();
  const questionIdToSearch = params.id || activeQuestionId;

  const isQuestionQuery = questionIdToSearch && !groupIdSearch;

  const commentsList = isQuestionQuery ? data : comments;
  const hasQuestionComments = isQuestionQuery ? hasOnboarded : hasAvailable;

  const commentIdToReply = searchParams.get(commentID);

  const isOnboardQuestion = !!location.state?.isOnboarding;
  const isDeactivated = !!location.state?.isDeactivated;

  const canAddComments = !isOnboardQuestion && !isDeactivated;

  const isQuestionPage = location.pathname.startsWith(`/${ROUTE.QUESTION}/`)
    && !isOnboardQuestion
    && !isDeactivated;

  const hasCommunityLink = !isMobile && isExpanded && isQuestionPage && hasQuestionComments;

  return (
    <Style.MainContainer $isExpanded={isExpanded}>
      <Style.Header>
        <div>
          <Style.HeaderTitle>{headerTitle}</Style.HeaderTitle>

          {hasCommunityLink && (
            <Link
              to={`/${ROUTE.COMMUNITY}`}
              state={
                params.id
                  ? {
                    questionId: params.id,
                  }
                  : null
              }
            >
              <Style.Question>
                <Style.QuestionTitle>
                  {t('feed.goToCommunity')}
                </Style.QuestionTitle>
              </Style.Question>
            </Link>
          )}
        </div>

        <Style.HeaderButtons>
          {isExpanded && hasQuestionComments && !isOnboardQuestion && (
            <CommentsFilterContext />
          )}

          {onExpand && !isMobile && (
            <CircleButton onClick={onExpand}>
              <ArrowUpIcon
                width={ICON_SIZE}
                height={ICON_SIZE}
              />
            </CircleButton>
          )}
        </Style.HeaderButtons>
      </Style.Header>

      {isExpanded && (
        <>
          {!!commentsList.length && !isOnboardQuestion && (
            <>
              {isLoadingComments ? <Spinner /> : <CommentList />}

              {(commentIdToReply || isQuestionPage) && !isDeactivated && (
                <SendCommentBlock
                  addNewCommentHandler={addNewCommentHandler}
                  addReplyHandler={addReplyHandler}
                />
              )}
            </>
          )}

          {(!commentsList.length || isOnboardQuestion) && (
            <>
              {isLoadingComments ? (
                <Spinner />
              ) : (
                <Style.EmptyContentContainer>
                  <Style.OnboardText>
                    {hasQuestionComments
                      ? t('empty.noResultsForYourFilters')
                      : t('feed.onboardFirstQuestionToOpenFeed')}
                  </Style.OnboardText>
                </Style.EmptyContentContainer>
              )}

              {isQuestionPage && canAddComments && (
                <SendCommentBlock
                  addNewCommentHandler={addNewCommentHandler}
                  addReplyHandler={addReplyHandler}
                />
              )}
            </>
          )}
        </>
      )}
    </Style.MainContainer>
  );
};
