import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const Label = styled.label`
  ${tw`flex flex-col gap-2 text-15-20 font-medium text-grey-dark-40`}
`;

export const Asterisk = styled.span`
  ${tw`text-red-label`}
`;
