import React, {
  MouseEvent, useEffect, useState, memo
} from 'react';
import {
  flexRender, Row
} from '@tanstack/react-table';
import {
  useInView
} from 'react-intersection-observer';

import {
  usePatchOrgRequestsUnreadMutation
} from 'src/redux/openapi';

import {
  EntityModel
} from '../../types';

import * as Style from './TableRow.styles';

interface TableRowProps<T extends EntityModel> {
  onRowClick?: (id: string) => void;
  row: Row<T>;
  index: number;
}

export const TableRowComponent = <T extends EntityModel>({
  row,
  onRowClick,
  index,
}: TableRowProps<T>) => {
  const [wasUpdated, setWasUpdated] = useState(false);

  const {
    id, isRead, status
  } = row.original;

  const canClick = status === 'PENDING';

  const onClick = (event: MouseEvent<HTMLTableRowElement>) => {
    event.stopPropagation();

    if (!canClick || !onRowClick) {
      return;
    }

    onRowClick(id);
  };

  const [updateStatus] = usePatchOrgRequestsUnreadMutation();

  const handleStatusUpdate = async () => {
    await updateStatus({
      updateGroupOrganizatinoRequestReadStatusBodySchema: {
        groupRequestIds: [id],
      },
    });

    setWasUpdated(true);
  };

  const isNewRequest = typeof isRead === 'boolean' && !isRead;

  const {
    ref, inView
  } = useInView({
    skip: !isNewRequest,
  });

  useEffect(
    () => {
      if (inView && isNewRequest && !wasUpdated) {
        handleStatusUpdate();
      }
    },
    [inView, isNewRequest, wasUpdated]
  );

  return (
    <Style.TableRow
      ref={ref}
      $index={index}
      onClick={onClick}
      $shouldHover={!!onRowClick && canClick}
    >
      {row.getVisibleCells().map((cell) => {
        return (
          <Style.TableCell key={cell.id}>
            {flexRender(
              cell.column.columnDef.cell,
              cell.getContext()
            )}
          </Style.TableCell>
        );
      })}
    </Style.TableRow>
  );
};

export const TableRow = memo(TableRowComponent);
