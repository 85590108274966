import React from 'react';
import {
  MenuListProps, components
} from 'react-select';

import {
  Spinner
} from 'src/shared/components';

export const SelectPaginationMenu = <T,>(props: MenuListProps<T>) => {
  const {
    selectProps: {
      sentryRef, displayLoader
    },
    children,
  } = props;

  return (
    <components.MenuList {...props}>
      {children}

      {displayLoader && (
        <div ref={sentryRef}>
          <Spinner size={24} />
        </div>
      )}
    </components.MenuList>
  );
};
