import React, {
  FC
} from 'react';

import * as Style from './ValueControllerInput.styles';

interface ValueControllerInputProps
  extends Pick<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  value?: number;
  formattedValue?: string;
  valueDescription?: string;
}

export const ValueControllerInput: FC<ValueControllerInputProps> = ({
  value = 0,
  formattedValue,
  valueDescription,
  ...props
}) => {
  return (
    <Style.InputContainer>
      <Style.Input
        type="number"
        value={value.toFixed()}
        {...props}
      />

      <p>{formattedValue || value}</p>

      {valueDescription && <Style.Subtitle>{valueDescription}</Style.Subtitle>}
    </Style.InputContainer>
  );
};
