import React, {
  FC
} from 'react';

import {
  PageLayout
} from 'src/widgets';

export const ComingSoon: FC = () => {
  return (
    <PageLayout>
      <section
        className="
          h-80vh
          flex flex-col gap-y-6 justify-center
        "
      >
        <h1
          className="
            font-bold text-6xl text-dark-blue text-center
          "
        >
          🛠️ Coming soon...
        </h1>
      </section>
    </PageLayout>
  );
};
