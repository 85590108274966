import React, {
  FC, SetStateAction, Dispatch
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  CheckBoxFalseIcon, CheckBoxTrueIcon
} from 'src/shared/icons';
import {
  useGetOrgGroupsInvitationsQuery
} from 'src/redux/openapi';

import * as Style from './GroupList.styles';

interface GroupListProps {
  selectedIds: string[];
  setSelectedGroups: Dispatch<SetStateAction<string[]>>;
  organizationId: string;
}

export const GroupList: FC<GroupListProps> = ({
  selectedIds,
  setSelectedGroups,
  organizationId,
}) => {
  const {
    t
  } = useTranslation();

  const {
    groups, total
  } = useGetOrgGroupsInvitationsQuery(
    {
      id: organizationId,
      limit: 5000,
    },
    {
      selectFromResult: ({
        data
      }) => ({
        groups: data?.data.groups || [],
        total: data?.data.total || 0,
      }),
    },
  );

  const isAllSelected = selectedIds.length === groups.length;

  const toggleSelectAll = () => {
    if (isAllSelected) {
      return setSelectedGroups([]);
    }

    const ids = groups.map(({
      id
    }) => id);

    return setSelectedGroups(ids);
  };

  const toggleGroupSelection = (id: string) => {
    setSelectedGroups((prev) => {
      const isChecked = prev.includes(id);

      if (isChecked) {
        return prev.filter((idFromList) => idFromList !== id);
      }

      return [...prev, id];
    });
  };

  return (
    <Style.ListContainer>
      <Style.ItemContainer>
        <Style.CheckboxContainer
          type="button"
          onClick={toggleSelectAll}
        >
          {isAllSelected ? <CheckBoxTrueIcon /> : <CheckBoxFalseIcon />}
        </Style.CheckboxContainer>

        <Style.ContentWrapper>
          <Style.Title>{t('buttons.selectAll')}</Style.Title>

          <Style.Subtitle>
            {`${t('group.Groups')}: `}

            <Style.InvitedCount>{total}</Style.InvitedCount>
          </Style.Subtitle>
        </Style.ContentWrapper>
      </Style.ItemContainer>

      {groups.map(({
        id, name, invitedCount
      }) => {
        const isSelected = selectedIds.includes(id);

        return (
          <Style.ItemContainer key={id}>
            <Style.CheckboxContainer
              type="button"
              onClick={() => toggleGroupSelection(id)}
            >
              {isSelected ? <CheckBoxTrueIcon /> : <CheckBoxFalseIcon />}
            </Style.CheckboxContainer>

            <Style.ContentWrapper>
              <Style.Title>{name}</Style.Title>

              <Style.Subtitle>
                {`${t('group.invited')}: `}

                <Style.InvitedCount>{invitedCount}</Style.InvitedCount>
              </Style.Subtitle>
            </Style.ContentWrapper>
          </Style.ItemContainer>
        );
      })}
    </Style.ListContainer>
  );
};
