import React, {
  FC, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import {
  clsx
} from 'clsx';
import {
  useParams
} from 'react-router-dom';

import {
  CheckBoxFalseIcon,
  CheckBoxTrueIcon,
  MoreVerticalIcon,
} from 'src/shared/icons';
import {
  Checkbox,
  MenuContainer,
  MenuItem,
  PopoverMenu,
  successfulToast,
  Tooltip,
} from 'src/shared/components';
import {
  formatDateRange, showWarningFromServer
} from 'src/shared/utils';
import {
  useTypedSelector
} from 'src/redux';
import {
  languageSelectors
} from 'src/redux/language';
import {
  usePutOrgQuestionPostEstimationMutation,
  useGetOrgQuestionPostEstimationQuery,
} from 'src/redux/openapi';

import * as Style from '../EditQuestions.styles';
import {
  EditQuestionType
} from '../types';
import {
  checkDeactivated, getTooltipMessage
} from '../utils';

interface QuestionCardProps {
  question: EditQuestionType;
  isSelected: boolean;
  onQuestionCheck: (id: string) => void;
  index: number;
  isRemoving?: boolean;
}

export const QuestionCard: FC<QuestionCardProps> = ({
  question,
  isSelected,
  onQuestionCheck,
  index,
  isRemoving,
}) => {
  const [wasMenuOpened, setWasMenuOpened] = useState(false);

  const params = useParams();

  const {
    organizationId = ''
  } = params;

  const {
    id,
    title,
    isMandatory = false,
    status,
    startDate,
    endDate,
    type,
  } = question;

  const {
    isAvailableForFeedback,
    isAvailabilityLoading,
    isAvailabilityFetching,
    refetch,
  } = useGetOrgQuestionPostEstimationQuery(
    {
      id: organizationId,
      questionId: id,
    },
    {
      skip: !organizationId || !wasMenuOpened,
      refetchOnMountOrArgChange: true,
      selectFromResult: ({
        data, isLoading, isFetching
      }) => ({
        isAvailableForFeedback: data?.data.postDebasing,
        isAvailabilityLoading: isLoading,
        isAvailabilityFetching: isFetching,
      }),
    },
  );

  const [updateAvailability] = usePutOrgQuestionPostEstimationMutation();

  const toggleAvailable = async () => {
    try {
      const response = await updateAvailability({
        id: organizationId,
        questionId: id,
        updateOrganizationQuestionPostEstimationBodySchema: {
          postDebasing: !isAvailableForFeedback,
        },
      }).unwrap();

      refetch();

      successfulToast(response.message);
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  const isCheckboxLoading = isAvailabilityLoading || isAvailabilityFetching;

  const onMenuOpen = () => {
    if (wasMenuOpened) {
      return;
    }

    setWasMenuOpened(true);
  };

  const isDeactivated = !isRemoving && checkDeactivated(question);
  const isUnavailable = !isDeactivated && status === 'UNAVAILABLE';

  const {
    t
  } = useTranslation();

  const language = useTypedSelector(languageSelectors.getLanguage);

  const isDisabled = isDeactivated || (isMandatory && isSelected) || (isMandatory && isRemoving);

  const isActive = !isDeactivated && !(isMandatory && isRemoving);
  const isSurvey = type === 'SURVEY';

  const tooltipMessage = isDisabled
    ? getTooltipMessage({
      isMandatory,
      isDeactivated,
    })
    : null;

  return (
    <Style.TopicDataContainer $isActive={isActive}>
      <Style.QuestionNumberModal>{`${index + 1}.`}</Style.QuestionNumberModal>

      <Style.QuestionContentContainer>
        <button
          type="button"
          onClick={() => onQuestionCheck(id)}
          disabled={isDisabled}
          className="flex"
        >
          <Tooltip
            variant="darkGray"
            opacity={1}
            disabled={!tooltipMessage}
            message={
              <Style.TooltipMessage>{tooltipMessage}</Style.TooltipMessage>
            }
          >
            {isSelected ? (
              <CheckBoxTrueIcon
                className={clsx(
                  'w-6 h-6 text-gray7',
                  {
                    'text-grey-dark-40': isDisabled,
                  }
                )}
              />
            ) : (
              <CheckBoxFalseIcon
                className={clsx(
                  'w-6 h-6 text-gray7',
                  {
                    'text-grey-dark-40': isDisabled,
                  }
                )}
              />
            )}
          </Tooltip>
        </button>

        <Style.QuestionInfoContainer>
          <Style.QuestionInfoTitleWrapper>
            <p className="grow">{title}</p>

            {organizationId && !isSurvey && (
              <PopoverMenu
                placement="left-center"
                buttonContent={(
                  <Style.QuestionInfoMenuWrapper>
                    <MoreVerticalIcon className="w-6 h-6" />
                  </Style.QuestionInfoMenuWrapper>
                )}
                shouldCloseOnParentDisappear
                onOpen={onMenuOpen}
              >
                <MenuContainer
                  $width="max-content"
                  className="border border-gray94"
                >
                  <MenuItem>
                    <Checkbox
                      type="checkMark"
                      isChecked={isAvailableForFeedback}
                      isLoading={isCheckboxLoading}
                      onChange={toggleAvailable}
                      label={t('question.availableForPostEstimationFeedback')}
                      className="gap-2"
                      disabled={isCheckboxLoading}
                    />
                  </MenuItem>
                </MenuContainer>
              </PopoverMenu>
            )}
          </Style.QuestionInfoTitleWrapper>

          <Style.QuestionExternalInfo>
            <Style.CardQuestionDates>
              {formatDateRange({
                start: startDate,
                end: endDate,
                language,
              })}
            </Style.CardQuestionDates>

            {isUnavailable && (
              <Style.UpcomingContainer $small>
                {t('question.upcoming')}
              </Style.UpcomingContainer>
            )}
          </Style.QuestionExternalInfo>
        </Style.QuestionInfoContainer>
      </Style.QuestionContentContainer>
    </Style.TopicDataContainer>
  );
};
