import {
  NotificationsReducer
} from './notificationsSlice';

const selectNotifications = (state: NotificationsReducer) => {
  return state.notifications.notifications;
};

const selectNotificationsTotal = (state: NotificationsReducer) => {
  return state.notifications.total;
};

const selectNotificationsUnreadCount = (state: NotificationsReducer) => {
  return state.notifications.unreadCount;
};

const selectNotificationsData = (state: NotificationsReducer) => {
  return state.notifications;
};

export const notificationsSelector = {
  selectNotifications,
  selectNotificationsTotal,
  selectNotificationsUnreadCount,
  selectNotificationsData,
};
