import React, {
  FC
} from 'react';

import {
  Logo
} from 'src/shared/components';
import {
  HelpCenter, Navigation, Notifications
} from 'src/features';
import {
  HeaderSearch, MyProfileMenu
} from 'src/widgets';
import {
  useTypedSelector
} from 'src/redux';
import {
  organizationSelectors
} from 'src/redux/organizations';

import * as Style from './Header.styles';

interface HeaderProps {
  isGeneral?: boolean;
}

export const Header: FC<HeaderProps> = ({
  isGeneral
}) => {
  const theme = useTypedSelector(organizationSelectors.orgUsersTheme);

  const isImageSrc = !!theme?.logoUrl;

  return (
    <Style.Header $isCustom={isImageSrc}>
      <Style.LeftContainer>
        <Style.LogoContainer $isCustom={isImageSrc}>
          <Logo />
        </Style.LogoContainer>

        <Navigation isGeneral={isGeneral} />
      </Style.LeftContainer>

      {!isGeneral && (
        <Style.RightContainer>
          <HeaderSearch />

          <Notifications />

          <HelpCenter />

          <MyProfileMenu />
        </Style.RightContainer>
      )}
    </Style.Header>
  );
};
