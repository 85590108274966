import {
  useEffect
} from 'react';

export const useGlobalStylesEffect = () => {
  useEffect(
    () => {
      document.body.classList.add(
        'bg-light-grayish-blue',
        'min-h-screen'
      );
    },
    []
  );
};
