import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  Spinner
} from 'src/shared/components/Spinner';
import {
  themeColors
} from 'src/shared/utils';
import {
  Button
} from 'src/shared/components/Button';

import * as Style from './StickyButtonsFooter.style';

interface StickyButtonsFooterProps {
  handleCancelClick: () => void;
  handleOrgNameChange: () => Promise<void>;
  isChangeOrgNameLoading: boolean;
}

export const StickyButtonsFooter: FC<StickyButtonsFooterProps> = ({
  handleCancelClick,
  handleOrgNameChange,
  isChangeOrgNameLoading,
}) => {
  const {
    t
  } = useTranslation();

  return (
    <Style.StickyButtonsFooter>
      <Button
        variant="big-black"
        className="w-max text-xl font-bold"
        onClick={handleCancelClick}
      >
        {t('buttons.cancel')}
      </Button>

      <Button
        variant="big-blue"
        className="w-max text-xl font-bold"
        onClick={handleOrgNameChange}
      >
        {isChangeOrgNameLoading ? (
          <Spinner
            size={24}
            color={themeColors.white}
          />
        ) : (
          t('buttons.saveChanges')
        )}
      </Button>
    </Style.StickyButtonsFooter>
  );
};
