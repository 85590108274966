import React, {
  FC, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  EmailIcon
} from 'src/shared/icons';
import {
  ModalAddMember
} from 'src/entities';

import {
  Button
} from '../Button';

import * as Style from './EmailInviteButton.styles';

interface EmailInviteButtonProps {
  refetchUsers?: () => void;
  groupId: string;
}

export const EmailInviteButton: FC<EmailInviteButtonProps> = ({
  refetchUsers,
  groupId,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    t
  } = useTranslation();

  const handleModalToggle = () => {
    if (refetchUsers) {
      refetchUsers();
    }

    setIsModalOpen((prev) => !prev);
  };

  return (
    <>
      <Button
        variant="invite-blue"
        className="flex-1 gap-1"
        onClick={handleModalToggle}
      >
        <Style.InviteEmailText>{t('group.emailInvite')}</Style.InviteEmailText>

        <EmailIcon className="text-button-blue" />
      </Button>

      <ModalAddMember
        isOpen={isModalOpen}
        closeModalHandler={handleModalToggle}
        groupID={groupId}
      />
    </>
  );
};
