import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const FormContainer = styled.form`
  ${tw`flex flex-col gap-8`}
`;

export const ModalHeader = styled.div`
  ${tw`flex flex-col gap-2 mb-8`}
`;

export const Title = styled.p`
  ${tw`text-28-36 text-gray7 font-bold`}
`;

export const Subtitle = styled.p`
  ${tw`text-base text-gray7 font-medium`}
`;

export const CheckboxesContainer = styled.div`
  ${tw`flex flex-col gap-3`}
`;

export const Label = styled.label`
  ${tw`flex gap-3 bg-alice-blue p-3 items-center rounded cursor-pointer`}
`;

export const IconContainer = styled.div`
  ${tw`flex justify-center items-center p-4 rounded-10px bg-input-blue`}
`;

export const ReasonDescription = styled.div`
  ${tw`flex flex-col gap-2 grow`}
`;

export const ReasonTitle = styled.p`
  ${tw`text-xl text-gray7 font-bold`}
`;

export const ReasonSubtitle = styled.p`
  ${tw`text-15-20 text-dark-gray font-medium`}
`;

export const ReasonInputContainer = styled.label`
  ${tw`flex gap-4 p-1 pl-4 bg-alice-blue rounded border border-gray94`}
`;

export const ReasonInput = styled.input`
  ${tw`grow bg-alice-blue outline-0`}
`;

export const SubmitButton = styled.button`
  ${tw`p-2.5 text-button-blue bg-white`}
`;
