import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const ContentContainer = styled.div`
  ${tw`flex flex-col gap-4 h-full grow`}
`;

export const SectionTitle = styled.p`
  ${tw`text-gray7 font-bold text-22-30`}
`;
