import React, {
  useState
} from 'react';

import {
  CloseIcon
} from 'src/shared/icons';

import {
  Spinner
} from '../Spinner';

import * as Style from './BasicColoredList.styles';

interface BasicColoredListItemProps<T extends Record<string, string>> {
  item: T;
  valueKey: keyof T;
  onDelete?: (listItem: T) => Promise<void> | void;
  index: number;
}

export const BasicColoredListItem = <T extends Record<string, string>>({
  item,
  valueKey,
  onDelete,
  index,
}: BasicColoredListItemProps<T>) => {
  const [isRemoving, setIsRemoving] = useState(false);

  const handleDelete = async (listItem: T) => {
    if (!onDelete) {
      return;
    }

    const isAsync = onDelete?.constructor.name === 'AsyncFunction';

    if (!isAsync) {
      onDelete(listItem);

      return;
    }

    setIsRemoving(true);

    await onDelete(listItem);

    setIsRemoving(false);
  };

  return (
    <Style.ListItem $index={index}>
      <Style.IndexContainer>{`${index + 1}.`}</Style.IndexContainer>

      <Style.ListItemText>{item[valueKey]}</Style.ListItemText>

      {onDelete && (
        <Style.CloseButton
          type="button"
          onClick={() => handleDelete(item)}
        >
          {isRemoving ? (
            <Spinner
              color="inherit"
              size={24}
            />
          ) : (
            <CloseIcon />
          )}
        </Style.CloseButton>
      )}
    </Style.ListItem>
  );
};
