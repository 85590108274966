import React, {
  FC,
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import {
  useSearchParams
} from 'react-router-dom';
import {
  useTranslation
} from 'react-i18next';

import {
  CommentListItem
} from 'src/entities/YourFeed/components';
import {
  ScrollContainer, Spinner, Button
} from 'src/shared/components';
import {
  useTypedSelector
} from 'src/redux';
import {
  userSelectors
} from 'src/redux/user';
import {
  Comment
} from 'src/redux/openapi';
import {
  commentID, followings, showOne
} from 'src/shared/utils';

import * as Style from './CommunityCommentsSection.styles';

interface CommentsListProps {
  commentsRef: MutableRefObject<HTMLDivElement | null>;
  comments: Comment[];
  isLoading: boolean;
  loadNextPage: () => Promise<void>;
  isLoadingMoreComments: boolean;
  refetch: () => void;
  total: number;
  hasQuestionComments: boolean;
}

export const CommentsList: FC<CommentsListProps> = ({
  commentsRef,
  comments,
  isLoading: isLoadingComments,
  loadNextPage,
  isLoadingMoreComments,
  refetch,
  total: commentsTotal,
  hasQuestionComments,
}) => {
  const {
    t
  } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const commentRef = useRef<HTMLDivElement | null>(null);

  const isLoading = isLoadingComments || isLoadingMoreComments;
  const shouldShowOneOnly = searchParams.get(showOne) === 'true';

  const {
    id: currentUserId
  } = useTypedSelector(userSelectors.user);

  const [isInitLoading, setIsInitLoading] = useState(true);

  const firstCommentId = useMemo(
    () => {
      return comments[0]?.id;
    },
    [comments]
  );

  useEffect(
    () => {
      if (scrollRef.current && commentRef.current && commentID) {
        commentRef.current.scrollIntoView();

        scrollRef.current?.scrollTo({
          top: scrollRef.current?.scrollHeight,
          left: 0,
          behavior: 'smooth',
        });

        setIsInitLoading(false);
      }
    },
    [firstCommentId, scrollRef, commentRef, commentID]
  );

  const loadNextPageHandler = () => {
    if (!isLoading) {
      loadNextPage();
    }
  };

  const onShowAllClick = () => {
    searchParams.delete(commentID);
    searchParams.delete(showOne);

    setSearchParams(searchParams);
  };

  const isFollowingsPage = searchParams.get(followings);

  return (
    <Style.Comments id="CommentsList">
      {!comments.length && !isFollowingsPage && (
        <Style.EmptyMessage>
          {hasQuestionComments
            ? t('empty.noResultsForYourFilters')
            : t('comment.thereAreNoComments')}
        </Style.EmptyMessage>
      )}

      {!comments.length && isFollowingsPage && (
        <Style.EmptyMessage>
          {t('community.hereActivityOfUsersYouFollow')}
        </Style.EmptyMessage>
      )}

      {!!comments.length && (
        <ScrollContainer
          className="max-h-full"
          externalRef={scrollRef}
        >
          <InfiniteScroll
            pageStart={1}
            loadMore={loadNextPageHandler}
            hasMore={!isInitLoading && comments.length < commentsTotal}
            loader={(
              <Spinner
                size={24}
                key="spinner"
              />
            )}
            useWindow={false}
            isReverse
          >
            <Style.CommentsContainer ref={commentsRef}>
              {comments.map((comment, index) => {
                const isCurrentUserComment = comment.author.id === currentUserId;

                const topRounded = index === 0
                  || comments[index - 1]?.author.id === currentUserId;

                const bottomRounded = index === comments.length - 1
                  || comments[index + 1]?.author.id === currentUserId;

                return (
                  <Style.CommentContainer
                    key={comment.id}
                    $isCurrentUserComment={isCurrentUserComment}
                    $withShift={isCurrentUserComment && !shouldShowOneOnly}
                    $topRounded={topRounded}
                    $bottomRounded={bottomRounded}
                    $isFullWidth={!!isFollowingsPage}
                    ref={!index ? commentRef : null}
                  >
                    <CommentListItem
                      comment={comment}
                      withDivider={false}
                      refetch={refetch}
                      groupRemoveAccess={!!comment.groupRemoveAccess}
                    />
                  </Style.CommentContainer>
                );
              })}
            </Style.CommentsContainer>
          </InfiniteScroll>
        </ScrollContainer>
      )}

      {shouldShowOneOnly && (
        <Button
          variant="big-black"
          className="absolute w-max bottom-6 right-6 font-bold text-xl"
          onClick={onShowAllClick}
        >
          {t('comment.showAllComments')}
        </Button>
      )}
    </Style.Comments>
  );
};
