import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const BlockContainer = styled.div<{
  $isNavBlock?: boolean;
}>`
  ${tw`px-6 pt-6 bg-white rounded-md shadow-card-shadow-default overflow-auto`}

  ${({
    $isNavBlock
  }) => ($isNavBlock ? tw`w-326px shrink-0` : tw`flex-1 relative`)}
`;
export const Container = styled.div`
  ${tw`h-full overflow-scroll pb-6 w-652px`}

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const NavContainer = styled.ul`
  ${tw`pb-6 flex flex-col gap-6 h-full overflow-scroll`}

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Title = styled.h1`
  ${tw`text-22-30 font-bold text-gray7 mb-10`}
`;
export const TextInfo = styled.p`
  ${tw`mb-4 last:mb-0 text-dark-gray text-17-26 font-medium max-w-535px`}
`;
export const ListItem = styled.li`
  ${tw`w-278px`}
`;
export const NavLink = styled.a<{ $isActive?: boolean }>`
  ${tw`text-gray7 text-17-26 font-medium capitalize`}

  ${({
    $isActive
  }) => $isActive && tw`text-button-blue`}
`;

export const Footer = styled.div`
  ${tw`absolute left-0 right-0 bottom-0 h-12 bg-footer`}
`;

export const MainContainer = styled.div`
  ${tw`flex h-full gap-6`}
`;

export const ContentTitle = styled.h1<{ $isActive: boolean }>`
  ${({
    $isActive
  }) => $isActive && tw`!text-button-blue`}
`;

export const Content = styled.div`
  ${tw`text-dim-gray font-medium overflow-auto`}

  h1 {
    ${tw`text-gray7 font-bold text-22-30 mb-3`}
  }

  h2 {
    ${tw`text-gray7 font-bold text-17-26 mb-3`}
  }

  p {
    ${tw`mb-6`}
  }

  ul {
    ${tw`mb-6 list-disc list-inside pl-10`}
  }

  ul li {
    ${tw`mb-6 list-disc`}
  }

  ul ul li {
    ${tw`mb-6`}

    list-style: square;
  }

  a {
    ${tw`text-button-blue hover:underline transition-all`}
  }
`;
