import React, {
  FC,
  FormEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  useLocation, useNavigate, useSearchParams
} from 'react-router-dom';
import {
  Controller, useForm
} from 'react-hook-form';
import {
  zodResolver
} from '@hookform/resolvers/zod';
import {
  useTranslation
} from 'react-i18next';
import {
  z
} from 'zod';

import {
  useTypedDispatch, useTypedSelector
} from 'src/redux';
import {
  Input,
  InputType,
  LoginSocialsInvite,
  warningToast,
  successfulToast,
  Button,
  Spinner,
} from 'src/shared/components';
import {
  setUserEmail, setUserName, userSelectors
} from 'src/redux/user';
import {
  ROUTE,
  invitedGroupName,
  invitedUserEmail,
  isErrorWithMessage,
  verification,
  showWarningFromServer,
  themeColors,
  groupInvitation,
  initVerification,
} from 'src/shared/utils';
import {
  usePostAuthSignInMutation,
  usePostReverificationsResendMutation,
  usePostUserVerificationsMutation,
} from 'src/redux/openapi';
import {
  useGetInputValidation, useGetRemainingTime
} from 'src/shared/hooks';
import {
  ToastBlockContainer
} from 'src/shared/components/ToastBlockContainer';
import {
  useGetGroupUserInviteAcceptSessionMutation
} from 'src/shared/api/groups/groupsApi';
import {
  useGetLoginExistsMutation
} from 'src/shared/api/auth/authApi';
import {
  setEmailToVerify
} from 'src/redux/verification';

import {
  InputNames
} from '../types';

import {
  SubmitButton
} from './SubmitButton';
import * as Style from './Login.styles';

interface EnterPasswordPageProps {
  isSocials?: boolean;
}

interface LoginForm {
  [InputNames.EMAIL]: string;
  [InputNames.PASSWORD]: string;
}

const formId = 'EnterPasswordForm';

export const EnterPasswordPage: FC<EnterPasswordPageProps> = ({
  isSocials,
}) => {
  const isUninitialized = useRef(false);

  const [isReverificationRequired, setIsReverificationRequired] = useState(false);

  const {
    startCountdown, remainingTime
  } = useGetRemainingTime();

  const navigate = useNavigate();

  const {
    search
  } = useLocation();

  const dispatch = useTypedDispatch();

  const {
    t
  } = useTranslation();

  const [login, {
    isLoading
  }] = usePostAuthSignInMutation();

  const {
    enterPasswordPageSchema
  } = useGetInputValidation();

  const [searchParams, setSearchParams] = useSearchParams();
  const invitedGroupTitle = searchParams.get(invitedGroupName) || '';
  const invitedEmail = searchParams.get(invitedUserEmail);
  const isReverification = searchParams.get(verification) === 'true';
  const isGroupInvitationStatus = searchParams.get(groupInvitation) === 'true';
  const isInitVerification = searchParams.get(initVerification);

  const [verifyAccount] = usePostUserVerificationsMutation();

  const onAccountVerification = async () => {
    try {
      const response = await verifyAccount().unwrap();

      successfulToast(response.message);
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  useEffect(
    () => {
      if (isUninitialized.current === false) {
        if (isInitVerification === 'true') {
          onAccountVerification();
        }

        isUninitialized.current = true;
      }
    },
    []
  );

  const [resendVerification, {
    isLoading: isResendLoading
  }] = usePostReverificationsResendMutation();

  const [getLoginExists] = useGetLoginExistsMutation();

  const onResend = async () => {
    try {
      const response = await resendVerification().unwrap();

      startCountdown();

      successfulToast(response.message);
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  const {
    email: userEmail,
    id: userId,
    name,
  } = useTypedSelector(userSelectors.user);

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    setError,
    formState: {
      errors
    },
  } = useForm<LoginForm>({
    mode: 'onTouched',
    defaultValues: {
      [InputNames.EMAIL]: userEmail || name || '',
      [InputNames.PASSWORD]: '',
    },
    resolver: zodResolver(enterPasswordPageSchema),
  });

  const onSubmit = handleSubmit(async ({
    email, password
  }) => {
    if (!!email.trim() && password) {
      try {
        const response = await login({
          signInBodySchema: {
            login: email.trim(),
            password,
          },
        }).unwrap();

        const {
          reverificationRequired,
          verificationRequired,
          userBlocked,
          isGroupAssigned,
        } = response.data;

        successfulToast(response.message);

        if (reverificationRequired) {
          return setIsReverificationRequired(true);
        }

        if (verificationRequired) {
          dispatch(setEmailToVerify(verificationRequired.email));

          return navigate(`/${ROUTE.EMAIL_VERIFICATION}`);
        }

        if (userBlocked) {
          return navigate(`/${ROUTE.ACCOUNT_BANNED}`);
        }

        if (!isGroupAssigned) {
          return navigate(`/${ROUTE.CREATE_GROUP}`);
        }

        return navigate(`/${ROUTE.QUESTION}`);
      } catch (error) {
        if (isErrorWithMessage(error)) {
          warningToast(error.data.message);

          // TODO: change with codes after BE implementation
          if (error.data.message === t('errors.userNotFound')) {
            setError(
              InputNames.EMAIL,
              {
                type: 'manual',
                message: t('errors.userNotFound'),
              }
            );
          }

          if (error.data.message === t('errors.invalidPassword')) {
            setError(
              InputNames.PASSWORD,
              {
                type: 'manual',
                message: t('errors.invalidPassword'),
              }
            );
          }
        }
      }
    }

    return null;
  });

  useEffect(
    () => {
      if (userId) {
        navigate(`/${ROUTE.QUESTION}`);
      }
    },
    [userId]
  );

  const handleSubmitClick = (event: FormEvent) => {
    event.preventDefault();
    onSubmit();
  };

  const [
    getGroupUserInviteAcceptSession,
    {
      isLoading: isLoadingGroupUserInviteAcceptSession,
      data: userInviteAcceptData,
      isError: isGroupInvitationError,
    },
  ] = useGetGroupUserInviteAcceptSessionMutation();

  const onBlurEmail = async () => {
    const {
      email
    } = getValues();

    const trimmedEmail = email.trim();

    if (isLoadingGroupUserInviteAcceptSession) {
      return;
    }

    if (!trimmedEmail) {
      setValue(
        InputNames.EMAIL,
        ''
      );

      return;
    }

    try {
      const response = await getLoginExists(trimmedEmail).unwrap();

      if (!response.data.userExists) {
        setError(
          InputNames.EMAIL,
          {
            type: 'manual',
            message: t('errors.userNotFound'),
          }
        );
      }
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  const invitationData = useMemo(
    () => {
      return userInviteAcceptData
        ? {
          groupName: userInviteAcceptData.data.group.name,
          email: userInviteAcceptData.data.userEmail,
        }
        : {
          groupName: invitedGroupTitle,
          email: invitedEmail,
        };
    },
    [searchParams, userInviteAcceptData]
  );

  const handleGetInviteAccept = async () => {
    try {
      const response = await getGroupUserInviteAcceptSession().unwrap();

      if (response.invitationSession) {
        searchParams.set(
          invitedGroupName,
          response.data.group.name
        );

        if (response.data.userEmail) {
          searchParams.set(
            invitedUserEmail,
            response.data.userEmail
          );
        }
      }

      searchParams.delete(groupInvitation);

      setSearchParams(searchParams);
    } catch (error) {
      if (isErrorWithMessage(error)) {
        warningToast(error.data.message);

        navigate(
          `/${ROUTE.LOGIN}`,
          {
            replace: true,
          }
        );
      }
    }
  };

  useEffect(
    () => {
      if (isGroupInvitationStatus && !isLoadingGroupUserInviteAcceptSession) {
        handleGetInviteAccept();
      }
    },
    [isGroupInvitationStatus]
  );

  const handleCreateAccountClick = () => {
    const {
      email
    } = getValues();

    const trimmedEmail = email.trim();

    const isEmail = z.string().email().safeParse(trimmedEmail).success;

    if (isEmail) {
      dispatch(setUserEmail(trimmedEmail));
      dispatch(setUserName(''));
    } else {
      dispatch(setUserName(trimmedEmail));
      dispatch(setUserEmail(''));
    }

    if (isSocials) {
      navigate(`/${ROUTE.SOCIAL_CREATE_ACCOUNT}${search}`);
    } else {
      navigate(`/${ROUTE.CREATE_ACCOUNT}`);
    }
  };

  return (
    <Style.MainContainer>
      <Style.Title>{t('login.title')}</Style.Title>

      {isSocials && !isGroupInvitationError && (
        <Style.SocialsContainer>
          <LoginSocialsInvite
            email={invitationData.email}
            groupName={invitationData.groupName}
          />
        </Style.SocialsContainer>
      )}

      {isReverificationRequired && (
        <ToastBlockContainer
          isWarning
          text={t('verification.accountIsNotVerified')}
          fullWidth
        />
      )}

      {isReverification && (
        <ToastBlockContainer
          isWarning
          text={t('verification.loginToComplete')}
          fullWidth
        />
      )}

      <Style.Form
        onSubmit={handleSubmitClick}
        id={formId}
      >
        <Controller
          name={InputNames.EMAIL}
          control={control}
          render={({
            field
          }) => {
            const onChange = (value: string) => {
              if (isReverificationRequired) {
                setIsReverificationRequired(false);
              }

              field.onChange(value);
            };

            const onBlur = () => {
              field.onBlur();
              onBlurEmail();
            };

            return (
              <Input
                label={t('login.labelLogin')}
                value={field.value}
                onChange={onChange}
                errorMessage={errors[InputNames.EMAIL]?.message}
                onBlur={onBlur}
              />
            );
          }}
        />

        <Controller
          name={InputNames.PASSWORD}
          control={control}
          render={({
            field
          }) => {
            return (
              <Input
                label={t('common.password')}
                value={field.value}
                onChange={(e) => field.onChange(e)}
                type={InputType.PASSWORD}
                errorMessage={errors[InputNames.PASSWORD]?.message}
                onBlur={field.onBlur}
                placeholder={t('login.passwordPlaceholder')}
              />
            );
          }}
        />
      </Style.Form>

      <Style.ButtonsContainer>
        <SubmitButton
          title={t('common.logIn')}
          isLoading={isLoading}
          isDisabled={
            !!errors[InputNames.EMAIL] || !!errors[InputNames.PASSWORD]
          }
          onClick={onSubmit}
          formId="EnterPasswordForm"
        />

        {isReverificationRequired && (
          <Button
            variant="big-grey-bordered"
            className="font-bold text-17-26 md:text-xl text-dark-gray"
            onClick={onResend}
            disabled={!!remainingTime || isResendLoading}
          >
            {remainingTime ? (
              `${t('recoveryPage.wait')} ${remainingTime}`
            ) : (
              <>
                {t('verification.resendEmail')}

                {isResendLoading && (
                  <Spinner
                    size={24}
                    color={themeColors['dark-gray']}
                    centered={false}
                  />
                )}
              </>
            )}
          </Button>
        )}

        {!isReverificationRequired && (
          <Button
            variant="big-black"
            className="font-bold text-17-26 md:text-xl text-white"
            onClick={handleCreateAccountClick}
          >
            {t('login.createAccount')}
          </Button>
        )}
      </Style.ButtonsContainer>
    </Style.MainContainer>
  );
};
