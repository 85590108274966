import {
  Achievements, LockedAchievements
} from 'src/redux/openapi';

export const CALIBRATED_TYPE = 'CALIBRATED';

export const findCalibratedBadge = <T extends { type: string }>(
  badges: T[] | null,
) => {
  return badges?.find((badge) => badge.type === CALIBRATED_TYPE);
};

export const getCalibratedBadge = ({
  achievements,
  lockedAchievements,
  language,
}: {
  achievements: Achievements;
  lockedAchievements: LockedAchievements;
  language: string;
}) => {
  const achievedBadge = findCalibratedBadge(achievements);
  const lockedBadge = findCalibratedBadge(lockedAchievements);

  const badgeData = achievedBadge || lockedBadge;

  if (!badgeData) {
    return null;
  }

  const {
    type, updatedAt
  } = badgeData;

  const description = 'achievement' in badgeData
    ? {
      ...badgeData.achievement,
    }
    : {
      description: badgeData.description,
      descriptionDe: badgeData.descriptionDe,
    };

  return {
    type,
    description:
      language === 'en' ? description.description : description.descriptionDe,
    updatedAt,
    isAchieved: !lockedBadge,
  };
};
