import React, {
  useMemo
} from 'react';
import {
  useSearchParams
} from 'react-router-dom';

import {
  DiscussionSection, PageLayout
} from 'src/widgets';
import {
  questionId,
  groupId,
  getSortByValue,
  followings,
} from 'src/shared/utils';
import {
  useGetCommunitySetsQuery,
  useGetGroupsCommunityQuery,
  useGetQuestionQuery,
} from 'src/redux/openapi';
import {
  useTypedSelector
} from 'src/redux';
import {
  commentsFilterSelectors
} from 'src/redux/commentsFilters';
import {
  CommunityFollowerSection
} from 'src/widgets/Sections/CommunityCommentsSection';

import * as Style from './Community.styles';
import {
  ActiveQuestionCommunity
} from './ActiveQuestionCommunity';

export const Community = () => {
  const [searchParams] = useSearchParams();

  const activeFilterId = searchParams.get(groupId);
  const isFollowingsPage = searchParams.get(followings);

  const sortBy = useTypedSelector(
    commentsFilterSelectors.selectCommunitySortBy,
  );

  const sortByValue = getSortByValue(sortBy);

  const {
    data: questionSetsData, isLoading: isSetsLoading
  } = useGetCommunitySetsQuery(
    {
      limit: 5000,
      filters: {
        sortBy: sortByValue,
        groupId: activeFilterId || undefined,
      },
    },
    {
      refetchOnMountOrArgChange: true,
      pollingInterval: 20000,
      skip: !!isFollowingsPage,
    },
  );

  const questionSets = useMemo(
    () => questionSetsData?.data.questionSets || [],
    [questionSetsData],
  );

  const {
    data: groupsData
  } = useGetGroupsCommunityQuery(
    {
      limit: 5000,
    },
    {
      skip: !!isFollowingsPage,
    },
  );

  const groups = useMemo(
    () => groupsData?.data.groups || [],
    [groupsData]
  );

  const activeQuestionId = searchParams.get(questionId) || null;

  const {
    data: questionsData
  } = useGetQuestionQuery(
    {
      id: activeQuestionId || '',
    },
    {
      skip: !activeQuestionId || !!isFollowingsPage,
      refetchOnMountOrArgChange: true,
    },
  );

  const activeQuestion = useMemo(
    () => {
      return isFollowingsPage || !activeQuestionId
        ? null
        : questionsData?.data.question || null;
    },
    [searchParams, questionsData, isFollowingsPage]
  );

  return (
    <Style.PageContainer>
      <PageLayout>
        <Style.MainContainer>
          <DiscussionSection
            questionSets={questionSets}
            isSetsLoading={isSetsLoading}
            sortBy={sortByValue}
            groups={groups}
          />

          {isFollowingsPage ? (
            <CommunityFollowerSection />
          ) : (
            <ActiveQuestionCommunity
              activeQuestion={activeQuestion}
              isNoGroups={!groups.length}
              isQuestionSets={!!questionSets.length}
              isSetsLoading={isSetsLoading}
            />
          )}
        </Style.MainContainer>
      </PageLayout>
    </Style.PageContainer>
  );
};
