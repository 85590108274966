import React, {
  FC
} from 'react';
import {
  DropdownIndicatorProps
} from 'react-select';
import {
  clsx
} from 'clsx';

import {
  ArrowDownIcon
} from 'src/shared/icons';

export const DropdownIndicator: FC<DropdownIndicatorProps> = ({
  selectProps,
  className,
}) => {
  return (
    <ArrowDownIcon
      className={clsx(
        'h-6 w-6 translate-all',
        {
          'rotate-180': selectProps.menuIsOpen,
        },
        className,
      )}
    />
  );
};
