import {
  useEffect, useState
} from 'react';

export const useDebouncedValue = <V = any>(value: V, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      const handler = setTimeout(
        () => setDebouncedValue(value),
        delay
      );

      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay]
  );

  return debouncedValue;
};
