import React, {
  FC
} from 'react';
import clsx from 'clsx';
import {
  useTranslation
} from 'react-i18next';

import {
  ValueController, Slider
} from 'src/shared/components';

type MultiChoiceChange = (value: number) => void;

interface SliderBlockProps {
  value: number;
  setValue: React.Dispatch<React.SetStateAction<number>> | MultiChoiceChange;
  isSingle?: boolean;
  defaultText?: boolean;
  text?: string;
  colorValue?: string;
  variant?: 'big' | 'small';
  formattedValue?: string;
  valueDescription?: string;
  minValue?: number;
  maxValue?: number;
  step?: number;
}

export const SliderBlock: FC<SliderBlockProps> = ({
  value,
  setValue,
  isSingle,
  defaultText = false,
  text,
  colorValue,
  variant = 'big',
  formattedValue,
  valueDescription,
  minValue = 0,
  maxValue = 100,
  step = 1,
}) => {
  const {
    t
  } = useTranslation();

  return (
    <div>
      <div className="flex flex-row justify-between items-center mb-4">
        <div
          className={clsx(
            'font-medium',
            {
              'text-dim-gray text-17-26': isSingle && defaultText,
              'text-gray7 text-base': !isSingle && defaultText,
              'text-15-20 text-gray7': text,
            }
          )}
        >
          {isSingle && defaultText && t('question.setProbability')}

          {!isSingle && defaultText && t('question.choosePossibility')}

          {text && (
            <div className="flex flex-row gap-2 items-center">
              {colorValue && (
                <div
                  className="w-4 h-4 rounded-full"
                  style={{
                    backgroundColor: colorValue,
                  }}
                />
              )}

              <div>{text}</div>
            </div>
          )}
        </div>

        <div>
          <ValueController
            value={value}
            onValueChange={setValue}
            variant={variant}
            formattedValue={formattedValue}
            valueDescription={valueDescription}
            step={step}
          />
        </div>
      </div>

      <div>
        <div className="mb-6">
          <Slider
            min={minValue}
            max={maxValue}
            value={value}
            onChange={setValue}
            variant="dot"
            size={variant}
            step={step}
          />
        </div>
      </div>
    </div>
  );
};
