import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import * as Style from './AccountSettingsTabs.style';

export enum ProfileSettingsTabs {
  Public_Info = 'publicInfo',
  Security = 'security',
  Emails = 'emailsPreferences',
  Characteristics = 'Characteristics',
}

interface AccountSettingsTabsProps {
  setActiveSettingsTab: (label: ProfileSettingsTabs) => void;
  activeSettingsTab: ProfileSettingsTabs;
  settingsVariants: ProfileSettingsTabs[];
}

export const AccountSettingsTabs: FC<AccountSettingsTabsProps> = ({
  setActiveSettingsTab,
  activeSettingsTab,
  settingsVariants,
}) => {
  const {
    t
  } = useTranslation();

  return (
    <Style.ModalTabsContainer>
      <Style.ModalTabsRow>
        {settingsVariants.map((label) => (
          <Style.ModalTabButton
            key={label}
            type="button"
            onClick={() => setActiveSettingsTab(label)}
          >
            <Style.ModalTabLabel $isActive={label === activeSettingsTab}>
              {t(`editAccount.${label}`)}
            </Style.ModalTabLabel>

            <Style.ModalTabBottomLine $isActive={label === activeSettingsTab} />
          </Style.ModalTabButton>
        ))}
      </Style.ModalTabsRow>
    </Style.ModalTabsContainer>
  );
};
