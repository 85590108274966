import React, {
  FC
} from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import {
  useParams, useSearchParams
} from 'react-router-dom';
import i18next from 'i18next';

import {
  MessageIcon
} from 'src/shared/icons';
import {
  ScrollContainer, Spinner
} from 'src/shared/components';
import {
  FilterDateType
} from 'src/widgets/CommentsFilterContext/types';
import {
  useTypedSelector
} from 'src/redux';
import {
  commentsFilterSelectors
} from 'src/redux/commentsFilters';
import {
  formatDateValue
} from 'src/widgets/CommentsFilterContext/utils';
import {
  commentsListSelectors
} from 'src/redux/commentsList';
import {
  useGetComments, useGetPollingComments
} from 'src/shared/hooks';
import {
  getCommentsTimeRange, groupId, questionId
} from 'src/shared/utils';

import {
  CommentListItem
} from '../CommentListItem';

import * as Style from './CommentList.styles';

interface CommentListProps {}

export const CommentList: FC<CommentListProps> = () => {
  const [searchParams] = useSearchParams();

  const {
    loadNextPage: loadPollingNextPage,
    isLoadingComments: isLoadingPollingComments,
    isLoadingMoreComments: isLoadingPollingMoreComments,
    refetch: refetchPollingComments,
  } = useGetPollingComments(true);

  const comments = useTypedSelector(commentsListSelectors.selectCommentsList);

  const {
    data,
    isLoading: isLoadingCachedComments,
    loadNextPage: loadCachedNextPage,
    isLoadingMoreComments: isLoadingCachedMoreComments,
    refetch,
    total: totalCachedComments,
  } = useGetComments();

  const activeQuestionId = searchParams.get(questionId) || null;
  const groupIdSearch = searchParams.get(groupId);
  const params = useParams();
  const questionIdToSearch = params.id || activeQuestionId;

  const isQuestionQuery = questionIdToSearch && !groupIdSearch;

  const commentsList = isQuestionQuery ? data : comments;
  const refetchComments = isQuestionQuery ? refetch : refetchPollingComments;

  const loadNextPage = isQuestionQuery
    ? loadCachedNextPage
    : loadPollingNextPage;

  const isLoadingComments = isQuestionQuery
    ? isLoadingCachedComments
    : isLoadingPollingComments;

  const isLoadingMoreComments = isQuestionQuery
    ? isLoadingCachedMoreComments
    : isLoadingPollingMoreComments;

  const timeRange = useTypedSelector(
    commentsFilterSelectors.selectFeedCommentsTimeRange,
  );

  const selectedDateRange = useTypedSelector(
    commentsFilterSelectors.selectFeedSelectedDateRange,
  );

  const {
    language
  } = i18next;

  const formattedDateRange = formatDateValue(
    selectedDateRange,
    language
  );

  const commentsStateTotal = useTypedSelector(
    commentsListSelectors.selectCommentsListTotal,
  );

  const commentsTotal = isQuestionQuery
    ? totalCachedComments
    : commentsStateTotal;

  const isLoading = isLoadingComments || isLoadingMoreComments;

  const loadNextPageHandler = () => {
    if (!isLoading) {
      loadNextPage();
    }
  };

  return (
    <>
      <Style.CommentsAmount>
        {timeRange !== FilterDateType.ALL_TIME && (
          <>
            {commentsTotal}

            <MessageIcon
              width={20}
              height={20}
            />
          </>
        )}

        {timeRange === FilterDateType.CUSTOM
          ? formattedDateRange
          : getCommentsTimeRange(timeRange)}
      </Style.CommentsAmount>

      <ScrollContainer className="flex-1">
        <InfiniteScroll
          pageStart={1}
          loadMore={loadNextPageHandler}
          hasMore={commentsList.length < commentsTotal}
          loader={(
            <Spinner
              size={24}
              key="spinner"
            />
          )}
          useWindow={false}
        >
          {commentsList.map((comment) => (
            <CommentListItem
              comment={comment}
              key={comment.id}
              refetch={refetchComments}
              groupRemoveAccess={!!comment.groupRemoveAccess}
            />
          ))}
        </InfiniteScroll>
      </ScrollContainer>
    </>
  );
};
