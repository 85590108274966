import React, {
  FC
} from 'react';
import {
  DroppableProvided
} from 'react-beautiful-dnd';
import {
  Coordinate
} from 'recharts/types/util/types';
import {
  useTranslation
} from 'react-i18next';

import {
  UserListItem
} from 'src/redux/organizations';

import {
  DraggableUserCard
} from '../DraggableUserCard';

import * as Style from './UsersCardList.style';

interface UsersCardListProps {
  allOrgUsers: UserListItem[];
  selectedUsers: UserListItem[];
  handleSelectUser: (newUser: UserListItem) => void;
  provided: DroppableProvided;
  isActiveDrag: boolean;
  cursorPosition: Coordinate | null;
}

export const UsersCardList: FC<UsersCardListProps> = ({
  allOrgUsers,
  selectedUsers,
  handleSelectUser,
  provided,
  isActiveDrag,
  cursorPosition,
}) => {
  const {
    t
  } = useTranslation();

  return (
    <Style.Container>
      <Style.EmptyMessage>
        {!allOrgUsers.length && t('empty.noMembersFound')}
      </Style.EmptyMessage>

      {allOrgUsers.map((user, i) => {
        const isSelected = selectedUsers.some(
          (selectedUser) => selectedUser.email === user.email,
        );

        return (
          <DraggableUserCard
            key={user.email}
            handleSelectUser={handleSelectUser}
            index={i}
            isSelected={isSelected}
            user={user}
            count={selectedUsers.length}
            isActiveDrag={isActiveDrag}
            cursorPosition={cursorPosition}
          />
        );
      })}

      {provided.placeholder}
    </Style.Container>
  );
};
