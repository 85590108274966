import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const Head = styled.div`
  ${tw`flex gap-2 items-center`}
`;

export const HeadTitle = styled.p`
  ${tw`font-bold text-28-36 text-gray7 grow`}
`;

export const ButtonsContainer = styled.div`
  ${tw`flex justify-end gap-3 pt-5`}
`;
