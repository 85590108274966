import React, {
  FC, useEffect
} from 'react';
import {
  Trans, useTranslation
} from 'react-i18next';
import {
  useLocation, useNavigate
} from 'react-router-dom';

import {
  Button, Spinner, successfulToast
} from 'src/shared/components';
import {
  useGetVerificationsResendCountdownQuery,
  usePostVerificationsResendMutation,
} from 'src/redux/openapi';
import {
  ROUTE, showWarningFromServer
} from 'src/shared/utils';
import {
  useTypedSelector
} from 'src/redux';
import {
  useGetRemainingTime
} from 'src/shared/hooks';
import {
  verificationSelectors
} from 'src/redux/verification';

import {
  Title
} from '../Login.styles';

import * as Style from './EmailVerification.styles';

export const EmailVerification: FC = () => {
  const {
    t
  } = useTranslation();

  const {
    secondsLeft, refetch
  } = useGetVerificationsResendCountdownQuery(
    undefined,
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({
        data
      }) => ({
        secondsLeft: data?.data.seconds || 0,
      }),
    },
  );

  const navigate = useNavigate();
  const location = useLocation();

  const {
    startCountdown, remainingTime, stopCountdown
  } = useGetRemainingTime({
    initialSeconds: secondsLeft,
  });

  const email = useTypedSelector(verificationSelectors.emailToVerify);

  useEffect(
    () => {
      if (location.state?.from === `/${ROUTE.CREATE_ACCOUNT}` && secondsLeft) {
        startCountdown();
      }

      return () => {
        stopCountdown();
      };
    },
    [secondsLeft]
  );

  useEffect(
    () => {
      if (!email) {
        navigate(`${ROUTE.LOGIN}`);
      }
    },
    []
  );

  const [resendEmail, {
    isLoading
  }] = usePostVerificationsResendMutation();

  const onResend = async () => {
    try {
      const response = await resendEmail().unwrap();

      refetch();
      successfulToast(response.message);
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  return (
    <Style.ContentContainer>
      <Title>{t('verification.emailVerification')}</Title>

      <Style.Message>
        <Trans
          i18nKey="verification.verificationEmailMessage"
          values={{
            email,
          }}
          components={{
            1: <Style.EmailSpan />,
          }}
        />
      </Style.Message>

      <Button
        variant="big-blue"
        onClick={onResend}
        className="mt-8 font-bold text-17-26 md:text-xl"
        disabled={isLoading || !!remainingTime}
      >
        {remainingTime ? (
          `${t('recoveryPage.wait')} ${remainingTime}`
        ) : (
          <>
            {t('verification.resendConfirmationEmail')}

            {isLoading && (
              <Spinner
                size={24}
                color="inherit"
                centered={false}
              />
            )}
          </>
        )}
      </Button>
    </Style.ContentContainer>
  );
};
