import React, {
  FC
} from 'react';

import {
  LoaderScreen
} from 'src/shared/components';
import {
  PageLayout
} from 'src/widgets';

export const LoaderPage: FC = () => {
  return (
    <PageLayout>
      <LoaderScreen />
    </PageLayout>
  );
};
