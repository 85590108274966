import React, {
  FC, useEffect, useMemo, useRef, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  AdminUpdateOrganizationBody,
  useGetAdminOrganizationQuery,
  useGetAdminOrganizationsQuery,
  useGetAdminRequestsQuery,
  useGetOrgRequestsUnreadCountQuery,
  usePutAdminOrganizationMutation,
  useGetAdminGroupsQuery,
} from 'src/redux/openapi';
import {
  SearchInput, Spinner, successfulToast
} from 'src/shared/components';
import {
  useDebouncedValue
} from 'src/shared/hooks';
import {
  useTypedSelector
} from 'src/redux';
import {
  languageSelectors
} from 'src/redux/language';
import {
  showWarningFromServer
} from 'src/shared/utils';

import {
  CreateOrganizationModal,
  DeleteGroupModal,
  DeleteOrganizationModal,
  DeleteOrgRequestModal,
  EditGroupAdminModal,
} from '../Modals';

import * as Style from './OrgManagementTable.styles';
import {
  getGroupCols,
  getGroupRequestsCols,
  getOrganizationCols,
  getTabsMap,
} from './utils';
import {
  EditMenuActions, SelectOption, Tabs
} from './types';
import {
  TableFooter,
  selectOptions,
  ManagementTableContent,
  RequestModal,
} from './ui';

interface OrgManagementTableProps {
  shouldRefetchOrg: boolean;
  toggleRefetchOrg: () => void;
}

export const OrgManagementTable: FC<OrgManagementTableProps> = ({
  shouldRefetchOrg,
  toggleRefetchOrg,
}) => {
  const language = useTypedSelector(languageSelectors.getLanguage);

  const [currentAction, setCurrentAction] = useState<EditMenuActions | null>(
    null,
  );

  const [idToModify, setIdToModify] = useState<string | null>(null);
  const [currentTab, setCurrentTab] = useState(Tabs.AllOrganizations);
  const [searchQuery, setSearchQuery] = useState('');
  const [maxTableHeight, setMaxTableHeight] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(selectOptions[0]);

  const contentRef = useRef<HTMLDivElement | null>(null);

  const isOrganizations = currentTab === Tabs.AllOrganizations;
  const isGroupsRequests = currentTab === Tabs.GroupsRequests;
  const isGroups = currentTab === Tabs.UnaffiliatedGroups;

  const removeAction = () => {
    setCurrentAction(null);
    setIdToModify(null);
  };

  const debouncedQuery = useDebouncedValue(
    searchQuery,
    500
  );

  useEffect(
    () => {
      setCurrentPage(1);
    },
    [currentTab, debouncedQuery]
  );

  const handleSearch = (value: string) => {
    setSearchQuery(value);
  };

  const onPageSizeChange = (value: SelectOption | null) => {
    if (!value) {
      return;
    }

    setPageSize(value);
    setCurrentPage(1);
  };

  const decreaseCurrentPage = () => setCurrentPage((prev) => prev - 1);

  const {
    data: organizationsData,
    refetch: refetchOrganizations,
    isLoading: isOrgDataLoading,
  } = useGetAdminOrganizationsQuery(
    {
      filters: {
        query: debouncedQuery,
        status: 'ACCEPTED',
      },
      limit: pageSize.value,
      page: currentPage,
    },
    {
      skip: !isOrganizations,
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    total: totalOrganizations, organizations
  } = useMemo(
    () => organizationsData?.data || {
      organizations: [],
      total: 0,
    },
    [organizationsData],
  );

  useEffect(
    () => {
      if (!organizations.length && currentPage !== 1 && isOrganizations) {
        decreaseCurrentPage();
      }
    },
    [organizations.length]
  );

  const {
    data: groupsRequestsData,
    refetch: refetchGroupsRequestsData,
    isLoading: isGroupsRequestsLoading,
  } = useGetAdminRequestsQuery(
    {
      filters: {
        query: debouncedQuery,
      },
      limit: pageSize.value,
      page: currentPage,
    },
    {
      skip: !isGroupsRequests,
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    total: totalRequests, groupRequests: requests
  } = useMemo(
    () => groupsRequestsData?.data || {
      groupRequests: [],
      total: 0,
    },
    [groupsRequestsData],
  );

  useEffect(
    () => {
      if (!requests.length && currentPage !== 1 && isGroupsRequests) {
        decreaseCurrentPage();
      }
    },
    [requests.length]
  );

  const {
    data: unaffiliatedGroupsData,
    refetch: refetchUnaffiliatedGroupsData,
    isLoading: isGroupsUnaffiliatedLoading,
  } = useGetAdminGroupsQuery(
    {
      query: debouncedQuery,
      limit: pageSize.value,
      page: currentPage,
    },
    {
      skip: !isGroups,
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    total: totalGroups, groups
  } = useMemo(
    () => unaffiliatedGroupsData?.data || {
      groups: [],
      total: 0,
    },
    [unaffiliatedGroupsData],
  );

  useEffect(
    () => {
      if (!groups.length && currentPage !== 1 && isGroups) {
        decreaseCurrentPage();
      }
    },
    [groups.length]
  );

  const isLoading = isGroupsRequestsLoading || isOrgDataLoading || isGroupsUnaffiliatedLoading;

  const total = useMemo(
    () => {
      if (isOrganizations) {
        return totalOrganizations;
      }

      if (isGroupsRequests) {
        return totalRequests;
      }

      if (isGroups) {
        return totalGroups;
      }

      return 0;
    },
    [totalOrganizations, totalRequests, totalGroups, currentTab]
  );

  const onAction = (id: string, action: EditMenuActions) => {
    setIdToModify(id);
    setCurrentAction(action);
  };

  const {
    data: requestsCountData, refetch: refetchRequestsCount
  } = useGetOrgRequestsUnreadCountQuery(
    undefined,
    {
      pollingInterval: 10000,
    }
  );

  const requestsCount = requestsCountData?.data.total;

  useEffect(
    () => {
      if (isGroupsRequests) {
        refetchGroupsRequestsData();
      }
    },
    [requestsCount]
  );

  useEffect(
    () => {
      if (shouldRefetchOrg && isOrganizations) {
        toggleRefetchOrg();
        refetchOrganizations();
      }
    },
    [shouldRefetchOrg, isOrganizations]
  );

  const onTabChange = (tabId: Tabs) => {
    setCurrentTab(tabId);
    refetchRequestsCount();
  };

  const organizationColumns = getOrganizationCols(
    onAction,
    language
  );

  const groupRequestsColumns = getGroupRequestsCols(
    onAction,
    language
  );

  const groupColumns = getGroupCols(
    onAction,
    language
  );

  const onGroupClick = (id: string) => {
    setIdToModify(id);
    setCurrentAction(EditMenuActions.Request);
  };

  const calculateMaxHeight = () => {
    if (contentRef?.current && window) {
      const {
        top
      } = contentRef.current.getBoundingClientRect();

      const distance = window.innerHeight - top - 24;
      setMaxTableHeight(distance);
    }
  };

  useEffect(
    () => {
      calculateMaxHeight();

      window.addEventListener(
        'resize',
        calculateMaxHeight
      );

      return () => {
        window.removeEventListener(
          'resize',
          calculateMaxHeight
        );
      };
    },
    []
  );

  const tabs = getTabsMap();

  const {
    t
  } = useTranslation();

  const {
    data: orgToEditData
  } = useGetAdminOrganizationQuery(
    {
      id: idToModify || '',
    },
    {
      skip: currentAction !== EditMenuActions.EditOrganisation || !idToModify,
      refetchOnMountOrArgChange: true,
    },
  );

  const organizationToEdit = useMemo(
    () => {
      const organization = orgToEditData?.data.organization;

      return {
        ...organization,
        admins:
        organization?.users.map(({
          role, user
        }) => ({
          role,
          email: user.email,
        })) || [],
      };
    },
    [orgToEditData?.data.organization]
  );

  const [saveEditResult] = usePutAdminOrganizationMutation();

  const onEditEnd = async (
    updatedOrganization?: AdminUpdateOrganizationBody,
  ) => {
    if (!updatedOrganization) {
      removeAction();

      return;
    }

    try {
      const response = await saveEditResult({
        id: idToModify || '',
        adminUpdateOrganizationByIdBodySchema: {
          ...updatedOrganization,
        },
      }).unwrap();

      successfulToast(response.message);
      removeAction();
      toggleRefetchOrg();
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  return (
    <Style.TableContentWrapper
      ref={contentRef}
      style={{
        maxHeight: `${maxTableHeight}px`,
      }}
    >
      <Style.TableHeader>
        <Style.Tabs>
          {tabs.map((tab) => (
            <Style.Tab
              key={tab.id}
              onClick={() => onTabChange(tab.id)}
              $isActive={tab.id === currentTab}
            >
              {tab.label}

              {tab.id === Tabs.GroupsRequests && !!requestsCount && (
                <Style.TabInfo>{requestsCount}</Style.TabInfo>
              )}
            </Style.Tab>
          ))}
        </Style.Tabs>

        <Style.SearchWrapper>
          <SearchInput
            value={searchQuery}
            onChange={handleSearch}
            placeholder={`${t(
              'filters.searchByNameOrAdmins',
              {
                entity: isOrganizations
                  ? t('common.organisation').toLowerCase()
                  : t('common.group').toLowerCase(),
              }
            )}...`}
            size={32}
            withBorder={false}
            shouldGrow
          />
        </Style.SearchWrapper>
      </Style.TableHeader>

      {isLoading ? (
        <Spinner withLoadingText />
      ) : (
        <>
          {isOrganizations && (
            <ManagementTableContent
              data={organizations}
              columns={organizationColumns}
            />
          )}

          {isGroupsRequests && (
            <ManagementTableContent
              data={requests}
              columns={groupRequestsColumns}
              onRowClick={onGroupClick}
            />
          )}

          {isGroups && (
            <ManagementTableContent
              data={groups}
              columns={groupColumns}
            />
          )}
        </>
      )}

      <TableFooter
        totalCount={total}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
        onPageSizeChange={onPageSizeChange}
        isOrganization={isOrganizations}
      />

      <DeleteOrganizationModal
        isOpen={currentAction === EditMenuActions.DeleteOrg}
        closeModal={removeAction}
        id={idToModify}
        onSuccessDelete={refetchOrganizations}
      />

      <DeleteOrgRequestModal
        isOpen={currentAction === EditMenuActions.DeleteRequest}
        closeModal={removeAction}
        id={idToModify}
        onSuccessDelete={refetchGroupsRequestsData}
      />

      <RequestModal
        isOpen={currentAction === EditMenuActions.Request}
        closeModal={removeAction}
        id={idToModify || ''}
        refetch={refetchGroupsRequestsData}
        onAcceptClick={() => setCurrentAction(null)}
      />

      <CreateOrganizationModal
        isOpen={currentAction === EditMenuActions.EditOrganisation}
        onClose={removeAction}
        isEditing
        name={organizationToEdit?.name}
        admins={organizationToEdit.admins}
        orgDomain={organizationToEdit?.domain}
        customCreateMethod={onEditEnd}
      />

      <EditGroupAdminModal
        isOpen={currentAction === EditMenuActions.EditGroup}
        onClose={removeAction}
        groupId={idToModify || ''}
        onSuccess={refetchUnaffiliatedGroupsData}
      />

      <DeleteGroupModal
        isOpen={currentAction === EditMenuActions.DeleteGroup}
        closeModal={removeAction}
        id={idToModify || ''}
        onSuccessDelete={refetchUnaffiliatedGroupsData}
      />
    </Style.TableContentWrapper>
  );
};
