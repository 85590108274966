import React, {
  FC
} from 'react';

import {
  StarFilledIcon, StarIcon
} from 'src/shared/icons';

interface StarProps {
  isFilled: boolean;
}

export const Star: FC<StarProps> = ({
  isFilled
}) => {
  return isFilled ? (
    <StarFilledIcon className="text-button-blue" />
  ) : (
    <StarIcon className="text-dim-gray" />
  );
};
