import {
  t
} from 'i18next';
import {
  FunctionComponent
} from 'react';

import {
  EditPencilIcon,
  MergeIcon,
  SplitIcon,
  TrashDeleteIcon,
  PowerIcon,
} from 'src/shared/icons';
import {
  themeColors
} from 'src/shared/utils';

export enum GroupAction {
  CreateFromGroup,
  MergeGroups,
  EditGroup,
  DeleteGroup,
  DeactivateGroup,
  ActivateGroup,
}

interface MenuItem {
  icon: FunctionComponent<React.SVGProps<SVGSVGElement>>;
  label: string;
  id: GroupAction;
  color?: string;
  withDivider?: boolean;
}

export const getMenuMap = ({
  activeUsersCount,
  isDeactivated,
  isPublic,
}: {
  activeUsersCount: number;
  isDeactivated?: boolean;
  isPublic: boolean | null;
}): MenuItem[] => {
  const defaultItems = [
    {
      icon: EditPencilIcon,
      label: t('group.editGroup'),
      id: GroupAction.EditGroup,
    },
  ];

  const createFromGroup = {
    icon: SplitIcon,
    label: t('organisation.createFromGroup'),
    id: GroupAction.CreateFromGroup,
  };

  const mergeGroups = {
    icon: MergeIcon,
    label: t('organisation.mergeGroups'),
    id: GroupAction.MergeGroups,
  };

  const activeOnly = [
    {
      icon: PowerIcon,
      label: t('group.deactivateGroup'),
      withDivider: true,
      id: GroupAction.DeactivateGroup,
      color: themeColors['red-text-error'],
    },
  ];

  const deactivatedOnly = [
    {
      icon: PowerIcon,
      label: t('group.activateGroup'),
      id: GroupAction.ActivateGroup,
      color: themeColors['button-blue'],
    },
    {
      icon: TrashDeleteIcon,
      label: t('group.deleteGroup'),
      withDivider: true,
      id: GroupAction.DeleteGroup,
      color: themeColors['red-text-error'],
    },
  ];

  const externalItemsToShow = isDeactivated ? deactivatedOnly : activeOnly;

  const defaultMenu = [...defaultItems, ...externalItemsToShow];

  if (!activeUsersCount) {
    return defaultMenu;
  }

  const withActiveUsersOnly = isDeactivated || isPublic
    ? [createFromGroup]
    : [createFromGroup, mergeGroups];

  return [...withActiveUsersOnly, ...defaultMenu];
};
