import React, {
  FC
} from 'react';

import {
  CloseIcon
} from 'src/shared/icons';
import {
  useDeleteGroupUsersInvitesMutation,
  GroupInvitedPerson,
} from 'src/redux/openapi';
import {
  Spinner, successfulToast
} from 'src/shared/components';
import {
  showWarningFromServer, themeColors
} from 'src/shared/utils';

import {
  getTimeAgo
} from './utils';
import * as Style from './InvitedPeopleList.styles';

interface InvitedPeopleListItemProps {
  invitedPerson: GroupInvitedPerson;
  index: number;
  currentTime: number;
  groupId: string;
  onInvitationRemove: () => void;
}

export const InvitedPeopleListItem: FC<InvitedPeopleListItemProps> = ({
  invitedPerson,
  index,
  currentTime,
  groupId,
  onInvitationRemove,
}) => {
  const {
    email, sentAt: time, id
  } = invitedPerson;

  const timeAgo = getTimeAgo(
    currentTime,
    Date.parse(time)
  );

  const [deleteUserInvitation, {
    isLoading
  }] = useDeleteGroupUsersInvitesMutation();

  const removeUserInvitation = async (inviteId: string) => {
    try {
      const response = await deleteUserInvitation({
        id: groupId,
        userId: inviteId,
      }).unwrap();

      successfulToast(response.message);

      onInvitationRemove();
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  const onRemove = () => {
    if (id) {
      removeUserInvitation(id);
    }
  };

  return (
    <Style.ListItem key={email}>
      <p>{`${index}.`}</p>

      <Style.Email>{email}</Style.Email>

      <Style.RightElement>
        <p>{`Sent ${timeAgo}`}</p>

        {isLoading ? (
          <Spinner
            color={themeColors['dim-gray']}
            size={24}
            centered={false}
          />
        ) : (
          <button
            type="button"
            onClick={onRemove}
          >
            <CloseIcon className="text-dim-gray w-6 h-6" />
          </button>
        )}
      </Style.RightElement>
    </Style.ListItem>
  );
};
