import React, {
  FC, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  PopoverMenu
} from 'src/shared/components';
import {
  RadialСheckFalseIcon, RadialСheckTrueIcon
} from 'src/shared/icons';
import {
  useTypedDispatch, useTypedSelector
} from 'src/redux';
import {
  setActiveTimeSpan, timeSpanSelectors
} from 'src/redux/timeSpans';
import {
  useMediaQuery
} from 'src/shared/hooks';

import * as Styles from './Selects.styles';
import {
  SelectButton
} from './SelectButton';

export const SelectTimeSpan: FC = () => {
  const dispatch = useTypedDispatch();
  const timeSpanChoices = useTypedSelector(timeSpanSelectors.selectAll);

  const timeSpanSelected = useTypedSelector(
    timeSpanSelectors.selectActiveTimeSpan,
  );

  const [shouldClose, setShouldClose] = useState(false);

  const handleSelectOption = (val: string) => {
    dispatch(setActiveTimeSpan(val));
    setShouldClose(true);
  };

  const handleButtonClick = (toggle: () => void) => {
    if (shouldClose) {
      setShouldClose(false);
    }

    toggle();
  };

  const {
    t
  } = useTranslation();

  const isMobile = useMediaQuery(
    'md',
    'down'
  );

  return (
    <PopoverMenu
      placement={isMobile ? 'bottom-end' : 'bottom-start'}
      shouldForcedClose={shouldClose}
      renderCustomButton={({
        isOpen, toggleMenu, triggerProps
      }) => (
        <SelectButton
          isOpen={isOpen}
          onButtonClick={() => handleButtonClick(toggleMenu)}
          title={t(`select.${timeSpanSelected.value}`)}
          isSelectedValue={!!timeSpanChoices.length}
          onClear={() => {}}
          {...triggerProps}
        />
      )}
    >
      <Styles.MenuContainer>
        <Styles.MenuTitle>{t('select.chooseForecastRange')}</Styles.MenuTitle>

        {timeSpanChoices.map((item) => (
          <Styles.MenuOption
            key={item.value}
            type="button"
            onClick={() => handleSelectOption(item.value)}
          >
            {t(`select.${item.value}`)}

            <div>
              {item.isSelected ? (
                <RadialСheckTrueIcon />
              ) : (
                <RadialСheckFalseIcon />
              )}
            </div>
          </Styles.MenuOption>
        ))}
      </Styles.MenuContainer>
    </PopoverMenu>
  );
};
