import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const SectionHeader = styled.div`
  ${tw`flex items-center justify-between gap-4 px-4 py-3 h-68px border border-gray94`}
`;

export const Section = styled.div<{ $withSearch: boolean }>`
  ${tw`grid max-h-full grid-rows-[68px,minmax(200px,1fr),87px] grid-cols-[minmax(0,1fr)]`}

  ${({
    $withSearch
  }) => $withSearch && tw`grid-rows-[68px,70px,minmax(200px,1fr),87px]`}
`;
export const SectionWide = styled.div<{ $withSearch: boolean }>`
  ${tw`grid max-h-full grid-rows-[68px,minmax(200px,1fr)] grid-cols-[minmax(0,1fr)]`}

  ${({
    $withSearch
  }) => $withSearch && tw`grid-rows-[68px,70px,minmax(200px,1fr)]`}
`;

export const Comments = styled.div`
  ${tw`bg-pale-blue px-6 grow relative`}
`;

export const CommentsContainer = styled.div`
  ${tw`my-4 flex flex-col-reverse`}
`;

export const CommentContainer = styled.div<{
  $isCurrentUserComment: boolean;
  $withShift: boolean;
  $topRounded: boolean;
  $bottomRounded: boolean;
  $isFullWidth?: boolean;
}>`
  ${tw`bg-white`}

  ${({
    $isCurrentUserComment
  }) => $isCurrentUserComment && tw`my-4 rounded`}

  ${({
    $isFullWidth
  }) => ($isFullWidth ? tw`w-full` : tw`w-676px`)}

  ${({
    $withShift
  }) => $withShift && tw`ml-auto`}

  ${({
    $topRounded
  }) => $topRounded && tw`rounded-t`}

  ${({
    $bottomRounded
  }) => $bottomRounded && tw`rounded-b`}
`;

export const SelectorsContainer = styled.div`
  ${tw`flex items-center gap-4 shrink-0`}
`;

export const SendCommentContainer = styled.div`
  ${tw`p-3`}
`;

export const EmptyMessage = styled.p`
  ${tw`text-17 font-medium text-dim-gray h-full flex justify-center items-center`}
`;

export const ButtonsContainer = styled.div`
  ${tw`flex justify-between items-center gap-4 shrink-0`}
`;

export const DatesTitle = styled.div`
  ${tw`text-base font-medium text-gray7`}
`;

export const MyOnlyLabel = styled.label`
  ${tw`px-2 py-3 flex gap-2 items-center justify-between cursor-pointer mx-2 shrink-0`}
`;

export const Switcher = styled.div<{ $isActive?: boolean }>`
  ${tw`
    rounded-55px w-12 p-px after:content-[''] after:block after:w-1/2
    after:bg-white after:pb-50% after:rounded-full after:transition-all
  `}

  ${({
    $isActive
  }) => ($isActive ? tw`bg-button-blue after:translate-x-full` : tw`bg-dim-gray`)}
`;

export const Question = styled.p`
  ${tw`text-xs text-dim-gray leading-5 font-medium`}
`;

export const QuestionTitle = styled.span`
  ${tw`hover:(text-dim-gray bg-dashed-hover bg-bottom bg-repeat-x bg-[length:8px_1px])
  active:(text-gray7 bg-dashed-active bg-bottom bg-repeat-x bg-[length:8px_1px])
  transition-colors`}
`;

export const HeaderTitleWrapper = styled.div`
  ${tw`overflow-hidden`}
`;
