import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const ContentContainer = styled.div`
  ${tw`flex flex-col gap-1 py-1 max-h-304px overflow-scroll rounded-md`}
`;

export const RowContainer = styled.div`
  ${tw`flex items-center gap-2 text-gray7 text-17-26 font-medium px-4 py-2`}
`;
export const GroupName = styled.p`
  ${tw`w-154px truncate`}
`;

export const AmountContainer = styled.div`
  ${tw`flex items-baseline gap-1 text-base font-['Inter']`}
`;

export const Growth = styled.p<{ $isIncrease?: boolean }>`
  ${tw`text-xs`}

  ${({
    $isIncrease
  }) => ($isIncrease ? tw`text-forest-green` : tw`text-red-text-error`)}
`;
