import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MainContainer = styled.div<{ $isExpanded: boolean }>`
  ${tw`md:grid grid-cols-1 md:grid-cols-[1fr,max-content]`}

  ${({
    $isExpanded
  }) => $isExpanded && tw`md:gap-7`}
`;

export const ContainerBlock = styled.div<{ $isExpanded: boolean }>`
  ${tw`row-start-1`}

  ${({
    $isExpanded
  }) => $isExpanded && tw`col-span-2`}
`;

export const TotalValueBlock = styled.div<{ $isBigger: boolean }>`
  ${tw`text-xl font-bold`}

  ${({
    $isBigger
  }) => ($isBigger
    ? tw`text-red-text-error flex items-center gap-4`
    : tw`text-gray7`)}
`;

export const MainInfoBlock = styled.div`
  ${tw`rounded-md bg-white shadow-card-shadow-default px-3 py-4 md:p-6 md:mb-6`}
`;
export const TextBlock = styled.div<{ $isGrey?: boolean }>`
  ${tw`text-15-20 font-medium`}

  ${({
    $isGrey
  }) => $isGrey && tw`text-dim-gray mb-2`}
`;

export const ExpandedContainer = styled.div`
  ${tw`flex justify-between gap-2 items-center`}
`;

export const ExpandedTitle = styled.div`
  ${tw`text-22-30 font-bold w-226px text-center`}
`;

export const QuestionTitle = styled.div`
  ${tw`text-18-22 md:text-34-43 font-bold text-gray7`}
`;

export const QuestionOnboardingText = styled.div`
  ${tw`text-17-26 font-medium text-dark-gray`}
`;

export const QuestionTitleSpan = styled.span`
  ${tw`pb-1 hover:(text-dim-gray bg-dashed-hover bg-bottom bg-repeat-x bg-[length:8px_1px])
  active:(text-gray7 bg-dashed-active bg-bottom bg-repeat-x bg-[length:8px_1px])
  transition-colors`}
`;
export const ButtonsContainer = styled.div`
  ${tw`md:mt-2 mb-6 flex justify-between items-center`}
`;

export const SpanBlue = styled.span<{ $isButton?: boolean }>`
  ${tw`text-button-blue`}

  ${({
    $isButton
  }) => $isButton && tw`text-xl font-bold`}
`;

export const SpanGrey = styled.span`
  ${tw`text-16-28 md:text-xl text-dim-gray font-bold`}
`;

export const FeedBlock = styled.div`
  ${tw`md:row-span-2 md:col-start-3`}
`;
export const FeedContainer = styled.div<{ $isExpanded: boolean }>`
  ${tw`row-start-1 col-start-2`}

  ${({
    $isExpanded
  }) => ($isExpanded ? tw`row-span-2 sticky top-4` : tw`row-span-1`)}
`;

export const QuestionDates = styled.div`
  ${tw`hidden md:block text-15-20 font-medium text-dim-gray`}
`;

export const HeadWrapper = styled.div`
  ${tw`flex items-center gap-12 mb-3`}
`;

export const HeadInfo = styled.div`
  ${tw`grow flex justify-between gap-4`}
`;

export const TitleContainer = styled.div`
  ${tw`flex flex-col gap-2 flex-1`}
`;

export const BreadcrumbsRow = styled.div`
  ${tw`flex justify-between items-center gap-2`}
`;

export const MobileButton = styled.button`
  ${tw`flex items-center gap-1 py-3 pr-3`}
`;

export const NavigateButtonsContainer = styled.div`
  ${tw`flex justify-between gap-2 flex-wrap items-center`}
`;
export const SubmitNextButtonsContainer = styled.div`
  ${tw`flex gap-2 md:gap-4`}
`;
export const InfoContentContainer = styled.div`
  ${tw`hidden md:flex flex-row gap-6 mb-6`}
`;
export const FlexOne = styled.div`
  ${tw`flex-1`}
`;
