import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const GroupInfoForm = styled.form`
  ${tw`flex gap-6`}
`;

export const FieldLabel = styled.label`
  ${tw`w-1/2 flex flex-col gap-2 text-15-20 text-grey-dark-40 font-medium`}
`;

export const Asterisk = styled.span`
  ${tw`text-red-text-error`}
`;

export const FieldInput = styled.input<{ $withError: boolean }>`
  ${tw`
    bg-alice-blue px-4 py-3px border border-gray94 outline-0 h-12
    text-17-26 text-dark-gray font-medium
    placeholder:text-dim-gray w-full rounded
  `}

  ${({
    $withError
  }) => $withError && tw`border-red-border-error bg-red-bg-error`}
`;

export const SectionHeader = styled.div`
  ${tw`flex justify-between items-start`}
`;

export const SectionTitle = styled.div`
  ${tw`text-22-30 font-bold grow`}
`;

export const SectionTitleContainer = styled.div`
  ${tw`flex flex-col gap-1 grow`}
`;

export const SectionSubtitle = styled.p`
  ${tw`text-15-20 font-medium text-dim-gray`}
`;

export const HeaderMessage = styled.p<{ $color: string }>`
  ${tw`text-17-26 font-bold`}

  ${({
    $color
  }) => `
    color: ${$color}
  `}
`;

export const InvitedPeopleEmptyMessage = styled.p`
  ${tw`text-xl font-medium text-light-grey h-130px flex items-center justify-center`}
`;

export const ErrorMessage = styled.div`
  ${tw`text-13-21 font-medium text-red-text-error mt-1`}
`;
