import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const ContentContainer = styled.div`
  ${tw`flex flex-col items-center gap-4`}
`;

export const TextContainer = styled.div`
  ${tw`flex flex-col justify-center gap-6`}
`;

export const Title = styled.p`
  ${tw`font-bold text-28-36 text-gray7`}
`;

export const ButtonsContainer = styled.div`
  ${tw`flex gap-4 justify-center items-center`}
`;
