import clsx from 'clsx';
import React, {
  FC, useEffect
} from 'react';
import {
  Link, useLocation
} from 'react-router-dom';

import {
  useTypedSelector
} from 'src/redux';
import {
  notificationsSelector
} from 'src/redux/notifications';
import {
  socketNotifications
} from 'src/shared/api/webSocket/socket';
import {
  BellBlackIcon
} from 'src/shared/icons';
import {
  ROUTE
} from 'src/shared/utils';
import {
  useGetNotificationsUnreadQuery
} from 'src/redux/openapi';

import * as Style from './Notification.styles';

export const Notifications: FC = () => {
  const location = useLocation();

  const isNotificationsPage = location.pathname.startsWith(
    `/${ROUTE.NOTIFICATIONS}`,
  );

  const isNewUser = location.pathname.startsWith(`/${ROUTE.CREATE_GROUP}`);

  const isNotificationPage = location.pathname.startsWith(
    `/${ROUTE.NOTIFICATIONS}`,
  );

  const {
    all
  } = useTypedSelector(
    notificationsSelector.selectNotificationsUnreadCount,
  );

  const {
    data, refetch
  } = useGetNotificationsUnreadQuery();

  const isNewNotifications = isNotificationPage
    ? !!data?.data.unreadTotal || !!all
    : !!data?.data.unreadTotal;

  useEffect(
    () => {
      const socket = socketNotifications;

      socket.on(
        'notification:create',
        () => {
          refetch();
        }
      );

      return () => {
        socketNotifications.off('notification:create');
      };
    },
    []
  );

  useEffect(
    () => {
      refetch();
    },
    []
  );

  return (
    <Style.Wrapper>
      <Link
        to={isNewUser ? `/${ROUTE.CREATE_GROUP}` : `/${ROUTE.NOTIFICATIONS}`}
        className={clsx({
          'cursor-default': isNewUser,
        })}
      >
        <Style.BellContainer $isNew={isNewNotifications}>
          <BellBlackIcon
            className={clsx({
              'text-dark-gray': !isNotificationsPage && !isNewUser,
              'text-button-blue': isNotificationsPage,
              'text-gray-whisper': isNewUser,
            })}
          />
        </Style.BellContainer>
      </Link>
    </Style.Wrapper>
  );
};
