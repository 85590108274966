import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import {
  useParams
} from 'react-router-dom';

import {
  NavigateBackButton, PageLayout
} from 'src/widgets';
import {
  Button,
  Spinner,
  successfulToast,
  UploadUsersList,
} from 'src/shared/components';
import {
  useGetOrganizationQuery,
  usePostOrgGroupsInvitationsUsersMutation,
} from 'src/redux/openapi';
import {
  useTypedSelector
} from 'src/redux';
import {
  organizationSelectors
} from 'src/redux/organizations';
import {
  showWarningFromServer
} from 'src/shared/utils';

import {
  GroupsAndMembers
} from '../SetupOrganization/ui/GroupsAndMembers';

import * as Style from './ManageInvitations.styles';

export const ManageInvitations: FC = () => {
  const params = useParams();

  const {
    organizationId = ''
  } = params;

  const {
    t
  } = useTranslation();

  const {
    data
  } = useGetOrganizationQuery(
    {
      id: organizationId,
    },
    {
      skip: !organizationId,
      refetchOnMountOrArgChange: true,
    },
  );

  const organizationName = data?.data.organization.name || '';

  const groupsToSetup = useTypedSelector(
    organizationSelectors.orgGroupsToSetup,
  );

  const [postNewUsersToGroup, {
    isLoading: isSaveLoading
  }] = usePostOrgGroupsInvitationsUsersMutation();

  const handleSaveOrgGroupUsers = async () => {
    const groups = groupsToSetup.map((group) => ({
      id: group.id,
      users: group.users.map((user) => ({
        id: user.id,
        email: user.email,
      })),
    }));

    try {
      const response = await postNewUsersToGroup({
        id: organizationId,
        updateOrganizationGroupsInvitationsBodySchema: {
          groups,
        },
      }).unwrap();

      successfulToast(response.message);
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  return (
    <PageLayout>
      <Style.MainContainer>
        <Style.Head>
          <NavigateBackButton backgroundColor="white" />

          <Style.HeadInfo>
            <Style.HeadTitle>{t('group.manageInvitations')}</Style.HeadTitle>

            <Style.HeadSubtitle>{organizationName}</Style.HeadSubtitle>
          </Style.HeadInfo>

          <Button
            variant="big-blue"
            className="font-bold !w-max text-xl"
            onClick={handleSaveOrgGroupUsers}
          >
            {t('buttons.save')}

            {isSaveLoading && (
              <Spinner
                size={24}
                color="white"
              />
            )}
          </Button>
        </Style.Head>

        <Style.UploadSectionContainer>
          <UploadUsersList />
        </Style.UploadSectionContainer>

        <GroupsAndMembers invitesOnly />
      </Style.MainContainer>
    </PageLayout>
  );
};
