import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const EmptyMessageContainer = styled.div`
  ${tw`text-center text-17-26 p-4 h-full flex flex-col items-center justify-center`}
`;

export const EmptyMessageTitle = styled.p`
  ${tw`font-bold text-dark-gray mb-2`}
`;

export const EmptyMessageSubtitle = styled.p`
  ${tw`font-medium text-dim-gray mb-6`}
`;

export const ResetFiltersButton = styled.button`
  ${tw`
    rounded-100px border border-button-blue font-bold 
    text-15-20 text-button-blue py-9px px-15px transition-all
    hover:(bg-button-blue text-white)
    
  `}
`;
