import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MenuContainer = styled.div`
  ${tw`bg-white rounded-15px shadow-menu-shadow w-222px`}
`;

export const MenuTitle = styled.div`
  ${tw`text-base font-medium text-gray7 px-4 py-3`}
`;

export const MenuOption = styled.button`
  ${tw`text-15-20 font-medium text-gray7 px-4 py-3
  flex justify-between w-full text-left`}
`;

export const SelectButtonContainer = styled.div<{ $isOpen: boolean }>`
  ${tw`flex  md:gap-1 items-center justify-center py-2.5 px-4 transition-all border rounded-1000px text-13-21 md:text-15-16 font-medium box-content`}

  ${({
    $isOpen
  }) => ($isOpen
    ? tw`text-button-blue border-button-blue`
    : tw`border-gray90 text-dark-gray`)}
`;
