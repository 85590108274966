import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MainContainer = styled.div<{ $isDeactivated: boolean }>`
  ${tw`flex flex-col gap-2 p-4 border border-gray94 rounded last:mb-6`}

  ${({
    $isDeactivated
  }) => $isDeactivated && tw`[&*]:!text-grey-dark-40`}
`;

export const ContentWrapper = styled.div`
  ${tw`flex justify-between items-center gap-2 w-full`}
`;

export const Title = styled.p`
  ${tw`text-17-26 font-bold text-gray7`}
`;

export const Subtitle = styled.p`
  ${tw`text-13-21 font-medium text-gray7`}
`;

export const GroupInfo = styled.div`
  ${tw`grow`}
`;
