import React, {
  FC, FormEvent, useMemo, useState
} from 'react';
import {
  Controller, useForm
} from 'react-hook-form';
import {
  zodResolver
} from '@hookform/resolvers/zod';
import {
  useTranslation
} from 'react-i18next';

import {
  Button,
  Input,
  ModalContainer,
  Spinner,
  successfulToast,
} from 'src/shared/components';
import {
  usePostOrganizationsMutation,
  AdminUpdateOrganizationBody,
} from 'src/redux/openapi';
import {
  showWarningFromServer
} from 'src/shared/utils';
import {
  useGetInputValidation
} from 'src/shared/hooks';

import * as Style from '../EditEntityByAdmin';

import {
  CreateOrganizationFormField as FormField
} from './types';

interface CreateOrganizationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  name?: string;
  customCreateMethod?: (
    updatedOrganization: AdminUpdateOrganizationBody,
  ) => Promise<void>;
  admins?: {
    email: string;
    role: 'SUPERADMIN' | 'ADMIN';
  }[];
  orgDomain?: string | null;
  isEditing?: boolean;
}

type CreateOrganizationForm = {
  [FormField.OrganizationName]: string;
  [FormField.Domain]: string;
  [FormField.MainAdmin]: string;
  [FormField.FirstSubAdmin]: string;
  [FormField.SecondSubAdmin]: string;
};

export const CreateOrganizationModal: FC<CreateOrganizationModalProps> = ({
  isOpen,
  onClose,
  onSuccess,
  name = '',
  customCreateMethod,
  orgDomain = '',
  isEditing,
  admins,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const orgMainAdmin = useMemo(
    () => admins?.find((admin) => admin.role === 'SUPERADMIN'),
    [admins],
  );

  const orgSubAdmins = useMemo(
    () => admins?.filter((admin) => admin.role === 'ADMIN') || [],
    [admins],
  );

  const {
    createOrganizationSchema
  } = useGetInputValidation();

  const {
    control,
    formState: {
      errors
    },
    handleSubmit,
    reset,
  } = useForm<CreateOrganizationForm>({
    mode: 'onTouched',
    defaultValues: {
      [FormField.OrganizationName]: '',
      [FormField.Domain]: '',
      [FormField.MainAdmin]: '',
      [FormField.FirstSubAdmin]: '',
      [FormField.SecondSubAdmin]: '',
    },
    values: {
      [FormField.OrganizationName]: name,
      [FormField.Domain]: orgDomain || '',
      [FormField.MainAdmin]: orgMainAdmin?.email || '',
      [FormField.FirstSubAdmin]: orgSubAdmins?.[0]?.email || '',
      [FormField.SecondSubAdmin]: orgSubAdmins?.[1]?.email || '',
    },
    resolver: zodResolver(createOrganizationSchema),
  });

  const handleClose = () => {
    onClose();
    reset();
  };

  const [createOrganization] = usePostOrganizationsMutation();

  const onSubmit = handleSubmit(async (data: CreateOrganizationForm) => {
    setIsLoading(true);

    const {
      organisationName,
      domain,
      mainAdministrator,
      firstSubAdmin,
      secondSubAdmin,
    } = data;

    const subAdmins = [firstSubAdmin, secondSubAdmin].filter(
      (value) => value.length,
    );

    const body = {
      name: organisationName,
      domain: domain || undefined,
      superadminEmail: mainAdministrator,
      adminEmails: subAdmins,
    };

    try {
      if (customCreateMethod) {
        await customCreateMethod(body);
      } else {
        const response = await createOrganization({
          createOrganizationBodySchema: body,
        }).unwrap();

        successfulToast(response.message);

        if (onSuccess) {
          onSuccess();
        }

        handleClose();
      }
    } catch (error) {
      showWarningFromServer(error);
    } finally {
      setIsLoading(false);
    }
  });

  const onFormSubmit = (event: FormEvent) => {
    event.preventDefault();
    onSubmit();
  };

  const {
    t
  } = useTranslation();

  return (
    <ModalContainer
      isOpen={isOpen}
      closeModalHandler={handleClose}
      modalTitle={
        isEditing
          ? t('organisation.editOrganisation')
          : t('organisation.createOrganisation')
      }
    >
      <Style.ContentContainer onSubmit={onFormSubmit}>
        <Style.InputsRow>
          <Style.InputContainer>
            <Controller
              name={FormField.OrganizationName}
              control={control}
              render={({
                field: {
                  onChange, onBlur, value
                }
              }) => {
                return (
                  <Input
                    onChange={onChange}
                    value={value}
                    placeholder={t('organisation.organisationName')}
                    label={t('organisation.organisationName')}
                    isRequired
                    onBlur={onBlur}
                    errorMessage={errors[FormField.OrganizationName]?.message}
                  />
                );
              }}
            />
          </Style.InputContainer>

          <Style.InputContainer>
            <Controller
              name={FormField.Domain}
              control={control}
              rules={{
                required: false,
              }}
              render={({
                field: {
                  onChange, onBlur, value
                }
              }) => {
                return (
                  <Input
                    onChange={onChange}
                    value={value}
                    placeholder={t('common.domain')}
                    label={`${t('common.domain')} (${t('common.optional')})`}
                    onBlur={onBlur}
                    errorMessage={errors[FormField.Domain]?.message}
                  />
                );
              }}
            />
          </Style.InputContainer>
        </Style.InputsRow>

        <Style.SetAdminsContainer>
          <Style.SetAdminsHeader>
            <Style.SetAdminsTitle>
              {isEditing
                ? t('common.manageAdministration')
                : t('common.setAdministrators')}
            </Style.SetAdminsTitle>

            {!isEditing && (
              <Style.SetAdminsSubtitle>
                {t('group.youCanSkipIt')}
              </Style.SetAdminsSubtitle>
            )}
          </Style.SetAdminsHeader>

          <Controller
            name={FormField.MainAdmin}
            control={control}
            render={({
              field: {
                onChange, onBlur, value
              }
            }) => {
              return (
                <Input
                  onChange={onChange}
                  value={value}
                  placeholder="user@email.com"
                  label={t('common.mainAdministrator')}
                  onBlur={onBlur}
                  isRequired
                  errorMessage={errors[FormField.MainAdmin]?.message}
                />
              );
            }}
          />

          <Style.InputsRow>
            <Style.InputContainer>
              <Controller
                name={FormField.FirstSubAdmin}
                control={control}
                rules={{
                  required: false,
                  minLength: 0,
                }}
                render={({
                  field: {
                    onChange, onBlur, value
                  }
                }) => {
                  return (
                    <Input
                      onChange={onChange}
                      value={value}
                      placeholder="user@email.com"
                      label={`${t('common.subAdministrator')} (${t(
                        'common.optional',
                      )})`}
                      onBlur={onBlur}
                      errorMessage={errors[FormField.FirstSubAdmin]?.message}
                    />
                  );
                }}
              />
            </Style.InputContainer>

            <Style.InputContainer>
              <Controller
                name={FormField.SecondSubAdmin}
                control={control}
                render={({
                  field: {
                    onChange, onBlur, value
                  }
                }) => {
                  return (
                    <Input
                      onChange={onChange}
                      value={value}
                      placeholder="user@email.com"
                      label={`${t('common.subAdministrator')} (${t(
                        'common.optional',
                      )})`}
                      onBlur={onBlur}
                      errorMessage={errors[FormField.SecondSubAdmin]?.message}
                    />
                  );
                }}
              />
            </Style.InputContainer>
          </Style.InputsRow>
        </Style.SetAdminsContainer>

        <Style.ButtonsContainer>
          <Button
            variant="big-grey-bordered"
            className="font-bold text-xl w-max"
            onClick={handleClose}
          >
            {t('buttons.cancel')}
          </Button>

          <Button
            variant="big-blue"
            onClick={onSubmit}
            className="font-bold text-xl w-max"
          >
            {isEditing
              ? t('buttons.saveChanges')
              : t('organisation.createOrganisation')}

            {isLoading && (
              <Spinner
                color="white"
                size={24}
                centered={false}
              />
            )}
          </Button>
        </Style.ButtonsContainer>
      </Style.ContentContainer>
    </ModalContainer>
  );
};
