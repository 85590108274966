import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const ContentContainer = styled.div`
  ${tw`flex gap-6`}
`;

export const SectionContainer = styled.div`
  ${tw`flex flex-col gap-4 flex-1 h-360px`}
`;

export const SectionTitle = styled.p`
  ${tw`text-17-26 font-bold text-gray7`}
`;

export const GroupList = styled.ul`
  ${tw`flex flex-col gap-2 overflow-auto pb-2`}
`;

export const GroupListItem = styled.li`
  ${tw`border border-gray90 rounded-md px-4 py-2 flex gap-2`}
`;

export const GroupTitle = styled.p`
  ${tw`text-17-26 font-medium text-gray7`}
`;

export const GroupInfoContainer = styled.div`
  ${tw`flex flex-col gap-1 grow`}
`;

export const GroupInfo = styled.div`
  ${tw`flex justify-between font-medium text-13-21 gap-2`}
`;

export const GroupInvitedInfo = styled.p`
  ${tw`text-gray7 shrink-0`}
`;

export const GroupLocation = styled.p`
  ${tw`text-dim-gray truncate`}
`;
