import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  useDeleteOrgRequestMutation
} from 'src/redux/openapi';
import {
  ModalDelete, successfulToast
} from 'src/shared/components';
import {
  showWarningFromServer
} from 'src/shared/utils';

interface DeleteOrgRequestModalProps {
  id?: string | null;
  onSuccessDelete?: () => void;
  closeModal: () => void;
  isOpen: boolean;
}

export const DeleteOrgRequestModal: FC<DeleteOrgRequestModalProps> = ({
  id,
  onSuccessDelete,
  closeModal,
  isOpen,
}) => {
  const [deleteRequest, {
    isLoading
  }] = useDeleteOrgRequestMutation();

  const onDelete = async () => {
    if (isLoading || !id) {
      return;
    }

    try {
      const response = await deleteRequest({
        id,
      }).unwrap();

      successfulToast(response?.message);

      if (onSuccessDelete) {
        onSuccessDelete();
      }

      closeModal();
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  const {
    t
  } = useTranslation();

  return (
    <ModalDelete
      closeModal={closeModal}
      isOpen={isOpen}
      title={t('organisation.removeRequest')}
      modalText={t(
        'modal.sureWantToDelete',
        {
          type: t('common.request'),
        }
      )}
      onDelete={onDelete}
      isLoading={false}
      deleteButtonTitle={t('buttons.remove')}
    />
  );
};
