import tw from 'twin.macro';
import {
  styled
} from 'styled-components';
import {
  Link
} from 'react-router-dom';

export const MainContainer = styled.div`
  ${tw`flex flex-col items-center justify-center h-full`}
`;

export const Title = styled.p`
  ${tw`font-bold text-black text-xl mt-4`}
`;

export const Subtitle = styled.p`
  ${tw`font-medium text-dark-gray text-17-26 mt-2 w-400px text-center`}
`;

export const HomeLink = styled(Link)`
  ${tw`font-bold text-button-blue text-15-20 mt-6 p-2`}
`;
