import React, {
  HTMLProps, forwardRef
} from 'react';
import clsx from 'clsx';

import {
  ArrowDownIcon
} from 'src/shared/icons';

import * as Styles from './Selects.styles';

interface SelectButtonProps extends HTMLProps<HTMLDivElement> {
  onClear: () => void;
  onButtonClick: () => void;
  isOpen: boolean;
  isSelectedValue: boolean;
  title: string;
}

export const SelectButton = forwardRef<HTMLDivElement, SelectButtonProps>(
  (
    {
      onClear, onButtonClick, isOpen, isSelectedValue, title, ...props
    },
    ref,
  ) => {
    return (
      <Styles.SelectButtonContainer
        $isOpen={isOpen}
        onClick={onButtonClick}
        role="button"
        tabIndex={0}
        ref={ref}
        {...props}
      >
        {title}

        <ArrowDownIcon
          className={clsx({
            'rotate-180 text-button-blue': isOpen,
            'text-gray7': !isOpen,
          })}
        />
      </Styles.SelectButtonContainer>
    );
  },
);
