import React, {
  FC, useEffect, useMemo, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import {
  useNavigate
} from 'react-router-dom';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import {
  SubIcon
} from 'src/shared/icons';
import {
  ROUTE
} from 'src/shared/utils';
import {
  OrgInvitationsGroup,
  useGetOrgGroupsInvitationsQuery,
} from 'src/redux/openapi';
import {
  EmptyResultsMessage, Spinner
} from 'src/shared/components';

import {
  GroupWithUsersCard
} from '../GroupWithUsersCard';

import * as Style from './InvitedTabContent.styles';

interface InvitedTabContentProps {
  searchQuery: string;
  organizationId: string;
  onInvitedUpdate: () => void;
}

export const InvitedTabContent: FC<InvitedTabContentProps> = ({
  searchQuery,
  organizationId,
  onInvitedUpdate,
}) => {
  const [currentLastItem, setCurrentLastItem] = useState<OrgInvitationsGroup | null>(null);

  const [visibleGroups, setVisibleGroups] = useState<OrgInvitationsGroup[]>([]);

  const {
    t
  } = useTranslation();

  const navigate = useNavigate();

  const onManageClick = () => {
    navigate(
      `/${ROUTE.ORGANISATION}/${organizationId}/${ROUTE.MANAGE_INVITATIONS}`,
    );
  };

  const {
    data: groupsData,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useGetOrgGroupsInvitationsQuery(
    {
      id: organizationId,
      query: searchQuery,
      lastCreatedAt: currentLastItem?.createdAt,
      lastId: currentLastItem?.id,
      limit: 10,
    },
    {
      skip: !organizationId,
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    total: totalGroups, groups: currentGroups
  } = useMemo(
    () => groupsData?.data || {
      total: 0,
      groups: [],
    },
    [groupsData],
  );

  const hasNextPage = totalGroups > currentGroups.length;

  const onLoadMore = () => {
    if (isLoading || isFetching || !visibleGroups.length) {
      return;
    }

    const lastItem = visibleGroups[visibleGroups.length - 1];

    setCurrentLastItem(lastItem);
  };

  const [sentryRef] = useInfiniteScroll({
    loading: isLoading || isFetching,
    hasNextPage,
    onLoadMore,
    disabled: !!isError,
    rootMargin: '0px 0px 100px 0px',
  });

  useEffect(
    () => {
      if (currentLastItem) {
        setVisibleGroups((prev) => [...prev, ...currentGroups]);
      } else {
        setVisibleGroups(currentGroups);
      }
    },
    [currentGroups]
  );

  const onGroupDelete = (id: string) => {
    setVisibleGroups((groups) => groups.filter((group) => group.id !== id));
  };

  const resetGroupsPagination = () => {
    if (currentLastItem) {
      setVisibleGroups([]);
      setCurrentLastItem(null);
    } else {
      refetch();
    }
  };

  return (
    <Style.MainContainer>
      <Style.ButtonsContainer>
        <Style.ActivityButton onClick={onManageClick}>
          {t('common.manage')}

          <SubIcon />
        </Style.ActivityButton>

        {/* TODO: uncomment after characteristics implementation */}

        {/* <Style.ActivityButton>
          {t('common.characteristics')}

          <ListIcon />
        </Style.ActivityButton> */}
      </Style.ButtonsContainer>

      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {!!searchQuery.length && !visibleGroups.length && (
            <EmptyResultsMessage results={t('group.Groups').toLowerCase()} />
          )}

          {visibleGroups.map((group) => (
            <GroupWithUsersCard
              groupData={group}
              onInvitedUpdate={onInvitedUpdate}
              key={group.id}
              setGroups={setVisibleGroups}
              onGroupDelete={onGroupDelete}
              resetGroupsPagination={resetGroupsPagination}
            />
          ))}

          {(isFetching || isLoading || hasNextPage) && (
            <div ref={sentryRef}>
              <Spinner size={24} />
            </div>
          )}
        </>
      )}
    </Style.MainContainer>
  );
};
