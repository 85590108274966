import React, {
  FC, MouseEventHandler
} from 'react';
import {
  useFieldArray, useForm
} from 'react-hook-form';
import {
  zodResolver
} from '@hookform/resolvers/zod';
import {
  useTranslation
} from 'react-i18next';

import {
  measureText
} from 'src/shared/utils';
import {
  useGetInputValidation
} from 'src/shared/hooks';
import {
  EmailIcon
} from 'src/shared/icons';
import {
  useTypedDispatch, useTypedSelector
} from 'src/redux';
import {
  addNewUsers, organizationSelectors
} from 'src/redux/organizations';

import {
  Button
} from '../Button';

import * as Style from './MailListInput.style';

interface MailListInputProps {
  buttonTitle?: 'send' | 'add';
}

interface MailFormValuesState {
  emails: {
    address: string;
  }[];
}

enum ModalValues {
  MAIL_LIST = 'emails',
}

export const MailListInput: FC<MailListInputProps> = ({
  buttonTitle = 'send',
}) => {
  const dispatch = useTypedDispatch();
  const userList = useTypedSelector(organizationSelectors.userList);

  const {
    emailsSchema
  } = useGetInputValidation();

  const {
    control,
    getValues,
    register,
    formState: {
      errors
    },
    setError,
    clearErrors,
    watch,
    setFocus,
    reset,
  } = useForm<MailFormValuesState>({
    mode: 'all',
    defaultValues: {
      emails: [
        {
          address: '',
        },
      ],
    },
    resolver: zodResolver(emailsSchema),
  });

  const {
    t
  } = useTranslation();

  const {
    fields, append, remove
  } = useFieldArray({
    name: ModalValues.MAIL_LIST,
    control,
  });

  const values = watch();

  const isInList = (email: string, isPaste?: boolean) => {
    if (userList.some((user) => user.email === email)) {
      return true;
    }

    return isPaste
      ? values.emails.some((field) => field.address === email)
      : values.emails.filter((field) => field.address === email).length > 1;
  };

  const handleFocusInput = () => {
    const mails = getValues(ModalValues.MAIL_LIST);
    const index = mails.findIndex((mail) => mail.address === '');

    if (index < 0) {
      append({
        address: '',
      });
    } else {
      setFocus(`${ModalValues.MAIL_LIST}.${index}.address`);
    }
  };

  const handleClickOnLabel: MouseEventHandler = (e) => {
    e.preventDefault();

    if (e.target === e.currentTarget) {
      handleFocusInput();
    }
  };

  const handleHookKeyDown = (
    evt: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    clearErrors();

    if (['Enter', 'Tab', ',', ' '].includes(evt.key)) {
      evt.preventDefault();
      const mail = getValues(`${ModalValues.MAIL_LIST}.${index}.address`);

      if (!mail) {
        remove(index);

        return;
      }

      const isError = !!errors?.emails?.[index]?.address || !!errors.emails?.message;

      if (isError) {
        setError(
          ModalValues.MAIL_LIST,
          {
            message: t('inputMessages.INVALID_EMAIL'),
          }
        );

        return;
      }

      const isNotUnique = isInList(mail);

      if (isNotUnique) {
        setError(
          ModalValues.MAIL_LIST,
          {
            message: t('inputMessages.EMAIL_WAS_ADDED'),
          }
        );

        return;
      }

      const canAdd = !isError && !isNotUnique;
      const isLastInList = index === fields.length - 1;
      const isFirstInList = fields.length === 1 && index === 0;

      if ((isLastInList && canAdd) || isFirstInList) {
        append(
          {
            address: '',
          },
          {
            focusIndex: index + 1,
          },
        );
      }

      if (!isLastInList && !isError) {
        append({
          address: '',
        });
      }

      if (!isLastInList && !mail) {
        remove(index);
      }
    }
  };

  const handleBlurHook = (index: number) => {
    const mail = getValues(`${ModalValues.MAIL_LIST}.${index}.address`);

    if (!mail && fields.length !== 1) {
      remove(index);
    }
  };

  const handlePaste: React.ClipboardEventHandler<HTMLInputElement> = (evt) => {
    evt.preventDefault();

    const paste = evt.clipboardData.getData('text');
    const emails = paste.match(/[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/g);

    if (emails) {
      const toBeAdded = emails.filter(
        (email: string) => !isInList(
          email,
          true
        ),
      );

      toBeAdded.forEach((mail) => {
        append({
          address: mail,
        });
      });
    }
  };

  const handleAddMails = () => {
    const mails = getValues(ModalValues.MAIL_LIST);

    if (mails.length) {
      const preparedMails = mails.map((mail) => ({
        email: mail.address,
      }));

      dispatch(addNewUsers(preparedMails));

      reset();
    }
  };

  return (
    <div>
      <Style.ModalLabelButton onClick={handleFocusInput}>
        {`${t('organisation.inviteNewMembers')} `}

        <Style.ModalRedSpan>*</Style.ModalRedSpan>
      </Style.ModalLabelButton>

      <Style.ModalGroupedLabel onClick={handleClickOnLabel}>
        {fields.map((mail, index) => {
          const currentInput = values.emails[index].address;

          const width = measureText(
            currentInput,
            16
          );

          return (
            <Style.ModalEmailHookToInvite
              $isFullWidth={fields.length === 1}
              $isTransparent={values.emails[index].address === ''}
              key={mail.id}
              {...register(`${ModalValues.MAIL_LIST}.${index}.address`)}
              type="email"
              onBlur={() => handleBlurHook(index)}
              onKeyDown={(evt) => handleHookKeyDown(
                evt,
                index
              )}
              placeholder={
                values.emails.length === 1
                && values.emails[index].address === ''
                  ? t('modal.pasteHereBunchOfEmails')
                  : ''
              }
              onPaste={handlePaste}
              style={{
                width:
                  values.emails.length === 1 && values.emails[0].address === ''
                    ? '320px'
                    : `calc(26px + ${width}px)`,
              }}
            />
          );
        })}
      </Style.ModalGroupedLabel>

      {errors.emails?.message && (
        <div className="my-2">
          <Style.ModalRedSpan>{errors.emails.message}</Style.ModalRedSpan>
        </div>
      )}

      {!!values.emails[0].address && (
        <Button
          type="button"
          variant="big-blue"
          className="w-max px-4 py-2.5 ml-auto mt-2 text-15-20 font-bold mb-4"
          onClick={handleAddMails}
        >
          {t(`buttons.${buttonTitle}`)}

          <EmailIcon />
        </Button>
      )}
    </div>
  );
};
