import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const EmptyContainer = styled.div`
  ${tw`flex flex-col items-center justify-center gap-1 h-full`}
`;

export const TextTitle = styled.div`
  ${tw`text-17-26 font-bold text-gray7`}
`;

export const TextSubTitle = styled.div`
  ${tw`text-13-21 font-medium text-dim-gray text-center w-250px`}
`;
