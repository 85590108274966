import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const ModalTabsContainer = styled.div`
  ${tw`py-6`}
`;

export const ModalTabButton = styled.button`
  ${tw`flex-1`}
`;

export const ModalTabLabel = styled.div<{ $isActive: boolean }>`
  ${tw`text-15-20 font-medium mb-2`}

  ${({
    $isActive
  }) => ($isActive ? tw`text-button-blue` : tw`text-dark-gray`)}
`;

export const ModalTabBottomLine = styled.div<{ $isActive: boolean }>`
  ${tw`h-1 rounded-t-20px`}

  ${({
    $isActive
  }) => ($isActive ? tw`bg-button-blue` : tw`bg-transparent`)}
`;

export const ModalTabsRow = styled.div`
  ${tw`flex flex-row justify-between items-center px-6 border-b border-light-grey`}
`;
