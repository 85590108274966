import React, {
  FC, useEffect, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import i18next from 'i18next';

import {
  PeopleIcon
} from 'src/shared/icons';
import {
  ScrollContainer, SearchInput
} from 'src/shared/components';
import {
  Filters, FilterAmountTile
} from 'src/features';
import {
  useGetOrganizationUsersQuery,
  Organization,
  UserGroupRole,
  useGetOrgGroupsUsersInvitedCountQuery,
  useGetOrganizationUsersAdminsQuery,
} from 'src/redux/openapi';
import {
  useDebouncedValue
} from 'src/shared/hooks';

import {
  SectionTitle,
  SectionHeader,
  SectionContainer,
} from '../Sections.styles';

import * as Style from './CommunitySection.styles';
import {
  MembersTabContent,
  GroupsTabContent,
  InvitedTabContent,
  CommunityMoreBtn,
  ModalSendMsgToMembers,
} from './ui';
import {
  CommunityActions
} from './types';

enum CommunityFilters {
  Groups = 'Groups',
  Members = 'Members',
  Invited = 'Invited',
}

const getCommunityFilters = () => [
  {
    id: CommunityFilters.Groups,
    label: i18next.t(`group.${CommunityFilters.Groups}`),
  },
  {
    id: CommunityFilters.Members,
    label: i18next.t(`group.${CommunityFilters.Members}`),
  },
  {
    id: CommunityFilters.Invited,
    label: i18next.t(`group.invited`),
  },
];

interface CommunitySectionProps {
  organization: Organization;
}

export const CommunitySection: FC<CommunitySectionProps> = ({
  organization,
}) => {
  const [activeFilterId, setActiveFilterId] = useState<CommunityFilters | null>(
    CommunityFilters.Groups,
  );

  const {
    t
  } = useTranslation();

  const [searchQuery, setSearchQuery] = useState('');

  const debouncedQuery = useDebouncedValue(
    searchQuery,
    500
  );

  const {
    id, role, memberCount, groupCount
  } = organization;

  const {
    data: {
      data: membersData
    } = {},
    refetch: refetchMembers,
    isLoading: isMembersLoading,
  } = useGetOrganizationUsersQuery(
    {
      id,
      query: debouncedQuery,
      limit: 5000,
      // TODO: add pagination after be pagination implementation
    },
    {
      refetchOnMountOrArgChange: true,
      skip: activeFilterId !== CommunityFilters.Members,
    },
  );

  const {
    data: {
      data: adminsData
    } = {},
    refetch: refetchAdmins,
    isLoading: isAdminsLoading,
  } = useGetOrganizationUsersAdminsQuery(
    {
      id,
      query: debouncedQuery,
    },
    {
      skip: activeFilterId !== CommunityFilters.Members,
    },
  );

  const refetchAllMembers = () => {
    refetchAdmins();
    refetchMembers();
  };

  const {
    invitedCount, refetch
  } = useGetOrgGroupsUsersInvitedCountQuery(
    {
      id,
    },
    {
      selectFromResult: ({
        data
      }) => ({
        invitedCount: data?.data.total,
      }),
    },
  );

  const onCommunityFilterClick = (filterId: CommunityFilters | null) => setActiveFilterId(filterId);

  useEffect(
    () => {
      setSearchQuery('');
    },
    [activeFilterId]
  );

  const communityFilters = getCommunityFilters();

  const [moreBtnActionType, setMoreBtnActionType] = useState<CommunityActions | null>(null);

  const closeModalActionBtn = () => setMoreBtnActionType(null);

  const setActionType = (action: CommunityActions) => {
    setMoreBtnActionType(action);
  };

  return (
    <SectionContainer id="CommunitySection">
      <SectionHeader className="pb-23px">
        <SectionTitle>
          <PeopleIcon className="w-6 h-6 text-dim-gray" />

          {t('organisation.community')}
        </SectionTitle>

        <Style.SearchContainer>
          <SearchInput
            onChange={setSearchQuery}
            value={searchQuery}
            placeholder={`${t('common.startTyping')}...`}
          />

          {role !== UserGroupRole.USER && (
            <CommunityMoreBtn
              setActionType={setActionType}
              organizationId={id}
            />
          )}
        </Style.SearchContainer>
      </SectionHeader>

      <Style.FiltersContainer>
        <Filters
          items={communityFilters}
          activeFilterId={activeFilterId}
          onFilterClick={onCommunityFilterClick}
          renderRightElement={(item) => (
            <FilterAmountTile isActive={item.id === activeFilterId}>
              {item.id === CommunityFilters.Groups && groupCount}

              {item.id === CommunityFilters.Members && memberCount}

              {item.id === CommunityFilters.Invited && invitedCount}
            </FilterAmountTile>
          )}
        />
      </Style.FiltersContainer>

      <ScrollContainer className="grow">
        {activeFilterId === CommunityFilters.Members && (
          <MembersTabContent
            membersData={membersData}
            adminsData={adminsData}
            role={role as UserGroupRole}
            organization={organization}
            refetchMembers={refetchAllMembers}
            isLoading={isMembersLoading || isAdminsLoading}
          />
        )}

        {activeFilterId === CommunityFilters.Groups && (
          <GroupsTabContent
            organizationId={organization.id}
            searchQuery={debouncedQuery}
          />
        )}

        {activeFilterId === CommunityFilters.Invited && (
          <InvitedTabContent
            organizationId={organization.id}
            searchQuery={debouncedQuery}
            onInvitedUpdate={refetch}
          />
        )}
      </ScrollContainer>

      <ModalSendMsgToMembers
        isOpen={moreBtnActionType === CommunityActions.SendMessages}
        closeModal={closeModalActionBtn}
      />
    </SectionContainer>
  );
};
