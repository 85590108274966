import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const ModalColorsContainer = styled.div<{
  $isActive?: boolean;
  $withSpace?: boolean;
}>`
  ${tw`flex gap-1`}

  ${({
    $withSpace
  }) => $withSpace && tw`mx-6 p-1 border rounded-lg mb-3`}

  ${({
    $isActive
  }) => ($isActive
    ? tw`border-button-blue shadow-active-theme`
    : tw`border-gray94 shadow-card-invite`)}
`;

export const ModalColorCard = styled.div<{ $bgColor: string }>`
  ${tw`h-36 flex-1 rounded shadow-card-invite p-2 flex flex-col justify-between`}

  ${({
    $bgColor
  }) => `
    background-color: ${$bgColor};
  `}
`;

export const ModalColorCardTitle = styled.div<{ $isBig?: boolean }>`
  ${tw`p-1 rounded bg-white06 font-medium text-gray7 w-max min-w-32px text-center`}

  ${({
    $isBig
  }) => ($isBig ? tw`text-base` : tw`text-xs leading-5`)}
`;

export const ModalThemeInputContainer = styled.div`
  ${tw`flex mb-4`}
`;

export const ModalThemeInput = styled.input`
  ${tw`flex-1 rounded-r px-4 py-3 border border-gray94 bg-alice-blue text-17-26 font-medium text-dark-gray placeholder:text-dim-gray outline-none`}
`;

export const ErrorMessage = styled.div`
  ${tw`text-13-21 font-medium text-red-text-error mt-1`}
`;

export const ModalButtonsContainer = styled.div`
  ${tw`flex gap-3 justify-end bg-white mt-10`}
`;

export const ModalThemeMainColor = styled.div<{ $bgColor: string }>`
  ${tw`h-56 rounded flex flex-col justify-between items-start p-2 mb-1`}

  ${({
    $bgColor
  }) => `
    background-color: ${$bgColor};
  `}
`;

export const MenuContainer = styled.div`
  ${tw`bg-white rounded-md shadow-menu-shadow w-222px p-2 flex flex-col gap-2`}
`;

export const ColorSelect = styled.button<{ $isOpen: boolean }>`
  ${tw`w-32 rounded-l flex gap-1 items-center justify-between px-4 py-3 border-l border-y text-15-20 font-medium text-dark-gray`}

  ${({
    $isOpen
  }) => ($isOpen
    ? tw`bg-gray94 border-l-light-grey border-y-light-grey`
    : tw`bg-white border-l-gray94 border-y-gray94`)}
`;

export const MenuOption = styled.button<{ $isSelected: boolean }>`
  ${tw`p-2 flex justify-between gap-1 items-center w-full rounded`}

  ${({
    $isSelected
  }) => $isSelected && tw`bg-alice-blue`}
`;

export const MenuText = styled.p<{ $isSelected: boolean }>`
  ${tw`text-17-26 font-medium text-left`}

  ${({
    $isSelected
  }) => ($isSelected ? tw`text-button-blue` : tw`text-dark-gray`)}
`;
