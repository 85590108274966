import React, {
  FC, useState, SetStateAction, Dispatch
} from 'react';
import clsx from 'clsx';
import {
  useTranslation
} from 'react-i18next';

import {
  BasicColoredList,
  EmailInviteButton,
  Spinner,
} from 'src/shared/components';
import {
  ArrowDownIcon, PowerIcon
} from 'src/shared/icons';
import {
  OrgInvitationsGroup, GroupInvitedPerson
} from 'src/redux/openapi';
import {
  useGetGroupInvites
} from 'src/entities/GroupsRoute/hooks';
import {
  PublicPin
} from 'src/widgets/PublicPin';

import {
  GroupMenu
} from '../GroupMenu';

import * as Style from './GroupWithUsersCard.styles';

interface GroupWithUsersCardProps {
  groupData: OrgInvitationsGroup;
  onInvitedUpdate: () => void;
  setGroups: Dispatch<SetStateAction<OrgInvitationsGroup[]>>;
  onGroupDelete: (id: string) => void;
  resetGroupsPagination: () => void;
}

export const GroupWithUsersCard: FC<GroupWithUsersCardProps> = ({
  groupData,
  onInvitedUpdate,
  setGroups,
  onGroupDelete,
  resetGroupsPagination,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const {
    t
  } = useTranslation();

  const toggleExpanded = () => setIsExpanded((prev) => !prev);

  const {
    id, name, invitedCount, status, isPublic
  } = groupData;

  const isGroupDeactivated = status === 'DEACTIVATED';

  const onInviteDeleteSuccess = () => {
    setGroups((groups) => groups.map((group) => (group.id === id
      ? {
        ...group,
        invitedCount: group.invitedCount - 1,
      }
      : group),),);

    onInvitedUpdate();
  };

  const {
    invites,
    sentryRef,
    displayLoader,
    removeUserInvitation,
    onInvitedListUpdate,
  } = useGetGroupInvites({
    shouldSkip: !isExpanded,
    id,
    onInvitesUpdate: onInvitedUpdate,
    onDeleteSuccess: onInviteDeleteSuccess,
  });

  const onGroupEdit = ({
    id: groupId,
    name: newName,
    location: newLocation,
    status: newStatus,
  }: Pick<
  Partial<OrgInvitationsGroup>,
  'id' | 'name' | 'location' | 'status'
  >) => {
    setGroups((groups) => groups.map((group) => (group.id === groupId
      ? {
        ...group,
        name: newName || group.name,
        location: newLocation || group.location,
        status: newStatus || group.status,
      }
      : group),),);
  };

  const onDelete = async ({
    id: userId
  }: Pick<GroupInvitedPerson, 'id'>) => {
    if (!userId) {
      return;
    }

    await removeUserInvitation(userId);
  };

  return (
    <Style.MainContainer $isDeactivated={isGroupDeactivated}>
      {isPublic && (
        <Style.ContentWrapper>
          <Style.Subtitle>
            {`${t('group.invited')}: `}

            <strong>{invitedCount}</strong>
          </Style.Subtitle>

          <PublicPin />
        </Style.ContentWrapper>
      )}

      <Style.ContentWrapper>
        <Style.GroupInfo>
          {!isPublic && (
            <Style.Subtitle>
              {`${t('group.invited')}: `}

              <strong>{invitedCount}</strong>
            </Style.Subtitle>
          )}

          <Style.Title>{name}</Style.Title>
        </Style.GroupInfo>

        {isGroupDeactivated && <PowerIcon />}

        <GroupMenu
          groupData={groupData}
          isDeactivated={isGroupDeactivated}
          onGroupEdit={onGroupEdit}
          onGroupDelete={onGroupDelete}
          resetGroupsPagination={resetGroupsPagination}
        />

        {!(!invitedCount && isGroupDeactivated) && (
          <button
            type="button"
            onClick={toggleExpanded}
          >
            <ArrowDownIcon
              className={clsx(
                'w-6 h-6',
                {
                  'rotate-180': isExpanded,
                }
              )}
            />
          </button>
        )}
      </Style.ContentWrapper>

      {isExpanded && (
        <>
          {!isGroupDeactivated && (
            <EmailInviteButton
              refetchUsers={onInvitedListUpdate}
              groupId={id}
            />
          )}

          {invites && (
            <BasicColoredList
              list={invites}
              valueKey="email"
              onDelete={onDelete}
            />
          )}

          {displayLoader && (
            <div ref={sentryRef}>
              <Spinner size={24} />
            </div>
          )}
        </>
      )}
    </Style.MainContainer>
  );
};
