import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import {
  Controller, useForm
} from 'react-hook-form';
import {
  zodResolver
} from '@hookform/resolvers/zod';
import {
  useParams
} from 'react-router-dom';

import {
  Button,
  ModalContainer,
  Spinner,
  successfulToast,
} from 'src/shared/components';
import {
  useGetInputValidation
} from 'src/shared/hooks';
import {
  usePostOrgUsersMessagesMutation
} from 'src/redux/openapi';
import {
  showWarningFromServer
} from 'src/shared/utils';

import {
  EmailNeededCheckMark
} from '../EmailNeededCheckMark';

import * as Style from './ModalSendMsgToMembers.style';

interface ModalSendMsgToMembersProps {
  closeModal: () => void;
  isOpen: boolean;
}

interface FormValues {
  message: string;
  isEmailNeeded: boolean;
}

export const ModalSendMsgToMembers: FC<ModalSendMsgToMembersProps> = ({
  closeModal,
  isOpen,
}) => {
  const {
    t
  } = useTranslation();

  const {
    sendMsgFromOrgAdminSchema
  } = useGetInputValidation();

  const {
    control,
    reset,
    formState: {
      errors
    },
    handleSubmit,
  } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      message: '',
      isEmailNeeded: false,
    },
    resolver: zodResolver(sendMsgFromOrgAdminSchema),
  });

  const handleCloseModal = () => {
    closeModal();
    reset();
  };

  const params = useParams();

  const {
    organizationId = ''
  } = params;

  const [sendMessages, {
    isLoading: isSendingMessages
  }] = usePostOrgUsersMessagesMutation();

  const onSubmit = async (data: FormValues) => {
    try {
      const resp = await sendMessages({
        id: organizationId,
        sendOrganizationUsersMessageBodySchema: {
          message: data.message,
          emailIncluded: data.isEmailNeeded,
        },
      }).unwrap();

      successfulToast(resp.message);

      handleCloseModal();
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  return (
    <ModalContainer
      modalWidth={634}
      isDarkBackground
      closeModalHandler={handleCloseModal}
      isOpen={isOpen}
      modalTitle={t('organisation.notificationForMembers')}
    >
      <Style.MainContainer>
        <div>
          <Style.Label htmlFor="OnboardingText">
            {t('organisation.writeYourNotification')}

            <Style.RedText>{' *'}</Style.RedText>
          </Style.Label>

          <Controller
            name="message"
            control={control}
            render={({
              field
            }) => {
              return (
                <Style.TextArea
                  id="OnboardingText"
                  value={field.value}
                  onChange={field.onChange}
                  placeholder={t('organisation.writeYourNotification')}
                />
              );
            }}
          />

          <Style.TextHint>
            {errors.message?.message ? (
              <Style.RedText>{errors.message.message}</Style.RedText>
            ) : (
              t('organisation.everyMemberReceiveNotifications')
            )}
          </Style.TextHint>
        </div>

        <Style.ButtonContainer>
          <Controller
            name="isEmailNeeded"
            control={control}
            render={({
              field
            }) => {
              return (
                <EmailNeededCheckMark
                  isChecked={field.value}
                  changeHandler={() => field.onChange(!field.value)}
                />
              );
            }}
          />

          <Button
            variant="big-blue"
            className="text-xl font-bold text-white !w-max shrink-0"
            onClick={handleSubmit(onSubmit)}
            disabled={isSendingMessages}
          >
            {isSendingMessages ? (
              <Spinner
                size={20}
                color="white"
              />
            ) : (
              t('buttons.sendMessage')
            )}
          </Button>
        </Style.ButtonContainer>
      </Style.MainContainer>
    </ModalContainer>
  );
};
