import React, {
  FC
} from 'react';
import {
  Trans, useTranslation
} from 'react-i18next';

import {
  getTimeAgo
} from 'src/shared/utils';
import {
  AnswerStatus
} from 'src/shared/api/notifications/types';
import {
  NotificationContainer
} from 'src/entities/NotificationsRoute';

import * as Style from './Notifications.styles';
import {
  getAnswerStatus
} from './utils';

interface OrgNotificationsProps {
  groupName: string;
  organizationName: string;
  createdAt: string;
  isNew: boolean;
  answerStatus: AnswerStatus;
  getHighlightedText: (text: string) => (string | JSX.Element)[];
}

export const OrgNotifications: FC<OrgNotificationsProps> = ({
  groupName,
  organizationName,
  createdAt,
  isNew,
  answerStatus,
  getHighlightedText,
}) => {
  const answer = getAnswerStatus(
    answerStatus,
    true
  );

  const {
    t
  } = useTranslation();

  return (
    <NotificationContainer $isNew={isNew}>
      <Style.Description>
        <Style.NotificationHeader>
          <Style.NotificationDate>
            {`${getTimeAgo(createdAt)} ${t('date.ago')}`}
          </Style.NotificationDate>

          {answer && (
            <Style.TextAnswer
              $isConfirm={answerStatus === AnswerStatus.ACCEPTED}
              $isNew={isNew}
            >
              {answer}
            </Style.TextAnswer>
          )}
        </Style.NotificationHeader>

        {!answer && (
          <Style.NotificationText>
            <Trans
              i18nKey="group.yourRequestToUpgradeGroup"
              components={{
                1: <>{getHighlightedText(groupName)}</>,
                2: (
                  <Style.NotificationBoldText>
                    {getHighlightedText(organizationName)}
                  </Style.NotificationBoldText>
                ),
              }}
            />
          </Style.NotificationText>
        )}

        {answerStatus === AnswerStatus.ACCEPTED && (
          <Style.NotificationText>
            <Trans
              i18nKey="group.hasBeenUpgradedToOrganization"
              components={{
                1: <Style.NotificationBoldText />,
              }}
              values={{
                groupName,
                organizationName,
              }}
            />
          </Style.NotificationText>
        )}

        {answerStatus === AnswerStatus.REJECTED && (
          <Style.NotificationText>
            <Trans
              i18nKey="group.upgradeRequestDeclined"
              components={{
                1: <Style.NotificationBoldText />,
              }}
              values={{
                groupName,
                organizationName,
              }}
            />
          </Style.NotificationText>
        )}
      </Style.Description>
    </NotificationContainer>
  );
};
