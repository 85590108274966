import React, {
  FC
} from 'react';

import {
  Button, ButtonProps, Spinner
} from 'src/shared/components';

interface ConfirmButtonProps extends ButtonProps {
  isLoading?: boolean;
}

export const ConfirmButton: FC<ConfirmButtonProps> = ({
  children,
  isLoading,
  ...props
}) => {
  return (
    <Button
      className="font-bold !text-15-20 !px-4 !w-max !h-9 !rounded-100px"
      {...props}
    >
      {children}

      {isLoading && (
        <Spinner
          size={15}
          color="inherit"
        />
      )}
    </Button>
  );
};
