import React, {
  FC, useMemo
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  useGetUserAchievementsQuery
} from 'src/redux/openapi';

import {
  BadgeCard
} from '../BadgeCard';
import {
  getCalibratedBadge
} from '../../utils';

import * as Style from './CalibrationFooterData.style';

export const CalibrationFooterData: FC = () => {
  const {
    t,
    i18n: {
      language
    },
  } = useTranslation();

  const {
    data: {
      data: achievementData
    } = {}
  } = useGetUserAchievementsQuery(
    {
      locked: true,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const calibratedBadge = useMemo(
    () => {
      if (!achievementData) {
        return null;
      }

      return getCalibratedBadge({
        ...achievementData,
        language,
      });
    },
    [achievementData, language]
  );

  return (
    <Style.FooterContainer>
      <Style.FooterData>
        <Style.Item22Text>{t('calibration.whatToolIsIt')}</Style.Item22Text>

        <Style.Item17Text>
          {t('calibration.calibrationExerciseText')}
        </Style.Item17Text>
      </Style.FooterData>

      {calibratedBadge && (
        <BadgeCard
          isAchieved={calibratedBadge.isAchieved}
          achievementDate={calibratedBadge.updatedAt}
          title={calibratedBadge.type}
          description={calibratedBadge.description}
        />
      )}
    </Style.FooterContainer>
  );
};
