import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const Wrapper = styled.div`
  ${tw`
      h-10 hidden md:flex items-center justify-center
      bg-white/20 rounded-full cursor-pointer gap-2
    `}
`;

export const BellContainer = styled.div<{ $isNew: boolean }>`
  ${tw`p-2 hidden md:block`}

  ${({
    $isNew
  }) => $isNew
    && tw`relative after:(
    absolute right-1 top-1 block w-4 h-4 bg-button-blue rounded-full transition-all duration-700
  )`}
`;
