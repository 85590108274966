import React, {
  FC
} from 'react';

import {
  TableView, CardsView
} from 'src/entities/Questions';

interface QuestionsProps {
  isTableView: boolean;
  isExpanded: boolean;
}

export const Questions: FC<QuestionsProps> = ({
  isTableView, isExpanded
}) => {
  return isTableView ? <TableView isExpanded={isExpanded} /> : <CardsView />;
};
