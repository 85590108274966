import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import {
  useParams
} from 'react-router-dom';

import {
  usePutOrgGroupsAndGroupIdStatusesMutation
} from 'src/redux/openapi';
import {
  ModalDelete, successfulToast
} from 'src/shared/components';
import {
  PowerIcon
} from 'src/shared/icons';
import {
  showWarningFromServer
} from 'src/shared/utils';

interface DeactivateGroupModalProps {
  id: string;
  onSuccess?: () => void;
  closeModal: () => void;
  isOpen: boolean;
}

export const DeactivateGroupModal: FC<DeactivateGroupModalProps> = ({
  id,
  onSuccess,
  closeModal,
  isOpen,
}) => {
  const {
    t
  } = useTranslation();

  const params = useParams();

  const {
    organizationId = ''
  } = params;

  const [deactivateGroup, {
    isLoading
  }] = usePutOrgGroupsAndGroupIdStatusesMutation();

  const onDelete = async () => {
    try {
      const response = await deactivateGroup({
        id: organizationId,
        groupId: id,
        updateOrganizationGroupStatusBodySchema: {
          status: 'DEACTIVATED',
        },
      }).unwrap();

      successfulToast(response.message);

      if (onSuccess) {
        onSuccess();
      }

      closeModal();
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  return (
    <ModalDelete
      isOpen={isOpen}
      closeModal={closeModal}
      title={t('group.deactivateTheGroup')}
      modalText={t('group.afterDeactivateWarning')}
      onDelete={onDelete}
      isLoading={isLoading}
      deleteButtonTitle={t('buttons.deactivate')}
      deleteButtonIcon={PowerIcon}
    />
  );
};
