import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  UserGroupRole,
  Organization,
  OrganizationMembers,
  Group,
  GroupMembers,
  OrganizationAdmins,
} from 'src/redux/openapi';
import {
  Divider, EmptyResultsMessage, Spinner
} from 'src/shared/components';
import {
  MemberRow
} from 'src/widgets/MemberRow';
import {
  StatusCount
} from 'src/widgets/StatusCount';
import {
  themeColors
} from 'src/shared/utils';

import * as Style from './MembersTabContent.styles';

interface MembersTabContentProps {
  membersData?: OrganizationMembers | GroupMembers;
  adminsData?: OrganizationAdmins;
  role: UserGroupRole;
  refetchMembers: () => void;
  organization?: Organization;
  group?: Group;
  isLoading?: boolean;
}

export const MembersTabContent: FC<MembersTabContentProps> = ({
  membersData = {},
  adminsData,
  role,
  organization,
  refetchMembers,
  group,
  isLoading,
}) => {
  const isAdmin = role !== UserGroupRole.USER;
  const isSuperAdmin = role === UserGroupRole.SUPERADMIN;

  const {
    t
  } = useTranslation();

  const {
    members = []
  } = membersData;

  const admins = 'admins' in membersData ? membersData.admins : adminsData?.admins || [];

  const totalAdminsNumber = admins?.length;

  const {
    status = null
  } = adminsData || {};

  if (isLoading) {
    return <Spinner size={24} />;
  }

  return (
    <Style.MembersContainer>
      {status && organization && <StatusCount membersStatusCount={status} />}

      <Style.MembersListContainer className="scroll-hidden">
        {!!admins.length && (
          <div>
            <Style.MemberRoleWrapper>
              <Style.MemberRole>{t('common.admins')}</Style.MemberRole>

              <Divider $color={themeColors['gray-whisper']} />
            </Style.MemberRoleWrapper>

            {admins.map((member) => (
              <MemberRow
                key={member.id}
                member={member}
                isAdminView={isAdmin}
                isSuperAdminView={isSuperAdmin}
                totalAdminsNumber={totalAdminsNumber}
                organization={organization}
                refetchMembers={refetchMembers}
                group={group}
                isMemberAdmin
              />
            ))}
          </div>
        )}

        {!!members.length && (
          <div>
            <Style.MemberRoleWrapper>
              <Style.MemberRole>{t('group.members')}</Style.MemberRole>

              <Divider $color={themeColors['gray-whisper']} />
            </Style.MemberRoleWrapper>

            {members?.map((member) => (
              <MemberRow
                key={member.id}
                member={member}
                isAdminView={isAdmin}
                isSuperAdminView={isSuperAdmin}
                totalAdminsNumber={totalAdminsNumber}
                organization={organization}
                refetchMembers={refetchMembers}
                group={group}
              />
            ))}
          </div>
        )}
      </Style.MembersListContainer>

      {!members.length && !admins.length && (
        <EmptyResultsMessage results={t('group.members').toLowerCase()} />
      )}
    </Style.MembersContainer>
  );
};
