import {
  OrganizationReducer
} from './organizationsSlice';

const organizations = (state: OrganizationReducer) => state.organizations.organizations;

const userList = (state: OrganizationReducer) => state.organizations.userList;

const orgGroupsToSetup = (state: OrganizationReducer) => state.organizations.groupsToSetUp;

const orgUsersTheme = (state: OrganizationReducer) => state.organizations.orgTheme;

const characteristics = (state: OrganizationReducer) => state.organizations.characteristics;

export const organizationSelectors = {
  organizations,
  orgGroupsToSetup,
  orgUsersTheme,
  userList,
  characteristics,
};
