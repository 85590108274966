import React, {
  FC, useState
} from 'react';
import {
  useNavigate, useParams
} from 'react-router-dom';

import {
  PageLayout
} from 'src/widgets';
import {
  ROUTE, showWarningFromServer
} from 'src/shared/utils';
import {
  usePostQuestionPostEstimationsMutation
} from 'src/redux/openapi';
import {
  successfulToast
} from 'src/shared/components';

import * as Style from './PostEstimationFeedback.styles';
import {
  FeedbackForm, FeedbackResult
} from './ui';

enum Steps {
  Form,
  Success,
  Warning,
}

export const PostEstimationFeedback: FC = () => {
  const [step, setStep] = useState<Steps>(Steps.Form);

  const navigate = useNavigate();
  const params = useParams();

  const {
    questionId = ''
  } = params;

  const [sendFeedback, {
    isLoading
  }] = usePostQuestionPostEstimationsMutation();

  const handleSendFeedback = async (feedback = '') => {
    try {
      const response = await sendFeedback({
        id: questionId,
        createQuestionPostEstimationFeedbackBodySchema: {
          feedback,
        },
      }).unwrap();

      if (step === Steps.Warning) {
        navigate(`${ROUTE.ROOT}`);
      }

      if (step === Steps.Form) {
        successfulToast(response.message);
        setStep(Steps.Success);
      }
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  const onFormSubmit = (feedback: string) => {
    if (!feedback.length) {
      setStep(Steps.Warning);

      return;
    }

    handleSendFeedback(feedback);
  };

  const onProceed = (shouldProceed: boolean) => {
    if (shouldProceed) {
      handleSendFeedback();

      return;
    }

    setStep(Steps.Form);
  };

  return (
    <PageLayout>
      <Style.ContentWrapper>
        {step === Steps.Form && (
          <FeedbackForm
            onFormSubmit={onFormSubmit}
            isSubmitLoading={isLoading}
            questionId={questionId}
          />
        )}

        {step === Steps.Success && <FeedbackResult type="success" />}

        {step === Steps.Warning && (
          <FeedbackResult
            onProceed={onProceed}
            type="warning"
            isLoading={isLoading}
          />
        )}
      </Style.ContentWrapper>
    </PageLayout>
  );
};
