import {
  emptySplitApi as api
} from '../emptyApi';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiAuth: build.query<GetApiAuthApiResponse, GetApiAuthApiArg>({
      query: () => ({
        url: `/api/auth/`,
      }),
    }),
    getApiAuthSignInExists: build.query<
    GetApiAuthSignInExistsApiResponse,
    GetApiAuthSignInExistsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/sign-in/exists`,
        params: {
          login: queryArg.login,
        },
      }),
    }),
    postApiAuthSignIn: build.mutation<
    PostApiAuthSignInApiResponse,
    PostApiAuthSignInApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/sign-in`,
        method: 'POST',
        body: queryArg.signInBodySchema,
      }),
    }),
    postApiAuthSignInGroup: build.mutation<
    PostApiAuthSignInGroupApiResponse,
    PostApiAuthSignInGroupApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/sign-in/group`,
        method: 'POST',
        body: queryArg.signInGroupAssignBodySchema,
      }),
    }),
    postApiAuthSignOut: build.mutation<
    PostApiAuthSignOutApiResponse,
    PostApiAuthSignOutApiArg
    >({
      query: () => ({
        url: `/api/auth/sign-out`,
        method: 'POST',
      }),
    }),
    postApiAuthSignUp: build.mutation<
    PostApiAuthSignUpApiResponse,
    PostApiAuthSignUpApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/sign-up`,
        method: 'POST',
        body: queryArg.signUpBodySchema,
      }),
    }),
    postApiAuthForgotPasswordRequest: build.mutation<
    PostApiAuthForgotPasswordRequestApiResponse,
    PostApiAuthForgotPasswordRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/forgot-password/request`,
        method: 'POST',
        body: queryArg.forgotPasswordRequestBodySchema,
      }),
    }),
    postApiAuthForgotPasswordVerify: build.mutation<
    PostApiAuthForgotPasswordVerifyApiResponse,
    PostApiAuthForgotPasswordVerifyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/forgot-password/verify`,
        method: 'POST',
        body: queryArg.forgotPasswordVerifyBodySchema,
      }),
    }),
    postApiAuthForgotPasswordReset: build.mutation<
    PostApiAuthForgotPasswordResetApiResponse,
    PostApiAuthForgotPasswordResetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/forgot-password/reset`,
        method: 'POST',
        body: queryArg.forgotPasswordResetBodySchema,
      }),
    }),
    postApiAuthForgotPasswordResend: build.mutation<
    PostApiAuthForgotPasswordResendApiResponse,
    PostApiAuthForgotPasswordResendApiArg
    >({
      query: () => ({
        url: `/api/auth/forgot-password/resend`,
        method: 'POST',
      }),
    }),
    postApiAuthProvidersSignUp: build.mutation<
    PostApiAuthProvidersSignUpApiResponse,
    PostApiAuthProvidersSignUpApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/providers/sign-up`,
        method: 'POST',
        body: queryArg.providersSignUpBodySchema,
      }),
    }),
    getApiAuthProvidersGoogle: build.query<
    GetApiAuthProvidersGoogleApiResponse,
    GetApiAuthProvidersGoogleApiArg
    >({
      query: () => ({
        url: `/api/auth/providers/google`,
      }),
    }),
    getApiAuthProvidersGoogleCallback: build.query<
    GetApiAuthProvidersGoogleCallbackApiResponse,
    GetApiAuthProvidersGoogleCallbackApiArg
    >({
      query: () => ({
        url: `/api/auth/providers/google/callback`,
      }),
    }),
    getApiAuthProvidersTwitter: build.query<
    GetApiAuthProvidersTwitterApiResponse,
    GetApiAuthProvidersTwitterApiArg
    >({
      query: () => ({
        url: `/api/auth/providers/twitter`,
      }),
    }),
    getApiAuthProvidersTwitterCallback: build.query<
    GetApiAuthProvidersTwitterCallbackApiResponse,
    GetApiAuthProvidersTwitterCallbackApiArg
    >({
      query: () => ({
        url: `/api/auth/providers/twitter/callback`,
      }),
    }),
    getApiAuthProvidersLinkedin: build.query<
    GetApiAuthProvidersLinkedinApiResponse,
    GetApiAuthProvidersLinkedinApiArg
    >({
      query: () => ({
        url: `/api/auth/providers/linkedin`,
      }),
    }),
    getApiAuthProvidersLinkedinCallback: build.query<
    GetApiAuthProvidersLinkedinCallbackApiResponse,
    GetApiAuthProvidersLinkedinCallbackApiArg
    >({
      query: () => ({
        url: `/api/auth/providers/linkedin/callback`,
      }),
    }),
    getApiAuthProvidersFacebook: build.query<
    GetApiAuthProvidersFacebookApiResponse,
    GetApiAuthProvidersFacebookApiArg
    >({
      query: () => ({
        url: `/api/auth/providers/facebook`,
      }),
    }),
    getApiAuthProvidersFacebookCallback: build.query<
    GetApiAuthProvidersFacebookCallbackApiResponse,
    GetApiAuthProvidersFacebookCallbackApiArg
    >({
      query: () => ({
        url: `/api/auth/providers/facebook/callback`,
      }),
    }),
  }),
  overrideExisting: false,
});

export {
  injectedRtkApi as arqApiAuth
};
export type GetApiAuthApiResponse =
  /** status 200 Default Response */ CheckAuthResponseSchema;
export type GetApiAuthApiArg = void;
export type GetApiAuthSignInExistsApiResponse =
  /** status 200 Default Response */ SignInExistsResponseSchema;
export type GetApiAuthSignInExistsApiArg = {
  login: string;
};
export type PostApiAuthSignInApiResponse =
  /** status 200 Default Response */ SignInResponseSchema;
export type PostApiAuthSignInApiArg = {
  signInBodySchema: SignInBodySchema;
};
export type PostApiAuthSignInGroupApiResponse =
  /** status 200 Default Response */ SignInResponseSchema;
export type PostApiAuthSignInGroupApiArg = {
  signInGroupAssignBodySchema: SignInGroupAssignBodySchema;
};
export type PostApiAuthSignOutApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PostApiAuthSignOutApiArg = void;
export type PostApiAuthSignUpApiResponse =
  /** status 200 Default Response */ SignUpResponseSchema;
export type PostApiAuthSignUpApiArg = {
  signUpBodySchema: SignUpBodySchema;
};
export type PostApiAuthForgotPasswordRequestApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PostApiAuthForgotPasswordRequestApiArg = {
  forgotPasswordRequestBodySchema: ForgotPasswordRequestBodySchema;
};
export type PostApiAuthForgotPasswordVerifyApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PostApiAuthForgotPasswordVerifyApiArg = {
  forgotPasswordVerifyBodySchema: ForgotPasswordVerifyBodySchema;
};
export type PostApiAuthForgotPasswordResetApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PostApiAuthForgotPasswordResetApiArg = {
  forgotPasswordResetBodySchema: ForgotPasswordResetBodySchema;
};
export type PostApiAuthForgotPasswordResendApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PostApiAuthForgotPasswordResendApiArg = void;
export type PostApiAuthProvidersSignUpApiResponse =
  /** status 200 Default Response */ ProvidersSignUpResponseSchema;
export type PostApiAuthProvidersSignUpApiArg = {
  providersSignUpBodySchema: ProvidersSignUpBodySchema;
};
export type GetApiAuthProvidersGoogleApiResponse = unknown;
export type GetApiAuthProvidersGoogleApiArg = void;
export type GetApiAuthProvidersGoogleCallbackApiResponse = unknown;
export type GetApiAuthProvidersGoogleCallbackApiArg = void;
export type GetApiAuthProvidersTwitterApiResponse = unknown;
export type GetApiAuthProvidersTwitterApiArg = void;
export type GetApiAuthProvidersTwitterCallbackApiResponse = unknown;
export type GetApiAuthProvidersTwitterCallbackApiArg = void;
export type GetApiAuthProvidersLinkedinApiResponse = unknown;
export type GetApiAuthProvidersLinkedinApiArg = void;
export type GetApiAuthProvidersLinkedinCallbackApiResponse = unknown;
export type GetApiAuthProvidersLinkedinCallbackApiArg = void;
export type GetApiAuthProvidersFacebookApiResponse = unknown;
export type GetApiAuthProvidersFacebookApiArg = void;
export type GetApiAuthProvidersFacebookCallbackApiResponse = unknown;
export type GetApiAuthProvidersFacebookCallbackApiArg = void;
export type CheckAuthResponseSchema = {
  data: {
    isAuthenticated: boolean;
  };
};
export type SignInExistsResponseSchema = {
  data: {
    userExists: boolean;
  };
};
export type User = {
  id: string;
  name: string;
  email: string;
  avatarUrl: string | null;
  displayName: string | null;
  description: string | null;
  role: 'ADMIN' | 'USER';
  fullName: string | null;
};
export type Group = {
  id: string;
  name: string;
  location: string | null;
} | null;
export type SignInResponseSchema = {
  message: string;
  data: {
    user: User;
    group: Group;
    userBlocked: boolean;
    isGroupAssigned: boolean | null;
    reverificationRequired: boolean;
    verificationRequired: {
      email: string;
    } | null;
  };
};
export type SignInBodySchema = {
  login: string;
  password: string;
};
export type SignInGroupAssignBodySchema = {
  name: string;
  location: string | null;
  questionIds?: string[];
};
export type MessageResponseSchema = {
  message: string;
};
export type SignUpResponseSchema = {
  message: string;
  data: {
    user: {
      id: string;
      name: string;
      email: string;
      avatarUrl: string | null;
      displayName: string | null;
      description: string | null;
      role: 'ADMIN' | 'USER';
      fullName: string | null;
    };
    group: {
      id: string;
      name: string;
      location: string | null;
    } | null;
    verificationRequired: {
      email: string;
    } | null;
  };
};
export type SignUpBodySchema = {
  email: string;
  password: string;
  username: string;
  fullName: string;
  agreement: boolean;
};
export type ForgotPasswordRequestBodySchema = {
  email: string;
};
export type ForgotPasswordVerifyBodySchema = {
  code: string;
};
export type Password = string;
export type ForgotPasswordResetBodySchema = {
  password: Password;
};
export type ProvidersSignUpResponseSchema = {
  message: string;
  data: {
    user: User;
  };
};
export type Username = string;
export type ProvidersSignUpBodySchema = {
  username: Username;
  agreement: boolean;
};
export const {
  useGetApiAuthQuery,
  useGetApiAuthSignInExistsQuery,
  usePostApiAuthSignInMutation,
  usePostApiAuthSignInGroupMutation,
  usePostApiAuthSignOutMutation,
  usePostApiAuthSignUpMutation,
  usePostApiAuthForgotPasswordRequestMutation,
  usePostApiAuthForgotPasswordVerifyMutation,
  usePostApiAuthForgotPasswordResetMutation,
  usePostApiAuthForgotPasswordResendMutation,
  usePostApiAuthProvidersSignUpMutation,
  useGetApiAuthProvidersGoogleQuery,
  useGetApiAuthProvidersGoogleCallbackQuery,
  useGetApiAuthProvidersTwitterQuery,
  useGetApiAuthProvidersTwitterCallbackQuery,
  useGetApiAuthProvidersLinkedinQuery,
  useGetApiAuthProvidersLinkedinCallbackQuery,
  useGetApiAuthProvidersFacebookQuery,
  useGetApiAuthProvidersFacebookCallbackQuery,
} = injectedRtkApi;
