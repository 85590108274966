import React, {
  FC
} from 'react';
import i18next from 'i18next';

import {
  CircleButton
} from 'src/shared/components';
import {
  ArrowLeftIcon, ArrowRightIcon
} from 'src/shared/icons';

import * as Style from './DatePicker.styles';
import {
  getMonthName
} from './utils';

interface CalendarHeaderProps {
  date: Date;
  decreaseMonth: () => void;
  increaseMonth: () => void;
}

export const CalendarHeader: FC<CalendarHeaderProps> = ({
  date,
  decreaseMonth,
  increaseMonth,
}) => {
  const {
    language
  } = i18next;

  const month = getMonthName(
    date,
    language
  );

  const year = date.getFullYear();

  return (
    <Style.CalendarHeader>
      <CircleButton
        onClick={decreaseMonth}
        size={48}
      >
        <ArrowLeftIcon />
      </CircleButton>

      <Style.CalendarHeaderInfo>
        <Style.HeaderYear>{year}</Style.HeaderYear>

        <Style.HeaderMonth>{month}</Style.HeaderMonth>
      </Style.CalendarHeaderInfo>

      <CircleButton
        onClick={increaseMonth}
        size={48}
      >
        <ArrowRightIcon />
      </CircleButton>
    </Style.CalendarHeader>
  );
};
