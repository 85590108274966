import {
  ShieldOffIcon,
  MessageIcon,
  ArchiveIcon,
  FlagIcon,
} from 'src/shared/icons';

enum ReasonReport {
  LEAKAGE = 'LEAKAGE',
  INAPPROPRIATE = 'INAPPROPRIATE',
  SPAM = 'SPAM',
  OTHER = 'OTHER',
}

enum LabelReport {
  LEAKAGE = 'labelLeakage',
  INAPPROPRIATE = 'labelInappropriate',
  SPAM = 'labelSpam',
  OTHER = 'labelOther',
}

enum DescriptionReport {
  LEAKAGE = 'descriptionLeakage',
  INAPPROPRIATE = 'descriptionInappropriate',
  SPAM = 'descriptionSpam',
  OTHER = 'descriptionOther',
}

interface CheckBoxDataType {
  value: ReasonReport;
  label: LabelReport;
  description: DescriptionReport;
  icon: React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined;
  }
  >;
}

export const CHECKBOX_DATA_MAP: CheckBoxDataType[] = [
  {
    value: ReasonReport.LEAKAGE,
    label: LabelReport.LEAKAGE,
    description: DescriptionReport.LEAKAGE,
    icon: ShieldOffIcon,
  },
  {
    value: ReasonReport.INAPPROPRIATE,
    label: LabelReport.INAPPROPRIATE,
    description: DescriptionReport.INAPPROPRIATE,
    icon: MessageIcon,
  },
  {
    value: ReasonReport.SPAM,
    label: LabelReport.SPAM,
    description: DescriptionReport.SPAM,
    icon: ArchiveIcon,
  },
  {
    value: ReasonReport.OTHER,
    label: LabelReport.OTHER,
    description: DescriptionReport.OTHER,
    icon: FlagIcon,
  },
];
