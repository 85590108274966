import React, {
  FC, useMemo, useState
} from 'react';

import {
  useGetAdminRequestsByIdQuery,
  useAcceptOrgRequestMutation,
  useDeclineOrgRequestMutation,
  AcceptOrganizationRequest,
} from 'src/redux/openapi';
import {
  Button,
  ModalContainer,
  ScrollContainer,
  Spinner,
  successfulToast,
} from 'src/shared/components';
import {
  PeopleIcon
} from 'src/shared/icons';
import {
  showWarningFromServer
} from 'src/shared/utils';
import {
  CreateOrganizationModal,
  CreateOrganizationAdmin,
} from 'src/features/Modals';

import * as Style from './RequestModal.styles';

interface RequestModalProps {
  isOpen: boolean;
  closeModal: () => void;
  id: string;
  refetch: () => void;
  onAcceptClick: () => void;
}

export const RequestModal: FC<RequestModalProps> = ({
  isOpen,
  closeModal,
  id,
  refetch,
  onAcceptClick,
}) => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const toggleCreateModal = () => setIsCreateModalOpen((prev) => !prev);

  const {
    data, isLoading, isFetching
  } = useGetAdminRequestsByIdQuery(
    {
      id,
    },
    {
      skip: !id || !isOpen,
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    group, organization, description
  } = data?.data.groupRequest || {};

  const {
    name: groupName, _count: count, users
  } = group || {};

  const {
    name: organizationName
  } = organization || {};

  const groupAdmin = users?.find((user) => user.role === 'SUPERADMIN')?.user
    .email;

  const [acceptCreation, {
    isLoading: isAcceptLoading
  }] = useAcceptOrgRequestMutation();

  const [declineCreation, {
    isLoading: isDeclineLoading
  }] = useDeclineOrgRequestMutation();

  const onActionSuccess = (message: string) => {
    successfulToast(message);
    refetch();
    closeModal();
  };

  const onAccept = async (updatedOrganization: AcceptOrganizationRequest) => {
    try {
      const response = await acceptCreation({
        id,
        acceptGroupToOrganizationBodySchema: {
          organization: updatedOrganization,
        },
      }).unwrap();

      onActionSuccess(response.message);
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  const onDecline = async () => {
    try {
      const response = await declineCreation({
        id,
      }).unwrap();

      onActionSuccess(response.message);
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  const handleAccept = () => {
    onAcceptClick();
    toggleCreateModal();
  };

  const admins: CreateOrganizationAdmin[] = useMemo(
    () => [
      {
        email: groupAdmin || '',
        role: 'SUPERADMIN',
      },
    ],
    [groupAdmin],
  );

  return (
    <>
      <ModalContainer
        closeModalHandler={closeModal}
        isOpen={isOpen}
        modalTitle="Request to upgrade group to organization"
      >
        <Style.ContentContainer>
          {isLoading || isFetching ? (
            <Spinner />
          ) : (
            <>
              <Style.FieldContainer>
                <Style.GroupInfoContainer>
                  <Style.GroupIcon>
                    <PeopleIcon />
                  </Style.GroupIcon>

                  <Style.GroupInfo>
                    <Style.GroupInfoHeader>
                      <p>{groupName}</p>

                      <p>
                        <Style.MemberSpan>{`Members: `}</Style.MemberSpan>

                        {count?.users || 0}
                      </p>
                    </Style.GroupInfoHeader>

                    <Style.GroupAdmin>{groupAdmin}</Style.GroupAdmin>
                  </Style.GroupInfo>
                </Style.GroupInfoContainer>
              </Style.FieldContainer>

              <div>
                <Style.FieldTitle>Organisation name</Style.FieldTitle>

                <Style.FieldContainer>{organizationName}</Style.FieldContainer>
              </div>

              <div>
                <Style.FieldTitle>About Organisation</Style.FieldTitle>

                <ScrollContainer
                  edgeHeight={34}
                  className="border border-input-blue rounded max-h-192px"
                  visibleScroll
                >
                  <Style.Description $isEmpty={!description}>
                    {description || 'Empty...'}
                  </Style.Description>
                </ScrollContainer>
              </div>

              <Style.ButtonsContainer>
                <Button
                  variant="big-grey-bordered"
                  onClick={closeModal}
                  className="!w-28"
                >
                  <Style.ButtonsText>Cancel</Style.ButtonsText>
                </Button>

                <Style.ActionButtons>
                  <Button
                    variant="big-red"
                    onClick={onDecline}
                    disabled={isDeclineLoading}
                  >
                    <Style.ButtonsText>Decline</Style.ButtonsText>

                    {isDeclineLoading && (
                      <Spinner
                        color="white"
                        size={24}
                        centered={false}
                      />
                    )}
                  </Button>

                  <Button
                    variant="big-blue"
                    onClick={handleAccept}
                  >
                    <Style.ButtonsText>Create</Style.ButtonsText>

                    {isAcceptLoading && (
                      <Spinner
                        color="white"
                        size={24}
                        centered={false}
                      />
                    )}
                  </Button>
                </Style.ActionButtons>
              </Style.ButtonsContainer>
            </>
          )}
        </Style.ContentContainer>
      </ModalContainer>

      <CreateOrganizationModal
        isOpen={isCreateModalOpen}
        onClose={toggleCreateModal}
        onSuccess={refetch}
        name={organizationName}
        admins={admins}
        customCreateMethod={onAccept}
      />
    </>
  );
};
