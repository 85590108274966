import {
  UserCharacteristic
} from 'src/redux/openapi';

export const getSelectValues = (characteristics: UserCharacteristic[]) => characteristics.map(({
  key: {
    id
  }, value
}) => ({
  id,
  selectedOption: {
    value: value.id,
    label: value.value,
  },
}));
