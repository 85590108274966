import React, {
  FC, PropsWithChildren
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  PeopleIcon
} from 'src/shared/icons';
import {
  EditButton, SearchInput, Tooltip
} from 'src/shared/components';
import {
  QuestionsFilter
} from 'src/widgets';

import * as Style from './GroupRoute.styles';

interface GroupSideBlockContainerProps extends PropsWithChildren {
  title: string;
  isMemberBlock: boolean;
  searchValue: string;
  handleSetSearchValue: (v: string) => void;
  handleOpenEdit?: () => void;
  isAdmin?: boolean;
  isDeactivated?: boolean;
}

export const GroupSideBlockContainer: FC<GroupSideBlockContainerProps> = ({
  children,
  title,
  isMemberBlock,
  searchValue,
  handleSetSearchValue,
  handleOpenEdit,
  isAdmin,
  isDeactivated = false,
}) => {
  const {
    t
  } = useTranslation();

  return (
    <Style.SideBlockContainer $isRelative={isMemberBlock}>
      <Style.SideBlockMainContainer>
        <Style.SideBlockInfoRow>
          <Style.SideBlockTitleContainer>
            {isMemberBlock && <PeopleIcon />}

            <Style.GroupDetailTitle>{title}</Style.GroupDetailTitle>
          </Style.SideBlockTitleContainer>

          <Style.SideBlockToolsContainer>
            {!isMemberBlock && <QuestionsFilter />}

            <SearchInput
              value={searchValue}
              onChange={handleSetSearchValue}
              placeholder={
                isMemberBlock
                  ? t('common.startTypingName')
                  : `${t('common.startTyping')}...`
              }
            />

            {!isMemberBlock && isAdmin && handleOpenEdit && (
              <Tooltip
                disabled={!isDeactivated}
                message={(
                  <Style.EditQuestionTooltip>
                    {t('group.editTooltipHint')}
                  </Style.EditQuestionTooltip>
                )}
              >
                <EditButton onClick={handleOpenEdit} />
              </Tooltip>
            )}
          </Style.SideBlockToolsContainer>
        </Style.SideBlockInfoRow>

        {children}
      </Style.SideBlockMainContainer>
    </Style.SideBlockContainer>
  );
};
