import {
  themeColors
} from 'src/shared/utils/themeColors';

import {
  Variant, TooltipVariant
} from './types';

export const getStyles = (variant?: TooltipVariant) => {
  switch (variant) {
    case Variant.light:
      return {
        backgroundColor: themeColors.white,
        borderColor: themeColors['gray-whisper'],
        borderWidth: 1,
        zIndex: 20,
      };

    case Variant.black:
      return {
        backgroundColor: themeColors.black,
        borderWidth: 0,
        zIndex: 20,
      };
    case Variant.darkGray:
      return {
        backgroundColor: themeColors['dark-gray'],
        borderWidth: 0,
        zIndex: 20,
      };

    case Variant.dark:
    default:
      return {
        backgroundColor: themeColors['dim-gray'],
        borderWidth: 0,
        zIndex: 20,
      };
  }
};
