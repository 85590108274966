import React, {
  FC, useState
} from 'react';
import clsx from 'clsx';
import {
  useTranslation
} from 'react-i18next';

import {
  useTypedSelector
} from 'src/redux';
import {
  userSelectors
} from 'src/redux/user';
import {
  Avatar, CircleButton, PopoverMenu
} from 'src/shared/components';
import {
  CloseIcon,
  EditPencilIcon,
  LogOutIcon,
  MoreVerticalIcon,
  TrashDeleteIcon,
} from 'src/shared/icons';
import {
  themeColors
} from 'src/shared/utils';
import {
  ChangeLanguageBlock
} from 'src/widgets/MyProfileMenu/components';
import {
  useSignOut
} from 'src/shared/hooks';

import {
  DeleteProfileModal
} from '../DeleteProfileModal';

import * as Style from './MenuHeader.style';

interface MenuHeaderProps {
  onClose: () => void;
  onEdit: () => void;
  onModalToggle: () => void;
}

export const MenuHeader: FC<MenuHeaderProps> = ({
  onClose,
  onEdit,
  onModalToggle,
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const {
    t
  } = useTranslation();

  const {
    avatarUrl: avatarSrc,
    name,
    displayName,
    description,
  } = useTypedSelector(userSelectors.user);

  const userName = displayName || name;

  const {
    isLoading, signOut
  } = useSignOut();

  const toggleModal = () => {
    setIsDeleteModalOpen((prev) => !prev);
    onModalToggle();
  };

  return (
    <>
      <Style.MenuHeader>
        <Avatar
          src={avatarSrc}
          userName={userName}
          size={56}
        />

        <Style.UserNameContainer>
          <Style.UserName>{userName}</Style.UserName>

          <Style.UserDescription>{description}</Style.UserDescription>
        </Style.UserNameContainer>

        <ChangeLanguageBlock />

        <PopoverMenu
          placement="bottom-end"
          renderCustomButton={({
            isOpen, toggleMenu, triggerProps
          }) => (
            <CircleButton
              onClick={toggleMenu}
              borderColor={
                isOpen ? themeColors['button-blue'] : themeColors.gray90
              }
              {...triggerProps}
            >
              <MoreVerticalIcon
                className={clsx(
                  'w-18px h-18px',
                  {
                    'text-dark-gray': !isOpen,
                    'text-button-blue': isOpen,
                  }
                )}
              />
            </CircleButton>
          )}
        >
          <Style.MoreMenuContainer>
            <Style.MoreMenuItem onClick={onEdit}>
              <EditPencilIcon />

              {t('profile.editAccount')}
            </Style.MoreMenuItem>

            <Style.MoreMenuItem
              onClick={signOut}
              disabled={isLoading}
            >
              <LogOutIcon />

              {t('profile.logOut')}
            </Style.MoreMenuItem>

            <Style.Divider />

            <Style.MoreMenuItem
              $color={themeColors['red-text-error']}
              onClick={toggleModal}
            >
              <TrashDeleteIcon />

              {t('profile.deleteAccount')}
            </Style.MoreMenuItem>
          </Style.MoreMenuContainer>
        </PopoverMenu>

        <CircleButton onClick={onClose}>
          <CloseIcon className="w-6 h-6 text-dark-gray" />
        </CircleButton>
      </Style.MenuHeader>

      <DeleteProfileModal
        isOpen={isDeleteModalOpen}
        onClose={toggleModal}
      />
    </>
  );
};
