import React, {
  FC
} from 'react';

import {
  ChartIcon
} from 'src/shared/icons';

import * as Style from './HeaderRow.styles';

interface HeaderRowProps {
  question: string;
  date?: string;
  datePrefix?: string;
  isTable?: boolean;
  isSurvey?: boolean;
}

export const HeaderRow: FC<HeaderRowProps> = ({
  question,
  date,
  isTable = false,
  isSurvey,
  datePrefix,
}) => {
  return (
    <Style.HeaderContainer $isTable={isTable}>
      <Style.Text>
        {isSurvey && <ChartIcon className="shrink-0" />}

        {question}
      </Style.Text>

      {date && (
        <Style.Date>
          {datePrefix && <b>{`${datePrefix} - `}</b>}

          {date}
        </Style.Date>
      )}
    </Style.HeaderContainer>
  );
};
