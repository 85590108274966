import React, {
  FC
} from 'react';
import {
  Controller, UseFormReturn
} from 'react-hook-form';
import {
  useTranslation
} from 'react-i18next';

import {
  LocationSelect
} from 'src/features/LocationSelect';
import {
  Input
} from 'src/shared/components';
import {
  themeColors
} from 'src/shared/utils';

import {
  CreateGroupForm
} from '../../types';

import * as Style from './CreateNewGroupForm.style';

interface CreateNewGroupFormProps {
  formConfig: UseFormReturn<CreateGroupForm>;
}

export const CreateNewGroupForm: FC<CreateNewGroupFormProps> = ({
  formConfig,
}) => {
  const {
    t
  } = useTranslation();

  const {
    control,
    formState: {
      errors
    },
  } = formConfig;

  return (
    <Style.FormContainer>
      <Controller
        name="groupName"
        control={control}
        render={({
          field
        }) => (
          <Input
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            isRequired
            placeholder={t(
              'inputMessages.MAXIMUM_CHARACTERS',
              {
                value: 30,
              }
            )}
            errorMessage={errors.groupName?.message}
            className="!bg-transparent !text-gray7 !text-base placeholder:!text-dim-gray font-medium"
          />
        )}
      />

      <Controller
        name="groupLocation"
        control={control}
        render={({
          field
        }) => (
          <LocationSelect
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            errorMessage={errors.groupLocation?.value?.message}
            hideIndicators
            isModal
            isRequired
            styles={{
              control: (base) => ({
                ...base,
                fontSize: '16px',
                color: themeColors['dim-gray'],
              }),
              valueContainer: (base) => ({
                ...base,
                padding: 0,
              }),
            }}
          />
        )}
      />
    </Style.FormContainer>
  );
};
