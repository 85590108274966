import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const Asterisk = styled.span`
  ${tw`text-red-label`}
`;

export const Label = styled.label`
  ${tw`flex flex-col gap-2`}
`;

export const LabelText = styled.p`
  ${tw`font-medium text-15-20 text-grey-dark-40`}
`;

export const ErrorMessage = styled.p`
  ${tw`text-13-21 font-medium text-red-text-error mt-1`}
`;
