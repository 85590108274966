import React, {
  FC, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  PlusIcon
} from 'src/shared/icons';

import {
  GroupCard
} from '../GroupCard';
import {
  Group
} from '../../types';
import {
  NewGroupCard
} from '../GroupCard/NewGroupCard';

import * as Style from './GroupsList.styles';

interface GroupsListProps {
  groups: Group[];
  onGroupAdded: (
    id: string,
    name: string,
    location: string,
    isPublic: boolean,
  ) => void;
}

export const GroupsList: FC<GroupsListProps> = ({
  groups, onGroupAdded
}) => {
  const {
    t
  } = useTranslation();

  const [isAddNewGroup, setIsAddNewGroup] = useState(false);

  const handleCloseAddNewGroup = () => {
    setIsAddNewGroup(false);
  };

  return (
    <Style.ListContainer>
      {groups.map((group) => (
        <GroupCard
          key={group.name}
          group={group}
        />
      ))}

      {isAddNewGroup && (
        <NewGroupCard
          close={handleCloseAddNewGroup}
          onGroupAdded={onGroupAdded}
        />
      )}

      <Style.AddButton
        type="button"
        onClick={() => {
          setIsAddNewGroup(true);
        }}
      >
        {t('organisation.addGroup')}

        <PlusIcon />
      </Style.AddButton>
    </Style.ListContainer>
  );
};
