import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MembersContainer = styled.div`
  ${tw`pb-6 h-full overflow-auto flex flex-col`}
`;

export const MembersListContainer = styled.div`
  ${tw`flex flex-col gap-6 overflow-auto mt-6`}
`;

export const MemberRoleWrapper = styled.div`
  ${tw`flex gap-2 items-center mb-3`}
`;

export const MemberRole = styled.p`
  ${tw`min-w-14 font-medium text-xs text-dim-gray`}
`;
