import React, {
  FC, MouseEvent, useMemo, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  Button
} from 'src/shared/components/Button';
import {
  PlusRoundedIcon
} from 'src/shared/icons';
import {
  useDeleteOrganizationThemeMutation,
  useGetOrganizationThemesQuery,
  usePostOrganizationThemeMutation,
  usePutOrganizationThemeMutation,
} from 'src/redux/openapi';
import {
  showWarningFromServer
} from 'src/shared/utils';
import {
  successfulToast
} from 'src/shared/components';
import {
  ThemeColorsRow,
  ModalDeleteTheme,
  ModalAddNewTheme,
} from 'src/shared/components/OrganizationSettingsModal/UI';

import * as Style from './OrgThemes.style';

interface OrgThemesProps {
  openModalHandler: () => void;
  closeModalHandler: () => void;
  id: string;
  isOpenModal: boolean;
}

export const OrgThemes: FC<OrgThemesProps> = ({
  openModalHandler,
  closeModalHandler,
  id,
  isOpenModal,
}) => {
  const {
    t
  } = useTranslation();

  const {
    data: themesData, refetch: refetchThemesData
  } = useGetOrganizationThemesQuery(
    {
      id,
    },
    {
      skip: !id,
      refetchOnMountOrArgChange: true,
    },
  );

  const orgThemesData = useMemo(
    () => {
      return themesData?.data.themes || [];
    },
    [themesData]
  );

  const [addNewColorValue, {
    isLoading: isAddNewColorValueLoading
  }] = usePostOrganizationThemeMutation();

  const addNewCustomTheme = async (newTheme: string, newThemeName: string) => {
    try {
      const {
        message
      } = await addNewColorValue({
        id,
        createOrganizationThemeBodySchema: {
          colorPrimary: newTheme,
          name: newThemeName,
        },
      }).unwrap();

      successfulToast(message);

      refetchThemesData();
      closeModalHandler();
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  const [setColorValues, {
    isLoading: isSetColorValuesLoading
  }] = usePutOrganizationThemeMutation();

  const [deleteColorValue, {
    isLoading: isDeleteColorValueLoading
  }] = useDeleteOrganizationThemeMutation();

  const setCustomTheme = async (newThemeId: string) => {
    try {
      const {
        message
      } = await setColorValues({
        id,
        updateOrganizationThemeBodySchema: {
          themeId: newThemeId,
        },
      }).unwrap();

      successfulToast(message);
      refetchThemesData();
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  const [deleteThemeId, setDeleteThemeId] = useState<string | null>(null);

  const closeDeleteModalHandler = () => {
    setDeleteThemeId(null);
  };

  const handleDeleteTheme = (e: MouseEvent, themeId: string) => {
    e.stopPropagation();
    setDeleteThemeId(themeId);
  };

  const handleDeleteClick = async () => {
    if (!deleteThemeId) {
      return;
    }

    try {
      const response = await deleteColorValue({
        id,
        themeId: deleteThemeId,
      }).unwrap();

      successfulToast(response.message);

      refetchThemesData();
      closeDeleteModalHandler();
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  return (
    <>
      <Style.ModalThemeTitleRow>
        <Style.ModalThemeTitle>
          {t('organisation.themes')}
        </Style.ModalThemeTitle>

        <Button
          type="button"
          variant="big-grey-bordered"
          className="w-max !px-4 !py-2.5 text-15-20 font-bold text-gray7"
          onClick={openModalHandler}
        >
          {t('organisation.addNewTheme')}

          <PlusRoundedIcon />
        </Button>
      </Style.ModalThemeTitleRow>

      {orgThemesData.map((theme) => (
        <ThemeColorsRow
          key={theme.id}
          theme={theme}
          isLoading={isSetColorValuesLoading}
          setCustomTheme={setCustomTheme}
          handleDeleteTheme={handleDeleteTheme}
        />
      ))}

      <ModalAddNewTheme
        isOpen={isOpenModal}
        closeModalHandler={closeModalHandler}
        setTheme={addNewCustomTheme}
        isLoading={isAddNewColorValueLoading}
      />

      <ModalDeleteTheme
        closeModal={closeDeleteModalHandler}
        isOpen={!!deleteThemeId}
        isLoading={isDeleteColorValueLoading}
        handleDeleteClick={handleDeleteClick}
      />
    </>
  );
};
