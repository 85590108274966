import {
  useEffect, useMemo, useState
} from 'react';
import {
  useSearchParams
} from 'react-router-dom';

import {
  topicId,
  questionView,
  questionsSortBy,
  questionsFilterBy,
  questionsTimeRange,
  getFilteredTimeRange,
  showWarningFromServer,
  FAVORITE,
} from 'src/shared/utils';
import {
  getQuestionSortBy
} from 'src/widgets/QuestionsFilter/utils';
import {
  HomeQuestionSortBy
} from 'src/shared/api/types';
import {
  TimeSpansValues
} from 'src/redux/timeSpans';
import {
  useGetQuestionStatisticsActiveMutation,
  useGetActiveStatisticsActiveQuery,
  useGetAvailableStatisticsQuery,
  useGetDeactivatedStatisticsQuery,
  QuestionsActiveStatistics,
  QuestionsAvailableStatistics,
  QuestionsUpdatesPer,
} from 'src/redux/openapi';

import {
  QuestionsView, FilterBy, SortBy
} from '../types';

const TABLE_VIEW_LIMIT = 8;
const AVAILABLE_LIMIT = 6;
const ACTIVE_OR_DEACTIVATED_LIMIT = 4;

export const useGetQuestions = () => {
  const [searchParams] = useSearchParams();

  const [availablePage, setAvailablePage] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [deactivatedPage, setDeactivatedPage] = useState(1);

  const [active, setActive] = useState<QuestionsActiveStatistics[]>([]);

  const [available, setAvailable] = useState<QuestionsAvailableStatistics[]>(
    [],
  );

  const [deactivated, setDeactivated] = useState<QuestionsActiveStatistics[]>(
    [],
  );

  const [isActiveMoreLoading, setIsActiveMoreLoading] = useState(false);
  const [isAvailableMoreLoading, setIsAvailableMoreLoading] = useState(false);

  const [isDeactivatedMoreLoading, setIsDeactivatedMoreLoading] = useState(false);

  const [isReloading, setIsReloading] = useState(false);

  const activeFilterId = searchParams.get(topicId);
  const isTableView = searchParams.get(questionView) === QuestionsView.Table;

  const filterParam = (searchParams.get(questionsFilterBy) as FilterBy)
    || (isTableView ? FilterBy.Active : null);

  const sortParam = searchParams.get(questionsSortBy) as SortBy;

  const timeRangeParam = searchParams.get(
    questionsTimeRange,
  ) as TimeSpansValues;

  const questionFilters = useMemo(
    () => {
      const sortByResult = getQuestionSortBy<HomeQuestionSortBy>(
        sortParam || SortBy.Newest,
        true,
      );

      const updatesPer = timeRangeParam
        ? (getFilteredTimeRange(timeRangeParam) as QuestionsUpdatesPer)
        : undefined;

      return {
        ...sortByResult,
        updatesPer,
      };
    },
    [sortParam, timeRangeParam]
  );

  const activeLimit = isTableView
    ? TABLE_VIEW_LIMIT
    : ACTIVE_OR_DEACTIVATED_LIMIT;

  const isFavoriteFilter = activeFilterId === FAVORITE;

  const {
    data: activeQuestionsData,
    isLoading: isActiveQuestionsLoading,
    isFetching: isActiveDataFetching,
  } = useGetActiveStatisticsActiveQuery(
    {
      filters: {
        questionSetId:
          activeFilterId && !isFavoriteFilter ? activeFilterId : undefined,
        favorite: isFavoriteFilter || undefined,
        ...questionFilters,
      },
      page: activePage,
      limit: activeLimit,
    },
    {
      skip: filterParam && filterParam !== FilterBy.Active,
      refetchOnMountOrArgChange: true,
    },
  );

  const [updateQuestionData] = useGetQuestionStatisticsActiveMutation();

  const onActiveQuestionUpdate = async (questionId: string) => {
    try {
      const response = await updateQuestionData({
        id: questionId,
        filters: {
          updatesPer: questionFilters.updatesPer,
        },
      }).unwrap();

      const updatedQuestion = response.data.statistics;

      setActive((currentActive) => currentActive.map((question) => {
        return question.id === updatedQuestion.id
          ? updatedQuestion
          : question;
      }),);
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  useEffect(
    () => {
      if (isActiveMoreLoading && !isActiveDataFetching) {
        setIsActiveMoreLoading(false);
      }
    },
    [isActiveDataFetching, isActiveMoreLoading]
  );

  const {
    total: totalActive, statistics: activeQuestions
  } = useMemo(
    () => activeQuestionsData?.data || {
      total: 0,
      statistics: [],
    },
    [activeQuestionsData],
  );

  useEffect(
    () => {
      if (activePage > 1) {
        setActive((prev) => [...prev, ...activeQuestions]);
      } else {
        setActive(activeQuestions);
      }
    },
    [activeQuestions]
  );

  const isMoreActive = totalActive > active.length;

  const deactivatedLimit = isTableView
    ? TABLE_VIEW_LIMIT
    : ACTIVE_OR_DEACTIVATED_LIMIT;

  const {
    data: deactivatedQuestionsData,
    isLoading: isDeactivatedQuestionsLoading,
    isFetching: isDeactivatedDataFetching,
  } = useGetDeactivatedStatisticsQuery(
    {
      filters: {
        questionSetId:
          activeFilterId && !isFavoriteFilter ? activeFilterId : undefined,
        favorite: isFavoriteFilter || undefined,
        ...questionFilters,
      },
      page: deactivatedPage,
      limit: deactivatedLimit,
    },
    {
      skip: filterParam && filterParam !== FilterBy.Deactivated,
      refetchOnMountOrArgChange: true,
    },
  );

  useEffect(
    () => {
      if (isDeactivatedMoreLoading && !isDeactivatedDataFetching) {
        setIsDeactivatedMoreLoading(false);
      }
    },
    [isDeactivatedDataFetching, isDeactivatedMoreLoading]
  );

  const {
    total: totalDeactivated, statistics: deactivatedQuestions
  } = useMemo(
    () => deactivatedQuestionsData?.data || {
      total: 0,
      statistics: [],
    },
    [deactivatedQuestionsData],
  );

  useEffect(
    () => {
      if (deactivatedPage > 1) {
        setDeactivated((prev) => [...prev, ...deactivatedQuestions]);
      } else {
        setDeactivated(deactivatedQuestions);
      }
    },
    [deactivatedQuestions]
  );

  const isMoreDeactivated = totalDeactivated > deactivated.length;

  const availableLimit = isTableView ? TABLE_VIEW_LIMIT : AVAILABLE_LIMIT;

  const {
    data: availableQuestionsData,
    isLoading: isAvailableQuestionsLoading,
    isFetching: isAvailableDataFetching,
  } = useGetAvailableStatisticsQuery(
    {
      filters: {
        questionSetId: activeFilterId || undefined,
        ...questionFilters,
      },
      page: availablePage,
      limit: availableLimit,
    },
    {
      skip:
        filterParam && filterParam !== FilterBy.Available && isFavoriteFilter,
      refetchOnMountOrArgChange: true,
    },
  );

  useEffect(
    () => {
      if (isAvailableMoreLoading && !isAvailableDataFetching) {
        setIsAvailableMoreLoading(false);
      }
    },
    [isAvailableDataFetching, isAvailableMoreLoading]
  );

  const {
    total: totalAvailable, statistics: availableQuestions
  } = useMemo(
    () => availableQuestionsData?.data || {
      total: 0,
      statistics: [],
    },
    [availableQuestionsData],
  );

  useEffect(
    () => {
      if (availablePage > 1) {
        setAvailable((prev) => [...prev, ...availableQuestions]);
      } else {
        setAvailable(availableQuestions);
      }
    },
    [availableQuestions]
  );

  const isMoreAvailable = totalAvailable > available.length;

  const isAllLoading = isAvailableQuestionsLoading
    || isDeactivatedQuestionsLoading
    || isActiveQuestionsLoading;

  const isLoading = isAllLoading || isReloading;

  const handleShowMoreActive = () => {
    if (isActiveMoreLoading) {
      return;
    }

    setIsActiveMoreLoading(true);
    setActivePage((prev) => prev + 1);
  };

  const handleShowMoreDeactivated = () => {
    if (isDeactivatedMoreLoading) {
      return;
    }

    setIsDeactivatedMoreLoading(true);
    setDeactivatedPage((prev) => prev + 1);
  };

  const handleShowMoreAvailable = () => {
    if (isAvailableMoreLoading) {
      return;
    }

    setIsAvailableMoreLoading(true);
    setAvailablePage((prev) => prev + 1);
  };

  const handleShowMore = (type = filterParam) => {
    switch (type) {
      case FilterBy.Active:
        return handleShowMoreActive();
      case FilterBy.Available:
        return handleShowMoreAvailable();
      case FilterBy.Deactivated:
        return handleShowMoreDeactivated();
      default:
        return null;
    }
  };

  const resetPage = (type = filterParam) => {
    switch (type) {
      case FilterBy.Active:
        setActivePage(1);
        break;
      case FilterBy.Available:
        setAvailablePage(1);
        break;
      case FilterBy.Deactivated:
        setDeactivatedPage(1);
        break;
      default:
        break;
    }
  };

  const resetAllPages = () => {
    setActivePage(1);
    setAvailablePage(1);
    setDeactivatedPage(1);
  };

  const resetAllPagesWithReloading = () => {
    setIsReloading(true);
    resetAllPages();
  };

  useEffect(
    () => {
      if (
        isReloading
      && !isAvailableDataFetching
      && !isActiveDataFetching
      && !isDeactivatedDataFetching
      ) {
        setIsReloading(false);
      }
    },
    [
      isReloading,
      isAvailableDataFetching,
      isActiveDataFetching,
      isDeactivatedDataFetching,
    ]
  );

  useEffect(
    () => {
      resetAllPagesWithReloading();
    },
    [activeFilterId, sortParam, timeRangeParam]
  );

  useEffect(
    () => {
      resetAllPages();
    },
    [isTableView, filterParam]
  );

  return {
    available:
      filterParam && filterParam !== FilterBy.Available ? [] : available,
    isMoreAvailable,
    isAvailableFetching: isAvailableMoreLoading,
    deactivated:
      filterParam && filterParam !== FilterBy.Deactivated ? [] : deactivated,
    isMoreDeactivated,
    isDeactivatedFetching: isDeactivatedMoreLoading,
    active: filterParam && filterParam !== FilterBy.Active ? [] : active,
    isMoreActive,
    isActiveFetching: isActiveMoreLoading,
    isLoading,
    handleShowMore,
    resetPage,
    onActiveQuestionUpdate,
  };
};

export type UseGetQuestions = ReturnType<typeof useGetQuestions>;
