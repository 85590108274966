import React, {
  FC, useMemo
} from 'react';
import hexToRgba from 'hex-to-rgba';
import {
  useTranslation
} from 'react-i18next';

import {
  CheckmarkCircleIcon
} from 'src/shared/icons';
import {
  colorsWithOpacity
} from 'src/shared/components/OrganizationSettingsModal/constants';
import {
  cutRgbInColorName,
  isHexColor,
  rgbToRgba,
  showWarningFromServer,
} from 'src/shared/utils';
import {
  useGetAllUserThemesQuery,
  usePutUserThemeMutation,
} from 'src/redux/openapi';
import {
  Spinner, successfulToast
} from 'src/shared/components';

import * as Style from './UserThemes.style';

interface UserThemesProps {}

export const UserThemes: FC<UserThemesProps> = () => {
  const {
    t
  } = useTranslation();

  const {
    data: allThemes, refetch: refetchThemes
  } = useGetAllUserThemesQuery(
    undefined,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const [changeUserActiveTheme, {
    isLoading: isChangeUserActiveThemeLoading
  }] = usePutUserThemeMutation();

  const handleChangeUserTheme = async (
    themeId: string,
    isActiveColor: boolean,
  ) => {
    if (isActiveColor) {
      return;
    }

    try {
      const resp = await changeUserActiveTheme({
        setCurrentUserThemeBodySchema: {
          themeId,
        },
      }).unwrap();

      successfulToast(resp.message);

      refetchThemes();
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  const themes = useMemo(
    () => {
      if (!allThemes) {
        return [];
      }

      return allThemes.data.themes;
    },
    [allThemes]
  );

  const isAllInactiveThemes = themes.every((theme) => !theme.isActive);

  return (
    <div>
      {!!themes.length && (
        <Style.SectionTitle>
          {t('editAccount.availableThemes')}
        </Style.SectionTitle>
      )}

      {!!themes.length
        && themes.map(({
          isActive, id, colorPrimary, name, isDefault
        }) => {
          const isDefaultActive = isDefault && isAllInactiveThemes;
          const isCheckMarkActive = isActive || isDefaultActive;

          return (
            <Style.ModalColorsContainer
              $withSpace
              $isActive={!!isCheckMarkActive}
              key={id}
            >
              <Style.ColorTitle>
                {isDefault ? t('editAccount.DefaultTheme') : name}
              </Style.ColorTitle>

              <Style.ColorsRaw
                onClick={() => handleChangeUserTheme(
                  id,
                  isActive
                )}
              >
                {[t('organisation.Primary'), ...colorsWithOpacity].map(
                  (valueColor, i) => {
                    const opacityValue = i === 0 ? '1' : `0.${valueColor}`;
                    const isColorHex = isHexColor(colorPrimary);

                    const preparedValue = isColorHex
                      ? colorPrimary
                      : cutRgbInColorName(colorPrimary);

                    return (
                      <Style.ModalColorCard
                        key={valueColor}
                        $bgColor={
                          isColorHex
                            ? hexToRgba(
                              colorPrimary,
                              opacityValue
                            )
                            : rgbToRgba(
                              colorPrimary,
                              opacityValue
                            )
                        }
                      >
                        <Style.ModalColorCardTitle>
                          {valueColor}
                        </Style.ModalColorCardTitle>

                        {i === 0
                          && isCheckMarkActive
                          && (isChangeUserActiveThemeLoading ? (
                            <Spinner
                              color="white"
                              size={24}
                            />
                          ) : (
                            <CheckmarkCircleIcon className="mx-auto text-white" />
                          ))}

                        <Style.ModalColorCardTitle>
                          {`${preparedValue}`}
                        </Style.ModalColorCardTitle>
                      </Style.ModalColorCard>
                    );
                  },
                )}
              </Style.ColorsRaw>
            </Style.ModalColorsContainer>
          );
        })}
    </div>
  );
};
