import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const Item17Text = styled.div<{ $isBold?: boolean; $isEnd?: boolean }>`
  ${tw`text-gray7 text-17-26`}

  ${({
    $isBold
  }) => ($isBold ? tw`font-bold` : tw`font-medium`)}

  ${({
    $isEnd
  }) => $isEnd && tw`text-end`}
`;

export const Item22Text = styled.div`
  ${tw`text-22-30 font-bold text-gray7`}
`;

export const FooterContainer = styled.div`
  ${tw`flex gap-8 p-6 rounded-md border border-gray94 bg-white mb-6`}
`;

export const FooterData = styled.div`
  ${tw`flex flex-col gap-4`}
`;
