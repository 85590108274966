import {
  defaultHexColor
} from 'src/shared/utils';

export const MAX_IMG_SIZE = 256;
export const MAX_SCREEN = 1440;
export const SIDE_SPACE = 64;
export const TOP_SPACE = 107;
export const BOTTOM_SPACE = 10;
export const MODAL_HEADER_HEIGHT = 164;

export const colorsWithOpacity = ['05', '10', '20', '40', '60', '80'];
export const modalPlaceholderColorHex = '#006D77';
export const modalPlaceholderColorRGB = 'rgb(000, 109, 119)';

export const RGB = 'RGB';

export const defaultColorsData = [
  {
    theme: 'Blue',
    colors: [
      {
        title: 'Primary',
        hexColor: defaultHexColor,
      },
      {
        title: '5',
        hexColor: '#F4F7FE',
      },
      {
        title: '10',
        hexColor: '#E9EFFE',
      },
      {
        title: '20',
        hexColor: '#D3DFFD',
      },
      {
        title: '40',
        hexColor: '#A7C0FB',
      },
      {
        title: '60',
        hexColor: '#7AA0F9',
      },
      {
        title: '80',
        hexColor: '#4E81F7',
      },
    ],
  },
];
