import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const FilterContainer = styled.div`
  ${tw`flex flex-col gap-1 p-2 bg-white rounded`}
`;

export const FilterTitle = styled.div`
  ${tw`text-13-21 font-medium text-dark-gray px-2 py-1`}
`;

export const LabelShow = styled.label<{ $isActive?: boolean }>`
  ${tw`flex gap-2 p-2 items-center rounded cursor-pointer text-dim-gray transition`}

  ${({
    $isActive
  }) => $isActive && tw`bg-alice-blue text-button-blue`}
`;

export const ShowTitleLabel = styled.div<{ $isActive?: boolean }>`
  ${tw`text-17-26 font-medium`}

  ${({
    $isActive
  }) => ($isActive ? tw`text-button-blue` : tw`text-dark-gray`)}
`;

export const Separator = styled.div`
  ${tw`h-px w-full bg-gray94`}
`;

export const NewOnlyLabel = styled.label`
  ${tw`px-2 py-3 flex gap-2 items-center justify-between cursor-pointer`}
`;

export const DatesLabel = styled.label`
  ${tw`flex gap-2 p-2 items-center rounded justify-between cursor-pointer`}
`;

export const DatesTitleLabel = styled.div`
  ${tw`text-base font-medium text-gray7`}
`;

export const FilterSwitcher = styled.div<{ $isActive?: boolean }>`
  ${tw`rounded-55px w-12 p-px after:content-[''] after:block after:w-1/2 after:bg-white after:pb-50% after:rounded-full after:transition-all`}

  ${({
    $isActive
  }) => ($isActive ? tw`bg-button-blue after:translate-x-full` : tw`bg-dim-gray`)}
`;
