import React, {
  FC, useEffect, useMemo, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  Filters
} from 'src/features/Filters';
import {
  QuestionSurveyQuestion,
  useGetQuestionSurveyOptionsQuery,
} from 'src/redux/openapi';

import * as Style from './SurveySection.style';
import {
  SurveyButton
} from './SurveyButton';

interface SurveySectionProps {
  selectedValue: string | null;
  onSelectValue: (value: string | null) => void;
  surveyQuestions: QuestionSurveyQuestion[];
  questionId: string;
  isDeactivated: boolean;
  onFilterChange: (data: QuestionSurveyQuestion) => void;
}

export const SurveySection: FC<SurveySectionProps> = ({
  selectedValue,
  onSelectValue,
  surveyQuestions,
  questionId,
  isDeactivated,
  onFilterChange,
}) => {
  const [activeQuestion, setActiveQuestion] = useState<QuestionSurveyQuestion | null>(null);

  const {
    t
  } = useTranslation();

  const activeFilterId = useMemo(
    () => activeQuestion?.id || null,
    [activeQuestion],
  );

  const onFilterClick = (id: string | null) => {
    if (!id) {
      return;
    }

    const questionById = surveyQuestions.find((question) => question.id === id) || null;

    if (!questionById) {
      return;
    }

    setActiveQuestion(questionById);
    onSelectValue(null);
  };

  const filters = useMemo(
    () => surveyQuestions.map(({
      id, title, status
    }) => ({
      id,
      label: title,
      disabled: status === 'UPCOMING',
    })),
    [surveyQuestions],
  );

  useEffect(
    () => {
      if (surveyQuestions.length) {
        const active = surveyQuestions.find((question) => question.status === 'ACTIVE')
        || surveyQuestions[0];

        setActiveQuestion(active);
      }
    },
    [surveyQuestions]
  );

  useEffect(
    () => {
      if (activeQuestion) {
        onFilterChange(activeQuestion);
      }
    },
    [activeQuestion]
  );

  const {
    options
  } = useGetQuestionSurveyOptionsQuery(
    {
      id: questionId,
      surveyQuestionId: activeFilterId || '',
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !activeFilterId || !questionId,
      selectFromResult: ({
        data, isLoading
      }) => ({
        options: data?.data.options || [],
        isOptionsLoading: isLoading,
      }),
    },
  );

  const isVoted = options.some((option) => option.isChosen)
    || activeQuestion?.status === 'CLOSED';

  return (
    <Style.MainContainer>
      <Style.FiltersContainer>
        <Filters
          items={filters}
          activeFilterId={activeFilterId}
          onFilterClick={onFilterClick}
          disabledMessage={t('question.upcoming')}
        />
      </Style.FiltersContainer>

      <Style.SurveyContainer>
        {options.map((option) => (
          <SurveyButton
            key={option.id}
            option={option}
            isVoted={isVoted}
            isSelected={selectedValue === option.id}
            onSelect={onSelectValue}
            isQuestionDeactivated={isDeactivated}
          />
        ))}
      </Style.SurveyContainer>
    </Style.MainContainer>
  );
};
