import React, {
  FC, useState
} from 'react';
import {
  useNavigate, useSearchParams
} from 'react-router-dom';
import {
  useTranslation
} from 'react-i18next';

import {
  HeaderRow,
  StatisticTile,
  StatisticRow,
  formatNumber,
  QuestionCard,
  FilterBy,
  isQuestionWithStatistics,
} from 'src/entities/Questions';
import {
  ActionButton, Star
} from 'src/widgets';
import {
  GlobeIcon, PeopleIcon, PersonIcon
} from 'src/shared/icons';
import {
  Button, Spinner, Tooltip
} from 'src/shared/components';
import {
  ROUTE,
  formatDateRange,
  questionsFilterBy,
  themeColors,
} from 'src/shared/utils';
import {
  useTypedSelector
} from 'src/redux';
import {
  languageSelectors
} from 'src/redux/language';
import {
  useToggleFavQuestion
} from 'src/shared/hooks';

import {
  DelegateButton
} from '../DelegateButton';

import * as Style from './TableView.styles';

interface TableRowProps {
  onUpdate: (card: QuestionCard) => void;
  row: QuestionCard;
  isExtendedTable: boolean;
}

export const TableRow: FC<TableRowProps> = ({
  onUpdate,
  row,
  isExtendedTable,
}) => {
  const navigate = useNavigate();

  const {
    t
  } = useTranslation();

  const [searchParams] = useSearchParams();

  const activeTab = searchParams.get(questionsFilterBy) || (FilterBy.Active as FilterBy);

  const isAvailable = activeTab === FilterBy.Available;

  const handleNavigateClick = (id: string | number) => navigate(
    `/${ROUTE.QUESTION}/${id}`,
    {
      state: {
        isDeactivated: activeTab === FilterBy.Deactivated,
        isOnboarding: isAvailable,
      },
    }
  );

  const {
    id, questionSet, title, startDate, endDate, activeSurveyQuestion
  } = row;

  const isStatistics = isQuestionWithStatistics(row);

  const shouldDisplayStatisticsCell = isStatistics
    && (!!row.userEstimatesAvg
      || !!row.globalEstimatesAvg
      || !!row.groupEstimatesAvg);

  const language = useTypedSelector(languageSelectors.getLanguage);

  const isFavorite = 'isFavorite' in row && row.isFavorite;
  const isSurvey = row.type === 'SURVEY';

  const canDelegate = 'hasDelegate' in row && row.status !== 'DEACTIVATED';

  const hasDelegate = canDelegate && row.hasDelegate;

  const [isQuestionFavorite, setIsQuestionFavorite] = useState(isFavorite);

  const toggleFavQuestion = () => setIsQuestionFavorite((prev) => !prev);

  const {
    handleChangeFavQuestion, isChangingFavQuestion
  } = useToggleFavQuestion(
    isQuestionFavorite,
    toggleFavQuestion
  );

  const handleChangeFavorite = () => {
    handleChangeFavQuestion(id);
  };

  const date = formatDateRange({
    start: activeSurveyQuestion?.startDate || startDate,
    end: activeSurveyQuestion?.endDate || endDate,
    language,
  });

  return (
    <Style.TableRow key={id}>
      <Style.TruncatedCell>
        <HeaderRow
          date={date}
          datePrefix={activeSurveyQuestion?.title}
          isSurvey={isSurvey}
          question={questionSet?.title || ''}
          isTable
        />

        <Style.Title onClick={() => handleNavigateClick(id)}>
          <Style.TitleSpan>{title}</Style.TitleSpan>
        </Style.Title>

        {!isStatistics && !!row.onboardingText && (
          <Style.OnboardText>{row.onboardingText}</Style.OnboardText>
        )}
      </Style.TruncatedCell>

      {!isExtendedTable && (
        <Style.ButtonCell>
          <Style.ButtonCellRow>
            <ActionButton onClick={() => handleNavigateClick(id)} />
          </Style.ButtonCellRow>
        </Style.ButtonCell>
      )}

      {isStatistics && (
        <>
          <td>
            {shouldDisplayStatisticsCell && (
              <StatisticRow isTableView>
                <StatisticTile
                  amount={row.userEstimatesAvg}
                  icon={<PersonIcon />}
                />

                <StatisticTile
                  amount={row.globalEstimatesAvg}
                  icon={<GlobeIcon />}
                  growth={row.globalEstimatesChange}
                />

                <StatisticTile
                  amount={row.groupEstimatesAvg}
                  icon={<PeopleIcon className="text-dim-gray" />}
                  questionId={id}
                  growth={row.groupEstimatesChange}
                  withBorder
                />
              </StatisticRow>
            )}
          </td>

          <td>
            <Style.TextCell>
              {formatNumber(row.participantsCount || 0)}
            </Style.TextCell>
          </td>

          <td>
            <Style.TextCell>
              {formatNumber(row.commentsCount || 0)}
            </Style.TextCell>
          </td>

          <td>
            {activeTab === FilterBy.Active ? (
              <Tooltip message={t('question.clickToUpdate')}>
                <Button
                  onClick={() => onUpdate(row)}
                  className="w-full"
                >
                  <Style.TextCell $underlined>
                    {formatNumber(row.updatesCount || 0)}
                  </Style.TextCell>
                </Button>
              </Tooltip>
            ) : (
              <Style.TextCell>
                {formatNumber(row.updatesCount || 0)}
              </Style.TextCell>
            )}
          </td>

          <td>
            {!isSurvey && (
              <Style.ButtonsContainer>
                {canDelegate && (
                  <DelegateButton
                    hasDelegate={hasDelegate}
                    questionId={id}
                  />
                )}

                <button
                  type="button"
                  onClick={handleChangeFavorite}
                  disabled={isChangingFavQuestion}
                >
                  {isChangingFavQuestion ? (
                    <Spinner
                      size={20}
                      color={themeColors['button-blue']}
                    />
                  ) : (
                    <Star isFilled={isQuestionFavorite} />
                  )}
                </button>
              </Style.ButtonsContainer>
            )}
          </td>
        </>
      )}
    </Style.TableRow>
  );
};
