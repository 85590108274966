import React, {
  FC, ReactNode
} from 'react';

import {
  Button, Spinner
} from 'src/shared/components';
import {
  ArrowRightIcon
} from 'src/shared/icons';

interface SubmitButtonProps {
  title: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  onClick: () => void;
  variant?: 'big-blue' | 'big-black';
  customRightIcon?: ReactNode;
  formId?: string;
}

export const SubmitButton: FC<SubmitButtonProps> = ({
  title,
  isLoading = false,
  isDisabled = false,
  onClick,
  variant = 'big-blue',
  customRightIcon,
  formId,
}) => {
  return (
    <div className="w-full">
      <Button
        onClick={onClick}
        variant={variant}
        disabled={isLoading || isDisabled}
        type={formId ? 'submit' : 'button'}
        form={formId}
      >
        <span className="text-17-26 md:text-xl font-bold">{title}</span>

        {isLoading ? (
          <Spinner
            color="white"
            size={24}
            centered={false}
          />
        ) : (
          <div>
            {customRightIcon || <ArrowRightIcon className="text-white" />}
          </div>
        )}
      </Button>
    </div>
  );
};
