import {
  createApi
} from '@reduxjs/toolkit/query/react';

import {
  PostCommentArg, PostCommentResponse
} from 'src/redux/openapi';
import {
  addCommentToCommentsList,
  addReplyToCommentsList,
  addToTotalOfCommentsList,
  setPageOfCommentsList,
} from 'src/redux/commentsList';

import {
  baseQuery
} from '../baseQuery';

export const commentsApi = createApi({
  baseQuery,
  reducerPath: 'commentsApi',
  endpoints: (build) => ({
    postCommentsByParentModel: build.mutation<
    PostCommentResponse,
    PostCommentArg
    >({
      query: (queryArg) => ({
        url: `/api/comments/${queryArg.parentModel}/${queryArg.parentId}`,
        method: 'POST',
        body: queryArg.createCommentBodySchema,
      }),
      async onCacheEntryAdded(
        queryArg,
        {
          cacheDataLoaded, cacheEntryRemoved, dispatch
        },
      ) {
        try {
          const response = await cacheDataLoaded;

          if (queryArg.parentModel !== 'comment') {
            dispatch(addCommentToCommentsList(response.data.data.comment));
            dispatch(addToTotalOfCommentsList());
            dispatch(setPageOfCommentsList(1));
          } else {
            dispatch(addReplyToCommentsList(response.data.data.comment));
          }
        } catch (error) {
          /** empty */
        }

        await cacheEntryRemoved;
      },
    }),
  }),
});

export const {
  usePostCommentsByParentModelMutation
} = commentsApi;
