import React, {
  FC
} from 'react';

import {
  privacyPolicy
} from './utils';
import {
  ContentLayout
} from './ui';

export const PrivacyPolicy: FC = () => {
  return <ContentLayout links={privacyPolicy} />;
};
