import React, {
  FC, HTMLProps, ReactNode
} from 'react';
import clsx from 'clsx';

import * as Style from './Switcher.styles';

interface SwitcherProps {
  isActive: boolean;
  onChange: () => void;
  switcherClassName?: HTMLProps<HTMLDivElement>['className'];
  labelClassName?: HTMLProps<HTMLLabelElement>['className'];
  label?: ReactNode;
}

export const Switcher: FC<SwitcherProps> = ({
  isActive,
  onChange,
  switcherClassName,
  labelClassName,
  label,
}) => {
  return (
    <label
      className={clsx(
        'flex gap-2 font-medium text-15-20 text-dark-gray items-center',
        labelClassName,
      )}
    >
      <input
        type="checkbox"
        checked={isActive}
        onChange={onChange}
        className="hidden"
      />

      <div>
        <Style.SwitcherContainer
          $isActive={isActive}
          className={switcherClassName}
        />
      </div>

      {label && <span>{label}</span>}
    </label>
  );
};
