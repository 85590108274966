import {
  useMemo, useState
} from 'react';

import {
  useMediaQuery
} from 'src/shared/hooks';
import {
  getTimeLineTicks
} from 'src/shared/utils';
import {
  TimelineConfig
} from 'src/entities';

export const useXAxis = ({
  timelineConfig,
  defaultScrollDistance = 0,
  capacity = 4,
}: {
  timelineConfig?: TimelineConfig;
  defaultScrollDistance?: number;
  capacity?: number;
}) => {
  const [scrollDistance, setScrollDistance] = useState(defaultScrollDistance);

  const isMobile = useMediaQuery(
    'md',
    'down'
  );

  const timelineTicks = useMemo(
    () => timelineConfig && getTimeLineTicks(timelineConfig),
    [timelineConfig, capacity],
  );

  const ticksCapacity = isMobile ? 4 : capacity;

  const isOverflown = timelineTicks && timelineTicks?.length > ticksCapacity;

  const timeOverflow = isOverflown
    ? timelineTicks[timelineTicks.length - 1] - timelineTicks[ticksCapacity - 1]
    : 0;

  const minShownDate = timelineTicks
    ? timelineTicks[0] + timeOverflow * scrollDistance
    : null;

  const maxShownDate = timelineTicks
    ? timelineTicks[timelineTicks.length - 1]
      - timeOverflow * (1 - scrollDistance)
    : null;

  const shownTicks = minShownDate && maxShownDate
    ? timelineTicks?.filter(
      (tickDate) => tickDate >= minShownDate && tickDate <= maxShownDate,
    )
    : timelineTicks;

  return {
    minDate: minShownDate,
    maxDate: maxShownDate,
    ticks: shownTicks,
    scrollDistance,
    setScrollDistance,
    isOverflown,
  };
};
