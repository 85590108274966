import React, {
  forwardRef
} from 'react';

import type {
  ButtonProps
} from 'src/shared/components';
import {
  themeColors
} from 'src/shared/utils/themeColors';

import * as Style from './CircleButton.styles';

interface CircleButtonProps extends ButtonProps {
  size?: number;
  withBorder?: boolean;
  borderColor?: string;
  backgroundColor?: string;
}

export const CircleButton = forwardRef<HTMLButtonElement, CircleButtonProps>(
  (
    {
      children,
      size = 44,
      withBorder = true,
      borderColor = themeColors.gray90,
      backgroundColor = themeColors.white,
      ...props
    },
    ref,
  ) => (
    <Style.ButtonContainer
      $size={size}
      $withBorder={withBorder}
      $borderColor={borderColor}
      $backgroundColor={backgroundColor}
      {...props}
      ref={ref}
    >
      {children}
    </Style.ButtonContainer>
  ),
);
