import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import {
  useNavigate
} from 'react-router-dom';

import {
  useMediaQuery
} from 'src/shared/hooks';
import {
  CardsLayout
} from 'src/widgets';
import {
  ActiveCard,
  ScoredCard,
  SimpleCard,
  FilterBy,
} from 'src/entities/Questions';
import {
  ModalUpdateForecast, Spinner
} from 'src/shared/components';
import {
  QuestionsActiveStatistics
} from 'src/redux/openapi';
import {
  ROUTE
} from 'src/shared/utils';

import {
  EmptyResultsMessage
} from '../EmptyResultsMessage';
import {
  useUpdateModal, useGetQuestions
} from '../../hooks';

import * as Style from './CardsView.styles';

interface CardsViewProps {}

export const CardsView: FC<CardsViewProps> = () => {
  const {
    t
  } = useTranslation();

  const navigate = useNavigate();

  const isTablet = useMediaQuery(
    'xl',
    'down'
  );

  const {
    active,
    available,
    deactivated,
    isLoading,
    handleShowMore,
    resetPage,
    isMoreActive,
    isMoreDeactivated,
    isMoreAvailable,
    isActiveFetching,
    isDeactivatedFetching,
    isAvailableFetching,
    onActiveQuestionUpdate,
  } = useGetQuestions();

  const {
    isUpdateModalOpen, updatedCard, toggleUpdateModal
  } = useUpdateModal();

  if (isLoading) {
    return <Spinner />;
  }

  if (!active.length && !available.length && !deactivated.length) {
    return <EmptyResultsMessage />;
  }

  const onActiveButtonClick = (card: QuestionsActiveStatistics) => {
    if (card.type === 'SURVEY') {
      navigate(`/${ROUTE.QUESTION}/${card.id}`);

      return;
    }

    toggleUpdateModal(card);
  };

  return (
    <Style.ContentContainer>
      {!!active.length && (
        <section>
          <Style.Title>{t('common.active')}</Style.Title>

          <CardsLayout
            cards={active}
            renderElement={(card) => (
              <ActiveCard
                card={card}
                buttonProps={{
                  onClick: () => onActiveButtonClick(card),
                }}
              />
            )}
            keyExtractor={(card) => card.id}
            maxPerRow={2}
            isActive
            onShowMoreClick={() => handleShowMore(FilterBy.Active)}
            onHideClick={() => resetPage(FilterBy.Active)}
            hasMore={isMoreActive}
            isFetching={isActiveFetching}
          />
        </section>
      )}

      {!!available.length && (
        <section>
          <Style.Title>{t('common.available')}</Style.Title>

          <CardsLayout
            cards={available}
            renderElement={(card) => <SimpleCard card={card} />}
            keyExtractor={(card) => card.id}
            maxPerRow={isTablet ? 2 : 3}
            onShowMoreClick={() => handleShowMore(FilterBy.Available)}
            onHideClick={() => resetPage(FilterBy.Available)}
            hasMore={isMoreAvailable}
            isFetching={isAvailableFetching}
          />
        </section>
      )}

      {!!deactivated.length && (
        <section>
          <Style.Title>{t('common.scored')}</Style.Title>

          <CardsLayout
            cards={deactivated}
            renderElement={(card) => <ScoredCard card={card} />}
            keyExtractor={(card) => card.id}
            maxPerRow={2}
            onShowMoreClick={() => handleShowMore(FilterBy.Deactivated)}
            onHideClick={() => resetPage(FilterBy.Deactivated)}
            hasMore={isMoreDeactivated}
            isFetching={isDeactivatedFetching}
          />
        </section>
      )}

      {isUpdateModalOpen && updatedCard && (
        <ModalUpdateForecast
          updatedCard={updatedCard}
          closeModalHandler={() => toggleUpdateModal()}
          isModalOpen={isUpdateModalOpen}
          refetchQuestions={() => onActiveQuestionUpdate(updatedCard.id)}
        />
      )}
    </Style.ContentContainer>
  );
};
