import React, {
  FC
} from 'react';

import {
  MinusRoundedIcon, PlusRoundedIcon
} from 'src/shared/icons';

import {
  Button
} from '../Button';
import {
  getRestrictedValue, MAX_VALUE, MIN_VALUE
} from '../../utils/percents';

import {
  ValueControllerInput
} from './ui';
import * as Style from './ValueController.styles';

type MultiChoiceChange = (value: number) => void;

interface ValueControllerProps {
  value: number;
  onValueChange:
  | React.Dispatch<React.SetStateAction<number>>
  | MultiChoiceChange;
  variant?: 'big' | 'small';
  formattedValue?: string;
  valueDescription?: string;
  step?: number;
}

export const ValueController: FC<ValueControllerProps> = ({
  value,
  onValueChange,
  variant = 'big',
  formattedValue,
  valueDescription,
  step = 1,
}) => {
  return (
    <Style.MainContainer $variant={variant}>
      <Button
        type="button"
        variant={
          variant === 'big'
            ? 'input-control-rounded'
            : 'input-control-rounded-small'
        }
        onClick={() => {
          onValueChange(getRestrictedValue(value - step));
        }}
        disabled={value <= MIN_VALUE}
      >
        <MinusRoundedIcon />
      </Button>

      <ValueControllerInput
        value={value}
        onChange={(e) => {
          const newValue = getRestrictedValue(Number(e.target.value));

          onValueChange(newValue);
        }}
        formattedValue={formattedValue}
        valueDescription={valueDescription}
      />

      <Button
        type="button"
        variant={
          variant === 'big'
            ? 'input-control-rounded'
            : 'input-control-rounded-small'
        }
        onClick={() => {
          onValueChange(getRestrictedValue(value + step));
        }}
        disabled={value >= MAX_VALUE}
      >
        <PlusRoundedIcon className="text-gray7" />
      </Button>
    </Style.MainContainer>
  );
};
