import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import i18next from 'i18next';

import {
  DatePickerMenu, TDate
} from 'src/features';
import {
  RadialСheckFalseIcon, RadialСheckTrueIcon
} from 'src/shared/icons';
import {
  PopoverMenu
} from 'src/shared/components';
import {
  useTypedDispatch
} from 'src/redux';
import {
  setFeedTimeRange
} from 'src/redux/commentsFilters';

import {
  filterDates
} from '../filterData';
import {
  FilterDateType
} from '../types';
import * as Style from '../CommentsFilterContext.styles';
import {
  formatDateValue
} from '../utils';

interface TimeDurationSectionProps {
  timeRange: FilterDateType;
  selectedDateRange: TDate[] | null;
  onDateRangeChange: (date: TDate[] | null) => void;
  isNoGroups?: boolean;
}

export const TimeDurationSection: FC<TimeDurationSectionProps> = ({
  timeRange,
  onDateRangeChange,
  selectedDateRange,
  isNoGroups,
}) => {
  const dispatch = useTypedDispatch();

  const {
    t
  } = useTranslation();

  const onDateFilterClick = (range: FilterDateType) => {
    if (range !== FilterDateType.CUSTOM && selectedDateRange) {
      onDateRangeChange(null);
    }

    dispatch(setFeedTimeRange(range));
  };

  const onDateApply = (date: TDate[] | null) => {
    onDateRangeChange(date);

    onDateFilterClick(
      date ? FilterDateType.CUSTOM : FilterDateType.IN_24_HOURS,
    );
  };

  const {
    language
  } = i18next;

  const formattedDateRange = formatDateValue(
    selectedDateRange,
    language
  );

  const filters = isNoGroups ? [FilterDateType.ALL_TIME] : filterDates;

  return (
    <>
      <Style.FilterTitle>{t('filters.timeDuration')}</Style.FilterTitle>

      {filters.map((date) => (
        <Style.LabelDates
          key={date}
          htmlFor={date}
        >
          <input
            type="radio"
            name="duration"
            checked={timeRange === date}
            className="hidden"
            id={date}
            onChange={() => onDateFilterClick(date)}
          />

          <Style.LabelDatesTitle>{t(`filters.${date}`)}</Style.LabelDatesTitle>

          {timeRange === date ? (
            <RadialСheckTrueIcon />
          ) : (
            <RadialСheckFalseIcon />
          )}
        </Style.LabelDates>
      ))}

      <PopoverMenu
        placement="left-center"
        applyMaxHeight={false}
        renderCustomButton={({
          toggleMenu, triggerProps
        }) => (
          <Style.LabelDates
            {...triggerProps}
            onClick={toggleMenu}
          >
            <Style.LabelDatesTitle>{formattedDateRange}</Style.LabelDatesTitle>

            {selectedDateRange ? (
              <RadialСheckTrueIcon />
            ) : (
              <RadialСheckFalseIcon />
            )}
          </Style.LabelDates>
        )}
      >
        <DatePickerMenu
          onApply={onDateApply}
          clearButtonTitle={t('buttons.clear')}
          selectedDateRange={selectedDateRange}
        />
      </PopoverMenu>
    </>
  );
};
