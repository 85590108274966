import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const SectionTitle = styled.div`
  ${tw`font-bold text-22-30 flex gap-2 items-center shrink-0`}
`;

export const SectionHeader = styled.div`
  ${tw`flex justify-between items-center gap-4`}
`;

export const ButtonsContainer = styled.div`
  ${tw`flex gap-3 items-center`}
`;

export const SectionContainer = styled.div`
  ${tw`bg-white rounded-md p-6 pb-0 flex flex-col`}
`;

export const Title = styled.p<{ $isActive?: boolean }>`
  ${tw`text-xl font-bold`}

  ${({
    $isActive
  }) => $isActive && tw`text-button-blue`}
`;
