import React, {
  FC, FormEvent
} from 'react';
import {
  zodResolver
} from '@hookform/resolvers/zod';
import {
  Controller, useForm
} from 'react-hook-form';
import {
  useNavigate
} from 'react-router-dom';
import {
  useTranslation
} from 'react-i18next';

import {
  Input, warningToast, successfulToast
} from 'src/shared/components';
import {
  NavigateBackButton
} from 'src/widgets';
import {
  ROUTE, isErrorWithMessage
} from 'src/shared/utils';
import {
  useResetPasswordMutation
} from 'src/shared/api/auth/authApi';
import {
  useTypedDispatch
} from 'src/redux';
import {
  setUserEmail
} from 'src/redux/user';
import {
  useGetInputValidation
} from 'src/shared/hooks';

import {
  InputNames
} from '../types';

import {
  SubmitButton
} from './SubmitButton';
import {
  Title, BackButtonContainer
} from './Login.styles';

interface MailForm {
  [InputNames.EMAIL]: string;
}

const formId = 'ResetPasswordForm';

export const ResetPasswordPage: FC = () => {
  const {
    t
  } = useTranslation();

  const navigate = useNavigate();

  const [resetPassword, {
    isLoading
  }] = useResetPasswordMutation();

  const dispatch = useTypedDispatch();

  const {
    emailSchema
  } = useGetInputValidation();

  const {
    handleSubmit,
    control,
    formState: {
      errors
    },
  } = useForm<MailForm>({
    mode: 'onTouched',
    defaultValues: {
      [InputNames.EMAIL]: '',
    },
    resolver: zodResolver(emailSchema),
  });

  const onSubmit = handleSubmit(async (values) => {
    if (values.email) {
      try {
        const response = await resetPassword({
          email: values.email.trim(),
        }).unwrap();

        successfulToast(response.message);

        dispatch(setUserEmail(values.email));

        navigate(
          `/${ROUTE.RECOVERY_CODE}`,
          {
            state: {
              time: Date.now(),
            },
          }
        );
      } catch (error) {
        if (isErrorWithMessage(error)) {
          warningToast(error.data.message);
          navigate(`/${ROUTE.CREATE_ACCOUNT}`);
        }
      }
    }
  });

  const onFormSubmit = (event: FormEvent) => {
    event.preventDefault();
    onSubmit();
  };

  return (
    <>
      <BackButtonContainer>
        <NavigateBackButton />
      </BackButtonContainer>

      <form
        id={formId}
        onSubmit={onFormSubmit}
        className="flex flex-col justify-center items-center flex-1 h-full gap-4 md:gap-8 max-w-440px m-auto"
      >
        <Title>{t('resetPassword.resetPassword')}</Title>

        <div className="w-full flex flex-col gap-5">
          <Controller
            name={InputNames.EMAIL}
            control={control}
            render={({
              field
            }) => {
              return (
                <Input
                  label={t('common.email')}
                  value={field.value}
                  onChange={(e) => field.onChange(e)}
                  errorMessage={errors[InputNames.EMAIL]?.message}
                  onBlur={field.onBlur}
                />
              );
            }}
          />
        </div>

        <SubmitButton
          title={t('buttons.sendCode')}
          onClick={onSubmit}
          isLoading={isLoading}
          formId={formId}
        />
      </form>
    </>
  );
};
