type Email = { address: string };

export enum FormField {
  Emails = 'emails',
  Message = 'message',
}

export interface EmailForm {
  [FormField.Emails]: Email[];
  [FormField.Message]: string;
}
