import React, {
  ReactNode, useEffect, useRef
} from 'react';
import clsx from 'clsx';

import {
  MoreVerticalIcon
} from 'src/shared/icons';
import {
  PopoverMenu, Tooltip
} from 'src/shared/components';

import * as Style from './Filters.styles';

export interface FilterItem<T> {
  id: T | null;
  label: ReactNode;
  disabled?: boolean;
}

interface FilterProps<T, U extends FilterItem<T>> {
  item: U;
  activeFilterId: T | null;
  onFilterClick: (id: T | null) => void;
  renderRightElement?: (item: U) => ReactNode;
  renderMenuContent?: (item: U) => ReactNode;
  renderSubtitle?: (item: U) => ReactNode;
  disabledMessage?: string;
}

export const Filter = <T, U extends FilterItem<T>>({
  item,
  activeFilterId,
  onFilterClick,
  renderRightElement,
  renderMenuContent,
  renderSubtitle,
  disabledMessage,
}: FilterProps<T, U>) => {
  const currentFilterRef = useRef<HTMLAnchorElement | null>(null);

  const onClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    itemId: T | null,
  ) => {
    event.preventDefault();

    if (item.disabled) {
      return;
    }

    onFilterClick(itemId);
  };

  const menuContent = renderMenuContent && renderMenuContent(item);

  useEffect(
    () => {
      if (currentFilterRef.current && activeFilterId === item.id) {
        currentFilterRef.current.scrollIntoView({
          block: 'nearest',
        });
      }
    },
    [activeFilterId]
  );

  return (
    <Tooltip
      disabled={!item.disabled || !disabledMessage}
      message={disabledMessage}
      childrenClassName="shrink-0"
    >
      <Style.FilterTile
        ref={currentFilterRef}
        $isActive={activeFilterId === item.id}
        $withMenu={!!menuContent}
        onClick={(event) => onClick(
          event,
          item.id
        )}
        $disabled={item.disabled}
      >
        <div>
          {item.label}

          {renderSubtitle && renderSubtitle(item)}
        </div>

        {renderRightElement && renderRightElement(item)}

        {menuContent && (
          <PopoverMenu
            placement="bottom-end"
            customShift={22}
            renderCustomButton={({
              isOpen, toggleMenu, triggerProps
            }) => (
              <button
                type="button"
                className="pr-4"
                onClick={(event) => {
                  event.stopPropagation();
                  toggleMenu();
                }}
                {...triggerProps}
              >
                <MoreVerticalIcon
                  className={clsx(
                    'w-4 h-4',
                    {
                      'text-white': activeFilterId === item.id && !isOpen,
                      'text-dim-gray': activeFilterId !== item.id && !isOpen,
                      'text-button-blue': isOpen,
                    }
                  )}
                />
              </button>
            )}
          >
            {menuContent}
          </PopoverMenu>
        )}
      </Style.FilterTile>
    </Tooltip>
  );
};
