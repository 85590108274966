import React, {
  FC, useEffect, useMemo, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  useGetQuestionDelegationsUsersQuery
} from 'src/redux/openapi';
import {
  Autocomplete
} from 'src/shared/components/Autocomplete/Autocomplete';
import {
  useCustomInfiniteScroll, useDebouncedValue
} from 'src/shared/hooks';

interface ManuallyAssigningProps {
  questionId: string;
  onDelegateChange: (id: string) => void;
}

interface SelectedValue {
  label: string;
  value: string;
}

export const ManuallyAssigning: FC<ManuallyAssigningProps> = ({
  questionId,
  onDelegateChange,
}) => {
  const [query, setQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const [selectedValue, setSelectedValue] = useState<SelectedValue | null>(
    null,
  );

  const {
    t
  } = useTranslation();

  useEffect(
    () => {
      if (selectedValue) {
        onDelegateChange(selectedValue?.value);
      }
    },
    [selectedValue]
  );

  const debouncedQuery = useDebouncedValue(
    query,
    500
  );

  useEffect(
    () => {
      setCurrentPage(1);
    },
    [debouncedQuery]
  );

  const {
    data: usersData,
    isFetching,
    isLoading,
    isError,
  } = useGetQuestionDelegationsUsersQuery(
    {
      id: questionId,
      page: currentPage,
      limit: 10,
      query: debouncedQuery,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    total: totalUsers, users
  } = useMemo(
    () => usersData?.data || {
      total: 0,
      users: [],
    },
    [usersData],
  );

  const {
    allItems: allUsers,
    sentryRef,
    displayLoader,
  } = useCustomInfiniteScroll({
    total: totalUsers,
    currentItems: users,
    isFetching,
    isLoading,
    isError,
    currentPage,
    setCurrentPage,
  });

  const selectOptions = allUsers.map(
    ({
      name, displayName, id, avatarUrl
    }) => ({
      label: displayName || name,
      value: id,
      rightImageUrl: avatarUrl,
    }),
  );

  return (
    <Autocomplete
      options={selectOptions}
      value={selectedValue}
      onChange={(value) => setSelectedValue(value as SelectedValue)}
      selectConfig={{
        sentryRef,
        displayLoader,
      }}
      inputValue={query}
      onInputChange={setQuery}
      placeholder={t('inputMessages.ENTER_NICKNAME')}
      hideIndicator
      isRequired
      label={t('inputMessages.USER_NICKNAME')}
      classNames={{
        menuList: () => `!max-h-192px`,
      }}
    />
  );
};
