import {
  Characteristic
} from 'src/redux/organizations';

export interface UsersOfOrganization {
  email: string;
  id?: string;
  groupTitle?: string;
  groupId?: string;
  characteristics?: Characteristic[];
}

export interface UserListItem {
  email: string;
  groups: {
    id: string;
    title: string;
  }[];
  characteristics?: string[];
}

export interface Group {
  id: string;
  name: string;
  location?: string | null;
  users: UsersOfOrganization[];
  characteristics?: Characteristic[];
  isPublic: boolean | null;
}

export interface OrganizationMember {
  email: string;
  group: Pick<Group, 'id' | 'name'>;
}

export enum GroupsAndMembersFormField {
  Groups = 'groups',
  Members = 'members',
}

export interface GroupsAndMembersFrom {
  [GroupsAndMembersFormField.Groups]: Group[];
  [GroupsAndMembersFormField.Members]: OrganizationMember[];
}

export enum SettingOptions {
  Edit = 'editGroup',
  Delete = 'deleteTheGroup',
}

export interface SelectsValue {
  title: string;
  isSelected: boolean;
}

export interface UserToDrag {
  email: string;
  groupTitle: string;
}
