import React, {
  FC, useEffect, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import {
  useNavigate, useParams
} from 'react-router-dom';

import {
  ROUTE, showWarningFromServer
} from 'src/shared/utils';
import {
  PageLayout
} from 'src/widgets';
import {
  successfulToast
} from 'src/shared/components';
import {
  useCalibrationsStartedMutation
} from 'src/redux/openapi';

import * as Style from './CountDown.style';
import {
  RoundSvgBorder
} from './ui';

export const CountDown: FC = () => {
  const {
    t
  } = useTranslation();

  const navigate = useNavigate();

  const [countDown, setCountDown] = useState(3000);
  const [isFinished, setIsFinished] = useState(false);

  const params = useParams();

  const {
    calibrationId
  } = params;

  const [startCalibration] = useCalibrationsStartedMutation();

  const handleStartCalibration = async () => {
    if (calibrationId) {
      try {
        const resp = await startCalibration({
          id: calibrationId,
        }).unwrap();

        successfulToast(resp.message);

        navigate(`/${ROUTE.CALIBRATION_TOOL}/${calibrationId}`);
      } catch (error) {
        showWarningFromServer(error);
        navigate(`/${ROUTE.CALIBRATION_TOOL}`);
      }
    }
  };

  useEffect(
    () => {
      if (isFinished) {
        handleStartCalibration();
      }
    },
    [isFinished]
  );

  useEffect(
    () => {
      const interval = setInterval(
        () => {
          setCountDown((prev) => prev - 100);
        },
        100
      );

      if (countDown === 0) {
        clearInterval(interval);
        setIsFinished(true);
      }

      return () => clearInterval(interval);
    },
    [countDown]
  );

  return (
    <PageLayout>
      <Style.Container>
        <Style.RoundContainer onClick={handleStartCalibration}>
          <Style.Title>{Math.round(countDown / 1000)}</Style.Title>

          <Style.SubTitle>{`${t('calibration.startIn')}...`}</Style.SubTitle>

          <Style.Skip>{t('buttons.skip')}</Style.Skip>

          <RoundSvgBorder
            size={224}
            countTotal={3000}
            countDown={countDown}
            strokeWidth={12}
          />
        </Style.RoundContainer>
      </Style.Container>
    </PageLayout>
  );
};
