import React, {
  FC,
  useEffect,
  useRef,
  useState,
  UIEvent,
  HTMLProps,
  useMemo,
  MutableRefObject,
} from 'react';
import {
  mergeRefs
} from 'react-laag';

import * as Style from './ScrollContainer.styles';

export enum ScrollContainerVariant {
  dark = 'dark',
  light = 'light',
}

const getBgColor = (variant: ScrollContainerVariant) => {
  switch (variant) {
    case ScrollContainerVariant.dark:
      return {
        top: '180deg, rgba(0, 0, 0, 0.06) 0%, rgba(0, 0, 0, 0.00) 100%',
        bottom: '180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.06) 100%',
      };

    case ScrollContainerVariant.light:
    default:
      return {
        top: '0deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%',
        bottom: '0deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%',
      };
  }
};

interface ScrollContainerProps extends HTMLProps<HTMLDivElement> {
  visibleScroll?: boolean;
  variant?: ScrollContainerVariant;
  edgeHeight?: number;
  externalRef?: MutableRefObject<HTMLDivElement | null>;
}

export const ScrollContainer: FC<ScrollContainerProps> = ({
  children,
  visibleScroll = false,
  variant = ScrollContainerVariant.light,
  edgeHeight = 69,
  externalRef = null,
  ...props
}) => {
  const edgeColors = useMemo(
    () => getBgColor(variant),
    [variant]
  );

  const scrollRef = useRef<HTMLDivElement>(null);
  const [isTopEdgeVisible, setIsTopEdgeVisible] = useState(false);
  const [isBottomEdgeVisible, setIsBottomEdgeVisible] = useState(false);

  const checkEdges = ({
    scrollTop,
    scrollHeight,
    clientHeight,
  }: {
    scrollTop: number;
    scrollHeight: number;
    clientHeight: number;
  }) => {
    if (!scrollRef.current?.scrollHeight) {
      return;
    }

    if (!scrollHeight || !clientHeight) {
      return;
    }

    const isScrollable = scrollHeight > clientHeight;

    const isBottomEdge = isScrollable && Math.abs(scrollHeight - (scrollTop + clientHeight)) > 2;

    setIsTopEdgeVisible(scrollTop > 1);
    setIsBottomEdgeVisible(isBottomEdge);
  };

  useEffect(
    () => {
      if (!scrollRef.current) {
        return undefined;
      }

      const observer = new MutationObserver(() => {
        if (!scrollRef.current) {
          return;
        }

        checkEdges(scrollRef.current);
      });

      observer.observe(
        scrollRef.current,
        {
          childList: true,
          subtree: true,
        }
      );

      checkEdges(scrollRef.current);

      return () => observer.disconnect();
    },
    []
  );

  const handleScroll = (event: UIEvent<HTMLDivElement>) => {
    checkEdges(event.currentTarget);
  };

  return (
    <Style.MainContainer
      ref={mergeRefs(
        scrollRef,
        externalRef
      )}
      onScroll={handleScroll}
      $visibleScroll={visibleScroll}
      {...props}
    >
      {isTopEdgeVisible && (
        <Style.BlurredEdgeContainer
          $position="top"
          $bgColor={edgeColors.top}
        >
          <Style.BlurredEdge $height={edgeHeight} />
        </Style.BlurredEdgeContainer>
      )}

      {children}

      {isBottomEdgeVisible && (
        <Style.BlurredEdgeContainer $bgColor={edgeColors.bottom}>
          <Style.BlurredEdge $height={edgeHeight} />
        </Style.BlurredEdgeContainer>
      )}
    </Style.MainContainer>
  );
};
