import {
  motion
} from 'framer-motion';
import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const Container = styled(motion.div)<{
  $transparent: boolean;
  $layerStart: number;
  $applyMaxHeight?: boolean;
  $borderRadius: number;
  $applyFullHeight?: boolean;
  $zIndex?: number;
  $maxHeight?: number;
}>`
  ${tw`rounded overflow-auto flex`}

  ${({
    $layerStart
  }) => $layerStart < 0 && tw`!top-2`}

  ${({
    $transparent
  }) => !$transparent && tw`bg-white`}

  ${({
    $borderRadius
  }) => `border-radius: ${$borderRadius}px;`}

  box-shadow: 0px 26px 62px -10px rgba(102, 102, 102, 0.16);

  ${({
    $layerStart, $applyMaxHeight
  }) => $layerStart
    && $applyMaxHeight
    && `max-height: calc(100vh - ${$layerStart}px - 24px);`}

  ${({
    $layerStart, $applyFullHeight, $maxHeight
  }) => {
    const layerStart = $layerStart >= 0 ? $layerStart : 8;
    const height = `calc(100vh - ${layerStart}px - 24px)`;
    const maxHeight = $maxHeight ? `${$maxHeight}px` : '100%';

    return (
      $applyFullHeight
      && `
      height: ${height};
      max-height: ${maxHeight};
    `
    );
  }}

  ${({
    $zIndex = 20
  }) => $zIndex && `z-index: ${$zIndex};`}
`;
