import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

import {
  themeColors
} from 'src/shared/utils';

export const Divider = styled.div<{
  $color?: string;
  $type?: 'vertical' | 'horizontal';
}>`
  ${({
    $type = 'horizontal'
  }) => ($type === 'vertical' ? tw`w-px h-full` : tw`h-px w-full`)}

  ${({
    $color = themeColors.gray94
  }) => `background-color: ${$color};`}
`;
