import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const ContentHeader = styled.div`
  ${tw`flex justify-between items-center mb-4`}
`;

export const Title = styled.p`
  ${tw`text-28-36 font-bold text-gray7`}
`;

export const HeaderRightSide = styled.div`
  ${tw`flex items-center gap-4`}
`;

export const CreateOrgButton = styled.button`
  ${tw`py-2.5 px-4 text-button-blue text-15-20 font-bold rounded-100px flex flex-row gap-2 items-center border border-button-blue shadow-menu-shadow shrink-0`}
`;

export const ContentWrapper = styled.div`
  ${tw`flex flex-col h-full`}
`;
