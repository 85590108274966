import React, {
  FC, MouseEventHandler, useEffect
} from 'react';
import {
  useForm, useFieldArray
} from 'react-hook-form';
import {
  zodResolver
} from '@hookform/resolvers/zod';
import {
  useTranslation
} from 'react-i18next';

import {
  EmailIcon
} from 'src/shared/icons';
import {
  Button,
  ModalContainer,
  successfulToast,
  Spinner,
} from 'src/shared/components';
import {
  measureText, showWarningFromServer
} from 'src/shared/utils';
import {
  useGetInputValidation
} from 'src/shared/hooks';
import {
  usePostGroupUsersInvitesMutation
} from 'src/redux/openapi';

import * as Style from './GroupRoute.styles';

interface ModalAddMemberProps {
  closeModalHandler: () => void;
  isOpen: boolean;
  groupID: string;
}

interface MailFormValuesState {
  mailList: {
    value: string;
  }[];
  comment: string;
}

enum ModalValues {
  MAIL_LIST = 'mailList',
  COMMENT = 'comment',
}

export const ModalAddMember: FC<ModalAddMemberProps> = ({
  closeModalHandler,
  isOpen,
  groupID,
}) => {
  const {
    addMemberSchema
  } = useGetInputValidation();

  const {
    t
  } = useTranslation();

  const {
    control,
    getValues,
    register,
    formState: {
      errors
    },
    setError,
    clearErrors,
    handleSubmit,
    watch,
    setFocus,
    reset,
  } = useForm<MailFormValuesState>({
    mode: 'all',
    defaultValues: {
      mailList: [
        {
          value: '',
        },
      ],
      comment: '',
    },
    resolver: zodResolver(addMemberSchema),
  });

  const {
    fields, append, remove
  } = useFieldArray({
    name: ModalValues.MAIL_LIST,
    control,
  });

  const values = watch();

  const isInList = (email: string, isPaste?: boolean) => {
    return isPaste
      ? values.mailList.some((field) => field.value === email)
      : values.mailList.filter((field) => field.value === email).length > 1;
  };

  const handleHookKeyDown = (
    evt: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    clearErrors();

    if (['Enter', 'Tab', ',', ' '].includes(evt.key)) {
      evt.preventDefault();
      const mail = getValues(`${ModalValues.MAIL_LIST}.${index}.value`);

      if (!mail) {
        remove(index);

        return;
      }

      const isError = !!errors?.mailList?.[index]?.value || !!errors.mailList?.message;

      if (isError) {
        setError(
          ModalValues.MAIL_LIST,
          {
            message: t('inputMessages.INVALID_EMAIL'),
          }
        );

        return;
      }

      const isNotUnique = isInList(mail);

      if (isNotUnique) {
        setError(
          ModalValues.MAIL_LIST,
          {
            message: t('inputMessages.EMAIL_WAS_ADDED'),
          }
        );

        return;
      }

      const canAdd = !isError && !isNotUnique;
      const isLastInList = index === fields.length - 1;
      const isFirstInList = fields.length === 1 && index === 0;

      if ((isLastInList && canAdd) || isFirstInList) {
        append(
          {
            value: '',
          },
          {
            focusIndex: index + 1,
          },
        );
      }

      if (!isLastInList && !isError) {
        append({
          value: '',
        });
      }

      if (!isLastInList && !mail) {
        remove(index);
      }
    }
  };

  const handleBlurHook = (index: number) => {
    const mail = getValues(`${ModalValues.MAIL_LIST}.${index}.value`);

    if (!mail && fields.length !== 1) {
      remove(index);
    }
  };

  const handlePaste: React.ClipboardEventHandler<HTMLInputElement> = (evt) => {
    evt.preventDefault();

    const paste = evt.clipboardData.getData('text');
    const emails = paste.match(/[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/g);

    if (emails) {
      const toBeAdded = emails.filter(
        (email: string) => !isInList(
          email,
          true
        ),
      );

      toBeAdded.forEach((mail) => {
        append({
          value: mail,
        });
      });
    }
  };

  const handleFocusInput = () => {
    const mails = getValues(ModalValues.MAIL_LIST);
    const index = mails.findIndex((mail) => mail.value === '');

    if (index < 0) {
      append({
        value: '',
      });
    } else {
      setFocus(`${ModalValues.MAIL_LIST}.${index}.value`);
    }
  };

  const [addGroupMembers, {
    isSuccess, isLoading
  }] = usePostGroupUsersInvitesMutation();

  useEffect(
    () => {
      if (isSuccess) {
        closeModalHandler();
      }
    },
    [isSuccess]
  );

  const onSubmit = handleSubmit(async (data) => {
    const mailList = data.mailList.map((mail) => mail.value);

    try {
      const response = await addGroupMembers({
        id: groupID,
        inviteGroupUsersEmailBodySchema: {
          inviteMessage: data.comment,
          emails: mailList,
        },
      }).unwrap();

      successfulToast(response.message);
      reset();
    } catch (error) {
      showWarningFromServer(error);
    }
  });

  const handleSandButton = () => {
    onSubmit();
  };

  const handleClickOnLabel: MouseEventHandler = (e) => {
    e.preventDefault();

    if (e.target === e.currentTarget) {
      handleFocusInput();
    }
  };

  return (
    <ModalContainer
      isOpen={isOpen}
      modalTitle={t('modal.emailInviteToGroup')}
      closeModalHandler={closeModalHandler}
    >
      <div className="flex flex-col gap-6">
        <div>
          <Style.ModalLabelButton onClick={handleFocusInput}>
            {`${t('modal.emailsToInvite')} `}

            <Style.ModalRedSpan>*</Style.ModalRedSpan>
          </Style.ModalLabelButton>

          <Style.ModalGroupedLabel onClick={handleClickOnLabel}>
            {fields.map((mail, index) => {
              const currentInput = values.mailList[index].value;

              const width = measureText(
                currentInput,
                16
              );

              return (
                <Style.ModalEmailHookToInvite
                  $isFullWidth={fields.length === 1}
                  $isTransparent={values.mailList[index].value === ''}
                  key={mail.id}
                  {...register(`${ModalValues.MAIL_LIST}.${index}.value`)}
                  type="email"
                  onBlur={() => handleBlurHook(index)}
                  onKeyDown={(evt) => handleHookKeyDown(
                    evt,
                    index
                  )}
                  placeholder={
                    values.mailList.length === 1
                    && values.mailList[index].value === ''
                      ? t('modal.pasteHereBunchOfEmails')
                      : ''
                  }
                  onPaste={handlePaste}
                  style={{
                    width:
                      values.mailList.length === 1
                      && values.mailList[0].value === ''
                        ? '320px'
                        : `calc(26px + ${width}px)`,
                  }}
                />
              );
            })}
          </Style.ModalGroupedLabel>

          {errors.mailList?.message && (
            <div className="my-2">
              <Style.ModalRedSpan>{errors.mailList.message}</Style.ModalRedSpan>
            </div>
          )}
        </div>

        <div>
          <Style.ModalLabel htmlFor={`${ModalValues.COMMENT}`}>
            {t('modal.inviteMessageOptional')}
          </Style.ModalLabel>

          <Style.ModalTextarea
            {...register(ModalValues.COMMENT)}
            placeholder={t('modal.sendTheInvitation')}
            id={`${ModalValues.COMMENT}`}
          />
        </div>

        <div className="flex justify-end items-center ">
          <Button
            variant="big-blue"
            className="w-max"
            onClick={handleSandButton}
            type="submit"
          >
            <Style.ModalSaveSpan>{t('buttons.send')}</Style.ModalSaveSpan>

            {isLoading ? (
              <Spinner
                color="white"
                size={24}
                centered={false}
              />
            ) : (
              <EmailIcon className="text-white" />
            )}
          </Button>
        </div>
      </div>
    </ModalContainer>
  );
};
