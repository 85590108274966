import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MainContainer = styled.div`
  ${tw`flex-1 relative`}
`;

export const ChildrenContainer = styled.div<{ $radius: number }>`
  ${tw`absolute font-bold text-dark-gray text-17-26 -translate-x-1/2 -translate-y-1/2`}

  ${({
    $radius
  }) => `
    left: ${$radius}px;
    top: ${$radius}px;
  `}
`;
