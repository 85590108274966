import React, {
  FC
} from 'react';
import {
  RouterProvider
} from 'react-router-dom';

import {
  router
} from './router';

export const RoutesProvider: FC = () => {
  return <RouterProvider router={router} />;
};
