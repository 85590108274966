import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const Container = styled.div<{ $centered: boolean; $color: string }>`
  ${tw`flex flex-col gap-2`}

  ${({
    $centered
  }) => $centered && tw`w-full h-full items-center justify-center`}

  ${({
    $color
  }) => `color: ${$color}`}
`;

export const LoadingTitle = styled.p`
  ${tw`text-dark-gray text-15-20 font-medium text-center`}
`;
