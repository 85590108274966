import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MainContainer = styled.div`
  ${tw`flex flex-col`}
`;

export const ButtonContainer = styled.div`
  ${tw`mt-6 flex justify-end gap-4`}
`;

export const Label = styled.label`
  ${tw`text-15-20 font-medium text-grey-dark-40`}
`;

export const TextHint = styled.div`
  ${tw`text-13-21 font-medium text-dim-gray`}
`;

export const RedText = styled.span`
  ${tw`text-red-label`}
`;

export const TextArea = styled.textarea`
  ${tw`w-full min-h-50px max-h-fit px-4 py-3 mt-2 mb-1 border border-gray94 bg-alice-blue rounded resize-none outline-none`}
`;
