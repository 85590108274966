import React, {
  FC, MouseEvent
} from 'react';
import hexToRgba from 'hex-to-rgba';
import {
  useTranslation
} from 'react-i18next';

import {
  cutRgbInColorName, isHexColor, rgbToRgba
} from 'src/shared/utils';
import {
  Spinner
} from 'src/shared/components/Spinner';
import {
  CheckmarkCircleIcon, TrashFilledIcon
} from 'src/shared/icons';
import {
  GetOrganizationThemesResponseSchema
} from 'src/redux/openapi/endpoints/organizations';

import {
  colorsWithOpacity
} from '../../constants';

import * as Style from './ThemeColorsRow.style';

interface ThemeColorsRowProps {
  theme: GetOrganizationThemesResponseSchema['data']['themes'][number];
  isLoading: boolean;
  setCustomTheme: (themeId: string) => void;
  handleDeleteTheme: (e: MouseEvent, themeId: string) => void;
}

export const ThemeColorsRow: FC<ThemeColorsRowProps> = ({
  theme: {
    id: themeId, isDefault, isActive, colorPrimary
  },
  isLoading,
  setCustomTheme,
  handleDeleteTheme,
}) => {
  const {
    t
  } = useTranslation();

  const colorsValues = [t('organisation.Primary'), ...colorsWithOpacity];

  return (
    <Style.ModalColorsContainer
      $withSpace
      $isActive={isActive}
    >
      {colorsValues.map((valueColor, i) => {
        const opacityValue = i === 0 ? '1' : `0.${valueColor}`;
        const isColorHex = isHexColor(colorPrimary);

        const preparedBGColor = isColorHex
          ? hexToRgba(
            colorPrimary,
            opacityValue
          )
          : rgbToRgba(
            colorPrimary,
            opacityValue
          );

        const canDeleteTheme = i === 0 && !isActive && !isDefault;
        const isActiveTheme = i === 0 && isActive;

        const preparedValue = isColorHex
          ? colorPrimary
          : cutRgbInColorName(colorPrimary);

        const handleSetThemeColor = () => {
          if (!isActive) {
            setCustomTheme(themeId);
          }
        };

        return (
          <Style.ModalColorCard
            key={valueColor}
            $bgColor={preparedBGColor}
            onClick={handleSetThemeColor}
          >
            <Style.ModalColorCardTitle>{valueColor}</Style.ModalColorCardTitle>

            {isActiveTheme
              && (isLoading ? (
                <Spinner
                  color="white"
                  size={24}
                />
              ) : (
                <CheckmarkCircleIcon className="mx-auto text-white" />
              ))}

            {canDeleteTheme && (
              <button
                type="button"
                onClick={(e) => {
                  handleDeleteTheme(
                    e,
                    themeId
                  );
                }}
              >
                {isLoading ? (
                  <Spinner
                    color="white"
                    size={24}
                  />
                ) : (
                  <TrashFilledIcon className="mx-auto text-white" />
                )}
              </button>
            )}

            <Style.ModalColorCardTitle>
              {`${preparedValue}`}
            </Style.ModalColorCardTitle>
          </Style.ModalColorCard>
        );
      })}
    </Style.ModalColorsContainer>
  );
};
