import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  AlertTriangleIcon, CheckmarkCircleEmptyIcon
} from 'src/shared/icons';
import {
  Button, Spinner
} from 'src/shared/components';

import * as Style from './FeedbackResult.styles';

interface FeedbackResultProps {
  onProceed?: (shouldProceed: boolean) => void;
  type: 'success' | 'warning';
  isLoading?: boolean;
}

export const FeedbackResult: FC<FeedbackResultProps> = ({
  onProceed,
  type,
  isLoading,
}) => {
  const {
    t
  } = useTranslation();

  const isSuccess = type === 'success';

  return (
    <Style.ContentContainer>
      {isSuccess ? (
        <CheckmarkCircleEmptyIcon className="text-button-blue w-12 h-12" />
      ) : (
        <AlertTriangleIcon className="text-negative80 w-12 h-12" />
      )}

      <Style.TextContainer>
        <Style.Title>
          {isSuccess
            ? t('feedback.thankYouForSharingFeedback')
            : t('feedback.sureWantProceedWithoutFeedback')}
        </Style.Title>

        {!isSuccess && onProceed && (
          <Style.ButtonsContainer>
            <Button
              variant="big-blue"
              className="!w-137px !h-11 text-15-20 font-bold"
              onClick={() => onProceed(true)}
            >
              {t('buttons.yes')}

              {isLoading && (
                <Spinner
                  size={15}
                  color="inherit"
                  centered={false}
                />
              )}
            </Button>

            <Button
              variant="big-grey-bordered"
              className="!w-137px !h-11 text-15-20 font-bold"
              onClick={() => onProceed(false)}
            >
              {t('buttons.no')}
            </Button>
          </Style.ButtonsContainer>
        )}
      </Style.TextContainer>
    </Style.ContentContainer>
  );
};
