import {
  clsx
} from 'clsx';
import React, {
  ReactNode
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  Spinner
} from 'src/shared/components';
import {
  useMediaQuery
} from 'src/shared/hooks';
import {
  ArrowDownIcon, ArrowUpIcon
} from 'src/shared/icons';
import {
  ActionButton
} from 'src/widgets';

const columnsVariants = {
  1: 'col-span-1',
  2: 'col-span-2',
  3: 'col-span-3',
  6: 'col-span-6',
};

interface Props<TCard> {
  cards: TCard[];
  renderElement: (card: TCard) => ReactNode;
  keyExtractor: (card: TCard) => string | number;
  maxPerRow?: number;
  isActive?: boolean;
  onShowMoreClick?: () => void;
  onHideClick?: () => void;
  hasMore: boolean;
  isFetching?: boolean;
}

export const CardsLayout = <TCard,>({
  cards,
  renderElement,
  maxPerRow = 3,
  keyExtractor,
  isActive,
  onShowMoreClick,
  onHideClick,
  hasMore,
  isFetching,
}: Props<TCard>): JSX.Element => {
  const {
    t
  } = useTranslation();

  const maxVisible = isActive ? 4 : 6;
  const isPagination = hasMore || cards.length > maxVisible;

  const isMobile = useMediaQuery(
    'md',
    'down'
  );

  const rows = Math.ceil(cards.length / maxPerRow);
  const remainder = cards.length % maxPerRow;
  const defaultCardSpan = maxPerRow >= 3 ? 2 : 3;

  const isOneLastCard = remainder === 1;

  const getCardsSpan = () => {
    if (!remainder) {
      return defaultCardSpan;
    }

    return isOneLastCard ? 6 : 3;
  };

  const lastRowCardsSpan = getCardsSpan();
  const maxIndex = rows * maxPerRow - 1;
  const lastFullIndex = remainder ? maxIndex - maxPerRow : maxIndex;

  const getColSpan = (index: number) => {
    const colSpan = index <= lastFullIndex ? defaultCardSpan : lastRowCardsSpan;

    return columnsVariants[colSpan];
  };

  const buttonIcon = hasMore ? <ArrowDownIcon /> : <ArrowUpIcon />;

  const buttonIconContent = hasMore && isFetching ? (
    <Spinner
      size={24}
      color="inherit"
    />
  ) : (
    buttonIcon
  );

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-6 md:gap-3 gap-y-4 mb-6">
        {cards.map((card, index) => {
          return (
            <div
              key={keyExtractor(card)}
              className={clsx(
                !isMobile && getColSpan(index),
                {
                  'max-w-55%':
                  isOneLastCard && index > lastFullIndex && !isMobile,
                }
              )}
            >
              {renderElement(card)}
            </div>
          );
        })}
      </div>

      {isPagination && (
        <ActionButton
          onClick={hasMore ? onShowMoreClick : onHideClick}
          buttonTitle={hasMore ? t('buttons.showMore') : t('buttons.hide')}
          buttonIcon={buttonIconContent}
          buttonWithBorder={false}
          className="w-full p-2 mt-2"
        />
      )}
    </>
  );
};
