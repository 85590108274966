import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MainContainer = styled.div`
  ${tw`flex gap-3 mb-10`}
`;

export const ModalHeader = styled.div`
  ${tw`flex flex-col gap-1 flex-1`}
`;

export const Title = styled.p`
  ${tw`text-22-30 font-bold`}
`;

export const SubTitle = styled.p`
  ${tw`text-grey-dark-40 text-15-20 font-medium`}
`;
