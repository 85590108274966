import dayjs from 'dayjs';
import {
  t
} from 'i18next';

const DEFAULT_VALUE = 'All Dates';

export const getMonthName = (date: Date, lang: string) => {
  return dayjs(date).locale(lang).format('MMMM');
};

export const formatValue = (inputValue: (Date | null)[] | null) => {
  if (!inputValue) {
    return DEFAULT_VALUE;
  }

  const [start, end] = inputValue;

  if (!start) {
    return DEFAULT_VALUE;
  }

  const formattedStart = dayjs(start).format('MMM D');

  if (end) {
    const startMonth = dayjs(start).format('MMM');

    const endMonth = dayjs(end).format('MMM');

    const formattedEnd = dayjs(end).format(
      startMonth === endMonth ? 'D' : 'MMM D',
    );

    return `${formattedStart}-${formattedEnd}`;
  }

  return formattedStart;
};

export const getCorrectDayOfWeek = (day: string) => {
  switch (day) {
    case 'Monday':
      return t('date.Monday')[0];
    case 'Tuesday':
      return t('date.Tuesday')[0];
    case 'Wednesday':
      return t('date.Wednesday')[0];
    case 'Thursday':
      return t('date.Thursday')[0];
    case 'Friday':
      return t('date.Friday')[0];
    case 'Saturday':
      return t('date.Saturday')[0];
    case 'Sunday':
      return t('date.Sunday')[0];
    default:
      return day[0];
  }
};
