import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const SectionContainer = styled.div`
  ${tw`border-r border-gray90 flex flex-col`}
`;

export const SectionHeader = styled.div`
  ${tw`flex flex-col gap-4 pt-4 pb-8`}
`;

export const TitleWrapper = styled.div`
  ${tw`flex justify-between items-center px-4`}
`;

export const FiltersContainer = styled.div`
  ${tw`overflow-x-scroll mx-4 relative`}

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const QuestionCard = styled.div<{ $isActive: boolean }>`
  ${tw`
    flex flex-col gap-3
    border-b first:border-t border-gray90 last:border-b-0 p-4 
    cursor-pointer transition ease-in
  `}

  ${({
    $isActive
  }) => $isActive && tw`bg-alice-blue`}
`;

export const SortMenu = styled.div`
  ${tw`p-2 flex flex-col gap-1 w-324px z-20`}
`;

export const SortMenuTitle = styled.p`
  ${tw`px-2 py-1 font-medium text-13-21 text-dark-gray`}
`;

export const SortMenuLabel = styled.button<{ $isActive: boolean }>`
  ${tw`p-2 flex items-center gap-2 font-medium text-17-26 text-dark-gray transition`}

  ${({
    $isActive
  }) => $isActive && tw`bg-alice-blue text-button-blue`}
`;

export const EmptyMessageContainer = styled.div`
  ${tw`
    flex flex-col gap-2 items-center text-center px-8 py-6 my-auto mx-6
    bg-alice-blue text-dark-gray text-17-26 font-medium rounded-md
  `}
`;

export const EmptyMessageTitle = styled.div`
  ${tw`text-gray7 font-bold`}
`;
