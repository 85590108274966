import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MenuContainer = styled.div`
  ${tw`
    w-238px py-2 flex flex-col gap-1
  `}
`;

export const MenuOption = styled.button`
  ${tw`text-base font-medium text-gray7 px-4 py-2
  flex justify-between w-full text-left gap-2`}
`;

export const ModalFilterTitle = styled.div`
  ${tw`text-13-21 text-dark-gray text-left font-medium text-dark-gray px-2 py-1 mx-2`}
`;

export const ModalLabel = styled.label<{ $isActive?: boolean }>`
  ${tw`flex gap-2 mx-2 p-2 items-center rounded cursor-pointer`}

  ${({
    $isActive
  }) => $isActive && tw`bg-alice-blue`}
`;

export const ModalLabelTitle = styled.div<{ $isActive?: boolean }>`
  ${tw`text-base font-medium grow`}

  ${({
    $isActive
  }) => ($isActive ? tw`text-button-blue` : tw`text-dark-gray`)}
`;

export const ModalFilterSeparatorLine = styled.div`
  ${tw`h-px px-2 w-full bg-gray94`}
`;

export const ModalListItemCount = styled.div`
  ${tw`px-1 rounded-100px group-hover:bg-white bg-input-blue`}
`;

export const ModalNestedContainer = styled.div`
  ${tw`
    hidden absolute left-0 top-0 z-50 w-324px h-412px -translate-x-full gap-1 flex-col group-hover:flex pb-2 bg-white rounded-b-15px rounded-tl-15px shadow-menu-shadow overflow-y-scroll animate-leftSlide
  `}
`;
