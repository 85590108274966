import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

import {
  CreateEntityLayoutVariant
} from './types';

export const MainContainer = styled.div<{
  $variant: CreateEntityLayoutVariant;
}>`
  ${tw`flex flex-col gap-6 mx-auto h-full pb-6`}

  ${({
    $variant
  }) => ($variant === 'medium' ? tw`max-w-910px` : tw`max-w-1050px`)}
`;

export const SectionContainer = styled.div<{ $grow?: boolean }>`
  ${tw`bg-white rounded-md border border-gray94 p-6 flex flex-col gap-6 shadow-section-shadow`}

  ${({
    $grow
  }) => $grow && tw`grow`}
`;

export const Header = styled.div`
  ${tw`flex flex-col gap-4`}
`;

export const Stepper = styled.div`
  ${tw`flex gap-10 justify-center`}
`;

export const StepsContainer = styled.div`
  ${tw`flex gap-4 grow`}
`;

export const Step = styled.button<{
  $isActive?: boolean;
  $isCompleted?: boolean;
}>`
  ${tw`
    font-bold text-22-30 flex flex-col gap-2
    after:(h-1 w-full rounded-100px bg-button-blue) w-1/2
    transition ease-in-out
  `}

  ${({
    $isActive
  }) => !$isActive && tw`font-medium text-dim-gray after:bg-gray-whisper`}

  ${({
    $isCompleted
  }) => $isCompleted && tw`font-medium text-dim-gray after:bg-forest-green`}
`;

export const NextStepButton = styled.button<{ $isOutline?: boolean }>`
  ${tw`
    flex items-center justify-center gap-1
    px-4 py-2.5 bg-button-blue rounded-100px
    text-15-20 font-bold text-white
    border border-button-blue
    hover:(bg-white text-button-blue) transition
  `}

  ${({
    $isOutline
  }) => $isOutline
    && tw`
    bg-white text-button-blue
    hover:(bg-button-blue text-white)
  `}
`;

export const GroupInfoForm = styled.form`
  ${tw`flex gap-6`}
`;

export const FieldLabel = styled.label`
  ${tw`w-1/2 flex flex-col gap-2 text-15-20 text-grey-dark-40 font-medium`}
`;

export const Asterisk = styled.span`
  ${tw`text-red-text-error`}
`;

export const FieldInput = styled.input<{ $withError: boolean }>`
  ${tw`
    bg-alice-blue px-4 py-3px border border-gray94 outline-0 h-12
    text-17-26 text-dark-gray font-medium
    placeholder:text-dim-gray w-full rounded
  `}

  ${({
    $withError
  }) => $withError && tw`border-red-border-error bg-red-bg-error`}
`;

export const SectionHeader = styled.div`
  ${tw`flex justify-between items-center`}
`;

export const SectionTitle = styled.div`
  ${tw`text-22-30 font-bold grow`}
`;

export const SectionTitleContainer = styled.div`
  ${tw`flex flex-col gap-1 grow`}
`;

export const SectionSubtitle = styled.p`
  ${tw`text-15-20 font-medium text-dim-gray`}
`;

export const HeaderMessage = styled.p<{ $color: string }>`
  ${tw`text-17-26 font-bold`}

  ${({
    $color
  }) => `
    color: ${$color}
  `}
`;

export const InvitedPeopleEmptyMessage = styled.p`
  ${tw`text-xl font-medium text-light-grey h-130px flex items-center justify-center`}
`;

export const ErrorMessage = styled.div`
  ${tw`text-13-21 font-medium text-red-text-error mt-1`}
`;
