import React, {
  FC
} from 'react';

import {
  СheckmarkСircleStrokeIcon
} from 'src/shared/icons';

import {
  Button
} from '../Button';
import {
  ModalContainer
} from '../ModalContainer';

import * as Style from './ModalSuccess.styles';

interface ModalSuccessProps {
  closeModal: () => void;
  isOpen: boolean;
}

export const ModalSuccess: FC<ModalSuccessProps> = ({
  closeModal, isOpen
}) => {
  return (
    <ModalContainer
      modalWidth={450}
      isDarkBackground
      isHeader={false}
      closeModalHandler={closeModal}
      isOpen={isOpen}
    >
      <div className="flex flex-col gap-6">
        <СheckmarkСircleStrokeIcon className="w-16 h-16 text-button-blue mx-auto" />

        <Style.ModalTitle>Request has been sent successfully</Style.ModalTitle>

        <Style.ModalText>
          The average waiting time for a response is 3 to 5 days you will find
          it in notification
        </Style.ModalText>

        <Button
          variant="big-blue"
          onClick={closeModal}
        >
          <Style.ModalSpan>Done</Style.ModalSpan>
        </Button>
      </div>
    </ModalContainer>
  );
};
