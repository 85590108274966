import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MainContainer = styled.div<{ $variant: 'big' | 'small' }>`
  ${tw`flex items-center gap-x-2 border border-gray90 rounded-100px p-1`}

  ${({
    $variant
  }) => ($variant === 'big' ? tw`h-12` : tw`h-34px`)}
`;
