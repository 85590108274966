import clsx from 'clsx';
import React from 'react';
import ReactSelect, {
  Props as ReactSelectProps
} from 'react-select';
import {
  useTranslation
} from 'react-i18next';

import {
  DropdownIndicator, SelectPaginationMenu
} from 'src/shared/components';
import {
  UseInfiniteScroll
} from 'src/shared/hooks';

import {
  Option
} from './ui';
import * as Style from './Autocomplete.styles';

interface AutocompleteProps<T> extends ReactSelectProps {
  selectConfig?: Pick<UseInfiniteScroll<T>, 'sentryRef' | 'displayLoader'>;
  hideIndicator?: boolean;
  label?: string;
  isRequired?: boolean;
  isLoading?: boolean;
}

export const Autocomplete = <T,>({
  selectConfig,
  hideIndicator,
  label,
  isRequired,
  classNames,
  isLoading,
  ...props
}: AutocompleteProps<T>) => {
  const {
    t
  } = useTranslation();

  return (
    <Style.Label>
      {label && (
        <p>
          {label}

          {isRequired && <Style.Asterisk>*</Style.Asterisk>}
        </p>
      )}

      <ReactSelect
        isMulti={false}
        hideSelectedOptions={false}
        isClearable={false}
        isSearchable
        closeMenuOnSelect
        closeMenuOnScroll={false}
        menuPortalTarget={document.body}
        noOptionsMessage={() => (isLoading ? `${t('common.Loading')}...` : t('empty.nothingFound'))}
        styles={{
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
          }),
        }}
        components={{
          DropdownIndicator: hideIndicator ? undefined : DropdownIndicator,
          MenuList: SelectPaginationMenu,
          Option,
        }}
        sentryRef={selectConfig?.sentryRef}
        displayLoader={selectConfig?.displayLoader}
        classNames={{
          control: ({
            menuIsOpen
          }) => clsx(
            `
                p-3 border outline-0 !rounded-md
                text-base text-dark-gray font-medium
                !shadow-none
              `,
            {
              '!border-gray90': !menuIsOpen,
              '!border-button-blue': menuIsOpen,
            },
          ),
          valueContainer: () => `!p-0 gap-2`,
          indicatorSeparator: () => `hidden`,
          input: () => `!m-0 !p-0 !h-6`,
          option: ({
            isSelected, isFocused
          }) => clsx(
            `
            text-base font-medium m-0 p-3 rounded !cursor-pointer !flex items-center gap-3
          `,
            {
              '!bg-alice-blue !text-button-blue': isSelected,
              '!bg-cultured': !isSelected && isFocused,
              '!text-dark-gray !bg-white hover:!bg-cultured': !isSelected,
            },
          ),
          menu: () => `!border-0 rounded !mt-1 !z-10 overflow-auto`,
          menuList: (data) => clsx(
            `overflow-auto !p-0`,
            classNames?.menuList && classNames?.menuList(data),
          ),
          dropdownIndicator: () => clsx({
            '!hidden': hideIndicator,
          }),
        }}
        {...props}
      />
    </Style.Label>
  );
};
