import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const Container = styled.div`
  ${tw`flex items-center justify-center h-full`}
`;

export const RoundContainer = styled.div`
  ${tw`rounded-full w-56 h-56 bg-input-blue flex flex-col gap-2 items-center justify-center relative`}
`;

export const Title = styled.div`
  ${tw`text-56-64 font-bold text-gray7`}
`;

export const SubTitle = styled.div`
  ${tw`text-base font-medium text-dim-gray`}
`;

export const Skip = styled.div`
  ${tw`text-15-20 font-bold text-button-blue`}
`;

export const SvgContainer = styled.div`
  ${tw`absolute`}
`;
