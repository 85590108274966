import React, {
  FC, PropsWithChildren
} from 'react';

import * as Style from './Card.styles';

export const Card: FC<PropsWithChildren> = ({
  children
}) => {
  return <Style.CardContainer>{children}</Style.CardContainer>;
};
