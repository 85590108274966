import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MainContainer = styled.div`
  ${tw`bg-white w-910px rounded-md p-6 h-full mx-auto flex flex-col gap-8 mb-6`}
`;

export const Head = styled.div`
  ${tw`flex gap-2 items-center`}
`;

export const HeadInfo = styled.div`
  ${tw`flex flex-col gap-1 grow`}
`;

export const HeadTitle = styled.p`
  ${tw`font-bold text-28-36 text-gray7`}
`;

export const HeadSubtitle = styled.p`
  ${tw`font-bold text-13-21 text-dim-gray`}
`;

export const UploadSectionContainer = styled.div`
  ${tw`p-6 border border-gray94 rounded-md`}
`;
