import {
  t
} from 'i18next';

export enum Steps {
  CreateNewGroup = 'CreateNewGroup',
  ChooseMembers = 'ChooseMembers',
}

export const getStepsMap = () => {
  return [
    {
      id: Steps.CreateNewGroup,
      label: t('organisation.createNewGroup'),
    },
    {
      id: Steps.ChooseMembers,
      label: t('group.chooseMembers'),
    },
  ];
};
