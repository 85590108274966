import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const AlertContainer = styled.div`
  ${tw`px-6 py-3 border rounded border-orange-alert flex justify-center items-center gap-3 bg-white mb-6`}
`;

export const Text = styled.div`
  ${tw`text-lg font-medium text-gray7`}
`;

export const AlertInfo = styled.div<{ $isCentered: boolean }>`
  ${tw`flex-1 flex gap-3 items-center`}

  ${({
    $isCentered
  }) => $isCentered && tw`justify-center`}
`;
