import {
  QuestionsActiveStatistics,
  QuestionsAvailableStatistics,
} from 'src/redux/openapi';

export interface Card {
  id: string;
  title: string;
  topic: string;
  date: string;
}

export interface Topic {
  id: string;
  label: string;
}

export interface Forecast {
  person: {
    value: number;
    optionLeader?: string;
  };
  globe: {
    value: number;
    growth?: number;
    optionLeader?: string;
  };
  group: {
    value: number;
    growth?: number;
    optionLeader?: string;
  };
}

export type QuestionCard =
  | QuestionsActiveStatistics
  | QuestionsAvailableStatistics;

export interface QuestionDataStructure {
  active: QuestionCard[];
  available: QuestionCard[];
  deactivated: QuestionCard[];
}

export enum QuestionsView {
  Table = 'table',
  Cards = 'cards',
}

export enum SortBy {
  Newest = 'Newest',
  Oldest = 'Oldest',
  Discussed = 'Discussed',
  Participants = 'Participants',
  Updates = 'Updates',
}

export enum FilterBy {
  Available = 'AVAILABLE',
  Active = 'ACTIVE',
  Deactivated = 'DEACTIVATED',
}
