import React, {
  FC, useCallback, useEffect, useMemo, useState
} from 'react';
import {
  useSearchParams
} from 'react-router-dom';

import {
  PageLayout
} from 'src/widgets';
import {
  AlertCircleIcon,
  CheckmarkCircleEmptyIcon,
  CloseCircleIcon,
} from 'src/shared/icons';
import {
  ROUTE, status as promotionStatus
} from 'src/shared/utils';
import {
  UpgradeToOrganizationResponse,
  usePatchOrganizationsAcceptMutation,
  usePatchOrganizationsDeclineMutation,
} from 'src/redux/openapi';
import {
  Spinner
} from 'src/shared/components';

import * as Style from './GroupPromotionStatus.styles.';

enum Status {
  Error = 'Error',
  Accept = 'Accept',
  Decline = 'Decline',
}

const STATUS_CONFIG = {
  accepted: {
    title: 'Successful promotion',
    icon: <CheckmarkCircleEmptyIcon className="text-button-blue w-16 h-16" />,
  },
  declined: {
    title: 'Successfully declined',
    icon: <CloseCircleIcon className="text-dark-gray w-16 h-16" />,
  },
  error: {
    title: 'Request Failure',
    subtitle:
      'There is a problem with your request, it could be a bad connection or the group has been deleted.',
    icon: <AlertCircleIcon className="text-red-text-error w-16 h-16" />,
  },
};

export const GroupPromotionStatus: FC = () => {
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState<Status | null>(null);
  const [groupName, setGroupName] = useState<string | null>(null);
  const [organisationName, setOrganisationName] = useState<string | null>(null);

  const [patchApiOrganizationsAccept] = usePatchOrganizationsAcceptMutation();
  const [patchApiOrganizationsDecline] = usePatchOrganizationsDeclineMutation();

  const statusParam = useMemo(
    () => searchParams.get(promotionStatus),
    [searchParams],
  ) as Status;

  const checkStatus = useCallback(
    async () => {
      const apiCallback = statusParam === Status.Decline
        ? patchApiOrganizationsDecline
        : patchApiOrganizationsAccept;

      try {
        const response = (await apiCallback().unwrap()) as UpgradeToOrganizationResponse;

        setGroupName(response.data.groupName);

        if (response.data.organizationName) {
          setOrganisationName(response.data.organizationName);
        }

        setStatus(statusParam);
      } catch (error) {
        setStatus(Status.Error);
      }
    },
    [statusParam]
  );

  useEffect(
    () => {
      checkStatus();
    },
    [checkStatus]
  );

  const {
    title, subtitle, icon
  } = useMemo(
    () => {
      switch (status) {
        case Status.Accept:
          return {
            ...STATUS_CONFIG.accepted,
            subtitle: `Group ${groupName} has been successfully promoted to organization ${organisationName}`,
          };

        case Status.Decline:
          return {
            ...STATUS_CONFIG.declined,
            subtitle: `The request of group ${groupName} for promotion to the organization was successfully declined`,
          };

        case Status.Error:
        default:
          return STATUS_CONFIG.error;
      }
    },
    [status]
  );

  return (
    <PageLayout>
      <Style.MainContainer>
        {status ? (
          <>
            {icon}

            <Style.Title>{title}</Style.Title>

            <Style.Subtitle>{subtitle}</Style.Subtitle>

            <Style.HomeLink to={`/${ROUTE.QUESTION}`}>
              Back to Questions
            </Style.HomeLink>
          </>
        ) : (
          <Spinner />
        )}
      </Style.MainContainer>
    </PageLayout>
  );
};
