import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  EnterRecoveryCode, NavigateBackButton
} from 'src/widgets';

import {
  MainContainer, Title, BackButtonContainer
} from './Login.styles';

export const RecoveryCodePage: FC = () => {
  const {
    t
  } = useTranslation();

  return (
    <>
      <BackButtonContainer>
        <NavigateBackButton />
      </BackButtonContainer>

      <MainContainer>
        <Title>{t('recoveryPage.recoveryCode')}</Title>

        <EnterRecoveryCode />
      </MainContainer>
    </>
  );
};
