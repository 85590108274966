import React, {
  FC
} from 'react';

import {
  QuestionMarkCircleIcon
} from 'src/shared/icons';

import * as Style from './HelpCenter.style';

export const HelpCenter: FC = () => {
  return (
    <Style.Container
      href="https://arqdecisions.com/em-tippspiel-hilfe/"
      target="_blank"
      rel="noreferrer"
    >
      <QuestionMarkCircleIcon />
    </Style.Container>
  );
};
