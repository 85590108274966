import {
  motion
} from 'framer-motion';
import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const Container = styled(motion.div)<{ $maxWidth?: number }>`
  ${tw`p-2 text-xs text-white leading-5 z-10 rounded`}

  ${({
    $maxWidth
  }) => $maxWidth && `max-width: ${$maxWidth}px;`}
`;
