import React from 'react';
import {
  OptionProps, components
} from 'react-select';

import {
  useHighlightedText
} from 'src/shared/hooks';
import {
  Avatar
} from 'src/shared/components/Avatar';

import {
  DefaultValue
} from '../../types';

export const Option = <T,>(props: OptionProps<T>) => {
  const {
    selectProps: {
      inputValue = ''
    },
    data,
  } = props;

  const {
    getHighlightedText
  } = useHighlightedText({
    searchQuery: inputValue,
  });

  const {
    label, rightImageUrl
  } = data as DefaultValue;

  return (
    <components.Option {...props}>
      {rightImageUrl !== undefined && (
        <Avatar
          src={rightImageUrl}
          userName={label}
          size={32}
        />
      )}

      <span>{getHighlightedText(label?.toString() || '')}</span>
    </components.Option>
  );
};
