import {
  SelectOptions, CommentLog
} from './types';

export const MOCK_LOGS: CommentLog[] = [
  {
    type: SelectOptions.LikeComments,
    date: new Date(),
    user: {
      name: 'Konstantin',
      avatarUrl: null,
    },
    questionTitle: 'Will Germany win more than 12 gold medals?',
    comment:
      'Emery Lubin will astound the world at the 2024 Summer Olympics with an awe-inspiring display of fencing master',
  },
  {
    type: SelectOptions.LeftComments,
    date: new Date(),
    user: {
      name: 'Dynamic Velocity',
      avatarUrl: null,
    },
    questionTitle: 'Will Germany win more than 12 gold medals?',
    comment:
      'Emery Lubin will astound the world at the 2024 Summer Olympics with an awe-inspiring display of fencing master',
  },
  {
    type: SelectOptions.UpdateForecast,
    date: new Date(),
    questionSetTitle: '2024 Summer Olympics',
    questionTitle: 'Will Germany win more than 12 gold medals?',
    statisticPercent: 18,
    updatedPercent: 11,
    comment:
      'Enter the comment where I describe my opinion about events whats happen so far and how it can effect in current situation so that why i decide to change my forecast',
  },
  {
    type: SelectOptions.RemoveFromGroup,
    date: new Date(),
    user: {
      name: 'Desirae Lipshutz',
      avatarUrl: null,
    },
    expireTime: new Date(Date.now() + 15 * 60 * 60 * 1000),
    removedUserName: 'Marilyn Lubin',
    groupName: 'Best Berlin Forecasters!',
  },
  {
    type: SelectOptions.InviteToGroup,
    date: new Date(),
    user: {
      name: 'Desirae Lipshutz',
      avatarUrl: null,
    },
    invitedAmount: 7,
    inviteMessage: 'Invite message',
  },
  {
    type: SelectOptions.JoinToGroup,
    date: new Date(),
    user: {
      name: 'Desirae Lipshutz',
      avatarUrl: null,
    },
    groupName: 'Best Berlin Forecasters!',
  },
  {
    type: SelectOptions.ReportUsers,
    date: new Date(),
    user: {
      name: 'Desirae Lipshutz',
      avatarUrl: null,
    },
    reportedUser: {
      name: 'Konstantin',
      avatarUrl: null,
    },
    reportName: 'Bad comment',
    describedReason: 'Some reason',
  },
  {
    type: SelectOptions.ReportComments,
    date: new Date(),
    user: {
      name: 'Dynamic Velocity',
      avatarUrl: null,
    },
    reportedUser: {
      name: 'Konstantin',
      avatarUrl: null,
    },
    reportName: 'Leakage of personal data',
    describedReason: 'Some reason',
    comment:
      'Emery Lubin will astound the world at the 2024 Summer Olympics with an awe-inspiring display of fencing master',
  },
];
