import React, {
  FC
} from 'react';
import {
  useLocation
} from 'react-router-dom';

import {
  useGlobalStylesEffect
} from 'src/app/hooks';
import {
  ROUTE
} from 'src/shared/utils';
import {
  Header
} from 'src/widgets';

interface PageLayoutProps {
  children: JSX.Element | JSX.Element[];
}

export const PageLayout: FC<PageLayoutProps> = ({
  children
}) => {
  useGlobalStylesEffect();

  const location = useLocation();

  const isGeneral = location.pathname.startsWith(`/${ROUTE.PRIVACY_POLICY}`)
    || location.pathname.startsWith(`/${ROUTE.TERMS_REGULATIONS}`)
    || location.pathname.startsWith(`/${ROUTE.FAQ}`);

  return (
    <div
      className="
        flex flex-col h-screen xxl:max-w-1440px
        px-4 md:px-8 pt-0 md:pt-8 pb-2 md:pb-6 xxl:mx-auto
      "
    >
      <Header isGeneral={isGeneral} />

      <main className="mt-3 md:mt-4 grow">{children}</main>
    </div>
  );
};
