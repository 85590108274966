import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const InputButton = styled.button`
  ${tw`relative flex flex-wrap rounded border border-gray94`}
`;

export const EmailField = styled.span`
  ${tw`
    text-center outline-0 block
  `}
`;

export const ErrorMessage = styled.p`
  ${tw`absolute bottom-0 left-0 translate-y-full text-xs text-red-text-error`}
`;
