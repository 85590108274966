import React, {
  FC, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  NotificationContainer,
  NotificationGroupContainer,
} from 'src/entities/NotificationsRoute';
import {
  Avatar, successfulToast
} from 'src/shared/components';
import {
  getTimeAgo, showWarningFromServer
} from 'src/shared/utils';
import {
  AnswerStatus
} from 'src/shared/api/notifications/types';
import {
  AdminNotificationChar,
  usePostCharsNotificationAcceptMutation,
  usePostCharsNotificationRejectMutation,
} from 'src/redux/openapi';

import {
  ConfirmButton
} from '../ConfirmButton';

import * as Style from './Notifications.styles';
import {
  getAnswerStatus
} from './utils';

interface AdminCharacteristicsNotificationProps {
  isNew: boolean;
  createdAt: string;
  authorAvatarUrl?: string | null;
  authorName: string;
  characteristics: AdminNotificationChar[];
  getHighlightedText: (text: string) => (string | JSX.Element)[];
  answerStatus?: AnswerStatus;
  notificationId: string;
}

export const AdminCharacteristicsNotification: FC<
AdminCharacteristicsNotificationProps
> = ({
  isNew,
  createdAt,
  authorAvatarUrl = null,
  authorName,
  characteristics,
  getHighlightedText,
  answerStatus = AnswerStatus.PENDING,
  notificationId,
}) => {
  const {
    t
  } = useTranslation();

  const defaultStatus = getAnswerStatus(answerStatus);

  const [status, setStatus] = useState(defaultStatus);

  const [handleAccept, {
    isLoading: isAcceptLoading
  }] = usePostCharsNotificationAcceptMutation();

  const [handleReject, {
    isLoading: isRejectLoading
  }] = usePostCharsNotificationRejectMutation();

  const handleAnswer = async (answer: boolean) => {
    try {
      const callback = answer ? handleAccept : handleReject;

      const response = await callback({
        id: notificationId,
      }).unwrap();

      successfulToast(response.message);

      const updatedStatus = getAnswerStatus(
        answer ? AnswerStatus.ACCEPTED : AnswerStatus.REJECTED,
      );

      setStatus(updatedStatus);
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  return (
    <NotificationContainer>
      <Style.HeaderWithAvatarWrapper>
        <Style.ImageContainer $isNew={isNew}>
          <Avatar
            size={56}
            src={authorAvatarUrl}
            userName={authorName}
          />
        </Style.ImageContainer>

        <Style.Description>
          <Style.NotificationHeader>
            <Style.NotificationDate>
              {`${getTimeAgo(createdAt)} ${t('date.ago')}`}
            </Style.NotificationDate>

            {status && <Style.TextAnswer>{status}</Style.TextAnswer>}
          </Style.NotificationHeader>

          <Style.NotificationText>
            <Style.NotificationBoldText>
              {getHighlightedText(authorName)}
            </Style.NotificationBoldText>

            {` ${t('notifications.recentlyMadeChangesToCharacteristics')}.`}
          </Style.NotificationText>
        </Style.Description>
      </Style.HeaderWithAvatarWrapper>

      <Style.ItemsList>
        {characteristics.map(({
          keyName, prevValue, newValue, keyId
        }) => (
          <div key={keyId}>
            <Style.ItemsListTitle>{keyName}</Style.ItemsListTitle>

            <NotificationGroupContainer
              $wasRead={!isNew}
              $width={468}
              $mediumFont
              $leftShift={68}
            >
              {t(
                'notifications.hasBeenChangedTo',
                {
                  prevValue: prevValue || '',
                  newValue,
                }
              )}
            </NotificationGroupContainer>
          </div>
        ))}

        {!status && (
          <Style.ButtonsContainer $withShift>
            <ConfirmButton
              variant="big-blue"
              onClick={() => handleAnswer(true)}
              isLoading={isAcceptLoading}
            >
              {t('buttons.approve')}
            </ConfirmButton>

            <ConfirmButton
              variant="big-grey-bordered"
              onClick={() => handleAnswer(false)}
              isLoading={isRejectLoading}
            >
              {t('buttons.reject')}
            </ConfirmButton>
          </Style.ButtonsContainer>
        )}
      </Style.ItemsList>
    </NotificationContainer>
  );
};
