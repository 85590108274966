import React, {
  FC
} from 'react';

import {
  getAxisDateString
} from 'src/shared/utils/dateTime';

type DateTickProps = {
  x?: number;
  y?: number;
  orientation?: 'top' | 'bottom';
  payload?: {
    value: number;
  };
  vertical?: boolean;
  textAnchor?: 'start' | 'middle' | 'end';
} & (
  | {
    date?: false;
    timeGap?: undefined;
  }
  | {
    date: true;
    timeGap?: number;
  }
);

export const GraphTick: FC<DateTickProps> = ({
  x,
  y,
  payload,
  vertical,
  textAnchor = 'start',
  date,
  timeGap,
  orientation,
}) => {
  const yTransform = orientation === 'top' ? -10 : y;

  return (
    <g transform={`translate(${x},${yTransform})`}>
      <text
        x={0}
        y={textAnchor === 'middle' ? 20 : 0}
        dy={vertical ? 14 : 7}
        textAnchor={textAnchor}
        className="text-15-20 font-medium text-dim-gray"
      >
        {date
          ? getAxisDateString({
            fromDate: payload?.value || new Date('01/01/01').getTime(),
            interval: timeGap,
          })
          : payload?.value}
      </text>
    </g>
  );
};
