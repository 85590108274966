import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  NotificationContainer
} from 'src/entities/NotificationsRoute';
import {
  getTimeAgo
} from 'src/shared/utils';
import {
  AnswerStatus
} from 'src/shared/api/notifications/types';

import * as Style from './Notifications.styles';

interface UserCharacteristicsNotificationProps {
  createdAt: string;
  isNew: boolean;
  getHighlightedText: (text: string) => (string | JSX.Element)[];
  status: AnswerStatus.ACCEPTED | AnswerStatus.REJECTED;
}

export const UserCharacteristicsNotification: FC<
UserCharacteristicsNotificationProps
> = ({
  createdAt, isNew, getHighlightedText, status
}) => {
  const {
    t
  } = useTranslation();

  return (
    <NotificationContainer $isNew={isNew}>
      <Style.HeaderWithAvatarWrapper>
        <Style.Description>
          <Style.NotificationHeader>
            <Style.NotificationDate>
              {`${getTimeAgo(createdAt)} ${t('date.ago')}`}
            </Style.NotificationDate>
          </Style.NotificationHeader>

          <Style.NotificationText>
            <Style.NotificationBoldText>
              {getHighlightedText(
                t('notifications.theOrganizationAdministrator'),
              )}
            </Style.NotificationBoldText>

            {` ${
              status === AnswerStatus.ACCEPTED
                ? t('notifications.approvedCharacteristicChange')
                : t('notifications.hasRejectedCharacteristicChange')
            }`}
          </Style.NotificationText>
        </Style.Description>
      </Style.HeaderWithAvatarWrapper>
    </NotificationContainer>
  );
};
