import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  HeightIcon,
  MessageIcon,
  PeopleIcon,
  TrendingUpIcon,
} from 'src/shared/icons';
import {
  getTimeRangeToDisplay
} from 'src/shared/utils';
import {
  TimeSpansValues
} from 'src/redux/timeSpans';
import {
  SortBy
} from 'src/entities/Questions';

import * as Style from './Menus.styles';

const DEFAULT_SELECT_OPTIONS_MAP = [
  {
    id: SortBy.Newest,
    icon: <HeightIcon />,
  },
  {
    id: SortBy.Oldest,
    icon: <HeightIcon className="-scale-y-100" />,
  },
];

const EXTENDED_SELECT_OPTIONS_MAP = [
  {
    id: SortBy.Discussed,
    icon: <MessageIcon />,
  },
  {
    id: SortBy.Participants,
    icon: <PeopleIcon />,
  },
  {
    id: SortBy.Updates,
    icon: <TrendingUpIcon />,
  },
];

interface SortByMenuProps {
  withExtendedOptions?: boolean;
  timeRange?: keyof typeof TimeSpansValues | null;
  onSelect: (value: SortBy | null) => void;
  currentValue: SortBy | null;
}

export const SortByMenu: FC<SortByMenuProps> = ({
  withExtendedOptions,
  onSelect,
  currentValue,
  timeRange,
}) => {
  const {
    t
  } = useTranslation();

  const rangeToDisplay = getTimeRangeToDisplay(timeRange);

  return (
    <Style.MenuContainer>
      <Style.MenuTitle>{t('filters.sortBy')}</Style.MenuTitle>

      {DEFAULT_SELECT_OPTIONS_MAP.map(({
        id, icon
      }) => (
        <Style.MenuItem
          key={id}
          type="button"
          $isActive={currentValue === id}
          onClick={() => onSelect(id)}
        >
          {icon}

          {t(`filters.${id}`)}
        </Style.MenuItem>
      ))}

      {withExtendedOptions
        && EXTENDED_SELECT_OPTIONS_MAP.map(({
          id, icon
        }) => (
          <Style.MenuItem
            key={id}
            type="button"
            $isActive={currentValue === id}
            onClick={() => onSelect(id)}
          >
            {icon}

            <Style.ItemTitle>
              {t(`filters.${id}`)}

              {!!rangeToDisplay && (
              <Style.TimeRange>{`/ ${rangeToDisplay}`}</Style.TimeRange>
              )}
            </Style.ItemTitle>
          </Style.MenuItem>
        ))}
    </Style.MenuContainer>
  );
};
