import React, {
  FC, useState
} from 'react';
import {
  createPortal
} from 'react-dom';
import {
  useTranslation
} from 'react-i18next';

import {
  getTrimmedName, themeColors
} from 'src/shared/utils';
import {
  ArrowUpTooltipIcon
} from 'src/shared/icons';
import {
  useTypedSelector
} from 'src/redux';
import {
  groupsSelectors
} from 'src/redux/groups';
import {
  GraphBarName
} from 'src/entities';
import './style.css';

interface GraphLabelProps {
  x?: number;
  title: GraphBarName | string;
  value?: number;
}

interface Coordinates {
  x: number;
  y: number;
}

export const GraphLabel: FC<GraphLabelProps> = ({
  x, title, value
}) => {
  const isName = Object.values(GraphBarName).includes(title as GraphBarName);
  const allUserGroups = useTypedSelector(groupsSelectors.groups);

  const formatGroupName = (id: string) => {
    const group = allUserGroups.find((groupInState) => groupInState.id === id);

    return group?.name || id;
  };

  const preparedTitle = formatGroupName(title);

  const [coordinates, setCoordinates] = useState<Coordinates | null>(null);

  const {
    t
  } = useTranslation();

  const isGroupTotal = isName && title === GraphBarName.GTOTAL;
  const translatedTitle = isName && t(`question.${title as GraphBarName}`);

  const formattedTitleValue = isGroupTotal
    ? getTrimmedName(translatedTitle as string)
    : translatedTitle;

  return value ? (
    <text
      x={x}
      y={340}
      fill={themeColors.gray7}
      textAnchor="start"
      dominantBaseline="central"
      className="text-13 font-medium relative"
      onMouseEnter={(e) => {
        setCoordinates({
          x: e.clientX,
          y: e.clientY,
        });
      }}
      onMouseLeave={() => setCoordinates(null)}
    >
      {isName ? formattedTitleValue : getTrimmedName(preparedTitle)}

      {coordinates
        && (!isName || isGroupTotal)
        && createPortal(
          <div
            style={{
              top: `${coordinates.y + 15}px`,
              left: `${coordinates.x}px`,
            }}
            className="label-tooltip"
          >
            {isGroupTotal ? translatedTitle : preparedTitle}

            <ArrowUpTooltipIcon className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-full" />
          </div>,
          document.body,
        )}
    </text>
  ) : null;
};
