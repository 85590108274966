import React, {
  FC
} from 'react';
import i18next from 'i18next';

import {
  Select
} from './Select';
import {
  TimePeriodOptions, TimePeriodSelectOption
} from './types';

export const getTimePeriodSelectOptions = () => [
  {
    label: `1 ${i18next.t('date.Month')}`,
    value: TimePeriodOptions.OneMonth,
  },
  {
    label: `3 ${i18next.t('date.Months')}`,
    value: TimePeriodOptions.ThreeMonths,
  },
  {
    label: `6 ${i18next.t('date.Months')}`,
    value: TimePeriodOptions.SixMonth,
  },
  {
    label: `1 ${i18next.t('date.Year')}`,
    value: TimePeriodOptions.OneYear,
  },
  {
    label: `3 ${i18next.t('date.Years')}`,
    value: TimePeriodOptions.ThreeYears,
  },
];

interface TimePeriodSelectProps {
  placeholder?: string;
  timePeriod: TimePeriodSelectOption | null;
  onPeriodChange: (value: TimePeriodSelectOption) => void;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
}

export const TimePeriodSelect: FC<TimePeriodSelectProps> = ({
  placeholder,
  timePeriod,
  onPeriodChange,
  onMenuOpen,
  onMenuClose,
}) => {
  const timePeriodSelectOptions = getTimePeriodSelectOptions();

  const onSelectChange = (
    newValue: TimePeriodSelectOption[] | TimePeriodSelectOption,
  ) => {
    if (!Array.isArray(newValue)) {
      onPeriodChange(newValue);
    }
  };

  return (
    <Select
      options={timePeriodSelectOptions}
      defaultValue={!placeholder ? timePeriodSelectOptions[2] : undefined}
      placeholder={placeholder}
      onChange={onSelectChange}
      selectedValue={timePeriod}
      onMenuClose={onMenuClose}
      onMenuOpen={onMenuOpen}
    />
  );
};
