import React, {
  Key, ReactNode, useEffect, useRef, useState
} from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import {
  Spinner
} from 'src/shared/components';
import {
  useMediaQuery, useGetDeviceSize
} from 'src/shared/hooks';

import * as Style from './Filters.styles';
import {
  Filter, FilterItem
} from './Filter';

interface FiltersProps<T, U extends FilterItem<T>> {
  items: U[];
  activeFilterId: T | null;
  onFilterClick: (id: T | null) => void;
  renderRightElement?: (item: U) => ReactNode;
  renderMenuContent?: (item: U) => ReactNode;
  renderSubtitle?: (item: U) => ReactNode;
  canShrink?: boolean;
  onLoadMore?: () => void;
  hasNextPage?: boolean;
  loading?: boolean;
  isError?: boolean;
  disabledMessage?: string;
}

export const Filters = <T, U extends FilterItem<T>>({
  items,
  activeFilterId,
  onFilterClick,
  renderRightElement,
  renderMenuContent,
  renderSubtitle,
  canShrink,
  onLoadMore = () => {},
  hasNextPage = false,
  loading = false,
  isError = false,
  disabledMessage,
}: FiltersProps<T, U>) => {
  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore,
    disabled: !!isError,
    rootMargin: '0px 0px 0px 100px',
  });

  const filtersRef = useRef<HTMLDivElement | null>(null);
  const [maxWidth, setMaxWidth] = useState(0);

  const isMobile = useMediaQuery(
    'md',
    'down'
  );

  const {
    width
  } = useGetDeviceSize();

  useEffect(
    () => {
      if (filtersRef.current && isMobile) {
        setMaxWidth(width - filtersRef.current.offsetLeft);
      }
    },
    [filtersRef.current?.offsetLeft, isMobile, width]
  );

  return (
    <Style.Filters
      $canShrink={canShrink}
      ref={filtersRef}
      $isMobile={isMobile}
      $maxWidth={maxWidth}
    >
      {items.map((item) => (
        <Filter
          key={item.id as Key}
          item={item}
          renderMenuContent={renderMenuContent}
          renderRightElement={renderRightElement}
          activeFilterId={activeFilterId}
          onFilterClick={onFilterClick}
          renderSubtitle={renderSubtitle}
          disabledMessage={disabledMessage}
        />
      ))}

      {(loading || hasNextPage) && (
        <div ref={sentryRef}>
          <Spinner size={24} />
        </div>
      )}
    </Style.Filters>
  );
};
