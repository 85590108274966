import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MainContainer = styled.div`
  ${tw`flex flex-col gap-4`}
`;

export const CropperContainer = styled.div`
  ${tw`relative w-full h-496px`}
`;

export const FooterContainer = styled.div`
  ${tw`flex gap-12 justify-between`}
`;

export const ZoomContainer = styled.div`
  ${tw`flex gap-4 items-center grow`}
`;

export const SliderContainer = styled.div`
  ${tw`grow`}
`;

export const ButtonsContainer = styled.div`
  ${tw`flex gap-2`}
`;
