import React, {
  forwardRef
} from 'react';

import {
  CircleButton, type ButtonProps
} from 'src/shared/components';
import {
  themeColors
} from 'src/shared/utils/themeColors';
import {
  FunnelIcon
} from 'src/shared/icons';

interface FilterButtonProps extends ButtonProps {
  iconColor?: string;
  borderColor?: string;
  iconSize?: string;
  size?: number;
  onClick: () => void;
}

export const FilterButton = forwardRef<HTMLButtonElement, FilterButtonProps>(
  (
    {
      iconColor = themeColors['dim-gray'],
      borderColor = themeColors.gray90,
      iconSize = 24,
      size,
      onClick,
      ...props
    },
    ref,
  ) => {
    return (
      <CircleButton
        size={size}
        borderColor={borderColor}
        onClick={onClick}
        ref={ref}
        backgroundColor={themeColors.white}
        {...props}
      >
        <FunnelIcon
          color={iconColor}
          width={iconSize}
          height={iconSize}
        />
      </CircleButton>
    );
  },
);
