import {
  PayloadAction, createSlice
} from '@reduxjs/toolkit';

import {
  NotificationType,
  NotificationTypeKeys,
} from 'src/shared/api/notifications/types';
import {
  authApi, usersApi
} from 'src/shared/api';
import {
  NotificationsResponseSchema
} from 'src/redux/openapi';

import {
  getNotificationByType
} from './utils';

type Notifications = NotificationsResponseSchema['data']['notifications'];

export interface NotificationsSlice {
  notifications: Notifications;
  total: number;
  unreadCount: {
    all: number;
    groups: number;
    reply: number;
    actions: number;
  };
}

export interface NotificationsReducer {
  notifications: NotificationsSlice;
}

export const initialState: NotificationsSlice = {
  notifications: [],
  total: 0,
  unreadCount: {
    all: 0,
    groups: 0,
    reply: 0,
    actions: 0,
  },
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotificationToStart: (
      state,
      action: PayloadAction<Notifications[number]>,
    ) => {
      state.notifications = [action.payload, ...state.notifications];
    },
    addNotificationToEnd: (
      state,
      action: PayloadAction<Notifications[number]>,
    ) => {
      state.notifications = [...state.notifications, action.payload];
    },
    resetNotifications: (state) => {
      state.notifications = [];
    },
    addNotificationsData: (
      state,
      action: PayloadAction<NotificationsResponseSchema['data']>,
    ) => {
      state.notifications = action.payload.notifications;
      state.total = action.payload.total;
      state.unreadCount = action.payload.unreadCount;
    },
    addNotifications: (state, action: PayloadAction<Notifications>) => {
      state.notifications = [...state.notifications, ...action.payload];
    },
    increaseTotal: (state) => {
      state.total += 1;
    },
    resetUnread: (state) => {
      state.unreadCount = {
        all: 0,
        groups: 0,
        reply: 0,
        actions: 0,
      };
    },
    increaseUnreadCount: (
      state,
      action: PayloadAction<NotificationTypeKeys>,
    ) => {
      const {
        all, reply, groups, actions
      } = state.unreadCount;

      const {
        payload
      } = action as { payload: NotificationType };

      const {
        isReply, isActions, isGroups
      } = getNotificationByType(payload);

      state.unreadCount = {
        all: all + 1,
        reply: isReply ? reply + 1 : reply,
        groups: isGroups ? groups + 1 : groups,
        actions: isActions ? actions + 1 : actions,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.signOut.matchFulfilled,
      () => initialState,
    );

    builder.addMatcher(
      usersApi.endpoints.getCurrentUser.matchRejected,
      () => initialState,
    );
  },
});

export const {
  resetNotifications,
  addNotificationsData,
  addNotifications,
  increaseTotal,
  addNotificationToEnd,
  addNotificationToStart,
  increaseUnreadCount,
  resetUnread,
} = notificationsSlice.actions;

export const notificationsReducer = notificationsSlice.reducer;
