import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const ListItem = styled.div`
  ${tw`
    flex gap-2 justify-between items-center border border-gray94 rounded 
    font-medium shadow-card-shadow-default p-2 text-gray7
  `}
`;

export const ItemIndex = styled.div`
  ${tw`text-15-20 text-dim-gray min-w-32px`}
`;

export const DomainContainer = styled.p`
  ${tw`grow`}
`;
