import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const AssignMembersGroupContainer = styled.div`
  ${tw`pt-8`}
`;

export const AssignMembersGroupTitle = styled.div`
  ${tw`text-22-30 text-gray7 font-bold mb-1`}
`;

export const AssignMembersGroupText = styled.div`
  ${tw`text-13-21 font-medium text-dim-gray mb-4`}
`;

export const AssignMembersGroupSpan = styled.span`
  ${tw`font-bold text-gray7`}
`;

export const AssignMembersContainer = styled.div`
  ${tw`flex flex-col gap-2 overflow-auto max-h-192px`}
`;

export const AssignMemberContainer = styled.label`
  ${tw`flex items-center gap-2 rounded p-2 border border-gray94 shadow-card-invite cursor-pointer`}
`;

export const AssignMemberRow = styled.div`
  ${tw`flex-1 flex gap-2 items-center`}
`;

export const AssignMemberText = styled.div`
  ${tw`text-15-20 font-medium text-gray7`}
`;
