import React, {
  FC
} from 'react';
import {
  useSearchParams
} from 'react-router-dom';
import {
  useTranslation
} from 'react-i18next';

import {
  GlobeIcon,
  MessageIcon,
  PeopleIcon,
  PersonIcon,
  TrendingUpIcon,
} from 'src/shared/icons';
import {
  SimpleCard,
  ActivityRow,
  ActivityTile,
  StatisticRow,
  StatisticTile,
} from 'src/entities/Questions';
import {
  ActionButtonProps
} from 'src/widgets';
import {
  themeColors
} from 'src/shared/utils/themeColors';
import {
  ButtonTitles
} from 'src/shared/api/types';
import {
  questionsTimeRange
} from 'src/shared/utils';
import {
  TimeSpansValues
} from 'src/redux/timeSpans';
import {
  QuestionsActiveStatistics
} from 'src/redux/openapi';

interface ActiveCardProps {
  card: QuestionsActiveStatistics;
  buttonProps?: ActionButtonProps;
}

export const ActiveCard: FC<ActiveCardProps> = ({
  card, buttonProps
}) => {
  const [searchParams] = useSearchParams();

  const {
    t
  } = useTranslation();

  const {
    participantsCount,
    commentsCount,
    updatesCount,
    userEstimatesAvg,
    globalEstimatesAvg,
    globalEstimatesChange,
    groupEstimatesAvg,
    groupEstimatesChange,
    id,
    type,
  } = card;

  const timeRange = (searchParams.get(questionsTimeRange) as TimeSpansValues) || null;

  const isSurvey = type === 'SURVEY';

  return (
    <SimpleCard
      card={card}
      buttonProps={{
        buttonTextColor: themeColors['button-blue'],
        buttonTitle: isSurvey
          ? t(`buttons.${ButtonTitles.Participate}`)
          : t(`buttons.${ButtonTitles.Update}`),
        buttonWithBorder: false,
        ...buttonProps,
      }}
    >
      <ActivityRow>
        <ActivityTile
          icon={<PeopleIcon className="text-dim-gray w-4 h-4 md:w-6 md:h-6" />}
          amount={participantsCount}
        />

        <ActivityTile
          icon={<MessageIcon className="text-dim-gray w-4 h-4 md:w-6 md:h-6" />}
          amount={commentsCount}
          timeRange={timeRange}
        />

        <ActivityTile
          icon={
            <TrendingUpIcon className="text-dim-gray w-4 h-4 md:w-6 md:h-6" />
          }
          amount={updatesCount}
          timeRange={timeRange}
        />
      </ActivityRow>

      {(!!userEstimatesAvg || !!globalEstimatesAvg || !!groupEstimatesAvg) && (
        <StatisticRow>
          <StatisticTile
            amount={userEstimatesAvg}
            icon={
              <PersonIcon className="text-dim-gray w-4 h-4 md:w-6 md:h-6" />
            }
            withBorder
          />

          <StatisticTile
            amount={globalEstimatesAvg}
            icon={<GlobeIcon className="text-dim-gray w-4 h-4 md:w-6 md:h-6" />}
            growth={globalEstimatesChange || 0}
            withBorder
          />

          <StatisticTile
            amount={groupEstimatesAvg}
            icon={
              <PeopleIcon className="text-dim-gray w-4 h-4 md:w-6 md:h-6" />
            }
            growth={groupEstimatesChange}
            withBorder
            questionId={id}
          />
        </StatisticRow>
      )}
    </SimpleCard>
  );
};
