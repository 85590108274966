import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const ModalFixedLayer = styled.div`
  ${tw`fixed top-0 left-0 bottom-0 right-0 z-1`}
`;

export const MenuContainer = styled.div<{ $isMyComment?: boolean }>`
  ${tw`w-72 p-2 flex flex-col gap-1 rounded-md absolute bottom-0 translate-y-full bg-white shadow-menu-shadow z-20`}

  ${({
    $isMyComment
  }) => ($isMyComment ? tw`right-0` : tw`-right-7`)}
`;

export const ModalFilterTitle = styled.div`
  ${tw`text-13-21 text-dim-gray text-left font-medium px-2 py-1`}
`;

export const ModalLabelShow = styled.label<{ $isActive?: boolean }>`
  ${tw`flex gap-2 p-2 items-center rounded cursor-pointer`}

  ${({
    $isActive
  }) => $isActive && tw`bg-alice-blue`}
`;

export const ModalButton = styled.button`
  ${tw`flex gap-2 p-2 items-center rounded cursor-pointer`}
`;

export const ModalLabelShowTitle = styled.div<{
  $isActive?: boolean;
  $isDelete?: boolean;
}>`
  ${tw`text-17-26 font-medium`}

  ${({
    $isActive
  }) => $isActive && tw`text-button-blue`}

  ${({
    $isActive, $isDelete
  }) => !$isActive && !$isDelete && tw`text-gray7`}

  ${({
    $isDelete
  }) => $isDelete && tw`text-red-text-error`}
`;

export const ModalFilterSeparatorLine = styled.div`
  ${tw`h-px w-full bg-gray94`}
`;

export const ModalLabelDates = styled.label`
  ${tw`flex gap-2 p-2 items-center rounded justify-between cursor-pointer`}
`;

export const ModalLabelDatesTitle = styled.div`
  ${tw`text-base font-medium text-gray7`}
`;

export const ModalListItem = styled.div`
  ${tw`p-2 flex justify-between group-hover:bg-alice-blue`}
`;
export const ModalListItemCount = styled.div`
  ${tw`px-1 rounded-100px group-hover:bg-white bg-input-blue`}
`;
export const ModalListItemTitle = styled.div`
  ${tw`group-hover:text-button-blue`}
`;
