import React from 'react';

import {
  getSearchRegex
} from 'src/shared/utils';

interface UseHighlightedTextArgs {
  searchQuery: string;
  spanClassName?: string;
}

export const useHighlightedText = ({
  searchQuery,
  spanClassName = 'bg-golden-yellow rounded',
}: UseHighlightedTextArgs) => {
  const getHighlightedText = (text = '') => {
    const regex = getSearchRegex(searchQuery);

    return text?.split(regex).map((part, i) => (part.toLowerCase() === searchQuery.toLowerCase() ? (
      <span
        key={i}
        className={spanClassName}
      >
        {part}
      </span>
    ) : (
      part
    )),);
  };

  return {
    getHighlightedText,
  };
};
