import clsx from 'clsx';
import React, {
  FC, useEffect, useMemo, useState
} from 'react';
import {
  useLocation, useNavigate, useSearchParams
} from 'react-router-dom';

import {
  ActivityTile, ActivityRow
} from 'src/entities/Questions';
import {
  CircleButton, Spinner
} from 'src/shared/components';
import {
  ArrowUpIcon,
  PeopleIcon,
  MessageIcon,
  TrendingUpIcon,
} from 'src/shared/icons';
import {
  ROUTE,
  formatDateRange,
  groupId as groupIdSearchParam,
  questionId,
} from 'src/shared/utils';
import {
  CommunityQuestionSet,
  useGetQuestionsCommunityQuery,
  CommunityQuestionsSortBy,
} from 'src/redux/openapi';
import {
  useTypedSelector
} from 'src/redux';
import {
  languageSelectors
} from 'src/redux/language';

import {
  QuestionCard
} from './QuestionCard';

interface TopicCardProps {
  topic: CommunityQuestionSet;
  groupId: string | null;
  sortBy?: CommunityQuestionsSortBy;
  isStatisticsVisible: boolean;
}

export const TopicCard: FC<TopicCardProps> = ({
  topic,
  groupId,
  sortBy,
  isStatisticsVisible,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [searchParams] = useSearchParams();

  const activeQuestionId = searchParams.get(questionId) || null;
  const activeGroupId = searchParams.get(groupIdSearchParam);

  const {
    title,
    startDate,
    endDate,
    commentsCount,
    updatesCount,
    participantsCount,
    id: topicId,
    questionsCount,
  } = topic;

  const language = useTypedSelector(languageSelectors.getLanguage);

  const {
    data: questionsData, isLoading
  } = useGetQuestionsCommunityQuery(
    {
      filters: {
        questionSetId: topicId,
        groupId: groupId || undefined,
        sortBy,
      },
      limit: 5000,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !isExpanded,
      pollingInterval: 20000,
    },
  );

  const date = formatDateRange({
    start: startDate,
    end: endDate,
    language,
  });

  const onExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(
    () => {
      if (location.state?.questionId && !isExpanded) {
        setIsExpanded(true);

        navigate(
          `/${ROUTE.COMMUNITY}?${questionId}=${location.state.questionId}`,
          {
            replace: true,
          },
        );
      } else {
        setIsExpanded(false);
      }
    },
    [activeGroupId]
  );

  const questions = useMemo(
    () => questionsData?.data.questions || [],
    [questionsData],
  );

  return (
    <div>
      <div className="p-4 border-b border-b-gray94 flex flex-col gap-2">
        <div className="flex gap-3 items-center">
          <div className="flex flex-col gap-1 grow">
            <p className="font-bold text-17-26 text-dark-gray">{title}</p>

            <p className="font-medium text-xs text-dim-gray">{date}</p>
          </div>

          <p className="px-1 min-w-28px bg-alice-blue font-medium text-15-20 text-dark-gray rounded-100px text-center">
            {questionsCount}
          </p>

          <div>
            <CircleButton onClick={onExpand}>
              {isLoading ? (
                <Spinner size={24} />
              ) : (
                <ArrowUpIcon
                  className={clsx(
                    'transition',
                    {
                      'rotate-180': !isExpanded,
                    }
                  )}
                />
              )}
            </CircleButton>
          </div>
        </div>

        {isStatisticsVisible && (
          <ActivityRow>
            <ActivityTile
              icon={<PeopleIcon />}
              amount={participantsCount}
            />

            <ActivityTile
              icon={<MessageIcon />}
              amount={commentsCount}
              timeRange="upToday"
            />

            <ActivityTile
              icon={<TrendingUpIcon />}
              amount={updatesCount}
              timeRange="upToday"
            />
          </ActivityRow>
        )}
      </div>

      {isExpanded && !!questions.length && (
        <div>
          {questions.map((questionItem) => {
            return (
              <QuestionCard
                key={questionItem.id}
                question={questionItem}
                isActive={questionItem.id === activeQuestionId}
                isStatisticsVisible={isStatisticsVisible}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
