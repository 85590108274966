import React, {
  FC, SetStateAction, useMemo, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import clsx from 'clsx';

import {
  getDaysUntilNDays
} from 'src/shared/utils';
import {
  Spinner, Tooltip
} from 'src/shared/components';
import {
  SelectOption
} from 'src/widgets/MyProfileMenu/components';
import {
  useGetCharacteristicsKeyValuesQuery,
  UserCharacteristic,
} from 'src/redux/openapi';
import {
  useCustomInfiniteScroll
} from 'src/shared/hooks';
import {
  ArrowDownIcon
} from 'src/shared/icons';

import {
  SelectedValue
} from '../../types';

import * as Style from './CharacteristicsListItem.styles';

interface CharacteristicsListItemProps {
  characteristic: UserCharacteristic;
  selectedValue: {
    label: string;
    value: string;
  };
  toggleValues: (id?: string) => void;
  isOpen: boolean;
  setSelectedValues: (value: SetStateAction<SelectedValue[]>) => void;
}

export const CharacteristicsListItem: FC<CharacteristicsListItemProps> = ({
  characteristic,
  selectedValue,
  toggleValues,
  isOpen,
  setSelectedValues,
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const {
    key: {
      id, name
    },
    lastModifiedAt,
  } = characteristic;

  const timeLeft = lastModifiedAt
    ? getDaysUntilNDays(
      lastModifiedAt,
      30
    )
    : null;

  const {
    data: optionsData,
    isError,
    isFetching,
    isLoading,
  } = useGetCharacteristicsKeyValuesQuery(
    {
      id,
      page: currentPage,
      limit: 10,
    },
    {
      skip: !isOpen,
    },
  );

  const {
    total: totalOptions, currentOptions
  } = useMemo(
    () => {
      const options = optionsData?.data.values.map((value) => ({
        value: value.id,
        label: value.value,
      })) || [];

      return {
        total: optionsData?.data.total || 0,
        currentOptions: options,
      };
    },
    [optionsData]
  );

  const {
    sentryRef,
    allItems: selectOptions,
    displayLoader,
  } = useCustomInfiniteScroll({
    total: totalOptions,
    currentItems: currentOptions,
    isFetching,
    isLoading,
    isError,
    currentPage,
    setCurrentPage,
  });

  const {
    t
  } = useTranslation();

  const onSelect = (
    characteristicId: string,
    newValue: SelectOption<string>[] | SelectOption<string>,
  ) => {
    if (Array.isArray(newValue)) {
      return;
    }

    setSelectedValues((prevValues) => prevValues.map((option) => (option.id === characteristicId
      ? {
        ...option,
        selectedOption: newValue,
      }
      : option),),);

    toggleValues();
  };

  return (
    <Style.CharacteristicContainer key={id}>
      <Style.CharacteristicTitle>{name}</Style.CharacteristicTitle>

      <Style.ListItemWrapper>
        <Style.ListItem
          onClick={() => toggleValues(id)}
          disabled={!!timeLeft}
        >
          <p>{selectedValue?.label}</p>

          {timeLeft ? (
            <Tooltip message={t('editAccount.characteristicsCanBeUpdated')}>
              <Style.DaysLeftText>
                {`${timeLeft} ${t('common.left')}`}
              </Style.DaysLeftText>
            </Tooltip>
          ) : (
            <>
              {isLoading && (
                <Spinner
                  size={24}
                  centered={false}
                />
              )}

              {!isLoading && (
                <ArrowDownIcon
                  className={clsx(
                    'w-6, h-6',
                    {
                      'rotate-180': isOpen,
                    }
                  )}
                />
              )}
            </>
          )}
        </Style.ListItem>

        {isOpen && !!selectOptions.length && (
          <Style.MenuContainer>
            {selectOptions.map((option) => {
              const isActive = option.value === selectedValue.value;

              return (
                <Style.MenuOption
                  key={option.value}
                  onClick={() => onSelect(
                    id,
                    option
                  )}
                  $isActive={isActive}
                >
                  {option.label}
                </Style.MenuOption>
              );
            })}

            {displayLoader && (
              <div ref={sentryRef}>
                <Spinner size={24} />
              </div>
            )}
          </Style.MenuContainer>
        )}
      </Style.ListItemWrapper>
    </Style.CharacteristicContainer>
  );
};
