import React, {
  FC
} from 'react';
import {
  Navigate
} from 'react-router-dom';

import {
  useTypedSelector
} from 'src/redux';
import {
  userSelectors
} from 'src/redux/user';
import {
  ROUTE
} from 'src/shared/utils';
import {
  PageLayout
} from 'src/widgets';

export const NotFound: FC = () => {
  const {
    id
  } = useTypedSelector(userSelectors.user);

  if (!id) {
    return (
      <Navigate
        to={`/${ROUTE.LOGIN}`}
        replace
      />
    );
  }

  return (
    <PageLayout>
      <section
        className="
          h-80vh
          flex flex-col gap-y-6 justify-center
        "
      >
        <h1
          className="
            font-bold text-6xl text-dark-blue text-center
          "
        >
          4😱4 - NOT FOUND!
        </h1>

        <p className="text-dark-blue text-xl text-center">
          Oops... Looks like the page you are looking for does not exist
        </p>
      </section>
    </PageLayout>
  );
};
