import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const RemoveTextWrapper = styled.div`
  ${tw`flex flex-col gap-1`}
`;

export const RemoveTextTitle = styled.p`
  ${tw`font-bold text-xl text-gray7`}
`;

export const RemoveTextSubtitle = styled.p`
  ${tw`text-13-21 font-medium text-dark-gray`}
`;
