import React, {
  FC, FunctionComponent
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import i18next from 'i18next';

import {
  AlertTriangleIcon, CloseIcon, TrashIcon
} from 'src/shared/icons';

import {
  Button
} from '../Button';
import {
  ModalContainer
} from '../ModalContainer';
import {
  Spinner
} from '../Spinner';

import * as Style from './ModalDelete.styles';

interface ModalDeleteProps {
  closeModal: () => void;
  isOpen: boolean;
  title: string | JSX.Element;
  modalText: string | JSX.Element;
  onDelete: () => void;
  isLoading?: boolean;
  deleteButtonTitle?: string;
  deleteButtonIcon?: FunctionComponent<React.SVGProps<SVGSVGElement>>;
  modalWidth?: number;
}

export const ModalDelete: FC<ModalDeleteProps> = ({
  closeModal,
  isOpen,
  title,
  modalText,
  onDelete,
  isLoading,
  deleteButtonTitle = i18next.t('buttons.delete'),
  deleteButtonIcon: DeleteIcon = TrashIcon,
  modalWidth = 450,
}) => {
  const {
    t
  } = useTranslation();

  return (
    <ModalContainer
      modalWidth={modalWidth}
      isDarkBackground
      isHeader={false}
      closeModalHandler={closeModal}
      isOpen={isOpen}
    >
      <div className="flex flex-col gap-6">
        <AlertTriangleIcon className="w-16 h-16 text-red-text-error mx-auto" />

        <Style.ModalTitle>{title}</Style.ModalTitle>

        <Style.ModalText>{modalText}</Style.ModalText>

        <Style.ButtonContainer>
          <Button
            variant="big-black"
            onClick={closeModal}
          >
            <Style.CancelText>{t('buttons.cancel')}</Style.CancelText>

            <CloseIcon className="text-white w-6 h-6" />
          </Button>

          <Button
            variant="big-red"
            onClick={onDelete}
          >
            {isLoading ? (
              <Spinner
                color="white"
                size={24}
                centered={false}
              />
            ) : (
              <>
                <Style.CancelText>{deleteButtonTitle}</Style.CancelText>

                <DeleteIcon className="text-white w-6 h-6" />
              </>
            )}
          </Button>
        </Style.ButtonContainer>
      </div>
    </ModalContainer>
  );
};
