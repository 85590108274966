import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const GroupCard = styled.div`
  ${tw`flex flex-col gap-4 px-6 py-4 border rounded-25px border-gray94`}
`;

export const CardHeader = styled.div`
  ${tw`flex justify-between items-center font-bold text-17-26 text-gray7`}
`;

export const CardLocation = styled.div`
  ${tw`font-medium text-13-21 text-dim-gray mt-1`}
`;

export const CardForecast = styled.div`
  ${tw`flex items-center gap-1 text-dark-gray font-medium text-13-21`}
`;

export const ActivityRow = styled.div`
  ${tw`flex justify-between items-center`}
`;

export const ActivityChild = styled.div`
  ${tw`flex items-center gap-18px`}
`;

export const QuestionsContainer = styled.div`
  ${tw`grid grid-cols-2 gap-2`}
`;

export const QuestionTile = styled.div`
  ${tw`p-2 bg-pale-blue flex flex-col gap-1`}
`;

export const QuestionDates = styled.p`
  ${tw`flex justify-between text-xs font-medium text-dim-gray`}
`;

export const QuestionTitle = styled.div`
  ${tw`text-13-21 font-bold text-dark-gray`}
`;

export const QuestionStatistic = styled.span`
  ${tw`text-center px-1 bg-input-blue rounded-100px`}
`;
