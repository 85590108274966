import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const ListContainer = styled.div`
  ${tw`flex flex-col gap-2`}
`;

export const ItemContainer = styled.div`
  ${tw`flex gap-2 py-4 not-last:border-b border-b-gray94`}
`;

export const ContentWrapper = styled.div`
  ${tw`flex flex-col gap-1 grow`}
`;

export const Title = styled.p`
  ${tw`font-bold text-17-26 text-gray7`}
`;

export const Subtitle = styled.p`
  ${tw`font-medium text-dim-gray text-13-21`}
`;

export const InvitedCount = styled.span`
  ${tw`font-bold text-gray7 text-13-21`}
`;

export const CheckboxContainer = styled.button`
  ${tw`flex`}
`;
