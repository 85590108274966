import React, {
  FC, useState
} from 'react';

import {
  SortPopoverMenu
} from 'src/shared/components/SortPopoverMenu';
import {
  HeightIcon
} from 'src/shared/icons';
import {
  TDate
} from 'src/features';
import {
  Select,
  HistoryDatePicker,
  HistoryCommentCard,
  RemoveGroupCard,
  UpdateForecastCard,
  JoinToGroupCard,
  InviteToGroupCard,
  ReportCard,
} from 'src/widgets/MyProfileMenu/components';

import * as Style from './HistoryTabContent.styles';
import {
  SelectOptions, SelectOption
} from './types';
import {
  MOCK_LOGS
} from './mockData';

enum SortBy {
  Newest = 'Newest',
  Oldest = 'Oldest',
}

const SORT_OPTIONS_MAP = [
  {
    id: SortBy.Newest,
    label: 'From the Newest',
    icon: <HeightIcon />,
  },
  {
    id: SortBy.Oldest,
    label: 'From the Oldest',
    icon: <HeightIcon className="-scale-y-100" />,
  },
];

const selectOptions = [
  {
    label: 'All Actions',
    value: SelectOptions.All,
  },
  {
    label: 'Like comments',
    value: SelectOptions.LikeComments,
  },
  {
    label: 'Left comment',
    value: SelectOptions.LeftComments,
  },
  {
    label: 'Update forecast',
    value: SelectOptions.UpdateForecast,
  },
  {
    label: 'Remove from group',
    value: SelectOptions.RemoveFromGroup,
  },
  {
    label: 'Invite to group',
    value: SelectOptions.InviteToGroup,
  },
  {
    label: 'Join to group',
    value: SelectOptions.JoinToGroup,
  },
  {
    label: 'Report users',
    value: SelectOptions.ReportUsers,
  },
  {
    label: 'Report comments',
    value: SelectOptions.ReportComments,
  },
];

export const HistoryTabContent: FC = () => {
  const [sortBy, setSortBy] = useState(SortBy.Newest);

  const [selectedActions, setSelectedActions] = useState<SelectOption[]>([
    selectOptions[0],
  ]);

  const [selectedDate, setSelectedDate] = useState<TDate[] | null>(null);

  const onSelectChange = (newValue: SelectOption[] | SelectOption) => {
    if (Array.isArray(newValue)) {
      setSelectedActions(newValue);
    }
  };

  return (
    <Style.MainContainer>
      <Style.FiltersContainer>
        <SortPopoverMenu
          options={SORT_OPTIONS_MAP}
          onSelect={(value) => setSortBy(value)}
          currentValue={sortBy}
        />

        <Select
          options={selectOptions}
          placeholder={
            selectedActions.length > 1
              ? `${selectedActions.length} Actions are selected`
              : 'All topics'
          }
          isMulti
          onChange={onSelectChange}
          selectedValue={selectedActions}
          defaultValue={selectOptions[0]}
        />

        <HistoryDatePicker
          onApply={setSelectedDate}
          selectedValue={selectedDate}
        />
      </Style.FiltersContainer>

      {MOCK_LOGS.length ? (
        <Style.LogsContainer>
          {MOCK_LOGS.map((log) => {
            switch (log.type) {
              case SelectOptions.LikeComments:
              case SelectOptions.LeftComments:
                return (
                  <HistoryCommentCard
                    type={log.type}
                    date={log.date}
                    user={log.user}
                    questionTitle={log.questionTitle}
                    comment={log.comment}
                  />
                );

              case SelectOptions.RemoveFromGroup:
                return (
                  <RemoveGroupCard
                    date={log.date}
                    user={log.user}
                    expireTime={log.expireTime}
                    removedUserName={log.removedUserName}
                    groupName={log.groupName}
                  />
                );

              case SelectOptions.UpdateForecast:
                return (
                  <UpdateForecastCard
                    date={log.date}
                    questionSetTitle={log.questionSetTitle}
                    questionTitle={log.questionTitle}
                    statisticPercent={log.statisticPercent}
                    updatedPercent={log.updatedPercent}
                    comment={log.comment}
                  />
                );

              case SelectOptions.JoinToGroup:
                return (
                  <JoinToGroupCard
                    date={log.date}
                    user={log.user}
                    groupName={log.groupName}
                  />
                );

              case SelectOptions.InviteToGroup:
                return (
                  <InviteToGroupCard
                    date={log.date}
                    user={log.user}
                    invitedAmount={log.invitedAmount}
                    inviteMessage={log.inviteMessage}
                  />
                );

              case SelectOptions.ReportComments:
                return (
                  <ReportCard
                    date={log.date}
                    user={log.user}
                    reportedUser={log.reportedUser}
                    reportName={log.reportName}
                    describedReason={log.describedReason}
                    comment={log.comment}
                  />
                );

              case SelectOptions.ReportUsers:
                return (
                  <ReportCard
                    date={log.date}
                    user={log.user}
                    reportedUser={log.reportedUser}
                    reportName={log.reportName}
                    describedReason={log.describedReason}
                  />
                );

              default:
                return null;
            }
          })}
        </Style.LogsContainer>
      ) : (
        <Style.EmptyResults>
          <Style.EmptyResultsTitle>
            No results were found
          </Style.EmptyResultsTitle>
          No results were found for your filters
        </Style.EmptyResults>
      )}
    </Style.MainContainer>
  );
};
