import {
  useEffect, useState
} from 'react';

export const useGetDeviceSize = () => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(
    () => {
      const handleWindowResize = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
      };

      handleWindowResize();

      window.addEventListener(
        'resize',
        handleWindowResize
      );

      return () => window.removeEventListener(
        'resize',
        handleWindowResize
      );
    },
    []
  );

  return {
    width,
    height,
  };
};
