export type TooltipPlacement =
  | 'top-center'
  | 'top-start'
  | 'top-end'
  | 'left-start'
  | 'left-center'
  | 'left-end'
  | 'right-start'
  | 'right-center'
  | 'right-end'
  | 'bottom-start'
  | 'bottom-center'
  | 'bottom-end'
  | 'center';

export enum Variant {
  light = 'light',
  dark = 'dark',
  black = 'black',
  darkGray = 'darkGray',
}

export type TooltipVariant = keyof typeof Variant;
