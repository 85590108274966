import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MainContainer = styled.div`
  ${tw`flex flex-col p-2 gap-1`}
`;

export const MenuItem = styled.button`
  ${tw`flex items-center justify-between p-2`}
`;

export const MenuSubtitle = styled.div`
  ${tw`text-13 font-medium text-dark-gray px-2 py-1`}
`;

export const Divider = styled.div`
  ${tw`bg-gray-whisper h-px w-full`}
`;
