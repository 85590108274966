import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const ContentWrapper = styled.div`
  ${tw`flex-1 overflow-auto flex flex-col`}
`;

export const FiltersContainer = styled.div`
  ${tw`flex gap-3 mb-4`}
`;

export const EmptyScores = styled.div`
  ${tw`grow flex flex-col items-center justify-center gap-2 font-medium text-15-20 text-grey-dark-40 text-center`}
`;

export const EmptyScoresTitle = styled.div`
  ${tw`font-bold`}
`;

export const QuestionList = styled.div`
  ${tw`overflow-auto`}
`;

export const QuestionCard = styled.div`
  ${tw`rounded-md border border-gray94 p-4 flex flex-col gap-4`}
`;

export const Score = styled.div<{ $isNegative?: boolean }>`
  ${tw`font-bold text-17-26 text-dark-gray`}

  ${({
    $isNegative
  }) => $isNegative && tw`text-red-text-error`}
`;

export const CardFooter = styled.div`
  ${tw`flex justify-between gap-4 items-center font-medium text-17 text-gray7`}
`;

export const StatisticRow = styled.div`
  ${tw`flex gap-2`}
`;

export const TopicInfo = styled.div`
  ${tw`flex justify-between gap-1 text-13-21 text-dim-gray`}
`;

export const TopicName = styled.p`
  ${tw`font-bold`}
`;

export const Date = styled.p`
  ${tw`font-medium`}
`;

export const QuestionTitle = styled.p`
  ${tw`font-bold text-xl text-gray7`}
`;
