import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MainContainer = styled.div`
  ${tw`flex flex-col grow`}
`;

export const FiltersContainer = styled.div`
  ${tw`flex gap-3 mb-4`}
`;

export const EmptyResults = styled.div`
  ${tw`grow flex flex-col items-center justify-center gap-2 font-medium text-15-20 text-grey-dark-40 text-center`}
`;

export const EmptyResultsTitle = styled.div`
  ${tw`font-bold`}
`;

export const LogsContainer = styled.div`
  ${tw`flex flex-col gap-4 pb-6`}
`;
