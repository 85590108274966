import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const Container = styled.div`
  ${tw`flex flex-col gap-2 items-center justify-center h-full`}
`;

export const SpinContainer = styled.div`
  ${tw`flex gap-2 items-center justify-center`}
`;

export const LoaderText = styled.p`
  ${tw`text-xl font-bold w-max text-dark-gray`}
`;

export const Text = styled.p`
  ${tw`text-dim-gray text-17-26 font-medium`}
`;
