import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import {
  useParams, useNavigate
} from 'react-router-dom';
import {
  clsx
} from 'clsx';

import {
  CreateEntityLayout,
  CreateEntityContent,
  PageLayout,
} from 'src/widgets';
import {
  EditQuestionsList, useEditQuestions
} from 'src/features';
import {
  Button,
  CircleButton,
  MenuContainer,
  MenuItem,
  PopoverMenu,
  Spinner,
} from 'src/shared/components';
import {
  CloseIcon, MoreVerticalIcon
} from 'src/shared/icons';
import {
  ROUTE, themeColors
} from 'src/shared/utils';
import {
  ModalSaveEditQuestions
} from 'src/features/EditQuestions/ui';
import {
  useGetGroupQuery
} from 'src/redux/openapi';

import * as Style from './EditQuestions.styles';
import {
  getGroupMenuConfig, getOrgMenuConfig
} from './utils';

export const EditQuestions: FC = () => {
  const {
    t
  } = useTranslation();

  const params = useParams();
  const navigate = useNavigate();

  const {
    organizationId, groupId
  } = params;

  const {
    data: groupApiData
  } = useGetGroupQuery(
    {
      id: groupId || '',
    },
    {
      skip: !groupId,
      refetchOnMountOrArgChange: true,
    },
  );

  const isGroupInOrganization = groupApiData?.data.group.organizationStatus === 'ACCEPTED';

  const groupMenuConfig = !isGroupInOrganization ? getGroupMenuConfig() : null;

  const menuConfig = organizationId ? getOrgMenuConfig() : groupMenuConfig;

  const onMenuItemClick = (route: ROUTE) => {
    const baseRoute = organizationId
      ? `${ROUTE.ORGANISATION}/${organizationId}`
      : `${ROUTE.GROUPS}/${groupId}`;

    navigate(`/${baseRoute}/${route}`);
  };

  const {
    isQuestionsLoading,
    preparedSets,
    selectConfig,
    onSave,
    handleProceed,
    deactivationList,
    isSavingInProcess,
    onFilterChange,
    activeGroupId,
    clearDeactivationList,
    groupsFiltersConfig,
    onLoadQuestionsBySetId,
  } = useEditQuestions({
    organizationId,
    groupId,
  });

  const {
    onCancel
  } = selectConfig;

  return (
    <>
      <PageLayout>
        <CreateEntityLayout>
          <CreateEntityContent>
            <Style.Head>
              <Style.HeadTitle>
                {t('group.editQuestionsForGroup')}
              </Style.HeadTitle>

              {menuConfig && (
                <PopoverMenu
                  placement="bottom-end"
                  renderCustomButton={({
                    isOpen,
                    toggleMenu,
                    triggerProps,
                  }) => (
                    <CircleButton
                      onClick={toggleMenu}
                      {...triggerProps}
                      borderColor={
                        isOpen
                          ? themeColors['button-blue']
                          : themeColors['light-grey']
                      }
                    >
                      <MoreVerticalIcon
                        className={clsx(
                          'w-6 h-6',
                          {
                            'text-dark-gray': !isOpen,
                            'text-button-blue': isOpen,
                          }
                        )}
                      />
                    </CircleButton>
                  )}
                >
                  <MenuContainer>
                    {menuConfig.map(({
                      route, label
                    }) => (
                      <MenuItem
                        $withHover
                        onClick={() => onMenuItemClick(route)}
                        key={route}
                      >
                        {label}
                      </MenuItem>
                    ))}
                  </MenuContainer>
                </PopoverMenu>
              )}

              <CircleButton
                borderColor={themeColors['light-grey']}
                onClick={() => navigate(-1)}
              >
                <CloseIcon className="w-6 h-6 text-dark-gray" />
              </CircleButton>
            </Style.Head>

            <EditQuestionsList
              isQuestionsLoading={isQuestionsLoading}
              preparedSets={preparedSets}
              selectConfig={selectConfig}
              groupsFiltersConfig={groupsFiltersConfig}
              activeGroupId={activeGroupId}
              onFilterChange={onFilterChange}
              onLoadQuestionsBySetId={onLoadQuestionsBySetId}
            />

            <Style.ButtonsContainer>
              <Button
                variant="big-black"
                className="text-xl font-bold w-max"
                onClick={onCancel}
              >
                {t('buttons.cancel')}
              </Button>

              <Button
                variant="big-blue"
                className="text-xl font-bold w-max"
                onClick={onSave}
              >
                {t('buttons.save')}

                {isSavingInProcess && !deactivationList && (
                  <Spinner
                    color="white"
                    size={24}
                  />
                )}
              </Button>
            </Style.ButtonsContainer>
          </CreateEntityContent>
        </CreateEntityLayout>
      </PageLayout>

      <ModalSaveEditQuestions
        closeModalHandler={clearDeactivationList}
        backButtonHandler={clearDeactivationList}
        handleProceed={handleProceed}
        isProceedInProcess={isSavingInProcess && !!deactivationList}
        isOpen={!!deactivationList}
        deactivatedList={deactivationList}
      />
    </>
  );
};
