import {
  useState, useRef, useEffect
} from 'react';
import dayjs from 'dayjs';

import {
  SECOND
} from '../utils';

type ReturnFormat = 'm:ss';

interface UseGetRemainingTimeArgs {
  initialSeconds?: number;
  format?: ReturnFormat;
}

export const useGetRemainingTime = ({
  initialSeconds = 60,
  format = 'm:ss',
}: UseGetRemainingTimeArgs = {}) => {
  const [remainingTime, setRemainingTime] = useState<number | null>(null);
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

  const stopCountdown = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }

    setRemainingTime(null);
  };

  const startCountdown = () => {
    setRemainingTime(initialSeconds);

    const timeOfSendingCode: number = Date.now();
    const timeToResendCode = timeOfSendingCode + initialSeconds * SECOND;

    intervalRef.current = setInterval(
      () => {
        const remaining = Math.ceil((timeToResendCode - Date.now()) / 1000);

        if (remaining <= 0) {
          stopCountdown();
        } else {
          setRemainingTime(remaining);
        }
      },
      1000
    );
  };

  useEffect(
    () => {
      return stopCountdown();
    },
    []
  );

  const formattedTime = remainingTime
    ? dayjs(remainingTime * 1000).format(format)
    : null;

  return {
    remainingTime: formattedTime,
    startCountdown,
    stopCountdown,
  };
};
