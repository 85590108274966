import {
  t
} from 'i18next';

import {
  TrashDeleteIcon, EditPencilIcon
} from 'src/shared/icons';

import {
  EditMenuActions, ManagementEntity
} from '../../types';

export const getEditMenuConfig = (type: ManagementEntity) => {
  switch (type) {
    case 'organization':
      return [
        {
          action: EditMenuActions.EditOrganisation,
          icon: EditPencilIcon,
          label: t('organisation.editOrganisation'),
        },
        {
          action: EditMenuActions.DeleteOrg,
          icon: TrashDeleteIcon,
          label: t('organisation.Delete'),
        },
      ];

    case 'groupRequest':
      return [
        {
          action: EditMenuActions.DeleteRequest,
          icon: TrashDeleteIcon,
          label: t('organisation.removeRequest'),
        },
      ];

    case 'group':
      return [
        {
          action: EditMenuActions.EditGroup,
          icon: EditPencilIcon,
          label: t('group.editGroup'),
        },
        {
          action: EditMenuActions.DeleteGroup,
          icon: TrashDeleteIcon,
          label: t('group.deleteGroup'),
        },
      ];

    default:
      return [];
  }
};
