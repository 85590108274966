import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MainContainer = styled.div<{ $visibleScroll?: boolean }>`
  ${tw`overflow-y-scroll relative`}

  ${({
    $visibleScroll
  }) => !$visibleScroll
    && `
    &::-webkit-scrollbar {
      display: none;
    }
  `}
`;

export const BlurredEdgeContainer = styled.div<{
  $position?: 'top' | 'bottom';
  $bgColor: string;
}>`
  ${tw`h-0 w-full sticky right-0`}

  ${({
    $position, $bgColor
  }) => {
    if ($position === 'top') {
      return `
        top: -1px;

        > div {
          top: 0;
          background: linear-gradient(${$bgColor});
        }
      `;
    }

    return `
      bottom: -1px;

      > div {
        bottom: 0;
        background: linear-gradient(${$bgColor});;
      }
    `;
  }}
`;

export const BlurredEdge = styled.div<{ $height: number }>`
  ${tw`w-full absolute right-0`}

  ${({
    $height
  }) => `
    height: ${$height}px;
  `}
`;
