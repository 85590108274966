import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const ButtonContainer = styled.button<{
  $size: number;
  $withBorder: boolean;
  $borderColor: string;
  $backgroundColor: string;
}>`
  ${tw`
    flex justify-center items-center rounded-full shrink-0
  `}

  ${({
    $withBorder
  }) => $withBorder && tw`border`}

  ${({
    $size, $borderColor, $backgroundColor
  }) => `
    width: ${$size}px;
    height: ${$size}px;
    border-color: ${$borderColor};
    background-color: ${$backgroundColor};
  `}
`;
