import React, {
  FC
} from 'react';
import {
  Trans, useTranslation
} from 'react-i18next';

import {
  QuestionResponseSchema
} from 'src/redux/openapi/endpoints/questions';
import {
  CommunityCommentsSection
} from 'src/widgets';
import {
  Spinner
} from 'src/shared/components';

import * as Style from './Community.styles';

interface ActiveQuestionCommunityProps {
  activeQuestion: QuestionResponseSchema['data']['question'] | null;
  isNoGroups: boolean;
  isQuestionSets: boolean;
  isSetsLoading: boolean;
}

export const ActiveQuestionCommunity: FC<ActiveQuestionCommunityProps> = ({
  activeQuestion,
  isNoGroups,
  isQuestionSets,
  isSetsLoading,
}) => {
  const {
    t
  } = useTranslation();

  if (isSetsLoading) {
    return <Spinner />;
  }

  return activeQuestion ? (
    <CommunityCommentsSection
      activeQuestion={activeQuestion}
      isNoGroups={isNoGroups}
    />
  ) : (
    <Style.EmptyChatContainer>
      {isQuestionSets ? (
        t('community.selectQuestionToOpenThread')
      ) : (
        <>
          <Style.EmptyMessageTitle>
            {t('community.onboardQuestion')}
          </Style.EmptyMessageTitle>

          <Trans
            i18nKey="community.submitFirstForecast"
            components={{
              1: <br />,
            }}
          />
        </>
      )}
    </Style.EmptyChatContainer>
  );
};
