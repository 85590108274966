import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  Avatar
} from 'src/shared/components';
import {
  getTimeAgo
} from 'src/shared/utils';
import {
  NotificationType
} from 'src/shared/api/notifications/types';
import {
  NotificationContainer,
  NotificationGroupContainer,
} from 'src/entities/NotificationsRoute';

import * as Style from './Notifications.styles';

interface UserNotificationsProps {
  authorAvatarUrl?: string | null;
  authorName: string;
  createdAt: string;
  isNew: boolean;
  groupName: string;
  type: NotificationType;
  reason?: string;
  getHighlightedText: (text: string) => (string | JSX.Element)[];
}

export const UserNotifications: FC<UserNotificationsProps> = ({
  authorAvatarUrl = null,
  authorName,
  createdAt,
  isNew,
  groupName,
  type,
  reason,
  getHighlightedText,
}) => {
  const {
    t
  } = useTranslation();

  const notificationText = type === NotificationType.GROUP_USER_REMOVAL
    ? t('group.removedYouFromGroup')
    : t('group.promotedYouToAdminIn');

  return (
    <NotificationContainer $isNew={isNew}>
      <Style.HeaderWithAvatarWrapper>
        <Style.ImageContainer $isNew={isNew}>
          <Avatar
            size={56}
            src={authorAvatarUrl}
            userName={authorName}
          />
        </Style.ImageContainer>

        <Style.Description>
          <Style.NotificationHeader>
            <Style.NotificationDate>
              {`${getTimeAgo(createdAt)} ${t('date.ago')}`}
            </Style.NotificationDate>
          </Style.NotificationHeader>

          <Style.NotificationText>
            <Style.NotificationBoldText>
              {getHighlightedText(authorName)}
            </Style.NotificationBoldText>

            {` ${notificationText} `}

            <Style.NotificationBoldText>
              {getHighlightedText(groupName)}
            </Style.NotificationBoldText>
          </Style.NotificationText>
        </Style.Description>
      </Style.HeaderWithAvatarWrapper>

      {reason && (
        <NotificationGroupContainer
          $wasRead={!isNew}
          className="max-w-468 w-max"
          $mediumFont
          $leftShift={68}
        >
          {reason}
        </NotificationGroupContainer>
      )}
    </NotificationContainer>
  );
};
