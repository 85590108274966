import {
  createSlice
} from '@reduxjs/toolkit';
import type {
  PayloadAction
} from '@reduxjs/toolkit';

import {
  authApi, usersApi
} from 'src/shared/api';
import {
  arqApiAuth, arqApiUsers, User
} from 'src/redux/openapi';

const initialState: User = {
  id: '',
  name: '',
  email: '',
  avatarUrl: '',
  displayName: '',
  description: '',
  role: 'USER',
  fullName: '',
};

export interface UserReducer {
  user: User;
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<User>) => action.payload,
    setUserEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setUserName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setAvatarUrl: (state, action: PayloadAction<string>) => {
      state.avatarUrl = action.payload;
    },
    resetUserData: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      arqApiAuth.endpoints.postApiAuthSignIn.matchFulfilled,
      (_, {
        payload
      }) => {
        const {
          user, reverificationRequired, verificationRequired
        } = payload.data;

        if (!reverificationRequired && !verificationRequired) {
          return user;
        }

        return initialState;
      },
    );

    builder.addMatcher(
      usersApi.endpoints.getCurrentUser.matchFulfilled,
      (_, {
        payload
      }) => payload.data.user,
    );

    builder.addMatcher(
      arqApiAuth.endpoints.postApiAuthSignUp.matchFulfilled,
      (_, {
        payload
      }) => payload.data.user,
    );

    builder.addMatcher(
      authApi.endpoints.signOut.matchFulfilled,
      () => initialState,
    );

    builder.addMatcher(
      usersApi.endpoints.postApiUsersCurrentAvatar.matchFulfilled,
      (_, {
        payload
      }) => payload.data.user,
    );

    builder.addMatcher(
      arqApiUsers.endpoints.patchApiUsersCurrent.matchFulfilled,
      (_, {
        payload
      }) => payload.data.user,
    );
  },
});

export const {
  setUserData,
  setUserEmail,
  resetUserData,
  setUserName,
  setAvatarUrl,
} = userSlice.actions;

export const userReducer = userSlice.reducer;
