import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const ModalFooter = styled.div`
  ${tw`flex justify-between items-center`}
`;

export const RemoveText = styled.div`
  ${tw`font-bold text-17-26 text-dim-gray`}
`;

export const RemoveCount = styled.span`
  ${tw`text-gray7`}
`;

export const Asterisk = styled.span`
  ${tw`text-red-text-error`}
`;

export const ButtonsContainer = styled.div`
  ${tw`flex gap-2`}
`;

export const SelectLabel = styled.label`
  ${tw`mb-8 block`}
`;

export const SelectLabelText = styled.p`
  ${tw`mb-2 font-medium text-grey-dark-40 text-15-20 flex gap-1`}
`;
