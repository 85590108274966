import React, {
  FC
} from 'react';
import {
  Draggable
} from 'react-beautiful-dnd';
import {
  Coordinate
} from 'recharts/types/util/types';
import {
  useTranslation
} from 'react-i18next';

import {
  CheckIcon, DragIcon, TrashIcon
} from 'src/shared/icons';
import {
  useTypedDispatch
} from 'src/redux';
import {
  removeUser, UserListItem
} from 'src/redux/organizations';
import {
  Divider
} from 'src/shared/components';

import * as Style from './DraggableUserCard.style';
import {
  ActiveDraggedCard
} from './ActiveDraggedCard';

interface DraggableUserCardProps {
  user: UserListItem;
  index: number;
  count: number;
  isSelected: boolean;
  isActiveDrag: boolean;
  handleSelectUser: (user: UserListItem) => void;
  cursorPosition: Coordinate | null;
}

export const DraggableUserCard: FC<DraggableUserCardProps> = ({
  user,
  index,
  count,
  isSelected,
  isActiveDrag,
  handleSelectUser,
  cursorPosition,
}) => {
  const dispatch = useTypedDispatch();

  const {
    email, groups, characteristics
  } = user;

  const characteristicsValues = characteristics
    .map(({
      value
    }) => value)
    .filter(Boolean)
    .join(', ');

  const handleDeleteUser = () => {
    dispatch(removeUser(email));
  };

  const {
    t
  } = useTranslation();

  return (
    <Draggable
      draggableId={email}
      index={index}
    >
      {(provided, snapshot) => {
        return (
          <>
            <Style.CardContainer
              onClick={(e) => {
                if (e.altKey) {
                  handleSelectUser(user);
                }
              }}
              $isDragging={snapshot.isDragging}
              $count={count}
              $isSelected={isSelected}
              $isActiveDrag={isActiveDrag}
            >
              <Style.CardContentContainer>
                <Style.CardDraggable
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  <DragIcon className="text-accent80 shrink-0" />
                </Style.CardDraggable>

                {snapshot.isDragging && (
                  <Style.CardDraggable>
                    <DragIcon className="text-accent80 shrink-0" />
                  </Style.CardDraggable>
                )}

                <Style.CardIndex>{`${index + 1}.`}</Style.CardIndex>

                <Style.InvitationData>
                  <Style.InvitationEmail>{email}</Style.InvitationEmail>

                  <Style.GroupsContainer>
                    {!groups.length ? (
                      <Style.InvitationGroup $isNotAssign>
                        {t('organisation.notAssigned')}
                      </Style.InvitationGroup>
                    ) : (
                      <>
                        {groups.map((group) => (
                          <Style.InvitationGroup key={group}>
                            {group}
                          </Style.InvitationGroup>
                        ))}
                      </>
                    )}
                  </Style.GroupsContainer>
                </Style.InvitationData>

                {isSelected && (
                  <CheckIcon className="text-button-blue shrink-0" />
                )}

                <button
                  type="button"
                  onClick={handleDeleteUser}
                >
                  <TrashIcon className="text-negative80 shrink-0" />
                </button>
              </Style.CardContentContainer>

              {!!characteristicsValues?.length && (
                <>
                  <Divider />

                  <Style.CharacteristicsContainer>
                    {characteristicsValues}
                  </Style.CharacteristicsContainer>
                </>
              )}
            </Style.CardContainer>

            {cursorPosition && snapshot.isDragging && (
              <ActiveDraggedCard
                cursorPosition={cursorPosition}
                index={index}
                isSelected={isSelected}
                email={email}
                groups={groups}
              />
            )}
          </>
        );
      }}
    </Draggable>
  );
};
