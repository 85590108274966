import React, {
  FC, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import clsx from 'clsx';

import {
  Spinner, successfulToast, PopoverMenu
} from 'src/shared/components';
import {
  showWarningFromServer
} from 'src/shared/utils';
import {
  RadialСheckFalseIcon, RadialСheckTrueIcon
} from 'src/shared/icons';
import {
  LocalizationService, TranslationKey
} from 'src/shared/services';
import {
  usePatchUserLanguageMutation
} from 'src/redux/openapi';
import {
  SelectButton
} from 'src/widgets/GraphSection/selects/SelectButton';
import {
  useMediaQuery
} from 'src/shared/hooks';

import * as Styles from './ChangeLanguageBlock.styles';

interface LanguageChoice {
  value: TranslationKey;
}

const languageChoices: LanguageChoice[] = [
  {
    value: 'de',
  },
  {
    value: 'en',
  },
];

export const ChangeLanguageBlock: FC = () => {
  const currentLanguage = LocalizationService.getLanguage();

  const {
    t
  } = useTranslation();

  const [shouldClose, setShouldClose] = useState(false);

  const [updateLanguage, {
    isLoading: isLanguageUpdating
  }] = usePatchUserLanguageMutation();

  const clickHandler = async (newLanguage: TranslationKey) => {
    if (isLanguageUpdating) {
      return;
    }

    if (newLanguage === currentLanguage) {
      setShouldClose(true);

      return;
    }

    try {
      const {
        message
      } = await updateLanguage({
        updateCurrentUserLanguageCodeBodySchema: {
          languageCode: newLanguage,
        },
      }).unwrap();

      successfulToast(message);
    } catch (error) {
      showWarningFromServer(error);
    } finally {
      setShouldClose(true);
    }
  };

  const handleButtonClick = (toggle: () => void) => {
    if (isLanguageUpdating) {
      return;
    }

    if (shouldClose) {
      setShouldClose(false);
    }

    toggle();
  };

  const isMobile = useMediaQuery(
    'md',
    'down'
  );

  return (
    <PopoverMenu
      placement="bottom-start"
      shouldForcedClose={shouldClose}
      renderCustomButton={({
        isOpen, toggleMenu, triggerProps
      }) => (
        <SelectButton
          isOpen={isOpen}
          onButtonClick={() => handleButtonClick(toggleMenu)}
          title={t(`select.${currentLanguage}`)}
          isSelectedValue={!!currentLanguage}
          onClear={() => {}}
          {...triggerProps}
          className={clsx(isMobile && 'border-none')}
        />
      )}
    >
      <Styles.MenuContainer>
        {languageChoices.map(({
          value
        }) => {
          const isSelectedLanguage = value === currentLanguage;

          return (
            <Styles.MenuOption
              key={value}
              type="button"
              onClick={() => clickHandler(value)}
            >
              <Styles.MenuText>
                {isSelectedLanguage && t(`select.${value}`)}

                {!isSelectedLanguage
                  && !isLanguageUpdating
                  && t(`select.${value}`)}

                {!isSelectedLanguage && isLanguageUpdating && (
                  <Spinner size={24} />
                )}
              </Styles.MenuText>

              {isSelectedLanguage ? (
                <RadialСheckTrueIcon />
              ) : (
                <RadialСheckFalseIcon />
              )}
            </Styles.MenuOption>
          );
        })}
      </Styles.MenuContainer>
    </PopoverMenu>
  );
};
