import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

import {
  TDate
} from 'src/features';

dayjs.extend(isBetween);

export const isDateBetweenDates = (
  currentDate: TDate | number,
  dateRange: TDate[],
) => {
  const [start, end] = dateRange;

  const startDate = dayjs(start);
  const createdAt = dayjs(currentDate);

  if (!end) {
    return startDate.isSame(
      createdAt,
      'day'
    );
  }

  const endDate = dayjs(end);

  return createdAt.isBetween(
    startDate,
    endDate,
    'day',
    '[]'
  );
};
