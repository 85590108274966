import React, {
  forwardRef, useImperativeHandle
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  EditQuestionsList, useEditQuestions
} from 'src/features';
import {
  ModalSaveEditQuestions
} from 'src/features/EditQuestions/ui';

import * as Style from './SetUpOrgQuestions.style';

interface SetUpOrgQuestionsProps {
  organizationId: string;
}

export interface SetUpOrgQuestionsMethods {
  handleNextClick: () => Promise<void>;
}

export const SetUpOrgQuestions = forwardRef<
SetUpOrgQuestionsMethods,
SetUpOrgQuestionsProps
>(({
  organizationId
}, ref) => {
  const {
    t
  } = useTranslation();

  const {
    selectConfig,
    onSave,
    preparedSets,
    isQuestionsLoading,
    activeGroupId = null,
    onFilterChange,
    isSavingInProcess,
    deactivationList,
    handleProceed,
    clearDeactivationList,
    groupsFiltersConfig,
    onLoadQuestionsBySetId,
  } = useEditQuestions({
    organizationId,
    shouldCache: true,
  });

  useImperativeHandle(
    ref,
    () => ({
      handleNextClick: onSave,
    })
  );

  return (
    <>
      <Style.SectionTitle>{t('group.chooseQuestionsSet')}</Style.SectionTitle>

      <Style.ContentContainer>
        <EditQuestionsList
          isQuestionsLoading={isQuestionsLoading}
          preparedSets={preparedSets}
          selectConfig={selectConfig}
          groupsFiltersConfig={groupsFiltersConfig}
          activeGroupId={activeGroupId}
          onFilterChange={onFilterChange}
          onLoadQuestionsBySetId={onLoadQuestionsBySetId}
        />
      </Style.ContentContainer>

      <ModalSaveEditQuestions
        closeModalHandler={clearDeactivationList}
        backButtonHandler={clearDeactivationList}
        handleProceed={handleProceed}
        isProceedInProcess={isSavingInProcess && !!deactivationList}
        isOpen={!!deactivationList}
        deactivatedList={deactivationList}
      />
    </>
  );
});
