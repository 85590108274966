import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MainContainer = styled.div`
  ${tw`flex flex-col gap-6 px-6 pb-6 overflow-auto`}
`;

export const Title = styled.div`
  ${tw`font-bold text-22-30 text-gray7`}
`;

export const ContentContainer = styled.div`
  ${tw`flex flex-col gap-4 overflow-auto`}
`;

export const FormContainer = styled.div`
  ${tw`flex flex-col gap-2 font-medium text-15-20 text-grey-dark-40`}
`;

export const ListContainer = styled.div`
  ${tw`flex flex-col gap-2 overflow-auto`}
`;
