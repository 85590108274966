import React, {
  FC, useEffect, useMemo, useRef, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import {
  useParams
} from 'react-router-dom';

import {
  Button,
  ModalContainer,
  Spinner,
  successfulToast,
} from 'src/shared/components';
import {
  getTextareaRowsCount, showWarningFromServer
} from 'src/shared/utils';
import {
  useGetGroupsQuestionOnboardingTextQuery,
  useGetOrgQuestionsWithOnboardingTextQuery,
  usePutGroupsQuestionIdOnboardingTextMutation,
  usePutOrgQuestionsWithOnboardingTextMutation,
} from 'src/redux/openapi';

import * as Style from './ModalEditOnboardingText.style';

interface ModalEditOnboardingTextProps {
  closeModal: () => void;
  isOpen: boolean;
  questionId: string;
  onAddedOnboardingText: () => void;
}

export const ModalEditOnboardingText: FC<ModalEditOnboardingTextProps> = ({
  closeModal,
  isOpen,
  questionId,
  onAddedOnboardingText,
}) => {
  const {
    t
  } = useTranslation();

  const params = useParams();

  const {
    organizationId, groupId
  } = params;

  const [putOnboardingText, {
    isLoading: isOnboardingTextLoading
  }] = usePutOrgQuestionsWithOnboardingTextMutation();

  const [putGroupOnboardingText, {
    isLoading: isGroupOnboardingTextLoading
  }] = usePutGroupsQuestionIdOnboardingTextMutation();

  const {
    onboardingTextInfo, isDataLoading
  } = useGetOrgQuestionsWithOnboardingTextQuery(
    {
      id: organizationId || '',
      questionId,
    },
    {
      skip: !organizationId || !!groupId,
      refetchOnMountOrArgChange: true,
      selectFromResult: ({
        data, isLoading
      }) => ({
        onboardingTextInfo: data?.data.onboardingText || '',
        isDataLoading: isLoading,
      }),
    },
  );

  const {
    onboardingGroupTextInfo, isGroupDataLoading
  } = useGetGroupsQuestionOnboardingTextQuery(
    {
      id: groupId || '',
      questionId,
    },
    {
      skip: !!organizationId || !groupId,
      refetchOnMountOrArgChange: true,
      selectFromResult: ({
        data, isLoading
      }) => ({
        onboardingGroupTextInfo: data?.data.onboardingText || '',
        isGroupDataLoading: isLoading,
      }),
    },
  );

  const [onboardingText, setOnboardingText] = useState('');

  useEffect(
    () => {
      if (organizationId) {
        setOnboardingText(onboardingTextInfo);
      }

      if (groupId) {
        setOnboardingText(onboardingGroupTextInfo);
      }
    },
    [onboardingTextInfo, onboardingGroupTextInfo]
  );

  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const rowsInTextarea = useMemo(
    () => {
      if (!textareaRef?.current || !onboardingText) {
        return 1;
      }

      const lineWidth = textareaRef.current.offsetWidth;

      return getTextareaRowsCount(
        lineWidth,
        onboardingText
      );
    },
    [textareaRef?.current, onboardingText]
  );

  const handleCloseModal = () => {
    closeModal();
    setOnboardingText('');
  };

  const updateOrgOnboardingText = async () => {
    if (!organizationId) {
      return;
    }

    try {
      const resp = await putOnboardingText({
        id: organizationId,
        questionId,
        updateOrganizationQuestionOnboardingTextBodySchema: {
          onboardingText: onboardingText || null,
        },
      }).unwrap();

      successfulToast(resp.message);
      onAddedOnboardingText();
      handleCloseModal();
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  const updateGroupOnboardingText = async () => {
    if (!groupId) {
      return;
    }

    try {
      const resp = await putGroupOnboardingText({
        id: groupId,
        questionId,
        updateGroupQuestionOnboardingTextBodySchema: {
          onboardingText: onboardingText || null,
        },
      }).unwrap();

      successfulToast(resp.message);
      onAddedOnboardingText();
      handleCloseModal();
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  const updateOnboardingText = () => {
    if (organizationId) {
      updateOrgOnboardingText();
    }

    if (groupId) {
      updateGroupOnboardingText();
    }
  };

  const isLoading = isOnboardingTextLoading
    || isDataLoading
    || isGroupDataLoading
    || isGroupOnboardingTextLoading;

  const isNewText = onboardingTextInfo !== onboardingText;

  return (
    <ModalContainer
      modalWidth={634}
      isDarkBackground
      closeModalHandler={handleCloseModal}
      isOpen={isOpen}
      modalTitle={t('modal.editOnboardingText')}
    >
      <Style.MainContainer>
        <div>
          <Style.Label htmlFor="OnboardingText">
            {t('modal.onboardingText')}

            {!!onboardingTextInfo && <Style.RedText>{' *'}</Style.RedText>}
          </Style.Label>

          <Style.TextArea
            rows={rowsInTextarea}
            id="OnboardingText"
            value={onboardingText}
            onChange={(e) => setOnboardingText(e.target.value)}
            placeholder={t('modal.enterOnboardingText')}
            ref={textareaRef}
          />

          <Style.TextHint>{t('modal.onboardingTextHint')}</Style.TextHint>
        </div>

        <Style.ButtonContainer>
          <Button
            variant="big-black"
            onClick={handleCloseModal}
            className="text-xl font-bold text-white !w-max"
          >
            {t('buttons.cancel')}
          </Button>

          <Button
            variant="big-blue"
            className="text-xl font-bold text-white !w-max"
            onClick={updateOnboardingText}
            disabled={isLoading || !isNewText}
          >
            {isLoading ? (
              <Spinner
                size={24}
                color="white"
              />
            ) : (
              t('buttons.save')
            )}
          </Button>
        </Style.ButtonContainer>
      </Style.MainContainer>
    </ModalContainer>
  );
};
