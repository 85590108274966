import React, {
  FC
} from 'react';

import {
  NavList
} from './ui';

interface NavigationProps {
  isGeneral?: boolean;
}

export const Navigation: FC<NavigationProps> = ({
  isGeneral
}) => {
  return (
    <div className="ml-6 md:ml-10 hidden md:block">
      <NavList
        className="flex"
        isGeneral={isGeneral}
      />
    </div>
  );
};
