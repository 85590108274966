import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import * as Style from './UsefulLinksBlock.style';

interface LinkSrc {
  title: string;
  url: string;
}

interface UsefulLinksBlockProps {
  links: LinkSrc[];
}

export const UsefulLinksBlock: FC<UsefulLinksBlockProps> = ({
  links
}) => {
  const leftSideLinksNumber = Math.ceil(links.length / 2);
  const leftSideLinks = links.filter((_, i) => i + 1 <= leftSideLinksNumber);
  const rightSideLinks = links.filter((_, i) => i + 1 > leftSideLinksNumber);

  const {
    t
  } = useTranslation();

  return (
    <Style.MainContainer>
      <Style.Title>{t('common.usefulLinks')}</Style.Title>

      {links.length ? (
        <Style.LinkContainer>
          <Style.SideContainer>
            {leftSideLinks.map(({
              title, url
            }) => (
              <li key={url}>
                <Style.TextLink href={url}>{title}</Style.TextLink>
              </li>
            ))}
          </Style.SideContainer>

          <Style.SideContainer>
            {rightSideLinks.map(({
              title, url
            }) => (
              <li key={url}>
                <Style.TextLink href={url}>{title}</Style.TextLink>
              </li>
            ))}
          </Style.SideContainer>
        </Style.LinkContainer>
      ) : (
        <Style.EmptyText>{t('empty.noUsefulLinks')}</Style.EmptyText>
      )}
    </Style.MainContainer>
  );
};
