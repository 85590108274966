import React, {
  FC, ReactNode, PropsWithChildren, HTMLProps
} from 'react';

import {
  InfoIcon
} from 'src/shared/icons';
import {
  InfoTooltip
} from 'src/entities/Questions';
import {
  Tooltip
} from 'src/shared/components';
import {
  useMediaQuery
} from 'src/shared/hooks';

import * as Style from './StatisticRow.styles';

interface StatisticTileProps extends HTMLProps<HTMLDivElement> {
  icon: ReactNode;
  amount?: number | null;
  growth?: number | null;
  questionId?: string;
  withBorder?: boolean;
  applyMinWidth?: boolean;
  height?: number | string;
  amountLeader?: string;
}

export const StatisticTile: FC<StatisticTileProps> = ({
  icon,
  amount,
  growth,
  questionId,
  withBorder = false,
  applyMinWidth = true,
  height = 48,
  amountLeader,
  ...props
}) => {
  if (typeof amount !== 'number') {
    return null;
  }

  const isMobile = useMediaQuery(
    'md',
    'down'
  );

  return (
    <Style.TileContainer
      $withBorder={withBorder}
      $height={isMobile ? 32 : height}
      {...props}
    >
      {icon}

      <Style.TileContent>
        <div>
          {!!amountLeader && (
            <Tooltip
              variant="dark"
              opacity={1}
              disabled={isMobile}
              message={
                <Style.TooltipContainer>{amountLeader}</Style.TooltipContainer>
              }
            >
              <Style.AmountLeader>{amountLeader}</Style.AmountLeader>
            </Tooltip>
          )}

          <Style.AmountContainer $isMulti={!!amountLeader}>
            <Style.Amount $applyMinWidth={applyMinWidth && !amountLeader}>
              {`${amount || 0}%`}
            </Style.Amount>

            {!!growth && (
              <Style.Growth $isGrowing={growth >= 0}>
                {`(${growth >= 0 ? '+' : ''}${growth}%)`}
              </Style.Growth>
            )}
          </Style.AmountContainer>
        </div>
      </Style.TileContent>

      {questionId && !isMobile && (
        <InfoTooltip questionId={questionId}>
          <Style.InfoContainer>
            <InfoIcon className="text-dim-gray" />
          </Style.InfoContainer>
        </InfoTooltip>
      )}
    </Style.TileContainer>
  );
};

interface StatisticRowProps extends PropsWithChildren {
  isTableView?: boolean;
}

export const StatisticRow: FC<StatisticRowProps> = ({
  children,
  isTableView = false,
}) => {
  return (
    <Style.RowContainer $withBackground={isTableView}>
      {children}
    </Style.RowContainer>
  );
};
