import React, {
  FC, ReactNode
} from 'react';
import useBreadcrumbs, {
  type BreadcrumbsRoute,
  BreadcrumbData,
} from 'use-react-router-breadcrumbs';

import * as Style from './Breadcrumbs.styles';

interface ExtendedBreadcrumbData extends BreadcrumbData<string> {
  customPath?: string;
}

interface BreadcrumbsProps {
  hideHome?: boolean;
  routes?: BreadcrumbsRoute<string>[];
  disableDefaults?: boolean;
}

interface BreadcrumbProps {
  link: string;
  breadcrumb: ReactNode;
  isLast: boolean;
}

const Breadcrumb: FC<BreadcrumbProps> = ({
  link, breadcrumb, isLast
}) => {
  return (
    <Style.Breadcrumb
      to={link}
      $isLast={isLast}
    >
      {breadcrumb}

      {!isLast && <Style.Divider>{' / '}</Style.Divider>}
    </Style.Breadcrumb>
  );
};

export const Breadcrumbs: FC<BreadcrumbsProps> = ({
  hideHome = true,
  routes,
  disableDefaults = false,
}) => {
  const breadcrumbs: ExtendedBreadcrumbData[] = useBreadcrumbs(
    routes,
    {
      disableDefaults,
    }
  );

  return (
    <div>
      {breadcrumbs.map(({
        breadcrumb, match, key, customPath
      }, index) => {
        if (match.pathname === '/' && hideHome) {
          return null;
        }

        return (
          <Breadcrumb
            key={key}
            link={customPath ?? match.pathname}
            isLast={index === breadcrumbs.length - 1}
            breadcrumb={breadcrumb}
          />
        );
      })}
    </div>
  );
};
