import React, {
  FC
} from 'react';

import {
  CheckIcon, KeyAdminIcon
} from 'src/shared/icons';
import {
  isOrganization
} from 'src/shared/utils';

import {
  Avatar
} from '../Avatar';

import * as Style from './ModalLeave.styles';

interface PortalMemberProps {
  user: {
    id: string;
    avatarUrl: string | null;
    name: string;
    displayName: string | null;
    groupName?: string | null;
  };
  selectedUserId: string;
  handleClick: (id: string) => void;
  isAdmin?: boolean;
}

export const PortalMember: FC<PortalMemberProps> = ({
  user,
  selectedUserId,
  handleClick,
  isAdmin,
}) => {
  const {
    id, avatarUrl, name, displayName, groupName = ''
  } = user;

  const userName = displayName || name;

  return (
    <Style.PortalMember
      $isSelected={selectedUserId === id}
      key={id}
    >
      <Style.MemberInfo
        onClick={() => {
          handleClick(id);
        }}
      >
        <Avatar
          size={40}
          src={avatarUrl}
          userName={userName}
        />

        {isOrganization(user) ? (
          <Style.MemberData>
            <Style.MemberGroup>{groupName}</Style.MemberGroup>

            <Style.MemberName>{userName}</Style.MemberName>
          </Style.MemberData>
        ) : (
          <Style.MemberName>{userName}</Style.MemberName>
        )}

        {isAdmin && (
          <KeyAdminIcon className="self-center w-6 h-6 text-brownish-yellow" />
        )}

        {selectedUserId === id ? (
          <CheckIcon className="self-center w-6 h-6 text-button-blue" />
        ) : (
          <Style.EmptyBlock />
        )}
      </Style.MemberInfo>
    </Style.PortalMember>
  );
};
