import {
  createApi
} from '@reduxjs/toolkit/query/react';

import {
  GetNotificationsArg,
  GetNotificationsResponse,
  Notification,
} from 'src/redux/openapi';
import {
  addNotificationToEnd,
  addNotificationToStart,
  increaseTotal,
  increaseUnreadCount,
  getNotificationByType,
} from 'src/redux/notifications';

import {
  baseQuery
} from '../baseQuery';
import {
  socketNotifications
} from '../webSocket/socket';
import {
  Response
} from '../config';

import {
  Context, NotificationType
} from './types';

interface NotificationResponse {
  notification: Notification;
}

export const notificationsApi = createApi({
  baseQuery,
  reducerPath: 'notificationsApi',
  endpoints: (builder) => ({
    getNotifications: builder.mutation<
    GetNotificationsResponse['data'],
    GetNotificationsArg
    >({
      query: (queryArg) => ({
        url: `/api/notifications/`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
          filters: queryArg.filters,
        },
      }),
      transformResponse: (response: GetNotificationsResponse) => {
        return response?.data || {};
      },
      async onCacheEntryAdded(
        queryArg,
        {
          cacheDataLoaded, cacheEntryRemoved, dispatch, getCacheEntry
        },
      ) {
        const socket = socketNotifications;

        try {
          await cacheDataLoaded;

          socket.off('notification:create');

          socket.on(
            'notification:create',
            (data: Response<NotificationResponse>) => {
              const {
                notification
              } = data.data;

              const {
                status
              } = (notification?.context as Context) ?? {};

              const {
                filters, page = 1, limit = 10
              } = queryArg;

              dispatch(increaseUnreadCount(notification.type));
              dispatch(increaseTotal());

              if (filters?.actionRequired && status !== 'PENDING') {
                return;
              }

              const {
                isActions, isGroups, isReply
              } = getNotificationByType(
                notification.type as NotificationType,
              );

              const currentTab = queryArg.filters?.tab;

              const shouldSkipReply = isReply && currentTab !== 'replies';
              const shouldSkipAction = isActions && currentTab !== 'actions';
              const shouldSkipGroup = isGroups && currentTab !== 'groups';

              const shouldSkip = currentTab
                && (shouldSkipReply || shouldSkipAction || shouldSkipGroup);

              if (shouldSkip) {
                return;
              }

              if (queryArg.filters?.sortOrder === 'asc') {
                const cacheData = getCacheEntry();

                const {
                  total = 0
                } = cacheData.data ?? {};

                const wasLastPageLoaded = total <= page * limit;

                if (wasLastPageLoaded) {
                  dispatch(addNotificationToEnd(data.data.notification));
                }
              } else {
                dispatch(addNotificationToStart(data.data.notification));
              }
            },
          );
        } catch (error) {
          /** empty */
        }

        await cacheEntryRemoved;
      },
    }),
  }),
});

export const {
  useGetNotificationsMutation
} = notificationsApi;
