import React, {
  FC, useEffect
} from 'react';
import {
  useLocation
} from 'react-router-dom';
import {
  useTranslation
} from 'react-i18next';

import {
  FilterPopoverMenu
} from 'src/shared/components';
import {
  ROUTE, showWarningFromServer
} from 'src/shared/utils';
import {
  TDate
} from 'src/features';
import {
  useTypedDispatch, useTypedSelector
} from 'src/redux';
import {
  groupsSelectors
} from 'src/redux/groups';
import {
  Logo
} from 'src/shared/icons';
import {
  commentsFilterSelectors,
  resetFeedSelectedDateRange,
  setFeedCommentsSortBy,
  setFeedSelectedDateRange,
  setFeedSelectedGroups,
  setFeedShowMyOnly,
} from 'src/redux/commentsFilters';
import {
  useGetAllGroupsMutation
} from 'src/shared/api/groups/groupsApi';
import {
  userSelectors
} from 'src/redux/user';
import {
  useMediaQuery
} from 'src/shared/hooks';

import {
  SortBy
} from './types';
import * as Style from './CommentsFilterContext.styles';
import {
  GroupSelectorSection,
  MyCommentsOnlySection,
  SortBySection,
  TimeDurationSection,
} from './components';

interface CommentsFilterContextProps {
  isNoGroups?: boolean;
}

export const CommentsFilterContext: FC<CommentsFilterContextProps> = ({
  isNoGroups,
}) => {
  const selectedGroups = useTypedSelector(
    commentsFilterSelectors.selectFeedSelectedGroups,
  );

  const selectedDateRange = useTypedSelector(
    commentsFilterSelectors.selectFeedSelectedDateRange,
  );

  const location = useLocation();

  const isMobile = useMediaQuery(
    'md',
    'down'
  );

  const {
    t
  } = useTranslation();

  const groupsState = useTypedSelector(groupsSelectors.groups);

  const {
    id: userId
  } = useTypedSelector(userSelectors.user);

  const [getAllGroups] = useGetAllGroupsMutation();

  const loadAllGroups = async () => {
    try {
      await getAllGroups({}).unwrap();
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  useEffect(
    () => {
      if (!groupsState.length && userId) {
        loadAllGroups();
      }
    },
    [groupsState.length]
  );

  const preparedGroups = groupsState.map((group) => ({
    id: group.id,
    label: group.name,
    // TODO: change iconSrc with group icon
    iconSrc: Logo,
  }));

  const isCommunityPage = location.pathname.includes(ROUTE.COMMUNITY);
  const isGroupPage = location.pathname.startsWith(`/${ROUTE.COMMUNITY}`);

  const dispatch = useTypedDispatch();

  const isMyOnly = useTypedSelector(
    commentsFilterSelectors.selectFeedShowMyOnly,
  );

  const sortBy = useTypedSelector(
    commentsFilterSelectors.selectFeedCommentsSortBy,
  );

  const onSortClick = (sortMethod: SortBy) => {
    dispatch(setFeedCommentsSortBy(sortMethod));
  };

  const setSelectedGroups = (groupsId: string[]) => {
    dispatch(setFeedSelectedGroups(groupsId));
  };

  const setSelectedDateRange = (dateRange: TDate[] | null) => {
    if (dateRange) {
      dispatch(setFeedSelectedDateRange(dateRange));
    } else {
      dispatch(resetFeedSelectedDateRange());
    }
  };

  const timeRange = useTypedSelector(
    commentsFilterSelectors.selectFeedCommentsTimeRange,
  );

  const toggleOnlyMy = () => {
    dispatch(setFeedShowMyOnly(!isMyOnly));
  };

  return (
    <FilterPopoverMenu
      tooltipMessage={isMobile ? '' : t('filters.filterComments')}
      width={324}
    >
      <Style.MenuContainer id="CommentsFilterContext">
        {!isNoGroups && (
          <>
            <SortBySection
              sortBy={sortBy}
              onSortClick={onSortClick}
            />

            <Style.FilterSeparatorLine />
          </>
        )}

        <TimeDurationSection
          timeRange={timeRange}
          selectedDateRange={selectedDateRange}
          onDateRangeChange={setSelectedDateRange}
          isNoGroups={isNoGroups}
        />

        {!!preparedGroups.length && !isNoGroups && (
          <>
            <Style.FilterSeparatorLine />

            {!isCommunityPage && !isGroupPage && (
              <GroupSelectorSection
                selectedGroups={selectedGroups}
                groups={preparedGroups}
                onApply={setSelectedGroups}
              />
            )}

            <MyCommentsOnlySection
              isMyOnly={isMyOnly}
              toggleOnlyMy={toggleOnlyMy}
            />
          </>
        )}
      </Style.MenuContainer>
    </FilterPopoverMenu>
  );
};
