import React, {
  FC
} from 'react';

import {
  Domain, useDeleteOrgWhitelistItemMutation
} from 'src/redux/openapi';
import {
  Spinner
} from 'src/shared/components';
import {
  showWarningFromServer, themeColors
} from 'src/shared/utils';
import {
  TrashIcon
} from 'src/shared/icons';

import * as Style from './DomainsListItem.styles';

interface DomainsListItemProps {
  organizationId: string;
  domainItem: Domain;
  index: number;
  onRefetch: () => void;
}

export const DomainsListItem: FC<DomainsListItemProps> = ({
  organizationId,
  domainItem,
  index,
  onRefetch,
}) => {
  const {
    id, domain
  } = domainItem;

  const [deleteDomain, {
    isLoading: isDeleting
  }] = useDeleteOrgWhitelistItemMutation();

  const onDelete = async () => {
    try {
      await deleteDomain({
        id: organizationId,
        domainId: id,
      });

      onRefetch();
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  return (
    <Style.ListItem>
      <Style.ItemIndex>{`${index + 1}.`}</Style.ItemIndex>

      <Style.DomainContainer>{domain}</Style.DomainContainer>

      <button
        type="button"
        onClick={onDelete}
      >
        {isDeleting ? (
          <Spinner
            size={24}
            color={themeColors.gray7}
          />
        ) : (
          <TrashIcon />
        )}
      </button>
    </Style.ListItem>
  );
};
