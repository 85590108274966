import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  getPercent, themeColors
} from 'src/shared/utils';

import * as Style from './CircularChartTile.styles';

export enum Variant {
  Scored = 'scored',
  Active = 'active',
}

const variantsConfig = {
  [Variant.Active]: {
    totalColor: themeColors['input-blue'],
    userColor: themeColors['button-blue'],
    title: Variant.Active,
  },
  [Variant.Scored]: {
    totalColor: '#FFF2EB',
    userColor: themeColors['orange-alert'],
    title: Variant.Scored,
  },
};

const BIG_CIRCLE_SIZE = 172;

interface CircularChartTileProps {
  variant: Variant;
  totalValue: number;
  userValue: number;
}

export const CircularChartTile: FC<CircularChartTileProps> = ({
  variant,
  totalValue,
  userValue,
}) => {
  const {
    totalColor, userColor, title
  } = variantsConfig[variant];

  const {
    t
  } = useTranslation();

  const userValuePercentage = getPercent(
    userValue,
    totalValue
  );

  const smallCircleSize = (BIG_CIRCLE_SIZE * userValuePercentage) / 100;

  return (
    <Style.MainContainer>
      <Style.Title>{t(`editAccount.${title}`)}</Style.Title>

      <Style.DescriptionContainer>
        <Style.DescriptionItem>
          <Style.Circle $color={totalColor} />

          {variant === Variant.Active
            ? t('common.available')
            : t('editAccount.activated')}
        </Style.DescriptionItem>

        <Style.DescriptionItem>
          <Style.Circle $color={userColor} />

          {variant === Variant.Active ? t('common.active') : t('common.scored')}
        </Style.DescriptionItem>
      </Style.DescriptionContainer>

      <Style.ChartContainer>
        <Style.Circle
          $color={totalColor}
          $size={BIG_CIRCLE_SIZE}
        >
          <Style.BigCircleContent>
            <Style.TotalValue>{totalValue}</Style.TotalValue>

            <Style.UserValueContainer>
              <Style.UserValue $isDark={smallCircleSize <= 48}>
                {`${userValuePercentage}%`}
              </Style.UserValue>

              <Style.UserValue $isDark={smallCircleSize <= 24}>
                {`(${userValue})`}
              </Style.UserValue>
            </Style.UserValueContainer>

            <Style.SmallCircleContainer>
              <Style.Circle
                $color={userColor}
                $size={smallCircleSize}
              />
            </Style.SmallCircleContainer>
          </Style.BigCircleContent>
        </Style.Circle>
      </Style.ChartContainer>
    </Style.MainContainer>
  );
};
