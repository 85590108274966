import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import * as Style from './InformationBlock.style';

interface InformationBlockProps {
  content: string;
}

export const InformationBlock: FC<InformationBlockProps> = ({
  content
}) => {
  const {
    t
  } = useTranslation();

  return (
    <Style.MainContainer>
      <Style.Title>{t('common.information')}</Style.Title>

      {content ? (
        <Style.Content>{content}</Style.Content>
      ) : (
        <Style.EmptyText>{t('empty.noInformation')}</Style.EmptyText>
      )}
    </Style.MainContainer>
  );
};
