import clsx from 'clsx';
import React, {
  FC
} from 'react';
import {
  Link, useLocation
} from 'react-router-dom';

import {
  useTypedSelector
} from 'src/redux';
import {
  organizationSelectors
} from 'src/redux/organizations';
import {
  Logo as LogoIcon
} from 'src/shared/icons';
import {
  ROUTE
} from 'src/shared/utils';

export const Logo: FC = () => {
  const location = useLocation();
  const isNewUser = location.pathname.startsWith(`/${ROUTE.CREATE_GROUP}`);

  const theme = useTypedSelector(organizationSelectors.orgUsersTheme);

  const imageSrc = theme?.logoUrl || LogoIcon;

  const isImageSrc = !!theme?.logoUrl;

  return (
    <Link
      to={isNewUser ? `/${ROUTE.CREATE_GROUP}` : '/'}
      className={clsx(
        'flex items-center',
        {
          'cursor-default': isNewUser,
          'w-62px h-8': !isImageSrc,
          'w-12 h-12': isImageSrc,
        }
      )}
    >
      <img
        src={imageSrc}
        alt="logo"
        className={clsx(
          'object-contain object-center',
          {
            'w-62px h-8': !isImageSrc,
            'w-12 h-12': isImageSrc,
          }
        )}
      />
    </Link>
  );
};
