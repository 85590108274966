import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const FilterMenuContainer = styled.div<{ $width: number }>`
  ${tw`bg-white rounded`}

  ${({
    $width
  }) => `width: ${$width}px`}
`;
