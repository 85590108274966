import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const SelectContainer = styled.div<{
  $isOpen: boolean;
  $withValue: boolean;
}>`
  ${tw`
    flex gap-1 items-center justify-center py-3 px-4
    transition-all border border-gray90 rounded-1000px
    text-15-16 font-medium box-content text-dark-gray
  `}

  ${({
    $isOpen
  }) => $isOpen && tw`border-gray7`}

  ${({
    $withValue
  }) => $withValue && tw`text-button-blue border-button-blue`}
`;

export const TitleContainer = styled.div`
  ${tw`flex items-center gap-1`}
`;
