import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const Label = styled.label`
  ${tw`flex flex-row gap-1 justify-start items-center w-full`}
`;

export const Text = styled.div`
  ${tw`font-medium text-base text-gray7`}
`;
