import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  Checkbox
} from 'src/shared/components';

import * as Style from './EmailNeededCheckMark.style';

interface EmailNeededCheckMarkProps {
  isChecked: boolean;
  changeHandler: () => void;
}

export const EmailNeededCheckMark: FC<EmailNeededCheckMarkProps> = ({
  isChecked,
  changeHandler,
}) => {
  const {
    t
  } = useTranslation();

  return (
    <Style.Label>
      <Checkbox
        type="checkMark"
        isChecked={isChecked}
        onChange={changeHandler}
        label={t('organisation.duplicateMessageToEmails')}
        className="gap-2"
      />
    </Style.Label>
  );
};
