import React, {
  ChangeEvent, FC, useEffect, useRef, useState
} from 'react';
import {
  useDropzone
} from 'react-dropzone';
import clsx from 'clsx';
import {
  useParams
} from 'react-router-dom';
import {
  useTranslation
} from 'react-i18next';

import {
  DownloadIcon
} from 'src/shared/icons';
import {
  usePostOrgInviteFileTemplateMutation
} from 'src/shared/api/organizations/organizationsApi';
import {
  DE_USERS_TEMPLATE_URL,
  EN_USERS_TEMPLATE_URL,
  MAX_MB_FILE_SIZE,
  XLSX_TYPE,
  getMaxAllowedFileSize,
  showWarningFromServer,
} from 'src/shared/utils';

import {
  Button
} from '../Button';
import {
  errorToast, successfulToast
} from '../Toasts';
import {
  Spinner
} from '../Spinner';

import * as Style from './UploadUsersList.styles';
import './index.css';

export const UploadUsersList: FC = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isFileUploading, setIsFileUploading] = useState(false);

  const params = useParams();

  const {
    t,
    i18n: {
      language
    },
  } = useTranslation();

  const templateLink = language === 'en' ? EN_USERS_TEMPLATE_URL : DE_USERS_TEMPLATE_URL;

  const organisationId = params.organizationId;

  const [postOrgFileTemplate] = usePostOrgInviteFileTemplateMutation();

  const loadFile = async (id: string, formData: FormData) => {
    try {
      setIsFileUploading(true);

      const response = await postOrgFileTemplate({
        id,
        formData,
      }).unwrap();

      successfulToast(response.message);

      setSelectedFile(null);
    } catch (error) {
      showWarningFromServer(error);
    } finally {
      setIsFileUploading(false);
    }
  };

  useEffect(
    () => {
      if (selectedFile && organisationId) {
        const formData = new FormData();

        formData.append(
          'template',
          selectedFile,
          selectedFile.name
        );

        loadFile(
          organisationId,
          formData
        );
      }
    },
    [selectedFile]
  );

  const inputRef = useRef<HTMLInputElement | null>(null);

  const validateFile = (file: File) => {
    const isNotValidTypeFile = file.type !== XLSX_TYPE;

    if (isNotValidTypeFile) {
      errorToast(t('errors.selectValidXlsxFile'));

      return;
    }

    const isBigFile = file.size > getMaxAllowedFileSize(MAX_MB_FILE_SIZE);

    if (isBigFile) {
      errorToast(`${t('errors.selectXlsxFileLessThen')} ${MAX_MB_FILE_SIZE}Mb`);

      return;
    }

    setSelectedFile(file);
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target?.files?.length) {
      return;
    }

    const inputFile = event.target.files[0];

    validateFile(inputFile);
  };

  const {
    getRootProps, getInputProps, isDragActive
  } = useDropzone({
    accept: {
      [XLSX_TYPE]: ['.xlsx'],
    },
    maxSize: getMaxAllowedFileSize(MAX_MB_FILE_SIZE),
    multiple: false,
    onDrop: (acceptedFiles) => {
      if (!acceptedFiles.length) {
        return;
      }

      const file = acceptedFiles[0];

      validateFile(file);
    },
  });

  const inputDrugProps = getInputProps();
  inputDrugProps.accept = `.xlsx, ${XLSX_TYPE}`;
  inputDrugProps.multiple = false;

  const handleInputClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  return (
    <Style.Container>
      <Style.Label htmlFor="downloadFile">
        <Style.Input
          id="downloadFile"
          type="file"
          accept={`.xlsx, ${XLSX_TYPE}`}
          onChange={handleFileChange}
          ref={inputRef}
          {...inputDrugProps}
        />

        {t('organisation.uploadFileWithGroupsAndUsersList')}
      </Style.Label>

      <Style.ActionBlock>
        <Style.InputContainer
          type="button"
          onClick={handleInputClick}
          className={clsx({
            'border-sides': !isDragActive && !isFileUploading,
            'border-sides-input': isDragActive && !isFileUploading,
            'border border-input-blue': isFileUploading,
          })}
          {...getRootProps()}
        >
          <Style.FileContainer>
            {isFileUploading ? (
              <Style.LoadingBlock>
                <Spinner size={24} />

                {`${t('organisation.uploading')}...`}
              </Style.LoadingBlock>
            ) : (
              <>
                <Style.Text>
                  {t('organisation.dragAndDrop')}

                  <Style.Span>{` xlsx`}</Style.Span>

                  {`. ${t('common.or')}`}
                </Style.Text>

                <Style.Text $isBlue>
                  {t('organisation.clickToUpload')}
                </Style.Text>
              </>
            )}
          </Style.FileContainer>
        </Style.InputContainer>

        <div>
          <a
            href={templateLink}
            download
          >
            <Button
              variant="big-grey-bordered"
              className="!h-11"
            >
              <Style.TextButton>
                {t('buttons.downloadTemplate')}
              </Style.TextButton>

              <DownloadIcon />
            </Button>
          </a>
        </div>
      </Style.ActionBlock>
    </Style.Container>
  );
};
