import React, {
  FC, useEffect, useRef
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  FlagIcon, TrashDeleteIcon
} from 'src/shared/icons';
import {
  Spinner
} from 'src/shared/components';

import * as Style from './CommentOptionsContext.styles';

interface CommentOptionsContextProps {
  handleCloseModal: () => void;
  handleOpenReportUser: () => void;
  deleteComment: () => void;
  isLoading: boolean;
  isMyComment: boolean;
  isAdmin: boolean;
}

export const CommentOptionsContext: FC<CommentOptionsContextProps> = ({
  handleCloseModal,
  handleOpenReportUser,
  deleteComment,
  isLoading,
  isMyComment,
  isAdmin,
}) => {
  const itemRef = useRef<HTMLButtonElement | null>(null);

  const {
    t
  } = useTranslation();

  useEffect(
    () => {
      if (itemRef?.current) {
        itemRef.current.focus();
      }
    },
    [itemRef]
  );

  return (
    <>
      <Style.ModalFixedLayer onClick={handleCloseModal} />

      <Style.MenuContainer $isMyComment={isMyComment}>
        {!isMyComment && (
          <Style.ModalButton
            type="button"
            onClick={handleOpenReportUser}
            ref={itemRef}
          >
            <FlagIcon className="w-6 h-6 text-dim-gray" />

            <Style.ModalLabelShowTitle>
              {t('modal.reportUser')}
            </Style.ModalLabelShowTitle>
          </Style.ModalButton>
        )}

        {(isMyComment || isAdmin) && (
          <Style.ModalButton
            type="button"
            onClick={deleteComment}
          >
            <TrashDeleteIcon className="text-red-text-error" />

            {isLoading ? (
              <Spinner
                size={24}
                color="text-red-text-error"
              />
            ) : (
              <Style.ModalLabelShowTitle $isDelete>
                {t('comment.deleteComment')}
              </Style.ModalLabelShowTitle>
            )}
          </Style.ModalButton>
        )}
      </Style.MenuContainer>
    </>
  );
};
