import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MainContainer = styled.div<{ $isEditingActive: boolean }>`
  ${tw`w-628px flex flex-col flex-1 overflow-auto`}

  ${({
    $isEditingActive
  }) => $isEditingActive && tw`justify-between pb-0`}
`;

export const StickyContent = styled.div`
  ${tw`sticky top-0 bg-white z-10`}
`;

export const TabContentWrapper = styled.div`
  ${tw`px-6 overflow-auto flex flex-col`}
`;

export const ButtonContent = styled.div`
  ${tw`md:mr-2`}
`;

export const SummaryContainer = styled.div`
  ${tw`p-4 rounded text-center w-fit mx-auto mb-6`}
`;

export const SummaryTitle = styled.div`
  ${tw`font-bold text-17-26 mb-4`}
`;

export const SummaryNumber = styled.div`
  ${tw`font-bold text-34-43 mb-2`}
`;

export const SummarySubtitle = styled.div`
  ${tw`text-dark-gray font-medium text-15-20 flex justify-center`}
`;

export const SummaryPercentValue = styled.span<{ $isGrowth: boolean }>`
  ${tw`font-medium text-15-20 text-forest-green flex`}

  ${({
    $isGrowth
  }) => ($isGrowth ? tw`text-forest-green` : tw`text-dim-gray`)}
`;

export const Tabs = styled.div`
  ${tw`flex gap-4 border-b border-b-light-grey mb-4`}
`;

export const Tab = styled.button<{ $isActive?: boolean }>`
  ${tw`
    flex-1 pb-3 relative min-w-74px cursor-pointer
    font-medium text-15-20 text-dark-gray text-center
    hover:text-gray7 transition duration-150
  `}

  ${({
    $isActive
  }) => $isActive
    && tw`
    text-button-blue
    after:(content-[""] w-full h-1 bg-button-blue absolute bottom-0 right-0 rounded-t-20px)
    hover:text-button-blue
  `}
`;
