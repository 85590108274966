import React, {
  FC
} from 'react';

import {
  ArrowRightIcon
} from 'src/shared/icons';

import {
  usePaginationPages
} from './hooks';
import * as Style from './Pagination.styles';

interface PaginationProps {
  totalCount: number;
  pageSize: number;
  currentPage: number;
  pageRange?: number;
  pageMargin?: number;
  onPageChange: (page: number) => void;
}

export const Pagination: FC<PaginationProps> = ({
  totalCount,
  pageSize,
  currentPage,
  pageRange = 12,
  pageMargin = 1,
  onPageChange,
}) => {
  const paginationRange = usePaginationPages({
    totalCount,
    pageSize,
    currentPage,
    pageRange,
    pageMargin,
  });

  const handlePreviousClick = () => {
    onPageChange(currentPage - 1);
  };

  const handleNextClick = () => {
    onPageChange(currentPage + 1);
  };

  if (paginationRange.length < 2) {
    return null;
  }

  return (
    <Style.PaginationContainer>
      <Style.PaginationButton
        disabled={currentPage === 1}
        onClick={handlePreviousClick}
      >
        <ArrowRightIcon className="rotate-180" />
      </Style.PaginationButton>

      {paginationRange.map((page, index) => (
        <Style.PaginationPageButton
          key={`${index}_${page}`}
          $isActive={Number(page) === currentPage}
          onClick={() => onPageChange(Number(page))}
          disabled={typeof page !== 'number'}
        >
          {page}
        </Style.PaginationPageButton>
      ))}

      <Style.PaginationButton
        disabled={currentPage === paginationRange.length}
        onClick={handleNextClick}
      >
        <ArrowRightIcon />
      </Style.PaginationButton>
    </Style.PaginationContainer>
  );
};
