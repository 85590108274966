import React, {
  FC, useCallback, useEffect, useMemo, useState
} from 'react';
import {
  Controller, useFieldArray, useForm
} from 'react-hook-form';
import dayjs from 'dayjs';
import {
  useTranslation
} from 'react-i18next';
import {
  useLocation, useNavigate
} from 'react-router-dom';

import {
  BarGraph, SingleValueGraph
} from 'src/entities';
import {
  useTypedSelector
} from 'src/redux';
import {
  timeSpanSelectors
} from 'src/redux/timeSpans';
import {
  useGetQuestionGroupsMutation
} from 'src/shared/api';
import {
  getFilteredTimeRange,
  manageDelegate,
  showWarningFromServer,
} from 'src/shared/utils';
import {
  ForecastType,
  useGetQuestionStatisticsChartsQuery,
  CurrentQuestion,
  ChartData,
  ChartDataApi,
} from 'src/redux/openapi';
import {
  OWN_PREDICTION
} from 'src/entities/Graph/constants';
import {
  Button
} from 'src/shared/components';
import {
  ModalSuccess,
  ManageDelegate,
  ModalAssignDelegate,
} from 'src/features';

import {
  SelectFilter, SelectGraphData, SelectTimeSpan
} from './selects';

interface GraphSectionProps {
  isExpanded?: boolean;
  question: CurrentQuestion;
  newForecast: ForecastType | null;
}

export interface SelectsValue {
  title: string;
  isSelected: boolean;
}

interface GraphFilterSelects {
  forecasts: SelectsValue[];
}

export const GraphSection: FC<GraphSectionProps> = ({
  isExpanded,
  question,
  newForecast,
}) => {
  const [isMyForecastShow, setIsMyForecastShow] = useState(true);
  const [isAverageForecastShow, setIsAverageForecastShow] = useState(true);
  const [groupsId, setGroupsId] = useState<string[]>([]);
  const [hasDelegate, setHasDelegate] = useState(false);
  const [delegateName, setDelegateName] = useState<string | null>(null);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);

  const [isManageDelegateModalOpen, setIsManageDelegateModalOpen] = useState(false);

  const {
    t
  } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();

  const [filteredLinesOfGroups, setFilteredLinesOfGroups] = useState<
  Record<string, boolean>
  >({});

  const [getQuestionGroups] = useGetQuestionGroupsMutation();

  const loadQuestionGroups = useCallback(
    async () => {
      try {
        const response = await getQuestionGroups({
          id: question.id,
        }).unwrap();

        const groupsIds = response.data.groups.map((group) => group.id);

        const filteredValuesToShow = groupsIds.reduce(
          (acc: Record<string, boolean>, val) => {
            return {
              ...acc,
              [val]: false,
            };
          },
          {},
        );

        setGroupsId(groupsIds);
        setFilteredLinesOfGroups(filteredValuesToShow);
      } catch (error) {
        showWarningFromServer(error);
      }
    },
    [question.id]
  );

  const {
    value: selectedTime
  } = useTypedSelector(
    timeSpanSelectors.selectActiveTimeSpan,
  );

  useEffect(
    () => {
      loadQuestionGroups();
    },
    [loadQuestionGroups]
  );

  useEffect(
    () => {
      setHasDelegate(question.hasDelegate);

      if (location.state && manageDelegate in location.state) {
        if (question.hasDelegate) {
          setIsManageDelegateModalOpen(true);
        } else {
          setIsAssignModalOpen(true);
        }

        navigate(
          '.',
          {
            replace: true,
          }
        );
      }
    },
    []
  );

  const {
    data: statisticsChartByIdData,
    refetch: refetchStatisticsChartByIdData,
  } = useGetQuestionStatisticsChartsQuery(
    {
      id: question.id,
      filters: {
        myForecasts: isMyForecastShow,
        groupsAverages: isAverageForecastShow,
        timeRange: getFilteredTimeRange(selectedTime),
        groupIds: groupsId,
      },
    },
    {
      pollingInterval: 20000,
      refetchOnMountOrArgChange: true,
    },
  );

  useEffect(
    () => {
      if (newForecast) {
        refetchStatisticsChartByIdData();
      }
    },
    [newForecast]
  );

  const statisticsData: ChartData[] = useMemo(
    () => {
      if (statisticsChartByIdData?.data.chart.length) {
        return statisticsChartByIdData.data.chart.map((info: ChartDataApi) => ({
          ...info,
          date: dayjs(info.date).toDate().getTime(),
        }));
      }

      return [];
    },
    [statisticsChartByIdData]
  );

  const filteredGroupID = Object.entries(filteredLinesOfGroups)
    .filter(([, value]) => value)
    .map(([key]) => key);

  const toggleShowMyForecast = () => setIsMyForecastShow((prev) => !prev);

  const toggleShowAverageForecast = () => setIsAverageForecastShow((prev) => !prev);

  const handleChangeFilters = (dataKey: string) => {
    if (dataKey === OWN_PREDICTION) {
      toggleShowMyForecast();
    } else {
      setFilteredLinesOfGroups((prev) => {
        const values = Object.entries(prev);

        return values.reduce(
          (acc, [key, value]) => {
            if (key === dataKey) {
              return {
                ...acc,
                [key]: !value,
              };
            }

            return {
              ...acc,
              [key]: value,
            };
          },
          {}
        );
      });
    }
  };

  const handleChangeGroupFilters = (groups: Record<string, boolean>) => {
    setFilteredLinesOfGroups(groups);
  };

  const defaultForecastOptions = useMemo(
    () => {
      return (
        question.estimatesTitles?.map((val) => ({
          title: val,
          isSelected: true,
        })) || []
      );
    },
    [question]
  );

  const {
    control, setValue, watch
  } = useForm<GraphFilterSelects>({
    defaultValues: {
      forecasts: defaultForecastOptions,
    },
  });

  const fieldsData = watch('forecasts');

  const {
    fields
  } = useFieldArray({
    name: 'forecasts',
    control,
  });

  useEffect(
    () => {
      if (question.type === 'MULTIPLE') {
        setValue(
          'forecasts',
          defaultForecastOptions
        );
      }
    },
    [question.id]
  );

  const setNewValue = (title: string) => {
    const newValues = fields.map((option) => {
      if (option.title === title) {
        return {
          ...option,
          isSelected: !option.isSelected,
        };
      }

      return option;
    });

    setValue(
      'forecasts',
      newValues
    );
  };

  const handleAssignDelegate = () => {
    if (!hasDelegate) {
      setIsAssignModalOpen(true);
    } else {
      setIsManageDelegateModalOpen(true);
    }
  };

  const handleOpenSuccessModal = (name: string) => {
    setHasDelegate(true);
    setDelegateName(name);
    setIsAssignModalOpen(false);
  };

  const handleRemoveDelegate = () => {
    setHasDelegate(false);
    setIsManageDelegateModalOpen(false);
  };

  const handleReassignDelegate = () => {
    setIsManageDelegateModalOpen(false);
    setIsAssignModalOpen(true);
  };

  return (
    <div>
      <div
        className="
          mb-6
          flex justify-start
          flex-wrap md:flex-nowrap
        "
      >
        <div className="flex gap-x-3">
          <div className="flex-grow">
            <SelectGraphData
              buttonTitle={t('select.groupAndOrganisation')}
              filteredLines={filteredLinesOfGroups}
              handleChangeFilters={handleChangeGroupFilters}
              isMyForecastShow={isMyForecastShow}
              isAverageForecastShow={isAverageForecastShow}
              showMyForecast={toggleShowMyForecast}
              showAverage={toggleShowAverageForecast}
            />
          </div>

          {question.type === 'MULTIPLE' && (
            <div className="flex-grow">
              <Controller
                name="forecasts"
                control={control}
                render={({
                  field
                }) => {
                  return (
                    <SelectFilter
                      buttonTitle={t('select.forecast')}
                      selectedItems={field.value}
                      onApply={field.onChange}
                    />
                  );
                }}
              />
            </div>
          )}

          <div className="flex-grow">
            <SelectTimeSpan />
          </div>

          {question.status !== 'DEACTIVATED' && (
            <Button
              variant="big-grey-bordered"
              className="w-max !py-3 text-15-20 font-medium text-dark-gray h-max sm:hidden md:flex"
              onClick={handleAssignDelegate}
            >
              {hasDelegate
                ? t('buttons.manageDelegate')
                : t('buttons.assignDelegate')}
            </Button>
          )}
        </div>
      </div>

      {question.type === 'SINGLE' ? (
        <>
          <p className="font-medium text-gray7 text-15 mb-4 pl-3">
            {`% ${t('common.forecast')}`}
          </p>

          <div className="md:mb-8">
            <SingleValueGraph
              isExpanded={isExpanded}
              filteredLines={filteredLinesOfGroups}
              preparedData={statisticsData}
              handleChangeFilters={handleChangeFilters}
              valuesToGraph={groupsId}
              isAverageForecastShow={isAverageForecastShow}
              isMyForecastShow={isMyForecastShow}
            />
          </div>
        </>
      ) : (
        <BarGraph
          optionsSelected={fieldsData}
          changeValue={setNewValue}
          isMyForecastShow={isMyForecastShow}
          isAverageForecastShow={isAverageForecastShow}
          filteredGroupID={filteredGroupID}
          statisticsData={statisticsData}
        />
      )}

      <ModalAssignDelegate
        isOpen={isAssignModalOpen}
        handleCloseModal={() => setIsAssignModalOpen(false)}
        onSuccess={handleOpenSuccessModal}
        isReassign={hasDelegate}
        questionId={question.id}
      />

      <ModalSuccess
        isOpen={!!delegateName}
        closeModal={() => setDelegateName(null)}
        title={`${delegateName} ${t('modal.isYourDelegate')}`}
        textContent={t('modal.successAssignDelegateText')}
        buttonTitle={t('buttons.close')}
      />

      <ManageDelegate
        isOpen={isManageDelegateModalOpen}
        closeModal={() => setIsManageDelegateModalOpen(false)}
        onRemove={handleRemoveDelegate}
        onReassign={handleReassignDelegate}
        questionId={question.id}
      />
    </div>
  );
};
