import React, {
  FC
} from 'react';
import clsx from 'clsx';
import {
  useTranslation
} from 'react-i18next';

import {
  ArrowUpIcon
} from 'src/shared/icons';
import {
  useMediaQuery
} from 'src/shared/hooks';

import {
  CircleButton
} from '../CircleButton';
import {
  SliderBlock
} from '../SliderBlock';

interface ForecastData {
  forecastTitle: string;
  forecastColor: string;
  forecastValue: number;
}

interface YourForecastBlockProps {
  isMulti?: boolean;
  isOpenForecast: boolean;
  onOpenForecast: () => void;
  singleValue: number;
  setSingleValue: (value: number) => void;
  values: ForecastData[];
  setNewValues: (index: number) => (newValue: number) => void;
}

export const YourForecastBlock: FC<YourForecastBlockProps> = ({
  isMulti = true,
  isOpenForecast,
  onOpenForecast,
  singleValue,
  setSingleValue,
  values,
  setNewValues,
}) => {
  const {
    t
  } = useTranslation();

  const isMobile = useMediaQuery(
    'md',
    'down'
  );

  return (
    <div className="py-4 md:py-6 bg-white">
      <div className="flex pt-4 flex-row justify-between items-center border-t border-t-gray-whisper mb-7 md:mb-8">
        <h3 className="text-gray7 text-18-28 md:text-xl font-bold">
          {isMulti || isMobile
            ? t('question.yourForecast')
            : t('question.updateForecast')}
        </h3>

        <div>
          <CircleButton onClick={onOpenForecast}>
            <ArrowUpIcon
              className={clsx({
                'rotate-180': isOpenForecast,
              })}
            />
          </CircleButton>
        </div>
      </div>

      <div
        className={clsx(
          'transition-all',
          {
            hidden: !isOpenForecast,
          }
        )}
      >
        {isMulti ? (
          <div
            className={clsx({
              'grid grid-cols-3 gap-6': isOpenForecast,
            })}
          >
            {values.length
              && values.map((data, i) => (
                <div key={`${data.forecastColor}_${data.forecastTitle}`}>
                  <SliderBlock
                    value={data.forecastValue}
                    setValue={setNewValues(i)}
                    text={data.forecastTitle}
                    colorValue={data.forecastColor}
                    variant="small"
                    formattedValue={`${data.forecastValue}%`}
                  />
                </div>
              ))}
          </div>
        ) : (
          <SliderBlock
            value={singleValue}
            setValue={setSingleValue}
            formattedValue={`${singleValue}%`}
            variant={isMobile ? 'small' : 'big'}
            defaultText
          />
        )}
      </div>
    </div>
  );
};
