import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const CommentsAmount = styled.div`
  ${tw`
    flex gap-1 items-center 
    mb-2.5 pt-9px px-6
    text-xs text-dark-gray font-medium leading-5
  `}
`;

export const CommentsContainer = styled.div`
  ${tw`overflow-scroll relative`}
`;

export const BlurredEdgeContainer = styled.div<{
  $position?: 'top' | 'bottom';
}>`
  ${tw`h-0 w-full sticky right-0`}

  ${({
    $position
  }) => {
    if ($position === 'top') {
      return `
        top: -1px;

        > div {
          top: 0;
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);
        }
      `;
    }

    return `
      bottom: -1px;

      > div {
        bottom: 0;
        background: linear-gradient(0deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
      }
    `;
  }}
`;

export const BlurredEdge = styled.div`
  ${tw`h-69px w-full absolute right-0`}
`;
