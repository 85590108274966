import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const StatsContainer = styled.div`
  ${tw`flex gap-2 mb-3`}
`;

export const TileContainer = styled.div`
  ${tw`p-1`}
`;

export const QuestionsContainer = styled.div`
  ${tw`grid grid-cols-2 gap-4`}
`;

export const NotificationGroupTitle = styled.div`
  ${tw`text-base font-semibold text-gray7 mb-1`}
`;
