import React, {
  FC, useMemo
} from 'react';
import {
  v4 as uuidv4
} from 'uuid';
import {
  useTranslation
} from 'react-i18next';

import {
  Button,
  CircleButton,
  ModalContainer,
  Spinner,
} from 'src/shared/components';
import {
  AlertTriangleIcon, ArrowLeftIcon, CloseIcon
} from 'src/shared/icons';
import {
  formatDateRange, themeColors
} from 'src/shared/utils';
import {
  GroupToDeactivateQuestions,
  OrgToDeactivateQuestions,
} from 'src/redux/openapi';
import {
  useTypedSelector
} from 'src/redux';
import {
  languageSelectors
} from 'src/redux/language';

import * as Style from '../EditQuestions.styles';
import {
  getDeactivationConfig
} from '../utils';

interface ModalSaveEditQuestionsProps {
  closeModalHandler: () => void;
  backButtonHandler: () => void;
  handleProceed: (answer: boolean) => void;
  isProceedInProcess?: boolean;
  isOpen: boolean;
  deactivatedList: OrgToDeactivateQuestions | GroupToDeactivateQuestions | null;
}

export const ModalSaveEditQuestions: FC<ModalSaveEditQuestionsProps> = ({
  closeModalHandler,
  backButtonHandler,
  handleProceed,
  isProceedInProcess,
  isOpen,
  deactivatedList,
}) => {
  const config = useMemo(
    () => (deactivatedList ? getDeactivationConfig(deactivatedList) : null),
    [deactivatedList],
  );

  const language = useTypedSelector(languageSelectors.getLanguage);

  const {
    t
  } = useTranslation();

  if (!config) {
    return null;
  }

  const {
    isQuestions,
    isQuestionSets,
    isQuestionsAndSets,
    isQuestionsOnly,
    isQuestionSetsOnly,
    titleEndPhrase,
    deactivationList,
  } = config;

  const {
    setsData, questionsData
  } = deactivationList;

  return (
    <ModalContainer
      closeModalHandler={closeModalHandler}
      modalWidth={762}
      isOpen={isOpen}
      isHeader={false}
    >
      <Style.SaveEditQuestionsContent>
        <Style.SaveModalHeader>
          <AlertTriangleIcon className="text-orange-alert self-center" />

          <p className="text-22-30 font-bold">
            {`${t('modal.sureToDeactivate')} ${titleEndPhrase}?`}
          </p>

          <Style.SaveTitleWrapper>
            <Style.ModalTitle>
              {isQuestionsAndSets && <>{t('modal.afterDeactivationMessage')}</>}

              {isQuestionsOnly && <>{t('modal.onceDeactivatedMessage')}</>}

              {isQuestionSetsOnly && (
                <>{t('modal.afterDeactivationSetMessage')}</>
              )}
            </Style.ModalTitle>
          </Style.SaveTitleWrapper>

          <Style.SaveCloseButtonWrapper>
            <CircleButton
              onClick={closeModalHandler}
              borderColor={themeColors['light-grey']}
            >
              <CloseIcon className="text-dark-gray w-6 h-6" />
            </CircleButton>
          </Style.SaveCloseButtonWrapper>
        </Style.SaveModalHeader>

        {isQuestionSets && (
          <div>
            {isQuestions && (
              <Style.SubTitleModal>{t('common.set(s)')}</Style.SubTitleModal>
            )}

            {setsData.map(({
              groupNames, sets
            }) => {
              return (
                <div key={uuidv4()}>
                  {groupNames && (
                    <Style.GroupNames>{groupNames.join(', ')}</Style.GroupNames>
                  )}

                  {sets?.map(
                    ({
                      title, startDate, endDate, questionsCount
                    }) => (
                      <Style.QuestionDeactivateModalContainer key={title}>
                        <Style.SaveSetContainer>
                          <Style.QuestionTitleModal>
                            {title}
                          </Style.QuestionTitleModal>

                          <Style.QuestionDatesModal>
                            {formatDateRange({
                              start: startDate,
                              end: endDate,
                              language,
                            })}
                          </Style.QuestionDatesModal>
                        </Style.SaveSetContainer>

                        <Style.QuestionTopicsNumberModal>
                          {questionsCount}
                        </Style.QuestionTopicsNumberModal>
                      </Style.QuestionDeactivateModalContainer>
                    ),
                  )}
                </div>
              );
            })}
          </div>
        )}

        {isQuestions && (
          <div>
            {isQuestionSets && (
              <Style.SubTitleModal>
                {t('common.question(s)')}
              </Style.SubTitleModal>
            )}

            {questionsData.map(({
              groupNames, questions
            }) => (
              <div key={uuidv4()}>
                {groupNames && (
                <Style.GroupNames>{groupNames.join(', ')}</Style.GroupNames>
                )}

                {questions?.map((question, index) => (
                  <Style.TopicDeactivateModalContainer key={question}>
                    <Style.ModalTitle $isBold>
                      {`${index + 1}.`}
                    </Style.ModalTitle>

                    <Style.TopicTitleModal>{question}</Style.TopicTitleModal>
                  </Style.TopicDeactivateModalContainer>
                ))}
              </div>
            ))}
          </div>
        )}
      </Style.SaveEditQuestionsContent>

      <Style.SaveModalFooter>
        <Button
          variant="big-grey-bordered"
          className="w-max"
          onClick={backButtonHandler}
        >
          <ArrowLeftIcon />

          <Style.ModalCancelSpan>{t('buttons.back')}</Style.ModalCancelSpan>
        </Button>

        <Button
          variant="big-blue"
          className="w-max"
          onClick={() => handleProceed(true)}
          disabled={isProceedInProcess}
        >
          <Style.ModalSaveSpan>{t('buttons.proceed')}</Style.ModalSaveSpan>

          {isProceedInProcess && (
            <Spinner
              color="white"
              size={24}
            />
          )}
        </Button>
      </Style.SaveModalFooter>
    </ModalContainer>
  );
};
