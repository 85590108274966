import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const CardContainer = styled.div`
  ${tw`
    p-4 flex flex-col gap-6 items-center rounded-md w-182px
    border border-gray94 shadow-card-shadow-default shrink-0
  `}
`;

export const CardDescription = styled.div`
  ${tw`flex flex-col items-center gap-1`}
`;

export const CardTitle = styled.div`
  ${tw`text-17-26 font-bold text-gray7`}
`;

export const CardSubTitle = styled.div`
  ${tw`text-13-21 font-medium text-dark-gray h-42px text-center line-clamp-2`}
`;

export const AchievedCardDateWrapper = styled.div`
  ${tw`
    flex gap-1 items-center text-xs leading-5 font-medium text-dim-gray w-full flex-auto
    before:(h-px w-full bg-button-blue) after:(h-px w-full bg-button-blue)
  `}
`;

export const AchievedCardDate = styled.div`
  ${tw`grow shrink-0`}
`;

export const AchievedProgressWrapper = styled.div`
  ${tw`flex flex-col gap-2 items-center w-full`}
`;
