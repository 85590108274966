import React, {
  FC, MouseEvent, useState
} from 'react';
import clsx from 'clsx';
import {
  useTranslation
} from 'react-i18next';

import {
  ArrowDownIcon
} from 'src/shared/icons';
import {
  // InputWithTags,
  StrictModeDroppable,
  successfulToast,
} from 'src/shared/components';
import {
  useTypedDispatch
} from 'src/redux';
import {
  removeOrgGroup,
  // addGroupCharacteristic,
  Characteristic,
} from 'src/redux/organizations';
import {
  useDeleteApiGroupsByIdMutation
} from 'src/redux/openapi/endpoints/groups';
import {
  showWarningFromServer
} from 'src/shared/utils';
import {
  PublicPin
} from 'src/widgets';

import {
  Group, SettingOptions
} from '../../types';

import * as Style from './GroupCard.styles';
import {
  NewGroupCard
} from './NewGroupCard';
import {
  GroupCardContext
} from './GroupCardContext';
import {
  GroupUserInList
} from './GroupUserInList';

interface GroupCardProps {
  group: Group;
}

// TODO: uncomment after characteristics clarification

// const characteristicsField = 'characteristics';

enum FormField {
  Characteristics = 'characteristics',
}

export interface CharacteristicForm {
  [FormField.Characteristics]: Characteristic[];
}

export const GroupCard: FC<GroupCardProps> = ({
  group
}) => {
  const {
    t
  } = useTranslation();

  const [isExpanded, setIsExpanded] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const handleClose = () => {
    setIsEdit(false);
  };

  const dispatch = useTypedDispatch();

  const {
    name, id, users, isPublic
  } = group;

  const toggleUserList = () => {
    setIsExpanded((prev) => !prev);
  };

  // TODO: uncomment after characteristics clarification

  // const {
  //   control, watch
  // } = useForm<CharacteristicForm>({
  //   defaultValues: {
  //     [FormField.Characteristics]: characteristics,
  //   }
  // });

  // const characteristicsValues: CharacteristicForm['characteristics'] = watch('characteristics');

  const [deleteGroup, {
    isLoading: isDeleteGroupLoading
  }] = useDeleteApiGroupsByIdMutation();

  const handleDeleteGroup = async () => {
    try {
      const resp = await deleteGroup({
        id,
      }).unwrap();

      successfulToast(resp.message);

      dispatch(
        removeOrgGroup({
          id,
          name,
        }),
      );
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  const handleClick = (
    event: MouseEvent<HTMLButtonElement>,
    option: SettingOptions,
  ) => {
    event.stopPropagation();

    if (isDeleteGroupLoading) {
      return;
    }

    switch (option) {
      case SettingOptions.Delete:
        handleDeleteGroup();
        break;

      case SettingOptions.Edit:
        setIsEdit(true);
        break;

      default:
        break;
    }
  };

  // TODO: uncomment after characteristics clarification

  // const onCharacteristicAdd = () => {
  //   dispatch(
  //     addGroupCharacteristic({
  //       group: group.name,
  //       characteristics: characteristicsValues,
  //     }),
  //   );
  // };

  // useEffect(
  //   () => {
  //     onCharacteristicAdd();
  //   },
  //   [characteristicsValues]
  // );

  return isEdit ? (
    <NewGroupCard
      close={handleClose}
      onGroupAdded={handleClose}
      isEdit={isEdit}
      groupEdit={group}
    />
  ) : (
    <StrictModeDroppable droppableId={id}>
      {(provided, snapshot) => (
        <Style.CardContainer
          ref={provided.innerRef}
          {...provided.droppableProps}
          $isDraggingOver={snapshot.isDraggingOver}
        >
          <div>
            {isPublic && (
              <Style.PublicContainer>
                <Style.CardSubtitle>
                  {`${t('group.members')}: `}

                  <Style.MembersCount>{users.length}</Style.MembersCount>
                </Style.CardSubtitle>

                <PublicPin />
              </Style.PublicContainer>
            )}

            <Style.CardHeader>
              <Style.CardInfo>
                <Style.CardTitle>{name}</Style.CardTitle>

                {!isPublic && (
                  <Style.CardSubtitle>
                    {`${t('group.members')}: `}

                    <Style.MembersCount>{users.length}</Style.MembersCount>
                  </Style.CardSubtitle>
                )}
              </Style.CardInfo>

              <GroupCardContext
                isLoading={isDeleteGroupLoading}
                handleClick={handleClick}
              />

              <button
                type="button"
                onClick={toggleUserList}
              >
                <ArrowDownIcon
                  className={clsx(
                    'text-dark-gray h-6 w-6 transition',
                    {
                      'rotate-180': isExpanded,
                    }
                  )}
                />
              </button>
            </Style.CardHeader>
          </div>

          {isExpanded && !!users.length && (
            <div>
              {users.map(({
                email
              }, index) => (
                <GroupUserInList
                  key={email}
                  groupName={group.name}
                  email={email}
                  index={index}
                />
              ))}
            </div>
          )}

          {/* TODO: uncomment after characteristics clarification */}

          {/* <InputWithTags
            control={control}
            name={characteristicsField}
            objectKey="value"
            placeholder={`${t('organisation.addCharacteristics')}...`}
            placeholderCustomStyles={{
              fontSize: '13px',
              lineHeight: '21px',
            }}
            fieldCustomStyles={{
              borderRadius: '100px',
              fontSize: '13px',
              lineHeight: '21px',
              padding: '0 8px',
            }}
            inputButtonCustomStyle={{
              gap: '8px',
              padding: '5px 8px',
              minHeight: '32px',
            }}
          /> */}
        </Style.CardContainer>
      )}
    </StrictModeDroppable>
  );
};
