import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const ActivityRow = styled.div`
  ${tw`grid grid-cols-2 gap-2.5 text-13-21 items-center`}
`;
export const MemberRowContainer = styled.div`
  ${tw`flex px-4 py-2 gap-4 rounded border border-input-blue`}
`;
export const MemberName = styled.div`
  ${tw`flex-1 text-17-26 font-medium text-gray7 self-center`}
`;
export const MemberInfo = styled.div`
  ${tw`flex gap-3 flex-1`}
`;

export const ModalLabel = styled.label`
  ${tw`block text-15-20 font-medium text-grey-dark-40 mb-2`}
`;
export const CancelText = styled.span`
  ${tw`text-xl font-bold`}
`;
export const ButtonContainer = styled.div`
  ${tw`mt-6 flex justify-end gap-3`}
`;

export const ModalTextarea = styled.textarea`
  ${tw`h-48 w-full px-4 py-2 rounded border border-gray94 bg-alice-blue outline-0`}

  resize: none;
`;
