import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const CardContainer = styled.div<{
  $isDraggingOver?: boolean;
  $isNewCard?: boolean;
}>`
  ${tw`p-4 flex flex-col rounded-md border border-gray94`}

  ${({
    $isDraggingOver
  }) => ($isDraggingOver ? tw`bg-cultured` : tw`bg-white`)}
  
  ${({
    $isNewCard
  }) => ($isNewCard ? tw`gap-4` : tw`gap-2`)}
`;

export const PublicContainer = styled.div`
  ${tw`flex gap-1 items-center justify-between mb-1`}
`;
export const CardHeader = styled.div`
  ${tw`flex gap-2`}
`;

export const CardInfo = styled.div`
  ${tw`grow`}
`;

export const CardTitle = styled.div`
  ${tw`text-17-26 font-bold text-gray7`}
`;

export const CardSubtitle = styled.div`
  ${tw`text-13-21 font-medium text-dim-gray`}
`;

export const MembersCount = styled.span`
  ${tw`font-bold text-gray7`}
`;

export const UserListItem = styled.div<{ $index: number }>`
  ${tw`flex gap-2 rounded p-1 items-center font-medium text-15-20`}

  ${({
    $index
  }) => ($index % 2 ? tw`bg-white` : tw`bg-gray98`)}
`;

export const UserIndex = styled.p`
  ${tw`min-w-6 text-dim-gray`}
`;

export const UserEmail = styled.p`
  ${tw`grow text-gray7`}
`;

export const NewTitle = styled.div`
  ${tw`text-17-26 font-bold text-gray7`}
`;

export const FieldLabel = styled.label`
  ${tw`w-full flex flex-col gap-2 text-15-20 text-grey-dark-40 font-medium`}
`;
export const TopMargin = styled.div`
  ${tw`mt-2`}
`;

export const Asterisk = styled.span`
  ${tw`text-red-text-error`}
`;

export const FieldInput = styled.input<{ $withError: boolean }>`
  ${tw`
    bg-alice-blue px-4 py-3px border border-gray94 outline-0 h-12
    text-17-26 text-dark-gray font-medium
    placeholder:text-dim-gray w-full rounded
  `}

  ${({
    $withError
  }) => $withError && tw`border-red-border-error bg-red-bg-error`}
`;

export const ErrorMessage = styled.div`
  ${tw`text-13-21 font-medium text-red-text-error mt-1`}
`;

export const ButtonsContainer = styled.div`
  ${tw`flex gap-2 justify-between items-center`}
`;
export const ButtonsRow = styled.div`
  ${tw`flex gap-2 items-center`}
`;

export const SpanButtonTitle = styled.span<{ $isWhite?: boolean }>`
  ${tw`text-15-20 font-bold`}

  ${({
    $isWhite
  }) => ($isWhite ? tw`text-white` : tw`text-dark-gray`)}
`;

export const MenuContainer = styled.div`
  ${tw`p-2 flex flex-col gap-1 shadow-menu-shadow text-dark-gray font-medium w-72 bg-white rounded`}
`;
export const PopoverContainer = styled.div`
  ${tw`flex my-auto`}
`;

export const MenuItem = styled.button<{ $isRedText: boolean }>`
  ${tw`p-2 flex gap-2 text-17-26`}

  ${({
    $isRedText
  }) => ($isRedText ? tw`text-red-text-error` : tw`text-dark-gray`)}
`;

export const IsPublicContainer = styled.button`
  ${tw`flex gap-2`}
`;

export const PublicText = styled.div`
  ${tw`text-17-26 font-medium text-gray7`}
`;
