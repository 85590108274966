import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const TableContentWrapper = styled.div`
  ${tw`bg-white overflow-auto grow flex flex-col`}
`;

export const NameCellSpan = styled.span<{ $isUnread?: boolean }>`
  ${tw`font-semibold text-gray7`}

  ${({
    $isUnread
  }) => $isUnread
    && tw`relative before:(w-2.5 h-2.5 rounded-full block bg-button-blue absolute -top-2.5 -left-2.5)`}
`;

export const RightAlignedCellText = styled.p`
  ${tw`text-end`}
`;

export const CenteredCellText = styled.p`
  ${tw`text-center`}
`;

export const TableHeader = styled.div`
  ${tw`flex justify-between px-3 items-center border-b border-b-gray94`}
`;

export const Tabs = styled.div`
  ${tw`flex gap-4`}
`;

export const Tab = styled.button<{ $isActive?: boolean }>`
  ${tw`
    flex gap-1 items-center
    pt-4 pb-3 px-2 relative cursor-pointer
    font-medium text-15-20 text-dark-gray text-center
    hover:text-gray7 transition duration-150
  `}

  ${({
    $isActive
  }) => $isActive
    && tw`
    text-button-blue
    after:(content-[""] w-full h-1 bg-button-blue absolute bottom-0 right-0 rounded-t-20px)
    hover:text-button-blue
  `}
`;

export const TabInfo = styled.div`
  ${tw`
    flex items-center justify-center bg-alice-blue 
    px-1 min-w-28px text-15-20 font-medium rounded-100px
  `}
`;

export const SearchWrapper = styled.div`
  ${tw`w-417px flex justify-end`}
`;

export const Status = styled.div<{ $color: string }>`
  ${tw`flex items-center gap-2 before:(w-2 h-2 rounded-full block) capitalize`}

  ${({
    $color
  }) => `
    color: ${$color};

    &:before {
      background-color: ${$color};
    }
  `}
`;
