import React, {
  FC,
  FormEvent,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  useLocation, useNavigate
} from 'react-router-dom';
import dayjs from 'dayjs';
import {
  useTranslation
} from 'react-i18next';

import {
  SubmitButton
} from 'src/pages/Login/components';
import {
  useRecoveryCodeMutation,
  useResendRecoveryCodeMutation,
} from 'src/shared/api/auth/authApi';
import {
  InputNumberCode, successfulToast
} from 'src/shared/components';
import {
  ClockIcon, RefreshIcon
} from 'src/shared/icons';
import {
  MINUTE, ROUTE, showWarningFromServer
} from 'src/shared/utils';
import {
  useGetApiAuthQuery,
  useForgotPasswordVerifyMutation,
  useForgotPasswordResendMutation,
} from 'src/redux/openapi';

import * as Style from './EnterRecoveryCode.styles';

interface EnterRecoveryCodeProps {
  maxButtonWidth?: number;
}

export const EnterRecoveryCode: FC<EnterRecoveryCodeProps> = ({
  maxButtonWidth,
}) => {
  const {
    t
  } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const [remainingTime, setRemainingTime] = useState<number | null>(60);
  const [isResendCodeClicked, setIsResendCodeClicked] = useState(false);

  const [recoveryCode, {
    isLoading: isRecoveryLoading
  }] = useRecoveryCodeMutation();

  const [resendRecoveryCode] = useResendRecoveryCodeMutation();

  const [resendCurrentUserRecoveryCode] = useForgotPasswordResendMutation();

  const [verifyCurrentUserCode] = useForgotPasswordVerifyMutation();

  const {
    data: userAuthenticatedData
  } = useGetApiAuthQuery(
    undefined,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const isUserAuthenticated = useMemo(
    () => userAuthenticatedData?.data.isAuthenticated,
    [userAuthenticatedData],
  );

  const formId = 'EnterRecoveryCodeForm';

  const initValues = Array(6).fill('');
  const [numbers, setNumbers] = useState<string[]>(initValues);

  const emptyIndex = numbers.findIndex((val) => val === '');

  const onResend = () => {
    const timeOfSendingCode: number = location.state?.time || Date.now();
    const timeToResendCode = timeOfSendingCode + MINUTE;

    intervalRef.current = setInterval(
      () => {
        const remaining = Math.ceil((timeToResendCode - Date.now()) / 1000);

        if (remaining <= 0) {
          setRemainingTime(null);
          setIsResendCodeClicked(false);

          if (intervalRef.current) {
            clearInterval(intervalRef.current);
          }
        } else {
          setRemainingTime(remaining);
        }
      },
      1000
    );
  };

  const handleResendCode = async () => {
    if (!remainingTime) {
      setIsResendCodeClicked(true);

      try {
        const response = isUserAuthenticated
          ? await resendCurrentUserRecoveryCode().unwrap()
          : await resendRecoveryCode().unwrap();

        successfulToast(response.message);

        if (!isUserAuthenticated) {
          navigate(
            `/${ROUTE.RECOVERY_CODE}`,
            {
              state: {
                time: Date.now(),
              },
              replace: true,
            }
          );
        }

        onResend();
        setNumbers(initValues);
      } catch (error) {
        showWarningFromServer(error);
      }
    }
  };

  useEffect(
    () => {
      onResend();

      return () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      };
    },
    []
  );

  const setNewNumberValue = (index: number) => {
    return (val: string) => setNumbers((prev) => prev.map((value, i) => {
      if (index === i) {
        return val;
      }

      return value;
    }),);
  };

  const onSubmit = async () => {
    const code = numbers.join('');

    if (code.length === 6) {
      try {
        if (isUserAuthenticated) {
          const response = await verifyCurrentUserCode({
            verifyForgotPasswordBodySchema: {
              code,
            },
          }).unwrap();

          successfulToast(response.message);
        } else {
          const response = await recoveryCode({
            code,
          }).unwrap();

          successfulToast(response.message);
        }

        navigate(`/${ROUTE.CREATE_PASSWORD}`);
      } catch (error) {
        showWarningFromServer(error);

        setNumbers(initValues);
      }
    }
  };

  const onFormSubmit = (event: FormEvent) => {
    event.preventDefault();
    onSubmit();
  };

  return (
    <Style.MainFormContainer
      id={formId}
      onSubmit={onFormSubmit}
    >
      <Style.FieldsContainer>
        {numbers.slice(
          0,
          3
        ).map((val, i) => {
          const id = useId();

          return (
            <InputNumberCode
              key={id}
              value={val}
              onChange={setNewNumberValue(i)}
              isActive={emptyIndex === i}
            />
          );
        })}

        <Style.Divider />

        {numbers.slice(3).map((val, i) => {
          const id = useId();

          return (
            <InputNumberCode
              key={id}
              value={val}
              onChange={setNewNumberValue(i + 3)}
              isActive={emptyIndex === i + 3}
            />
          );
        })}
      </Style.FieldsContainer>

      <Style.SubmitButtonContainer $width={maxButtonWidth}>
        <SubmitButton
          title={t('buttons.sendCode')}
          onClick={onSubmit}
          isLoading={isRecoveryLoading}
          formId={formId}
        />
      </Style.SubmitButtonContainer>

      <Style.FooterContainer>
        <p>{`${t('recoveryPage.didntReceive')} `}</p>

        <Style.ResendButton
          type="button"
          onClick={handleResendCode}
          $isResendCodeClicked={!!remainingTime}
          disabled={!!remainingTime || isResendCodeClicked}
        >
          <p>
            {remainingTime
              ? `${t('recoveryPage.wait')} ${dayjs(remainingTime * 1000).format(
                'm:ss',
              )}`
              : t('recoveryPage.resendCode')}
          </p>

          {remainingTime ? <ClockIcon /> : <RefreshIcon />}
        </Style.ResendButton>
      </Style.FooterContainer>
    </Style.MainFormContainer>
  );
};
