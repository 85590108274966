import React from 'react';

import './index.css';
import {
  getSearchRegex
} from 'src/shared/utils';

export const getHighlightedText = (text: string, searchQuery: string) => {
  const regex = getSearchRegex(searchQuery);

  return searchQuery ? (
    text.split(regex).map((part, i) => (part.toLowerCase() === searchQuery.toLowerCase() ? (
      <span
        className="comment-match"
        key={i}
      >
        {part}
      </span>
    ) : (
      part
    )),)
  ) : (
    <span>{text}</span>
  );
};
