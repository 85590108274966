import {
  t
} from 'i18next';

import {
  getPercent, themeColors
} from 'src/shared/utils';

type Status = 'registered' | 'active' | 'invited' | 'removed';

export const getStatusMap = (
  membersCount: Record<Status, number>,
  total: number,
) => {
  const {
    registered, active, invited, removed
  } = membersCount;

  const getStatusPercent = (statusCount: number) => {
    if (!total) {
      return 0;
    }

    return getPercent(
      statusCount,
      total
    );
  };

  return [
    {
      label: t('group.registered'),
      color: themeColors.amazonite,
      count: registered,
      percent: getStatusPercent(registered),
    },
    {
      label: t('common.active'),
      color: themeColors['venus-violet'],
      count: active,
      percent: getStatusPercent(active),
    },
    {
      label: t('group.invited'),
      color: themeColors['slate-gray'],
      count: invited,
      percent: getStatusPercent(invited),
    },
    {
      label: t('group.removed'),
      color: themeColors.independence,
      count: removed,
      percent: getStatusPercent(removed),
    },
  ];
};

export const getProgressWidthInPercent = (
  values: { percent: number }[],
  currentIndex: number,
) => {
  const slicedValues = values.slice(
    0,
    currentIndex + 1
  );

  return slicedValues.reduce(
    (prev, currentValue) => prev + currentValue.percent,
    0,
  );
};
