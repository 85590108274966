import React, {
  FC, useEffect, useRef
} from 'react';

interface InputProps {
  value: string;
  onChange: (e: string) => void;
  isActive?: boolean;
}

export const InputNumberCode: FC<InputProps> = ({
  onChange,
  value,
  isActive,
}) => {
  const handleChange = (inputValue: string) => {
    const reg = /^[0-9]$/;
    const isMatch = reg.test(inputValue);

    if (inputValue.length === 1 && isMatch) {
      onChange(inputValue);
    }

    if (inputValue.length === 0) {
      onChange(inputValue);
    }
  };

  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(
    () => {
      if (inputRef && inputRef.current && isActive) {
        inputRef.current.focus();
      }
    },
    [isActive]
  );

  return (
    <input
      ref={inputRef}
      type="text"
      placeholder="*"
      className="rounded border border-gray94 bg-alice-blue w-12 h-12 px-4 py-1 text-center"
      value={value}
      onChange={(e) => handleChange(e.target.value)}
    />
  );
};
