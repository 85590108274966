import React, {
  FC, useMemo
} from 'react';
import {
  useSearchParams
} from 'react-router-dom';
import {
  t
} from 'i18next';

import {
  FAVORITE, questionsFilterBy, topicId
} from 'src/shared/utils';
import {
  FilterBy
} from 'src/entities/Questions';

import * as Style from './TableView.styles';

const TABS_MAP = [FilterBy.Active, FilterBy.Available, FilterBy.Deactivated];

const formatTab = (tab: FilterBy) => {
  return `${t(`common.${tab}`)}`;
};

export const TableHeaderTabs: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const activeTab = useMemo(
    () => searchParams.get(questionsFilterBy) || FilterBy.Active,
    [searchParams],
  ) as FilterBy;

  const onTabChange = (tab: string) => {
    if (activeTab === tab) {
      return;
    }

    searchParams.set(
      questionsFilterBy,
      tab
    );

    setSearchParams(searchParams);
  };

  const isFavoriteQuestions = useMemo(
    () => searchParams.get(topicId) === FAVORITE,
    [searchParams],
  );

  const questionTabs = isFavoriteQuestions
    ? TABS_MAP.filter((tab) => tab !== FilterBy.Available)
    : TABS_MAP;

  return (
    <Style.Tabs>
      {questionTabs.map((tab) => (
        <Style.Tab
          key={tab}
          type="button"
          onClick={() => onTabChange(tab)}
          $isActive={tab === activeTab}
        >
          {formatTab(tab)}
        </Style.Tab>
      ))}
    </Style.Tabs>
  );
};
