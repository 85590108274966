import React, {
  FC, PropsWithChildren
} from 'react';

import * as Style from './CreateEntityLayout.styles';
import {
  CreateEntityLayoutVariant
} from './types';

interface CreateEntityLayoutProps extends PropsWithChildren {
  variant?: CreateEntityLayoutVariant;
}

export const CreateEntityLayout: FC<CreateEntityLayoutProps> = ({
  children,
  variant = 'medium',
}) => {
  return (
    <Style.MainContainer $variant={variant}>{children}</Style.MainContainer>
  );
};

export const CreateEntityHead: FC<PropsWithChildren> = ({
  children
}) => {
  return (
    <Style.SectionContainer>
      <Style.Header>{children}</Style.Header>
    </Style.SectionContainer>
  );
};

export const CreateEntityContent: FC<PropsWithChildren> = ({
  children
}) => {
  return <Style.SectionContainer $grow>{children}</Style.SectionContainer>;
};
