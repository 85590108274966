import {
  CommentsListReducer
} from './commentsListSlice';

const selectCommentsList = (state: CommentsListReducer) => {
  return state.commentsList.comments;
};

const selectCommentsListTotal = (state: CommentsListReducer) => {
  return state.commentsList.total;
};

const selectCommentsListPage = (state: CommentsListReducer) => {
  return state.commentsList.page;
};

const selectCommentsData = (state: CommentsListReducer) => {
  return state.commentsList;
};

export const commentsListSelectors = {
  selectCommentsList,
  selectCommentsListTotal,
  selectCommentsListPage,
  selectCommentsData,
};
