import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MainContainer = styled.div`
  ${tw`flex flex-col`}
`;

export const Text = styled.div`
  ${tw`mb-10 text-17-26 font-normal text-gray7`}
`;

export const ButtonContainer = styled.div`
  ${tw`mt-2 flex justify-end gap-4`}
`;

export const SliderContainer = styled.div`
  ${tw`px-4`}
`;
