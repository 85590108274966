import {
  zodResolver
} from '@hookform/resolvers/zod';
import React, {
  FC
} from 'react';
import {
  Controller, useForm
} from 'react-hook-form';
import {
  useTranslation
} from 'react-i18next';

import {
  LocationSelect
} from 'src/features/LocationSelect';
import {
  Button,
  Input,
  ModalContainer,
  Spinner,
  successfulToast,
} from 'src/shared/components';
import {
  useGetInputValidation
} from 'src/shared/hooks';
import {
  usePatchGroupIdMutation
} from 'src/redux/openapi';
import {
  showWarningFromServer
} from 'src/shared/utils';

import * as Style from './EditGroupModal.styles';

interface EditGroupModalProps {
  isOpen: boolean;
  closeModal: () => void;
  group?: {
    name: string;
    location: string | null;
    id: string;
  };
  refetchGroup?: () => void;
  onGroupEdit?: ({
    id,
    location,
    name,
  }: {
    id: string;
    location: string;
    name: string;
  }) => void;
}

interface EditGroupForm {
  groupName: string;
  groupLocation: {
    value: string;
    label: string;
  };
}

export const EditGroupModal: FC<EditGroupModalProps> = ({
  isOpen,
  closeModal,
  group,
  refetchGroup,
  onGroupEdit,
}) => {
  const {
    t
  } = useTranslation();

  const {
    createGroupSchema
  } = useGetInputValidation();

  const {
    name = '', location = '', id = ''
  } = group || {};

  const {
    control,
    formState: {
      errors
    },
    handleSubmit,
  } = useForm<EditGroupForm>({
    mode: 'onTouched',
    values: {
      groupName: name,
      groupLocation: {
        value: location || '',
        label: location || '',
      },
    },
    resolver: zodResolver(createGroupSchema),
  });

  const [updateGroupInfo, {
    isLoading: isUpdateGroupLoading
  }] = usePatchGroupIdMutation();

  const onSubmit = handleSubmit(async (data) => {
    const {
      groupLocation: {
        value
      },
      groupName,
    } = data;

    try {
      const {
        message
      } = await updateGroupInfo({
        id,
        updateGroupBodySchema: {
          name: groupName,
          location: value,
        },
      }).unwrap();

      if (refetchGroup) {
        refetchGroup();
      }

      if (onGroupEdit) {
        onGroupEdit({
          id,
          name: groupName,
          location: value,
        });
      }

      successfulToast(message);

      closeModal();
    } catch (error) {
      showWarningFromServer(error);
    }
  });

  return (
    <ModalContainer
      isOpen={isOpen}
      modalTitle={t('group.changeName')}
      closeModalHandler={closeModal}
    >
      <Style.FormContainer onSubmit={onSubmit}>
        <Controller
          name="groupName"
          control={control}
          render={({
            field
          }) => (
            <Input
              value={field.value}
              onChange={field.onChange}
              isRequired
              label={t('modal.groupName')}
              placeholder={t(
                'inputMessages.MAXIMUM_CHARACTERS',
                {
                  value: 30,
                }
              )}
              errorMessage={errors.groupName?.message}
              onBlur={field.onBlur}
            />
          )}
        />

        <Style.InputLabel>
          <Controller
            name="groupLocation"
            control={control}
            render={({
              field
            }) => (
              <LocationSelect
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                errorMessage={errors.groupLocation?.value?.message}
                hideIndicators
                isModal
                isRequired
              />
            )}
          />
        </Style.InputLabel>
      </Style.FormContainer>

      <Style.ButtonsContainer>
        <Button
          variant="big-grey-bordered"
          className="font-bold text-xl w-max"
          onClick={closeModal}
        >
          {t('buttons.cancel')}
        </Button>

        <Button
          variant="big-blue"
          onClick={onSubmit}
          className="font-bold text-xl w-max"
        >
          {t('buttons.saveChanges')}

          {isUpdateGroupLoading && (
            <Spinner
              color="white"
              size={24}
              centered={false}
            />
          )}
        </Button>
      </Style.ButtonsContainer>
    </ModalContainer>
  );
};
