import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const ContentContainer = styled.div`
  ${tw`p-6 bg-white m-auto rounded flex flex-col gap-4 w-640px`}
`;

export const Title = styled.h1`
  ${tw`font-bold text-gray7 text-22-30 text-center`}
`;

export const Header = styled.div`
  ${tw`flex flex-col gap-6`}
`;

export const QuestionSetTitle = styled.h3`
  ${tw`text-light-blue text-14-16 mb-1`}
`;

export const QuestionTitle = styled.h2`
  ${tw`text-gray7 text-20-24 font-bold`}
`;

export const FormContainer = styled.form`
  ${tw`flex flex-col gap-4`}
`;

export const Label = styled.label`
  ${tw`font-medium text-17-26 text-dark-gray flex flex-col gap-3`}
`;

export const ErrorMessage = styled.p`
  ${tw`text-xs text-red-text-error`}
`;
