import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const ModalTitle = styled.div<{ $isBold?: boolean }>`
  ${tw`text-base text-dim-gray `}

  ${({
    $isBold
  }) => ($isBold ? tw`font-bold` : tw`font-medium`)}
`;

export const SubTitleModal = styled.div`
  ${tw`text-xl font-bold text-gray7 mb-3`}
`;

export const QuestionDeactivateModalContainer = styled.div`
  ${tw`flex justify-between items-center border-b border-b-light-grey py-3`}
`;

export const QuestionTitleModal = styled.div`
  ${tw`text-17-26 font-bold text-dark-gray`}
`;

export const QuestionDatesModal = styled.div`
  ${tw`text-xs font-medium text-dim-gray`}
`;

export const QuestionTopicsNumberModal = styled.div`
  ${tw`p-1 rounded-full bg-alice-blue text-dark-gray h-7 min-w-28px flex items-center justify-center`}
`;

export const TopicDeactivateModalContainer = styled.div`
  ${tw`flex gap-4 items-center px-2 py-3 border-b border-b-light-grey`}
`;

export const TopicTitleModal = styled.div`
  ${tw`text-base text-dark-gray font-medium`}
`;

export const ModalCancelSpan = styled.span`
  ${tw`text-xl text-dark-gray font-bold`}
`;

export const ModalSaveSpan = styled.span`
  ${tw`text-xl text-white font-bold`}
`;

export const QuestionNumberModal = styled.div`
  ${tw`text-17-26 font-bold text-dim-gray`}
`;

export const ModalGrey7Span = styled.span`
  ${tw`text-gray7`}
`;

export const QuestionIsEditContainer = styled.div<{
  $isExpanded?: boolean;
}>`
  ${tw`flex justify-between gap-3 mb-2`}

  ${({
    $isExpanded
  }) => !$isExpanded && tw`border-b border-b-light-grey pb-3`}
`;

export const QuestionDatesTitleContainer = styled.div`
  ${tw`flex gap-1 flex-col`}
`;

export const CardQuestionDates = styled.div`
  ${tw`text-xs leading-5 font-medium text-dim-gray`}
`;

export const CardQuestionTitle = styled.div<{ $isInvited?: boolean }>`
  ${tw`font-bold`}

  ${({
    $isInvited
  }) => ($isInvited ? tw`text-13-21 text-dark-gray mt-1` : tw`text-17-26 text-gray7`)}
`;

export const QuestionNumber = styled.div`
  ${tw`p-1 text-15-20 font-medium text-dark-gray rounded-100px bg-alice-blue min-w-28px h-7 flex items-center justify-center`}
`;

export const TooltipMessage = styled.p`
  ${tw`text-center max-w-263px text-13-21 font-medium`}
`;

export const DeactivatedStatus = styled.div`
  ${tw`w-max text-15-20 font-medium text-dim-gray px-2 py-1 rounded-100px bg-gray-whisper`}
`;

export const QuestionsAllContainer = styled.div`
  ${tw`pb-3 overflow-x-hidden`}
`;

export const TopicDataContainer = styled.div<{
  $isActive?: boolean;
}>`
  ${tw`font-medium text-base text-dark-gray border-b border-b-light-grey px-2 py-3 grid grid-cols-[minmax(14px,max-content),1fr] gap-2`}

  ${({
    $isActive
  }) => !$isActive && tw`text-grey-dark-40`}
`;

export const QuestionContentContainer = styled.div`
  ${tw`flex gap-4`}
`;

export const FilterSubtitle = styled.div<{
  $isActive?: boolean;
}>`
  ${tw`font-medium text-dim-gray text-xs leading-5 flex gap-0.5`}

  ${({
    $isActive
  }) => $isActive && tw`text-light-grey`}
`;

export const GroupNames = styled.div`
  ${tw`text-xl font-bold text-gray7 mb-2`}
`;

export const SaveEditQuestionsContent = styled.div`
  ${tw`flex flex-col gap-6`}
`;

export const EditListContent = styled.div<{ $isGrow?: boolean }>`
  ${tw`flex flex-col gap-6 h-full overflow-y-auto`}

  ${({
    $isGrow
  }) => $isGrow && tw`flex-1`}
`;

export const TooltipWrapper = styled.div`
  ${tw`text-xs leading-5 font-medium`}
`;

export const EditModalFooter = styled.div`
  ${tw`flex justify-between items-center mt-auto`}
`;

export const RemoveOverrideSpan = styled.span`
  ${tw`font-semibold text-xl text-dim-gray px-4`}
`;

export const EditModalFooterButtons = styled.div`
  ${tw`flex gap-4`}
`;

export const SaveModalHeader = styled.div`
  ${tw`grid grid-cols-sm-scale-sm gap-2 grid-rows-2-min justify-between`}
`;

export const SaveModalFooter = styled.div`
  ${tw`flex gap-4 justify-end items-center mt-6`}
`;

export const SaveSetContainer = styled.div`
  ${tw`flex flex-col gap-1`}
`;

export const SaveTitleWrapper = styled.div`
  ${tw`row-start-2 col-start-2`}
`;

export const SaveCloseButtonWrapper = styled.div`
  ${tw`row-span-2`}
`;

export const UpcomingContainer = styled.div<{ $small?: boolean }>`
  ${tw`px-2 font-medium text-dark-gray rounded-100px bg-alice-blue flex items-center justify-center`}

  ${({
    $small
  }) => ($small ? tw`text-xs leading-5` : tw`text-15-20 py-1`)}
`;

export const SetPartContainer = styled.div<{ $centered?: boolean }>`
  ${tw`flex gap-4`}

  ${({
    $centered
  }) => $centered && tw`items-center`}
`;

export const SetInfoWrapper = styled.div`
  ${tw`my-auto`}
`;

export const QuestionInfoContainer = styled.div`
  ${tw`flex flex-col grow`}
`;

export const QuestionExternalInfo = styled.div`
  ${tw`flex justify-between items-center`}
`;

export const QuestionInfoTitleWrapper = styled.div`
  ${tw`flex items-center justify-between gap-4`}
`;

export const QuestionInfoMenuWrapper = styled.div`
  ${tw`flex items-center justify-center w-10`}
`;
