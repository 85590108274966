import React, {
  FC, useEffect
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import {
  useNavigate
} from 'react-router-dom';

import {
  useTypedDispatch
} from 'src/redux';
import {
  resetUserData
} from 'src/redux/user';
import {
  Button
} from 'src/shared/components';
import {
  ArrowLeftWhiteIcon, EmailIcon
} from 'src/shared/icons';
import {
  ROUTE
} from 'src/shared/utils';
import {
  BlockedAccountMsg, NavigateBackButton
} from 'src/widgets';

import {
  MainContainer, Title, BackButtonContainer
} from './Login.styles';

export const AccountBannedPage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();

  const {
    t
  } = useTranslation();

  useEffect(
    () => {
      dispatch(resetUserData());
    },
    []
  );

  return (
    <>
      <BackButtonContainer>
        <NavigateBackButton />
      </BackButtonContainer>

      <MainContainer>
        <Title>{t('bannedPage.pageBanned')}</Title>

        <BlockedAccountMsg />

        <div className="w-full">
          <a href="mailto:arq@appeal.com">
            <Button variant="big-blue">
              <span className="text-17-26 md:text-xl font-bold">
                {t('bannedPage.writeRequest')}
              </span>

              <EmailIcon className="text-white" />
            </Button>
          </a>

          <div className="mt-3 text-eerie-black text-17-26 font-medium">
            {`${t('bannedPage.writeDirectly')} arq@appeal.com`}
          </div>
        </div>

        <div className="w-full">
          <Button
            onClick={() => navigate(`/${ROUTE.LOGIN}`)}
            variant="big-black"
          >
            <ArrowLeftWhiteIcon />

            <span className="text-17-26 md:text-xl font-bold">
              {t('buttons.back')}
            </span>
          </Button>
        </div>
      </MainContainer>
    </>
  );
};
