import {
  useMediaQuery as useKokojerMediaQuery
} from '@kokojer/react-responsive';

const BREAKPOINTS = {
  sm: '320px',
  mobileL: '425px',
  md: '979px',
  lg: '1024px',
  xl: '1280px',
};

export const useMediaQuery = (
  breakpoint: keyof typeof BREAKPOINTS,
  relation: 'up' | 'down' = 'up',
) => {
  return useKokojerMediaQuery({
    query: `(${relation === 'up' ? 'min-width:' : 'max-width:'} ${
      BREAKPOINTS[breakpoint]
    })`,
  });
};
