import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MainContainer = styled.div`
  ${tw`flex gap-2 py-4 px-2 not-last:border-b border-b-gray94`}
`;

export const ContentWrapper = styled.div`
  ${tw`flex flex-col grow`}
`;

export const Index = styled.p`
  ${tw`font-semibold text-dark-gray min-w-6`}
`;

export const HeadWrapper = styled.div`
  ${tw`flex flex-col gap-1`}
`;

export const Title = styled.p`
  ${tw`font-medium text-dark-gray mb-1`}
`;

export const SubtitleWrapper = styled.div`
  ${tw`flex justify-between`}
`;

export const Subtitle = styled.p`
  ${tw`font-medium text-dim-gray text-xs leading-5`}
`;

export const UpcomingTile = styled.div`
  ${tw`bg-alice-blue rounded-100px px-2 font-medium text-xs leading-5 text-dark-gray`}
`;
