import clsx from 'clsx';
import React, {
  FC
} from 'react';

export interface ButtonProps
  extends Pick<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  'type' | 'onClick' | 'disabled' | 'form'
  > {
  variant?:
  | 'input-control-rounded'
  | 'input-control-rounded-small'
  | 'big-black'
  | 'big-red'
  | 'big-blue'
  | 'big-blue-outlined'
  | 'big-grey-bordered'
  | 'invite-grey'
  | 'invite-blue'
  | 'none';
  children?: React.ReactNode;
  className?: string;
}

export const Button: FC<ButtonProps> = ({
  children,
  type = 'button',
  variant = 'none',
  disabled,
  className,
  ...props
}) => {
  return (
    <button
      type={type}
      className={clsx(
        {
          'w-full bg-button-blue text-white flex flex-row gap-1 justify-center items-center rounded-100px h-11 md:h-52px px-4 md:px-6 py-2.5 md:py-3 disabled:bg-light-blue hover:bg-accent80':
            variant === 'big-blue',
          'w-full py-2.5 md:py-3 px-4 bg-white text-button-blue text-15-20 font-bold rounded-100px flex flex-row gap-1 items-center border border-button-blue shadow-menu-shadow':
            variant === 'big-blue-outlined',
          'w-full bg-gray7 text-white flex flex-row gap-1 justify-center items-center rounded-100px h-11 md:h-52px px-4 md:px-6 py-2.5 md:py-3 disabled:bg-dark-gray hover:bg-dark-gray':
            variant === 'big-black',
          'w-full bg-white text-dark-gray border border-gray90 flex flex-row gap-1 justify-center items-center rounded-100px h-11 md:h-52px px-4 py-2.5':
            variant === 'big-grey-bordered',
          'w-10 h-10 bg-input-blue rounded-full flex justify-center items-center':
            variant === 'input-control-rounded',
          'w-6 h-6 bg-input-blue rounded-full flex justify-center items-center':
            variant === 'input-control-rounded-small',
          'border border-gray90 px-4 py-2.5 bg-white rounded-lg flex justify-center items-center':
            variant === 'invite-grey',
          'border border-button-blue px-4 py-2.5 bg-white rounded-lg flex justify-center items-center':
            variant === 'invite-blue',
          'w-full bg-red-text-error hover:bg-red-text-error hover:bg-opacity-80 text-white flex flex-row gap-1 justify-center items-center rounded-100px h-11 md:h-52px px-6 py-3 disabled:bg-opacity-80':
            variant === 'big-red',
        },
        className,
      )}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );
};
