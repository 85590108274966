import React, {
  FC, useEffect, useRef, useState
} from 'react';
import parse from 'html-react-parser';
import {
  useLocation
} from 'react-router-dom';

import {
  PageLayout
} from 'src/widgets';
import {
  useNavigatorOnLine
} from 'src/shared/hooks/useNavigatorOnLine';
import {
  LoaderScreen
} from 'src/shared/components';
import {
  useScrollToAnchor
} from 'src/shared/hooks';

import * as Style from './ContentLayout.styles';

interface ContentLayoutProps {
  links: {
    id: string | null;
    label?: string | null;
    content: string;
  }[];
}

export const ContentLayout: FC<ContentLayoutProps> = ({
  links
}) => {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [maxHeight, setMaxHeight] = useState(0);

  const {
    sectionRefs, onLinkClick
  } = useScrollToAnchor({
    items: links,
    hashKey: 'id',
    containerRef: contentRef,
  });

  const {
    hash
  } = useLocation();

  const isOnLine = useNavigatorOnLine();

  useEffect(
    () => {
      if (contentRef.current) {
        const windowHeight = window.innerHeight;
        const maxHeightValue = windowHeight - contentRef.current.offsetTop - 24;
        setMaxHeight(maxHeightValue);
      }
    },
    [contentRef]
  );

  return (
    <PageLayout>
      {isOnLine ? (
        <Style.MainContainer>
          <Style.BlockContainer
            $isNavBlock
            style={{
              maxHeight: `${maxHeight}px`,
            }}
          >
            <Style.NavContainer>
              {links.map(({
                id, label
              }) => {
                if (!label) {
                  return null;
                }

                return (
                  <Style.ListItem key={id}>
                    <Style.NavLink
                      href={`#${id}`}
                      $isActive={hash === `#${id}`}
                      onClick={onLinkClick}
                    >
                      {label}
                    </Style.NavLink>
                  </Style.ListItem>
                );
              })}
            </Style.NavContainer>
          </Style.BlockContainer>

          <Style.BlockContainer
            ref={contentRef}
            style={{
              maxHeight: `${maxHeight}px`,
            }}
          >
            <Style.Content>
              {links.map(({
                id, content, label
              }, index) => {
                if (!content) {
                  return null;
                }

                return (
                  <div
                    key={id}
                    id={id ? `#${id}` : `#${index}`}
                    ref={(el) => {
                      sectionRefs.current[index] = el;
                    }}
                  >
                    {label && (
                      <Style.ContentTitle $isActive={hash === `#${id}`}>
                        {`${index}. ${label}`}
                      </Style.ContentTitle>
                    )}

                    {parse(content)}
                  </div>
                );
              })}
            </Style.Content>
          </Style.BlockContainer>
        </Style.MainContainer>
      ) : (
        <LoaderScreen />
      )}
    </PageLayout>
  );
};
