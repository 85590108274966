import React, {
  FormEvent, FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import {
  useForm, Controller
} from 'react-hook-form';
import {
  zodResolver
} from '@hookform/resolvers/zod';
import z from 'zod';

import {
  Input, Spinner, successfulToast
} from 'src/shared/components';
import {
  useGetInputValidation
} from 'src/shared/hooks';
import {
  useGetOrgWhitelistEmailsQuery,
  usePostOrgWhitelistEmailsMutation,
} from 'src/redux/openapi';
import {
  showWarningFromServer, themeColors
} from 'src/shared/utils';

import * as Style from './Whitelist.styles';
import {
  DomainsListItem
} from './ui';

interface WhitelistProps {
  organizationId: string;
}

interface WhitelistForm {
  domain: string;
}

export const Whitelist: FC<WhitelistProps> = ({
  organizationId
}) => {
  const {
    data, refetch, isLoading
  } = useGetOrgWhitelistEmailsQuery({
    id: organizationId,
  });

  const {
    domains
  } = data?.data || {};

  const [addDomain] = usePostOrgWhitelistEmailsMutation();

  const {
    t
  } = useTranslation();

  const {
    domainValidation
  } = useGetInputValidation();

  const validationSchema = z.object({
    domain: domainValidation,
  });

  const {
    handleSubmit,
    control,
    formState: {
      errors
    },
    reset,
  } = useForm<WhitelistForm>({
    mode: 'onSubmit',
    defaultValues: {
      domain: '',
    },
    resolver: zodResolver(validationSchema),
  });

  const onSubmit = handleSubmit(async ({
    domain
  }) => {
    try {
      const response = await addDomain({
        id: organizationId,
        createOrganizationEmailDomainBodySchema: {
          domain,
        },
      }).unwrap();

      successfulToast(response.message);

      reset();
      refetch();
    } catch (error) {
      showWarningFromServer(error);
    }
  });

  const onFormSubmit = (event: FormEvent) => {
    event.preventDefault();
    onSubmit();
  };

  return (
    <Style.MainContainer>
      <Style.Title>{t('whitelist.whitelist')}</Style.Title>

      <Style.ContentContainer>
        <form onSubmit={onFormSubmit}>
          <Controller
            name="domain"
            control={control}
            render={({
              field
            }) => {
              return (
                <Input
                  label={t('common.addEmailDomains')}
                  value={field.value}
                  onChange={(value) => field.onChange(value.trim())}
                  errorMessage={errors.domain?.message}
                  onBlur={field.onBlur}
                  placeholder="email.com"
                />
              );
            }}
          />
        </form>

        {isLoading && (
          <Spinner
            size={32}
            color={themeColors.gray7}
          />
        )}

        {domains && (
          <Style.ListContainer>
            {domains.map((domainItem, index) => (
              <DomainsListItem
                key={domainItem.id}
                domainItem={domainItem}
                onRefetch={refetch}
                index={index}
                organizationId={organizationId}
              />
            ))}
          </Style.ListContainer>
        )}
      </Style.ContentContainer>
    </Style.MainContainer>
  );
};
