import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  CheckBoxFalseIcon, CheckBoxTrueIcon
} from 'src/shared/icons';

import * as Style from './PrivateCommentCheckMark.styles';

interface PrivateCommentCheckMarkProps {
  isPrivateComment: boolean;
  changeIsPrivateComment: () => void;
  className?: string;
}

export const PrivateCommentCheckMark: FC<PrivateCommentCheckMarkProps> = ({
  isPrivateComment,
  changeIsPrivateComment,
  className,
}) => {
  const {
    t
  } = useTranslation();

  return (
    <Style.Label $classNames={className}>
      {isPrivateComment ? <CheckBoxTrueIcon /> : <CheckBoxFalseIcon />}

      <input
        type="checkbox"
        checked={isPrivateComment}
        onChange={changeIsPrivateComment}
        className="hidden"
      />

      <Style.Text>
        {` ${t('comment.privateComment')} `}

        <Style.Span>{t('comment.visibleOnlyForYou')}</Style.Span>
      </Style.Text>
    </Style.Label>
  );
};
