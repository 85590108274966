import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const CalendarHeader = styled.div`
  ${tw`flex justify-between items-center gap-6 border-0 bg-white`}
`;

export const CalendarHeaderInfo = styled.div`
  ${tw`flex flex-col justify-center gap-1`}
`;

export const HeaderMonth = styled.p`
  ${tw`font-bold leading-6 text-black`}
`;

export const HeaderYear = styled.p`
  ${tw`text-xs text-grey-dark-40`}
`;
