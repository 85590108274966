import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const Container = styled.div<{ $isActive: boolean }>`
  ${tw`p-4 flex flex-col gap-3 border-b border-b-gray94`}

  ${({
    $isActive
  }) => $isActive && tw`bg-alice-blue`}
`;
export const UpperRow = styled.div`
  ${tw`flex gap-3 items-center`}
`;

export const BottomRow = styled.div`
  ${tw`flex justify-end`}
`;

export const StatisticRow = styled.div`
  ${tw`flex justify-between items-center gap-3`}
`;

export const Statistic = styled.div`
  ${tw`flex gap-2.5`}
`;

export const Activity = styled.div`
  ${tw`text-17-26 font-bold text-dark-gray`}
`;

export const NameContainer = styled.div`
  ${tw`flex-1 py-2`}
`;

export const Name = styled.div`
  ${tw`text-gray7 text-base font-semibold`}
`;

export const ButtonContainer = styled.div`
  ${tw`w-max`}
`;

export const MessageContainer = styled.div`
  ${tw`flex gap-2 items-center text-17-26 font-medium`}
`;

export const Span = styled.span`
  ${tw`font-bold`}
`;

export const Button = styled.button`
  ${tw`py-2 text-15-20 font-bold text-button-blue flex items-center gap-1`}
`;
