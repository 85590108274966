export enum CommentFormData {
  COMMENT = 'comment',
  IS_PRIVATE = 'isPrivate',
}

export interface CommentForm {
  [CommentFormData.COMMENT]: string;
  [CommentFormData.IS_PRIVATE]: boolean;
}

export interface SurveyDateInfo {
  title: string;
  startDate: string;
  endDate: string;
}
