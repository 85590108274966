import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MainContainer = styled.div`
  ${tw`flex flex-col gap-6`}
`;

export const Title = styled.p`
  ${tw`text-gray7 text-28-36 font-bold`}
`;

export const TextContent = styled.p`
  ${tw`text-dark-gray text-17-26 font-medium text-center`}
`;

export const ButtonsContainer = styled.div`
  ${tw`flex gap-4`}
`;
