import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const ManageDelegateContent = styled.div`
  ${tw`p-6 border border-gray90 rounded-md flex flex-col justify-center items-center gap-4 mt-6`}
`;

export const ManageDelegateText = styled.div`
  ${tw`text-center text-15-20 font-bold text-gray7`}
`;

export const ManageDelegateSpan = styled.span`
  ${tw`font-medium`}
`;

export const ManageDelegateButtonsRow = styled.div`
  ${tw`flex gap-2.5 justify-end mt-10`}
`;
