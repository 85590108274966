import clsx from 'clsx';
import React, {
  FC, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import {
  AutocompletePrediction
} from 'react-places-autocomplete';
import Select, {
  SingleValue, StylesConfig
} from 'react-select';

import {
  ArrowDownIcon
} from 'src/shared/icons';

import * as Style from './LocationSelect.styles';

type Option = {
  value: string;
  label: string;
};

interface LocationSelectProps {
  value: Option;
  onChange: (newValue: SingleValue<string | Option>) => void;
  onBlur: () => void;
  errorMessage?: string;
  hideIndicators?: boolean;
  styles?: StylesConfig<string | Option>;
  isModal?: boolean;
  isRequired?: boolean;
  label?: string;
}

const DropdownIndicator: FC = () => (
  <div className="flex items-center justify-center text-button-blue bg-white rounded-sm p-2">
    <ArrowDownIcon className="h-6 w-6" />
  </div>
);

const defaultSelectValue = {
  value: '',
  label: '',
};

export const LocationSelect: FC<LocationSelectProps> = ({
  value,
  onChange,
  onBlur,
  errorMessage,
  hideIndicators,
  styles = {},
  isModal,
  isRequired,
  label,
}) => {
  const [suggestions, setSuggestions] = useState<Option[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const sessionToken = new google.maps.places.AutocompleteSessionToken();

  const autocompleteService = new google.maps.places.AutocompleteService();

  const formatSuggestions = (data: AutocompletePrediction[] | null) => {
    if (!data) {
      return setSuggestions([]);
    }

    const formattedData = data.map((prediction) => ({
      value: prediction.description,
      label: prediction.description,
    }));

    return setSuggestions(formattedData);
  };

  const getSuggestion = async (typedValue: string) => {
    setInputValue(typedValue);

    if (!typedValue) {
      setSuggestions([]);
    }

    try {
      setIsLoading(true);

      await autocompleteService.getPlacePredictions(
        {
          input: typedValue || '',
          sessionToken,
          types: ['(cities)'],
        },
        formatSuggestions,
      );
    } catch (error) {
      // TODO: add error handler
    } finally {
      setIsLoading(false);
    }
  };

  const onOpen = () => {
    onChange(defaultSelectValue);
  };

  const {
    t
  } = useTranslation();

  return (
    <Style.Label>
      <Style.LabelText>
        {label || t('group.location')}

        {isRequired && <Style.Asterisk>*</Style.Asterisk>}
      </Style.LabelText>

      <Select
        defaultInputValue={value.value ? value.value : ''}
        onChange={onChange}
        isMulti={false}
        onInputChange={getSuggestion}
        onMenuOpen={onOpen}
        onBlur={onBlur}
        menuPortalTarget={isModal ? document.body : null}
        inputValue={inputValue}
        options={suggestions}
        isLoading={isLoading}
        value={value.value ? value : ''}
        placeholder={t('group.chooseYourLocation')}
        components={{
          DropdownIndicator,
        }}
        styles={{
          menuPortal: (base) => (isModal
            ? {
              ...base,
              zIndex: 9999,
            }
            : base),
          ...styles,
        }}
        classNames={{
          control: () => clsx(
            `
              pl-4 pr-3px py-3px border outline-0 h-12
              text-17-26 text-dark-gray font-medium
              placeholder:text-dim-gray !shadow-none
            `,
            {
              '!border-red-border-error !bg-red-bg-error':
                  errorMessage && !inputValue,
              '!border-gray94 !bg-alice-blue': !errorMessage || inputValue,
            },
          ),
          indicatorSeparator: () => `
          hidden
        `,
          option: () => `
          border-b border-gray94 !text-gray7 text-17-26 font-medium mb-4 last:mb-0 pb-2
        `,
          menu: () => `
          p-4 shadow-menu-shadow
        `,
          indicatorsContainer: () => clsx({
            '!hidden': hideIndicators,
          }),
        }}
      />

      {errorMessage && <Style.ErrorMessage>{errorMessage}</Style.ErrorMessage>}
    </Style.Label>
  );
};
