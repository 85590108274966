import clsx from 'clsx';
import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  sortMethods
} from '../filterData';
import {
  SortBy
} from '../types';
import * as Style from '../CommentsFilterContext.styles';

interface SortBySectionProps {
  sortBy: SortBy | null;
  onSortClick: (sortBy: SortBy) => void;
}

export const SortBySection: FC<SortBySectionProps> = ({
  sortBy,
  onSortClick,
}) => {
  const {
    t
  } = useTranslation();

  return (
    <>
      <Style.FilterTitle>{t('filters.sortBy')}</Style.FilterTitle>

      {sortMethods.map(({
        title, icon: Icon
      }) => (
        <Style.Label
          key={title}
          $isActive={sortBy === title}
          htmlFor={title}
        >
          <Icon
            className={clsx(
              'w-6 h-6',
              {
                'text-dim-gray': sortBy !== title,
                'text-button-blue': sortBy === title,
              }
            )}
          />

          <input
            type="radio"
            name="isNewestSort"
            checked={sortBy === title}
            onChange={() => onSortClick(title)}
            className="hidden"
            id={title}
          />

          <Style.LabelTitle $isActive={sortBy === title}>
            {t(`filters.${title}`)}
          </Style.LabelTitle>
        </Style.Label>
      ))}
    </>
  );
};
