import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const ListContainer = styled.ul`
  ${tw`flex flex-col w-full`}
`;

export const ListItem = styled.li<{ $index: number }>`
  ${tw`flex justify-between items-center gap-2 p-2 font-medium text-15-20 text-dim-gray`}

  ${({
    $index
  }) => ($index % 2 ? tw`bg-gray98` : tw`bg-white`)}
`;

export const IndexContainer = styled.div`
  ${tw`min-w-6`}
`;

export const ListItemText = styled.div`
  ${tw`flex-1 text-gray7`}
`;

export const CloseButton = styled.button`
  ${tw`flex items-center justify-center`}
`;
