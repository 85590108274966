import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const CardContainer = styled.article`
  ${tw`
    rounded-md bg-white p-4
    cursor-pointer transition duration-300 h-full
  `}

  &:hover {
    box-shadow: 0px 12px 24px 0px rgba(128, 128, 128, 0.1);
  }
`;
