import React, {
  FC
} from 'react';
import {
  useForm
} from 'react-hook-form';
import {
  zodResolver
} from '@hookform/resolvers/zod';
import {
  useTranslation
} from 'react-i18next';

import {
  Button,
  ModalContainer,
  Spinner,
  successfulToast,
} from 'src/shared/components';
import {
  useForgotPasswordRequestMutation,
  usePostPasswordMutation,
} from 'src/redux/openapi';
import {
  showWarningFromServer
} from 'src/shared/utils';
import {
  useGetInputValidation
} from 'src/shared/hooks';
import {
  FormField
} from 'src/pages/Login/types';

import * as Style from './EditProfileMenu.styles';

interface EditPasswordModalProps {
  isOpen: boolean;
  onClose: () => void;
  onPasswordForgot: () => void;
}

export const EditPasswordModal: FC<EditPasswordModalProps> = ({
  isOpen,
  onClose,
  onPasswordForgot,
}) => {
  const {
    editPasswordSchema
  } = useGetInputValidation();

  const {
    t
  } = useTranslation();

  const {
    handleSubmit,
    register,
    formState: {
      errors
    },
    reset,
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      [FormField.CurrentPassword]: '',
      [FormField.NewPassword]: '',
      [FormField.RepeatPassword]: '',
    },
    resolver: zodResolver(editPasswordSchema),
  });

  const handleClose = () => {
    reset();
    onClose();
  };

  const [changePassword, {
    isLoading: isSubmitting
  }] = usePostPasswordMutation();

  const onSubmit = handleSubmit(async ({
    currentPassword, newPassword
  }) => {
    try {
      const response = await changePassword({
        changePasswordBodySchema: {
          oldPassword: currentPassword,
          newPassword,
        },
      }).unwrap();

      successfulToast(response.message);
      handleClose();
    } catch (error) {
      showWarningFromServer(error);
    }
  });

  const [resetPassword, {
    isLoading: isResetLoading
  }] = useForgotPasswordRequestMutation();

  const handleForgotPassword = async () => {
    try {
      const response = await resetPassword().unwrap();

      successfulToast(response.message);

      onPasswordForgot();
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  return (
    <ModalContainer
      isOpen={isOpen}
      closeModalHandler={handleClose}
      modalTitle={t('modal.editPassword')}
      testId="editPasswordModal"
    >
      <Style.ModalForm onSubmit={onSubmit}>
        <Style.InputLabel>
          {t('modal.currentPassword')}

          <Style.Input
            type="password"
            placeholder={t('modal.currentPassword')}
            {...register(FormField.CurrentPassword)}
          />

          {errors[FormField.CurrentPassword]?.message && (
            <Style.ErrorMessage>
              {errors[FormField.CurrentPassword]?.message}
            </Style.ErrorMessage>
          )}
        </Style.InputLabel>

        <Style.InputLabel>
          {t('modal.newPassword')}

          <Style.Input
            type="password"
            placeholder={t('modal.newPassword')}
            {...register(FormField.NewPassword)}
          />

          {errors[FormField.NewPassword]?.message && (
            <Style.ErrorMessage>
              {errors[FormField.NewPassword]?.message}
            </Style.ErrorMessage>
          )}
        </Style.InputLabel>

        <Style.InputLabel>
          {t('modal.repeatNewPassword')}

          <Style.Input
            type="password"
            placeholder={t('modal.repeatNewPassword')}
            {...register(FormField.RepeatPassword)}
          />

          {errors[FormField.RepeatPassword]?.message && (
            <Style.ErrorMessage>
              {errors[FormField.RepeatPassword]?.message}
            </Style.ErrorMessage>
          )}
        </Style.InputLabel>

        <Style.ModalButtons>
          <Button
            variant="big-grey-bordered"
            className="!w-157px font-bold text-15 text-gray7 !px-4 !py-2.5 !h-10"
            onClick={handleForgotPassword}
            disabled={isResetLoading}
          >
            {isResetLoading ? <Spinner size={24} /> : t('login.forgotPassword')}
          </Button>

          <Style.ModalRightButtons>
            <Button
              variant="big-black"
              className="w-max font-bold text-15 !px-4 !py-2.5 !h-10"
              onClick={handleClose}
            >
              {t('buttons.cancel')}
            </Button>

            <Button
              variant="big-blue"
              className="w-153px font-bold text-15 !px-4 !py-2.5 !h-10"
              onClick={onSubmit}
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <Spinner
                  size={24}
                  color="white"
                />
              ) : (
                t('modal.updatePassword')
              )}
            </Button>
          </Style.ModalRightButtons>
        </Style.ModalButtons>
      </Style.ModalForm>
    </ModalContainer>
  );
};
