import React, {
  FC
} from 'react';

import {
  CheckBoxFalse,
  CheckBoxTrue,
  SelectedFalse,
  SelectedTrue,
} from 'src/shared/icons';

interface CheckMarkProps {
  isChecked: boolean;
  isRounded?: boolean;
}

export const CheckMark: FC<CheckMarkProps> = ({
  isChecked, isRounded
}) => {
  const selectCheckMarkType = () => {
    if (!isRounded) {
      return isChecked ? CheckBoxTrue : CheckBoxFalse;
    }

    return isChecked ? SelectedTrue : SelectedFalse;
  };

  return (
    <img
      src={selectCheckMarkType()}
      alt="check"
    />
  );
};
