import React from 'react';

import * as Style from './BasicColoredList.styles';
import {
  BasicColoredListItem
} from './BasicColoredListItem';

interface BasicColoredListProps<T extends Record<string, string>> {
  list: T[];
  valueKey: keyof T;
  uniqueKey?: keyof T;
  onDelete?: (listItem: T) => Promise<void> | void;
}

export const BasicColoredList = <T extends Record<string, string>>({
  list,
  onDelete,
  valueKey,
  uniqueKey,
}: BasicColoredListProps<T>) => {
  return (
    <Style.ListContainer>
      {list.map((item, index) => (
        <BasicColoredListItem
          key={uniqueKey ? item[uniqueKey] : item[valueKey]}
          index={index}
          valueKey={valueKey}
          onDelete={onDelete}
          item={item}
        />
      ))}
    </Style.ListContainer>
  );
};
