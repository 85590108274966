export enum SortBy {
  MOST_DISCUSSION = 'mostDiscussion',
  MOST_LIKED = 'mostLiked',
  CHRONOLOGICAL = 'chronological',
}

export enum FilterDateType {
  IN_48_HOURS = 'inTwoDays',
  IN_24_HOURS = 'in24hours',
  WEEK = 'sevenDays',
  ALL_TIME = 'allTime',
  CUSTOM = 'custom',
}
