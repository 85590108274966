import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  Button,
  ModalContainer,
  Spinner,
  successfulToast,
} from 'src/shared/components';
import {
  usePatchUserCurrentCharacteristicsMutation
} from 'src/redux/openapi';
import {
  showWarningFromServer
} from 'src/shared/utils';

import {
  SelectedValue
} from '../../types';

import * as Style from './SaveCharacteristicsModal.styles';

interface SaveCharacteristicsModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedValues: SelectedValue[];
  onSuccess: () => void;
}

export const SaveCharacteristicsModal: FC<SaveCharacteristicsModalProps> = ({
  isOpen,
  onClose,
  selectedValues,
  onSuccess,
}) => {
  const {
    t
  } = useTranslation();

  const [saveCharacteristics, {
    isLoading
  }] = usePatchUserCurrentCharacteristicsMutation();

  const onSave = async () => {
    const valuesToSave = selectedValues.map((value) => ({
      keyId: value.id,
      valueId: value.selectedOption.value,
    }));

    try {
      const response = await saveCharacteristics({
        updateCurrentUserCharacteristicsBodySchema: {
          characteristics: valuesToSave,
        },
      }).unwrap();

      successfulToast(response.message);

      onSuccess();
      onClose();
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  return (
    <ModalContainer
      isOpen={isOpen}
      isHeader={false}
      closeModalHandler={onClose}
      modalWidth={556}
    >
      <Style.MainContainer>
        <Style.Title>
          {t('editAccount.wantToChangeCharacteristics')}
        </Style.Title>

        <Style.TextContent>
          {t('editAccount.someCharacteristicsMayImpact')}
        </Style.TextContent>

        <Style.ButtonsContainer>
          <Button
            variant="big-black"
            className="text-xl font-bold"
            onClick={onClose}
          >
            {t('buttons.cancel')}
          </Button>

          <Button
            variant="big-blue"
            className="text-xl font-bold"
            onClick={onSave}
          >
            {t('buttons.change')}

            {isLoading && (
              <Spinner
                size={24}
                color="inherit"
                centered={false}
              />
            )}
          </Button>
        </Style.ButtonsContainer>
      </Style.MainContainer>
    </ModalContainer>
  );
};
