import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const ModalColorsContainer = styled.div<{
  $isActive?: boolean;
  $withSpace?: boolean;
}>`
  ${tw`flex gap-1`}

  ${({
    $withSpace
  }) => $withSpace && tw`mx-6 p-1 border rounded-lg mb-3`}

  ${({
    $isActive
  }) => ($isActive
    ? tw`border-button-blue shadow-active-theme`
    : tw`border-gray94 shadow-card-invite`)}
`;

export const ModalColorCard = styled.div<{ $bgColor: string }>`
  ${tw`h-36 flex-1 rounded shadow-card-invite p-2 flex flex-col justify-between`}

  ${({
    $bgColor
  }) => `
    background-color: ${$bgColor};
  `}
`;

export const ModalColorCardTitle = styled.div<{ $isBig?: boolean }>`
  ${tw`p-1 rounded bg-white06 font-medium text-gray7 min-w-32px text-center`}

  ${({
    $isBig
  }) => ($isBig ? tw`text-base` : tw`text-xs leading-5`)}
`;
