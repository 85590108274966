import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  getPercent, numberWithPeriods, themeColors
} from 'src/shared/utils';

import * as Style from './CommunityTile.style';

enum Columns {
  Forecast = 'forecasts',
  Comments = 'comments',
  Commendations = 'commendations',
}

const columnsConfig = [
  {
    id: Columns.Forecast,
    mainColor: themeColors.amazonite,
    secondaryColor: '#E5FFFE',
  },
  {
    id: Columns.Comments,
    mainColor: themeColors['venus-violet'],
    secondaryColor: '#EFEDFF',
  },
  {
    id: Columns.Commendations,
    mainColor: themeColors['brownish-yellow'],
    secondaryColor: '#FFF6E5',
  },
];

const PARENT_COLUMN_HEIGHT = 145;

interface PropValue {
  total: number;
  user: number;
}

interface CommunityTileProps {
  community?: {
    [Columns.Forecast]: PropValue;
    [Columns.Comments]: PropValue;
    [Columns.Commendations]: PropValue;
  };
}

export const CommunityTile: FC<CommunityTileProps> = ({
  community
}) => {
  const {
    t
  } = useTranslation();

  return (
    <Style.MainContainer>
      <Style.Title>{t('editAccount.community')}</Style.Title>

      <Style.Description>
        {columnsConfig.map(({
          id, mainColor
        }) => (
          <Style.DescriptionItem key={id}>
            <Style.Circle $color={mainColor} />

            {t(`editAccount.${id}`)}
          </Style.DescriptionItem>
        ))}
      </Style.Description>

      <Style.Chart>
        {columnsConfig.map(({
          id, mainColor, secondaryColor
        }) => {
          const {
            user = 0, total = 0
          } = community ? community[id] : {};

          const userValuePercentage = getPercent(
            user,
            total
          );

          const childColumnSize = (PARENT_COLUMN_HEIGHT * userValuePercentage) / 100 || 1;

          return (
            <Style.ChartItem key={id}>
              <Style.ParentColumn $color={secondaryColor}>
                <Style.TotalValue>{numberWithPeriods(total)}</Style.TotalValue>

                <Style.ChildColumn
                  $color={mainColor}
                  $height={childColumnSize}
                />
              </Style.ParentColumn>

              <Style.ItemDescription>
                {`${userValuePercentage}% (${user})`}
              </Style.ItemDescription>
            </Style.ChartItem>
          );
        })}
      </Style.Chart>
    </Style.MainContainer>
  );
};
