import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import dayjs from 'dayjs';

import {
  UserCalibrationsResponseSchema
} from 'src/redux/openapi';
import {
  getConfidenceStatus, getUserTimeZone
} from 'src/shared/utils';

import * as Style from './ResultItem.style';

interface ResultItemProps {
  result: UserCalibrationsResponseSchema['data']['calibrations'][number];
  index: number;
}

export const ResultItem: FC<ResultItemProps> = ({
  index,
  result: {
    id, finishedAt, correctAnswers, confidence, totalQuestions
  },
}) => {
  const {
    t
  } = useTranslation();

  const userTimeZone = getUserTimeZone();

  const status = getConfidenceStatus({
    correct: correctAnswers || 0,
    confidence,
    total: totalQuestions,
  });

  const actualResult = Math.floor(
    ((correctAnswers || 0) / totalQuestions) * 100,
  );

  const createdAtUserZone = dayjs.utc(finishedAt).tz(userTimeZone);

  const datesInfo = createdAtUserZone.format('MMM DD, YYYY');
  const timeInfo = createdAtUserZone.format('HH:mm:ss');

  return (
    <Style.ItemContainer key={id}>
      <Style.ItemIndex>
        <Style.Item17Text $isBold>{`${index + 1}.`}</Style.Item17Text>
      </Style.ItemIndex>

      <Style.ItemDates>
        <Style.Item17Text>{datesInfo}</Style.Item17Text>

        <Style.Item13Text>{timeInfo}</Style.Item13Text>
      </Style.ItemDates>

      <div>
        <Style.Item17Text
          $isBold
          $isEnd
        >
          {`${t(`calibration.specified`)} ${confidence}% - ${t(
            'calibration.actual',
          )} ${actualResult}%`}
        </Style.Item17Text>

        <Style.Item13Text $isRight>
          {t(`calibration.${status}`)}
        </Style.Item13Text>
      </div>
    </Style.ItemContainer>
  );
};
