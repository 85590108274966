import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MainContainer = styled.div`
  ${tw`flex flex-col h-full`}
`;

export const StickyContent = styled.div`
  ${tw`sticky top-0 bg-white z-10`}
`;

export const HeaderContainer = styled.div`
  ${tw`flex gap-3 items-center py-4 sticky top-0 bg-white px-6`}
`;

export const HeaderTitle = styled.div`
  ${tw`grow text-22-30 font-bold`}
`;

export const ContentContainer = styled.div`
  ${tw`grow overflow-auto`}
`;

export const SectionContainer = styled.div`
  ${tw`flex flex-col gap-6`}
`;

export const SectionTitle = styled.div`
  ${tw`text-xl text-black font-bold`}
`;

export const SectionContent = styled.div`
  ${tw`flex flex-col gap-4`}
`;

export const AvatarRow = styled.div`
  ${tw`flex gap-3 items-center`}
`;

export const AvatarSettings = styled.div`
  ${tw`flex flex-col gap-2 text-15-20`}
`;

export const ActionButton = styled.button`
  ${tw`
    text-center w-max rounded-100px py-9px px-15px
    border border-gray90 font-bold
  `}
`;

export const AvatarDescription = styled.p`
  ${tw`font-medium text-dim-gray`}
`;

export const InputLabel = styled.label`
  ${tw`flex flex-col gap-2 text-15-20 font-medium text-dim-gray flex-1`}
`;

export const Input = styled.input`
  ${tw`
    outline-0 border border-gray94 rounded py-2.5 px-15px bg-alice-blue
    text-17-26 font-medium text-gray7 placeholder:text-dim-gray
  `}
`;

export const FlexRowContainer = styled.div<{ $alignBase?: boolean }>`
  ${tw`flex justify-between gap-4`}

  ${({
    $alignBase
  }) => ($alignBase ? tw`items-baseline` : tw`items-center`)}
`;

export const PasswordContent = styled.div`
  ${tw`flex flex-col gap-1 text-17-26 font-medium text-dark-gray`}
`;

export const PasswordDescription = styled.p`
  ${tw`text-15-20 text-dim-gray`}
`;

export const MenuFooter = styled.div`
  ${tw`border-t border-gray94 flex justify-end gap-3 py-4`}
`;

export const ErrorMessage = styled.div`
  ${tw`text-13-21 font-medium text-red-text-error -mt-1`}
`;

export const InputHint = styled.div`
  ${tw`text-13-21 font-medium text-dim-gray -mt-1`}
`;

export const ModalForm = styled.form`
  ${tw`flex flex-col gap-6`}
`;

export const ModalButtons = styled.div`
  ${tw`flex justify-between`}
`;

export const ModalRightButtons = styled.div`
  ${tw`flex gap-2`}
`;

export const EmailsContainer = styled.div`
  ${tw`w-580px bg-white flex flex-col gap-1 p-2`}
`;

export const AdditionalEmail = styled.button<{ $isActive?: boolean }>`
  ${tw`flex gap-2 p-2 transition-all rounded items-center justify-between text-17-26 font-medium`}

  ${({
    $isActive
  }) => ($isActive ? tw`text-button-blue bg-alice-blue` : tw`text-dark-gray`)}
`;
