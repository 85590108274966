import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const ImageContainer = styled.div<{ $isNew?: boolean }>`
  ${tw`shrink-0 self-center`}

  ${({
    $isNew
  }) => $isNew
    && tw`
      relative
      after:(w-4 h-4 absolute -top-0.5 -right-0.5 bg-button-blue rounded-full)
    `}
`;

export const ButtonsContainer = styled.div<{ $withShift?: boolean }>`
  ${tw`flex gap-2`}

  ${({
    $withShift
  }) => $withShift && tw`ml-68px`}
`;

export const ButtonToConfirm = styled.button<{
  $isConfirm?: boolean;
  $isRemoval?: boolean;
}>`
  ${tw`rounded-100px font-bold text-15-20 px-4 py-2 flex gap-1 items-center h-max`}

  ${({
    $isConfirm
  }) => ($isConfirm
    ? tw`bg-button-blue text-white`
    : tw`border border-gray90 bg-white text-dark-gray`)}

  ${({
    $isRemoval
  }) => $isRemoval && tw`bg-red-text-error text-white`}
`;

export const ImageBlock = styled.img<{ $isInline?: boolean }>`
  ${tw`rounded-full object-cover object-center border-input-blue`}

  ${({
    $isInline
  }) => ($isInline ? tw`inline-block w-6 h-6 border` : tw`w-14 h-14 border-2`)}
`;

export const NotificationDate = styled.div<{ $isDark?: boolean }>`
  ${tw`text-grey-dark-40 text-15-20 font-medium`}

  ${({
    $isDark
  }) => ($isDark ? tw`text-dark-gray` : tw`text-grey-dark-40`)}
`;

export const NotificationText = styled.div`
  ${tw`text-17-26 font-medium text-dark-gray`}
`;

export const NotificationPerson = styled.span`
  ${tw`text-17-26 font-bold text-gray7`}
`;

export const TextAnswer = styled.span<{
  $isConfirm?: boolean;
  $isNew?: boolean;
}>`
  ${tw`font-bold text-15`}

  ${({
    $isConfirm, $isNew
  }) => $isNew && ($isConfirm ? tw`text-accent80` : tw`text-negative80`)}

  ${({
    $isNew
  }) => !$isNew && tw`text-dim-gray`}
`;

export const TextBold = styled.span`
  ${tw`font-bold`}
`;

export const HeaderWithAvatarWrapper = styled.div`
  ${tw`flex gap-3 items-center`}
`;

export const Description = styled.div`
  ${tw`flex flex-col gap-2 text-17-26 grow`}
`;

export const NotificationHeader = styled.div`
  ${tw`flex justify-between`}
`;

export const NotificationBoldText = styled.span`
  ${tw`font-bold text-gray7`}
`;

export const ItemsList = styled.ul`
  ${tw`flex flex-col gap-4`}
`;

export const ItemsListTitle = styled.p`
  ${tw`ml-68px px-4 py-3 font-medium text-dark-gray`}
`;
