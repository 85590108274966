import React, {
  ReactNode
} from 'react';
import clsx from 'clsx';

import {
  SortIcon
} from 'src/shared/icons';
import {
  themeColors
} from 'src/shared/utils';

import {
  PopoverMenu
} from '../PopoverMenu';
import {
  CircleButton
} from '../CircleButton';

import * as Style from './SortPopoverMenu.styles';

interface SortByOption<T> {
  id: T;
  label: string;
  icon: ReactNode;
}

interface SortPopoverMenuProps<T> {
  options: SortByOption<T>[];
  onSelect: (value: T) => void;
  currentValue: T;
  menuWidth?: number;
}

export const SortPopoverMenu = <T,>({
  options,
  onSelect,
  currentValue,
  menuWidth = 292,
}: SortPopoverMenuProps<T>) => {
  return (
    <PopoverMenu
      renderCustomButton={({
        isOpen, toggleMenu, triggerProps
      }) => (
        <CircleButton
          borderColor={isOpen ? themeColors['button-blue'] : themeColors.gray90}
          {...triggerProps}
          onClick={toggleMenu}
        >
          <SortIcon
            className={clsx({
              'text-button-blue': isOpen,
              'text-dark-gray': !isOpen,
            })}
          />
        </CircleButton>
      )}
    >
      <Style.MenuContainer $width={menuWidth}>
        <Style.MenuTitle>Sort by</Style.MenuTitle>

        {options.map(({
          id, icon, label
        }) => (
          <Style.MenuItem
            key={id as string}
            type="button"
            $isActive={currentValue === id}
            onClick={() => onSelect(id)}
          >
            {icon}

            {label}
          </Style.MenuItem>
        ))}
      </Style.MenuContainer>
    </PopoverMenu>
  );
};
