import {
  useEffect, useState
} from 'react';

import {
  OrgQuestionsOnboardingText
} from 'src/redux/openapi';

export const useSetOnboardingTextList = (
  defaultQuestionsData?: OrgQuestionsOnboardingText[],
) => {
  const [questionsOnboardingText, setQuestionsOnboardingText] = useState<
  OrgQuestionsOnboardingText[]
  >([]);

  useEffect(
    () => {
      if (defaultQuestionsData?.length) {
        setQuestionsOnboardingText(defaultQuestionsData);
      }
    },
    [defaultQuestionsData]
  );

  const handleOnboardingTextChange = (id: string, value: string) => {
    setQuestionsOnboardingText((prev) => {
      return prev.map((item) => {
        if (item.id !== id) {
          return item;
        }

        return {
          ...item,
          onboardingText: value,
        };
      });
    });
  };

  return {
    questionsOnboardingText,
    onTextChange: handleOnboardingTextChange,
  };
};
