import React, {
  FC, useState
} from 'react';
import {
  Trans, useTranslation
} from 'react-i18next';
import {
  useNavigate, useParams
} from 'react-router-dom';

import {
  PageLayout
} from 'src/widgets';
import {
  Button
} from 'src/shared/components';
import {
  ROUTE, getConfidenceStatus
} from 'src/shared/utils';
import {
  CheckmarkCircleEmptyIcon,
  CloseCircleIcon,
  RefreshIcon,
} from 'src/shared/icons';
import {
  useGetCalibrationByIdQuery
} from 'src/redux/openapi';

import {
  ModalSetConfidenceRate
} from '../CalibrationTool/ui';

import * as Style from './CalibrationResults.style';
import {
  LoadingResults
} from './ui';

export const CalibrationResults: FC = () => {
  const {
    t
  } = useTranslation();

  const navigate = useNavigate();
  const params = useParams();

  const {
    calibrationId
  } = params;

  const {
    resultData, calibrationStep, isCalibrated, isResultLoading
  } = useGetCalibrationByIdQuery(
    {
      id: calibrationId || '',
    },
    {
      skip: !calibrationId,
      refetchOnMountOrArgChange: true,
      selectFromResult: ({
        data, isLoading
      }) => ({
        resultData: data?.data.calibration,
        isCalibrated: data?.data.calibration.calibrated,
        calibrationStep: data?.data.calibratedCount || 0,
        isResultLoading: isLoading,
      }),
    },
  );

  const calibratedResult = isCalibrated
    ? ''
    : getConfidenceStatus({
      confidence: resultData?.confidence || 0,
      correct: resultData?.correctAnswers || 0,
      total: resultData?.totalQuestions || 0,
    });

  const [isStartCalibration, setIsStartCalibration] = useState(false);

  const toggleModal = () => {
    setIsStartCalibration((prev) => !prev);
  };

  const handleFinishClick = () => {
    navigate(`/${ROUTE.CALIBRATION_TOOL}`);
  };

  const isCalibrationCompleted = isCalibrated && calibrationStep > 1;
  const isFirstStepCalibrated = isCalibrated && calibrationStep === 1;

  return (
    <PageLayout>
      {isResultLoading ? (
        <LoadingResults />
      ) : (
        <Style.Results>
          {isCalibrated ? (
            <CheckmarkCircleEmptyIcon className="text-accent80 w-16 h-16" />
          ) : (
            <CloseCircleIcon className="text-negative80 w-16 h-16" />
          )}

          <Style.ResultTitle>
            {isCalibrationCompleted && t('calibration.answersCalibrated')}

            {isFirstStepCalibrated && t('calibration.round1Completed')}

            {!isCalibrated && t('calibration.answersNotCalibrated')}
          </Style.ResultTitle>

          <Style.ResultComment>
            {isFirstStepCalibrated
              && t('calibration.passSecondRoundOfCalibration')}

            {isCalibrationCompleted
              && t('calibration.calibrationCompletedHint')}

            {!isCalibrated && (
              <Trans
                i18nKey="calibration.notCalibratedResultHint"
                values={{
                  calibratedResult,
                }}
              />
            )}
          </Style.ResultComment>

          <Style.ResultButtons>
            {!isCalibrationCompleted && (
              <Button
                variant="big-grey-bordered"
                onClick={toggleModal}
                className="text-xl font-bold text-dark-gray !w-max"
              >
                {isFirstStepCalibrated
                  ? t('buttons.continue')
                  : t('buttons.restart')}

                {!isFirstStepCalibrated && <RefreshIcon />}
              </Button>
            )}

            <Button
              variant="big-blue"
              onClick={handleFinishClick}
              className="text-xl font-bold text-white !w-max"
            >
              {t('buttons.finish')}
            </Button>
          </Style.ResultButtons>

          <ModalSetConfidenceRate
            isOpen={isStartCalibration}
            closeModal={toggleModal}
            isTestResult={resultData?.isTest}
          />
        </Style.Results>
      )}
    </PageLayout>
  );
};
