import React, {
  FC, Dispatch, SetStateAction
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  useGetGroupUsersFromGroupQuery,
  UserForMerge,
} from 'src/redux/openapi';
import {
  Spinner
} from 'src/shared/components';
import {
  ChooseItemsList
} from 'src/widgets';

import * as Style from './ChooseMemberContent.styles';

interface ChooseMemberContentProps {
  groupId: string;
  selectedItems: UserForMerge[];
  setSelectedItems: Dispatch<SetStateAction<UserForMerge[]>>;
}

export const ChooseMemberContent: FC<ChooseMemberContentProps> = ({
  groupId,
  selectedItems,
  setSelectedItems,
}) => {
  const {
    t
  } = useTranslation();

  const {
    users, isUsersLoading
  } = useGetGroupUsersFromGroupQuery(
    {
      id: groupId,
      // TODO: Add pagination after redesign or BE changes
      limit: 1000,
    },
    {
      selectFromResult: ({
        data, isLoading, isFetching
      }) => ({
        users: data?.data.users || [],
        isUsersLoading: isLoading || isFetching,
      }),
      refetchOnMountOrArgChange: true,
    },
  );

  return (
    <Style.ContentContainer>
      <Style.TitleContainer>
        <Style.Title>{t('group.Members')}</Style.Title>

        <Style.Subtitle>
          {`${t('group.addToNewGroup')}: `}

          <Style.SelectedCount>{selectedItems.length}</Style.SelectedCount>
        </Style.Subtitle>
      </Style.TitleContainer>

      {isUsersLoading ? (
        <Spinner size={24} />
      ) : (
        <>
          {!!users.length && (
            <ChooseItemsList
              list={users}
              valueKey="email"
              listProps={{
                className: 'pb-6',
              }}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
            />
          )}

          {!users.length && (
            <Style.EmptyMessage>{t('empty.noMembersFound')}</Style.EmptyMessage>
          )}
        </>
      )}
    </Style.ContentContainer>
  );
};
