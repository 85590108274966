import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const AvatarRow = styled.div`
  ${tw`flex gap-3 items-center px-6`}
`;

export const AvatarSettings = styled.div`
  ${tw`flex flex-col gap-2 text-15-20`}
`;

export const ActionLabel = styled.label`
  ${tw`
    text-center w-max rounded-100px py-9px px-15px
    border border-gray90 font-bold cursor-pointer
  `}
`;

export const AvatarDescription = styled.p`
  ${tw`font-medium text-dim-gray`}
`;
