import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  FromTheNewestIcon,
  FromTheOldestIcon,
  RadialСheckFalseIcon,
  RadialСheckTrueIcon,
  ThumbUpIcon,
} from 'src/shared/icons';

import * as Style from './NotificationSettings.styles';

export enum SortBy {
  Newest = 'fromNewest',
  Waiting = 'waitingForResponse',
  Oldest = 'fromOldest',
}

export enum FilterBy {
  AllTime = 'allTime',
  Week = 'inSevenDays',
  TwoDays = 'inTwoDays',
}

const SORT_BY_MAP = [
  {
    id: SortBy.Newest,
    icon: <FromTheNewestIcon />,
  },
  {
    id: SortBy.Waiting,
    icon: <ThumbUpIcon />,
  },
  {
    id: SortBy.Oldest,
    icon: <FromTheOldestIcon />,
  },
];

const FILTER_BY_MAP = [
  {
    id: FilterBy.AllTime,
  },
  {
    id: FilterBy.Week,
  },
  {
    id: FilterBy.TwoDays,
  },
];

interface NotificationSettingsProps {
  sortBy: SortBy;
  onSort: (sortBy: SortBy) => void;
  isNewOnly: boolean;
  dateFilter: string;
  changeNewOnly: () => void;
  handleSetDateFilter: (value: FilterBy) => void;
}

export const NotificationSettings: FC<NotificationSettingsProps> = ({
  isNewOnly,
  dateFilter,
  changeNewOnly,
  handleSetDateFilter,
  sortBy,
  onSort,
}) => {
  const {
    t
  } = useTranslation();

  return (
    <Style.FilterContainer>
      <Style.FilterTitle>{t('filters.showMe')}</Style.FilterTitle>

      {SORT_BY_MAP.map(({
        id, icon
      }) => (
        <Style.LabelShow
          key={id}
          $isActive={id === sortBy}
          onClick={() => onSort(id)}
        >
          {icon}

          <Style.ShowTitleLabel $isActive={id === sortBy}>
            {t(`filters.${id}`)}
          </Style.ShowTitleLabel>
        </Style.LabelShow>
      ))}

      <Style.Separator />

      <Style.FilterTitle>{t('filters.timeDuration')}</Style.FilterTitle>

      {FILTER_BY_MAP.map(({
        id
      }) => (
        <Style.DatesLabel key={id}>
          <input
            type="radio"
            name="duration"
            checked={dateFilter === id}
            className="hidden"
            id={id}
            onChange={() => handleSetDateFilter(id)}
          />

          <Style.DatesTitleLabel>{t(`filters.${id}`)}</Style.DatesTitleLabel>

          {dateFilter === id ? (
            <RadialСheckTrueIcon />
          ) : (
            <RadialСheckFalseIcon />
          )}
        </Style.DatesLabel>
      ))}

      <Style.Separator />

      <Style.NewOnlyLabel htmlFor="New only">
        <Style.DatesTitleLabel>{t('filters.newOnly')}</Style.DatesTitleLabel>

        <input
          type="checkbox"
          checked={isNewOnly}
          onChange={changeNewOnly}
          id="New only"
          className="hidden"
        />

        <Style.FilterSwitcher $isActive={isNewOnly} />
      </Style.NewOnlyLabel>
    </Style.FilterContainer>
  );
};
