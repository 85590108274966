import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MainContainer = styled.div`
  ${tw`flex flex-col gap-4`}
`;

export const ActivityButton = styled.button`
  ${tw`
    flex flex-1 items-center justify-center gap-1 rounded-lg border border-gray90
    font-bold text-15-20 text-dark-gray shadow-button-shadow py-2.5
  `}
`;

export const ButtonsContainer = styled.div`
  ${tw`flex gap-4`}
`;
