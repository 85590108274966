import React, {
  useEffect, useMemo, useState, useRef
} from 'react';
import {
  useNavigate, useParams
} from 'react-router-dom';
import {
  useTranslation
} from 'react-i18next';
import i18next from 'i18next';

import {
  Stepper,
  UploadUsersList,
  successfulToast,
  useStepper,
  warningToast,
} from 'src/shared/components';
import {
  CreateEntityContent,
  CreateEntityHead,
  CreateEntityLayout,
  PageLayout,
  SetOnboardingTextList,
  useSetOnboardingTextList,
} from 'src/widgets';
import {
  OrgSetupQuestionsOnboardingText,
  useGetQuestionsQuery,
  usePostOrgGroupsUsersMutation,
  usePutOrgQuestionsOnboardingTextMutation,
} from 'src/redux/openapi';
import {
  ROUTE, showWarningFromServer
} from 'src/shared/utils';
import {
  useTypedDispatch, useTypedSelector
} from 'src/redux';
import {
  organizationSelectors,
  resetUsersToSetUp,
} from 'src/redux/organizations';

import {
  SetUpOrgQuestions
} from './ui/SetUpOrgQuestions';
import {
  GroupsAndMembers
} from './ui/GroupsAndMembers';
import {
  SetUpOrgQuestionsMethods
} from './ui/SetUpOrgQuestions/SetUpOrgQuestions';

const getStepsMap = () => [
  {
    id: 'step1',
    label: `${i18next.t('organisation.groupsAndMembers')}`,
  },
  {
    id: 'step2',
    label: `${i18next.t('organisation.SetQuestions')}`,
  },
  {
    id: 'step3',
    label: `${i18next.t('question.setOnboardingText')}`,
  },
];

export const SetupOrganization = () => {
  const [isSaveLoading, setIsSavingLoading] = useState(false);
  const [isNextLoading, setIstNextLoading] = useState(false);

  const navigate = useNavigate();
  const steps = getStepsMap();

  const editQuestionsRef = useRef<SetUpOrgQuestionsMethods>(null);

  const {
    currentStep,
    setCurrentStep,
    completeCurrentStep,
    activeStepId,
    completedStatus,
    isLastStep,
    onStepClick,
    isCurrentStepCompleted,
  } = useStepper(steps);

  const {
    t
  } = useTranslation();

  const params = useParams();
  const dispatch = useTypedDispatch();

  const {
    organizationId
  } = params;

  if (!organizationId) {
    return null;
  }

  const {
    data,
    isLoading: isQuestionsLoading,
    isFetching,
  } = useGetQuestionsQuery(
    {
      limit: 5000,
      filters: {
        notStatus: 'DEACTIVATED',
        sortBy: 'startDate',
        sortOrder: 'asc',
      },
    },
    {
      skip: currentStep !== 2,
      refetchOnMountOrArgChange: true,
    },
  );

  const questionsWithOnboardingText = useMemo(
    () => data?.data.questions.map(({
      id, title, status, startDate, endDate
    }) => ({
      id,
      title,
      status,
      startDate,
      endDate,
      onboardingText: '',
    })) || [],
    [data],
  );

  const {
    questionsOnboardingText, onTextChange
  } = useSetOnboardingTextList(
    questionsWithOnboardingText,
  );

  const [saveOnboardingText] = usePutOrgQuestionsOnboardingTextMutation();

  const handleSaveOnboardingText = async () => {
    const questionsToSave = questionsOnboardingText.filter(
      ({
        onboardingText
      }) => onboardingText?.trim(),
    );

    await saveOnboardingText({
      id: organizationId,
      updateOrganizationQuestionsOnboardingTextBodySchema: {
        questions: questionsToSave as OrgSetupQuestionsOnboardingText,
      },
    }).unwrap();
  };

  const groupsToSetup = useTypedSelector(
    organizationSelectors.orgGroupsToSetup,
  );

  const [postNewUsersToGroup] = usePostOrgGroupsUsersMutation();

  const handleSaveOrgGroupUsers = async () => {
    const groups = groupsToSetup.map((group) => ({
      id: group.id,
      users: group.users.map((user) => ({
        id: user.id,
        email: user.email,
      })),
    }));

    await postNewUsersToGroup({
      id: organizationId,
      createGroupsUsersBodySchema: {
        groups,
      },
    }).unwrap();
  };

  const handleSaveOgrSettings = async () => {
    try {
      setIsSavingLoading(true);
      await handleSaveOrgGroupUsers();
      await handleSaveOnboardingText();

      successfulToast(t('organisation.setupSuccess'));

      navigate(`/${ROUTE.ORGANISATION}/${organizationId}`);
    } catch (error) {
      showWarningFromServer(error);
    } finally {
      setIsSavingLoading(false);
    }
  };

  const checkProceed = () => {
    if (!groupsToSetup.length && currentStep === 0) {
      warningToast(t('group.addAtLeastOne'));

      return false;
    }

    return true;
  };

  const onComplete = () => {
    const canProceed = checkProceed();

    if (!canProceed) {
      return;
    }

    if (!isCurrentStepCompleted) {
      completeCurrentStep();
    }
  };

  const onQuestionsSave = async () => {
    try {
      setIstNextLoading(true);
      await editQuestionsRef.current?.handleNextClick();
    } catch (error) {
      showWarningFromServer(error);
    } finally {
      setIstNextLoading(false);
    }
  };

  const handleNextStep = async () => {
    const canProceed = checkProceed();

    if (!canProceed) {
      return;
    }

    if (currentStep === 1) {
      await onQuestionsSave();
    }

    if (!isLastStep) {
      onComplete();

      setCurrentStep((prev) => prev + 1);
    } else {
      handleSaveOgrSettings();

      onComplete();
    }
  };

  const handleStepClick = async (step: number) => {
    const canProceed = checkProceed();

    if (!canProceed) {
      return;
    }

    onComplete();
    onStepClick(step);

    if (currentStep === 1) {
      await onQuestionsSave();
    }
  };

  useEffect(
    () => {
      if (currentStep === 0) {
        completeCurrentStep(!!groupsToSetup.length);
      }
    },
    [groupsToSetup.length, currentStep]
  );

  useEffect(
    () => {
      return () => {
        dispatch(resetUsersToSetUp());
      };
    },
    []
  );

  return (
    <PageLayout>
      <CreateEntityLayout variant="large">
        <CreateEntityHead>
          <Stepper
            steps={steps}
            completedStatus={completedStatus}
            activeStepId={activeStepId}
            nextStepButtonTitle={
              !isLastStep ? t('buttons.next') : t('buttons.create')
            }
            handleNextStep={handleNextStep}
            isLoading={isSaveLoading || isNextLoading}
            onStepClick={handleStepClick}
          />

          {currentStep === 0 && <UploadUsersList />}
        </CreateEntityHead>

        <CreateEntityContent>
          {currentStep === 0 && (
            <GroupsAndMembers shouldResetOnUnmount={false} />
          )}

          {currentStep === 1 && (
            <SetUpOrgQuestions
              organizationId={organizationId}
              ref={editQuestionsRef}
            />
          )}

          {currentStep === 2 && (
            <SetOnboardingTextList
              onTextChange={onTextChange}
              questions={questionsOnboardingText}
              isLoading={isQuestionsLoading || isFetching}
            />
          )}
        </CreateEntityContent>
      </CreateEntityLayout>
    </PageLayout>
  );
};
