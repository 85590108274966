export const LIGHT_BLUE = '#A7C0FB';

export const forecastsColorValues = [
  {
    forecastTitle: 'one',
    forecastColor: '#F57024',
    forecastColorStroke: '#F2B491',
  },
  {
    forecastTitle: 'two',
    forecastColor: '#4769BA',
    forecastColorStroke: '#73ADD7',
  },
  {
    forecastTitle: 'three',
    forecastColor: '#6C809A',
    forecastColorStroke: '#9FAFC4',
  },
  {
    forecastTitle: 'four',
    forecastColor: '#4A4766',
    forecastColorStroke: '#756DB6',
  },
  {
    forecastTitle: 'five',
    forecastColor: '#D7B166',
    forecastColorStroke: '#F2DAAB',
  },
  {
    forecastTitle: 'six',
    forecastColor: '#00C2B8',
    forecastColorStroke: '#B8EBE8',
  },
  {
    forecastTitle: 'seven',
    forecastColor: '#006D77',
    forecastColorStroke: '#79AEB2',
  },
  {
    forecastTitle: 'eight',
    forecastColor: '#9E2A2B',
    forecastColorStroke: '#E5A1A1',
  },
  {
    forecastTitle: 'nine',
    forecastColor: '#A663CC',
    forecastColorStroke: '#D8AAF2',
  },
];
