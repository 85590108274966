import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const Container = styled.div`
  ${tw`px-6 flex flex-col justify-between h-full`}
`;

export const TabName = styled.div`
  ${tw`text-xl text-black font-bold mb-6`}
`;

export const TextHint = styled.div`
  ${tw`text-15-20 text-dim-gray font-medium mb-2`}
`;

export const MenuContainer = styled.div`
  ${tw`bg-white rounded-md w-580px flex flex-col border border-gray90`}
`;

export const ValueSelect = styled.button`
  ${tw`w-full h-11 rounded-md flex gap-1 items-center justify-between px-4 py-2 border text-15-20 font-medium text-dim-gray bg-white border-gray90`}
`;

export const MenuOption = styled.button`
  ${tw`py-2 px-4 w-full h-11 rounded hover:bg-cultured`}
`;

export const MenuText = styled.p<{ $isSelected: boolean }>`
  ${tw`text-17-26 font-medium text-left`}

  ${({
    $isSelected
  }) => ($isSelected ? tw`text-button-blue` : tw`text-dim-gray`)}
`;
