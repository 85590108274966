import React from 'react';
import {
  flexRender,
  TableOptions,
  useReactTable,
  getCoreRowModel,
} from '@tanstack/react-table';

import {
  EntityModel
} from '../../types';
import {
  TableRow
} from '../TableRow';

import * as Style from './ManagementTableContent.styles';

interface ManagementTableContentProps<T extends EntityModel>
  extends Pick<TableOptions<T>, 'data' | 'columns'> {
  onRowClick?: (id: string) => void;
}

export const ManagementTableContent = <T extends EntityModel>({
  data,
  columns,
  onRowClick,
}: ManagementTableContentProps<T>) => {
  const table = useReactTable({
    data,
    getCoreRowModel: getCoreRowModel(),
    columns,
  });

  return (
    <Style.TableWrapper className="scroll-hidden">
      <Style.TableContainer>
        <Style.TableHead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Style.HeaderRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Style.HeaderCell
                  key={header.id}
                  colSpan={header.colSpan}
                  style={{
                    width: `${header.getSize()}px`,
                  }}
                >
                  {!header.isPlaceholder
                    && flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                </Style.HeaderCell>
              ))}
            </Style.HeaderRow>
          ))}
        </Style.TableHead>

        <tbody>
          {table.getRowModel().rows.map((row, index) => (
            <TableRow
              row={row}
              key={row.original.id}
              onRowClick={onRowClick}
              index={index}
            />
          ))}
        </tbody>
      </Style.TableContainer>
    </Style.TableWrapper>
  );
};
