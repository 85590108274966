import React, {
  FC
} from 'react';

import {
  LIGHT_BLUE
} from 'src/shared/utils/colors';
import {
  Point
} from 'src/entities';

interface TooltipCursorProps {
  width?: number;
  height?: number;
  points?: Point[];
}

export const TooltipCursor: FC<TooltipCursorProps> = ({
  width = 100,
  height = 5,
  points,
}) => {
  return (
    <line
      x1={points?.[0]?.x || 0}
      x2={points?.[1]?.x || 0}
      y1={(points?.[0]?.y || 0) - 4}
      y2={(points?.[1]?.y || 0) + 13}
      width={width}
      height={height}
      stroke={LIGHT_BLUE}
      strokeWidth={2}
    />
  );
};
