import React, {
  FC, useEffect, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import {
  useParams
} from 'react-router-dom';

import {
  useGetOrganizationQuery,
  usePatchOrganizationByIdMutation,
} from 'src/redux/openapi';
import {
  showWarningFromServer
} from 'src/shared/utils';
import {
  successfulToast
} from 'src/shared/components/Toasts';
import {
  Spinner
} from 'src/shared/components/Spinner';

import * as Style from './OrgEmailSettings.style';
import {
  WeekDays, getDayName
} from './helpers';

interface OrgEmailSettingsProps {}

export const OrgEmailSettings: FC<OrgEmailSettingsProps> = () => {
  const {
    t
  } = useTranslation();

  const params = useParams();

  const organisationId = params.organizationId;

  const weekDays = Object.values(WeekDays);

  const [chosenDay, setChosenDay] = useState<WeekDays | null>(null);
  const [newDayToSet, setNewDayToSet] = useState<WeekDays | null>(null);

  const {
    communicationDayData
  } = useGetOrganizationQuery(
    {
      id: organisationId || '',
    },
    {
      skip: !organisationId,
      refetchOnMountOrArgChange: true,
      selectFromResult: ({
        data
      }) => ({
        communicationDayData: data?.data.organization.communicationDay || null,
      }),
    },
  );

  const [setDaySettings, {
    isLoading: isSettingDayLoading
  }] = usePatchOrganizationByIdMutation();

  useEffect(
    () => {
      if (communicationDayData) {
        const communicationDay = getDayName(communicationDayData);

        setChosenDay(communicationDay);
      } else {
        setChosenDay(null);
      }
    },
    [communicationDayData]
  );

  const handleDayChange = async () => {
    if (!newDayToSet) {
      return;
    }

    try {
      const resp = await setDaySettings({
        id: organisationId || '',
        updateOrganizationBodySchema: {
          communicationDay: Object.values(WeekDays).indexOf(newDayToSet) + 1,
        },
      }).unwrap();

      successfulToast(resp.message);
      setChosenDay(newDayToSet);
    } catch (error) {
      showWarningFromServer(error);
    } finally {
      setNewDayToSet(null);
    }
  };

  useEffect(
    () => {
      if (newDayToSet) {
        handleDayChange();
      }
    },
    [newDayToSet]
  );

  return (
    <Style.EmailRow>
      <Style.Title>{t('organisation.emailPreferences')}</Style.Title>

      <Style.DaysRow>
        <Style.SubTitle>
          {`${t('organisation.sendOverviewEvery')}:`}
        </Style.SubTitle>

        {weekDays.map((day) => (
          <Style.DayButton
            $isActive={day === chosenDay}
            type="button"
            key={day}
            onClick={() => setNewDayToSet(day)}
            disabled={isSettingDayLoading}
          >
            {isSettingDayLoading && newDayToSet === day ? (
              <Spinner size={20} />
            ) : (
              <Style.DayName>{t(`date.${day}`)[0]}</Style.DayName>
            )}
          </Style.DayButton>
        ))}
      </Style.DaysRow>
    </Style.EmailRow>
  );
};
