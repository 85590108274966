import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const Title = styled.div`
  ${tw`text-center text-28-36 font-bold text-gray7 mb-6`}
`;

export const Text = styled.div`
  ${tw`text-center text-17-26 font-medium text-dark-gray mb-6`}
`;

export const ButtonsContainer = styled.div`
  ${tw`flex gap-4 justify-between items-center`}
`;
