import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const CardContainer = styled.div`
  ${tw`border border-gray94 rounded-md shadow-card-shadow-default p-15px`}
`;

export const HeaderContainer = styled.div`
  ${tw`flex justify-between text-13-21 font-medium text-dim-gray mb-4`}
`;

export const HeaderTitle = styled.p`
  ${tw`font-bold`}
`;

export const AvatarWrapper = styled.div`
  ${tw`shrink-0`}
`;

export const ContentWrapper = styled.div`
  ${tw`flex flex-col gap-2`}
`;

export const ContentContainer = styled.div`
  ${tw`flex gap-2`}
`;

export const AvatarRowDescription = styled.div`
  ${tw`flex flex-col gap-1 text-xs text-dim-gray font-medium leading-5`}
`;

export const TextContent = styled.p`
  ${tw`font-medium text-13-21`}
`;

export const UserName = styled.p`
  ${tw`font-semibold text-base text-gray7`}
`;

export const ForecastTile = styled.div`
  ${tw`
    p-11px rounded-10px border border-gray90 flex flex-col gap-1
    text-xs text-dim-gray font-medium leading-5
  `}
`;

export const ForecastStatisticRow = styled.div`
  ${tw`flex justify-between gap-1`}
`;

export const ForecastStatistic = styled.div`
  ${tw`leading-6 font-semibold text-gray7`}
`;

export const ForecastUpdatedStatistic = styled.span`
  ${tw`text-dim-gray`}
`;
