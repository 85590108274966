import React, {
  FC, useEffect, useRef
} from 'react';
import {
  useSearchParams
} from 'react-router-dom';

import {
  ActivityTile, StatisticTile
} from 'src/entities/Questions';
import {
  PeopleIcon,
  MessageIcon,
  TrendingUpIcon,
  GlobeIcon,
} from 'src/shared/icons';
import {
  commentID,
  formatNumber,
  questionId,
  questionsSortBy,
  showOne,
} from 'src/shared/utils';
import {
  CommunityQuestion
} from 'src/redux/openapi';

import * as Style from './QuestionCard.styles';

interface QuestionCardProps {
  question: CommunityQuestion;
  isActive: boolean;
  isStatisticsVisible: boolean;
}

export const QuestionCard: FC<QuestionCardProps> = ({
  question,
  isActive,
  isStatisticsVisible,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const commentRef = useRef<HTMLDivElement | null>(null);

  const sortBy = searchParams.get(questionsSortBy);

  const onCardClick = (id: string) => {
    searchParams.delete(commentID);
    searchParams.delete(showOne);

    searchParams.set(
      questionId,
      id
    );

    setSearchParams(searchParams);
  };

  const {
    id,
    title,
    participantsCount,
    commentsCount,
    globalEstimatesAvg,
    globalEstimatesChange,
    updatesCount,
  } = question;

  useEffect(
    () => {
      if (commentRef.current && isActive) {
        commentRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    },
    [sortBy]
  );

  return (
    <Style.CardContainer
      $isActive={isActive}
      onClick={() => onCardClick(id.toString())}
      aria-hidden="true"
      ref={commentRef}
    >
      <p>{title}</p>

      {isStatisticsVisible && (
        <Style.QuestionInfo>
          <Style.QuestionInfoWrapper $gap={12}>
            <StatisticTile
              amount={globalEstimatesAvg}
              icon={<GlobeIcon className="w-4 h-4" />}
              growth={globalEstimatesChange}
              applyMinWidth={false}
              height="auto"
              style={{
                backgroundColor: 'transparent',
              }}
            />
          </Style.QuestionInfoWrapper>

          <Style.QuestionInfoWrapper $gap={8}>
            <ActivityTile
              icon={<PeopleIcon className="w-4 h-4" />}
              amount={formatNumber(participantsCount)}
              style={{
                fontSize: '13px',
                padding: '4px',
              }}
            />

            <ActivityTile
              icon={<MessageIcon className="w-4 h-4 text-dim-gray" />}
              amount={formatNumber(commentsCount)}
              timeRange="upToday"
              style={{
                fontSize: '13px',
                padding: '4px',
              }}
            />

            <ActivityTile
              icon={<TrendingUpIcon className="w-4 h-4 text-dim-gray" />}
              amount={formatNumber(updatesCount)}
              timeRange="upToday"
              style={{
                fontSize: '13px',
                padding: '4px',
              }}
            />
          </Style.QuestionInfoWrapper>
        </Style.QuestionInfo>
      )}
    </Style.CardContainer>
  );
};
