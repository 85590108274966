import React, {
  FC, useEffect, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import clsx from 'clsx';

import {
  Button,
  PopoverMenu,
  Spinner,
  successfulToast,
} from 'src/shared/components';
import {
  ArrowDownIcon
} from 'src/shared/icons';
import {
  useGetUserCurrentEmailsQuery,
  usePostEmailCommunicationUnsubscriptionMutation,
  usePutEmailCommunicationFrequencyMutation,
} from 'src/redux/openapi';
import {
  showWarningFromServer, themeColors
} from 'src/shared/utils';

import * as Style from './EmailsPreferencesTab.style';

enum PeriodValues {
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  BiWeekly = 'ONCE_TWO_WEEKS',
}

export const EmailsPreferencesTab: FC = () => {
  const {
    t
  } = useTranslation();

  const defaultSelect = t('common.select');

  const [selectedValue, setSelectedValue] = useState<PeriodValues | null>(null);

  const periodValues = [
    PeriodValues.Daily,
    PeriodValues.Weekly,
    PeriodValues.BiWeekly,
  ];

  const {
    data: emailsPreferences, refetch
  } = useGetUserCurrentEmailsQuery(
    undefined,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const [
    emailCommunicationUnsubscription,
    {
      isLoading: isUnsubscriptionLoading
    },
  ] = usePostEmailCommunicationUnsubscriptionMutation();

  const [changeEmailFrequency, {
    isLoading: isLoadingEmailFrequency
  }] = usePutEmailCommunicationFrequencyMutation();

  const handleUnsubscribeClick = async () => {
    try {
      const resp = await emailCommunicationUnsubscription().unwrap();

      successfulToast(resp.message);

      setSelectedValue(null);
      refetch();
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  useEffect(
    () => {
      if (emailsPreferences?.data.emailCommunication?.enabled) {
        setSelectedValue(
          emailsPreferences.data.emailCommunication
            .emailFrequency as PeriodValues,
        );
      }
    },
    [emailsPreferences]
  );

  const [shouldClose, setShouldClose] = useState(false);

  const clickHandler = async (choice: PeriodValues) => {
    if (selectedValue === choice) {
      setShouldClose(true);

      return;
    }

    try {
      const response = await changeEmailFrequency({
        updateCurrentUserEmailCommunicationFrequencyBodySchema: {
          emailFrequency: choice,
        },
      }).unwrap();

      successfulToast(response.message);

      setSelectedValue(choice);
      setShouldClose(true);
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  const handleButtonClick = (toggle: () => void) => {
    if (shouldClose) {
      setShouldClose(false);
    }

    toggle();
  };

  return (
    <Style.Container>
      <div>
        <Style.TabName>{t('editAccount.emailsPreferences')}</Style.TabName>

        <Style.TextHint>{t('editAccount.receiveOverview')}</Style.TextHint>

        <PopoverMenu
          placement="bottom-start"
          shouldForcedClose={shouldClose}
          zIndex={2222}
          renderCustomButton={({
            isOpen: isOpenChoices,
            toggleMenu,
            triggerProps,
          }) => (
            <Style.ValueSelect
              type="button"
              onClick={() => handleButtonClick(toggleMenu)}
              tabIndex={0}
              {...triggerProps}
            >
              {selectedValue ? t(`date.${selectedValue}`) : defaultSelect}

              {isLoadingEmailFrequency && (
                <Spinner
                  color={themeColors['dim-gray']}
                  size={20}
                />
              )}

              <ArrowDownIcon
                className={clsx(
                  'w-6 h-6',
                  {
                    'rotate-180': isOpenChoices,
                  }
                )}
              />
            </Style.ValueSelect>
          )}
        >
          <Style.MenuContainer>
            {periodValues.map((value) => {
              return (
                <Style.MenuOption
                  type="button"
                  onClick={() => clickHandler(value)}
                  key={value}
                  disabled={isLoadingEmailFrequency}
                >
                  <Style.MenuText $isSelected={value === selectedValue}>
                    {t(`date.${value}`)}
                  </Style.MenuText>
                </Style.MenuOption>
              );
            })}
          </Style.MenuContainer>
        </PopoverMenu>
      </div>

      {!!selectedValue && (
        <div className="flex justify-end items-end h-full py-4">
          <Button
            variant="big-blue"
            className="font-bold w-max text-xl"
            onClick={handleUnsubscribeClick}
          >
            {t('buttons.unsubscribe')}

            {isUnsubscriptionLoading && (
              <Spinner
                color="white"
                size={24}
              />
            )}
          </Button>
        </div>
      )}
    </Style.Container>
  );
};
