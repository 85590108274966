import React, {
  Dispatch,
  FC,
  useEffect,
  useMemo,
  SetStateAction,
  useState,
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import ReactSelect from 'react-select';
import clsx from 'clsx';
import {
  useParams
} from 'react-router-dom';

import {
  Checkbox,
  Divider,
  DropdownIndicator,
  Spinner,
  SelectPaginationMenu,
} from 'src/shared/components';
import {
  GroupForMerge,
  useGetOrgGroupsInvitationsQuery,
} from 'src/redux/openapi';
import {
  useCustomInfiniteScroll, UseInfiniteScroll
} from 'src/shared/hooks';

import {
  SelectValue
} from '../../types';

import * as Style from './ChooseGroupContent.styles';
import {
  SingleValue
} from './SingleValue';

interface ChooseGroupContentProps {
  groupsConfig: UseInfiniteScroll<GroupForMerge>;
  currentGroup: GroupForMerge;
  selectedGroup: SelectValue | null;
  setSelectedGroup: Dispatch<SetStateAction<SelectValue | null>>;
  selectedGroups: GroupForMerge[];
  setSelectedGroups: Dispatch<SetStateAction<GroupForMerge[]>>;
}

export const ChooseGroupContent: FC<ChooseGroupContentProps> = ({
  groupsConfig,
  currentGroup,
  selectedGroup,
  setSelectedGroup,
  selectedGroups,
  setSelectedGroups,
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const {
    t
  } = useTranslation();

  const params = useParams();

  const {
    organizationId = ''
  } = params;

  const {
    allItems: allGroups, displayLoader, sentryRef
  } = groupsConfig;

  const fromGroups = useMemo(
    () => allGroups.filter((group) => group.id !== selectedGroup?.value),
    [selectedGroup, allGroups],
  );

  const onGroupCheck = (group: GroupForMerge) => {
    setSelectedGroups((prevSelected) => {
      const isSelected = prevSelected.some(({
        id
      }) => group.id === id);

      return isSelected
        ? prevSelected.filter(({
          id
        }) => id !== group.id)
        : [...prevSelected, group];
    });
  };

  const onGroupSelect = (value: SelectValue) => {
    setSelectedGroup(value);

    const isSelectedForMerge = selectedGroups.some(
      (group) => group.id === value.value,
    );

    if (isSelectedForMerge) {
      setSelectedGroups((prevSelected) => prevSelected.filter(({
        id
      }) => id !== value.value),);
    }
  };

  const {
    data: groupsData,
    isLoading,
    isFetching,
    isError,
  } = useGetOrgGroupsInvitationsQuery(
    {
      id: organizationId,
      page: currentPage,
      limit: 10,
      status: 'ACTIVATED',
    },
    {
      skip: !organizationId,
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    total: totalQuestions, groups
  } = useMemo(
    () => groupsData?.data || {
      total: 0,
      groups: [],
    },
    [groupsData],
  );

  const selectConfig = useCustomInfiniteScroll({
    total: totalQuestions,
    currentItems: groups,
    isFetching,
    isLoading,
    isError,
    currentPage,
    setCurrentPage,
  });

  const selectOptions = selectConfig.allItems.map(
    ({
      name, id, activeUsersCount, location
    }) => ({
      label: name,
      value: id,
      activeUsersCount,
      location,
    }),
  );

  useEffect(
    () => {
      if (currentGroup && !selectedGroup) {
        const {
          id, name, activeUsersCount, location
        } = currentGroup;

        setSelectedGroup({
          value: id,
          label: name,
          activeUsersCount,
          location,
        });
      }
    },
    [currentGroup]
  );

  return (
    <Style.ContentContainer>
      <Style.SectionContainer>
        <Style.SectionTitle>{t('group.fromGroups')}</Style.SectionTitle>

        <Style.GroupList className="scroll-hidden">
          {fromGroups.map((group) => {
            const isSelected = selectedGroups.some(
              (selected) => selected.id === group.id,
            );

            const {
              name, location, activeUsersCount, id
            } = group;

            return (
              <Style.GroupListItem key={id}>
                <Checkbox
                  type="checkMark"
                  isChecked={isSelected}
                  onChange={() => onGroupCheck(group)}
                />

                <Style.GroupInfoContainer>
                  <Style.GroupTitle>{name}</Style.GroupTitle>

                  <Style.GroupInfo>
                    <Style.GroupInvitedInfo>
                      {`${t('common.active')}: `}

                      <strong>{activeUsersCount}</strong>
                    </Style.GroupInvitedInfo>

                    <Style.GroupLocation>{location}</Style.GroupLocation>
                  </Style.GroupInfo>
                </Style.GroupInfoContainer>
              </Style.GroupListItem>
            );
          })}

          {displayLoader && (
            <div ref={sentryRef}>
              <Spinner size={24} />
            </div>
          )}
        </Style.GroupList>
      </Style.SectionContainer>

      <Divider $type="vertical" />

      <Style.SectionContainer>
        <Style.SectionTitle>{t('group.toGroup')}</Style.SectionTitle>

        <ReactSelect
          options={selectOptions}
          isMulti={false}
          hideSelectedOptions={false}
          isClearable={false}
          value={selectedGroup}
          isSearchable={false}
          closeMenuOnSelect
          closeMenuOnScroll={false}
          menuPortalTarget={document.body}
          onChange={(newValue) => onGroupSelect(newValue as SelectValue)}
          styles={{
            menuPortal: (base) => ({
              ...base,
              zIndex: 9999,
            }),
          }}
          components={{
            DropdownIndicator,
            SingleValue,
            MenuList: SelectPaginationMenu,
          }}
          sentryRef={selectConfig.sentryRef}
          displayLoader={selectConfig.displayLoader}
          classNames={{
            control: ({
              menuIsOpen
            }) => clsx(
              `
                px-4 py-2 border outline-0 !rounded-md
                text-17-26 text-dark-gray font-medium
                !shadow-none
              `,
              {
                '!border-gray90': !menuIsOpen,
                '!border-button-blue': menuIsOpen,
              },
            ),
            valueContainer: () => `!flex !p-0 gap-2`,
            indicatorSeparator: () => `hidden`,
            option: ({
              isSelected
            }) => clsx(
              `
                text-17-26 font-medium mb-2 last:mb-0 p-2 rounded hover:!bg-cultured cursor-pointer
              `,
              {
                '!bg-alice-blue !text-button-blue': isSelected,
                '!text-dark-gray !bg-white': !isSelected,
              },
            ),
            menu: () => `p-2 !border-0 rounded !mt-1 !z-10 overflow-auto`,
            menuList: () => `overflow-auto !max-h-260px`,
          }}
        />
      </Style.SectionContainer>
    </Style.ContentContainer>
  );
};
