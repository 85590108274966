import React, {
  ReactNode, useState, FC
} from 'react';

import {
  CircleButton
} from 'src/shared/components';

import * as Style from './SwitchButton.styles';

interface Option {
  id: number | string;
  label: ReactNode | string;
}

interface SwitchButtonProps {
  options: Option[];
  onChange: () => void;
  defaultOption?: Option;
}

export const SwitchButton: FC<SwitchButtonProps> = ({
  options,
  onChange,
  defaultOption,
}) => {
  const [selectedOptionId, setSelectedOptionId] = useState(
    defaultOption?.id || options[0].id,
  );

  const handleOptionChange = (id: number | string) => {
    setSelectedOptionId(id);

    onChange();
  };

  return (
    <Style.MainContainer>
      {options.map(({
        id, label
      }) => {
        const isSelected = selectedOptionId === id;

        return (
          <CircleButton
            key={id}
            size={36}
            withBorder={false}
            backgroundColor={isSelected ? 'white' : 'transparent'}
            className={`
              transition-colors duration-300 ease-in-out ${
                isSelected ? 'text-dark-gray' : 'text-dim-gray'
              }
            `}
            onClick={() => handleOptionChange(id)}
            disabled={isSelected}
          >
            {label}
          </CircleButton>
        );
      })}
    </Style.MainContainer>
  );
};
