import React, {
  FC, FormEvent, useMemo, useState
} from 'react';
import {
  Controller, useForm
} from 'react-hook-form';
import {
  zodResolver
} from '@hookform/resolvers/zod';
import {
  useTranslation
} from 'react-i18next';

import {
  Button,
  Input,
  ModalContainer,
  Spinner,
  successfulToast,
} from 'src/shared/components';
import {
  useGetAdminGroupQuery,
  usePutAdminGroupMutation,
} from 'src/redux/openapi';
import {
  showWarningFromServer
} from 'src/shared/utils';
import {
  useGetInputValidation
} from 'src/shared/hooks';
import {
  LocationSelect
} from 'src/features/LocationSelect';

import * as Style from '../EditEntityByAdmin';

import {
  EditGroupFormField as FormField, EditGroupForm
} from './types';

interface EditGroupAdminModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  groupId: string;
}

export const EditGroupAdminModal: FC<EditGroupAdminModalProps> = ({
  isOpen,
  onClose,
  onSuccess,
  groupId,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    data: groupData,
    isLoading: isGroupDataLoading,
    isFetching: isGroupDataFetching,
  } = useGetAdminGroupQuery(
    {
      id: groupId,
    },
    {
      skip: !isOpen,
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    name,
    location,
    users: admins,
  } = useMemo(
    () => groupData?.data.group || {
      name: '',
      location: '',
      users: null,
    },
    [groupData],
  );

  const groupMainAdmin = useMemo(
    () => admins?.find((admin) => admin.role === 'SUPERADMIN'),
    [admins],
  );

  const groupSubAdmins = useMemo(
    () => admins?.filter((admin) => admin.role === 'ADMIN') || [],
    [admins],
  );

  const {
    editAdminGroupSchema
  } = useGetInputValidation();

  const {
    control,
    formState: {
      errors
    },
    handleSubmit,
    reset,
  } = useForm<EditGroupForm>({
    mode: 'onTouched',
    defaultValues: {
      [FormField.GroupName]: '',
      [FormField.Location]: {
        value: '',
        label: '',
      },
      [FormField.MainAdmin]: '',
      [FormField.FirstSubAdmin]: '',
      [FormField.SecondSubAdmin]: '',
    },
    values: {
      [FormField.GroupName]: name,
      [FormField.Location]: {
        value: location || '',
        label: location || '',
      },
      [FormField.MainAdmin]: groupMainAdmin?.email || '',
      [FormField.FirstSubAdmin]: groupSubAdmins?.[0]?.email || '',
      [FormField.SecondSubAdmin]: groupSubAdmins?.[1]?.email || '',
    },
    resolver: zodResolver(editAdminGroupSchema),
  });

  const handleClose = () => {
    onClose();
    reset();
  };

  const [saveGroup] = usePutAdminGroupMutation();

  const onSubmit = handleSubmit(async (data: EditGroupForm) => {
    setIsLoading(true);

    const {
      groupName,
      groupLocation,
      mainAdministrator,
      firstSubAdmin,
      secondSubAdmin,
    } = data;

    const subAdmins = [firstSubAdmin, secondSubAdmin].filter(
      (value) => value.length,
    );

    const body = {
      name: groupName,
      location: groupLocation.value || undefined,
      superadminEmail: mainAdministrator,
      adminEmails: subAdmins,
    };

    try {
      const response = await saveGroup({
        id: groupId,
        adminUpdateGroupByIdBodySchema: body,
      }).unwrap();

      successfulToast(response.message);

      if (onSuccess) {
        onSuccess();
      }

      handleClose();
    } catch (error) {
      showWarningFromServer(error);
    } finally {
      setIsLoading(false);
    }
  });

  const onFormSubmit = (event: FormEvent) => {
    event.preventDefault();
    onSubmit();
  };

  const {
    t
  } = useTranslation();

  return (
    <ModalContainer
      isOpen={isOpen}
      closeModalHandler={handleClose}
      modalTitle={t('group.editGroup')}
    >
      {isGroupDataLoading || isGroupDataFetching ? (
        <Style.SpinnerContainer>
          <Spinner size={24} />
        </Style.SpinnerContainer>
      ) : (
        <Style.ContentContainer onSubmit={onFormSubmit}>
          <Style.InputsRow>
            <Style.InputContainer>
              <Controller
                name={FormField.GroupName}
                control={control}
                render={({
                  field: {
                    onChange, onBlur, value
                  }
                }) => {
                  return (
                    <Input
                      onChange={onChange}
                      value={value}
                      placeholder={t('organisation.organisationName')}
                      label={t('organisation.organisationName')}
                      isRequired
                      onBlur={onBlur}
                      errorMessage={errors[FormField.GroupName]?.message}
                    />
                  );
                }}
              />
            </Style.InputContainer>

            <Style.InputContainer>
              <Controller
                name={FormField.Location}
                control={control}
                rules={{
                  required: false,
                }}
                render={({
                  field: {
                    onChange, onBlur, value
                  }
                }) => {
                  return (
                    <LocationSelect
                      onChange={onChange}
                      value={value}
                      label={`${t('group.location')} (${t('common.optional')})`}
                      onBlur={onBlur}
                      errorMessage={errors[FormField.Location]?.message}
                    />
                  );
                }}
              />
            </Style.InputContainer>
          </Style.InputsRow>

          <Style.SetAdminsContainer>
            <Style.SetAdminsHeader>
              <Style.SetAdminsTitle>
                {t('common.manageAdministration')}
              </Style.SetAdminsTitle>
            </Style.SetAdminsHeader>

            <Controller
              name={FormField.MainAdmin}
              control={control}
              render={({
                field: {
                  onChange, onBlur, value
                }
              }) => {
                return (
                  <Input
                    onChange={onChange}
                    value={value}
                    placeholder="user@email.com"
                    label={t('common.mainAdministrator')}
                    onBlur={onBlur}
                    isRequired
                    errorMessage={errors[FormField.MainAdmin]?.message}
                  />
                );
              }}
            />

            <Style.InputsRow>
              <Style.InputContainer>
                <Controller
                  name={FormField.FirstSubAdmin}
                  control={control}
                  rules={{
                    required: false,
                    minLength: 0,
                  }}
                  render={({
                    field: {
                      onChange, onBlur, value
                    }
                  }) => {
                    return (
                      <Input
                        onChange={onChange}
                        value={value}
                        placeholder="user@email.com"
                        label={`${t('common.subAdministrator')} (${t(
                          'common.optional',
                        )})`}
                        onBlur={onBlur}
                        errorMessage={errors[FormField.FirstSubAdmin]?.message}
                      />
                    );
                  }}
                />
              </Style.InputContainer>

              <Style.InputContainer>
                <Controller
                  name={FormField.SecondSubAdmin}
                  control={control}
                  render={({
                    field: {
                      onChange, onBlur, value
                    }
                  }) => {
                    return (
                      <Input
                        onChange={onChange}
                        value={value}
                        placeholder="user@email.com"
                        label={`${t('common.subAdministrator')} (${t(
                          'common.optional',
                        )})`}
                        onBlur={onBlur}
                        errorMessage={errors[FormField.SecondSubAdmin]?.message}
                      />
                    );
                  }}
                />
              </Style.InputContainer>
            </Style.InputsRow>
          </Style.SetAdminsContainer>

          <Style.ButtonsContainer>
            <Button
              variant="big-grey-bordered"
              className="font-bold text-xl w-max"
              onClick={handleClose}
            >
              {t('buttons.cancel')}
            </Button>

            <Button
              variant="big-blue"
              onClick={onSubmit}
              className="font-bold text-xl w-max"
            >
              {t('buttons.saveChanges')}

              {isLoading && (
                <Spinner
                  color="white"
                  size={24}
                  centered={false}
                />
              )}
            </Button>
          </Style.ButtonsContainer>
        </Style.ContentContainer>
      )}
    </ModalContainer>
  );
};
