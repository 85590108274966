import React, {
  FC, useEffect, useMemo
} from 'react';
import {
  useSearchParams
} from 'react-router-dom';
import {
  useTranslation
} from 'react-i18next';

import {
  getColumnWidths,
  getColumnHeaders,
  FilterBy,
} from 'src/entities/Questions';
import {
  ActionButton
} from 'src/widgets';
import {
  ArrowDownIcon, ArrowUpIcon
} from 'src/shared/icons';
import {
  FAVORITE,
  questionsFilterBy,
  questionsTimeRange,
  topicId,
} from 'src/shared/utils';
import {
  ModalUpdateForecast, Spinner
} from 'src/shared/components';

import {
  EmptyResultsMessage
} from '../EmptyResultsMessage';
import {
  useGetQuestions, useUpdateModal
} from '../../hooks';

import * as Style from './TableView.styles';
import {
  TableHeaderTabs
} from './TableHeaderTabs';
import {
  TableRow
} from './TableRow';

const MAX_ROWS = 8;

interface TableViewProps {
  isExpanded: boolean;
}

export const TableView: FC<TableViewProps> = ({
  isExpanded
}) => {
  const [searchParams] = useSearchParams();

  const {
    t
  } = useTranslation();

  const {
    active,
    available,
    deactivated,
    isLoading,
    handleShowMore,
    resetPage,
    isMoreActive,
    isMoreDeactivated,
    isMoreAvailable,
    onActiveQuestionUpdate,
    isActiveFetching,
    isAvailableFetching,
    isDeactivatedFetching,
  } = useGetQuestions();

  const {
    isUpdateModalOpen, updatedCard, toggleUpdateModal
  } = useUpdateModal();

  const isFavoriteQuestions = useMemo(
    () => searchParams.get(topicId) === FAVORITE,
    [searchParams],
  );

  const activeTab = useMemo(
    () => searchParams.get(questionsFilterBy) || FilterBy.Active,
    [searchParams],
  ) as FilterBy;

  const isFiltersApplied = useMemo(
    () => !!searchParams.get(questionsTimeRange),
    [searchParams],
  );

  const isExtendedTable = activeTab !== FilterBy.Available;

  const visibleQuestions = useMemo(
    () => {
      switch (activeTab) {
        case FilterBy.Active:
          return {
            rows: active,
            hasMore: isMoreActive,
            isPagination: isMoreActive || active.length > MAX_ROWS,
          };
        case FilterBy.Deactivated:
          return {
            rows: deactivated,
            hasMore: isMoreDeactivated,
            isPagination: isMoreDeactivated || deactivated.length > MAX_ROWS,
          };
        case FilterBy.Available:
        default:
          return isFavoriteQuestions
            ? {
              rows: [],
              hasMore: false,
              isPagination: false,
            }
            : {
              rows: available,
              hasMore: isMoreAvailable,
              isPagination: isMoreAvailable || available.length > MAX_ROWS,
            };
      }
    },
    [
      activeTab,
      active,
      deactivated,
      available,
      isMoreAvailable,
      isMoreDeactivated,
      isMoreActive,
    ]
  );

  const isFetching = isActiveFetching || isAvailableFetching || isDeactivatedFetching;

  const {
    rows, isPagination, hasMore
  } = visibleQuestions;

  const columnWidths = getColumnWidths(
    isExpanded,
    isExtendedTable
  );

  const columnHeaders = getColumnHeaders(isExtendedTable);

  useEffect(
    () => {
      resetPage();
    },
    [activeTab]
  );

  const buttonIcon = hasMore ? <ArrowDownIcon /> : <ArrowUpIcon />;

  const buttonIconContent = hasMore && isFetching ? (
    <Spinner
      size={24}
      color="inherit"
    />
  ) : (
    buttonIcon
  );

  const shouldExpand = isPagination && !isLoading;

  return (
    <Style.MainContainer>
      <Style.TableWrapper $shouldExpand={shouldExpand}>
        <Style.Header>
          <TableHeaderTabs />
        </Style.Header>

        {isLoading ? (
          <Spinner />
        ) : (
          <>
            {!rows.length && (
              <>
                {isFiltersApplied && <EmptyResultsMessage isTable />}

                {!isFiltersApplied && (
                  <Style.EmptyQuestionsMessage>
                    {t('empty.thereAreNoQuestions')}
                  </Style.EmptyQuestionsMessage>
                )}
              </>
            )}

            {!!rows.length && (
              <Style.ScrollWrapper className="scroll-hidden">
                <Style.TableContainer $applyMinWidth={isExtendedTable}>
                  <Style.TableHead>
                    <tr>
                      {columnHeaders.map(({
                        id, label
                      }, index) => (
                        <Style.ColumnHeader
                          key={id}
                          $width={columnWidths[index]}
                        >
                          {label}
                        </Style.ColumnHeader>
                      ))}
                    </tr>
                  </Style.TableHead>

                  <tbody className="overflow-auto">
                    {rows.map((row) => (
                      <TableRow
                        key={row.id}
                        onUpdate={toggleUpdateModal}
                        row={row}
                        isExtendedTable={isExtendedTable}
                      />
                    ))}
                  </tbody>
                </Style.TableContainer>
              </Style.ScrollWrapper>
            )}
          </>
        )}
      </Style.TableWrapper>

      {shouldExpand && (
        <ActionButton
          onClick={() => (hasMore ? handleShowMore() : resetPage())}
          buttonTitle={hasMore ? t('buttons.showMore') : t('buttons.hide')}
          buttonIcon={buttonIconContent}
          buttonWithBorder={false}
          className="w-full p-2 mt-2"
        />
      )}

      {isUpdateModalOpen && updatedCard && (
        <ModalUpdateForecast
          updatedCard={updatedCard}
          closeModalHandler={() => toggleUpdateModal()}
          isModalOpen={isUpdateModalOpen}
          refetchQuestions={() => onActiveQuestionUpdate(updatedCard.id)}
        />
      )}
    </Style.MainContainer>
  );
};
