import React, {
  FC
} from 'react';
import {
  useParams
} from 'react-router-dom';
import {
  useTranslation
} from 'react-i18next';

import {
  useGetOrganizationQuery
} from 'src/redux/openapi';
import {
  CreateEntityLayout,
  CreateEntityContent,
  PageLayout,
  SetOnboardingTextList,
} from 'src/widgets';
import {
  Stepper
} from 'src/shared/components';
import {
  Breadcrumbs, EditQuestionsList
} from 'src/features';
import {
  ModalSaveEditQuestions
} from 'src/features/EditQuestions/ui';

import {
  GroupList
} from './ui';
import {
  getBreadcrumbsRoutes, Steps
} from './utils';
import {
  useEditQuestionsInBulk
} from './hooks';
import * as Style from './EditOrgQuestionsInBulk.styles';

export const EditOrgQuestionsInBulk: FC = () => {
  const params = useParams();

  const {
    t
  } = useTranslation();

  const {
    organizationId = ''
  } = params;

  const {
    data: orgData
  } = useGetOrganizationQuery(
    {
      id: organizationId,
    },
    {
      skip: !organizationId,
      refetchOnMountOrArgChange: true,
    },
  );

  const breadcrumbsRoutes = getBreadcrumbsRoutes(
    orgData?.data.organization.name,
  );

  const {
    questionSets,
    isDataLoading,
    selectConfig,
    nextStepButtonConfig,
    onNextStepClick,
    questionsOnboardingText,
    onTextChange,
    completedStatus,
    selectedGroups,
    setSelectedGroups,
    activeStepId,
    steps,
    isAdding,
    isSavingInProcess,
    deactivationList,
    handleProceed,
    clearDeactivationList,
  } = useEditQuestionsInBulk();

  return (
    <>
      <PageLayout>
        <CreateEntityLayout variant={isAdding ? 'large' : 'medium'}>
          <CreateEntityContent>
            <Breadcrumbs routes={breadcrumbsRoutes} />

            <Stepper
              steps={steps}
              activeStepId={activeStepId}
              completedStatus={completedStatus}
              nextStepButtonTitle={nextStepButtonConfig.title}
              nextStepButtonIcon={nextStepButtonConfig.icon}
              handleNextStep={onNextStepClick}
              isLoading={isSavingInProcess && !deactivationList}
            />

            {activeStepId === Steps.ChooseQuestions && (
              <>
                {!isAdding && !!questionSets.length && (
                  <Style.RemoveTextWrapper>
                    <Style.RemoveTextTitle>
                      {t('question.removeQuestions')}
                    </Style.RemoveTextTitle>

                    <Style.RemoveTextSubtitle>
                      {t('question.chooseQuestionToDelete')}
                    </Style.RemoveTextSubtitle>
                  </Style.RemoveTextWrapper>
                )}

                <EditQuestionsList
                  isQuestionsLoading={isDataLoading}
                  preparedSets={questionSets}
                  selectConfig={selectConfig}
                  isRemoving={!isAdding}
                />
              </>
            )}

            {activeStepId === Steps.SetOnboardingText && (
              <SetOnboardingTextList
                questions={questionsOnboardingText}
                onTextChange={onTextChange}
                isLoading={isDataLoading}
              />
            )}

            {activeStepId === Steps.ChooseGroups && (
              <GroupList
                setSelectedGroups={setSelectedGroups}
                selectedIds={selectedGroups}
                organizationId={organizationId}
              />
            )}
          </CreateEntityContent>
        </CreateEntityLayout>
      </PageLayout>

      <ModalSaveEditQuestions
        closeModalHandler={clearDeactivationList}
        backButtonHandler={clearDeactivationList}
        handleProceed={handleProceed}
        isOpen={!!deactivationList}
        deactivatedList={deactivationList}
        isProceedInProcess={isSavingInProcess && !!deactivationList}
      />
    </>
  );
};
