import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query';
import qs from 'qs';

const initialQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_PATH,
  credentials: 'include',
  paramsSerializer: (params) => {
    return qs.stringify(
      params,
      {
        arrayFormat: 'brackets',
      }
    );
  },
  timeout: 10000,
});

type BaseQuery = BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>;

const baseQuery: BaseQuery = async (args, api, extraOptions) => {
  const result = await initialQuery(
    args,
    api,
    extraOptions
  );

  return result;
};

export {
  baseQuery
};
