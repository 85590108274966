import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const ImageContainer = styled.img<{
  size: number;
  $isClickable?: boolean;
  $isLogo?: boolean;
}>`
  ${tw`object-center`}

  ${({
    size
  }) => `
    width: ${size}px;
    height: ${size}px;
  `}

  ${({
    $isClickable
  }) => $isClickable && tw`cursor-pointer`}

  ${({
    $isLogo
  }) => ($isLogo ? tw`rounded object-contain` : tw`rounded-full object-cover`)}
`;

export const EmptyContainer = styled.div<{
  size: number;
  $isClickable?: boolean;
  $isLogo?: boolean;
}>`
  ${tw`
    flex items-center justify-center bg-alice-blue border border-input-blue 
    font-medium text-black
  `}

  ${({
    size
  }) => `
    width: ${size}px;
    height: ${size}px;
    font-size: ${size / 2 - 3}px;
  `}

  ${({
    $isClickable
  }) => $isClickable && tw`cursor-pointer`}

  ${({
    $isLogo
  }) => ($isLogo ? tw`rounded` : tw`rounded-full`)}
`;
