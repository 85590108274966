import {
  t
} from 'i18next';

import {
  AnswerStatus
} from 'src/shared/api/notifications/types';

export const getAnswerStatus = (
  status: AnswerStatus,
  withConfirmVariant = false,
) => {
  switch (status) {
    case AnswerStatus.ACCEPTED:
      return withConfirmVariant
        ? t('answerStatus.confirm')
        : t('answerStatus.approved');

    case AnswerStatus.REJECTED:
      return withConfirmVariant
        ? t('answerStatus.declined')
        : t('answerStatus.canceled');

    default:
      return null;
  }
};
