import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const ContentContainer = styled.div`
  ${tw`flex gap-4 h-full overflow-y-auto`}

  max-height: calc(100vh - 24px);
`;

export const Divider = styled.div`
  ${tw`w-px h-full bg-gray94`}
`;

export const TooltipContainer = styled.div`
  ${tw`text-center text-xs leading-5`}
`;

export const SectionTitle = styled.p`
  ${tw`text-gray7 font-bold text-22-30`}
`;

export const SectionSubtitle = styled.p`
  ${tw`text-dim-gray font-medium text-15-20`}
`;

export const CountSpan = styled.span<{ $isDark?: boolean }>`
  ${tw`text-dim-gray font-bold text-17-26`}

  ${({
    $isDark
  }) => $isDark && tw`text-gray7`}
`;

export const CenteredRowContainer = styled.div<{ $gap?: number }>`
  ${tw`flex justify-between items-center`}

  ${({
    $gap
  }) => $gap && `gap: ${$gap}px;`}
`;

export const FiltersCount = styled.div`
  ${tw`absolute -top-0.5 right-0 px-2 py-0.5 rounded-100px bg-button-blue text-white text-center text-xs font-medium`}
`;

export const SectionHeader = styled.div<{ $grow?: boolean }>`
  ${tw`flex flex-col gap-1`}

  ${({
    $grow
  }) => $grow && tw`grow`}
`;

export const SectionContainer = styled.div`
  ${tw`flex flex-col flex-1 gap-6`}
`;

export const UsersToAddContainer = styled.div`
  ${tw`max-h-screen overflow-y-auto`}
`;

export const FilterButtons = styled.button<{ $isSelected: boolean }>`
  ${tw`rounded p-2 text-17-26 font-medium text-left`}

  ${({
    $isSelected
  }) => ($isSelected
    ? tw`text-button-blue bg-alice-blue`
    : tw`text-dark-gray bg-white`)}
`;

export const FilterContainer = styled.div`
  ${tw`p-2 rounded shadow-menu-shadow`}
`;

export const FilterPopoverContainer = styled.div`
  ${tw`relative p-0.5`}
`;

export const FilterBlock = styled.div`
  ${tw`flex flex-col gap-1`}
`;

export const FilterTitle = styled.div`
  ${tw`px-2 py-1 text-13-21 font-medium text-dark-gray`}
`;
