import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MainContainer = styled.div`
  ${tw`px-3 py-7px mb-6 rounded border border-gray94`}
`;

export const Title = styled.p`
  ${tw`font-semibold leading-6 text-black mb-1`}
`;

export const Description = styled.div`
  ${tw`flex gap-4 mb-4`}
`;

export const DescriptionItem = styled.div`
  ${tw`flex gap-1 items-center text-xs font-medium leading-5`}
`;

export const Circle = styled.div<{ $color: string }>`
  ${tw`rounded-full w-2 h-2`}

  ${({
    $color
  }) => `background-color: ${$color};`}
`;

export const Chart = styled.div`
  ${tw`flex gap-4`}
`;

export const ChartItem = styled.div`
  ${tw`flex-1 flex flex-col gap-1 items-center`}
`;

export const TotalValue = styled.p`
  ${tw`z-10 text-13-21 font-medium text-center`}
`;

export const ItemDescription = styled.p`
  ${tw`font-semibold leading-6`}
`;

export const ParentColumn = styled.div<{ $color: string }>`
  ${tw`h-145px w-10 pt-1 relative rounded-t-10px rounded-b flex justify-center`}

  ${({
    $color
  }) => `background-color: ${$color};`}
`;

export const ChildColumn = styled.div<{ $color: string; $height: number }>`
  ${tw`rounded-t-10px w-8 absolute bottom-0 left-1/2 -translate-x-1/2 transition-all duration-700 ease-in-out`}

  ${({
    $color, $height
  }) => `
    background-color: ${$color};
    height: ${$height}px;
  `}
`;
