import dayjs from 'dayjs';
import 'dayjs/locale/de';
import React, {
  FC, useState
} from 'react';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import {
  useXAxis
} from 'src/entities/Graph/hooks';
import {
  GraphDot,
  GraphLegend,
  GraphTick,
  GraphTooltip,
  TooltipCursor,
} from 'src/entities/Graph/ui';
import {
  useTypedSelector
} from 'src/redux';
import {
  timeSpanSelectors
} from 'src/redux/timeSpans';
import {
  DAY,
  forecastsColorValues,
  getCapacitySingle,
  themeColors,
} from 'src/shared/utils';
import {
  ChartData
} from 'src/redux/openapi';
import {
  languageSelectors
} from 'src/redux/language';
import {
  useMediaQuery
} from 'src/shared/hooks';

import {
  MY_FORECAST, OWN_PREDICTION, TOTAL_PREDICTION
} from './constants';

interface SingleValueGraphProps {
  isExpanded?: boolean;
  filteredLines: Record<string, boolean>;
  preparedData: ChartData[];
  handleChangeFilters: (key: string) => void;
  valuesToGraph: string[];
  isAverageForecastShow: boolean;
  isMyForecastShow: boolean;
}

type LineData = {
  [key: string]: boolean;
} & {
  own?: boolean;
};

export const SingleValueGraph: FC<SingleValueGraphProps> = ({
  isExpanded,
  filteredLines,
  preparedData,
  handleChangeFilters,
  valuesToGraph,
  isAverageForecastShow,
  isMyForecastShow,
}) => {
  const currentLanguage: string = useTypedSelector(
    languageSelectors.getLanguage,
  );

  const isMobile = useMediaQuery(
    'md',
    'down'
  );

  const selectedTimeSpan = useTypedSelector(
    timeSpanSelectors.selectActiveTimeSpan,
  );

  if (!selectedTimeSpan) {
    return null;
  }

  const timelineTicks = preparedData.map((item) => item.date);
  const minDateValue = timelineTicks[0];
  const maxDateValue = timelineTicks[timelineTicks.length - 1];

  const timelineConfig = {
    startDate: dayjs(minDateValue).locale(currentLanguage).toDate().getTime(),
    endDate: dayjs(maxDateValue).locale(currentLanguage).toDate().getTime(),
    timeGap: DAY,
  };

  const {
    scrollDistance, isOverflown, setScrollDistance, minDate, maxDate
  } = useXAxis({
    timelineConfig,
    defaultScrollDistance: 1,
    capacity: getCapacitySingle(selectedTimeSpan.value),
  });

  const xAxisTimeGap = timelineConfig.timeGap;

  const linesWithUserId: LineData = {
    ...filteredLines,
    [OWN_PREDICTION]: isMyForecastShow,
  };

  const domainSpaceGap = isMobile ? 3 : 2;

  const [legendHeight, setLegendHeight] = useState<number>(0);

  const handleSetLegendHeight = (height: number) => {
    setLegendHeight(height);
  };

  return (
    <ResponsiveContainer
      width="100%"
      height={423 + legendHeight}
    >
      <ComposedChart
        height={100}
        width={31}
        data={preparedData}
        margin={{
          top: 10,
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <Legend
          content={(
            <GraphLegend
              state={linesWithUserId}
              onChange={handleChangeFilters}
              withScroll={isOverflown}
              scrollDistance={scrollDistance}
              onScroll={setScrollDistance}
              setHeight={handleSetLegendHeight}
            />
          )}
        />

        <CartesianGrid
          stroke={themeColors['gray-whisper']}
          strokeWidth={1}
        />

        {valuesToGraph.map((lineValue, i) => (
          <Line
            key={lineValue}
            name={lineValue}
            unit="%"
            hide={!linesWithUserId[lineValue]}
            dataKey={`${lineValue}.total`}
            stroke={forecastsColorValues[i]?.forecastColor || 'red'}
            strokeWidth={2}
            connectNulls
            yAxisId="estimationPercentsAxis"
            isAnimationActive={false}
            dot={<GraphDot r={2} />}
            activeDot={(
              <GraphDot
                r={2}
                stroke={forecastsColorValues[i]?.forecastColor || 'red'}
              />
            )}
            type="stepAfter"
          />
        ))}

        <YAxis
          yAxisId="votesCountAxis"
          orientation="right"
          tickCount={6}
          interval={0}
          tickLine={false}
          axisLine={false}
          width={0}
          tick={<GraphTick />}
        />

        <Area
          dataKey={TOTAL_PREDICTION}
          unit="%"
          name="Total average"
          yAxisId="estimationPercentsAxis"
          type="linear"
          stroke={themeColors.lineArea}
          fill={themeColors.lineArea}
          fillOpacity={0}
          strokeLinecap="round"
          strokeDasharray="6 6"
          strokeWidth={2}
          isAnimationActive={false}
          dot={<GraphDot r={9} />}
          activeDot={false}
          hide={!isAverageForecastShow}
        />

        <Line
          name={MY_FORECAST}
          unit="%"
          hide={!isMyForecastShow}
          dataKey={OWN_PREDICTION}
          stroke={themeColors.myForecast}
          strokeWidth={2}
          connectNulls
          yAxisId="estimationPercentsAxis"
          isAnimationActive={false}
          dot={<GraphDot r={2} />}
          activeDot={(
            <GraphDot
              r={2}
              stroke={themeColors.myForecast}
            />
          )}
          type="stepAfter"
        />

        <XAxis
          dataKey="date"
          type="number"
          tickFormatter={(number) => dayjs(number).locale(currentLanguage).format('MMM D')}
          allowDataOverflow
          domain={[
            minDate
              ? minDate - xAxisTimeGap / domainSpaceGap
              : `dataMin - ${DAY}`,
            maxDate
              ? maxDate + xAxisTimeGap / domainSpaceGap
              : `dataMax + ${DAY}`,
          ]}
          interval={0}
          axisLine={false}
          tickLine={false}
          tick={(
            <GraphTick
              vertical
              date
              textAnchor="middle"
            />
          )}
          {...(timelineTicks && {
            ticks: timelineTicks,
          })}
        />

        <YAxis
          tickCount={6}
          yAxisId="estimationPercentsAxis"
          orientation="left"
          interval={0}
          tickLine={false}
          axisLine={false}
          width={30}
          tick={<GraphTick textAnchor="end" />}
          type="number"
          domain={[0, 100]}
        />

        <Tooltip
          content={<GraphTooltip />}
          cursor={<TooltipCursor />}
          wrapperStyle={{
            outline: 'none',
            zIndex: 99,
          }}
          position={{
            y: isMobile ? undefined : 0,
          }}
          offset={isMobile ? undefined : 0}
          allowEscapeViewBox={{
            x: isMobile ? false : isExpanded,
            y: !isMobile,
          }}
          isAnimationActive
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};
