import React, {
  FC, useEffect, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  Button,
  EmailInviteButton,
  ScrollContainer,
  Spinner,
  successfulToast,
} from 'src/shared/components';
import {
  LinkIcon
} from 'src/shared/icons';
import {
  usePostGroupUsersInvitesLinksMutation,
  usePostGroupUsersInvitesResendMutation,
} from 'src/redux/openapi';
import {
  showWarningFromServer, themeColors
} from 'src/shared/utils';

import {
  InvitedEmailBlock
} from '../../InvitedEmailBlock';
import {
  useGetGroupInvites
} from '../../hooks';

import * as Style from './GroupInvites.styles';

interface GroupInvitesProps {
  groupId: string;
  refetchInvitedCount: () => void;
  searchQuery?: string;
}

export const GroupInvites: FC<GroupInvitesProps> = ({
  groupId,
  refetchInvitedCount,
  searchQuery,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const {
    t
  } = useTranslation();

  const {
    invites,
    sentryRef,
    displayLoader,
    removeUserInvitation,
    onInvitedListUpdate,
  } = useGetGroupInvites({
    id: groupId,
    onInvitesUpdate: refetchInvitedCount,
    onDeleteSuccess: refetchInvitedCount,
    searchQuery,
  });

  const [resendInvitation, {
    isLoading: isResendLoading
  }] = usePostGroupUsersInvitesResendMutation();

  const handleResendInvitations = async () => {
    if (!isResendLoading) {
      try {
        const response = await resendInvitation({
          id: groupId,
        }).unwrap();

        successfulToast(response.message);
      } catch (error) {
        showWarningFromServer(error);
      }
    }
  };

  const [getInvitesLink, {
    isLoading: isGetInvitesLinkLoading
  }] = usePostGroupUsersInvitesLinksMutation();

  const handleCreateLink = async () => {
    if (!isGetInvitesLinkLoading) {
      try {
        const response = await getInvitesLink({
          id: groupId,
        }).unwrap();

        successfulToast(response.message);

        navigator.clipboard.writeText(response.data.link);
        setShowTooltip(true);
      } catch (error) {
        showWarningFromServer(error);
      }
    }
  };

  useEffect(
    () => {
      const timeToEnd = showTooltip ? 1200 : 0;

      const timer = setTimeout(
        () => {
          if (showTooltip) {
            setShowTooltip(false);
          }
        },
        timeToEnd
      );

      return () => clearTimeout(timer);
    },
    [showTooltip]
  );

  return (
    <>
      <Style.MainContainer>
        <Style.InviteTitle>{t('group.invite')}</Style.InviteTitle>

        <Style.HeadContainer>
          <Button
            variant="invite-grey"
            className="flex-1 gap-1 relative"
            onClick={handleCreateLink}
          >
            <Style.InviteEmailText>
              {t('buttons.createLink')}
            </Style.InviteEmailText>

            {isGetInvitesLinkLoading ? (
              <Spinner
                color={themeColors['dark-gray']}
                size={24}
                centered={false}
              />
            ) : (
              <LinkIcon className="text-dark-gray" />
            )}

            {showTooltip && (
              <Style.TooltipInvited>
                {t('group.linkCopiedExpiresIn3days')}

                <Style.TooltipInvitedAfter />
              </Style.TooltipInvited>
            )}
          </Button>

          <EmailInviteButton
            refetchUsers={onInvitedListUpdate}
            groupId={groupId}
          />
        </Style.HeadContainer>
      </Style.MainContainer>

      <Style.LinkContainer>
        <Style.InviteTitle>{t('group.invitedEmails')}</Style.InviteTitle>

        <Style.UnderlinedTextButton onClick={handleResendInvitations}>
          {t('group.sendRemindersToUsers')}
        </Style.UnderlinedTextButton>
      </Style.LinkContainer>

      {invites.length > 0 && (
        <ScrollContainer>
          {invites.map((person, i) => {
            return (
              <InvitedEmailBlock
                key={person.email}
                person={person}
                index={i + 1}
                removeUserInvitation={removeUserInvitation}
              />
            );
          })}

          {displayLoader && (
            <div ref={sentryRef}>
              <Spinner size={24} />
            </div>
          )}
        </ScrollContainer>
      )}
    </>
  );
};
