import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MenuContainer = styled.div`
  ${tw`flex flex-col gap-1`}
`;

export const MenuTitle = styled.div`
  ${tw`text-13 font-medium text-dark-gray px-2 py-1`}
`;

export const MenuList = styled.div`
  ${tw`flex flex-col gap-1`}
`;

export const MenuCheckboxItem = styled.button`
  ${tw`flex items-center justify-between p-2 text-17-26 font-medium`}
`;

export const MenuItem = styled.button<{ $isActive?: boolean }>`
  ${tw`flex gap-2 text-17-26 font-medium p-2 transition`}

  ${({
    $isActive
  }) => $isActive && tw`bg-alice-blue text-button-blue`}
`;

export const TimeRange = styled.p`
  ${tw`text-grey-dark-40`}
`;

export const ItemTitle = styled.div`
  ${tw`flex items-center gap-1`}
`;
