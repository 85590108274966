import React, {
  FC, useState, useEffect, useRef, useMemo
} from 'react';
import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import dayjs from 'dayjs';
import {
  useTranslation
} from 'react-i18next';

import {
  ArrowDownIcon,
  ArrowUpIcon,
  EllipseLoaderIcon,
  MessageIcon,
  TrendingDownIcon,
  TrendingUpIcon,
} from 'src/shared/icons';
import {
  Avatar, Button
} from 'src/shared/components';
import {
  commentID,
  getIsNewComment,
  getTimeAgo,
  commentSearch,
  ROUTE,
  getUserTimeZone,
  followings,
} from 'src/shared/utils';
import {
  Comment, ReplyComment
} from 'src/redux/openapi';
import {
  useTypedSelector
} from 'src/redux';
import {
  userSelectors
} from 'src/redux/user';
import {
  ActivityButton
} from 'src/entities/YourFeed/components';

import * as Style from './CommentListItem.styles';
import './index.css';
import {
  getHighlightedText
} from './getHighlightedText';
import {
  getExtraCommentData
} from './utils';
import {
  CommentItemHeader
} from './CommentItemHeader';
import {
  CommentLikeButton
} from './CommentLikeButton';

const ICON_SIZE = 20;

interface CommentListItemProps {
  comment: Comment | ReplyComment;
  extended?: boolean;
  withDivider?: boolean;
  refetch: () => void;
  groupRemoveAccess: boolean;
}

export const CommentListItem: FC<CommentListItemProps> = ({
  comment,
  extended = false,
  withDivider = true,
  refetch,
  groupRemoveAccess,
}) => {
  const [isAllCommentsVisible, setIsAllCommentsVisible] = useState(false);

  const {
    t
  } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const location = useLocation();

  const params = useParams();

  const {
    id: userId, role: userRole
  } = useTypedSelector(userSelectors.user);

  const {
    author, text: content, id, createdAt
  } = comment;

  const extraCommentData = useMemo(
    () => getExtraCommentData(comment),
    [comment],
  );

  const {
    comments, commentsCount, question, forecast, estimatesDiff
  } = extraCommentData;

  const userTimeZone = getUserTimeZone();
  const createdAtUserZone = dayjs.utc(createdAt).tz(userTimeZone);
  const formattedDate = createdAtUserZone.format();

  const commentIdToReply = searchParams.get(commentID);
  const searchQuery = searchParams.get(commentSearch) || '';

  const onCommentsExpand = () => {
    if (!commentIdToReply || commentIdToReply !== id) {
      searchParams.set(
        commentID,
        id
      );

      setSearchParams(searchParams);
    } else {
      searchParams.delete(commentID);
      setSearchParams(searchParams);
    }
  };

  const showAllComments = () => setIsAllCommentsVisible((prev) => !prev);

  const isNewComment = getIsNewComment(Date.parse(formattedDate));

  const isTodayComment = getIsNewComment(
    Date.parse(formattedDate),
    1
  );

  const [isLoader, setIsLoader] = useState(isNewComment);

  useEffect(
    () => {
      const timer = setTimeout(
        () => {
          if (isLoader) {
            setIsLoader(false);
          }
        },
        3000
      );

      return () => clearTimeout(timer);
    },
    []
  );

  const itemRef = useRef<HTMLElement | null>(null);

  useEffect(
    () => {
      if (itemRef && itemRef.current && isLoader) {
        itemRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }
    },
    [itemRef, isLoader]
  );

  const createdAtDateAndMonth = createdAtUserZone.format('DD.MM');
  const createdAtTime = createdAtUserZone.format('HH:mm');

  const isFollowingsPage = searchParams.get(followings);

  const isCommunityPage = location.pathname.startsWith(`/${ROUTE.COMMUNITY}`) && !isFollowingsPage;

  const isQuestionTitleNeeded = question && !isCommunityPage && !params.id;

  return (
    <Style.CommentContainer
      $isNew={isNewComment}
      $isNotExtended={!extended}
      $withDivider={withDivider}
      ref={itemRef}
    >
      <Style.AvatarContainer>
        <Avatar
          src={author.avatarUrl}
          size={!extended ? 40 : 24}
          userName={author.displayName || author.name}
        />
      </Style.AvatarContainer>

      <Style.ContentContainer>
        <CommentItemHeader
          author={author}
          isAdmin={userRole === 'ADMIN' || groupRemoveAccess}
          onCommentDelete={refetch}
          userId={userId}
          commentId={id}
        />

        {isQuestionTitleNeeded && (
          <Link
            to={`/${ROUTE.QUESTION}/${question.id}`}
            state={{
              isOnboarding: !question.hasUserOnboarded,
            }}
          >
            <Style.Question>
              <Style.QuestionTitle>{question.title}</Style.QuestionTitle>
            </Style.Question>
          </Link>
        )}

        {forecast && (
          <Style.ForecastContainer>
            {forecast.estimates.map((option) => {
              const isEstimatesDiff = typeof estimatesDiff === 'number';

              return (
                <div key={option.title}>
                  {forecast && (
                    <Style.ForecastOption>
                      {forecast.estimates.length > 1
                        ? option.title
                        : t('comment.forecastUpdate')}
                    </Style.ForecastOption>
                  )}

                  <Style.ForecastDataContainer>
                    <div>
                      <Style.SpanForecastData $isBlack>
                        {`${option.value}% `}
                      </Style.SpanForecastData>

                      <Style.SpanForecastData>
                        {isEstimatesDiff
                          && ` ${
                            estimatesDiff <= 0 ? '' : '+'
                          }${estimatesDiff}%/24 ${t('date.hours')}`}
                      </Style.SpanForecastData>
                    </div>

                    {isEstimatesDiff && (
                      <div>
                        {estimatesDiff < 0 ? (
                          <TrendingDownIcon className="text-red-text-error" />
                        ) : (
                          <TrendingUpIcon className="text-forest-green" />
                        )}
                      </div>
                    )}
                  </Style.ForecastDataContainer>
                </div>
              );
            })}
          </Style.ForecastContainer>
        )}

        <Style.CommentContent>
          {getHighlightedText(
            content,
            searchQuery
          )}
        </Style.CommentContent>

        <Style.CenteredContainer>
          <Style.CenteredContainer $gap={12}>
            <CommentLikeButton
              isExtended={extended}
              comment={comment}
              refetchComments={refetch}
            />

            {!extended && comments && (
              <ActivityButton
                icon={MessageIcon}
                title={comments.length}
                isActive={commentIdToReply === `${id}`}
                onClick={onCommentsExpand}
              />
            )}
          </Style.CenteredContainer>

          <Style.CenteredContainer $gap={4}>
            {!isLoader && isTodayComment && (
              <Style.CreatedAtText $isToday={isTodayComment}>
                {`${getTimeAgo(Date.parse(formattedDate))} ${t('date.ago')}`}
              </Style.CreatedAtText>
            )}

            {!isLoader && !isTodayComment && (
              <>
                <Style.CreatedAtText>
                  {createdAtDateAndMonth}
                </Style.CreatedAtText>

                <Style.CreatedAtText>{createdAtTime}</Style.CreatedAtText>
              </>
            )}

            {isLoader && (
              <>
                <EllipseLoaderIcon className="text-dim-gray animate-spin" />

                <Style.CreatedAtText $isToday={isTodayComment}>
                  {t('common.loading')}
                </Style.CreatedAtText>
              </>
            )}
          </Style.CenteredContainer>
        </Style.CenteredContainer>

        {commentIdToReply === id && comments && !!comments.length && (
          <Style.ExpandedContainer>
            <div>
              {isAllCommentsVisible
                ? comments.map((extendedComment) => (
                  <CommentListItem
                    key={extendedComment.id}
                    comment={extendedComment}
                    extended
                    refetch={refetch}
                    groupRemoveAccess={groupRemoveAccess}
                  />
                ))
                : comments.slice(
                  0,
                  2
                ).map((extendedComment) => (
                  <CommentListItem
                    key={extendedComment.id}
                    comment={extendedComment}
                    extended
                    refetch={refetch}
                    groupRemoveAccess={groupRemoveAccess}
                  />
                ))}
            </div>

            {comments && commentsCount > 2 && (
              <Button onClick={showAllComments}>
                <Style.ButtonContent>
                  {!isAllCommentsVisible ? (
                    <>
                      {t('buttons.showAll')}

                      <ArrowDownIcon
                        width={ICON_SIZE}
                        height={ICON_SIZE}
                      />
                    </>
                  ) : (
                    <>
                      {t('buttons.hide')}

                      <ArrowUpIcon
                        width={ICON_SIZE}
                        height={ICON_SIZE}
                      />
                    </>
                  )}
                </Style.ButtonContent>
              </Button>
            )}
          </Style.ExpandedContainer>
        )}
      </Style.ContentContainer>
    </Style.CommentContainer>
  );
};
