import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

const TOP_PADDING = '17px';
const BOTTOM_MARGIN = '31px';

export const SectionsContainer = styled.div<{
  $shift: number;
  $isComplete: boolean;
}>`
  ${tw`grid gap-6`}

  padding-top: ${TOP_PADDING};
  margin-bottom: ${BOTTOM_MARGIN};

  ${({
    $shift
  }) => `grid-template-rows: minmax(620px, calc(100vh - ${$shift}px - ${TOP_PADDING} - ${BOTTOM_MARGIN}));`}

  ${({
    $isComplete
  }) => ($isComplete
    ? tw`grid-cols-[1.5fr,minmax(440px,1fr),320px]`
    : tw`grid-cols-2`)}
`;
