import {
  createSlice
} from '@reduxjs/toolkit';

import {
  arqApiGroup, Group
} from 'src/redux/openapi';
import {
  authApi, groupsApi, usersApi
} from 'src/shared/api';

const initialState: Group[] = [];

export interface GroupReducer {
  groups: Group[];
}

export const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      groupsApi.endpoints.getAllGroups.matchFulfilled,
      (state, {
        payload
      }) => payload.data.groups,
    );

    builder.addMatcher(
      arqApiGroup.endpoints.getApiGroups.matchFulfilled,
      (state, {
        payload
      }) => payload.data.groups,
    );

    builder.addMatcher(
      authApi.endpoints.signOut.matchFulfilled,
      () => initialState,
    );

    builder.addMatcher(
      usersApi.endpoints.getCurrentUser.matchRejected,
      () => initialState,
    );
  },
});

export const groupsReducer = groupsSlice.reducer;
