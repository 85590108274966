export const themeColors = {
  'dark-blue': '#272F51',
  'gulf-blue': '#2E3A59',
  'light-blue': 'var(--color-primary-60)',
  white: '#FFFFFF',
  cultured: '#F7F7F7',
  'ghost-white': '#F8F9FC',
  'form-border': '#E4E4E7',
  'forest-green': '#3D9916',
  black: '#000000',
  'eerie-black': '#1F1F1F',
  'silver-gray': '#ACACAC',
  'light-grayish-blue': 'var(--color-primary-bg)',
  'light-grey': '#D0D0D0',
  'grey-dark-40': '#A0A0A0',
  'dim-gray': '#717171',
  gray94: '#F0F0F0',
  gray90: '#E5E5E5',
  gray98: '#FAFAFA',
  gray85: '#D9D9D9',
  gray7: '#121212',
  'gray-whisper': '#EAEAEA',
  'dark-gray': '#414141',
  'alice-blue': 'var(--color-primary-05)',
  lotion: '#FDFDFD',
  'input-border-blue': 'var(--color-primary-40)',
  'input-blue': 'var(--color-primary-10)',
  'button-blue': 'var(--color-primary)',
  'red-label': '#E74949',
  'red-text-error': '#E0215A',
  'red-border-error': '#FDD3E0',
  'red-bg-error': '#FDF4F7',
  'orange-alert': '#F57024',
  'pale-blue': '#FAFCFF',
  'list-blue': 'var(--color-primary-20)',
  'brownish-yellow': '#D7B166',
  'golden-yellow': '#F8D072',
  beige: '#F1E4CA',
  accent80: 'var(--color-primary-80)',
  myForecast: '#C82323',
  lineArea: '#E1E1E1',
  amazonite: '#00C2B8',
  'venus-violet': '#756DB6',
  'palm-green': '#F3F6F1',
  'slate-gray': '#6C809A',
  independence: '#4A4766',
};
