import React, {
  FC, useCallback, useEffect, useMemo, useState
} from 'react';
import {
  useNavigate, useSearchParams
} from 'react-router-dom';
import ms from 'ms';
import {
  useTranslation, Trans
} from 'react-i18next';

import {
  ArrowLeftIcon, CloseCircleFilledIcon
} from 'src/shared/icons';
import {
  PageLayout
} from 'src/widgets';
import {
  CircleButton,
  FilterPopoverMenu,
  ScrollContainer,
  SearchInput,
  Spinner,
} from 'src/shared/components';
import {
  NotificationsList,
  NotificationSettings,
  SortBy,
  FilterBy,
} from 'src/entities/NotificationsRoute';
import {
  DAY,
  WEEK,
  notificationTab,
  showWarningFromServer,
} from 'src/shared/utils';
import {
  useGetNotificationsMutation
} from 'src/shared/api/notifications/notificationsApi';
import {
  SortOrder
} from 'src/shared/api/types';
import {
  useTypedDispatch, useTypedSelector
} from 'src/redux';
import {
  addNotifications,
  addNotificationsData,
  notificationsSelector,
  resetUnread,
} from 'src/redux/notifications';
import {
  NotificationTab
} from 'src/shared/api/notifications/types';
import {
  useDebouncedValue
} from 'src/shared/hooks';
import {
  UnreadNotificationsCount
} from 'src/redux/openapi';

import * as Style from './NotificationsPage.styles';

enum NotificationsLinks {
  ALL = 'all',
  REPLIES = 'replies',
  GROUPS = 'groups',
  ACTIONS = 'actions',
}

type NotificationsLinksMap = {
  link: NotificationsLinks;
  countKey: keyof UnreadNotificationsCount;
}[];

const notificationLinks: NotificationsLinksMap = [
  {
    link: NotificationsLinks.ALL,
    countKey: NotificationsLinks.ALL,
  },
  {
    link: NotificationsLinks.GROUPS,
    countKey: NotificationsLinks.GROUPS,
  },
  {
    link: NotificationsLinks.REPLIES,
    countKey: 'reply',
  },
  {
    link: NotificationsLinks.ACTIONS,
    countKey: NotificationsLinks.ACTIONS,
  },
];

const getTimeFromFilter = (filter: FilterBy) => {
  switch (filter) {
    case FilterBy.TwoDays:
      return ms(2 * DAY);

    case FilterBy.Week:
      return ms(WEEK);

    default:
      return undefined;
  }
};

export const NotificationsPage: FC = () => {
  const [isNewOnly, setIsNewOnly] = useState(false);
  const [dateFilter, setDateFilter] = useState(FilterBy.AllTime);
  const [sortBy, setSortBy] = useState(SortBy.Newest);
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstLoading, setIsFirstLoading] = useState(true);

  const {
    t
  } = useTranslation();

  const debouncedQuery = useDebouncedValue(
    searchValue,
    500
  );

  const dispatch = useTypedDispatch();

  const navigate = useNavigate();

  const handleSetSearchValue = (value: string) => {
    setSearchValue(value);
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const filteredLink = useMemo(
    () => searchParams.get(notificationTab) || NotificationsLinks.ALL,
    [searchParams],
  );

  const handleSetActiveLink = (link: string) => {
    setSearchParams(
      {
        notificationTab: link,
      },
      {
        replace: true,
      },
    );
  };

  const handleNewOnly = () => setIsNewOnly((prev) => !prev);

  const {
    notifications, total, unreadCount
  } = useTypedSelector(
    notificationsSelector.selectNotificationsData,
  );

  const [getNotifications] = useGetNotificationsMutation();

  const loadNotifications = useCallback(
    async (page?: number) => {
      setIsLoading(true);

      if (!page) {
        setIsFirstLoading(true);
      }

      try {
        const response = await getNotifications({
          limit: 10,
          filters: {
            sortBy: 'createdAt',
            sortOrder:
              sortBy === SortBy.Oldest ? SortOrder.Asc : SortOrder.Desc,
            status: isNewOnly ? 'UNREAD' : undefined,
            actionRequired: sortBy === SortBy.Waiting ? true : undefined,
            from: getTimeFromFilter(dateFilter),
            tab:
              filteredLink === NotificationsLinks.ALL
                ? undefined
                : (filteredLink.toLowerCase() as NotificationTab),
            query: debouncedQuery,
          },
          page,
        }).unwrap();

        if (!page) {
          setCurrentPage(1);
          dispatch(addNotificationsData(response));
        } else {
          setCurrentPage((prev) => prev + 1);
          dispatch(addNotifications(response.notifications));
        }
      } catch (error) {
        showWarningFromServer(error);
      } finally {
        setIsFirstLoading(false);
        setIsLoading(false);
      }
    },
    [isNewOnly, dateFilter, sortBy, debouncedQuery, filteredLink],
  );

  useEffect(
    () => {
      loadNotifications();
    },
    [loadNotifications]
  );

  useEffect(
    () => {
      return () => {
        dispatch(resetUnread());
      };
    },
    []
  );

  const onLoadMore = async () => {
    if (isLoading || notifications.length >= total) {
      return;
    }

    await loadNotifications(currentPage + 1);
  };

  return (
    <PageLayout>
      <Style.MainContainer>
        <Style.HeaderContainer>
          <Style.HeaderTopContainer>
            <Style.HeaderTitleContainer>
              <CircleButton onClick={() => navigate(-1)}>
                <ArrowLeftIcon />
              </CircleButton>

              <Style.HeaderTitle>{t('common.notifications')}</Style.HeaderTitle>
            </Style.HeaderTitleContainer>

            <Style.HeaderButtonsContainer>
              <Style.InputContainer>
                <SearchInput
                  value={searchValue}
                  onChange={handleSetSearchValue}
                  placeholder={t('common.startTyping')}
                  withCloseButton
                  closeIconPosition="end"
                  customCloseIcon={CloseCircleFilledIcon}
                  closeIconClassName="text-gulf-blue w-6 h-6"
                  disableBlurring={!!searchValue}
                  shouldGrow
                  onClose={() => setSearchValue('')}
                />
              </Style.InputContainer>

              <FilterPopoverMenu
                tooltipMessage={t('notifications.filterNotifications')}
                width={324}
              >
                <NotificationSettings
                  sortBy={sortBy}
                  onSort={setSortBy}
                  isNewOnly={isNewOnly}
                  changeNewOnly={handleNewOnly}
                  dateFilter={dateFilter}
                  handleSetDateFilter={setDateFilter}
                />
              </FilterPopoverMenu>
            </Style.HeaderButtonsContainer>
          </Style.HeaderTopContainer>

          <Style.HeaderTabs>
            {notificationLinks.map(({
              link, countKey
            }) => {
              const isActiveLink = filteredLink === link;

              const activeCount = unreadCount[countKey] || 0;

              return (
                <Style.HeaderButtonLink
                  key={link}
                  type="button"
                  onClick={() => handleSetActiveLink(link)}
                >
                  <Style.LinkTitleContainer>
                    <Style.LinkTitle
                      $isActive={isActiveLink}
                      $isTitle
                    >
                      {t(`common.${link}`)}
                    </Style.LinkTitle>

                    {!!activeCount && (
                      <Style.LinkCountBlock $isActive={isActiveLink}>
                        {activeCount}
                      </Style.LinkCountBlock>
                    )}
                  </Style.LinkTitleContainer>

                  <Style.LinkFooterBlock $isActive={isActiveLink} />
                </Style.HeaderButtonLink>
              );
            })}
          </Style.HeaderTabs>
        </Style.HeaderContainer>

        {isFirstLoading ? (
          <Spinner />
        ) : (
          <ScrollContainer
            edgeHeight={68}
            className="grow"
          >
            {notifications.length ? (
              <NotificationsList
                notifications={notifications}
                onLoadMore={onLoadMore}
                hasMore={notifications?.length < total}
                query={debouncedQuery}
              />
            ) : (
              <Style.EmptyMessageContainer>
                {!total ? (
                  <>
                    <Style.EmptyMessageTitle>
                      {t('common.noMatchesByRequest')}
                    </Style.EmptyMessageTitle>

                    <p>{t('common.tryAnotherKeywordOrFilter')}</p>
                  </>
                ) : (
                  <>
                    <Style.EmptyMessageTitle>
                      {t('notifications.emptyNotifications')}
                    </Style.EmptyMessageTitle>

                    <p>
                      <Trans i18nKey="notifications.currentlyNoNotifications" />
                    </p>
                  </>
                )}
              </Style.EmptyMessageContainer>
            )}
          </ScrollContainer>
        )}
      </Style.MainContainer>
    </PageLayout>
  );
};
