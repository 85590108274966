import React, {
  FC
} from 'react';

import {
  faq
} from './utils';
import {
  ContentLayout
} from './ui';

export const FaqPage: FC = () => {
  return <ContentLayout links={faq} />;
};
