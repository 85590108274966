import {
  useNavigate
} from 'react-router-dom';

import {
  resetUserData
} from 'src/redux/user';
import {
  useTypedDispatch
} from 'src/redux';

import {
  useSignOutMutation
} from '../api/auth/authApi';
import {
  ROUTE, showWarningFromServer
} from '../utils';
import {
  successfulToast
} from '../components';

export const useSignOut = () => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();

  const [signOut, {
    isLoading
  }] = useSignOutMutation();

  const handleSignOut = async () => {
    try {
      const response = await signOut().unwrap();

      successfulToast(response.message);

      navigate(
        `/${ROUTE.LOGIN}`,
        {
          replace: true,
        }
      );

      dispatch(resetUserData());
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  return {
    isLoading,
    signOut: handleSignOut,
  };
};
