import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MainContainer = styled.div`
  ${tw`flex border border-gray94 gap-4 px-4 py-3 items-center`}
`;

export const MatchesWrapper = styled.div`
  ${tw`flex gap-2`}
`;

export const MatchesAmount = styled.div`
  ${tw`text-grey-dark-40 font-medium leading-6`}
`;

export const InputLabel = styled.label`
  ${tw`flex bg-input-blue grow h-42px p-2 rounded-100px gap-2`}
`;

export const Input = styled.input`
  ${tw`bg-input-blue grow outline-0`}
`;
