import React, {
  FC, useEffect, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import clsx from 'clsx';

import {
  CheckIcon
} from 'src/shared/icons';
import {
  ModalContainer,
  Button,
  successfulToast,
  warningToast,
  Spinner,
} from 'src/shared/components';
import {
  ToastBlockContainer
} from 'src/shared/components/ToastBlockContainer';
import {
  usePostQuestionDelegationsMutation
} from 'src/redux/openapi';
import {
  showWarningFromServer
} from 'src/shared/utils';

import * as Style from './ModalAssignDelegate.style';
import {
  AssignFromGroup, ManuallyAssigning
} from './ui';

interface ModalAssignDelegateProps {
  handleCloseModal: () => void;
  onSuccess: (delegateName: string) => void;
  isOpen: boolean;
  isReassign: boolean;
  questionId: string;
}

export const ModalAssignDelegate: FC<ModalAssignDelegateProps> = ({
  handleCloseModal,
  onSuccess,
  isOpen,
  isReassign,
  questionId,
}) => {
  const {
    t
  } = useTranslation();

  const [isManually, setIsManually] = useState(true);
  const [delegateId, setDelegateId] = useState<string | null>(null);

  useEffect(
    () => {
      setDelegateId(null);
    },
    [isManually]
  );

  const [assignDelegate, {
    isLoading
  }] = usePostQuestionDelegationsMutation();

  const onSubmit = async () => {
    if (!delegateId) {
      warningToast(t('inputMessages.SELECT_DELEGATE'));

      return;
    }

    try {
      const response = await assignDelegate({
        id: questionId,
        createQuestionDelegateBodySchema: {
          delegateId: delegateId || '',
        },
      }).unwrap();

      const {
        message,
        data: {
          delegate: {
            displayName, name
          },
        },
      } = response;

      successfulToast(message);

      onSuccess(displayName || name);
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  const onClose = () => {
    handleCloseModal();
    setIsManually(true);
  };

  return (
    <ModalContainer
      modalWidth={794}
      isDarkBackground
      closeModalHandler={onClose}
      isOpen={isOpen}
      modalTitle={
        isReassign ? t('modal.reassignDelegate') : t('modal.assignDelegate')
      }
      isGap={false}
    >
      <Style.DelegateContainer>
        <ToastBlockContainer
          isInfo
          text={
            isReassign
              ? t('modal.youCanReassignDelegate')
              : t('modal.youCanAssignDelegate')
          }
          fullWidth
          containerClassName="border-button-blue pl-6 mb-8"
          showIndicator={false}
        />

        <Style.TabContainer>
          <Style.TabButton
            $isActive={isManually}
            type="button"
            onClick={() => setIsManually(true)}
          >
            {isReassign
              ? t('modal.reassignManually')
              : t('modal.assignManually')}
          </Style.TabButton>

          <Style.TabButton
            $isActive={!isManually}
            type="button"
            onClick={() => setIsManually(false)}
          >
            {isReassign
              ? t('modal.reassignFromGroup')
              : t('modal.assignFromGroup')}
          </Style.TabButton>
        </Style.TabContainer>

        <Style.UnderlineContainer>
          <Style.UnderlineActive $isActive={isManually} />
        </Style.UnderlineContainer>
      </Style.DelegateContainer>

      {isManually ? (
        <ManuallyAssigning
          questionId={questionId}
          onDelegateChange={setDelegateId}
        />
      ) : (
        <AssignFromGroup
          questionId={questionId}
          delegateId={delegateId}
          onDelegateChange={setDelegateId}
        />
      )}

      <Button
        onClick={onSubmit}
        variant="big-blue"
        className={clsx(
          'w-max text-xl font-bold ml-auto',
          isManually ? 'mt-10' : 'mt-6',
        )}
      >
        {isReassign ? t('buttons.reassign') : t('buttons.assign')}

        {isLoading ? (
          <Spinner
            centered={false}
            color="inherit"
            size={24}
          />
        ) : (
          <CheckIcon />
        )}
      </Button>
    </ModalContainer>
  );
};
