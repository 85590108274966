import {
  t
} from 'i18next';

import {
  UserGroupRole, UserGroupRoleType
} from 'src/redux/openapi';

interface GetUserRoleArgs {
  groupRole: UserGroupRoleType | null;
  organizationRole: UserGroupRoleType | null;
  userRole: UserGroupRoleType | null;
}

export const getGroupUserRole = ({
  groupRole,
  organizationRole,
  userRole,
}: GetUserRoleArgs) => {
  const isPlatformAdmin = userRole === UserGroupRole.ADMIN;
  const rolesToCheck = [organizationRole, groupRole];

  const hasSuperAdminRole = rolesToCheck.includes(UserGroupRole.SUPERADMIN)
    || organizationRole === UserGroupRole.ADMIN;

  if (isPlatformAdmin || hasSuperAdminRole) {
    return UserGroupRole.SUPERADMIN;
  }

  const hasAdminRole = rolesToCheck.includes(UserGroupRole.ADMIN);

  if (hasAdminRole) {
    return UserGroupRole.ADMIN;
  }

  return UserGroupRole.USER;
};

export enum GroupFilters {
  Active = 'Active',
  Invited = 'Invited',
}

export const getGroupMembersFilters = () => [
  {
    id: GroupFilters.Active,
    label: t('common.active'),
  },
  {
    id: GroupFilters.Invited,
    label: t('group.invited'),
  },
];
