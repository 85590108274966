import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const ModalContainer = styled.div`
  ${tw`fixed top-0 left-0 right-0 bottom-0 bg-modal-bg flex overflow-y-auto`}
`;

export const ModalViewBlock = styled.div`
  ${tw`w-675px mx-auto my-auto p-4 bg-white rounded-md shadow-menu-shadow`}
`;

export const HeaderContainer = styled.div`
  ${tw`flex flex-col-reverse md:flex-row md:justify-between gap-2`}
`;

export const ModalDateInfo = styled.div`
  ${tw`text-xs font-medium leading-5 text-dim-gray`}
`;

export const ModalTitle = styled.div`
  ${tw`text-18-28 md:text-22-30 font-bold text-gray7`}
`;
export const TitlesContainer = styled.div<{ $isMultiChoice?: boolean }>`
  ${tw`mb-6`}

  ${({
    $isMultiChoice
  }) => $isMultiChoice && tw`flex justify-between items-center`}
`;

export const DataContainer = styled.div<{ $isMultiChoice?: boolean }>`
  ${tw`flex items-center`}

  ${({
    $isMultiChoice
  }) => ($isMultiChoice ? tw`flex-col gap-5` : tw`flex-row justify-between`)}
`;

export const FooterButtonsContainer = styled.div<{ $isMultiChoice?: boolean }>`
  ${tw`flex flex-row gap-4`}

  ${({
    $isMultiChoice
  }) => $isMultiChoice && tw`justify-between w-full`}
`;

export const ModalSubTitle = styled.div`
  ${tw`text-18-28 md:text-xl font-bold text-gray7 mb-7 md:mb-0`}
`;

export const ModalResetSpan = styled.span`
  ${tw`text-16-28 md:text-xl text-dark-gray font-bold border-light-grey`}
`;

export const ModalSaveSpan = styled.span`
  ${tw`text-16-28 md:text-xl text-white font-bold`}
`;
export const ModalEqualSpan = styled.span`
  ${tw`text-xl text-button-blue font-bold`}
`;

export const ButtonContainer = styled.button`
  ${tw`w-10 h-10 p-2 flex items-center md:border md:border-light-grey rounded-100px`}
`;

export const TotalValueBlock = styled.div<{ $isBigger: boolean }>`
  ${tw`text-xl font-bold`}

  ${({
    $isBigger
  }) => ($isBigger
    ? tw`text-red-text-error flex items-center gap-4`
    : tw`text-gray7`)}
`;

export const TextBlock = styled.div`
  ${tw`text-15-20 font-medium`}
`;
