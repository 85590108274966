import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const Label = styled.label<{ $classNames?: string }>`
  ${tw`flex flex-row gap-1 justify-start items-center w-full`}

  ${({
    $classNames
  }) => ($classNames ? tw`${$classNames}` : tw`mb-5`)}
`;

export const Text = styled.div`
  ${tw`font-medium text-13-21 md:text-base text-gray7`}
`;
export const Span = styled.span`
  ${tw`text-grey-dark-40`}
`;
