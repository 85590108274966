import React, {
  FC, useId, useState, ReactNode
} from 'react';
import {
  Link
} from 'react-router-dom';
import {
  useTranslation
} from 'react-i18next';

import {
  PasswordEye
} from 'src/shared/icons';
import {
  ROUTE
} from 'src/shared/utils';

import {
  InputType
} from './types';
import * as Style from './Input.styles';

interface InputProps {
  type?: InputType;
  label?: string;
  value: string | number;
  onChange: (e: string) => void;
  onBlur?: () => void;
  isRequired?: boolean;
  bottomMessage?: ReactNode;
  errorMessage?: string;
  placeholder?: string;
  className?: string;
  step?: number;
}

export const Input: FC<InputProps> = ({
  onChange,
  type,
  value,
  label,
  isRequired,
  bottomMessage,
  errorMessage,
  placeholder,
  onBlur = () => {},
  className,
  step,
}) => {
  const {
    t
  } = useTranslation();

  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const htmlID = useId();

  const getInputType = () => {
    if (type === InputType.PASSWORD && !isPasswordShown) {
      return InputType.PASSWORD;
    }

    if (type === InputType.EMAIL) {
      return InputType.EMAIL;
    }

    if (type === InputType.NUMBER) {
      return InputType.NUMBER;
    }

    return InputType.TEXT;
  };

  return (
    <div>
      {label && (
        <Style.LabelWrapper>
          <Style.Label>
            {label}

            {isRequired && <Style.Asterisk>*</Style.Asterisk>}
          </Style.Label>

          {type === InputType.PASSWORD && !isRequired && (
            <Style.ForgotPasswordContainer>
              <Link to={`/${ROUTE.RESET_PASSWORD}`}>
                {t('login.forgotPassword')}
              </Link>
            </Style.ForgotPasswordContainer>
          )}
        </Style.LabelWrapper>
      )}

      <Style.InputWrapper $isError={!!errorMessage}>
        <Style.Input
          $isError={!!errorMessage}
          type={getInputType()}
          id={htmlID}
          name={`${htmlID}_field`}
          value={value}
          placeholder={placeholder || label}
          autoComplete="off"
          onChange={(e) => onChange(e.target.value)}
          onBlur={onBlur}
          className={className}
          step={step}
        />

        {type === InputType.PASSWORD && (
          <Style.ShowButton
            type="button"
            tabIndex={-1}
            onClick={() => setIsPasswordShown(!isPasswordShown)}
          >
            <img
              src={PasswordEye}
              alt="eye"
            />
          </Style.ShowButton>
        )}
      </Style.InputWrapper>

      {bottomMessage && !errorMessage && (
        <Style.BottomMessage>{bottomMessage}</Style.BottomMessage>
      )}

      {errorMessage && <Style.ErrorMessage>{errorMessage}</Style.ErrorMessage>}
    </div>
  );
};
