import React, {
  FC, useState
} from 'react';
import {
  Trans, useTranslation
} from 'react-i18next';
import {
  useForm
} from 'react-hook-form';
import {
  zodResolver
} from '@hookform/resolvers/zod';
import {
  useParams
} from 'react-router-dom';

import {
  Button,
  Divider,
  ModalContainer,
  Spinner,
  Stepper,
  successfulToast,
  Switcher,
  useStepper,
  warningToast,
} from 'src/shared/components';
import {
  ArrowRightIcon, CheckmarkThinIcon
} from 'src/shared/icons';
import {
  useGetGroupsNamesExistsMutation,
  usePostGroupFromGroupMutation,
  UserForMerge,
} from 'src/redux/openapi';
import {
  useGetInputValidation
} from 'src/shared/hooks';
import {
  showWarningFromServer
} from 'src/shared/utils';

import {
  getStepsMap, Steps
} from './utils';
import * as Style from './CreateFromGroupModal.styles';
import {
  ChooseMemberContent, CreateNewGroupForm
} from './ui';
import {
  CreateGroupForm
} from './types';

interface CreateFromGroupModalProps {
  isOpen: boolean;
  closeModal: () => void;
  groupId: string;
  onGroupCreation: () => void;
}

export const CreateFromGroupModal: FC<CreateFromGroupModalProps> = ({
  isOpen,
  closeModal,
  groupId,
  onGroupCreation,
}) => {
  const [inactiveChosen, setInactiveChosen] = useState(true);
  const [selectedUsers, setSelectedUsers] = useState<UserForMerge[]>([]);

  const toggleInactiveChosen = () => setInactiveChosen((prev) => !prev);

  const {
    t
  } = useTranslation();

  const params = useParams();

  const {
    organizationId = ''
  } = params;

  const steps = getStepsMap();

  const {
    completeCurrentStep,
    activeStepId,
    handleNextStep,
    completedStatus,
    setCurrentStep,
    handlePreviousStep,
  } = useStepper(steps);

  const {
    createGroupSchema
  } = useGetInputValidation();

  const formConfig = useForm<CreateGroupForm>({
    mode: 'onTouched',
    values: {
      groupName: '',
      groupLocation: {
        value: '',
        label: '',
      },
    },
    resolver: zodResolver(createGroupSchema),
  });

  const onClose = () => {
    setCurrentStep(0);
    formConfig.reset();
    closeModal();
  };

  const [handleCreation, {
    isLoading: isCreationLoading
  }] = usePostGroupFromGroupMutation();

  const onCreateClick = async () => {
    const {
      groupName, groupLocation
    } = formConfig.getValues();

    const userIds = selectedUsers.map(({
      userId
    }) => userId);

    try {
      const response = await handleCreation({
        id: organizationId,
        createOrganizationGroupFromGroupBodySchema: {
          group: {
            name: groupName,
            location: groupLocation.value,
          },
          fromGroupId: groupId,
          inactivateChosenUsers: inactiveChosen,
          userIds,
        },
      }).unwrap();

      successfulToast(response.message);
      onGroupCreation();
      onClose();
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  const [checkExisting, {
    isLoading: isExistsLoading
  }] = useGetGroupsNamesExistsMutation();

  const checkProceed = async () => {
    const {
      groupLocation: groupLocationError, groupName: groupNameError
    } = formConfig.formState.errors;

    if (groupLocationError || groupNameError) {
      return false;
    }

    const {
      groupName: name, groupLocation: location
    } = formConfig.getValues();

    if (!name || !location) {
      formConfig.trigger();

      return false;
    }

    let canProceed = true;

    try {
      const response = await checkExisting({
        name,
      }).unwrap();

      if (response.data.exists) {
        warningToast(t('group.existsAlready'));
      }

      return !response.data.exists;
    } catch (error) {
      showWarningFromServer(error);
      canProceed = false;
    }

    return canProceed;
  };

  const onNextStepClick = async () => {
    if (activeStepId === Steps.CreateNewGroup) {
      const canProceed = await checkProceed();

      if (!canProceed) {
        return;
      }

      completeCurrentStep();
      handleNextStep();
    } else {
      onCreateClick();
    }
  };

  return (
    <ModalContainer
      isOpen={isOpen}
      modalTitle={t('organisation.createFromGroup')}
      closeModalHandler={onClose}
      modalWidth={644}
    >
      <Style.ContentWrapper>
        <Stepper
          steps={steps}
          activeStepId={activeStepId}
          completedStatus={completedStatus}
        />

        {activeStepId === Steps.CreateNewGroup && (
          <CreateNewGroupForm formConfig={formConfig} />
        )}

        {activeStepId === Steps.ChooseMembers && (
          <ChooseMemberContent
            groupId={groupId}
            selectedItems={selectedUsers}
            setSelectedItems={setSelectedUsers}
          />
        )}
      </Style.ContentWrapper>

      <Divider className="absolute left-0" />

      <Style.Footer>
        {activeStepId === Steps.ChooseMembers && (
          <>
            <Switcher
              isActive={inactiveChosen}
              onChange={toggleInactiveChosen}
              switcherClassName="!w-16"
              label={(
                <Trans
                  i18nKey="group.inactivateChosenMembers"
                  components={{
                    1: <br />,
                  }}
                />
              )}
              labelClassName="mr-auto"
            />

            <Button
              variant="big-grey-bordered"
              className="w-max font-bold text-xl"
              onClick={handlePreviousStep}
            >
              <ArrowRightIcon className="rotate-180" />

              {t('buttons.back')}
            </Button>
          </>
        )}

        <Button
          variant="big-blue"
          className="w-max font-bold text-xl"
          onClick={onNextStepClick}
        >
          {activeStepId === Steps.ChooseMembers ? (
            <>
              {t('buttons.create')}

              {isCreationLoading ? (
                <Spinner
                  size={24}
                  color="inherit"
                />
              ) : (
                <CheckmarkThinIcon />
              )}
            </>
          ) : (
            <>
              {t('buttons.next')}

              {isExistsLoading ? (
                <Spinner
                  size={24}
                  color="inherit"
                />
              ) : (
                <ArrowRightIcon />
              )}
            </>
          )}
        </Button>
      </Style.Footer>
    </ModalContainer>
  );
};
