import React, {
  FC, Fragment, MouseEvent
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  PopoverMenu, Spinner
} from 'src/shared/components';
import {
  MoreVerticalIcon,
  TrashDeleteIcon,
  EditPencilIcon,
} from 'src/shared/icons';

import {
  SettingOptions
} from '../../types';

import * as Style from './GroupCard.styles';

const SETTING_OPTIONS_MAP = [
  {
    id: SettingOptions.Edit,
    icon: <EditPencilIcon className="text-dark-gray" />,
  },
  {
    id: SettingOptions.Delete,
    icon: <TrashDeleteIcon className="text-red-text-error" />,
  },
];

interface GroupCardContextProps {
  isLoading: boolean;
  handleClick: (
    event: MouseEvent<HTMLButtonElement>,
    option: SettingOptions,
  ) => void;
}

export const GroupCardContext: FC<GroupCardContextProps> = ({
  isLoading,
  handleClick,
}) => {
  const {
    t
  } = useTranslation();

  return (
    <Style.PopoverContainer>
      <PopoverMenu
        placement="bottom-end"
        customShift={22}
        applyMaxHeight={false}
        renderCustomButton={({
          toggleMenu, triggerProps
        }) => (
          <button
            type="button"
            className="pr-4"
            onClick={(event) => {
              event.stopPropagation();
              toggleMenu();
            }}
            {...triggerProps}
          >
            <MoreVerticalIcon className="text-dark-gray h-6 w-6" />
          </button>
        )}
      >
        <Style.MenuContainer>
          {SETTING_OPTIONS_MAP.map(({
            id: optionId, icon
          }, index) => (
            <Fragment key={optionId}>
              <Style.MenuItem
                onClick={(event) => handleClick(
                  event,
                  optionId
                )}
                $isRedText={optionId === SettingOptions.Delete}
              >
                {icon}

                {optionId === SettingOptions.Delete && isLoading ? (
                  <Spinner size={24} />
                ) : (
                  t(`group.${optionId}`)
                )}
              </Style.MenuItem>

              {index === 0 && <div className="h-px bg-gray94" />}
            </Fragment>
          ))}
        </Style.MenuContainer>
      </PopoverMenu>
    </Style.PopoverContainer>
  );
};
