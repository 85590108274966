import React, {
  FC, MouseEvent
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  LogOutIcon, SettingsIcon, TrashDeleteIcon
} from 'src/shared/icons';

import * as Style from './OrganisationSettings.styles';

export enum SettingOptions {
  Leave = 'Leave',
  Delete = 'Delete',
  Settings = 'Settings',
}

const SETTING_OPTIONS_MAP = [
  {
    id: SettingOptions.Leave,
    icon: <LogOutIcon className="text-dark-gray" />,
  },
  {
    id: SettingOptions.Delete,
    icon: <TrashDeleteIcon className="text-red-text-error" />,
  },
];

interface OrganisationSettingsProps {
  isAdmin: boolean;
  openModalConfirm: (action: SettingOptions) => void;
}

export const OrganisationSettings: FC<OrganisationSettingsProps> = ({
  isAdmin,
  openModalConfirm,
}) => {
  const {
    t
  } = useTranslation();

  const handleClick = (
    event: MouseEvent<HTMLButtonElement>,
    option: SettingOptions,
  ) => {
    event.stopPropagation();

    switch (option) {
      case SettingOptions.Delete:
        openModalConfirm(option);
        break;

      case SettingOptions.Leave:
        openModalConfirm(option);
        break;

      case SettingOptions.Settings:
        openModalConfirm(option);
        break;

      default:
        break;
    }
  };

  const optionsForUser = isAdmin
    ? SETTING_OPTIONS_MAP
    : SETTING_OPTIONS_MAP.filter(
      (option) => option.id !== SettingOptions.Delete,
    );

  return (
    <Style.MenuContainer>
      {isAdmin && (
        <>
          <Style.MenuItem
            onClick={(event) => handleClick(
              event,
              SettingOptions.Settings
            )}
          >
            <SettingsIcon className="text-dark-gray w-6 h-6" />

            {t('organisation.Settings')}
          </Style.MenuItem>

          <Style.Divider />
        </>
      )}

      <Style.Title>{t('organisation.organisationSettings')}</Style.Title>

      {optionsForUser.map(({
        id, icon
      }) => (
        <Style.MenuItem
          key={id}
          onClick={(event) => handleClick(
            event,
            id
          )}
        >
          {icon}

          {t(`organisation.${id}`)}
        </Style.MenuItem>
      ))}
    </Style.MenuContainer>
  );
};
