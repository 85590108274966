import {
  useMemo, useState
} from 'react';
import {
  useForm
} from 'react-hook-form';
import {
  useLocation, useNavigate
} from 'react-router-dom';
import {
  zodResolver
} from '@hookform/resolvers/zod';
import i18next from 'i18next';
import {
  useTranslation
} from 'react-i18next';

import {
  ROUTE, showWarningFromServer
} from 'src/shared/utils';
import {
  warningToast,
  successfulToast,
  useStepper,
} from 'src/shared/components';
import {
  useGetGroupUsersInvitesQuery,
  usePostApiGroupsMutation,
  usePostSignInGroupMutation,
} from 'src/redux/openapi';
import {
  useGetApiQuestionSetsQuery
} from 'src/redux/openapi/endpoints/questionSets';
import {
  useNestedSelect, useGetInputValidation
} from 'src/shared/hooks';

import {
  FormField, UseCreateGroupResult, CreateGroupForm
} from './types';

const getSteps = () => [
  {
    id: 'step1',
    label: `${i18next.t('group.groupNameAndQuestion')}`,
  },
  {
    id: 'step2',
    label: `${i18next.t('group.invitePeople')}`,
  },
];

export const useCreateGroup = (): UseCreateGroupResult => {
  const [newGroupId, setNewGroupId] = useState('');
  const stepsMap = getSteps();

  const {
    currentStep,
    setCurrentStep,
    completeCurrentStep,
    activeStepId,
    isLastStep,
    isLastStepCompleted,
    completedStatus,
  } = useStepper(stepsMap);

  const {
    createGroupSchema
  } = useGetInputValidation();

  const {
    t
  } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  const isNewUser = location.pathname.startsWith(`/${ROUTE.CREATE_GROUP}`);

  const [createGroup] = usePostApiGroupsMutation();
  const [createFirstGroup] = usePostSignInGroupMutation();

  const {
    data: invitedUserData, refetch: refetchInvitedUserData
  } = useGetGroupUsersInvitesQuery(
    {
      id: newGroupId,
      limit: 5000,
    },
    {
      skip: !newGroupId,
    },
  );

  const invitedPeople = useMemo(
    () => invitedUserData?.data.users || [],
    [invitedUserData],
  );

  const {
    data: questionSetsData, isLoading: isQuestionSetsLoading
  } = useGetApiQuestionSetsQuery({
    limit: 5000,
    filters: {
      not: {
        status: 'DEACTIVATED',
      },
    },
  });

  const questionSets = useMemo(
    () => questionSetsData?.data.questionSets || [],
    [questionSetsData],
  );

  const initialSelectedItems = useMemo(
    () => questionSets
      .filter((set) => set.questions.some((question) => question.isMandatory))
      .map((set) => ({
        parentId: set.id,
        selectedChildrenId: set.questions
          .filter((question) => question.isMandatory)
          .map((question) => question.id),
      })),
    [questionSets],
  );

  const selectConfig = useNestedSelect({
    initialSelectedItems,
  });

  const {
    register,
    control,
    getValues,
    formState: {
      errors
    },
    trigger,
  } = useForm<CreateGroupForm>({
    mode: 'onTouched',
    defaultValues: {
      [FormField.GroupName]: '',
      [FormField.GroupLocation]: {
        value: '',
        label: '',
      },
    },
    resolver: zodResolver(createGroupSchema),
  });

  const handleGroupCreation = async (
    body: { name: string; location: string; questionIds: string[] },
    isFirstGroup?: boolean,
  ) => {
    try {
      const response = isFirstGroup
        ? await createFirstGroup({
          signInGroupAssignBodySchema: body,
        }).unwrap()
        : await createGroup({
          createGroupBodySchema: body,
        }).unwrap();

      const {
        message, data
      } = response;

      successfulToast(message);
      setNewGroupId(data.group?.id || '');

      completeCurrentStep();

      setCurrentStep(1);
    } catch (error) {
      showWarningFromServer(
        error,
        t('errors.errorInCreatingGroup')
      );
    }
  };

  const handleGroupCreationClick = () => {
    const {
      groupLocation, groupName
    } = getValues();

    if (!groupName || !groupLocation.value) {
      warningToast(t('errors.nameAndLocationRequired'));

      return;
    }

    const body = {
      name: groupName,
      location: groupLocation.value,
      questionIds: selectConfig.selectedItems.flatMap(
        (questionSet) => questionSet.selectedChildrenId,
      ),
    };

    handleGroupCreation(
      body,
      isNewUser
    );
  };

  const handleNextStep = async () => {
    if (currentStep === 0) {
      const validationResult = await trigger([
        FormField.GroupName,
        FormField.GroupLocation,
      ]);

      if (!validationResult) {
        return;
      }

      handleGroupCreationClick();
    }

    if (currentStep === 1) {
      if (completedStatus[stepsMap[0].id]) {
        if (isNewUser) {
          navigate('/');
        } else {
          navigate(`/${ROUTE.GROUPS}/${newGroupId}`);
        }
      } else {
        setCurrentStep(0);
      }
    }
  };

  const onPeopleInvite = async () => {
    await refetchInvitedUserData();

    completeCurrentStep();
  };

  return {
    steps: stepsMap,
    handleNextStep,
    completedStatus,
    isLastStep,
    isLastStepCompleted,
    currentStep,
    control,
    register,
    onPeopleInvite,
    activeStepId,
    errors,
    newGroupId,
    invitedPeople,
    questionSets,
    isQuestionSetsLoading,
    refetchInvitedUserData,
    selectConfig,
  };
};
