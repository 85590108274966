import React, {
  FC
} from 'react';
import {
  useNavigate
} from 'react-router-dom';
import {
  useTranslation
} from 'react-i18next';

import {
  Button,
  ModalContainer,
  Spinner,
  successfulToast,
} from 'src/shared/components';
import {
  AlertTriangleIcon,
  CloseIcon,
  TrashDeleteIcon,
} from 'src/shared/icons';
import {
  useDeleteUserCurrentMutation
} from 'src/redux/openapi';
import {
  useTypedDispatch
} from 'src/redux';
import {
  resetUserData
} from 'src/redux/user';
import {
  ROUTE, showWarningFromServer
} from 'src/shared/utils';

import * as Style from './DeleteProfileModal.styles';

interface DeleteProfileModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const BUTTON_CLASS = 'flex-1 text-5 font-bold';

export const DeleteProfileModal: FC<DeleteProfileModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [deleteProfile, {
    isLoading
  }] = useDeleteUserCurrentMutation();

  const {
    t
  } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useTypedDispatch();

  const onDelete = async () => {
    try {
      const response = await deleteProfile().unwrap();

      successfulToast(response.message);
      onClose();

      navigate(
        `/${ROUTE.LOGIN}`,
        {
          replace: true,
        }
      );

      dispatch(resetUserData());
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  return (
    <ModalContainer
      isOpen={isOpen}
      closeModalHandler={onClose}
      modalWidth={466}
      isHeader={false}
    >
      <Style.ContentContainer>
        <AlertTriangleIcon className="w-16 h-16 text-red-text-error" />

        <Style.Title>{t('modal.sureDeleteAccount')}</Style.Title>

        <Style.Subtitle>{t('modal.actionIsIrreversible')}</Style.Subtitle>

        <Style.ButtonsContainer>
          <Button
            variant="big-black"
            className={BUTTON_CLASS}
            onClick={onClose}
          >
            {t('buttons.cancel')}

            <CloseIcon />
          </Button>

          <Button
            variant="big-red"
            className={BUTTON_CLASS}
            onClick={onDelete}
          >
            {t('buttons.deleteAccount')}

            {isLoading ? (
              <Spinner
                size={24}
                centered={false}
                color="white"
              />
            ) : (
              <TrashDeleteIcon />
            )}
          </Button>
        </Style.ButtonsContainer>
      </Style.ContentContainer>
    </ModalContainer>
  );
};
