import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  SpinnerIcon
} from 'src/shared/icons';
import {
  themeColors
} from 'src/shared/utils';

import * as Style from './Spinner.styles';

interface SpinnerProps {
  color?: string;
  size?: number;
  centered?: boolean;
  withLoadingText?: boolean;
}

export const Spinner: FC<SpinnerProps> = ({
  color = themeColors['gulf-blue'],
  size = 40,
  centered = true,
  withLoadingText = false,
}) => {
  const {
    t
  } = useTranslation();

  return (
    <Style.Container
      $color={color}
      $centered={centered}
      id="Spinner"
    >
      <SpinnerIcon
        width={size}
        height={size}
        className="animate-spin"
      />

      {withLoadingText && (
        <Style.LoadingTitle>{`${t('common.Loading')}...`}</Style.LoadingTitle>
      )}
    </Style.Container>
  );
};
