import {
  UserListItem,
  GroupUser,
  OrganizationGroupWithUsers,
  Characteristic,
} from './types';

const getUsersWithGroups = (
  usersAccumulator: UserListItem[],
  groupUser: GroupUser,
) => {
  let existingUser = usersAccumulator.find(
    (user) => user.email === groupUser.email,
  );

  if (!existingUser) {
    existingUser = {
      email: groupUser.email,
      groups: [],
      characteristics: groupUser.characteristics || [],
    };

    usersAccumulator.push(existingUser);
  }

  if (groupUser.group && !existingUser.groups.includes(groupUser.group)) {
    existingUser.groups.push(groupUser.group);
  }

  return usersAccumulator;
};

export const getGroupUsersWithGroups = (users: GroupUser[]) => {
  return users.reduce(
    (usersAccumulator: UserListItem[], groupUser) => {
      return getUsersWithGroups(
        usersAccumulator,
        groupUser
      );
    },
    []
  );
};

export const getGroupsUsersWithGroups = (
  groups: OrganizationGroupWithUsers[],
) => {
  return groups.reduce(
    (usersAccumulator: UserListItem[], group) => {
      group.users.forEach(({
        email, characteristics
      }) => {
        const groupUser = {
          email,
          group: group.name,
          characteristics,
        };

        getUsersWithGroups(
          usersAccumulator,
          groupUser
        );
      });

      return usersAccumulator;
    },
    []
  );
};

export const getUniqueUsers = <
  T extends {
    email: string;
    groups?: string[];
    characteristics?: Characteristic[];
  },
>(
    newValues: T[],
    oldValues: T[],
  ) => {
  const allValues = [...newValues, ...oldValues];

  const userMap = new Map<string, T>();

  allValues.forEach((user) => {
    if (userMap.has(user.email)) {
      const existingUser = userMap.get(user.email)!;

      existingUser.groups = Array.from(
        new Set([...(existingUser.groups || []), ...(user.groups || [])]),
      );
    } else {
      userMap.set(
        user.email,
        {
          ...user,
        }
      );
    }
  });

  return Array.from(userMap.values());
};

export const getUniqueCharacteristics = (
  newValue: string | string[],
  oldValues: string[],
) => {
  if (Array.isArray(newValue)) {
    return Array.from(new Set([...newValue, ...oldValues]));
  }

  return Array.from(new Set([newValue, ...oldValues]));
};
