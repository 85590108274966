import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const ContentContainer = styled.div`
  ${tw`flex flex-col justify-center max-w-450px w-full h-full m-auto`}
`;

export const Message = styled.p`
  ${tw`font-medium text-17-26 text-dark-gray mt-2`}
`;

export const EmailSpan = styled.span`
  ${tw`text-button-blue`}
`;
