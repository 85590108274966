import {
  useState, useMemo, useEffect
} from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import {
  successfulToast
} from 'src/shared/components';
import {
  useGetGroupUsersInvitesQuery,
  useDeleteGroupUsersInvitesMutation,
  GroupInvitedPerson,
} from 'src/redux/openapi';
import {
  showWarningFromServer
} from 'src/shared/utils';

interface UseGetGroupsInvitesArgs {
  id: string;
  shouldSkip?: boolean;
  onInvitesUpdate?: () => void;
  onDeleteSuccess?: () => void;
  searchQuery?: string;
}

export const useGetGroupInvites = ({
  id,
  shouldSkip,
  onInvitesUpdate,
  onDeleteSuccess,
  searchQuery,
}: UseGetGroupsInvitesArgs) => {
  const [currentLastItem, setCurrentLastItem] = useState<GroupInvitedPerson>();

  const [visibleInvites, setVisibleInvites] = useState<GroupInvitedPerson[]>(
    [],
  );

  const {
    data: usersData,
    isLoading: isUsersLoading,
    isFetching,
    isError,
    refetch,
    isUninitialized,
  } = useGetGroupUsersInvitesQuery(
    {
      id,
      lastInvitationSentAt: currentLastItem?.sentAt,
      lastUserId: currentLastItem?.id,
      limit: 20,
      query: searchQuery,
    },
    {
      skip: shouldSkip,
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    total: totalUsers, users: currentUsers
  } = useMemo(
    () => usersData?.data || {
      total: 0,
      users: [],
    },
    [usersData],
  );

  const hasNextPage = totalUsers > currentUsers.length;

  const onLoadMore = () => {
    if (isUsersLoading || isFetching || !visibleInvites.length) {
      return;
    }

    const lastItem = visibleInvites[visibleInvites.length - 1];

    setCurrentLastItem(lastItem);
  };

  const [sentryRef] = useInfiniteScroll({
    loading: isUsersLoading || isFetching,
    hasNextPage,
    onLoadMore,
    disabled: !!isError,
    rootMargin: '0px 0px 100px 0px',
  });

  const displayLoader = hasNextPage || isUsersLoading || isFetching;

  useEffect(
    () => {
      if (currentLastItem) {
        setVisibleInvites((prev) => [...prev, ...currentUsers]);
      } else {
        setVisibleInvites(currentUsers);
      }
    },
    [currentUsers]
  );

  const onRefetch = () => {
    if (currentLastItem) {
      setCurrentLastItem(undefined);
    } else if (!isUninitialized) {
      refetch();
    }
  };

  useEffect(
    () => {
      onRefetch();
    },
    [searchQuery]
  );

  const onInvitedListUpdate = () => {
    onRefetch();

    if (onInvitesUpdate) {
      onInvitesUpdate();
    }
  };

  const [deleteUserInvitation] = useDeleteGroupUsersInvitesMutation();

  const removeUserInvitation = async (userId: string) => {
    try {
      const response = await deleteUserInvitation({
        id,
        userId,
      }).unwrap();

      successfulToast(response.message);

      setVisibleInvites((invites) => invites.filter((invite) => invite.id !== userId),);

      if (onDeleteSuccess) {
        onDeleteSuccess();
      }

      if (onInvitesUpdate) {
        onInvitesUpdate();
      }
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  return {
    invites: visibleInvites,
    sentryRef,
    displayLoader,
    removeUserInvitation,
    onInvitedListUpdate,
  };
};
