import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MainContainer = styled.div`
  ${tw`flex flex-col justify-center items-center flex-1 h-full gap-4 md:gap-8 max-w-440px m-auto`}
`;

export const BackButtonContainer = styled.div`
  ${tw`md:absolute md:top-42px md:left-42px mb-2 md:mb-0`}
`;

export const Title = styled.h1`
  ${tw`text-xl md:text-28-36 font-bold text-eerie-black text-center`}
`;

export const SocialsContainer = styled.div`
  ${tw`w-full`}
`;

export const Form = styled.form`
  ${tw`w-full flex flex-col gap-5`}
`;

export const ButtonsContainer = styled.div`
  ${tw`flex flex-col gap-3 w-full`}
`;
