import React, {
  FC, useCallback, useEffect, useMemo, useState
} from 'react';
import {
  useParams
} from 'react-router-dom';
import {
  DragDropContext, DragStart, DropResult
} from 'react-beautiful-dnd';
import {
  Coordinate
} from 'recharts/types/util/types';
import {
  useTranslation
} from 'react-i18next';

import {
  FilterPopoverMenu,
  MailListInput,
  Spinner,
  StrictModeDroppable,
  Tooltip,
} from 'src/shared/components';
import {
  useGetOrgGroupsInvitationsUsersQuery,
  useGetOrgGroupsUsersQuery,
} from 'src/redux/openapi';
import {
  allUsersList
} from 'src/shared/utils';
import {
  useTypedDispatch, useTypedSelector
} from 'src/redux';
import {
  addGroup,
  addUsersToGroup,
  loadOrgGroupsData,
  organizationSelectors,
  resetUsersToSetUp,
  UserListItem,
} from 'src/redux/organizations';

import {
  GroupsList
} from '../GroupsList';
import {
  UsersCardList
} from '../UsersCardList';
import {
  CustomCursor
} from '../CustomCursor';

import {
  FilterMembers
} from './FilterMembers';
import * as Style from './GroupsAndMembers.styles';

interface GroupsAndMembersProps {
  invitesOnly?: boolean;
  shouldResetOnUnmount?: boolean;
}

export const GroupsAndMembers: FC<GroupsAndMembersProps> = ({
  invitesOnly,
  shouldResetOnUnmount = true,
}) => {
  const {
    t
  } = useTranslation();

  const params = useParams();
  const dispatch = useTypedDispatch();

  const groupsToSetup = useTypedSelector(
    organizationSelectors.orgGroupsToSetup,
  );

  const userList = useTypedSelector(organizationSelectors.userList);

  const onGroupAdded = useCallback(
    (
      newGroupId: string,
      newName: string,
      newLocation: string,
      isPublic: boolean,
    ) => {
      dispatch(
        addGroup({
          id: newGroupId,
          name: newName,
          location: newLocation,
          users: [],
          isPublic,
        }),
      );
    },
    [],
  );

  const {
    organizationId
  } = params;

  const {
    data: organizationUsersData,
    isLoading: isOrganizationUsersLoading,
    isFetching: isOrganizationUsersFetching,
  } = useGetOrgGroupsUsersQuery(
    {
      id: `${organizationId}`,
      limit: 1000,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !organizationId || invitesOnly,
    },
  );

  const {
    data: organizationInvitesData,
    isLoading: isOrganizationInvitesLoading,
    isFetching: isOrganizationInvitesFetching,
  } = useGetOrgGroupsInvitationsUsersQuery(
    {
      id: `${organizationId}`,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !organizationId || !invitesOnly,
    },
  );

  const [selectedUsers, setSelectedUsers] = useState<UserListItem[]>([]);
  const [cursorPosition, setCursorPosition] = useState<Coordinate | null>(null);
  const [isDraggingUsers, setIsDraggingUsers] = useState(false);
  const [isFilterShowAll, setIsFilterShowAll] = useState(true);

  const [selectedCharacteristics, setSelectedCharacteristics] = useState<
  string[]
  >([]);

  const groupsOrgArray = useMemo(
    () => {
      return (
        organizationUsersData?.data.groups
      || organizationInvitesData?.data.groups
      || []
      );
    },
    [organizationUsersData, organizationInvitesData]
  );

  useEffect(
    () => {
      return () => {
        if (shouldResetOnUnmount) {
          dispatch(resetUsersToSetUp());
        }
      };
    },
    []
  );

  const isFetching = isOrganizationUsersFetching || isOrganizationInvitesFetching;

  useEffect(
    () => {
      if (!isFetching) {
        dispatch(loadOrgGroupsData(groupsOrgArray));
      }
    },
    [groupsOrgArray, isFetching]
  );

  const countAllUsersToAdd = userList?.length;

  const usersToDisplay = useMemo(
    () => {
      if (!userList) {
        return [];
      }

      const filterByGroupStatus = isFilterShowAll
        ? userList
        : userList.filter(({
          groups
        }) => !groups?.length);

      if (!selectedCharacteristics.length) {
        return filterByGroupStatus;
      }

      return filterByGroupStatus.filter(({
        characteristics = []
      }) => characteristics.some((characteristic) => {
        return selectedCharacteristics.some(
          (selectedChar) => selectedChar === characteristic.value,
        );
      }),);
    },
    [userList, isFilterShowAll, selectedCharacteristics]
  );

  const isFilteredUsers = countAllUsersToAdd !== usersToDisplay.length;

  const handleSelectUser = (newUser: UserListItem) => {
    setSelectedUsers((prev) => {
      const userSelected = prev.find((user) => user.email === newUser.email);

      if (userSelected) {
        return prev.filter((user) => user.email !== newUser.email);
      }

      return [...prev, newUser];
    });
  };

  const setNewValue = (newValue: string | string[]) => {
    if (Array.isArray(newValue)) {
      setSelectedCharacteristics(newValue);

      return;
    }

    setSelectedCharacteristics((prevSelected) => {
      const isSelected = prevSelected.includes(newValue);

      if (isSelected) {
        return prevSelected.filter((item) => item !== newValue);
      }

      return [...prevSelected, newValue];
    });
  };

  const handleDragEnd = (result: DropResult) => {
    if (
      !result.destination
      || result.destination.droppableId === allUsersList
    ) {
      if (isDraggingUsers) {
        setIsDraggingUsers(false);
      }

      return;
    }

    const groupToDrop = groupsToSetup.find(
      (group) => group.id === result.destination?.droppableId,
    );

    if (!isDraggingUsers && groupToDrop) {
      const userEmail = result.draggableId;

      const isUserInList = groupToDrop.users.find(
        (user) => user.email === userEmail,
      );

      if (!isUserInList) {
        dispatch(
          addUsersToGroup({
            ...groupToDrop,
            users: [
              {
                email: userEmail,
              },
            ],
          }),
        );
      }
    }

    if (isDraggingUsers && groupToDrop && selectedUsers.length) {
      dispatch(
        addUsersToGroup({
          ...groupToDrop,
          users: selectedUsers,
        }),
      );

      setSelectedUsers([]);
    }

    setIsDraggingUsers(false);
  };

  const handleDragStart = (start: DragStart) => {
    const userEmail = start.draggableId;

    const isDraggedUserSelected = selectedUsers.find((user) => {
      return user.email === userEmail;
    });

    if (isDraggedUserSelected) {
      setIsDraggingUsers(true);
    }
  };

  const filtersSelectedCount = selectedCharacteristics?.length;

  const allFiltersCount = isFilterShowAll
    ? filtersSelectedCount
    : filtersSelectedCount + 1;

  const isLoading = isOrganizationUsersLoading || isOrganizationInvitesLoading;

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <DragDropContext
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
    >
      <Style.ContentContainer
        onMouseMove={(e) => {
          setCursorPosition({
            x: e.clientX,
            y: e.clientY,
          });
        }}
      >
        <Style.SectionContainer>
          <Style.SectionHeader>
            <Style.CenteredRowContainer>
              <Style.SectionTitle>{t('common.groups')}</Style.SectionTitle>

              <div>
                <Style.CountSpan>{`${t('common.created')}: `}</Style.CountSpan>

                <Style.CountSpan $isDark>
                  {groupsToSetup?.length}
                </Style.CountSpan>
              </div>
            </Style.CenteredRowContainer>

            <Style.SectionSubtitle>
              {t('organisation.manageMembersByGroup')}
            </Style.SectionSubtitle>
          </Style.SectionHeader>

          <GroupsList
            groups={groupsToSetup}
            onGroupAdded={onGroupAdded}
          />
        </Style.SectionContainer>

        <Style.Divider />

        <Style.SectionContainer>
          <Style.CenteredRowContainer $gap={24}>
            <Style.SectionHeader $grow>
              <Style.CenteredRowContainer>
                <Style.SectionTitle>{t('group.members')}</Style.SectionTitle>

                <Tooltip
                  message={(
                    <Style.TooltipContainer>
                      {`${usersToDisplay?.length}: ${t(
                        'organisation.filteredUsers',
                      )}`}

                      <br />

                      {`${countAllUsersToAdd}: ${t('organisation.allUsers')}`}
                    </Style.TooltipContainer>
                  )}
                  disabled={countAllUsersToAdd === 0 || !isFilteredUsers}
                >
                  <Style.SectionTitle>
                    {`${!isFilteredUsers ? '' : usersToDisplay?.length}${
                      isFilteredUsers ? '/' : ''
                    }${countAllUsersToAdd}`}
                  </Style.SectionTitle>
                </Tooltip>
              </Style.CenteredRowContainer>

              <Style.SectionSubtitle>
                {t('organisation.inviteToOrganisations')}
              </Style.SectionSubtitle>
            </Style.SectionHeader>

            <Style.FilterPopoverContainer>
              <FilterPopoverMenu
                tooltipMessage={t('organisation.filterMembers')}
                width={292}
              >
                <FilterMembers
                  isShowAll={isFilterShowAll}
                  setShowAll={setIsFilterShowAll}
                  changeCharacteristic={setNewValue}
                  selectedCharacteristics={selectedCharacteristics}
                />
              </FilterPopoverMenu>

              {!!allFiltersCount && (
                <Style.FiltersCount>{allFiltersCount}</Style.FiltersCount>
              )}
            </Style.FilterPopoverContainer>
          </Style.CenteredRowContainer>

          <Style.UsersToAddContainer>
            <MailListInput buttonTitle="add" />

            <StrictModeDroppable droppableId={allUsersList}>
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  <UsersCardList
                    allOrgUsers={usersToDisplay}
                    selectedUsers={selectedUsers}
                    handleSelectUser={handleSelectUser}
                    provided={provided}
                    isActiveDrag={isDraggingUsers}
                    cursorPosition={cursorPosition}
                  />
                </div>
              )}
            </StrictModeDroppable>
          </Style.UsersToAddContainer>
        </Style.SectionContainer>
      </Style.ContentContainer>

      {cursorPosition && isDraggingUsers && (
        <CustomCursor
          count={selectedUsers?.length}
          cursorPosition={cursorPosition}
        />
      )}
    </DragDropContext>
  );
};
