import {
  TimeSpansReducer, TimeSpansState
} from './timespansSlice';

const findActive = (val: TimeSpansState) => val.isSelected;

const selectAll = (state: TimeSpansReducer) => state.timeSpans;

const selectActiveTimeSpan = (state: TimeSpansReducer) => {
  return selectAll(state).find(findActive) || state.timeSpans[0];
};

export const timeSpanSelectors = {
  selectAll,
  selectActiveTimeSpan,
};
