import i18n from 'i18next';
import {
  initReactI18next
} from 'react-i18next';

import {
  resources
} from './resources';
import {
  TranslationKey
} from './types';

const initLocalization = () => {
  i18n.use(initReactI18next).init({
    compatibilityJSON: 'v3',
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    resources,
  });
};

const onChangeLanguage = (language: string) => {
  i18n.changeLanguage(language);
};

const getLanguage = () => i18n.language as TranslationKey;

export const LocalizationService = {
  initLocalization,
  onChangeLanguage,
  getLanguage,
};
