import React, {
  FC, useMemo, useState
} from 'react';
import {
  useLayer
} from 'react-laag';
import {
  AnimatePresence
} from 'framer-motion';
import {
  useNavigate
} from 'react-router-dom';
import {
  useTranslation
} from 'react-i18next';

import {
  ScrollContainer,
  SearchInput,
  ShowMoreButton,
} from 'src/shared/components';
import {
  themeColors, ROUTE, formatDateRange
} from 'src/shared/utils';
import {
  PeopleIcon
} from 'src/shared/icons';
import {
  useGetGlobalSearchQuery
} from 'src/redux/openapi';
import {
  useTypedSelector
} from 'src/redux';
import {
  languageSelectors
} from 'src/redux/language';
import {
  useHighlightedText
} from 'src/shared/hooks';

import * as Style from './HeaderSearch.styles';

const MAX_ROWS = 2;

export const HeaderSearch: FC = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isInputActive, setIsInputActive] = useState(false);
  const [showAllQuestions, setShowAllQuestions] = useState(false);
  const [showAllGroups, setShowAllGroups] = useState(false);

  const {
    t
  } = useTranslation();

  const navigate = useNavigate();

  const {
    data
  } = useGetGlobalSearchQuery(
    {
      query: searchQuery,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !searchQuery,
    },
  );

  const isMenu = isInputActive && !!searchQuery;

  const onGroupClick = (id: string) => {
    setIsInputActive(false);
    navigate(`/${ROUTE.GROUPS}/${id}`);
  };

  const onQuestionClick = (id: string) => {
    setIsInputActive(false);
    navigate(`/${ROUTE.QUESTION}/${id}`);
  };

  const {
    renderLayer, triggerProps, layerProps, triggerBounds
  } = useLayer({
    isOpen: isMenu,
    overflowContainer: false,
    placement: 'bottom-start',
    triggerOffset: 0,
    containerOffset: 16,
    onOutsideClick: () => setIsInputActive(false),
  });

  const {
    questions, groups
  } = useMemo(
    () => ({
      questions: data?.data.questions || [],
      groups: data?.data.groups || [],
    }),
    [data],
  );

  const visibleQuestions = useMemo(
    () => {
      return showAllQuestions || questions.length <= MAX_ROWS
        ? questions
        : questions.slice(
          0,
          MAX_ROWS
        );
    },
    [questions, showAllQuestions]
  );

  const visibleGroups = useMemo(
    () => {
      return showAllGroups || groups.length <= MAX_ROWS
        ? groups
        : groups.slice(
          0,
          MAX_ROWS
        );
    },
    [groups, showAllGroups]
  );

  const toggleShowQuestions = () => {
    setShowAllQuestions((prev) => !prev);

    if (showAllGroups) {
      setShowAllGroups(false);
    }
  };

  const toggleShowGroups = () => {
    setShowAllGroups((prev) => !prev);

    if (showAllQuestions) {
      setShowAllQuestions(false);
    }
  };

  const language = useTypedSelector(languageSelectors.getLanguage);

  const {
    getHighlightedText
  } = useHighlightedText({
    searchQuery,
  });

  return (
    <>
      <Style.InputContainer {...triggerProps}>
        <SearchInput
          disableBlurring
          isHeader
          placeholder={t('common.search')}
          value={searchQuery}
          onChange={setSearchQuery}
          size={40}
          iconColor={themeColors['dark-gray']}
          onStateChange={setIsInputActive}
          withCloseButton
          shouldGrow
        />
      </Style.InputContainer>

      {renderLayer(
        <AnimatePresence>
          {isMenu && (
            <Style.MenuContainer
              {...layerProps}
              className="combobox__menu"
              style={{
                width: triggerBounds?.width,
                ...layerProps.style,
              }}
              initial={{
                opacity: 0,
                scaleY: 0.75,
              }}
              animate={{
                opacity: 1,
                scaleY: 1,
              }}
              exit={{
                opacity: 0,
                scaleY: 0.75,
              }}
              transition={{
                duration: 0.125,
              }}
            >
              {!visibleQuestions.length && !visibleGroups.length && (
                <Style.EmptyMessage>
                  {t('empty.nothingFound')}
                </Style.EmptyMessage>
              )}

              {!!visibleQuestions.length && (
                <Style.SectionContainer $isOpen={showAllQuestions}>
                  <Style.Title>
                    {t('common.questions')}

                    <Style.Divider />
                  </Style.Title>

                  <ScrollContainer>
                    {visibleQuestions.map(
                      ({
                        id,
                        questionSet: {
                          title: questionSetTitle
                        },
                        startDate,
                        endDate,
                        title,
                      }) => (
                        <Style.RowContainer
                          key={id}
                          onClick={() => onQuestionClick(id)}
                        >
                          <Style.RowHeader>
                            <Style.HeaderText $bold>
                              {getHighlightedText(questionSetTitle)}
                            </Style.HeaderText>

                            <Style.HeaderText>
                              {formatDateRange({
                                start: startDate,
                                end: endDate,
                                language,
                              })}
                            </Style.HeaderText>
                          </Style.RowHeader>

                          <Style.RowTitle>
                            {getHighlightedText(title)}
                          </Style.RowTitle>
                        </Style.RowContainer>
                      ),
                    )}
                  </ScrollContainer>

                  {questions.length > MAX_ROWS && (
                    <ShowMoreButton
                      onClick={toggleShowQuestions}
                      isAllShown={showAllQuestions}
                    />
                  )}
                </Style.SectionContainer>
              )}

              {!!visibleGroups.length && (
                <Style.SectionContainer $isOpen={showAllGroups}>
                  <Style.Title>
                    {t('common.groups')}

                    <Style.Divider />
                  </Style.Title>

                  <ScrollContainer>
                    {visibleGroups.map(
                      ({
                        id, name, location, _count: {
                          users
                        }
                      }) => (
                        <Style.RowContainer
                          onClick={() => onGroupClick(id)}
                          key={id}
                          $isGroup
                        >
                          <Style.GroupContent>
                            {location && (
                            <Style.HeaderText $bold>
                              {getHighlightedText(location)}
                            </Style.HeaderText>
                            )}

                            <Style.RowTitle>
                              {getHighlightedText(name)}
                            </Style.RowTitle>
                          </Style.GroupContent>

                          <Style.HeaderSubtitle>
                            <PeopleIcon className="text-dim-gray h-6 w-5" />

                            <Style.HeaderText>{users}</Style.HeaderText>
                          </Style.HeaderSubtitle>
                        </Style.RowContainer>
                      ),
                    )}
                  </ScrollContainer>

                  {groups.length > MAX_ROWS && (
                    <ShowMoreButton
                      onClick={toggleShowGroups}
                      isAllShown={showAllGroups}
                    />
                  )}
                </Style.SectionContainer>
              )}
            </Style.MenuContainer>
          )}
        </AnimatePresence>,
      )}
    </>
  );
};
