import {
  useEffect, useState
} from 'react';

export interface TChildCheckbox {
  id: string;
}

export interface TParentCheckbox extends TChildCheckbox {
  childrenCheckboxes: TChildCheckbox[];
}

export interface SelectedItem {
  parentId: string;
  selectedChildrenId: string[];
}

export interface UseNestedSelect {
  selectedItems: {
    parentId: string;
    selectedChildrenId: string[];
  }[];
  onParentCheck: (parentItem: TParentCheckbox) => void;
  onChildCheck: (parentItem: TParentCheckbox, childId: string) => void;
  onCancel: () => void;
}

interface UseChooseQuestionsSetArgs<T extends SelectedItem> {
  initialSelectedItems?: T[];
}

export const useNestedSelect = <T extends SelectedItem>({
  initialSelectedItems = [],
}: UseChooseQuestionsSetArgs<T>): UseNestedSelect => {
  const [selectedItems, setSelectedItems] = useState<T[]>([]);

  useEffect(
    () => {
      if (initialSelectedItems.length) {
        setSelectedItems(initialSelectedItems);
      }
    },
    [initialSelectedItems]
  );

  const onCancel = () => {
    setSelectedItems(initialSelectedItems);
  };

  const onParentCheck = (parentItem: TParentCheckbox) => {
    const {
      id, childrenCheckboxes
    } = parentItem;

    const isSelected = selectedItems.some((item) => item.parentId === id);

    const updatedValue = isSelected
      ? selectedItems.filter((item) => item.parentId !== id)
      : ([
        ...selectedItems,
        {
          parentId: id,
          selectedChildrenId: childrenCheckboxes.map((child) => child.id),
        },
      ] as T[]);

    setSelectedItems(updatedValue);
  };

  const onChildCheck = (parentItem: TParentCheckbox, childId: string) => {
    let updatedValue = [];

    if (!selectedItems.some((item) => item.parentId === parentItem.id)) {
      updatedValue = [
        ...selectedItems,
        {
          parentId: parentItem.id,
          selectedChildrenId: parentItem.childrenCheckboxes
            .filter((child) => child.id === childId)
            .map((child) => child.id),
        },
      ];
    } else {
      updatedValue = selectedItems
        ?.map((item) => {
          if (item.parentId !== parentItem.id) {
            return item;
          }

          const isSelected = item.selectedChildrenId.some(
            (id) => childId === id,
          );

          if (!isSelected) {
            return {
              ...item,
              selectedChildrenId: [...item.selectedChildrenId, childId],
            };
          }

          return {
            ...item,
            selectedChildrenId: isSelected
              ? item.selectedChildrenId.filter((id) => childId !== id)
              : [...item.selectedChildrenId, childId],
          };
        })
        .filter((item) => item.selectedChildrenId.length);
    }

    setSelectedItems(updatedValue as T[]);
  };

  return {
    selectedItems,
    onParentCheck,
    onChildCheck,
    onCancel,
  };
};
