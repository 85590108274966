export enum ButtonTitles {
  Deactivated = 'deactivated',
  Update = 'update',
  Onboard = 'onboard',
  Participate = 'participate',
}

export interface Items {
  id: string;
  title: string;
  startDate: string | null;
  endDate: string | null;
}

export enum QuestionEntityStatus {
  Activated = 'ACTIVATED',
  Deactivated = 'DEACTIVATED',
}

export type QuestionEntityType = 'ACTIVATED' | 'DEACTIVATED' | null;

export enum QuestionStatus {
  Available = 'AVAILABLE',
  Deactivated = 'DEACTIVATED',
}

export enum QuestionSortBy {
  CreatedAt = 'createdAt',
  CommentCount = 'commentCount',
  ForecastCount = 'forecastCount',
  ParticipantsCount = 'participantsCount',
}

export enum HomeQuestionSortBy {
  CreatedAt = 'createdAt',
  CommentCount = 'commentsCount',
  UpdatesCount = 'updatesCount',
  ParticipantsCount = 'participantsCount',
}

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}
