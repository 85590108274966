import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const Stepper = styled.div`
  ${tw`flex gap-10 justify-center`}
`;

export const StepsContainer = styled.div`
  ${tw`flex gap-4 grow`}
`;

export const Step = styled.button<{
  $isActive?: boolean;
  $isCompleted?: boolean;
}>`
  ${tw`
    font-bold text-22-30 flex flex-col gap-2 flex-1
    after:(h-1 w-full rounded-100px bg-button-blue)
    transition ease-in-out whitespace-nowrap
  `}

  ${({
    $isActive
  }) => !$isActive && tw`font-medium text-dim-gray after:bg-gray-whisper`}

  ${({
    $isCompleted
  }) => $isCompleted && tw`font-medium text-dim-gray after:bg-forest-green`}
`;

export const NextStepButton = styled.button<{ $isOutline?: boolean }>`
  ${tw`
    flex items-center justify-center gap-1
    px-4 py-2.5 bg-button-blue rounded-100px
    text-15-20 font-bold text-white
    border border-button-blue
    hover:(bg-white text-button-blue) transition
  `}

  ${({
    $isOutline
  }) => $isOutline
    && tw`
    bg-white text-button-blue
    hover:(bg-button-blue text-white)
  `}
`;
