import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MainContainer = styled.div`
  ${tw`border border-gray94 rounded`}
`;

export const TopContentContainer = styled.div`
  ${tw`px-3 py-2 border-b border-gray94`}
`;

export const BottomContentContainer = styled.div`
  ${tw`px-3 py-3 font-medium text-dim-gray flex`}
`;

export const Head = styled.div`
  ${tw`flex justify-between items-center mb-1`}
`;

export const Title = styled.p`
  ${tw`font-semibold text-gray7`}
`;

export const StatusDescriptionContainer = styled.div`
  ${tw`flex gap-4 mb-4`}
`;

export const StatusDescription = styled.div`
  ${tw`flex items-center gap-1 font-medium text-xs leading-5 text-dim-gray`}
`;

export const StatusNumber = styled.p`
  ${tw`text-gray7`}
`;

export const StatusIndicator = styled.div<{ $color: string }>`
  ${tw`rounded-full h-2 w-2`}

  ${({
    $color
  }) => `background-color: ${$color};`}
`;

export const ProgressContainer = styled.div`
  ${tw`h-4 relative`}
`;

export const ProgressItem = styled.div<{
  $color: string;
  $widthPercent: number;
  $index: number;
}>`
  ${tw`rounded-1000px h-full absolute`}

  ${({
    $color, $widthPercent, $index
  }) => `
    background-color: ${$color};
    width: ${$widthPercent}%;
    z-index: ${$index};
  `}
`;
