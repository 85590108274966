import React, {
  FC
} from 'react';

import * as Style from './CustomCursor.style';

interface CustomCursorProps {
  count: number;
  cursorPosition: {
    x: number;
    y: number;
  };
}

export const CustomCursor: FC<CustomCursorProps> = ({
  count,
  cursorPosition,
}) => {
  return (
    <Style.CursorContainer
      $coordX={cursorPosition.x - 30}
      $coordY={cursorPosition.y - 30}
    >
      {count}
    </Style.CursorContainer>
  );
};
