import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const ProgressContainer = styled.div`
  ${tw`h-4 relative z-0`}
`;

export const ProgressItem = styled.div<{
  $color: string;
  $widthPercent: number;
  $index: number;
}>`
  ${tw`rounded-1000px h-full absolute`}

  ${({
    $color, $widthPercent, $index
  }) => `
    background-color: ${$color};
    width: ${$widthPercent}%;
    z-index: ${$index};
  `}
`;
