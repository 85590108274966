import tw from 'twin.macro';
import {
  styled
} from 'styled-components';
import {
  motion
} from 'framer-motion';

const PADDING = 16;
const GAP = 16;
const CONTENT_HEIGHT = 207;
const SECTION_HEIGHT = CONTENT_HEIGHT + 2 * PADDING + GAP;

export const InputContainer = styled.div`
  ${tw`hidden md:flex w-3/5 justify-end`}
`;

export const MenuContainer = styled(motion.div)`
  ${tw`bg-white shadow-menu-shadow p-4 rounded flex flex-col gap-4 overflow-y-auto mt-1 z-10`}

  max-height: calc(100vh - 80px - 24px);
`;

export const Divider = styled.div`
  ${tw`h-px bg-gray94`}
`;

export const Title = styled.div`
  ${tw`text-xs leading-5 font-medium text-dim-gray flex flex-col gap-1`}
`;

export const SectionContainer = styled.div<{ $isOpen?: boolean }>`
  ${tw`flex flex-col gap-2`}

  max-height: calc(100% - ${SECTION_HEIGHT}px);

  ${({
    $isOpen
  }) => $isOpen && tw`overflow-y-auto`}
`;

export const RowContainer = styled.div<{ $isGroup?: boolean }>`
  ${tw`flex px-3 py-2 cursor-pointer`}

  ${({
    $isGroup
  }) => ($isGroup ? tw`gap-4 justify-between items-center` : tw`flex-col gap-1`)}
`;

export const RowHeader = styled.div`
  ${tw`flex gap-4 items-center justify-between`}
`;

export const GroupContent = styled.div`
  ${tw`flex flex-col gap-1`}
`;

export const HeaderSubtitle = styled.div`
  ${tw`flex gap-2 items-center`}
`;

export const HeaderText = styled.div<{ $bold?: boolean }>`
  ${tw`font-medium text-13-21 text-dim-gray`}

  ${({
    $bold
  }) => $bold && tw`font-bold`}
`;

export const RowTitle = styled.div`
  ${tw`font-medium text-17-26 text-gray7`}
`;

export const ShowButton = styled.button`
  ${tw`flex items-center justify-center gap-1 font-semibold text-button-blue leading-6`}
`;

export const EmptyMessage = styled.p`
  ${tw`font-medium text-17-26 text-grey-dark-40 text-center`}
`;

export const HighlightedText = styled.span`
  ${tw`bg-golden-yellow rounded`}
`;
