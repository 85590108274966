import React, {
  Dispatch,
  FC,
  useEffect,
  useMemo,
  useState,
  SetStateAction,
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  UserForMerge,
  GroupForMerge,
  useGetGroupUsersFromGroupQuery,
} from 'src/redux/openapi';
import {
  Checkbox, Spinner
} from 'src/shared/components';
import {
  Filters
} from 'src/features/Filters';
import {
  ChooseItemsListContainer,
  ChooseItemsListItem,
  ChooseItemsItemContent,
  ChooseItemsLightText,
} from 'src/widgets/';

import {
  SelectedUsers
} from '../../types';

import * as Style from './ChooseMemberContent.styles';

interface ChooseMemberContentProps {
  allGroups: GroupForMerge[];
  currentGroup: { id: string; name: string };
  selectedItems: SelectedUsers[];
  setSelectedItems: Dispatch<SetStateAction<SelectedUsers[]>>;
}

export const ChooseMemberContent: FC<ChooseMemberContentProps> = ({
  currentGroup,
  allGroups,
  selectedItems,
  setSelectedItems,
}) => {
  const [activeFilterId, setActiveFilterId] = useState<string | null>(null);

  const currentSelectedItems = selectedItems.find((item) => {
    return item.groupId === activeFilterId;
  })?.users || [];

  const totalSelected = selectedItems.flatMap((item) => item.users).length;

  useEffect(
    () => {
      if (allGroups.length) {
        setActiveFilterId(allGroups[0].id);
      }
    },
    [allGroups]
  );

  const preparedFilters = useMemo(
    () => {
      return allGroups.map(({
        id, name, activeUsersCount
      }) => ({
        id,
        label: name,
        activeUsersCount,
      }));
    },
    [allGroups, currentGroup]
  );

  const {
    t
  } = useTranslation();

  const {
    users, isUsersLoading
  } = useGetGroupUsersFromGroupQuery(
    {
      id: activeFilterId || '',
      // TODO: Add pagination after redesign or BE changes
      limit: 1000,
    },
    {
      skip: !activeFilterId,
      selectFromResult: ({
        data, isLoading, isFetching
      }) => ({
        users: data?.data.users || [],
        isUsersLoading: isLoading || isFetching,
      }),
      refetchOnMountOrArgChange: true,
    },
  );

  const isAllSelected = currentSelectedItems.length === users.length;

  const handleSetSelectedItems = (value: UserForMerge | UserForMerge[]) => {
    if (!activeFilterId) {
      return;
    }

    const isExists = selectedItems.some(
      (item) => item.groupId === activeFilterId,
    );

    const isAll = Array.isArray(value);

    if (!isExists) {
      setSelectedItems((prevSelectedItems) => [
        ...prevSelectedItems,
        {
          groupId: activeFilterId,
          users: isAll ? value : [value],
        },
      ]);

      return;
    }

    if (isAll) {
      setSelectedItems((prevSelectedItems) => prevSelectedItems.map((item) => {
        return item.groupId === activeFilterId
          ? {
            ...item,
            users: value,
          }
          : item;
      }),);

      return;
    }

    setSelectedItems((prevSelectedItems) => {
      return prevSelectedItems.map((item) => {
        if (item.groupId === activeFilterId) {
          return {
            ...item,
            users: item.users.some((user) => user.userId === value.userId)
              ? item.users.filter((user) => user.userId !== value.userId)
              : [...item.users, value],
          };
        }

        return item;
      });
    });
  };

  const selectAll = () => {
    const itemsToSelect = isAllSelected ? [] : users;

    handleSetSelectedItems(itemsToSelect);
  };

  return (
    <Style.ContentContainer>
      <Style.TitleContainer>
        <Style.Title>
          {t(
            'group.moveUsersToGroup',
            {
              groupName: currentGroup?.name,
            }
          )}
        </Style.Title>

        <Style.Subtitle>
          {`${t('group.usersSelected')}: `}

          <Style.SelectedCount>{totalSelected}</Style.SelectedCount>
        </Style.Subtitle>
      </Style.TitleContainer>

      <Filters
        activeFilterId={activeFilterId}
        items={preparedFilters}
        onFilterClick={setActiveFilterId}
      />

      {isUsersLoading ? (
        <Spinner size={24} />
      ) : (
        <>
          {!!users.length && (
            <ChooseItemsListContainer className="pb-6">
              <ChooseItemsListItem>
                <Checkbox
                  onChange={selectAll}
                  isChecked={isAllSelected}
                  type="checkMark"
                />

                <ChooseItemsLightText>
                  {t('buttons.selectAll')}
                </ChooseItemsLightText>
              </ChooseItemsListItem>

              {users.map((item, index) => {
                const isChecked = currentSelectedItems.some(
                  (prevItem) => prevItem.email === item.email,
                );

                return (
                  <ChooseItemsListItem key={item.email}>
                    <Checkbox
                      onChange={() => handleSetSelectedItems(item)}
                      isChecked={isChecked}
                      type="checkMark"
                    />

                    <ChooseItemsItemContent>
                      <ChooseItemsLightText>
                        {`${
                          index + 1
                        }.`}
                      </ChooseItemsLightText>

                      <p>{item.email}</p>
                    </ChooseItemsItemContent>
                  </ChooseItemsListItem>
                );
              })}
            </ChooseItemsListContainer>
          )}

          {!users.length && (
            <Style.EmptyMessage>{t('empty.noMembersFound')}</Style.EmptyMessage>
          )}
        </>
      )}
    </Style.ContentContainer>
  );
};
