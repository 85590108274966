import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const ListContainer = styled.div`
  ${tw`flex flex-col gap-4 overflow-y-auto`}

  max-height: calc(100vh - 24px);
`;

export const AddButton = styled.button`
  ${tw`text-button-blue font-bold text-15-20 flex gap-1 items-center justify-center`}
`;
