import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const BlueSpan = styled.span`
  ${tw`underline text-button-blue`}

  text-decoration-skip-ink: none;
`;

export const Container = styled.div`
  ${tw`flex flex-row gap-2 items-start`}
`;

export const ParagraphWrapper = styled.div`
  ${tw`flex flex-col gap-1`}
`;

export const Paragraph = styled.p`
  ${tw`font-['DM_Sans'] font-normal text-base`}
`;

export const ErrorMessage = styled.div`
  ${tw`text-13-21 font-medium text-red-text-error mt-1 ml-8`}
`;
