import React, {
  FC
} from 'react';
import {
  useLocation, useNavigate
} from 'react-router-dom';
import {
  Trans, useTranslation
} from 'react-i18next';

import {
  ModalDelete, successfulToast
} from 'src/shared/components';
import {
  ROUTE, showWarningFromServer
} from 'src/shared/utils';
import {
  useDeleteOrganizationMutation
} from 'src/redux/openapi';

interface DeleteOrganizationModalProps {
  id?: string | null;
  onSuccessDelete?: () => void;
  closeModal: () => void;
  isOpen: boolean;
}

export const DeleteOrganizationModal: FC<DeleteOrganizationModalProps> = ({
  id,
  onSuccessDelete,
  closeModal,
  isOpen,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const isCurrentOrgPage = !!id && location.pathname.includes(id);

  const [deleteOrganizationApi, {
    isLoading
  }] = useDeleteOrganizationMutation();

  const onDelete = async () => {
    if (isLoading || !id) {
      return;
    }

    try {
      const response = await deleteOrganizationApi({
        id,
      }).unwrap();

      successfulToast(response?.message);

      if (onSuccessDelete) {
        onSuccessDelete();
      }

      if (isCurrentOrgPage) {
        navigate(`/${ROUTE.ORGANISATION}`);
      }

      closeModal();
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  const {
    t
  } = useTranslation();

  const title = (
    <Trans
      i18nKey="modal.sureWantToDelete"
      values={{
        type: t('modal.organization'),
      }}
    />
  );

  const modalText = (
    <Trans
      i18nKey="modal.afterDeleting"
      values={{
        type: t('modal.anOrganization'),
        action: t('modal.membersWillBeDissolvedByGroups'),
      }}
    />
  );

  return (
    <ModalDelete
      isOpen={isOpen}
      closeModal={closeModal}
      title={title}
      modalText={modalText}
      onDelete={onDelete}
      isLoading={isLoading}
      modalWidth={571}
    />
  );
};
