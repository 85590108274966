import React, {
  FC, useEffect, useRef, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  GroupMembersBlock,
  GroupQuestionsBlock,
} from 'src/entities/GroupsRoute';
import {
  YourFeed
} from 'src/entities/YourFeed';
import {
  Group, UserGroupRole
} from 'src/redux/openapi';
import {
  getGroupUserRole
} from 'src/entities/GroupsRoute/utils';

import * as Style from './GroupCurrent.styles';

interface GroupCurrentProps {
  group: Group;
}

export const GroupCurrent: FC<GroupCurrentProps> = ({
  group
}) => {
  const {
    t
  } = useTranslation();

  const sectionsContainerRef = useRef<HTMLDivElement>(null);
  const [sectionsShift, setSectionsShift] = useState(0);
  const [isAlertOpen, setIsAlertOpen] = useState(true);

  useEffect(
    () => {
      if (!isAlertOpen) {
        setIsAlertOpen(true);
      }
    },
    [group]
  );

  const {
    memberCount,
    groupRole,
    userRole,
    organizationRole,
    status,
    organizationStatus,
  } = group;

  const role = getGroupUserRole({
    groupRole:
      organizationStatus === 'ACCEPTED' ? UserGroupRole.USER : groupRole,
    organizationRole,
    userRole,
  });

  const isAdminView = role === UserGroupRole.ADMIN;
  const isSuperAdminView = role === UserGroupRole.SUPERADMIN;

  const isAdmin = isAdminView || isSuperAdminView;

  const isComplete = memberCount >= 5;
  const isDeactivated = status === 'DEACTIVATED';

  const isGroupInOrganization = group.organizationStatus === 'ACCEPTED';

  useEffect(
    () => {
      if (sectionsContainerRef.current) {
        const shift = sectionsContainerRef.current?.offsetTop || 0;

        setSectionsShift(shift);
      }
    },
    [group]
  );

  return (
    <div>
      {group && (
        <Style.SectionsContainer
          ref={sectionsContainerRef}
          $shift={sectionsShift}
          $isComplete={isComplete}
        >
          <GroupQuestionsBlock
            groupId={group.id}
            isAdmin={isAdmin}
            isDeactivated={isDeactivated}
            canOnboardingText={!isGroupInOrganization && isAdmin}
          />

          <GroupMembersBlock
            group={group}
            isAdminView={isAdminView}
            isSuperAdminView={isSuperAdminView}
          />

          {isComplete && (
            <YourFeed
              isExpanded
              headerTitle={t('common.discussion')}
            />
          )}
        </Style.SectionsContainer>
      )}
    </div>
  );
};
