import React, {
  FC, useState
} from 'react';
import {
  Trans, useTranslation
} from 'react-i18next';

import {
  getRoundedDifferenceToNow,
  getTimeAgo,
  showWarningFromServer,
} from 'src/shared/utils';
import {
  Avatar, successfulToast
} from 'src/shared/components';
import {
  GroupStats,
  usePatchGroupUsersInvitesAcceptMutation,
  usePatchGroupUsersInvitesDeclineMutation,
} from 'src/redux/openapi';
import {
  AnswerStatus
} from 'src/shared/api/notifications/types';
import {
  NotificationContainer
} from 'src/entities/NotificationsRoute';

import {
  NotificationGroupCard
} from '../NotificationGroupCard';
import {
  ConfirmButton
} from '../ConfirmButton';
import {
  ShowDetailsButton
} from '../ShowDetailsButton/ShowDetailsButton';

import * as Style from './Notifications.styles';
import {
  getAnswerStatus
} from './utils';

interface GroupNotificationProps {
  createdAt: string;
  userName: string;
  userAvatarUrl: string | null;
  groupName: string;
  groupId: string;
  isNew: boolean;
  answerStatus: AnswerStatus;
  notificationId: string;
  getHighlightedText: (text: string) => (string | JSX.Element)[];
  invitation: {
    invitationMessage: string | null;
    invitationExpiration: string | null;
  } | null;
}

export const GroupNotification: FC<GroupNotificationProps> = ({
  createdAt,
  userName,
  userAvatarUrl,
  groupName,
  groupId,
  isNew,
  answerStatus,
  notificationId,
  invitation,
  getHighlightedText,
}) => {
  const [status, setStatus] = useState(getAnswerStatus(answerStatus));
  const [groupStats, setGroupStats] = useState<GroupStats | null>(null);

  const {
    t
  } = useTranslation();

  const [acceptInvitation, {
    isLoading: isAcceptLoading
  }] = usePatchGroupUsersInvitesAcceptMutation();

  const [denyInvitation, {
    isLoading: isDenyLoading
  }] = usePatchGroupUsersInvitesDeclineMutation();

  const isLoading = isAcceptLoading || isDenyLoading;

  const handleAnswer = async (answer: boolean) => {
    try {
      const response = answer
        ? await acceptInvitation({
          id: groupId,
          acceptLoggedInUserInviteBodySchema: {
            notificationId,
          },
        }).unwrap()
        : await denyInvitation({
          id: groupId,
          declineLoggedInUserInviteBodySchema: {
            notificationId,
          },
        }).unwrap();

      setStatus(
        answer
          ? getAnswerStatus(AnswerStatus.ACCEPTED)
          : getAnswerStatus(AnswerStatus.REJECTED),
      );

      successfulToast(response.message);
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  return (
    <NotificationContainer $isNew={isNew}>
      <Style.HeaderWithAvatarWrapper>
        <Style.ImageContainer $isNew={isNew}>
          <Avatar
            size={56}
            src={userAvatarUrl}
            userName={userName}
          />
        </Style.ImageContainer>

        <Style.Description>
          <Style.NotificationHeader>
            <Style.NotificationDate>
              {`${getTimeAgo(createdAt)} ${t('date.ago')}`}
            </Style.NotificationDate>

            {status ? (
              <Style.TextAnswer>{status}</Style.TextAnswer>
            ) : (
              <Style.NotificationDate $isDark>
                {invitation?.invitationExpiration
                  && `${t('common.groupInvite')} ${getRoundedDifferenceToNow(
                    invitation?.invitationExpiration,
                  )} ${t('common.left')}`}
              </Style.NotificationDate>
            )}
          </Style.NotificationHeader>

          <Style.NotificationText>
            <Trans
              i18nKey="common.invitedYouToJoinTheGroup"
              components={{
                1: (
                  <Style.NotificationPerson>
                    {getHighlightedText(userName)}
                  </Style.NotificationPerson>
                ),
                2: (
                  <Style.NotificationPerson>
                    {getHighlightedText(groupName)}
                  </Style.NotificationPerson>
                ),
              }}
            />

            {invitation?.invitationMessage && (
              <>
                {`: `}

                {getHighlightedText(invitation.invitationMessage)}
              </>
            )}
          </Style.NotificationText>
        </Style.Description>
      </Style.HeaderWithAvatarWrapper>

      {!status && !groupStats && (
        <ShowDetailsButton
          notificationId={notificationId}
          onDetailsLoaded={setGroupStats}
          withShift
        />
      )}

      {!status && groupStats && (
        <>
          <NotificationGroupCard
            groupName={getHighlightedText(groupName)}
            groupStats={groupStats}
            withLeftShift
          />

          <Style.ButtonsContainer
            className="mt-3"
            $withShift
          >
            <ConfirmButton
              variant="big-blue"
              onClick={() => handleAnswer(true)}
              disabled={isLoading}
              isLoading={isAcceptLoading}
            >
              {t('buttons.confirm')}
            </ConfirmButton>

            <ConfirmButton
              variant="big-grey-bordered"
              onClick={() => handleAnswer(false)}
              isLoading={isDenyLoading}
              disabled={isLoading}
            >
              {t('buttons.deny')}
            </ConfirmButton>
          </Style.ButtonsContainer>
        </>
      )}
    </NotificationContainer>
  );
};
