import React, {
  FC
} from 'react';

import {
  themeColors
} from 'src/shared/utils';

import * as Style from './RoundSvgBorder.style';

interface RoundSvgBorderProps {
  size: number;
  countDown: number;
  countTotal: number;
  strokeWidth: number;
}

export const RoundSvgBorder: FC<RoundSvgBorderProps> = ({
  size,
  countDown,
  countTotal,
  strokeWidth,
}) => {
  const radius = (size + strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const progress = 1 - countDown / countTotal;

  return (
    <Style.SvgContainer>
      <svg
        width={size + strokeWidth}
        height={size + strokeWidth}
      >
        <circle
          cx={radius}
          cy={radius}
          r={radius - strokeWidth / 2}
          fill="transparent"
          stroke={themeColors['button-blue']}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeDasharray={circumference}
          strokeDashoffset={circumference * (1 - progress)}
          className="transition-all ease-in-out"
        />
      </svg>
    </Style.SvgContainer>
  );
};
