import {
  ROUTE
} from 'src/shared/utils/routes';

enum HeaderLinks {
  QUESTION = 'question',
  COMMUNITY = 'community',
  GROUPS = 'groups',
  ORGANISATION = 'organisation',
  PRIVACY_POLICY = 'privacyPolicy',
  TERMS_REGULATIONS = 'termsAndConditions',
  FAQ = 'faq',
  ARQ_MANAGEMENT = 'arqManagement',
  CALIBRATION_TOOL = 'calibrationTool',
  LEARN = 'learn',
  HELP_CENTER = 'helpCenter',
}

enum HeaderLinksDescription {
  CALIBRATION_TOOL = 'calibrationToolDescription',
  HELP_CENTER = 'helpCenterDescription',
}

export interface Tab {
  value: HeaderLinks;
  url: ROUTE | string;
  forAdminsOnly?: boolean;
  description?: HeaderLinksDescription;
  subTabs?: Tab[];
}

export const tabs: Tab[] = [
  {
    value: HeaderLinks.QUESTION,
    url: ROUTE.QUESTION,
  },
  {
    value: HeaderLinks.COMMUNITY,
    url: ROUTE.COMMUNITY,
  },
  {
    value: HeaderLinks.GROUPS,
    url: ROUTE.GROUPS,
  },
  {
    value: HeaderLinks.ORGANISATION,
    url: ROUTE.ORGANISATION,
  },
  {
    value: HeaderLinks.ARQ_MANAGEMENT,
    url: ROUTE.ARQ_MANAGEMENT,
    forAdminsOnly: true,
  },
  {
    value: HeaderLinks.LEARN,
    url: ROUTE.LEARN,
    forAdminsOnly: true,
    subTabs: [
      {
        value: HeaderLinks.CALIBRATION_TOOL,
        url: ROUTE.CALIBRATION_TOOL,
        forAdminsOnly: true,
        description: HeaderLinksDescription.CALIBRATION_TOOL,
      },
    ],
  },
];

export const tabsGeneral: Tab[] = [
  {
    value: HeaderLinks.PRIVACY_POLICY,
    url: ROUTE.PRIVACY_POLICY,
  },
  {
    value: HeaderLinks.TERMS_REGULATIONS,
    url: ROUTE.TERMS_REGULATIONS,
  },
  {
    value: HeaderLinks.FAQ,
    url: ROUTE.FAQ,
  },
];
