import {
  SortOrder
} from 'src/shared/api/types';

export enum SortBy {
  Newest = 'Newest',
  Oldest = 'Oldest',
  Higher = 'Higher',
  Lower = 'Lower',
}

enum FilterSortBy {
  DeactivatedAt = 'deactivatedAt',
  Score = 'score',
}

export const getScoresFilters = (sortBy: SortBy) => {
  switch (sortBy) {
    case SortBy.Lower:
      return {
        sortBy: FilterSortBy.Score,
        sortOrder: SortOrder.Asc,
      };

    case SortBy.Higher:
      return {
        sortBy: FilterSortBy.Score,
        sortOrder: SortOrder.Desc,
      };

    case SortBy.Oldest:
      return {
        sortBy: FilterSortBy.DeactivatedAt,
        sortOrder: SortOrder.Asc,
      };

    case SortBy.Newest:
    default:
      return {
        sortBy: FilterSortBy.DeactivatedAt,
        sortOrder: SortOrder.Desc,
      };
  }
};

export const getScoreResult = (result: boolean | string) => {
  if (typeof result === 'string') {
    return result;
  }

  return result ? 'Yes' : 'No';
};
