import React, {
  FC,
  PropsWithChildren,
  ReactElement,
  useState,
  HTMLProps,
  useEffect,
  useMemo,
} from 'react';
import {
  useLayer, Placement, TriggerProps
} from 'react-laag';
import {
  AnimatePresence
} from 'framer-motion';
import clsx from 'clsx';

import * as Style from './PopoverMenu.styles';

interface CustomButtonProps {
  toggleMenu: () => void;
  isOpen: boolean;
  triggerProps: TriggerProps;
}

interface PopoverMenuProps extends PropsWithChildren {
  buttonContent?: ReactElement;
  renderCustomButton?: (props: CustomButtonProps) => ReactElement;
  buttonProps?: HTMLProps<HTMLButtonElement>;
  containerProps?: HTMLProps<HTMLDivElement>;
  onClose?: () => void;
  onOpen?: () => void;
  customShift?: number;
  auto?: boolean;
  placement?: Placement;
  possiblePlacements?: Placement[];
  opacity?: number;
  disabled?: boolean;
  shouldForcedClose?: boolean;
  transparent?: boolean;
  applyMaxHeight?: boolean;
  applyFullHeight?: boolean;
  maxHeight?: number;
  borderRadius?: number;
  hideScrollBar?: boolean;
  shouldCloseOnOutsideClick?: boolean;
  shouldCloseOnParentDisappear?: boolean;
  zIndex?: number;
}

export const PopoverMenu: FC<PopoverMenuProps> = ({
  children,
  buttonContent,
  onClose,
  onOpen,
  buttonProps,
  customShift = 8,
  auto = true,
  placement = 'bottom-start',
  possiblePlacements,
  opacity = 1,
  disabled = false,
  shouldForcedClose = false,
  renderCustomButton,
  transparent = false,
  applyMaxHeight = true,
  borderRadius = 4,
  hideScrollBar = false,
  shouldCloseOnOutsideClick = true,
  shouldCloseOnParentDisappear = false,
  applyFullHeight = false,
  maxHeight,
  zIndex,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);

    if (onClose) {
      onClose();
    }
  };

  const toggleMenu = () => setIsOpen((prevOpen) => {
    if (prevOpen && onClose) {
      onClose();
    }

    if (!prevOpen && onOpen) {
      onOpen();
    }

    return !prevOpen;
  });

  const onOutsideClick = () => {
    if (!shouldCloseOnOutsideClick) {
      return;
    }

    toggleMenu();
  };

  useEffect(
    () => {
      if (shouldForcedClose) {
        toggleMenu();
      }
    },
    [shouldForcedClose]
  );

  const onDisappear = () => {
    if (shouldCloseOnParentDisappear) {
      toggleMenu();
    }
  };

  const {
    triggerProps, layerProps, renderLayer, triggerBounds
  } = useLayer({
    isOpen,
    onOutsideClick,
    placement,
    possiblePlacements,
    triggerOffset: customShift,
    auto,
    onDisappear,
    containerOffset: 8,
    arrowOffset: 8,
    onParentClose: handleClose,
  });

  const layerStart = useMemo(
    () => {
      if (placement.includes('bottom')) {
        return triggerBounds?.bottom || 0 + customShift;
      }

      return triggerBounds?.top || 0;
    },
    [triggerBounds]
  );

  return (
    <>
      {buttonContent && (
        <button
          {...buttonProps}
          {...triggerProps}
          type="button"
          onClick={toggleMenu}
          disabled={disabled}
        >
          {buttonContent}
        </button>
      )}

      {renderCustomButton
        && renderCustomButton({
          toggleMenu,
          isOpen,
          triggerProps,
        })}

      {renderLayer(
        <AnimatePresence>
          {isOpen && (
            <Style.Container
              id="PopoverMenuLayer"
              className={clsx({
                'scroll-hidden': hideScrollBar,
              })}
              $transparent={transparent}
              $layerStart={layerStart}
              $applyMaxHeight={applyMaxHeight}
              $applyFullHeight={applyFullHeight}
              $borderRadius={borderRadius}
              $maxHeight={maxHeight}
              $zIndex={zIndex}
              initial={{
                opacity: 0,
                scale: 0.9,
              }}
              animate={{
                opacity,
                scale: 1,
              }}
              exit={{
                opacity: 0,
                scale: 0.9,
              }}
              transition={{
                duration: 0.1,
              }}
              {...layerProps}
            >
              {children}
            </Style.Container>
          )}
        </AnimatePresence>,
      )}
    </>
  );
};
