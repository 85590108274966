import {
  useMemo, useState, useRef
} from 'react';
import {
  useLocation, useNavigate, useParams
} from 'react-router-dom';
import {
  useTranslation
} from 'react-i18next';

import {
  useGetOrgQuestionsOnboardingTextQuery,
  usePostOrgQuestionsAdditionMutation,
  usePostOrgQuestionsRemovalDeactivatedMutation,
  usePostOrgQuestionsRemovalMutation,
  OrgToDeactivateQuestions,
  useGetQuestionSetsEditingQuery,
} from 'src/redux/openapi';
import {
  useNestedSelect
} from 'src/shared/hooks';
import {
  areAllArraysInObjectEmpty,
  ROUTE,
  showWarningFromServer,
} from 'src/shared/utils';
import {
  successfulToast,
  useStepper,
  warningToast,
} from 'src/shared/components';
import {
  useSetOnboardingTextList
} from 'src/widgets';

import {
  getStepsMap, Steps, getNextStepButtonConfig
} from '../utils';

export const useEditQuestionsInBulk = () => {
  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
  const [isSavingInProcess, setIsSavingInProcess] = useState(false);

  const [deactivationList, setDeactivationList] = useState<OrgToDeactivateQuestions | null>(null);

  const resolveRef = useRef<((value: boolean) => void) | null>(null);

  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  const {
    organizationId = ''
  } = params;

  const isAdding = location.pathname.includes(ROUTE.ADD_IN_BULK);

  const steps = getStepsMap(isAdding);

  const {
    t
  } = useTranslation();

  const {
    completeCurrentStep,
    activeStepId,
    isLastStep,
    handleNextStep,
    completedStatus,
  } = useStepper(steps);

  const nextStepButtonConfig = useMemo(
    () => getNextStepButtonConfig({
      isLastStep,
      isAdding,
    }),
    [isLastStep, isAdding],
  );

  const {
    questionSets, isQuestionSetsLoading
  } = useGetQuestionSetsEditingQuery(
    {
      // TODO: add pagination after BE or design changing
      limit: 5000,
      filters: {
        notStatus: 'DEACTIVATED',
      },
      sortBy: 'startDate',
      sortOrder: 'asc',
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({
        data, isLoading
      }) => ({
        questionSets: data?.data.questionSets || [],
        isQuestionSetsLoading: isLoading,
      }),
    },
  );

  const initialSelectedItems = useMemo(
    () => {
      if (isAdding) {
        return questionSets
          .filter((set) => set.questions.some((question) => question.isMandatory))
          .map((set) => ({
            parentId: set.id,
            selectedChildrenId: set.questions
              .filter((question) => question.isMandatory)
              .map((question) => question.id),
          }));
      }

      return [];
    },
    [questionSets]
  );

  const selectConfig = useNestedSelect({
    initialSelectedItems,
  });

  const selectedQuestionsIds = useMemo(
    () => selectConfig.selectedItems.flatMap((items) => items.selectedChildrenId),
    [selectConfig],
  );

  const {
    questionsWithOnboardingText, isQuestionsWithTextLoading
  } = useGetOrgQuestionsOnboardingTextQuery(
    {
      id: organizationId,
      questionIds: selectedQuestionsIds,
    },
    {
      skip: activeStepId !== Steps.SetOnboardingText,
      selectFromResult: ({
        data, isLoading, isFetching
      }) => ({
        questionsWithOnboardingText: data?.data.questions || [],
        isQuestionsWithTextLoading: isLoading || isFetching,
      }),
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    questionsOnboardingText, onTextChange
  } = useSetOnboardingTextList(
    questionsWithOnboardingText,
  );

  const navigateToOrganization = () => navigate(`/${ROUTE.ORGANISATION}/${organizationId}`);

  const [addQuestions] = usePostOrgQuestionsAdditionMutation();
  const [removeQuestions] = usePostOrgQuestionsRemovalMutation();

  const [getQuestionsToDeactivate] = usePostOrgQuestionsRemovalDeactivatedMutation();

  const onAddQuestions = async () => {
    try {
      setIsSavingInProcess(true);

      const questions = questionsOnboardingText.map((question) => (question.onboardingText?.trim()
        ? question
        : {
          ...question,
          onboardingText: null,
        }),);

      const response = await addQuestions({
        id: organizationId,
        addBatchOrganizationQuestionsBodySchema: {
          questions,
          groupIds: selectedGroups,
        },
      }).unwrap();

      successfulToast(response.message);

      navigateToOrganization();
    } catch (error) {
      showWarningFromServer(error);
    } finally {
      setIsSavingInProcess(false);
    }
  };

  const waitToDeactivationConfirmation = () => {
    return new Promise((resolve) => {
      resolveRef.current = resolve;
    });
  };

  const handleProceed = (shouldProceed = false) => {
    if (resolveRef.current) {
      resolveRef.current(shouldProceed);
      resolveRef.current = null;
    }
  };

  const clearDeactivationList = () => {
    setDeactivationList(null);
  };

  const checkQuestionsToDeactivate = async () => {
    const listToDeactivate = await getQuestionsToDeactivate({
      id: organizationId,
      fetchBatchRemoveOrganizationsQuestionsToDeactivateBodySchema: {
        questionIds: selectedQuestionsIds,
        groupIds: selectedGroups,
      },
    }).unwrap();

    if (!listToDeactivate.data) {
      return null;
    }

    const isItemsToDeactivate = !areAllArraysInObjectEmpty(
      listToDeactivate.data as Record<string, unknown>,
    );

    if (!isItemsToDeactivate) {
      return null;
    }

    return listToDeactivate.data.deactivationList;
  };

  const saveRemoving = async () => {
    try {
      const response = await removeQuestions({
        id: organizationId,
        removeBatchOrganizationQuestionsBodySchema: {
          questionIds: selectedQuestionsIds,
          groupIds: selectedGroups,
        },
      }).unwrap();

      successfulToast(response.message);

      navigateToOrganization();
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  const onRemoveQuestions = async () => {
    try {
      setIsSavingInProcess(true);

      const deactivatedList = await checkQuestionsToDeactivate();

      if (!deactivatedList) {
        await saveRemoving();

        return;
      }

      setIsSavingInProcess(false);

      setDeactivationList(deactivatedList);

      const confirmResult = await waitToDeactivationConfirmation();

      if (confirmResult) {
        setIsSavingInProcess(true);
        await saveRemoving();
      }
    } catch (error) {
      showWarningFromServer(error);
    } finally {
      setIsSavingInProcess(false);
    }
  };

  const handleComplete = async () => {
    if (!selectedGroups.length && activeStepId === Steps.ChooseGroups) {
      return warningToast(t('group.selectAtLeastOne'));
    }

    return isAdding ? onAddQuestions() : onRemoveQuestions();
  };

  const completeWithChangeStep = () => {
    completeCurrentStep();
    handleNextStep();
  };

  const onNextStepClick = () => {
    switch (activeStepId) {
      case Steps.ChooseQuestions:
        if (!selectConfig.selectedItems.length) {
          return warningToast(t('question.selectAtLeastOne'));
        }

        return completeWithChangeStep();

      case Steps.SetOnboardingText:
        return completeWithChangeStep();

      case Steps.ChooseGroups:
        return handleComplete();

      default:
        return handleNextStep();
    }
  };

  const isDataLoading = isQuestionSetsLoading || isQuestionsWithTextLoading;

  return {
    questionSets,
    isDataLoading,
    selectConfig,
    nextStepButtonConfig,
    steps,
    activeStepId,
    onNextStepClick,
    questionsOnboardingText,
    onTextChange,
    completedStatus,
    selectedGroups,
    setSelectedGroups,
    isAdding,
    isSavingInProcess,
    deactivationList,
    handleProceed,
    clearDeactivationList,
  };
};
