import React, {
  FC, useEffect, useState, useMemo, useCallback
} from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import {
  useTranslation
} from 'react-i18next';
import i18next from 'i18next';

import {
  GlobeIcon,
  HeightIcon,
  PeopleIcon,
  PersonIcon,
  TrendingUpIcon,
} from 'src/shared/icons';
import {
  formatDateRange,
  getProfileTimePeriodSelectValue,
  replaceDotWithComma,
  showWarningFromServer,
} from 'src/shared/utils';
import {
  SortPopoverMenu
} from 'src/shared/components/SortPopoverMenu';
import {
  Spinner
} from 'src/shared/components';
import {
  UserScores,
  ScoresSets,
  useGetUsersCurrentScoresMutation,
} from 'src/redux/openapi';
import {
  StatisticTile
} from 'src/entities/Questions';
import {
  useTypedSelector
} from 'src/redux';
import {
  languageSelectors
} from 'src/redux/language';
import {
  useMediaQuery
} from 'src/shared/hooks';

import {
  Select, TimePeriodSelect, TimePeriodSelectOption
} from '../Select';

import * as Style from './ScoresTabContent.styles';
import {
  getScoreResult, getScoresFilters, SortBy
} from './utils';

const getSortOptionsMap = () => [
  {
    id: SortBy.Newest,
    label: i18next.t('profile.Newest'),
    icon: <HeightIcon />,
  },
  {
    id: SortBy.Oldest,
    label: i18next.t('profile.Oldest'),
    icon: <HeightIcon className="-scale-y-100" />,
  },
  {
    id: SortBy.Higher,
    label: i18next.t('profile.Higher'),
    icon: <TrendingUpIcon />,
  },
  {
    id: SortBy.Lower,
    label: i18next.t('profile.Lower'),
    icon: <TrendingUpIcon className="-scale-y-100" />,
  },
];

type SelectOption = {
  label: string;
  value: string;
};

const getDefaultOption = () => ({
  label: i18next.t('select.allQuestionSet'),
  value: 'all',
});

const statisticStyle = {
  padding: '4px',
  paddingInline: '8px',
  fontSize: '17px',
  height: '34px',
};

interface ScoresTabContentProps {
  onModalToggle: (value: boolean) => void;
}

export const ScoresTabContent: FC<ScoresTabContentProps> = ({
  onModalToggle,
}) => {
  const [sortBy, setSortBy] = useState(SortBy.Newest);

  const language = useTypedSelector(languageSelectors.getLanguage);

  const isMobile = useMediaQuery(
    'md',
    'down'
  );

  const defaultOption = getDefaultOption();

  const {
    t
  } = useTranslation();

  const [selectedSets, setSelectedSets] = useState<SelectOption[]>([
    defaultOption,
  ]);

  const [timePeriod, setTimePeriod] = useState<TimePeriodSelectOption | null>(
    null,
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [scores, setScores] = useState<UserScores>([]);
  const [total, setTotal] = useState(0);
  const [questionSets, setQuestionSets] = useState<ScoresSets | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const filters = useMemo(
    () => {
      const preparedSetsIds = selectedSets
        .filter((set) => set.value !== defaultOption.value)
        .map((set) => set.value);

      const questionSetIds = preparedSetsIds.length ? preparedSetsIds : undefined;

      const fromDate = timePeriod
        ? getProfileTimePeriodSelectValue(timePeriod.value)
        : undefined;

      return {
        questionSetIds,
        fromDate,
        ...getScoresFilters(sortBy),
      };
    },
    [sortBy, selectedSets, timePeriod]
  );

  const [getApiScores] = useGetUsersCurrentScoresMutation();

  const getScores = useCallback(
    async (page?: number) => {
      setIsLoading(true);

      try {
        const response = await getApiScores({
          page: page ?? 1,
          filters: {
            withQuestionSets: !questionSets || !questionSets.length,
            ...filters,
          },
        }).unwrap();

        const {
          total: totalResponse,
          scores: scoresResponse,
          questionSets: questionSetsResponse,
        } = response.data;

        setTotal(totalResponse);

        if (!questionSets || !questionSets.length) {
          setQuestionSets(questionSetsResponse);
        }

        if (!page) {
          setScores(scoresResponse);
          setCurrentPage(1);
        } else {
          setScores((prev) => [...prev, ...scoresResponse]);
          setCurrentPage((prev) => prev + 1);
        }
      } catch (error) {
        showWarningFromServer(error);
      } finally {
        setIsLoading(false);
      }
    },
    [sortBy, selectedSets, getApiScores, filters],
  );

  useEffect(
    () => {
      getScores();
    },
    [getScores]
  );

  const onLoadMore = async () => {
    if (isLoading || scores.length >= total) {
      return;
    }

    await getScores(currentPage + 1);
  };

  const onSelectChange = (newValue: SelectOption[] | SelectOption) => {
    if (Array.isArray(newValue)) {
      setSelectedSets(newValue);
    }
  };

  const preparedSets = useMemo(
    () => questionSets?.map(({
      id, title
    }) => ({
      label: title,
      value: id,
    })) || [],
    [questionSets],
  );

  const topicSelectOptions = [defaultOption, ...preparedSets];

  if (!questionSets || !questionSets?.length) {
    return isLoading ? (
      <Spinner size={40} />
    ) : (
      <Style.EmptyScores>
        <Style.EmptyScoresTitle>
          {t('empty.scoresIsEmpty')}
        </Style.EmptyScoresTitle>

        {t('empty.scoresBasedOnResults')}

        <br />

        {t('empty.keepAnsweringQuestions')}
      </Style.EmptyScores>
    );
  }

  const onMenuClose = () => onModalToggle(false);
  const onMenuOpen = () => onModalToggle(true);

  return (
    <Style.ContentWrapper>
      <Style.FiltersContainer>
        <SortPopoverMenu
          options={getSortOptionsMap()}
          onSelect={(value) => setSortBy(value)}
          currentValue={sortBy}
        />

        <Select
          options={topicSelectOptions}
          placeholder={
            selectedSets.length > 1
              ? `${selectedSets.length} ${t('select.setsAreSelected')}`
              : t('select.allQuestionsSet')
          }
          onChange={onSelectChange}
          isMulti
          selectedValue={selectedSets}
          defaultValue={defaultOption}
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuClose}
        />

        <TimePeriodSelect
          placeholder={t('common.timePeriod')}
          timePeriod={timePeriod}
          onPeriodChange={setTimePeriod}
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuClose}
        />
      </Style.FiltersContainer>

      {scores?.length ? (
        <Style.QuestionList className="scroll-hidden">
          <InfiniteScroll
            className="flex flex-col gap-4 pb-6 overflow-auto scroll-hidden"
            loadMore={onLoadMore}
            hasMore={scores?.length < total && !isLoading}
            loader={(
              <Spinner
                size={24}
                key="spinner"
              />
            )}
            useWindow={false}
            threshold={20}
          >
            {scores.map(
              ({
                questionSet,
                id,
                title,
                userScore,
                result,
                userEstimatesAvg,
                globalEstimatesAvg,
                groupEstimatesAvg,
                deactivatedAt,
                createdAt,
              }) => {
                const scoreTitle = getScoreResult(result);

                return (
                  <Style.QuestionCard key={id}>
                    <Style.TopicInfo>
                      <Style.TopicName>
                        {questionSet?.title || ''}
                      </Style.TopicName>

                      <Style.Date>
                        {formatDateRange({
                          start: deactivatedAt || createdAt,
                          language,
                        })}
                      </Style.Date>
                    </Style.TopicInfo>

                    <Style.QuestionTitle>{title}</Style.QuestionTitle>

                    <Style.CardFooter>
                      {!isMobile && <p>{scoreTitle}</p>}

                      <Style.StatisticRow>
                        <StatisticTile
                          icon={<PersonIcon className="text-dim-gray" />}
                          amount={userEstimatesAvg}
                          withBorder
                          style={statisticStyle}
                        />

                        <StatisticTile
                          icon={<GlobeIcon className="text-dim-gray" />}
                          amount={globalEstimatesAvg}
                          withBorder
                          style={statisticStyle}
                        />

                        <StatisticTile
                          icon={<PeopleIcon className="text-dim-gray" />}
                          amount={groupEstimatesAvg}
                          withBorder
                          style={statisticStyle}
                        />
                      </Style.StatisticRow>

                      <Style.Score $isNegative={userScore < 0}>
                        {userScore > 0 && '+'}

                        {replaceDotWithComma(userScore)}
                      </Style.Score>
                    </Style.CardFooter>
                  </Style.QuestionCard>
                );
              },
            )}
          </InfiniteScroll>
        </Style.QuestionList>
      ) : (
        <Style.EmptyScores>
          <Style.EmptyScoresTitle>
            {t('empty.keepAnsweringQuestions')}
          </Style.EmptyScoresTitle>

          {t('empty.tryOtherFilters')}
        </Style.EmptyScores>
      )}
    </Style.ContentWrapper>
  );
};
