import React, {
  FC, FormEvent, KeyboardEvent, useEffect, useRef
} from 'react';
import {
  useForm, useFieldArray, Controller
} from 'react-hook-form';
import {
  mergeRefs
} from 'react-laag';
import {
  z
} from 'zod';
import {
  useTranslation
} from 'react-i18next';

import {
  EmailIcon
} from 'src/shared/icons';
import {
  emailsSchema, emailsUniqueSchema
} from 'src/shared/utils/validation';
import {
  Spinner, successfulToast, warningToast
} from 'src/shared/components';
import {
  useAddGroupMembersMutation
} from 'src/shared/api/groups/groupsApi';
import {
  showWarningFromServer
} from 'src/shared/utils';

import * as Style from './InvitePeopleForm.styles';
import {
  EmailForm, FormField
} from './types';

interface InvitePeopleFormProps {
  onSubmit: () => Promise<void>;
  groupId: string;
}

export const InvitePeopleForm: FC<InvitePeopleFormProps> = ({
  onSubmit,
  groupId,
}) => {
  const emailFieldsRef = useRef<HTMLSpanElement[]>([]);

  const {
    t
  } = useTranslation();

  const {
    control,
    setError,
    formState: {
      errors
    },
    clearErrors,
    getValues,
    reset,
    register,
  } = useForm<EmailForm>({
    defaultValues: {
      [FormField.Message]: '',
      [FormField.Emails]: [],
    },
  });

  const {
    fields, append, remove
  } = useFieldArray({
    control,
    name: FormField.Emails,
  });

  useEffect(
    () => {
      emailFieldsRef.current.forEach((element, index) => {
        if (!element) {
          return;
        }

        if (element.textContent !== fields[index].address) {
          element.textContent = fields[index].address;
        }
      });
    },
    [fields]
  );

  const validateEmails = async () => {
    try {
      const emailValues = getValues(FormField.Emails);

      const mailsToValidate = emailValues.length > 0 && emailValues.at(-1)?.address !== ''
        ? emailValues
        : emailValues.slice(
          0,
          -1
        );

      await emailsUniqueSchema.parseAsync({
        emails: mailsToValidate,
      });

      clearErrors(FormField.Emails);
    } catch (error) {
      if (error instanceof z.ZodError) {
        setError(
          FormField.Emails,
          {
            type: 'validate',
            message: error.issues[0]?.message,
          }
        );
      }
    }
  };

  const addNewField = (newValue = '') => {
    append({
      address: newValue,
    });

    validateEmails();
  };

  const [addGroupMembers, {
    isLoading
  }] = useAddGroupMembersMutation();

  const submitHandler = async (data: EmailForm) => {
    const {
      emails, message
    } = data;

    try {
      const response = await addGroupMembers({
        groupId,
        body: {
          emails: emails.map((email) => email.address),
          inviteMessage: message,
        },
      }).unwrap();

      successfulToast(response.message);

      await onSubmit();

      reset({
        [FormField.Message]: '',
        [FormField.Emails]: [],
      });
    } catch (error) {
      showWarningFromServer(
        error,
        t('errors.errorInInvitingPeople')
      );
    }
  };

  const onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formValues = getValues();
    submitHandler(formValues);
  };

  const handleKeyDown = (
    event: KeyboardEvent<HTMLSpanElement>,
    index: number,
  ) => {
    if (['Space', 'Enter', 'Tab', 'Comma'].includes(event.code)) {
      event.preventDefault();

      if (index !== fields.length - 1) {
        event.currentTarget.blur();

        return;
      }

      addNewField();
    }
  };

  const onBlur = (value: string, index: number) => {
    if (!value) {
      remove(index);
    }

    validateEmails();
  };

  const handlePaste = async (
    event: React.ClipboardEvent<HTMLSpanElement>,
    index: number,
  ) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData('text');
    const emailAddresses = pastedText.match(/\S+@\S+/g);

    if (!emailAddresses) {
      warningToast(t('errors.pasteValidData'));

      return;
    }

    remove(index);

    try {
      await emailsSchema.parseAsync({
        emails: emailAddresses.map((email) => ({
          address: email,
        })),
      });
    } catch (error) {
      if (error instanceof z.ZodError) {
        warningToast(error.issues[0]?.message);
      }

      return;
    }

    emailAddresses.forEach(async (email) => {
      addNewField(email);
    });
  };

  return (
    <Style.FormContainer onSubmit={onFormSubmit}>
      <Style.InputButton
        onClick={(event) => {
          event.stopPropagation();

          const {
            nodeName
          } = event.target as HTMLButtonElement;

          if (nodeName !== 'SPAN') {
            addNewField();
          }
        }}
        tabIndex={0}
        aria-label={t('group.addNewEmail')}
        type="button"
      >
        {fields.map((field, index) => (
          <div key={field.id}>
            <Controller
              name={`emails.${index}.address`}
              control={control}
              render={({
                field: inputField
              }) => (
                <Style.EmailField
                  className="block email"
                  role="textbox"
                  contentEditable
                  onKeyDown={(event) => handleKeyDown(
                    event,
                    index
                  )}
                  onInput={(event) => {
                    inputField.onChange(event.currentTarget.textContent);
                  }}
                  onBlur={() => onBlur(
                    inputField.value,
                    index
                  )}
                  tabIndex={0}
                  ref={mergeRefs(
                    inputField.ref,
                    (el: HTMLSpanElement) => {
                      emailFieldsRef.current[index] = el;
                    }
                  )}
                  onPaste={(event) => handlePaste(
                    event,
                    index
                  )}
                  defaultValue={inputField.value}
                />
              )}
            />
          </div>
        ))}

        {!fields.length && (
          <Style.PlaceHolder>{t('group.addEmailsVia')}</Style.PlaceHolder>
        )}

        {errors.emails && errors.emails.message && (
          <Style.ErrorMessage>{errors.emails.message}</Style.ErrorMessage>
        )}
      </Style.InputButton>

      <Style.MessageInputLabel>
        <Style.MessageInputTitle>
          {t('modal.inviteMessageOptional')}
        </Style.MessageInputTitle>

        <Style.MessageInput
          placeholder={t('group.sendInvitationLetter')}
          {...register(FormField.Message)}
        />
      </Style.MessageInputLabel>

      <Style.SubmitButton
        type="submit"
        disabled={!!errors.emails?.message || !fields.length}
      >
        {t('buttons.submit')}

        {isLoading ? (
          <Spinner
            color="inherit"
            size={24}
          />
        ) : (
          <EmailIcon className="h-6 w-6" />
        )}
      </Style.SubmitButton>
    </Style.FormContainer>
  );
};
