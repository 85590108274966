import React, {
  FC
} from 'react';
import {
  useNavigate
} from 'react-router-dom';
import {
  useTranslation
} from 'react-i18next';

import {
  ROUTE,
  getTimeAgo,
  questionId as questionIdParam,
  commentID as commentIdParam,
  showOne,
} from 'src/shared/utils';
import {
  Avatar
} from 'src/shared/components';
import {
  useTypedDispatch, useTypedSelector
} from 'src/redux';
import {
  commentsFilterSelectors,
  setFeedTimeRange,
} from 'src/redux/commentsFilters';
import {
  FilterDateType
} from 'src/widgets/CommentsFilterContext';
import {
  NotificationContainer,
  NotificationGroupContainer,
} from 'src/entities/NotificationsRoute';

import * as Style from './Notifications.styles';

interface CommentNotificationProps {
  authorAvatarUrl?: string | null;
  authorName: string;
  createdAt: string;
  text: string;
  isNew: boolean;
  getHighlightedText: (text: string) => (string | JSX.Element)[];
  commentId: string;
  questionId: string | null;
}

export const CommentNotification: FC<CommentNotificationProps> = ({
  authorAvatarUrl = null,
  authorName,
  createdAt,
  text,
  isNew,
  getHighlightedText,
  commentId,
  questionId,
}) => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();

  const {
    t
  } = useTranslation();

  const commentsTimeRange = useTypedSelector(
    commentsFilterSelectors.selectFeedCommentsTimeRange,
  );

  const onCommentClick = () => {
    if (commentsTimeRange !== FilterDateType.ALL_TIME) {
      dispatch(setFeedTimeRange(FilterDateType.ALL_TIME));
    }

    navigate(
      `/${ROUTE.COMMUNITY}?${questionIdParam}=${questionId}&${commentIdParam}=${commentId}&${showOne}=true`,
    );
  };

  return (
    <NotificationContainer $isNew={isNew}>
      <Style.HeaderWithAvatarWrapper>
        <Style.ImageContainer $isNew={isNew}>
          <Avatar
            size={56}
            src={authorAvatarUrl}
            userName={authorName}
          />
        </Style.ImageContainer>

        <Style.Description>
          <Style.NotificationHeader>
            <Style.NotificationDate>
              {`${getTimeAgo(createdAt)} ${t('date.ago')}`}
            </Style.NotificationDate>
          </Style.NotificationHeader>

          <Style.NotificationText>
            <Style.NotificationBoldText>
              {getHighlightedText(authorName)}
            </Style.NotificationBoldText>

            {` ${t('comment.replyToYourComment')}`}
          </Style.NotificationText>
        </Style.Description>
      </Style.HeaderWithAvatarWrapper>

      <NotificationGroupContainer
        $wasRead={!isNew}
        $width={468}
        $mediumFont
        $isClickable
        onClick={onCommentClick}
        $leftShift={68}
      >
        {text}
      </NotificationGroupContainer>
    </NotificationContainer>
  );
};
