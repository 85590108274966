import React, {
  FC
} from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import {
  useTranslation
} from 'react-i18next';
import i18next from 'i18next';

import {
  getAxisDateString
} from 'src/shared/utils/dateTime';
import {
  Checkbox, CheckboxType
} from 'src/shared/components';
import {
  LineDashedIcon, TooltipArrowLeftIcon
} from 'src/shared/icons';
import {
  useTypedSelector
} from 'src/redux';
import {
  groupsSelectors
} from 'src/redux/groups';
import {
  prepareGroupIdForGraph
} from 'src/shared/utils';
import {
  MY_FORECAST, TOTAL_PREDICTION
} from 'src/entities/Graph';
import {
  GraphType, Point, TooltipPayloadType
} from 'src/entities';
import {
  useMediaQuery
} from 'src/shared/hooks';

import {
  PointData
} from './types';

interface GraphTooltipProps {
  label?: number;
  payload?: PointData[];
  coordinate?: Partial<Point>;
  timeGap?: number;
  type?: GraphType;
  isLeftSide?: boolean;
  payloadData?: TooltipPayloadType[] | null;
}

export const GraphTooltip: FC<GraphTooltipProps> = ({
  label,
  payload: lines,
  timeGap,
  coordinate,
  type,
  isLeftSide,
  payloadData,
}) => {
  const ownForecastName: string = payloadData && payloadData[0].name ? `${payloadData[0].name}` : '';

  const {
    t
  } = useTranslation();

  const allUserGroups = useTypedSelector(groupsSelectors.groups);

  const formatGroupName = (id: string) => {
    const correctId = prepareGroupIdForGraph(id);

    const group = allUserGroups.find(
      (groupInState) => groupInState.id === correctId,
    );

    return group?.name || id;
  };

  const isOwnForecast = !!payloadData?.[0]?.payload.own;
  const isGroupTotalForecast = !!payloadData?.[0]?.payload.groupsTotal;

  const ownForecastValue: number = payloadData?.[0]?.payload.own?.[ownForecastName] ?? 0;

  const getTotalData = (name: string): number => {
    return payloadData
      && typeof payloadData[0].payload.total === 'object'
      && payloadData[0].payload.total[name]
      ? payloadData[0].payload.total[name]
      : 0;
  };

  const getFilteredGroupsData = (name: string): number => {
    return payloadData
      && payloadData[0].payload.groupsTotal
      && payloadData[0].payload.groupsTotal[name]
      ? payloadData[0].payload.groupsTotal[name]
      : 0;
  };

  const renderPointData = (
    {
      name, color
    }: Pick<PointData, 'name' | 'color'>,
    votesNumber: number,
  ) => {
    const formattedName = formatGroupName(name);

    return (
      <label
        className="
          flex gap-x-2 items-center
          flex-1
        "
      >
        <Checkbox
          color={color}
          type={CheckboxType.SQUARE}
          isChecked
          size={20}
        />

        <div className="flex flex-col justify-start">
          <span className="text-15-20 text-gray7 font-medium">
            {formattedName === MY_FORECAST
              ? t('question.dataKeyOwn')
              : formattedName}
          </span>

          {votesNumber >= 0 && formattedName !== MY_FORECAST && (
            <span className="text-xs leading-5 text-dim-gray font-medium">
              {`${votesNumber} ${
                votesNumber === 1
                  ? t('common.forecast')
                  : t('question.forecasts')
              }`}
            </span>
          )}
        </div>
      </label>
    );
  };

  const isLeftSideCloser = coordinate && coordinate.x && coordinate.x < 150;

  const isMobile = useMediaQuery(
    'md',
    'down'
  );

  return (
    <div
      className={clsx(
        ' bg-white shadow-menu-shadow',
        {
          'rounded-10px -translate-y-full': type !== GraphType.BAR,
          '-translate-x-1/2':
          type !== GraphType.BAR && !isLeftSideCloser && !isMobile,
          '-translate-x-40%':
          type !== GraphType.BAR && isLeftSideCloser && !isMobile,
          '-translate-y-40% p-4 rounded border border-gray-whisper relative':
          type === GraphType.BAR && payloadData,
          '-translate-x-full': isLeftSide,
        }
      )}
    >
      {type !== GraphType.BAR && (
        <>
          <h3
            className="
              px-3 py-2 text-dim-gray
              text-15-20 font-medium
            "
          >
            {getAxisDateString({
              fromDate: label || new Date('01/01/01').getTime(),
              interval: timeGap,
            })}
          </h3>

          <ul className="text-15-20 font-medium text-gray7">
            {lines?.map(({
              color, name, dataKey, value, unit, payload
            }) => {
              const correctDataKey = prepareGroupIdForGraph(dataKey);
              const numberOfVotes = payload[`${correctDataKey}`]?.votes || 0;
              const totalVotes = payload.votes;

              return (
                <li
                  key={dataKey}
                  className="flex gap-2 px-3 py-2"
                >
                  {dataKey !== TOTAL_PREDICTION
                    && renderPointData(
                      {
                        name,
                        color,
                      },
                      numberOfVotes,
                    )}

                  {dataKey === TOTAL_PREDICTION && (
                    <label
                      className="
                          flex gap-x-2 items-center flex-1
                          text-15-20 text-gray7 font-medium
                        "
                    >
                      <div className="w-5">
                        <LineDashedIcon />
                      </div>

                      <div className="flex flex-col justify-start">
                        <span className="text-15-20 text-gray7 font-medium">
                          {t('question.dataKeyTotal')}
                        </span>

                        {typeof value === 'number' && value >= 0 && (
                          <span className="text-xs leading-5 text-dim-gray font-medium">
                            {`${totalVotes} ${t('question.totalVotes')}`}
                          </span>
                        )}
                      </div>
                    </label>
                  )}

                  {`${typeof value === 'number' ? value : value.total}${
                    unit !== '%' ? ' ' : ''
                  }${unit}`}
                </li>
              );
            })}
          </ul>
        </>
      )}

      {type === GraphType.BAR && payloadData && (
        <div>
          {payloadData.map(({
            color, name, unit, dataKey, value
          }) => {
            const totalValue = getTotalData(name);
            const filteredGroupsValue = getFilteredGroupsData(name);

            const idInDataKey = dataKey.slice(
              0,
              -(name.length + 2)
            );

            const isGroup = idInDataKey.split('-').length > 4;

            const preparedName = formatGroupName(
              isGroup ? idInDataKey : dataKey,
            );

            return (
              <div key={name}>
                <div className="flex flex-row justify-between items-center gap-2">
                  <div className="flex-1 flex flex-row gap-2 items-center">
                    <div
                      className="w-4 h-4 rounded-full"
                      style={{
                        backgroundColor: color,
                      }}
                    />

                    <div className="flex-1 text-15-20 font-medium text-dark-gray">
                      {name}
                    </div>
                  </div>

                  <h3 className=" text-dim-gray text-xs leading-5 font-medium">
                    {dayjs(label).locale(i18next.language).format('MMM D')}
                  </h3>
                </div>

                <div className="flex flex-row gap-10">
                  {isOwnForecast && (
                    <div className="text-17-26 font-medium text-dark-gray">
                      {t('question.own')}

                      <span className="pl-2 text-17-26 font-bold text-gray7">
                        {`${
                          Number.isInteger(ownForecastValue)
                            ? ownForecastValue
                            : +ownForecastValue.toFixed(1)
                        } ${unit}`}
                      </span>
                    </div>
                  )}

                  <div>
                    {t('question.total')}

                    <span className="pl-2 text-17-26 font-bold text-gray7">
                      {`${
                        Number.isInteger(totalValue)
                          ? totalValue
                          : +totalValue.toFixed(1)
                      } ${unit}`}
                    </span>
                  </div>

                  {isGroupTotalForecast && (
                    <div>
                      {t('question.gTotal')}

                      <span className="pl-2 text-17-26 font-bold text-gray7">
                        {`${
                          Number.isInteger(filteredGroupsValue)
                            ? filteredGroupsValue
                            : +filteredGroupsValue.toFixed(1)
                        } ${unit}`}
                      </span>
                    </div>
                  )}
                </div>

                {isGroup && (
                  <div>
                    {preparedName}

                    <span className="pl-2 text-17-26 font-bold text-gray7">
                      {`${
                        Number.isInteger(value) ? value : +value.toFixed(1)
                      } ${unit}`}
                    </span>
                  </div>
                )}
              </div>
            );
          })}

          <TooltipArrowLeftIcon
            className={clsx(
              'absolute top-1/2 -translate-y-1/2',
              {
                'left-0 -translate-x-full': !isLeftSide,
                'right-0 rotate-180 translate-x-full': isLeftSide,
              }
            )}
          />
        </div>
      )}
    </div>
  );
};
