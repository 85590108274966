import React, {
  Dispatch, SetStateAction, HTMLProps
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  Checkbox
} from 'src/shared/components';

import * as Style from './ChooseItemsList.style';

interface ChooseItemsListProps<
  T extends Record<string, string | null | number>,
> {
  list: T[];
  valueKey: keyof T;
  listProps?: HTMLProps<HTMLUListElement>;
  listItemProps?: HTMLProps<HTMLLIElement>;
  selectedItems: T[];
  setSelectedItems: Dispatch<SetStateAction<T[]>>;
}

export const ChooseItemsList = <
  T extends Record<string, string | null | number>,
>({
    list,
    valueKey,
    listProps,
    listItemProps,
    selectedItems,
    setSelectedItems,
  }: ChooseItemsListProps<T>) => {
  const {
    t
  } = useTranslation();

  const onSelect = (item: T) => {
    setSelectedItems((prev) => {
      const isValueInList = prev.some(
        (prevItem) => prevItem[valueKey] === item[valueKey],
      );

      if (isValueInList) {
        return prev.filter((prevItem) => prevItem[valueKey] !== item[valueKey]);
      }

      return [...prev, item];
    });
  };

  const isAllSelected = selectedItems.length === list.length;

  const selectAll = () => {
    const itemsToSelect = isAllSelected ? [] : list;

    setSelectedItems(itemsToSelect);
  };

  return (
    <Style.ListContainer {...listProps}>
      <Style.ListItem {...listItemProps}>
        <Checkbox
          onChange={selectAll}
          isChecked={isAllSelected}
          type={isAllSelected ? 'checkMark' : 'minus'}
        />

        <Style.LightText>{t('buttons.selectAll')}</Style.LightText>
      </Style.ListItem>

      {list.map((item, index) => {
        const isChecked = selectedItems.some(
          (prevItem) => prevItem[valueKey] === item[valueKey],
        );

        return (
          <Style.ListItem
            key={item[valueKey]}
            {...listItemProps}
          >
            <Checkbox
              onChange={() => onSelect(item)}
              isChecked={isChecked}
              type="checkMark"
            />

            <Style.ListItemContent>
              <Style.LightText>{`${index + 1}.`}</Style.LightText>

              <p>{item[valueKey]}</p>
            </Style.ListItemContent>
          </Style.ListItem>
        );
      })}
    </Style.ListContainer>
  );
};
