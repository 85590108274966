import React, {
  useState, FC, useMemo, useEffect
} from 'react';
import clsx from 'clsx';
import {
  useTranslation
} from 'react-i18next';
import {
  useLocation
} from 'react-router-dom';

import {
  ActivityTile, StatisticTile
} from 'src/entities/Questions';
import {
  CircleButton, ScrollContainer, Spinner
} from 'src/shared/components';
import {
  ArrowUpWideIcon,
  MessageIcon,
  PeopleIcon,
  TrendingUpIcon,
  PersonIcon,
  GlobeIcon,
} from 'src/shared/icons';
import {
  ROUTE, formatNumber
} from 'src/shared/utils';
import {
  QuestionEntityStatus,
  QuestionEntityType,
  QuestionSortBy,
} from 'src/shared/api/types';
import {
  SortOrder, useGetGroupSetsAndQuestionsQuery
} from 'src/redux/openapi';
import {
  checkDeactivatedQuestionStatus
} from 'src/features';
import {
  useCustomInfiniteScroll
} from 'src/shared/hooks';

import * as Style from './QuestionsOverviewCard.styles';
import {
  ContextMenu, ModalEditOnboardingText
} from './UI';

interface QuestionsOverviewCardProps {
  questionSetId?: string;
  title: string;
  dates: string;
  participantsNumber: number;
  commentsPerDay: number;
  activeMembersPerDay: number;
  questionCount?: number;
  status?: QuestionEntityType | null;
  id: string;
  filters: {
    sortBy: QuestionSortBy;
    sortOrder?: SortOrder;
  };
  searchQuestion: string;
  isAdmin?: boolean;
  canOnboardingText?: boolean;
}

export const QuestionsOverviewCard: FC<QuestionsOverviewCardProps> = ({
  title,
  dates,
  participantsNumber,
  commentsPerDay,
  activeMembersPerDay,
  questionSetId = '',
  questionCount = 0,
  status,
  id,
  filters,
  searchQuestion,
  isAdmin,
  canOnboardingText,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [questionIdTextOnboard, setQuestionIdTextOnboard] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const handleCloseEditOnboardingTextModal = () => {
    setQuestionIdTextOnboard('');
  };

  useEffect(
    () => {
      setCurrentPage(1);
    },
    [searchQuestion, filters]
  );

  const {
    pathname
  } = useLocation();

  const isOrganizationPage = pathname.includes(`/${ROUTE.ORGANISATION}/`);

  const {
    t
  } = useTranslation();

  const toggleExpand = () => {
    setIsExpanded((prevExpanded) => !prevExpanded);
  };

  useEffect(
    () => {
      setIsExpanded(false);
    },
    [id]
  );

  const {
    data: questionsDataGroup,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useGetGroupSetsAndQuestionsQuery(
    {
      id,
      questionSetId,
      filters: {
        ...filters,
        query: searchQuestion,
      },
      limit: 10,
      page: currentPage,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !isExpanded,
    },
  );

  const {
    total: totalQuestions, questions
  } = useMemo(
    () => questionsDataGroup?.data || {
      total: 0,
      questions: [],
    },
    [questionsDataGroup],
  );

  const {
    sentryRef,
    allItems: allQuestions,
    displayLoader,
  } = useCustomInfiniteScroll({
    total: totalQuestions,
    currentItems: questions,
    isFetching,
    isLoading,
    isError,
    currentPage,
    setCurrentPage,
  });

  const onAddedOnboardingText = () => {
    refetch();
    setCurrentPage(1);
  };

  return (
    <Style.MainContainer id="QuestionsOverviewCard">
      <Style.OverviewContainer>
        <Style.OverviewHeader>
          <Style.OverviewTitle>
            <Style.Title>{title}</Style.Title>

            <Style.Subtitle>{dates}</Style.Subtitle>
          </Style.OverviewTitle>

          {status === QuestionEntityStatus.Deactivated ? (
            <Style.DeactivatedTile>
              {t('question.deactivated')}
            </Style.DeactivatedTile>
          ) : (
            <Style.QuestionsAmount>{questionCount}</Style.QuestionsAmount>
          )}

          <CircleButton onClick={toggleExpand}>
            {isLoading ? (
              <Spinner size={24} />
            ) : (
              <ArrowUpWideIcon
                className={clsx(
                  'w-6 h-6 text-dark-gray transition-all',
                  {
                    'rotate-180': isExpanded,
                  }
                )}
              />
            )}
          </CircleButton>
        </Style.OverviewHeader>

        <Style.ActivityRow>
          <ActivityTile
            icon={<PeopleIcon className="text-dim-gray" />}
            amount={`${participantsNumber}`}
            className="p-1 grow"
          />

          <ActivityTile
            icon={<MessageIcon className="text-dim-gray" />}
            amount={formatNumber(commentsPerDay)}
            timeRange="upToday"
            className="p-1"
          />

          <ActivityTile
            icon={<TrendingUpIcon className="text-dim-gray" />}
            amount={`${activeMembersPerDay}`}
            timeRange="upToday"
            className="p-1"
          />
        </Style.ActivityRow>
      </Style.OverviewContainer>

      {isExpanded && allQuestions && (
        <ScrollContainer
          className="max-h-500px"
          visibleScroll
        >
          {allQuestions.map(
            ({
              id: questionId,
              title: questionTitle,
              globalEstimatesAvg,
              userEstimatesAvg,
              globalEstimatesChange,
              groupEstimatesAvg,
              groupEstimatesChange,
              participantsCount,
              commentsCount,
              isActive,
              type,
              onboardingText,
              ...question
            }) => {
              const isDeactivated = checkDeactivatedQuestionStatus(question);

              const handleSetQuestionIdTextOnboard = () => {
                setQuestionIdTextOnboard(questionId);
              };

              const onboardQuestionType = type === 'SINGLE' || type === 'MULTIPLE';

              const hasContextMenu = onboardQuestionType
                ? canOnboardingText
                  || (isAdmin && !isDeactivated && isOrganizationPage)
                : false;

              return (
                <Style.QuestionContainer key={questionId}>
                  <Style.QuestionTitleRow>
                    <Style.QuestionTitle>{questionTitle}</Style.QuestionTitle>

                    {hasContextMenu && (
                      <ContextMenu
                        setActiveQuestion={handleSetQuestionIdTextOnboard}
                      />
                    )}
                  </Style.QuestionTitleRow>

                  {!!onboardingText && (
                    <Style.QuestionDetails>
                      {onboardingText}
                    </Style.QuestionDetails>
                  )}

                  {(!!userEstimatesAvg || isDeactivated) && (
                    <Style.QuestionInfo>
                      {userEstimatesAvg && (
                        <>
                          <Style.QuestionInfoWrapper $gap={12}>
                            <StatisticTile
                              amount={userEstimatesAvg}
                              icon={<PersonIcon className="w-4 h-4" />}
                              applyMinWidth={false}
                              height="auto"
                            />

                            <StatisticTile
                              amount={globalEstimatesAvg}
                              icon={<GlobeIcon className="w-4 h-4" />}
                              growth={globalEstimatesChange}
                              applyMinWidth={false}
                              height="auto"
                            />

                            <StatisticTile
                              amount={groupEstimatesAvg}
                              icon={<PeopleIcon className="w-4 h-4" />}
                              growth={groupEstimatesChange}
                              applyMinWidth={false}
                              height="auto"
                            />
                          </Style.QuestionInfoWrapper>

                          <Style.QuestionInfoWrapper $gap={8}>
                            <ActivityTile
                              icon={<PeopleIcon className="w-4 h-4" />}
                              amount={`${formatNumber(participantsCount)}`}
                              className="p-1"
                            />

                            <ActivityTile
                              icon={
                                <MessageIcon className="w-4 h-4 text-dim-gray" />
                              }
                              amount={formatNumber(commentsCount)}
                              className="p-1"
                            />
                          </Style.QuestionInfoWrapper>
                        </>
                      )}

                      {isDeactivated && (
                        <Style.DeactivatedTile>
                          {t('question.deactivated')}
                        </Style.DeactivatedTile>
                      )}
                    </Style.QuestionInfo>
                  )}
                </Style.QuestionContainer>
              );
            },
          )}

          {displayLoader && (
            <div ref={sentryRef}>
              <Spinner size={24} />
            </div>
          )}
        </ScrollContainer>
      )}

      {!!questionIdTextOnboard && (
        <ModalEditOnboardingText
          isOpen={!!questionIdTextOnboard}
          closeModal={handleCloseEditOnboardingTextModal}
          questionId={questionIdTextOnboard}
          onAddedOnboardingText={onAddedOnboardingText}
        />
      )}
    </Style.MainContainer>
  );
};
