import React, {
  FC, useMemo
} from 'react';
import {
  useSearchParams
} from 'react-router-dom';
import {
  useTranslation
} from 'react-i18next';

import {
  SortBy,
  SortByMenu,
  FilterByMenu,
  TimeRangeMenu,
  FilterBy,
} from 'src/entities/Questions';
import {
  TimeSpansValues
} from 'src/redux/timeSpans';
import {
  FilterPopoverMenu
} from 'src/shared/components';
import {
  questionsFilterBy,
  questionsSortBy,
  questionsTimeRange,
} from 'src/shared/utils';
import {
  useMediaQuery
} from 'src/shared/hooks';

import * as Style from './OnboardingQuestionsFilter.styles';

interface OnboardingQuestionsFilterProps {
  isTableView: boolean;
}

export const OnboardingQuestionsFilter: FC<OnboardingQuestionsFilterProps> = ({
  isTableView,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    t
  } = useTranslation();

  const filterBy = useMemo(
    () => searchParams.get(questionsFilterBy),
    [searchParams],
  ) as FilterBy;

  const sortBy = useMemo(
    () => searchParams.get(questionsSortBy) || SortBy.Newest,
    [searchParams],
  ) as SortBy | null;

  const timeRange = useMemo(
    () => searchParams.get(questionsTimeRange),
    [searchParams],
  ) as TimeSpansValues | null;

  const onSortClick = (filter: SortBy | null) => {
    if (filter) {
      searchParams.set(
        questionsSortBy,
        filter
      );
    } else {
      searchParams.delete(questionsSortBy);
    }

    setSearchParams(searchParams);
  };

  const onFilterClick = (id: FilterBy | null) => {
    if (!id) {
      searchParams.delete(questionsFilterBy);
    } else {
      searchParams.set(
        questionsFilterBy,
        id
      );
    }

    setSearchParams(searchParams);
  };

  const onTimeRangeSelect = (range: TimeSpansValues | null) => {
    if (range) {
      searchParams.set(
        questionsTimeRange,
        range
      );
    } else {
      searchParams.delete(questionsTimeRange);
    }

    setSearchParams(searchParams);
  };

  const isMobile = useMediaQuery(
    'md',
    'down'
  );

  return (
    <FilterPopoverMenu
      tooltipMessage={isMobile ? '' : t('filters.filterQuestions')}
      placement="bottom-start"
      width={308}
    >
      <Style.MainContainer>
        {!isTableView && (
          <>
            <FilterByMenu
              currentValue={filterBy}
              onSelect={onFilterClick}
            />

            <Style.Divider />
          </>
        )}

        <TimeRangeMenu
          currentValue={timeRange}
          onSelect={onTimeRangeSelect}
        />

        <Style.Divider />

        <SortByMenu
          withExtendedOptions
          onSelect={onSortClick}
          currentValue={sortBy}
          timeRange={timeRange}
        />
      </Style.MainContainer>
    </FilterPopoverMenu>
  );
};
