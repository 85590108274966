import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MainContainer = styled.section<{ $isExpanded: boolean }>`
  ${tw`
    flex flex-col pt-3 md:pt-6 md:w-326px -mt-1 md:mt-0
    bg-white rounded-md max-h-full
  `}

  ${({
    $isExpanded
  }) => ($isExpanded
    ? `
        height: clamp(500px, calc(100vh - 126px), 880px);
        position: sticky;
        top: 16px;
      `
    : tw`h-fit py-2`)}

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Header = styled.div`
  ${tw`flex items-center justify-between px-6`}
`;

export const HeaderTitle = styled.h3`
  ${tw`font-bold text-22-30 text-gray7`}
`;

export const HeaderButtons = styled.div`
  ${tw`flex gap-2`}
`;

export const EmptyContentContainer = styled.div`
  ${tw`grow flex justify-center items-center`}
`;

export const OnboardText = styled.p`
  ${tw`
    self-center flex items-center
    w-166px
    font-['Inter'] leading-5 text-dark-gray text-center
  `}
`;

export const Question = styled.p`
  ${tw`text-xs text-dim-gray leading-5 font-medium`}
`;

export const QuestionTitle = styled.span`
  ${tw`hover:(text-dim-gray bg-dashed-hover bg-bottom bg-repeat-x bg-[length:8px_1px])
  active:(text-gray7 bg-dashed-active bg-bottom bg-repeat-x bg-[length:8px_1px])
  transition-colors`}
`;
