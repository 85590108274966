import React, {
  FC, useRef, useEffect, useMemo
} from 'react';
import {
  Link, useSearchParams
} from 'react-router-dom';
import clsx from 'clsx';
import {
  useTranslation
} from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';

import {
  Filters
} from 'src/features';
import {
  CircleButton,
  PopoverMenu,
  ScrollContainer,
  Spinner,
} from 'src/shared/components';
import {
  ClockIcon, MessageIcon, PeopleIcon, SortIcon
} from 'src/shared/icons';
import {
  ROUTE,
  followings,
  groupId,
  questionId,
  themeColors,
} from 'src/shared/utils';
import {
  useTypedDispatch
} from 'src/redux';
import {
  CommunityQuestionSet,
  CommunityQuestionsSortBy,
  useGetGroupsCommunityQuery,
  CommunityGroup,
} from 'src/redux/openapi';
import {
  SortByInCommunity,
  setCommunitySortBy,
} from 'src/redux/commentsFilters';
import {
  useGetFollowingsUsers
} from 'src/shared/hooks';

import {
  Title
} from '../Sections.styles';

import * as Style from './DiscussionSection.styles';
import {
  FollowingCard, TopicCard
} from './components';

enum SortByLabel {
  Latest = 'latest',
  MostCommented = 'mostCommented',
  MostParticipants = 'mostParticipants',
  MostEngaged = 'mostEngaged',
}

const SORT_BY_MAP = [
  {
    id: SortByInCommunity.Latest,
    label: SortByLabel.Latest,
    icon: <ClockIcon />,
  },
  {
    id: SortByInCommunity.MostCommented,
    label: SortByLabel.MostCommented,
    icon: <MessageIcon />,
  },
  {
    id: SortByInCommunity.MostParticipants,
    label: SortByLabel.MostParticipants,
    icon: <PeopleIcon />,
  },
];

const SORT_BY_FAVORITE = [
  {
    id: SortByInCommunity.MostEngaged,
    label: SortByLabel.MostEngaged,
    icon: <MessageIcon />,
  },
];

interface DiscussionSectionProps {
  questionSets: CommunityQuestionSet[];
  isSetsLoading: boolean;
  sortBy: CommunityQuestionsSortBy;
  groups: CommunityGroup[];
}

export const DiscussionSection: FC<DiscussionSectionProps> = ({
  questionSets,
  isSetsLoading,
  sortBy,
  groups,
}) => {
  const commentToScrollRef = useRef<HTMLDivElement>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const activeQuestionId = searchParams.get(questionId) || null;
  const activeFilterId = searchParams.get(groupId);
  const isFollowingsPage = searchParams.get(followings);

  const {
    followingsList,
    loadNextPage,
    isLoadingMore,
    refetch,
    total: totalFollowings,
  } = useGetFollowingsUsers();

  const handleFollowingsClick = () => {
    if (activeQuestionId) {
      searchParams.delete(questionId);
    }

    if (activeFilterId) {
      searchParams.delete(groupId);
    }

    searchParams.set(
      followings,
      'true'
    );

    setSearchParams(searchParams);
  };

  const {
    t
  } = useTranslation();

  const dispatch = useTypedDispatch();

  const {
    data: groupsData
  } = useGetGroupsCommunityQuery(
    {
      limit: 5000,
    },
    {
      skip: !!isFollowingsPage,
    },
  );

  const filters = useMemo(
    () => {
      if (!groupsData?.data.groups.length) {
        return [];
      }

      const groupsToFilter = groupsData?.data.groups.map(({
        name, id
      }) => ({
        label: name,
        id,
      }));

      return [
        {
          id: null,
          label: t('common.allGroups'),
        },
        ...groupsToFilter,
      ];
    },
    [groupsData]
  );

  const onFilterClick = (id: string | null) => {
    if (id) {
      searchParams.set(
        groupId,
        id
      );
    } else {
      searchParams.delete(groupId);
    }

    setSearchParams(searchParams);
  };

  const onSortClick = (sortType: SortByInCommunity) => {
    if (!isFollowingsPage) {
      dispatch(setCommunitySortBy(sortType));
    }
  };

  useEffect(
    () => {
      if (commentToScrollRef.current && activeQuestionId) {
        commentToScrollRef.current.scrollIntoView();
      }
    },
    [activeFilterId]
  );

  const sortByItems = useMemo(
    () => {
      if (isFollowingsPage) {
        return SORT_BY_FAVORITE;
      }

      if (groups.length) {
        return SORT_BY_MAP;
      }

      return SORT_BY_MAP.filter(
        (item) => item.id !== SortByInCommunity.MostParticipants,
      );
    },
    [groups, isFollowingsPage]
  );

  return (
    <Style.SectionContainer>
      <Style.SectionHeader>
        <Style.TitleWrapper>
          <div className="flex gap-6">
            <Link to={`/${ROUTE.COMMUNITY}`}>
              <Title $isActive={!isFollowingsPage}>
                {t('question.questionDiscussion')}
              </Title>
            </Link>

            <button
              type="button"
              onClick={handleFollowingsClick}
            >
              <Title $isActive={!!isFollowingsPage}>
                {t('question.followings')}
              </Title>
            </button>
          </div>

          <PopoverMenu
            borderRadius={6}
            renderCustomButton={({
              isOpen: isMenuOpen,
              toggleMenu,
              triggerProps,
            }) => (
              <CircleButton
                {...triggerProps}
                onClick={toggleMenu}
                borderColor={
                  isMenuOpen ? themeColors['button-blue'] : themeColors.gray90
                }
              >
                <SortIcon
                  className={clsx({
                    'text-button-blue': isMenuOpen,
                    'text-gray7': !isMenuOpen,
                  })}
                />
              </CircleButton>
            )}
          >
            <Style.SortMenu>
              <Style.SortMenuTitle>
                {isFollowingsPage
                  ? t('question.sortFollowingsBy')
                  : t('question.sortQuestionsBy')}
              </Style.SortMenuTitle>

              {sortByItems.map(({
                id, label, icon
              }) => (
                <Style.SortMenuLabel
                  $isActive={sortBy === id}
                  key={id}
                  onClick={() => onSortClick(id)}
                >
                  {icon}

                  <p>{t(`question.${label}`)}</p>
                </Style.SortMenuLabel>
              ))}
            </Style.SortMenu>
          </PopoverMenu>
        </Style.TitleWrapper>

        {!isFollowingsPage && !!filters.length && (
          <Style.FiltersContainer>
            <Filters
              items={filters}
              onFilterClick={onFilterClick}
              activeFilterId={activeFilterId}
            />
          </Style.FiltersContainer>
        )}
      </Style.SectionHeader>

      {isSetsLoading ? (
        <Spinner />
      ) : (
        <>
          {!isFollowingsPage && !!questionSets?.length && (
            <ScrollContainer edgeHeight={48}>
              {questionSets?.map((topic) => (
                <TopicCard
                  topic={topic}
                  key={topic.id}
                  groupId={activeFilterId}
                  sortBy={sortBy}
                  isStatisticsVisible={!!filters.length}
                />
              ))}
            </ScrollContainer>
          )}

          {!isFollowingsPage && !questionSets?.length && (
            <Style.EmptyMessageContainer>
              <Style.EmptyMessageTitle>
                {t('community.onboardQuestionFirst')}
              </Style.EmptyMessageTitle>

              {t('community.hereYouWillFindAllDiscussions')}
            </Style.EmptyMessageContainer>
          )}

          {isFollowingsPage && !totalFollowings && (
            <Style.EmptyMessageContainer>
              <Style.EmptyMessageTitle>
                {t('community.currentlyNoFollowings')}
              </Style.EmptyMessageTitle>

              {t('community.hereWillDisplayedUsersYouFollow')}
            </Style.EmptyMessageContainer>
          )}

          {isFollowingsPage && !!totalFollowings && (
            <ScrollContainer
              edgeHeight={48}
              className="border-t border-t-gray94"
            >
              <InfiniteScroll
                pageStart={1}
                loadMore={loadNextPage}
                hasMore={
                  !isLoadingMore && followingsList.length < totalFollowings
                }
                loader={(
                  <Spinner
                    size={24}
                    key="spinner"
                  />
                )}
                useWindow={false}
                threshold={50}
              >
                {followingsList.map((user) => (
                  <FollowingCard
                    key={user.id}
                    user={user}
                    refetch={refetch}
                  />
                ))}
              </InfiniteScroll>
            </ScrollContainer>
          )}
        </>
      )}
    </Style.SectionContainer>
  );
};
