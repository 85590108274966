import React, {
  FC, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  CircleButton
} from 'src/shared/components';
import {
  ArrowLeftIcon, CloseIcon
} from 'src/shared/icons';

import {
  AccountSettingsTabs,
  ProfileSettingsTabs,
} from '../AccountSettingsTabs';
import {
  PublicInfo
} from '../PublicInfo';
import {
  SecurityTab
} from '../SecurityTab';
import {
  EmailsPreferencesTab
} from '../EmailsPreferencesTab';
import {
  CharacteristicsTabContent
} from '../CharacteristicsTabContent';

import * as Style from './EditProfileMenu.styles';

interface EditProfileMenuProps {
  onEditClose: () => void;
  onMenuClose: () => void;
  onModalToggle: (isOpen: boolean) => void;
}

export const EditProfileMenu: FC<EditProfileMenuProps> = ({
  onEditClose,
  onMenuClose,
  onModalToggle,
}) => {
  const {
    t
  } = useTranslation();

  const [activeSettingsTab, setActiveSettingsTab] = useState(
    ProfileSettingsTabs.Public_Info,
  );

  const tabValues = [
    ProfileSettingsTabs.Public_Info,
    ProfileSettingsTabs.Security,
    ProfileSettingsTabs.Emails,
    ProfileSettingsTabs.Characteristics,
  ];

  return (
    <Style.MainContainer>
      <Style.StickyContent>
        <Style.HeaderContainer>
          <CircleButton onClick={onEditClose}>
            <ArrowLeftIcon className="w-6 h-6 text-dark-gray" />
          </CircleButton>

          <Style.HeaderTitle>{t('editAccount.editAccount')}</Style.HeaderTitle>

          <CircleButton onClick={onMenuClose}>
            <CloseIcon className="w-6 h-6 text-dark-gray" />
          </CircleButton>
        </Style.HeaderContainer>

        <AccountSettingsTabs
          setActiveSettingsTab={(newTab: ProfileSettingsTabs) => setActiveSettingsTab(newTab)}
          activeSettingsTab={activeSettingsTab}
          settingsVariants={tabValues}
        />
      </Style.StickyContent>

      <Style.ContentContainer>
        {activeSettingsTab === ProfileSettingsTabs.Public_Info && (
          <PublicInfo onModalToggle={onModalToggle} />
        )}

        {ProfileSettingsTabs.Security === activeSettingsTab && (
          <SecurityTab onModalToggle={onModalToggle} />
        )}

        {ProfileSettingsTabs.Emails === activeSettingsTab && (
          <EmailsPreferencesTab />
        )}

        {ProfileSettingsTabs.Characteristics === activeSettingsTab && (
          <CharacteristicsTabContent onModalToggle={onModalToggle} />
        )}
      </Style.ContentContainer>
    </Style.MainContainer>
  );
};
