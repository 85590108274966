import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const ContentContainer = styled.div`
  ${tw`min-h-360px flex flex-col gap-4`}
`;

export const TitleContainer = styled.div`
  ${tw`flex flex-col gap-1`}
`;

export const Title = styled.p`
  ${tw`font-bold text-22-30 text-gray7`}
`;

export const Subtitle = styled.p`
  ${tw`font-medium text-13-21 text-dim-gray`}
`;

export const SelectedCount = styled.span`
  ${tw`font-bold text-gray7`}
`;

export const EmptyMessage = styled.p`
  ${tw`font-medium text-dim-gray flex items-center justify-center`}
`;

export const ListContainer = styled.ul`
  ${tw`flex flex-col gap-2 overflow-auto pb-6`}
`;

export const ListItem = styled.li`
  ${tw`flex items-center gap-2 p-2 rounded border border-gray94 font-medium text-15-20`}
`;

export const ListItemContent = styled.div`
  ${tw`flex gap-2 text-gray7 items-center`}
`;

export const LightText = styled.p`
  ${tw`text-dim-gray min-w-32px`}
`;
