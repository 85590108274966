import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MenuContainer = styled.div`
  ${tw`p-2 flex flex-col gap-1 shadow-menu-shadow text-dark-gray font-medium w-72 bg-white rounded`}
`;

export const Title = styled.p`
  ${tw`px-2 py-1 text-13-21`}
`;

export const MenuItem = styled.button`
  ${tw`p-2 flex gap-2 text-17-26`}
`;

export const Divider = styled.div`
  ${tw`h-px bg-gray94`}
`;
