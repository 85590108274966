import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const ListContainer = styled.div`
  ${tw`overflow-auto mb-6`}
`;

export const ListItem = styled.div`
  ${tw`
    flex gap-2 justify-between items-center border border-gray94 rounded 
    font-medium shadow-card-shadow-default p-2 text-gray7
  `}
`;

export const ItemIndex = styled.div`
  ${tw`text-15-20 text-dim-gray min-w-32px`}
`;

export const DateContainer = styled.div`
  ${tw`flex gap-1 flex-1`}
`;

export const DateTitle = styled.p`
  ${tw`text-grey-dark-40`}
`;

export const TypeContainer = styled.div`
  ${tw`w-98px text-right`}
`;

export const SpinnerContainer = styled.div`
  ${tw`h-40`}
`;
