import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MenuContainer = styled.div`
  ${tw`shadow-menu-shadow w-243px p-2 flex flex-col gap-1 rounded-md`}
`;

export const LastVerification = styled.div`
  ${tw`flex gap-1 text-13-21 text-dim-gray px-2 py-1`}
`;

export const LastVerificationValue = styled.p`
  ${tw`text-dark-gray`}
`;

export const EditButton = styled.button<{ $isOpen: boolean }>`
  ${tw`flex items-center justify-center`}

  ${({
    $isOpen
  }) => ($isOpen ? tw`text-button-blue` : tw`text-dim-gray`)}
`;

export const MenuItem = styled.button`
  ${tw`flex items-center gap-2 text-red-text-error text-17-26 font-medium p-2`}
`;
