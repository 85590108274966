export const MIN_VALUE = 0;
export const MAX_VALUE = 100;

export const getRestrictedValue = (value: number) => {
  if (value < MIN_VALUE) {
    return MIN_VALUE;
  }

  if (value > MAX_VALUE) {
    return MAX_VALUE;
  }

  return value;
};
