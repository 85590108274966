import React, {
  useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  PageLayout
} from 'src/widgets';
import {
  PlusRoundedIcon
} from 'src/shared/icons';
import {
  OrgManagementTable
} from 'src/features/OrgManagementTable';
import {
  CreateOrganizationModal
} from 'src/features';

import * as Style from './OrganizationManagement.styles';

export const OrganizationManagement = () => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [shouldRefetchOrg, setShouldRefetchOrg] = useState(false);

  const toggleModal = () => setIsModalOpened((prev) => !prev);

  const toggleRefetch = () => setShouldRefetchOrg((prev) => !prev);

  const {
    t
  } = useTranslation();

  return (
    <PageLayout>
      <Style.ContentWrapper>
        <Style.ContentHeader>
          <Style.Title>{t('organisation.organisationManagement')}</Style.Title>

          <Style.HeaderRightSide>
            <Style.CreateOrgButton onClick={toggleModal}>
              <PlusRoundedIcon className="w-6 h-6 text-button-blue" />

              {t('organisation.createOrganisation')}
            </Style.CreateOrgButton>
          </Style.HeaderRightSide>
        </Style.ContentHeader>

        <OrgManagementTable
          shouldRefetchOrg={shouldRefetchOrg}
          toggleRefetchOrg={toggleRefetch}
        />
      </Style.ContentWrapper>

      <CreateOrganizationModal
        isOpen={isModalOpened}
        onClose={toggleModal}
        onSuccess={toggleRefetch}
      />
    </PageLayout>
  );
};
