import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const Container = styled.div<{ $isActive?: boolean }>`
  ${tw`bg-white min-h-screen`}
`;

export const HeaderContainer = styled.div`
  ${tw`flex justify-between gap-1 items-center mb-2`}
`;

export const Button = styled.button`
  ${tw`flex items-center p-3`}
`;

export const UserContainer = styled.div`
  ${tw`px-4 flex justify-between items-center gap-1 mb-6`}
`;

export const UserInfoContainer = styled.div`
  ${tw`flex gap-4 items-center`}
`;

export const UserNameContainer = styled.div`
  ${tw`grow flex flex-col gap-1`}
`;

export const UserName = styled.p`
  ${tw`font-bold text-15-20`}
`;

export const UserDescription = styled.p`
  ${tw`font-medium text-13-16`}
`;

export const SummaryContainer = styled.div`
  ${tw`p-4 rounded text-center w-full mx-auto mb-6 bg-alice-blue`}
`;

export const SummaryTitle = styled.div`
  ${tw`font-bold text-17-26 mb-4`}
`;

export const SummaryNumber = styled.div`
  ${tw`font-bold text-34-43 mb-2`}
`;

export const Tabs = styled.div`
  ${tw`flex gap-4 border-b border-b-light-grey mb-4`}
`;

export const Tab = styled.button<{ $isActive?: boolean }>`
  ${tw`
    flex-1 pb-3 relative min-w-74px cursor-pointer
    font-medium text-15-20 text-dark-gray text-center
    hover:text-gray7 transition duration-150
  `}

  ${({
    $isActive
  }) => $isActive
    && tw`
    text-button-blue
    after:(content-[""] w-full h-1 bg-button-blue absolute bottom-0 right-0 rounded-t-20px)
    hover:text-button-blue
  `}
`;

export const TabContentWrapper = styled.div`
  ${tw`px-6 overflow-auto flex flex-col`}
`;

export const SideWrapper = styled.div`
  ${tw`px-4`}
`;
