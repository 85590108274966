import React, {
  FC, PropsWithChildren
} from 'react';
import {
  Placement
} from 'react-laag';

import {
  FilterButton, PopoverMenu, Tooltip
} from 'src/shared/components';
import {
  themeColors
} from 'src/shared/utils';

import * as Style from './FilterPopoverMenu.styles';

interface FilterPopoverMenuProps extends PropsWithChildren {
  tooltipMessage: string;
  placement?: Placement;
  width?: number;
}

export const FilterPopoverMenu: FC<FilterPopoverMenuProps> = ({
  tooltipMessage,
  children,
  placement = 'bottom-end',
  width = 292,
}) => {
  return (
    <PopoverMenu
      renderCustomButton={({
        isOpen, toggleMenu, triggerProps
      }) => (
        <Tooltip
          message={tooltipMessage}
          disabled={!tooltipMessage}
        >
          <FilterButton
            onClick={toggleMenu}
            iconColor={
              !isOpen ? themeColors['dim-gray'] : themeColors['button-blue']
            }
            borderColor={
              !isOpen ? themeColors.gray90 : themeColors['button-blue']
            }
            {...triggerProps}
          />
        </Tooltip>
      )}
      placement={placement}
      auto={false}
    >
      <Style.FilterMenuContainer $width={width}>
        {children}
      </Style.FilterMenuContainer>
    </PopoverMenu>
  );
};
