import {
  FilterBy, SortBy
} from 'src/entities/Questions';
import {
  SortOrder
} from 'src/redux/openapi';
import {
  QuestionStatus,
  QuestionSortBy,
  HomeQuestionSortBy,
  QuestionEntityStatus,
} from 'src/shared/api/types';

export const getQuestionFilter = (
  filter: FilterBy,
  activeParam = 'activeOnly',
) => {
  switch (filter) {
    case FilterBy.Active:
      return {
        status: QuestionStatus.Available,
        [activeParam]: true,
      };
    case FilterBy.Deactivated:
      return {
        status: QuestionStatus.Deactivated,
      };
    case FilterBy.Available:
    default:
      return {
        status: QuestionStatus.Available,
        [activeParam]: false,
      };
  }
};

export const getGroupQuestionFilter = (filter: FilterBy) => {
  switch (filter) {
    case FilterBy.Deactivated:
      return {
        groupStatus: QuestionEntityStatus.Deactivated,
      };
    case FilterBy.Available:
    default:
      return {
        groupStatus: QuestionEntityStatus.Activated,
      };
  }
};

export const getQuestionSortBy = <T>(
  sortBy: SortBy,
  isHomePage?: boolean,
): {
    sortBy: T;
    sortOrder?: SortOrder;
  } => {
  const sortMapping = isHomePage
    ? {
      Oldest: HomeQuestionSortBy.CreatedAt,
      Discussed: HomeQuestionSortBy.CommentCount,
      Participants: HomeQuestionSortBy.ParticipantsCount,
      Updates: HomeQuestionSortBy.UpdatesCount,
      Newest: HomeQuestionSortBy.CreatedAt,
    }
    : {
      Oldest: QuestionSortBy.CreatedAt,
      Discussed: QuestionSortBy.CommentCount,
      Participants: QuestionSortBy.ParticipantsCount,
      Updates: QuestionSortBy.ForecastCount,
      Newest: QuestionSortBy.CreatedAt,
    };

  return {
    sortBy: sortMapping[sortBy] as T,
    sortOrder: sortBy !== SortBy.Oldest ? 'desc' : 'asc',
  };
};
