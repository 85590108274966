import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const Header = styled.header<{ $isCustom: boolean }>`
  ${tw`bg-transparent md:bg-white text-dim-gray pl-0 md:pl-6 flex justify-between rounded`}

  ${({
    $isCustom
  }) => ($isCustom ? tw`py-1` : tw`py-2`)}
`;

export const LeftContainer = styled.div`
  ${tw`flex w-auto justify-between items-center`}
`;

export const LogoContainer = styled.div<{ $isCustom: boolean }>`
  ${({
    $isCustom
  }) => ($isCustom ? tw`w-12 h-12` : tw`w-62px h-8`)}
`;

export const RightContainer = styled.div`
  ${tw`flex gap-x-2 grow justify-end items-center`}
`;
