import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const Container = styled.div`
  ${tw`bg-white p-6 rounded-md`}
`;

export const ProgressContainer = styled.div`
  ${tw`flex gap-2 justify-between mb-12`}
`;

export const TimerContainer = styled.div`
  ${tw`flex gap-2 px-2 py-1 bg-alice-blue rounded relative`}
`;

export const SecondsBlock = styled.div`
  ${tw`text-gray7 text-base font-medium w-7`}
`;

export const ProgressArea = styled.div`
  ${tw`bg-alice-blue rounded flex-1`}
`;

export const ProgressResult = styled.div<{ $width: number }>`
  ${tw`text-white bg-light-blue text-right px-2 py-1 rounded-md`}

  ${({
    $width
  }) => ({
    width: `${$width * 100}%`,
  })}
`;

export const Title = styled.h1`
  ${tw`text-gray7 text-34-43 font-bold mb-8`}
`;

export const InputContainer = styled.div`
  ${tw`flex justify-between gap-4 mb-4`}
`;

export const InputDividerContainer = styled.div`
  ${tw`w-4 h-12 flex items-center justify-center self-end`}
`;

export const InputDivider = styled.div`
  ${tw`w-4 h-1 rounded-100px bg-gray94`}
`;

export const InputArea = styled.div`
  ${tw`flex-1`}
`;

export const RangeContainer = styled.div`
  ${tw`bg-alice-blue rounded py-1 px-1 mb-12`}
`;

export const Relative = styled.div`
  ${tw`relative`}
`;

export const ButtonsContainer = styled.div`
  ${tw`flex justify-between`}
`;

export const FinishButton = styled.button`
  ${tw`bg-white border border-red-text-error text-red-text-error text-xl font-bold py-3 px-8 rounded-full w-max`}
`;

export const TicksContainer = styled.div`
  ${tw`absolute top-1 flex justify-between mx-4`}

  width: calc(100% - 16px - 4px);
`;

export const Tick = styled.div<{ $width: number }>`
  ${({
    $width
  }) => `
  width: ${$width}%;
`}
`;

export const ContainerX = styled.div`
  ${tw`border-t border-t-light-grey`}
`;

export const ContainerXTicks = styled.div`
  ${tw`flex justify-between`}
`;

export const XTick = styled.div`
  ${tw`w-8 text-center`}
`;
