import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MainContainer = styled.div`
  ${tw`px-2 py-4 border-t border-t-gray90`}
`;

export const InputWrapper = styled.div`
  ${tw`pl-4 pr-1 py-1 rounded border border-gray94 bg-alice-blue flex items-center gap-4`}
`;

export const InputBlock = styled.input`
  ${tw`outline-none bg-alice-blue w-full text-17`}
`;
export const SendButton = styled.button`
  ${tw`p-2.5 rounded-sm bg-white shadow-card-shadow-default`}
`;
