import React, {
  FC
} from 'react';
import {
  useNavigate
} from 'react-router-dom';

import {
  CircleButton
} from 'src/shared/components';
import {
  ArrowLeft
} from 'src/shared/icons';
import {
  themeColors
} from 'src/shared/utils';

interface NavigateBackButtonProps {
  backgroundColor?: string;
  borderColor?: string;
}

export const NavigateBackButton: FC<NavigateBackButtonProps> = ({
  backgroundColor = themeColors['light-grayish-blue'],
  borderColor = themeColors.gray90,
}) => {
  const navigate = useNavigate();

  return (
    <CircleButton
      onClick={() => navigate(-1)}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
    >
      <img
        src={ArrowLeft}
        alt="icon"
      />
    </CircleButton>
  );
};
