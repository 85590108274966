import React from 'react';
import {
  useTranslation
} from 'react-i18next';
import {
  SingleValueProps
} from 'react-select';

import {
  SelectValue
} from '../../types';

import * as Style from './ChooseGroupContent.styles';

const isGroupValue = (value: unknown): value is SelectValue => {
  return typeof value === 'object' && value !== null && 'location' in value;
};

export const SingleValue = <T,>({
  selectProps
}: SingleValueProps<T>) => {
  const {
    t
  } = useTranslation();

  const {
    value
  } = selectProps;

  if (!value || !isGroupValue(value)) {
    return null;
  }

  const {
    label, location, activeUsersCount
  } = value;

  return (
    <Style.GroupInfoContainer>
      <Style.GroupTitle>{label}</Style.GroupTitle>

      <Style.GroupInfo>
        <Style.GroupInvitedInfo>
          {`${t('common.active')}: `}

          <strong>{activeUsersCount}</strong>
        </Style.GroupInvitedInfo>

        <Style.GroupLocation>{location}</Style.GroupLocation>
      </Style.GroupInfo>
    </Style.GroupInfoContainer>
  );
};
