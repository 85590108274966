import {
  PostApiOrganizationsByIdLogoApiResponse,
  PostApiOrganizationsByIdUsersInvitesFilesTemplateApiArg,
  PostApiOrganizationsByIdUsersInvitesFilesTemplateApiResponse,
} from 'src/redux/openapi/endpoints/organizations';
import {
  emptySplitApi as api
} from 'src/redux/openapi/emptyApi';

type PostOrgInviteFileTemplateArgs =
  PostApiOrganizationsByIdUsersInvitesFilesTemplateApiArg & {
    formData: FormData;
  };

type PostOrgLogoFileArgs = {
  id: string;
  formData: FormData;
};

export const organizationsApi = api.injectEndpoints({
  endpoints: (build) => ({
    postOrgInviteFileTemplate: build.mutation<
    PostApiOrganizationsByIdUsersInvitesFilesTemplateApiResponse,
    PostOrgInviteFileTemplateArgs
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}/users/invites/files/template`,
        method: 'POST',
        body: queryArg.formData,
      }),
      invalidatesTags: ['Template'],
    }),
    postOrgLogoFile: build.mutation<
    PostApiOrganizationsByIdLogoApiResponse,
    PostOrgLogoFileArgs
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.id}/logo`,
        method: 'POST',
        body: queryArg.formData,
      }),
    }),
  }),
});

export const {
  usePostOrgInviteFileTemplateMutation,
  usePostOrgLogoFileMutation,
} = organizationsApi;
