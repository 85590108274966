import React, {
  FC
} from 'react';
import {
  CloseButtonProps
} from 'react-toastify';

import {
  CloseIcon
} from 'src/shared/icons';

export const CloseToastButton: FC<CloseButtonProps> = ({
  closeToast
}) => (
  <button
    onClick={closeToast}
    type="button"
    className="self-start p-3 text-dark-gray"
  >
    <CloseIcon />
  </button>
);
