import dayjs from 'dayjs';
import React, {
  FC, Fragment, useState
} from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import {
  GraphType
} from 'src/entities';
import {
  useXAxis
} from 'src/entities/Graph/hooks';
import {
  GraphBarName, TooltipPayloadType
} from 'src/entities/Graph/types';
import {
  GraphLabel,
  GraphLegend,
  GraphTick,
  GraphTooltip,
} from 'src/entities/Graph/ui';
import {
  useTypedSelector
} from 'src/redux';
import {
  timeSpanSelectors
} from 'src/redux/timeSpans';
import {
  DAY, forecastsColorValues, themeColors
} from 'src/shared/utils';
import {
  SelectsValue
} from 'src/widgets/GraphSection/GraphSection';
import {
  ChartData
} from 'src/redux/openapi';

import {
  GROUPS_TOTAL_PREDICTION,
  MY_FORECAST,
  OWN_PREDICTION,
  TOTAL_PREDICTION,
} from './constants';

interface BarGraphProps {
  optionsSelected: SelectsValue[];
  changeValue: (title: string) => void;
  isMyForecastShow: boolean;
  isAverageForecastShow: boolean;
  filteredGroupID: string[];
  timelineConfig?: {
    startDate: number;
    endDate: number;
    timeGap: number;
  };
  statisticsData: ChartData[];
}

type RadiusType = number | [number, number, number, number];

interface DataProp {
  width: number;
  x: number;
  y: number;
  tooltipPayload: TooltipPayloadType[];
}

type PositionProp = Pick<DataProp, 'x' | 'y'>;

export const BarGraph: FC<BarGraphProps> = ({
  optionsSelected,
  changeValue,
  isMyForecastShow,
  isAverageForecastShow,
  filteredGroupID,
  statisticsData,
}) => {
  const selectedTimeSpan = useTypedSelector(
    timeSpanSelectors.selectActiveTimeSpan,
  );

  if (!selectedTimeSpan) {
    return null;
  }

  const timelineTicks = statisticsData.map((item) => item.date);
  const minDateValue = timelineTicks[0];
  const maxDateValue = timelineTicks[timelineTicks.length - 1];

  const timelineConfig = {
    startDate: dayjs(minDateValue).toDate().getTime(),
    endDate: dayjs(maxDateValue).toDate().getTime(),
    timeGap: DAY,
  };

  const getCapacity = () => {
    let barCount = filteredGroupID.length;

    if (isMyForecastShow) {
      barCount += 1;
    }

    if (isAverageForecastShow) {
      barCount += 1;
    }

    if (barCount <= 1) {
      return 5;
    }

    if (barCount === 2) {
      return 4;
    }

    return 3;
  };

  const {
    setScrollDistance, scrollDistance, isOverflown, minDate, maxDate
  } = useXAxis({
    timelineConfig,
    defaultScrollDistance: 1,
    capacity: getCapacity(),
  });

  const handleChangeFilters = (dataKey: string) => {
    changeValue(dataKey);
  };

  const [positionTooltip, setPositionTooltip] = useState<PositionProp | null>(
    null,
  );

  const [tooltipData, setTooltipData] = useState<TooltipPayloadType[] | null>(
    null,
  );

  const handleMouseLeave = () => {
    setTooltipData(null);
  };

  const handleSetTooltipPosition = ({
    x,
    y,
    width,
    tooltipPayload,
  }: DataProp) => {
    setPositionTooltip({
      x: x > 600 ? x : x + width,
      y,
    });

    setTooltipData(tooltipPayload);
  };

  const xAxisTimeGap = timelineConfig.timeGap;

  const getBarRadiusValue = (optionTitle: string): RadiusType => {
    const filteredOptions = optionsSelected.filter(
      (option) => option.isSelected,
    );

    const indexOfTitle = filteredOptions.findIndex(
      (option) => option.title === optionTitle,
    );

    if (indexOfTitle === 0 && indexOfTitle === filteredOptions.length - 1) {
      return [8, 8, 4, 4];
    }

    if (indexOfTitle === 0) {
      return [0, 0, 4, 4];
    }

    if (indexOfTitle === filteredOptions.length - 1) {
      return [8, 8, 0, 0];
    }

    return [0, 0, 0, 0];
  };

  const optionsToShow = optionsSelected.map((data) => data.title);

  const graphState = optionsSelected.reduce(
    (acc: Record<string, boolean>, {
      title, isSelected
    }) => {
      return {
        ...acc,
        [title]: isSelected,
      };
    },
    {},
  );

  return (
    <ResponsiveContainer
      width="100%"
      height={400}
    >
      <BarChart
        data={statisticsData}
        barCategoryGap={80}
        barGap={25}
        barSize={40}
        margin={{
          top: 10,
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <Legend
          content={(
            <GraphLegend
              state={graphState}
              onChange={handleChangeFilters}
              isMulti
              onScroll={setScrollDistance}
              scrollDistance={scrollDistance}
              withScroll={isOverflown}
            />
          )}
          wrapperStyle={{
            bottom: '-25px',
          }}
        />

        <CartesianGrid
          stroke={themeColors['gray-whisper']}
          strokeWidth={1}
          height={400}
          vertical={false}
        />

        <Tooltip
          cursor={false}
          content={(
            <GraphTooltip
              timeGap={xAxisTimeGap}
              type={GraphType.BAR}
              isLeftSide={!!positionTooltip?.x && positionTooltip.x > 600}
              payloadData={tooltipData}
            />
          )}
          wrapperStyle={{
            outline: 'none',
            zIndex: 99,
          }}
          allowEscapeViewBox={{
            x: true,
            y: true,
          }}
          position={{
            x: positionTooltip?.x || 0,
            y: positionTooltip?.y || 0,
          }}
        />

        <XAxis
          padding={{
            right: 30,
            left: 30,
          }}
          orientation="top"
          dataKey="date"
          type="number"
          allowDataOverflow
          domain={[
            minDate ? minDate - xAxisTimeGap / 2 : `dataMin - ${DAY}`,
            maxDate ? maxDate + xAxisTimeGap / 2 : `dataMax + ${DAY}`,
          ]}
          interval={0}
          axisLine={false}
          tickLine={false}
          tick={(
            <GraphTick
              vertical
              date
              textAnchor="middle"
              orientation="top"
            />
          )}
          {...(timelineTicks && {
            ticks: timelineTicks,
          })}
        />

        <YAxis
          yAxisId="estimationPercentsAxis"
          orientation="left"
          tickCount={6}
          interval={0}
          tickLine={false}
          axisLine={false}
          width={0}
          type="number"
          domain={[0, 100]}
          tick={<GraphTick textAnchor="end" />}
        />

        <YAxis
          yAxisId="votesCountAxis"
          orientation="right"
          tickCount={6}
          interval={0}
          tickLine={false}
          axisLine={false}
          width={0}
          tick={<GraphTick />}
        />

        {optionsToShow.map((keyData, i) => (
          <Fragment key={keyData}>
            {isMyForecastShow && (
              <Bar
                unit="%"
                name={keyData}
                hide={!graphState[keyData]}
                yAxisId="estimationPercentsAxis"
                dataKey={`${OWN_PREDICTION}[${keyData}]`}
                fill={forecastsColorValues[i]?.forecastColor || 'red'}
                isAnimationActive={false}
                stackId={MY_FORECAST}
                radius={getBarRadiusValue(keyData)}
                onMouseOver={handleSetTooltipPosition}
                onMouseLeave={handleMouseLeave}
                label={<GraphLabel title={GraphBarName.OWN} />}
              />
            )}

            {isAverageForecastShow && (
              <Bar
                unit="%"
                name={keyData}
                hide={!graphState[keyData]}
                yAxisId="estimationPercentsAxis"
                dataKey={`${TOTAL_PREDICTION}[${keyData}]`}
                fill={forecastsColorValues[i]?.forecastColor || 'red'}
                isAnimationActive={false}
                stackId={TOTAL_PREDICTION}
                radius={getBarRadiusValue(keyData)}
                onMouseOver={handleSetTooltipPosition}
                onMouseLeave={handleMouseLeave}
                label={<GraphLabel title={GraphBarName.TOTAL} />}
              />
            )}

            {!!filteredGroupID.length && (
              <>
                <Bar
                  unit="%"
                  name={keyData}
                  hide={!graphState[keyData]}
                  yAxisId="estimationPercentsAxis"
                  dataKey={`${GROUPS_TOTAL_PREDICTION}[${keyData}]`}
                  fill={forecastsColorValues[i]?.forecastColor || 'red'}
                  isAnimationActive={false}
                  stackId="filtered"
                  radius={getBarRadiusValue(keyData)}
                  onMouseOver={handleSetTooltipPosition}
                  onMouseLeave={handleMouseLeave}
                  label={<GraphLabel title={GraphBarName.GTOTAL} />}
                />

                {filteredGroupID.map((groupId) => (
                  <Bar
                    key={`${groupId}[${keyData}]`}
                    unit="%"
                    name={keyData}
                    hide={!graphState[keyData]}
                    yAxisId="estimationPercentsAxis"
                    dataKey={`${groupId}[${keyData}]`}
                    fill={forecastsColorValues[i]?.forecastColor || 'red'}
                    isAnimationActive={false}
                    stackId={groupId}
                    radius={getBarRadiusValue(keyData)}
                    onMouseOver={handleSetTooltipPosition}
                    onMouseLeave={handleMouseLeave}
                    label={<GraphLabel title={groupId} />}
                  />
                ))}
              </>
            )}
          </Fragment>
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};
