export const getTimeAgo = (currentTime: number, createdAt: number): string => {
  const timeDifference = currentTime - createdAt;

  const minute = 60 * 1000;
  const hour = 60 * minute;

  if (timeDifference < minute) {
    return 'just now';
  }

  if (timeDifference < hour) {
    const minutesAgo = Math.floor(timeDifference / minute);

    return `${minutesAgo} min${minutesAgo !== 1 ? 's' : ''} ago`;
  }

  const hoursAgo = Math.floor(timeDifference / hour);

  return `${hoursAgo} hour${hoursAgo !== 1 ? 's' : ''} ago`;
};
