import React, {
  FC
} from 'react';
import i18next from 'i18next';
import {
  useTranslation
} from 'react-i18next';

import {
  TimeSpansValues
} from 'src/redux/timeSpans';

import * as Style from './Menus.styles';

interface TimeRangeMenuProps {
  onSelect: (value: TimeSpansValues | null) => void;
  currentValue: TimeSpansValues | null;
}

const getTimeRangeMap = () => [
  {
    id: null,
    label: i18next.t('filters.questionRange'),
  },
  {
    id: TimeSpansValues.upToday,
    label: `24 ${i18next.t('date.hours')}`,
  },
  {
    id: TimeSpansValues.last7Days,
    label: i18next.t('date.oneWeek'),
  },
  {
    id: TimeSpansValues.twoWeeks,
    label: i18next.t('date.twoWeeks'),
  },
  {
    id: TimeSpansValues.last30Days,
    label: i18next.t('date.Month'),
  },
];

export const TimeRangeMenu: FC<TimeRangeMenuProps> = ({
  onSelect,
  currentValue,
}) => {
  const {
    t
  } = useTranslation();

  const timeRangeMap = getTimeRangeMap();

  return (
    <Style.MenuContainer>
      <Style.MenuTitle>{t('filters.updatesPer')}</Style.MenuTitle>

      {timeRangeMap.map(({
        id, label
      }) => (
        <Style.MenuItem
          key={id}
          type="button"
          $isActive={currentValue === id}
          onClick={() => onSelect(id)}
        >
          {label}
        </Style.MenuItem>
      ))}
    </Style.MenuContainer>
  );
};
