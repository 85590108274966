import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MainContainer = styled.div`
  ${tw`p-2 flex flex-col gap-1 shadow-menu-shadow text-dark-gray font-medium w-302px bg-white rounded-md`}
`;

export const ButtonContainer = styled.button`
  ${tw`p-2 flex gap-2`}
`;

export const Divider = styled.div`
  ${tw`h-px bg-gray94`}
`;

export const ButtonTitle = styled.div<{ $isRed?: boolean }>`
  ${tw`text-17-26 font-medium`}

  ${({
    $isRed
  }) => ($isRed ? tw`text-red-text-error` : tw`text-dark-gray`)}
`;
