import React, {
  FC
} from 'react';
import {
  useSearchParams
} from 'react-router-dom';
import {
  Trans, useTranslation
} from 'react-i18next';

import {
  questionsSortBy,
  questionsFilterBy,
  questionsTimeRange,
} from 'src/shared/utils';

import * as Style from './EmptyResultsMessage.style';

interface EmptyResultsMessageProps {
  isTable?: boolean;
}

export const EmptyResultsMessage: FC<EmptyResultsMessageProps> = ({
  isTable,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    t
  } = useTranslation();

  const onReset = () => {
    const paramsToDelete = [questionsSortBy, questionsTimeRange];

    if (!isTable) {
      paramsToDelete.push(questionsFilterBy);
    }

    paramsToDelete.forEach((param) => searchParams.delete(param));
    setSearchParams(searchParams);
  };

  return (
    <Style.EmptyMessageContainer>
      <Style.EmptyMessageTitle>
        {t('empty.thereAreNoMatchesWithFilters')}
      </Style.EmptyMessageTitle>

      <Style.EmptyMessageSubtitle>
        <Trans i18nKey="empty.tryOtherSettingsOrResetFilters" />
      </Style.EmptyMessageSubtitle>

      <Style.ResetFiltersButton onClick={onReset}>
        {t('filters.resetFilters')}
      </Style.ResetFiltersButton>
    </Style.EmptyMessageContainer>
  );
};
