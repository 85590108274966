import {
  createApi, fetchBaseQuery
} from '@reduxjs/toolkit/query/react';
import qs from 'qs';

export const emptySplitApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_PATH,
    credentials: 'include',
    paramsSerializer: (params) => {
      return qs.stringify(
        params,
        {
          arrayFormat: 'brackets',
        }
      );
    },
    timeout: 10000,
  }),
  tagTypes: [
    'Organization',
    'Theme',
    'Members',
    'Invites',
    'Template',
    'Followings',
    'Favorites',
    'Survey',
  ],
  endpoints: () => ({}),
});
