import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MenuContainer = styled.div`
  ${tw`bg-white rounded-15px shadow-menu-shadow max-w-max`}
`;

export const MenuOption = styled.button`
  ${tw`px-4 py-3
  flex justify-between gap-1 items-center w-full`}
`;

export const MenuText = styled.p`
  ${tw`w-14 text-15-20 font-medium text-gray7 text-left`}
`;
