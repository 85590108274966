import {
  CheckBoxFalseIcon,
  CheckBoxMinusIcon,
  CheckboxSquareFilledIcon,
  CheckboxSquareIcon,
  CheckBoxTrueIcon,
} from 'src/shared/icons';

import {
  CheckboxType, SelectedState
} from './types';

export const checkMarksMap = {
  [CheckboxType.SQUARE]: {
    [SelectedState.CHECKED]: CheckboxSquareFilledIcon,
    [SelectedState.UNCHECKED]: CheckboxSquareIcon,
  },
  [CheckboxType.CHECK_MARK]: {
    [SelectedState.CHECKED]: CheckBoxTrueIcon,
    [SelectedState.UNCHECKED]: CheckBoxFalseIcon,
  },
  [CheckboxType.MINUS]: {
    [SelectedState.CHECKED]: CheckBoxMinusIcon,
    [SelectedState.UNCHECKED]: CheckBoxFalseIcon,
  },
};
