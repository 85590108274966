export enum EditGroupFormField {
  GroupName = 'groupName',
  Location = 'groupLocation',
  MainAdmin = 'mainAdministrator',
  FirstSubAdmin = 'firstSubAdmin',
  SecondSubAdmin = 'secondSubAdmin',
}

export type EditGroupForm = {
  [EditGroupFormField.GroupName]: string;
  [EditGroupFormField.Location]: {
    value: string;
    label: string;
  };
  [EditGroupFormField.MainAdmin]: string;
  [EditGroupFormField.FirstSubAdmin]: string;
  [EditGroupFormField.SecondSubAdmin]: string;
};
