import React, {
  FC, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import {
  useNavigate, useSearchParams
} from 'react-router-dom';

import {
  Button,
  ModalContainer,
  SliderBlock,
  Spinner,
  successfulToast,
} from 'src/shared/components';
import {
  ArrowLeftIcon
} from 'src/shared/icons';
import {
  ROUTE, showWarningFromServer
} from 'src/shared/utils';
import {
  usePostCalibrationsMutation
} from 'src/redux/openapi';

import * as Style from './ModalSetConfidenceRate.style';

interface ModalSetConfidenceRateProps {
  closeModal: () => void;
  isOpen: boolean;
  isTestResult?: boolean;
}

export const ModalSetConfidenceRate: FC<ModalSetConfidenceRateProps> = ({
  closeModal,
  isOpen,
  isTestResult,
}) => {
  const {
    t
  } = useTranslation();

  const [confidenceRate, setConfidenceRate] = useState(10);

  const handleConfidenceRateChange = (value: number) => {
    if (value < 10 || value > 90) {
      return;
    }

    setConfidenceRate(value);
  };

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const isTest = !!searchParams.get('test');

  const handleCloseModal = () => {
    if (isTest) {
      searchParams.delete('test');
      setSearchParams(searchParams);
    }

    closeModal();
  };

  const [startCalibrations, {
    isLoading
  }] = usePostCalibrationsMutation();

  const onProceed = async () => {
    try {
      const resp = await startCalibrations({
        createCalibrationBodySchema: {
          confidence: confidenceRate,
          isTest: isTestResult || isTest,
        },
      }).unwrap();

      successfulToast(resp.message);

      if (resp.data.calibration.totalQuestions > 0) {
        navigate(
          `/${ROUTE.CALIBRATION_TOOL}/${ROUTE.COUNT_DOWN}/${resp.data.calibration.id}`,
        );
      } else {
        handleCloseModal();
      }
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  return (
    <ModalContainer
      modalWidth={715}
      isDarkBackground
      closeModalHandler={handleCloseModal}
      isOpen={isOpen}
      modalTitle={t('calibration.confidencePercentage')}
      isGap={false}
    >
      <Style.MainContainer>
        <Style.Text>{t('calibration.specifyConfidencePercentage')}</Style.Text>

        <Style.SliderContainer>
          <SliderBlock
            variant="big"
            value={confidenceRate}
            setValue={handleConfidenceRateChange}
            formattedValue={`${confidenceRate}%`}
            step={5}
          />
        </Style.SliderContainer>

        <Style.ButtonContainer>
          <Button
            variant="big-grey-bordered"
            onClick={handleCloseModal}
            className="text-xl font-bold text-dark-gray !w-max"
            disabled={isLoading}
          >
            <ArrowLeftIcon />

            {t('buttons.back')}
          </Button>

          <Button
            variant="big-blue"
            className="text-xl font-bold text-white !w-max"
            onClick={onProceed}
          >
            {t('buttons.proceed')}

            {isLoading && (
              <Spinner
                color="white"
                size={24}
                centered={false}
              />
            )}
          </Button>
        </Style.ButtonContainer>
      </Style.MainContainer>
    </ModalContainer>
  );
};
