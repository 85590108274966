import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const FilterAmount = styled.div<{ $isActive: boolean }>`
  ${tw`rounded-100px bg-alice-blue font-medium text-15-20 px-1 min-w-28px text-center`}

  ${({
    $isActive
  }) => ($isActive ? tw`text-gray7` : tw`text-dim-gray`)}
`;

export const SearchContainer = styled.div`
  ${tw`flex w-max gap-2`}
`;

export const FiltersContainer = styled.div`
  ${tw`mb-6`}
`;
