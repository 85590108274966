import {
  useMemo
} from 'react';

interface UsePaginationArgs {
  totalCount: number;
  pageSize: number;
  pageRange?: number;
  currentPage: number;
  pageMargin?: number;
}

const DOTS = '...';

const range = (start: number, end: number) => {
  const length = end - start + 1;

  return Array.from(
    {
      length,
    },
    (_, idx) => idx + start,
  );
};

export const usePaginationPages = ({
  totalCount,
  pageSize,
  pageRange = 5,
  currentPage,
  pageMargin = 1,
}: UsePaginationArgs) => {
  const paginationRange = useMemo(
    () => {
      const totalPages = Math.ceil(totalCount / pageSize);

      if (pageRange + pageMargin + 1 >= totalPages) {
        return range(
          1,
          totalPages
        );
      }

      const lastLeftPage = pageRange - 1;
      const firstRightPage = totalPages - pageRange + 1;

      const shouldShowRightDots = currentPage <= firstRightPage;
      const shouldShowLeftDots = currentPage > lastLeftPage;

      const leftSide = shouldShowLeftDots
        ? range(
          1,
          pageMargin
        )
        : range(
          1,
          pageRange
        );

      const rightSide = shouldShowRightDots
        ? range(
          totalPages - pageMargin + 1,
          totalPages
        )
        : range(
          totalPages - pageRange + 1,
          totalPages
        );

      if (!shouldShowRightDots || !shouldShowLeftDots) {
        return [...leftSide, DOTS, ...rightSide];
      }

      const halfRange = Math.floor((pageRange - 2 * pageMargin) / 2);

      const center = range(
        currentPage - halfRange,
        currentPage + halfRange
      );

      return [...leftSide, DOTS, ...center, DOTS, ...rightSide];
    },
    [pageRange, pageMargin, totalCount, pageSize, currentPage]
  );

  return paginationRange;
};
