import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const ContentContainer = styled.div<{ $isGap: boolean }>`
  ${tw`flex flex-col h-full`}

  ${({
    $isGap
  }) => $isGap && tw`gap-6`}
`;

export const ChildrenContainer = styled.div`
  ${tw`grow overflow-y-auto`}
`;

export const Header = styled.div`
  ${tw`flex flex-row justify-between items-center gap-2`}
`;

export const GroupDetailTitle = styled.div`
  ${tw`text-22-30 font-bold text-gray7`}
`;

export const ModalWindowCloseBtn = styled.button`
  ${tw`w-11 h-11 rounded-100px px-2 py-2.5 border border-light-grey`}
`;
