import {
  t
} from 'i18next';

import {
  ROUTE
} from 'src/shared/utils';

export const getOrgMenuConfig = () => {
  return [
    {
      route: ROUTE.ADD_IN_BULK,
      label: t('question.addInBulk'),
    },
    {
      route: ROUTE.REMOVE_IN_BULK,
      label: t('question.removeInBulk'),
    },
  ];
};

export const getGroupMenuConfig = () => {
  return [
    {
      route: ROUTE.SET_ONBOARDING_TEXT,
      label: t('question.setOnboardingText'),
    },
  ];
};
