import React, {
  FC, ReactElement, ButtonHTMLAttributes
} from 'react';
import clsx from 'clsx';
import {
  t
} from 'i18next';

import {
  themeColors
} from 'src/shared/utils/themeColors';
import {
  NextIcon
} from 'src/shared/icons';
import {
  ButtonTitles
} from 'src/shared/api/types';

import * as Style from './ActionButton.styles';

export interface ActionButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonBackgroundColor?: string;
  buttonTextColor?: string;
  buttonTitle?: string;
  buttonWithIcon?: boolean;
  buttonIcon?: ReactElement;
  buttonWithBorder?: boolean;
}

export const ActionButton: FC<ActionButtonProps> = ({
  buttonBackgroundColor = 'transparent',
  buttonTextColor = themeColors['dark-gray'],
  buttonTitle = t(`buttons.${ButtonTitles.Onboard}`),
  buttonWithIcon = true,
  buttonIcon = <NextIcon />,
  buttonWithBorder = true,
  className,
  ...props
}) => {
  return (
    <Style.ButtonContainer
      type="button"
      $backgroundColor={buttonBackgroundColor}
      $textColor={buttonTextColor}
      $withBorder={buttonWithBorder}
      className={clsx(
        className,
        {
          'hover:text-accent80': buttonTitle === ButtonTitles.Update,
        }
      )}
      {...props}
    >
      {buttonTitle}

      {buttonWithIcon && <Style.ButtonIcon>{buttonIcon}</Style.ButtonIcon>}
    </Style.ButtonContainer>
  );
};
