import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MainContainer = styled.div`
  ${tw`rounded-md bg-white shadow-card-shadow-1 p-6 min-h-369px`}
`;

export const EmptyText = styled.div`
  ${tw`font-['Inter'] leading-5 text-dark-gray`}
`;

export const Title = styled.h3`
  ${tw`text-xl font-bold text-gray7 mb-6`}
`;

export const Content = styled.p`
  ${tw`text-17-26 font-medium text-dark-gray`}
`;
