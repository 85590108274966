import React, {
  FC
} from 'react';
import clsx from 'clsx';

import './styles.css';
import {
  t
} from 'i18next';

type InputProps = React.TextareaHTMLAttributes<HTMLTextAreaElement>;

export const InputTextArea: FC<InputProps> = ({
  className,
  rows = 1,
  value,
  onChange,
  placeholder = t('comment.commentForecastOptional'),
  ...props
}) => {
  return (
    <textarea
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className={clsx(
        `
          max-h-max p-1
          text-15-20 md:text-17 text-gray7 placeholder:text-dim-gray
          bg-alice-blue border border-form-border
          rounded auto-resize-off
          focus:border-input-border-blue focus:bg-input-blue
        `,
        className,
      )}
      rows={rows}
      {...props}
    />
  );
};
