import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  Checkbox
} from 'src/shared/components';
import {
  useTypedSelector
} from 'src/redux';
import {
  organizationSelectors
} from 'src/redux/organizations';

import * as Style from './GroupsAndMembers.styles';

interface FilterMembersProps {
  isShowAll: boolean;
  setShowAll: (value: boolean) => void;
  selectedCharacteristics: string[];
  changeCharacteristic: (title: string | string[]) => void;
}

const CHECKBOX_CLASS_NAME = 'text-17-26 font-medium text-dark-gray p-2 justify-between w-full flex-row-reverse';

export const FilterMembers: FC<FilterMembersProps> = ({
  isShowAll,
  setShowAll,
  changeCharacteristic,
  selectedCharacteristics,
}) => {
  const {
    t
  } = useTranslation();

  const characteristics = useTypedSelector(
    organizationSelectors.characteristics,
  );

  const isAllSelected = selectedCharacteristics.length === characteristics.length;

  const selectAllCharacteristics = () => {
    if (isAllSelected) {
      changeCharacteristic([]);
    } else {
      changeCharacteristic(characteristics);
    }
  };

  return (
    <Style.FilterContainer>
      <Style.FilterBlock>
        <Style.FilterTitle>{t('filters.showMe')}</Style.FilterTitle>

        <Style.FilterButtons
          $isSelected={isShowAll}
          type="button"
          onClick={() => setShowAll(true)}
        >
          {t('organisation.allUsers')}
        </Style.FilterButtons>

        <Style.FilterButtons
          $isSelected={!isShowAll}
          type="button"
          onClick={() => setShowAll(false)}
        >
          {t('organisation.notAssigned')}
        </Style.FilterButtons>

        <Style.Divider />

        {!!characteristics.length && (
          <>
            <Style.FilterTitle>
              {t('organisation.showWithThisCharacteristics')}
            </Style.FilterTitle>

            <Checkbox
              type="checkMark"
              isChecked={isAllSelected}
              onChange={selectAllCharacteristics}
              color="inherit"
              className={CHECKBOX_CLASS_NAME}
              label={t('organisation.allCharacteristic')}
            />
          </>
        )}

        {characteristics.map((characteristic) => {
          const isSelected = selectedCharacteristics.includes(characteristic);

          return (
            <Checkbox
              key={characteristic}
              type="checkMark"
              isChecked={isSelected}
              onChange={() => changeCharacteristic(characteristic)}
              color="inherit"
              className={CHECKBOX_CLASS_NAME}
              label={characteristic}
            />
          );
        })}
      </Style.FilterBlock>
    </Style.FilterContainer>
  );
};
