import React, {
  FC
} from 'react';

import {
  CircleButton, ModalContainer
} from 'src/shared/components';
import {
  ArrowLeftIcon, CloseIcon
} from 'src/shared/icons';
import {
  EnterRecoveryCode
} from 'src/widgets';

import * as Style from './RecoveryCodeModal.styles';

interface RecoveryCodeModalProps {
  isOpen: boolean;
  onClose: () => void;
  goBack: () => void;
}

export const RecoveryCodeModal: FC<RecoveryCodeModalProps> = ({
  isOpen,
  onClose,
  goBack,
}) => {
  return (
    <ModalContainer
      isOpen={isOpen}
      closeModalHandler={onClose}
      testId="editPasswordModal"
      isHeader={false}
    >
      <Style.MainContainer>
        <CircleButton onClick={goBack}>
          <ArrowLeftIcon />
        </CircleButton>

        <Style.ModalHeader>
          <Style.Title>Enter recovery code</Style.Title>

          <Style.SubTitle>
            We send to your email verification code
          </Style.SubTitle>
        </Style.ModalHeader>

        <CircleButton onClick={onClose}>
          <CloseIcon className="w-6 h-6 text-dark-gray" />
        </CircleButton>
      </Style.MainContainer>

      <EnterRecoveryCode maxButtonWidth={392} />
    </ModalContainer>
  );
};
