import React, {
  FC, useEffect, useMemo, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  useGetOrganizationGroupsQuery
} from 'src/redux/openapi';
import {
  EmptyResultsMessage, Spinner
} from 'src/shared/components';
import {
  useCustomInfiniteScroll
} from 'src/shared/hooks';

import {
  GroupOrgCard
} from '../GroupOrgCard';

import * as Style from './GroupsTabContent.styles';

interface GroupsTabContentProps {
  searchQuery: string;
  organizationId: string;
}

export const GroupsTabContent: FC<GroupsTabContentProps> = ({
  organizationId,
  searchQuery,
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(
    () => {
      setCurrentPage(1);
    },
    [searchQuery]
  );

  const {
    data: groupsData,
    isLoading,
    isFetching,
    isError,
  } = useGetOrganizationGroupsQuery(
    {
      id: organizationId,
      query: searchQuery,
      limit: 10,
      page: currentPage,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    total: totalGroups, groups: currentGroups
  } = useMemo(
    () => groupsData?.data || {
      total: 0,
      groups: [],
    },
    [groupsData],
  );

  const {
    sentryRef,
    allItems: groups,
    displayLoader,
  } = useCustomInfiniteScroll({
    total: totalGroups,
    currentItems: currentGroups,
    isFetching,
    isLoading,
    isError,
    currentPage,
    setCurrentPage,
  });

  const {
    t
  } = useTranslation();

  if (isLoading) {
    return <Spinner size={24} />;
  }

  return (
    <Style.GroupsContainer>
      {!groups.length && (
        <EmptyResultsMessage results={t('common.groups').toLowerCase()} />
      )}

      {groups.map((group) => (
        <GroupOrgCard
          key={group.id}
          group={group}
        />
      ))}

      {displayLoader && (
        <div ref={sentryRef}>
          <Spinner size={24} />
        </div>
      )}
    </Style.GroupsContainer>
  );
};
