export enum GraphType {
  LINEAR = 'linear',
  BAR = 'bar',
}
export enum GraphBarName {
  OWN = 'own',
  TOTAL = 'total',
  GTOTAL = 'gTotal',
}

export interface Point {
  x: number;
  y: number;
}

export interface TimelineConfig {
  startDate: number;
  endDate: number;
  timeGap: number;
}

export interface TooltipPayloadType {
  color: string;
  dataKey: string;
  fill: string;
  name: string;
  unit: string;
  value: number;
  payload: {
    total:
    | number
    | {
      [x: string]: number;
    };
    votes: number;
    own?: {
      [x: string]: number;
    };
    groupsTotal?: {
      [x: string]: number;
    };
    groupsVotes?: number;
  };
}
