import {
  emptySplitApi as api
} from '../emptyApi';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiAdminOrganizations: build.query<
    GetApiAdminOrganizationsApiResponse,
    GetApiAdminOrganizationsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/admin/organizations`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
          filters: queryArg.filters,
        },
      }),
    }),
    postApiAdminOrganizations: build.mutation<
    PostApiAdminOrganizationsApiResponse,
    PostApiAdminOrganizationsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/admin/organizations`,
        method: 'POST',
        body: queryArg.createOrganizationBodySchema,
      }),
    }),
    getApiAdminOrganizationsRequests: build.query<
    GetApiAdminOrganizationsRequestsApiResponse,
    GetApiAdminOrganizationsRequestsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/admin/organizations/requests`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
          filters: queryArg.filters,
        },
      }),
    }),
    getApiAdminOrganizationsRequestsUnreadCount: build.query<
    GetApiAdminOrganizationsRequestsUnreadCountApiResponse,
    GetApiAdminOrganizationsRequestsUnreadCountApiArg
    >({
      query: () => ({
        url: `/api/admin/organizations/requests/unread/count`,
      }),
    }),
    patchApiAdminOrganizationsRequestsUnread: build.mutation<
    PatchApiAdminOrganizationsRequestsUnreadApiResponse,
    PatchApiAdminOrganizationsRequestsUnreadApiArg
    >({
      query: (queryArg) => ({
        url: `/api/admin/organizations/requests/unread`,
        method: 'PATCH',
        body: queryArg.updateGroupOrganizatinoRequestReadStatusBodySchema,
      }),
    }),
    getApiAdminOrganizationsRequestsById: build.query<
    GetApiAdminOrganizationsRequestsByIdApiResponse,
    GetApiAdminOrganizationsRequestsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/admin/organizations/requests/${queryArg.id}`,
      }),
    }),
    deleteApiAdminOrganizationsRequestsById: build.mutation<
    DeleteApiAdminOrganizationsRequestsByIdApiResponse,
    DeleteApiAdminOrganizationsRequestsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/admin/organizations/requests/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    getApiAdminOrganizationsById: build.query<
    GetApiAdminOrganizationsByIdApiResponse,
    GetApiAdminOrganizationsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/admin/organizations/${queryArg.id}`,
      }),
    }),
    putApiAdminOrganizationsById: build.mutation<
    PutApiAdminOrganizationsByIdApiResponse,
    PutApiAdminOrganizationsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/admin/organizations/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.adminUpdateOrganizationByIdBodySchema,
      }),
    }),
    putApiAdminGroupsById: build.mutation<
    PutApiAdminGroupsByIdApiResponse,
    PutApiAdminGroupsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/admin/groups/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.adminUpdateGroupByIdBodySchema,
      }),
    }),
    getApiAdminGroupsById: build.query<
    GetApiAdminGroupsByIdApiResponse,
    GetApiAdminGroupsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/admin/groups/${queryArg.id}`,
      }),
    }),
    patchApiAdminOrganizationsRequestsByIdAccept: build.mutation<
    PatchApiAdminOrganizationsRequestsByIdAcceptApiResponse,
    PatchApiAdminOrganizationsRequestsByIdAcceptApiArg
    >({
      query: (queryArg) => ({
        url: `/api/admin/organizations/requests/${queryArg.id}/accept`,
        method: 'PATCH',
        body: queryArg.acceptGroupToOrganizationBodySchema,
      }),
    }),
    patchApiAdminOrganizationsRequestsByIdDecline: build.mutation<
    PatchApiAdminOrganizationsRequestsByIdDeclineApiResponse,
    PatchApiAdminOrganizationsRequestsByIdDeclineApiArg
    >({
      query: (queryArg) => ({
        url: `/api/admin/organizations/requests/${queryArg.id}/decline`,
        method: 'PATCH',
      }),
    }),
    getApiAdminGroups: build.query<
    GetApiAdminGroupsApiResponse,
    GetApiAdminGroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/admin/groups`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
          query: queryArg.query,
        },
      }),
    }),
  }),
  overrideExisting: false,
});

export {
  injectedRtkApi as arqApiAdmin
};
export type GetApiAdminOrganizationsApiResponse =
  /** status 200 Default Response */ AdminOrgsResponseSchema;
export type GetApiAdminOrganizationsApiArg = {
  page?: number;
  limit?: number;
  filters?: {
    status?: 'ACCEPTED' | 'PENDING';
    query?: string;
  };
};
export type PostApiAdminOrganizationsApiResponse =
  /** status 201 Default Response */ CreateOrganizationResponseSchema;
export type PostApiAdminOrganizationsApiArg = {
  createOrganizationBodySchema: CreateOrganizationBodySchema;
};
export type GetApiAdminOrganizationsRequestsApiResponse =
  /** status 200 Default Response */ GroupOrganizationRequestsResponseSchema;
export type GetApiAdminOrganizationsRequestsApiArg = {
  page?: Page;
  limit?: Limit;
  filters?: {
    query?: string;
  };
};
export type GetApiAdminOrganizationsRequestsUnreadCountApiResponse =
  /** status 200 Default Response */ UnreadGroupOrganizationRequestCountResponseSchema;
export type GetApiAdminOrganizationsRequestsUnreadCountApiArg = void;
export type PatchApiAdminOrganizationsRequestsUnreadApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PatchApiAdminOrganizationsRequestsUnreadApiArg = {
  updateGroupOrganizatinoRequestReadStatusBodySchema: UpdateGroupOrganizatinoRequestReadStatusBodySchema;
};
export type GetApiAdminOrganizationsRequestsByIdApiResponse =
  /** status 200 Default Response */ GroupOrganizationRequestResponseSchema;
export type GetApiAdminOrganizationsRequestsByIdApiArg = {
  id: string;
};
export type DeleteApiAdminOrganizationsRequestsByIdApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type DeleteApiAdminOrganizationsRequestsByIdApiArg = {
  id: string;
};
export type GetApiAdminOrganizationsByIdApiResponse =
  /** status 200 Default Response */ AdminGetOrganizationByIdResponseSchema;
export type GetApiAdminOrganizationsByIdApiArg = {
  id: string;
};
export type PutApiAdminOrganizationsByIdApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PutApiAdminOrganizationsByIdApiArg = {
  id: string;
  adminUpdateOrganizationByIdBodySchema: AdminUpdateOrganizationByIdBodySchema;
};
export type PutApiAdminGroupsByIdApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PutApiAdminGroupsByIdApiArg = {
  id: string;
  adminUpdateGroupByIdBodySchema: AdminUpdateGroupByIdBodySchema;
};
export type GetApiAdminGroupsByIdApiResponse =
  /** status 200 Default Response */ AdminGetGroupByIdResponseSchema;
export type GetApiAdminGroupsByIdApiArg = {
  id: string;
};
export type PatchApiAdminOrganizationsRequestsByIdAcceptApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PatchApiAdminOrganizationsRequestsByIdAcceptApiArg = {
  id: string;
  acceptGroupToOrganizationBodySchema: AcceptGroupToOrganizationBodySchema;
};
export type PatchApiAdminOrganizationsRequestsByIdDeclineApiResponse =
  /** status 200 Default Response */ MessageResponseSchema;
export type PatchApiAdminOrganizationsRequestsByIdDeclineApiArg = {
  id: string;
};
export type GetApiAdminGroupsApiResponse =
  /** status 200 Default Response */ GetUnaffiliatedGroupsResponseSchema;
export type GetApiAdminGroupsApiArg = {
  page?: Page;
  limit?: Limit;
  query?: string;
};
export type AdminOrgsResponseSchema = {
  message: string;
  data: {
    total: number;
    organizations: {
      id: string;
      name: string;
      isSetUp: boolean;
      createdAt: string;
      status: 'ACCEPTED' | 'PENDING';
      groupsCount: number;
      membersCount: number;
      admins:
      | {
        id: string;
        name: string;
        displayName: string | null;
        role: 'SUPERADMIN' | 'ADMIN';
        email: string;
      }[]
      | null;
    }[];
  };
};
export type CreateOrganizationResponseSchema = {
  message: string;
  data: {
    organization: {
      name: string;
      id: string;
      status: 'ACCEPTED' | 'PENDING';
      createdAt: string;
      isSetUp: boolean;
    };
  };
};
export type CreateOrganizationBodySchema = {
  name: string;
  domain?: string;
  superadminEmail: string;
  adminEmails?: string[];
};
export type GroupOrganizationRequestsResponseSchema = {
  message: string;
  data: {
    groupRequests: {
      id: string;
      isRead: boolean;
      status: 'PENDING' | 'ACCEPTED' | 'REJECTED';
      createdAt: string;
      updatedAt: string;
      description: string;
      group: {
        id: string;
        users: {
          role: 'SUPERADMIN' | 'ADMIN';
          user: {
            displayName: string | null;
            name: string;
            email: string;
            id: string;
          };
        }[];
        name: string;
        _count: {
          users: number;
        };
      };
      organization: {
        name: string;
        id: string;
      } | null;
    }[];
    total: number;
  };
};
export type Page = number;
export type Limit = number;
export type UnreadGroupOrganizationRequestCountResponseSchema = {
  message: string;
  data: {
    total: number;
  };
};
export type MessageResponseSchema = {
  message: string;
};
export type UpdateGroupOrganizatinoRequestReadStatusBodySchema = {
  groupRequestIds: string[];
};
export type Items = {
  id: string;
  isRead: boolean;
  status: 'PENDING' | 'ACCEPTED' | 'REJECTED';
  createdAt: string;
  updatedAt: string;
  description: string;
  group: {
    id: string;
    users: {
      role: 'SUPERADMIN' | 'ADMIN';
      user: {
        displayName: string | null;
        name: string;
        email: string;
        id: string;
      };
    }[];
    name: string;
    _count: {
      users: number;
    };
  };
  organization: {
    name: string;
    id: string;
  } | null;
};
export type GroupOrganizationRequestResponseSchema = {
  message: string;
  data: {
    groupRequest: Items;
  };
};
export type AdminGetOrganizationByIdResponseSchema = {
  message: string;
  data: {
    organization: {
      id: string;
      name: string;
      domain: string | null;
      logoUrl: string | null;
      status: 'ACCEPTED' | 'PENDING';
      isSetUp: boolean;
      createdAt: string;
      updatedAt: string;
      users: {
        role: 'SUPERADMIN' | 'ADMIN';
        updatedAt: string;
        createdAt: string;
        email: string;
        user: {
          id: string;
          displayName: string | null;
          name: string;
          email: string;
        };
      }[];
    };
  };
};
export type AdminUpdateOrganizationByIdBodySchema = {
  name: string;
  domain?: string;
  superadminEmail: string;
  adminEmails?: string[];
};
export type AdminUpdateGroupByIdBodySchema = {
  name: string;
  location?: string | null;
  superadminEmail: string;
  adminEmails?: string[];
};
export type AdminGetGroupByIdResponseSchema = {
  message: string;
  data: {
    group: {
      id: string;
      name: string;
      location: string | null;
      status: 'ACTIVATED' | 'DEACTIVATED';
      createdAt: string;
      updatedAt: string;
      users: {
        role: 'SUPERADMIN' | 'ADMIN' | 'USER';
        updatedAt: string;
        createdAt: string;
        email: string;
        user: {
          id: string;
          displayName: string | null;
          name: string;
          email: string;
        };
      }[];
    } | null;
  };
};
export type Name = string;
export type Domain = string;
export type SuperadminEmail = string;
export type AdminEmails = string[];
export type AcceptGroupToOrganizationBodySchema = {
  organization?: {
    name?: Name;
    domain?: Domain;
    superadminEmail?: SuperadminEmail;
    adminEmails?: AdminEmails;
  };
};
export type GetUnaffiliatedGroupsResponseSchema = {
  message: string;
  data: {
    groups: {
      id: string;
      status: 'ACTIVATED' | 'DEACTIVATED';
      name: string;
      location: string | null;
      createdAt: string;
      updatedAt: string;
      memberCount: number;
      admins:
      | {
        id: string;
        email: string;
        role: 'SUPERADMIN' | 'ADMIN';
        displayName: string | null;
        name: string;
      }[]
      | null;
    }[];
    total: number;
  };
};
export const {
  useGetApiAdminOrganizationsQuery,
  usePostApiAdminOrganizationsMutation,
  useGetApiAdminOrganizationsRequestsQuery,
  useGetApiAdminOrganizationsRequestsUnreadCountQuery,
  usePatchApiAdminOrganizationsRequestsUnreadMutation,
  useGetApiAdminOrganizationsRequestsByIdQuery,
  useDeleteApiAdminOrganizationsRequestsByIdMutation,
  useGetApiAdminOrganizationsByIdQuery,
  usePutApiAdminOrganizationsByIdMutation,
  usePutApiAdminGroupsByIdMutation,
  useGetApiAdminGroupsByIdQuery,
  usePatchApiAdminOrganizationsRequestsByIdAcceptMutation,
  usePatchApiAdminOrganizationsRequestsByIdDeclineMutation,
  useGetApiAdminGroupsQuery,
} = injectedRtkApi;
