import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const CardContainer = styled.div<{ $isActive: boolean }>`
  ${tw`flex flex-col gap-2 p-4 hover:bg-alice-blue transition cursor-pointer`}

  ${({
    $isActive
  }) => $isActive && tw`bg-input-blue hover:bg-input-blue`}
`;

export const QuestionInfo = styled.div`
  ${tw`flex items-center justify-between gap-6`}
`;

export const QuestionInfoWrapper = styled.div<{ $gap: number }>`
  ${tw`flex items-center justify-between text-13`}

  ${({
    $gap
  }) => `gap: ${$gap}px`}
`;
