import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  ModalContainer,
  Avatar,
  Button,
  successfulToast,
  Spinner,
} from 'src/shared/components';
import {
  useDeleteQuestionDelegationsMutation,
  useGetQuestionDelegationsQuery,
} from 'src/redux/openapi';
import {
  showWarningFromServer
} from 'src/shared/utils';

import * as Style from './ManageDelegateModal.styles';

interface ManageDelegateProps {
  isOpen: boolean;
  closeModal: () => void;
  questionId: string;
  onRemove: () => void;
  onReassign: () => void;
}

export const ManageDelegate: FC<ManageDelegateProps> = ({
  isOpen,
  closeModal,
  questionId,
  onRemove,
  onReassign,
}) => {
  const {
    t
  } = useTranslation();

  const {
    isDelegateLoading, delegate
  } = useGetQuestionDelegationsQuery(
    {
      id: questionId,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !isOpen,
      selectFromResult: ({
        data, isLoading, isFetching
      }) => ({
        isDelegateLoading: isLoading || isFetching,
        delegate: data?.data.delegate || null,
      }),
    },
  );

  const {
    name, displayName, avatarUrl = null
  } = delegate || {};

  const delegateName = displayName || name || '';

  const [deleteDelegation, {
    isLoading: isDeleting
  }] = useDeleteQuestionDelegationsMutation();

  const handleDeleteDelegate = async () => {
    try {
      const response = await deleteDelegation({
        id: questionId,
      }).unwrap();

      successfulToast(response.message);

      onRemove();
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  const isButtonsDisabled = isDeleting || isDelegateLoading;

  return (
    <ModalContainer
      modalWidth={567}
      isDarkBackground
      closeModalHandler={closeModal}
      isOpen={isOpen}
      modalTitle={t('modal.manageDelegate')}
      isGap={false}
    >
      <Style.ManageDelegateContent>
        {isDelegateLoading ? (
          <Spinner size={32} />
        ) : (
          <>
            <Avatar
              src={avatarUrl}
              userName={delegateName}
              size={128}
            />

            <Style.ManageDelegateText>
              {t('modal.currentDelegate')}

              <Style.ManageDelegateSpan>
                {`: ${delegateName}`}
              </Style.ManageDelegateSpan>
            </Style.ManageDelegateText>
          </>
        )}
      </Style.ManageDelegateContent>

      <Style.ManageDelegateButtonsRow>
        <Button
          variant="big-blue"
          className="w-max text-white text-xl font-bold"
          onClick={onReassign}
          disabled={isButtonsDisabled}
        >
          {t('buttons.reassign')}
        </Button>

        <Button
          variant="big-black"
          className="w-max text-white text-xl font-bold"
          onClick={handleDeleteDelegate}
          disabled={isButtonsDisabled}
        >
          {t('buttons.remove')}

          {isDeleting && (
            <Spinner
              size={24}
              color="inherit"
            />
          )}
        </Button>
      </Style.ManageDelegateButtonsRow>
    </ModalContainer>
  );
};
