import React, {
  CSSProperties, FC
} from 'react';
import {
  useParams, useSearchParams
} from 'react-router-dom';
import {
  useForm
} from 'react-hook-form';
import z from 'zod';
import {
  zodResolver
} from '@hookform/resolvers/zod';
import {
  useTranslation
} from 'react-i18next';

import {
  ArrowForwardIcon
} from 'src/shared/icons';
import {
  commentID, questionId
} from 'src/shared/utils';
import {
  commentLengthValidation
} from 'src/shared/utils/validation';

import * as Style from './SendCommentBlock.styles';

interface SendCommentBlockProps {
  addReplyHandler?: (newComment: string, commentId: string) => Promise<void>;
  addNewCommentHandler?: (
    newComment: string,
    parentId: string,
  ) => Promise<void>;
  containerStyle?: CSSProperties;
}

interface CommentForm {
  comment: string;
}

const schema = z.object({
  comment: commentLengthValidation,
});

export const SendCommentBlock: FC<SendCommentBlockProps> = ({
  addReplyHandler,
  addNewCommentHandler,
  containerStyle,
}) => {
  const [searchParams] = useSearchParams();
  const params = useParams();

  const {
    t
  } = useTranslation();

  const commentIdToReply = searchParams.get(commentID);

  const idQuestion = searchParams.get(questionId);

  const questionIdToSearch = params.id || idQuestion;

  const {
    register, handleSubmit, reset
  } = useForm<CommentForm>({
    mode: 'all',
    defaultValues: {
      comment: '',
    },
    resolver: zodResolver(schema),
  });

  const onSubmit = handleSubmit((data) => {
    if (addReplyHandler && commentIdToReply) {
      addReplyHandler(
        data.comment,
        commentIdToReply
      );

      reset();
    } else if (addNewCommentHandler && questionIdToSearch) {
      addNewCommentHandler(
        data.comment,
        questionIdToSearch
      );

      reset();
    }
  });

  const handleEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  };

  return (
    <Style.MainContainer style={containerStyle}>
      <Style.InputWrapper>
        <div className="flex-1">
          <Style.InputBlock
            type="text"
            placeholder={`${
              commentIdToReply
                ? t('feed.replyToComment')
                : t('feed.enterYouComment')
            }...`}
            {...register('comment')}
            onKeyDown={handleEnterPress}
            autoComplete="off"
          />
        </div>

        <Style.SendButton
          type="submit"
          onClick={onSubmit}
        >
          <ArrowForwardIcon className="text-button-blue -rotate-90" />
        </Style.SendButton>
      </Style.InputWrapper>
    </Style.MainContainer>
  );
};
