import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const InviteTitle = styled.div`
  ${tw`text-17-26 font-bold text-black`}
`;

export const InviteEmailText = styled.span`
  ${tw`text-15-20 font-bold text-dark-gray`}
`;

export const TooltipInvited = styled.div`
  ${tw`absolute -top-1 left-1/2 -translate-x-1/2 -translate-y-full p-2 rounded-sm bg-forest-green text-center w-103px text-white text-xs leading-5 font-medium animate-tooltipShow`}
`;

export const TooltipInvitedAfter = styled.div`
  ${tw`absolute w-4 h-4 bg-forest-green rotate-45 bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2`}
`;

export const UnderlinedTextButton = styled.button`
  ${tw`text-base font-normal underline text-button-blue text-left w-max`}

  text-decoration-skip-ink: none;
`;

export const MainContainer = styled.div`
  ${tw`flex flex-col gap-4 mb-6 mt-3`}
`;

export const HeadContainer = styled.div`
  ${tw`flex gap-2`}
`;

export const LinkContainer = styled.div`
  ${tw`flex flex-col gap-2 mb-4`}
`;
