import React, {
  FC, useEffect, useRef, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  CommunitySection, SetsQuestionsSection
} from 'src/widgets';
import {
  YourFeed
} from 'src/entities/YourFeed';
import {
  Organization
} from 'src/redux/openapi';

import * as Style from './Organisation.styles';

interface CurrentOrganizationProps {
  organization: Organization;
}

export const CurrentOrganization: FC<CurrentOrganizationProps> = ({
  organization,
}) => {
  const {
    t
  } = useTranslation();

  const [sectionsShift, setSectionsShift] = useState(0);
  const sectionsContainerRef = useRef<HTMLDivElement>(null);

  const {
    id, role
  } = organization;

  useEffect(
    () => {
      if (sectionsContainerRef.current) {
        const shift = sectionsContainerRef.current?.offsetTop || 0;

        setSectionsShift(shift);
      }
    },
    [id]
  );

  return (
    <Style.SectionsContainer
      ref={sectionsContainerRef}
      $shift={sectionsShift}
    >
      <SetsQuestionsSection
        id={id}
        role={role}
      />

      <CommunitySection organization={organization} />

      <YourFeed
        isExpanded
        headerTitle={t('feed.organisationFeed')}
      />
    </Style.SectionsContainer>
  );
};
