import React, {
  PropsWithChildren,
  FC,
  ReactNode,
  useRef,
  CSSProperties,
  useEffect,
  HTMLProps,
} from 'react';
import {
  useLayer, useHover, Arrow, mergeRefs
} from 'react-laag';
import {
  AnimatePresence
} from 'framer-motion';
import clsx from 'clsx';

import * as Style from './Tooltip.styles';
import {
  TooltipPlacement, TooltipVariant
} from './types';
import {
  getStyles
} from './utils';

interface TooltipProps extends PropsWithChildren {
  message?: ReactNode;
  variant?: TooltipVariant;
  customShift?: number;
  auto?: boolean;
  placement?: TooltipPlacement;
  possiblePlacements?: TooltipPlacement[];
  arrowSize?: number;
  opacity?: number;
  hideOnScroll?: boolean;
  disabled?: boolean;
  pointerEvents?: CSSProperties['pointerEvents'];
  delayLeave?: number;
  maxWidth?: number;
  messageContainerProps?: HTMLProps<HTMLDivElement>;
  onHoverCallback?: (wasHovered: boolean) => void;
  childrenClassName?: string;
}

export const Tooltip: FC<TooltipProps> = ({
  children,
  childrenClassName,
  message = null,
  variant,
  customShift = 0,
  auto = true,
  placement = 'bottom-center',
  possiblePlacements,
  arrowSize = 8,
  opacity = 1,
  hideOnScroll = true,
  disabled = false,
  pointerEvents = 'auto',
  delayLeave = 300,
  maxWidth,
  messageContainerProps,
  onHoverCallback,
}) => {
  const [isOver, hoverProps, close] = useHover({
    delayEnter: 50,
    delayLeave,
    hideOnScroll,
  });

  const ref = useRef<HTMLDivElement>(null);

  const styles = getStyles(variant);

  const mouseLeaveHandler = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  ) => {
    const {
      relatedTarget
    } = event;

    if (
      !(
        relatedTarget instanceof Element && ref.current?.contains(relatedTarget)
      )
    ) {
      hoverProps.onMouseLeave(event);
    }
  };

  const {
    triggerProps, layerProps, arrowProps, renderLayer
  } = useLayer({
    isOpen: isOver && !disabled,
    placement,
    triggerOffset: 8 + customShift,
    auto,
    possiblePlacements,
    onParentClose: close,
  });

  useEffect(
    () => {
      if (onHoverCallback) {
        onHoverCallback(isOver);
      }
    },
    [isOver]
  );

  return (
    <>
      <div
        className={clsx(
          'tooltip-text-wrapper',
          childrenClassName
        )}
        {...triggerProps}
        {...hoverProps}
        onMouseLeave={mouseLeaveHandler}
      >
        {children}
      </div>

      {renderLayer(
        <AnimatePresence>
          {isOver && !disabled && (
            <Style.Container
              className="tooltip-box"
              initial={{
                opacity: 0,
                scale: 0.9,
              }}
              animate={{
                opacity,
                scale: 1,
              }}
              exit={{
                opacity: 0,
                scale: 0.9,
              }}
              transition={{
                duration: 0.1,
              }}
              {...layerProps}
              style={{
                ...styles,
                ...layerProps.style,
                pointerEvents,
              }}
              ref={mergeRefs(
                layerProps.ref,
                ref
              )}
              onMouseLeave={hoverProps.onMouseLeave}
              $maxWidth={maxWidth}
            >
              <div {...messageContainerProps}>{message}</div>

              <Arrow
                {...arrowProps}
                backgroundColor={styles.backgroundColor}
                borderColor={styles.borderColor}
                borderWidth={styles.borderWidth}
                size={arrowSize}
              />
            </Style.Container>
          )}
        </AnimatePresence>,
      )}
    </>
  );
};
