import React, {
  FC, PropsWithChildren, ReactNode, HTMLProps
} from 'react';

import {
  TimeSpansValues
} from 'src/redux/timeSpans';
import {
  getTimeRangeToDisplay
} from 'src/shared/utils';

import * as Style from './ActivityRow.styles';

interface ActivityTileProps extends HTMLProps<HTMLDivElement> {
  icon: ReactNode;
  amount?: string | number;
  timeRange?: keyof typeof TimeSpansValues | null;
}

export const ActivityTile: FC<ActivityTileProps> = ({
  icon,
  amount = 0,
  timeRange,
  ...props
}) => {
  const rangeToDisplay = getTimeRangeToDisplay(timeRange);

  return (
    <Style.TileContainer {...props}>
      {icon}

      <Style.TileContent>
        {amount}

        {rangeToDisplay && (
          <Style.MediumText>{`/ ${rangeToDisplay}`}</Style.MediumText>
        )}
      </Style.TileContent>
    </Style.TileContainer>
  );
};

export const ActivityRow: FC<PropsWithChildren> = ({
  children
}) => {
  return <Style.RowContainer>{children}</Style.RowContainer>;
};
