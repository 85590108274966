import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const InputContainer = styled.div`
  ${tw`w-12 relative text-17-26 font-bold text-gray7 text-center flex flex-col items-center justify-center`}
`;

export const Input = styled.input`
  ${tw`hidden`}
`;

export const Subtitle = styled.p`
  ${tw`font-medium text-11-19 text-dim-gray`}
`;
