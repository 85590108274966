import React, {
  FC,
  useState,
  ChangeEvent,
  useEffect,
  MutableRefObject,
  useRef,
} from 'react';
import {
  useSearchParams
} from 'react-router-dom';
import clsx from 'clsx';
import {
  useTranslation
} from 'react-i18next';

import {
  CircleButton
} from 'src/shared/components';
import {
  ArrowUpIcon, CloseIcon, SearchIcon
} from 'src/shared/icons';
import {
  commentSearch, questionId
} from 'src/shared/utils';

import * as Style from './CommunitySearch.styles';

const COMMENT_CLASS = 'comment-match';
const COMMENT_ACTIVE_CLASS = 'comment-match--active';

interface CommunitySearchProps {
  matches: number;
  onClose: () => void;
  commentsRef: MutableRefObject<HTMLDivElement | null>;
  comments: any;
}

export const CommunitySearch: FC<CommunitySearchProps> = ({
  matches,
  onClose,
  commentsRef,
  comments,
}) => {
  const {
    t
  } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const [matchIndex, setMatchIndex] = useState(1);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const searchQuery = searchParams.get(commentSearch) || '';
  const activeQuestionId = searchParams.get(questionId) || null;

  const jumpToMatch = (indexOffset: 1 | -1) => {
    setMatchIndex((prev) => prev + indexOffset);

    const allMatches = commentsRef.current
      ? Array.from(commentsRef.current.querySelectorAll(`.${COMMENT_CLASS}`))
      : [];

    const currentActive = commentsRef.current?.querySelector(
      `.${COMMENT_ACTIVE_CLASS}`,
    );

    if (currentActive) {
      const currentIndex = allMatches.indexOf(currentActive);

      const nextIndex = currentIndex + indexOffset;
      const nextElement = allMatches[nextIndex];

      currentActive.classList.remove(COMMENT_ACTIVE_CLASS);

      if (nextElement) {
        nextElement.classList.add(COMMENT_ACTIVE_CLASS);

        nextElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  };

  const jumpToNextMatch = () => jumpToMatch(1);

  const jumpToPrevMatch = () => jumpToMatch(-1);

  const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    searchParams.set(
      commentSearch,
      event.target.value
    );

    setSearchParams(searchParams);
  };

  const clearSearch = () => {
    searchParams.delete(commentSearch);
    setSearchParams(searchParams);
  };

  useEffect(
    () => {
      const currentActive = commentsRef.current?.querySelector(
        `.${COMMENT_ACTIVE_CLASS}`,
      );

      if (currentActive) {
        currentActive.classList.remove(COMMENT_ACTIVE_CLASS);
      }

      const firstMatch = commentsRef.current?.querySelector(`.${COMMENT_CLASS}`);

      if (firstMatch) {
        firstMatch.classList.add(COMMENT_ACTIVE_CLASS);

        firstMatch.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }

      setMatchIndex(1);
    },
    [searchParams, comments]
  );

  useEffect(
    () => {
      if (inputRef.current) {
        inputRef.current?.focus();
      }

      return () => {
        const currentActive = commentsRef.current?.querySelector(
          `.${COMMENT_ACTIVE_CLASS}`,
        );

        if (currentActive) {
          currentActive.classList.remove(COMMENT_ACTIVE_CLASS);
        }

        clearSearch();
      };
    },
    []
  );

  useEffect(
    () => {
      clearSearch();
    },
    [activeQuestionId]
  );

  const isPrevButtonDisabled = matchIndex === 1 || matches === 0;
  const isNextButtonDisabled = matchIndex === matches || matches === 0;

  return (
    <Style.MainContainer>
      <Style.MatchesWrapper>
        <button
          type="button"
          onClick={jumpToPrevMatch}
          disabled={isPrevButtonDisabled}
        >
          <ArrowUpIcon
            className={clsx(
              'text-gray7 w-6 h-6 transition',
              {
                'text-grey-dark-40': isPrevButtonDisabled,
              }
            )}
          />
        </button>

        <button
          type="button"
          onClick={jumpToNextMatch}
          disabled={isNextButtonDisabled}
        >
          <ArrowUpIcon
            className={clsx(
              'text-gray7 w-6 h-6 rotate-180 transition',
              {
                'text-grey-dark-40': isNextButtonDisabled,
              }
            )}
          />
        </button>

        <Style.MatchesAmount>
          {!!matches && `${matchIndex}/`}

          {`${matches} ${t('common.matches')}`}
        </Style.MatchesAmount>
      </Style.MatchesWrapper>

      <Style.InputLabel>
        <SearchIcon />

        <Style.Input
          placeholder={t('common.search')}
          value={searchQuery}
          onChange={onSearch}
          ref={inputRef}
        />

        <button
          type="button"
          onClick={clearSearch}
          className="text-gulf-blue"
        >
          <CloseIcon />
        </button>
      </Style.InputLabel>

      <CircleButton onClick={onClose}>
        <CloseIcon />
      </CircleButton>
    </Style.MainContainer>
  );
};
