import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const CommentContainer = styled.article<{
  $isNew?: boolean;
  $isNotExtended?: boolean;
  $withDivider: boolean;
}>`
  ${tw`flex py-4 gap-2 bg-white`}

  ${({
    $isNew, $isNotExtended
  }) => $isNotExtended && $isNew && tw`animate-newMessage`}

  ${({
    $isNotExtended, $withDivider
  }) => $isNotExtended && $withDivider && tw`border-b border-b-gray-whisper`}

  ${({
    $isNotExtended
  }) => $isNotExtended && tw`px-6`}
`;

export const ContentContainer = styled.div`
  ${tw`grow`}
`;

export const AvatarContainer = styled.div`
  ${tw`shrink-0`}
`;

export const Header = styled.div`
  ${tw`flex justify-between items-center mb-1`}
`;

export const ActionButtons = styled.div<{ $isCommunity?: boolean }>`
  ${tw`flex items-center`}

  ${({
    $isCommunity
  }) => ($isCommunity ? tw`gap-3` : tw`gap-2`)}
`;

export const MoreButton = styled.div<{ $isCommunity?: boolean }>`
  ${tw`relative h-6`}

  ${({
    $isCommunity
  }) => ($isCommunity ? tw`gap-3` : tw`gap-2`)}
`;

export const Name = styled.p`
  ${tw`font-semibold text-gray7`}
`;

export const Question = styled.p`
  ${tw`text-xs text-dim-gray leading-5 font-medium mb-2`}
`;
export const QuestionTitle = styled.span`
  ${tw`hover:(text-dim-gray bg-dashed-hover bg-bottom bg-repeat-x bg-[length:8px_1px])
  active:(text-gray7 bg-dashed-active bg-bottom bg-repeat-x bg-[length:8px_1px])
  transition-colors`}
`;

export const CommentContent = styled.p`
  ${tw`font-medium text-gray7 text-13-21 mb-2`}

  ${`overflow-wrap: anywhere;`}
`;

export const CenteredContainer = styled.div<{ $gap?: number }>`
  ${tw`flex justify-between items-center`}

  ${({
    $gap
  }) => `gap: ${$gap}px`}
`;

export const CreatedAtText = styled.div<{ $isToday?: boolean }>`
  ${tw`text-dim-gray font-medium`}

  ${({
    $isToday
  }) => ($isToday ? tw`text-15-20` : tw`text-11-19`)}
`;

export const CreatedTodayText = styled.div`
  ${tw`text-dim-gray text-15-20 font-medium`}
`;

export const ExpandedContainer = styled.div`
  ${tw`pt-4 flex flex-col`}
`;

export const ButtonContent = styled.div`
  ${tw`flex justify-center items-center gap-1 text-button-blue font-semibold`}
`;

export const ForecastOption = styled.div`
  ${tw`text-xs font-medium text-dim-gray mb-1`}
`;
export const ForecastContainer = styled.div`
  ${tw`p-3 flex flex-col gap-3 rounded-10px border border-gray90 mb-2`}
`;

export const ForecastDataContainer = styled.div`
  ${tw`flex justify-between`}
`;

export const SpanForecastData = styled.span<{ $isBlack?: boolean }>`
  ${tw`text-13-21 font-bold`}

  ${({
    $isBlack
  }) => ($isBlack ? tw`text-gray7` : tw`text-dim-gray`)}
`;

export const CommendsContainer = styled.div`
  ${tw`flex gap-1 p-2 rounded-md shadow-menu-shadow`}
`;

export const CommendButton = styled.button<{ $isActive?: boolean }>`
  ${tw`p-2 transition rounded`}

  ${({
    $isActive
  }) => ($isActive
    ? tw`bg-alice-blue text-button-blue`
    : tw`text-dim-gray hover:(bg-cultured text-dark-gray)`)}
`;
