import clsx from 'clsx';
import React, {
  FC, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import ReactSelect from 'react-select';
import {
  useParams
} from 'react-router-dom';

import {
  ModalContainer,
  DropdownIndicator,
  Button,
  successfulToast,
  Spinner,
  warningToast,
} from 'src/shared/components';
import {
  useDeleteOrgInactiveUsersMutation,
  useGetOrgUsersInactiveCountQuery,
} from 'src/redux/openapi';
import {
  showWarningFromServer
} from 'src/shared/utils';

import {
  getSelectOptions, SelectOption
} from './utils';
import * as Style from './RemoveInactiveUsersModal.styles';

interface RemoveInactiveUsersModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

type Option = {
  value: SelectOption;
  label: string;
};

export const RemoveInactiveUsersModal: FC<RemoveInactiveUsersModalProps> = ({
  isOpen,
  handleClose,
}) => {
  const selectOptions = getSelectOptions();

  const [removeOption, setRemoveOption] = useState<Option>(selectOptions[0]);

  const {
    t
  } = useTranslation();

  const params = useParams();

  const {
    organizationId = ''
  } = params;

  const {
    count
  } = useGetOrgUsersInactiveCountQuery(
    {
      id: organizationId,
      oldInactiveOnly: removeOption.value === SelectOption.MoreThanSixWeeks,
    },
    {
      skip: !organizationId || !isOpen,
      selectFromResult: ({
        data
      }) => ({
        count: data?.data.count || 0,
      }),
    },
  );

  const [removeOrgInactiveUsers, {
    isLoading
  }] = useDeleteOrgInactiveUsersMutation();

  const onRemove = async () => {
    if (!organizationId) {
      return;
    }

    if (!count) {
      warningToast(t('group.noUsersToRemove'));

      return;
    }

    try {
      const response = await removeOrgInactiveUsers({
        id: organizationId,
        oldInactiveOnly: removeOption.value === SelectOption.MoreThanSixWeeks,
      }).unwrap();

      successfulToast(response.message);
      handleClose();
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  return (
    <ModalContainer
      isOpen={isOpen}
      closeModalHandler={handleClose}
      modalTitle={t('group.removeInactiveUsers')}
    >
      <Style.SelectLabel>
        <Style.SelectLabelText>
          {t('common.removePer')}

          <Style.Asterisk>*</Style.Asterisk>
        </Style.SelectLabelText>

        <ReactSelect
          options={selectOptions}
          defaultValue={selectOptions[0]}
          value={removeOption}
          menuPortalTarget={document.body}
          onChange={(value) => setRemoveOption(value as Option)}
          isSearchable={false}
          components={{
            DropdownIndicator,
          }}
          styles={{
            menuPortal: (base) => ({
              ...base,
              zIndex: 9999,
            }),
          }}
          classNames={{
            control: ({
              menuIsOpen
            }) => clsx(
              `
                px-4 py-3 border outline-0 !rounded-md
                text-15-20 font-medium !shadow-none
              `,
              {
                '!border-button-blue !text-button-blue': menuIsOpen,
                '!text-dark-gray !border-gray90': !menuIsOpen,
              },
            ),
            singleValue: ({
              selectProps: {
                menuIsOpen
              }
            }) => clsx({
              '!text-button-blue': menuIsOpen,
              '!text-dark-gray': !menuIsOpen,
            }),
            valueContainer: () => `!px-0 !h-6`,
            indicatorSeparator: () => `hidden`,
            indicatorsContainer: () => `!h-6`,
            option: ({
              isSelected
            }) => clsx(
              `
              text-17-26 font-medium mb-2 last:mb-0 p-2 rounded hover:!bg-cultured cursor-pointer
            `,
              {
                '!bg-alice-blue !text-button-blue': isSelected,
                '!text-dark-gray !bg-white': !isSelected,
              },
            ),
            menu: () => `p-2 !shadow-menu-shadow !border-0 rounded !mt-1 !z-10 bg-white`,
            menuList: () => 'bg-white',
            menuPortal: () => `z-9999`,
          }}
        />
      </Style.SelectLabel>

      <Style.ModalFooter>
        <Style.RemoveText>
          {`${t('buttons.remove')}: `}

          <Style.RemoveCount>
            {`${count} ${t('common.users').toLowerCase()}`}
          </Style.RemoveCount>
        </Style.RemoveText>

        <Style.ButtonsContainer>
          <Button
            variant="big-black"
            onClick={handleClose}
            className="text-xl font-bold"
          >
            {t('buttons.cancel')}
          </Button>

          <Button
            variant="big-red"
            onClick={onRemove}
            className="text-xl font-bold"
          >
            {t('buttons.remove')}

            {isLoading && (
              <Spinner
                size={24}
                color="white"
              />
            )}
          </Button>
        </Style.ButtonsContainer>
      </Style.ModalFooter>
    </ModalContainer>
  );
};
