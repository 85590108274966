import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const ContentWrapper = styled.div`
  ${tw`flex flex-col gap-4`}
`;

export const Footer = styled.div`
  ${tw`border-t border-gray94 pt-4 flex justify-end gap-4`}
`;
