import React, {
  FC, useState
} from 'react';
import {
  useNavigate
} from 'react-router-dom';
import {
  useTranslation
} from 'react-i18next';

import {
  useTypedSelector
} from 'src/redux';
import {
  userSelectors
} from 'src/redux/user';
import {
  Avatar, Divider
} from 'src/shared/components';
import {
  ArrowLeftIcon, LogOutIcon
} from 'src/shared/icons';
import {
  ROUTE
} from 'src/shared/utils';
import {
  ActivityTabContent,
  BadgesTabContent,
  ChangeLanguageBlock,
  ScoresTabContent,
  Tabs,
  TABS_MAP,
} from 'src/widgets/MyProfileMenu';
import {
  useSignOut
} from 'src/shared/hooks';

import * as Style from './ProfilePage.style';

export const ProfilePage: FC = () => {
  const navigate = useNavigate();

  const {
    t
  } = useTranslation();

  const {
    avatarUrl, displayName, name, description
  } = useTypedSelector(
    userSelectors.user,
  );

  const userName = displayName || name;

  const {
    isLoading, signOut
  } = useSignOut();

  const [summary, setSummary] = useState({
    total: 0,
  });

  const [activeTab, setActiveTab] = useState(Tabs.Activity);
  const [isActiveModal, setIsActiveModal] = useState(false);

  return (
    <Style.Container $isActive={isActiveModal}>
      <Style.HeaderContainer>
        <Style.Button
          type="button"
          onClick={() => navigate(ROUTE.ROOT)}
        >
          <ArrowLeftIcon />
        </Style.Button>

        <ChangeLanguageBlock />
      </Style.HeaderContainer>

      <Style.UserContainer>
        <Style.UserInfoContainer>
          <Avatar
            src={avatarUrl}
            userName={userName}
            size={56}
          />

          <Style.UserNameContainer>
            <Style.UserName>{userName}</Style.UserName>

            <Style.UserDescription>{description}</Style.UserDescription>
          </Style.UserNameContainer>
        </Style.UserInfoContainer>

        <Style.Button
          type="button"
          onClick={signOut}
          disabled={isLoading}
        >
          <LogOutIcon className="text-dark-gray" />
        </Style.Button>
      </Style.UserContainer>

      <Divider className="mb-4" />

      <Style.SideWrapper>
        <Style.SummaryContainer>
          <Style.SummaryTitle>
            {t('profile.summaryPerformance')}
          </Style.SummaryTitle>

          <Style.SummaryNumber>{summary.total}</Style.SummaryNumber>
        </Style.SummaryContainer>
      </Style.SideWrapper>

      <Style.Tabs>
        {TABS_MAP.map((tab) => (
          <Style.Tab
            key={tab}
            type="button"
            onClick={() => setActiveTab(tab)}
            $isActive={tab === activeTab}
          >
            {t(`profile.${tab}`)}
          </Style.Tab>
        ))}
      </Style.Tabs>

      <Style.TabContentWrapper className="scroll-hidden">
        {activeTab === Tabs.Activity && (
          <ActivityTabContent
            onSummaryChange={setSummary}
            onModalToggle={setIsActiveModal}
          />
        )}

        {activeTab === Tabs.Scores && (
          <ScoresTabContent onModalToggle={setIsActiveModal} />
        )}

        {activeTab === Tabs.Badges && <BadgesTabContent />}
      </Style.TabContentWrapper>
    </Style.Container>
  );
};
