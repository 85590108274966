import React, {
  FC
} from 'react';
import ReactSelect from 'react-select';
import clsx from 'clsx';

import {
  Pagination, DropdownIndicator
} from 'src/shared/components';

import {
  SelectOption
} from '../../types';

import * as Style from './TableFooter.styles';

const selectOptionsMap = [20, 30, 40, 50];

export const selectOptions = selectOptionsMap.map((option) => ({
  value: option,
  label: option,
}));

interface TableFooterProps {
  totalCount: number;
  pageSize: SelectOption;
  currentPage: number;
  onPageChange: (page: number) => void;
  isOrganization: boolean;
  onPageSizeChange: (value: SelectOption) => void;
}

export const TableFooter: FC<TableFooterProps> = ({
  totalCount,
  pageSize,
  currentPage,
  onPageChange,
  isOrganization,
  onPageSizeChange,
}) => {
  return (
    <Style.TableFooter>
      <Pagination
        totalCount={totalCount}
        pageSize={pageSize.value}
        currentPage={currentPage}
        pageRange={12}
        pageMargin={1}
        onPageChange={onPageChange}
      />

      <Style.PageSelectorContainer>
        {`${isOrganization ? 'Organisations' : 'Groups'} per page`}

        <ReactSelect
          options={selectOptions}
          defaultValue={selectOptions[0]}
          value={pageSize}
          onChange={(value) => onPageSizeChange(value as SelectOption)}
          menuPlacement="top"
          isSearchable={false}
          components={{
            DropdownIndicator,
          }}
          classNames={{
            control: () => `
                px-2 py-1 border outline-0 !rounded-md
                text-15-20 font-medium
                !shadow-none !border-gray90 !h-8 !min-h-8 !w-20
              `,
            singleValue: () => `!text-dim-gray`,
            valueContainer: () => `!px-0 !h-6`,
            indicatorSeparator: () => `hidden`,
            indicatorsContainer: () => `!h-6`,
            option: ({
              isSelected
            }) => clsx(
              `
                  text-17-26 font-medium mb-2 last:mb-0 p-2 rounded hover:!bg-cultured cursor-pointer
                `,
              {
                '!bg-alice-blue !text-button-blue': isSelected,
                '!text-dark-gray !bg-white': !isSelected,
              },
            ),
            menu: () => `p-2 !shadow-menu-shadow !border-0 rounded !mt-1 !z-10`,
            menuList: () => '!max-h-min',
          }}
        />
      </Style.PageSelectorContainer>
    </Style.TableFooter>
  );
};
