import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const ModalTitle = styled.div`
  ${tw`text-34-43 font-bold text-gray7 text-center`}
`;

export const ModalContainer = styled.div<{ $isAdminView?: boolean }>`
  ${tw`flex flex-col`}

  ${({
    $isAdminView
  }) => ($isAdminView ? tw`gap-4` : tw`gap-6`)}
`;

export const ModalText = styled.div`
  ${tw`text-17-26 font-medium text-dark-gray text-center`}
`;
export const ModalTextLeave = styled.div`
  ${tw`text-13-21 font-medium text-dark-gray text-left`}
`;

export const ButtonContainer = styled.div<{ $isAdminView?: boolean }>`
  ${tw`mt-6 flex gap-4`}

  ${({
    $isAdminView
  }) => $isAdminView && tw`px-1`}
`;

export const CancelText = styled.span`
  ${tw`text-xl font-bold`}
`;

export const MemberRowContainer = styled.div`
  ${tw`flex p-2 gap-4 rounded border border-input-blue w-410px mx-auto`}
`;

export const MemberData = styled.div`
  ${tw`flex-1 text-left`}
`;

export const MemberGroup = styled.div`
  ${tw`text-xs font-medium leading-5 text-dim-gray mb-0.5`}
`;

export const MemberName = styled.div<{ $isSelected?: boolean }>`
  ${tw`flex-1 text-gray7 self-center`}

  ${({
    $isSelected
  }) => ($isSelected ? tw`font-bold text-17-26` : tw`font-medium text-base`)}
`;

export const MemberInfo = styled.div`
  ${tw`flex gap-3 flex-1 items-center justify-center`}
`;

export const PortalContainer = styled.div`
  ${tw`bg-white w-410px fixed top-1/2 left-1/2 -translate-x-1/2 translate-y-50px z-9999 px-2 py-4 rounded border border-input-blue shadow-menu-shadow animate-modalShow`}
`;

export const PortalMemberList = styled.div`
  ${tw`overflow-y-scroll w-full`}

  &::-webkit-scrollbar {
    width: 4px;
  }
`;
export const PortalMember = styled.div<{ $isSelected?: boolean }>`
  ${tw`px-1 rounded h-46px flex my-auto mb-3 last:mb-0`}

  ${({
    $isSelected
  }) => ($isSelected ? tw`bg-alice-blue` : tw`bg-white hover:bg-white-smoke`)}
`;

export const PortalSearch = styled.div`
  ${tw`mb-4 flex px-4 py-2.5 gap-4 rounded-56px border border-gray90 bg-white`}
`;

export const PortalSearchInput = styled.input`
  ${tw`flex-1 outline-none`}
`;

export const EmptyMessage = styled.p`
  ${tw`font-medium text-17-26 text-grey-dark-40 text-center`}
`;

export const EmptyBlock = styled.div`
  ${tw`w-6`}
`;
