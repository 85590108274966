import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MainContainer = styled.div`
  ${tw`flex flex-col gap-6 px-6 overflow-auto`}
`;

export const StretchedRow = styled.div`
  ${tw`flex justify-between items-center gap-3`}
`;

export const Title = styled.div`
  ${tw`font-bold text-22-30 text-gray7`}
`;

export const SwitcherContainer = styled.div`
  ${tw`font-bold text-13-21 text-dim-gray flex items-center gap-2`}
`;

export const ReverifyButton = styled.button`
  ${tw`flex items-center gap-1 font-medium text-base text-gray7 transition-all`}
`;

export const SendButton = styled.button`
  ${tw`flex gap-1 items-center whitespace-nowrap text-center font-bold bg-gray7 text-15-20 text-white px-4 py-3 rounded-100px disabled:bg-light-grey`}
`;

export const TooltipMessageWrapper = styled.div`
  ${tw`text-center`}
`;

export const ButtonsContainer = styled.div`
  ${tw`flex gap-3 justify-end py-4 px-6 border-t border-t-gray94 mt-auto`}
`;
