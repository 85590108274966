import React, {
  FC
} from 'react';
import {
  useForm, Controller
} from 'react-hook-form';
import {
  useTranslation
} from 'react-i18next';

import {
  CheckMark,
  ModalContainer,
  Spinner,
  successfulToast,
} from 'src/shared/components';
import {
  ArrowForwardIcon
} from 'src/shared/icons';
import {
  usePostApiUsersReportsMutation
} from 'src/redux/openapi';
import {
  showWarningFromServer, themeColors
} from 'src/shared/utils';

import * as Style from './ReportUserModal.styles';
import {
  CHECKBOX_DATA_MAP
} from './utils';

enum FormField {
  ReasonCheckbox = 'reasonCheckbox',
  ReasonInput = 'reasonInput',
}

interface ReportUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  memberName: string;
  userId: string;
}

export const ReportUserModal: FC<ReportUserModalProps> = ({
  onClose,
  memberName,
  isOpen,
  userId,
}) => {
  const {
    register, handleSubmit, reset, control
  } = useForm({
    defaultValues: {
      [FormField.ReasonInput]: '',
      [FormField.ReasonCheckbox]: CHECKBOX_DATA_MAP[0].value,
    },
  });

  const {
    t
  } = useTranslation();

  const handleClose = () => {
    reset();
    onClose();
  };

  const [reportUser, {
    isLoading
  }] = usePostApiUsersReportsMutation();

  const onSubmit = handleSubmit(async (data) => {
    try {
      const response = await reportUser({
        reportUserBodySchema: {
          option: data.reasonCheckbox,
          comment: data.reasonInput,
          userId,
        },
      }).unwrap();

      successfulToast(response.message);
      handleClose();
    } catch (error) {
      showWarningFromServer(error);
    }
  });

  return (
    <ModalContainer
      isOpen={isOpen}
      closeModalHandler={handleClose}
      isHeader={false}
      modalWidth={529}
    >
      <Style.ModalHeader>
        <Style.Title>{`${t('modal.reportThe')} ${memberName}`}</Style.Title>

        <Style.Subtitle>{t('modal.chooseOptionFillReasonArea')}</Style.Subtitle>
      </Style.ModalHeader>

      <Style.FormContainer onSubmit={onSubmit}>
        <Style.CheckboxesContainer>
          {CHECKBOX_DATA_MAP.map(
            ({
              value, label, description, icon: Icon
            }) => (
              <Style.Label key={value}>
                <Style.IconContainer>
                  <Icon className="w-6 h-6 text-gray7" />
                </Style.IconContainer>

                <Style.ReasonDescription>
                  <Style.ReasonTitle>{t(`modal.${label}`)}</Style.ReasonTitle>

                  <Style.ReasonSubtitle>
                    {t(`modal.${description}`)}
                  </Style.ReasonSubtitle>
                </Style.ReasonDescription>

                <Controller
                  name={FormField.ReasonCheckbox}
                  control={control}
                  render={({
                    field
                  }) => {
                    const isChecked = field.value === value;

                    return (
                      <button
                        type="button"
                        onClick={() => field.onChange(value)}
                      >
                        <CheckMark isChecked={isChecked} />
                      </button>
                    );
                  }}
                />
              </Style.Label>
            ),
          )}
        </Style.CheckboxesContainer>

        <Style.ReasonInputContainer>
          <Style.ReasonInput
            {...register(FormField.ReasonInput)}
            placeholder={t('modal.describeReason')}
            autoComplete="off"
          />

          <Style.SubmitButton type="submit">
            {isLoading ? (
              <Spinner
                color={themeColors['button-blue']}
                size={24}
              />
            ) : (
              <ArrowForwardIcon className="w-6 h-6" />
            )}
          </Style.SubmitButton>
        </Style.ReasonInputContainer>
      </Style.FormContainer>
    </ModalContainer>
  );
};
