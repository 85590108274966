import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import * as Style from './EmptyResultInfo.style';

export const EmptyResultInfo: FC = () => {
  const {
    t
  } = useTranslation();

  return (
    <Style.EmptyContainer>
      <Style.TextTitle>
        {t('calibration.startFirstCalibration')}
      </Style.TextTitle>

      <Style.TextSubTitle>
        {t('calibration.hereYouWillSeeResults')}
      </Style.TextSubTitle>
    </Style.EmptyContainer>
  );
};
