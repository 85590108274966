import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const InputsRow = styled.div`
  ${tw`flex gap-4`}
`;

export const InputContainer = styled.div`
  ${tw`grow flex-1`}
`;

export const ContentContainer = styled.form`
  ${tw`flex flex-col gap-8`}
`;

export const SetAdminsContainer = styled.div`
  ${tw`flex flex-col gap-4`}
`;

export const SetAdminsTitle = styled.div`
  ${tw`text-xl font-bold text-gray7`}
`;

export const SetAdminsSubtitle = styled.div`
  ${tw`text-15-20 font-medium text-dim-gray`}
`;

export const SetAdminsHeader = styled.div`
  ${tw`flex flex-col gap-1`}
`;

export const ButtonsContainer = styled.div`
  ${tw`flex justify-between`}
`;

export const SpinnerContainer = styled.div`
  ${tw`min-h-410px flex items-center`}
`;
