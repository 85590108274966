import React, {
  FC, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  RemoveInactiveUsersModal
} from 'src/features/Modals/ui/RemoveInactiveUsersModal';
import {
  MembersStatusCount
} from 'src/redux/openapi';

import * as Style from './StatusCount.styles';
import {
  getStatusMap
} from './utils';
import {
  Menu, ProgressBar
} from './ui';

interface StatusCountProps {
  membersStatusCount: MembersStatusCount;
}

export const StatusCount: FC<StatusCountProps> = ({
  membersStatusCount
}) => {
  const [isRemoveModalOpen, setIseRemoveModalOpen] = useState(false);

  const {
    t
  } = useTranslation();

  const {
    count, lastVerificationAt
  } = membersStatusCount;

  const {
    inactive, registered, active, invited, removed
  } = count;

  const total = registered + active + invited + removed;

  const statusConfig = getStatusMap(
    count,
    total
  );

  const toggleRemoveModal = () => setIseRemoveModalOpen((prev) => !prev);

  if (!statusConfig) {
    return null;
  }

  return (
    <>
      <Style.MainContainer>
        <Style.TopContentContainer>
          <Style.Head>
            <Style.Title>{t('organisation.statusCount')}</Style.Title>

            {(lastVerificationAt || !!inactive) && (
              <Menu
                lastVerification={lastVerificationAt}
                onRemoveClick={toggleRemoveModal}
                isInactive={!!inactive}
              />
            )}
          </Style.Head>

          <Style.StatusDescriptionContainer>
            {statusConfig.map(({
              label, color, count: statusCount
            }) => (
              <Style.StatusDescription key={label}>
                <Style.StatusIndicator $color={color} />

                <p>{label}</p>

                <Style.StatusNumber>{statusCount}</Style.StatusNumber>
              </Style.StatusDescription>
            ))}
          </Style.StatusDescriptionContainer>

          {!!total && <ProgressBar statusConfig={statusConfig} />}
        </Style.TopContentContainer>

        <Style.BottomContentContainer>
          {`${t('organisation.inactiveUsers')}:`}

          <Style.Title>
            &nbsp;
            {inactive}
          </Style.Title>
        </Style.BottomContentContainer>
      </Style.MainContainer>

      <RemoveInactiveUsersModal
        isOpen={isRemoveModalOpen}
        handleClose={toggleRemoveModal}
      />
    </>
  );
};
