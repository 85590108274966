import {
  ClockIcon, MessageIcon, ThumbUpIcon
} from 'src/shared/icons';

import {
  FilterDateType, SortBy
} from './types';

export const sortMethods = [
  {
    title: SortBy.MOST_DISCUSSION,
    icon: MessageIcon,
  },
  {
    title: SortBy.MOST_LIKED,
    icon: ThumbUpIcon,
  },
  {
    title: SortBy.CHRONOLOGICAL,
    icon: ClockIcon,
  },
];

export const filterDates = [
  FilterDateType.IN_24_HOURS,
  FilterDateType.IN_48_HOURS,
  FilterDateType.WEEK,
  FilterDateType.ALL_TIME,
];
