import {
  useEffect, useRef, RefObject, MouseEvent
} from 'react';
import {
  useLocation, useNavigate
} from 'react-router-dom';

interface UseScrollToAnchorArgs<T> {
  items: T[];
  hashKey: keyof T;
  containerRef: RefObject<HTMLDivElement>;
}

export const useScrollToAnchor = <T>({
  items,
  hashKey,
  containerRef,
}: UseScrollToAnchorArgs<T>) => {
  const sectionRefs = useRef<(HTMLDivElement | null)[]>(Array(items.length));

  const {
    hash
  } = useLocation();

  const navigate = useNavigate();

  const scrollToElement = (newHash: string) => {
    const refElement = sectionRefs.current.find(
      (el) => el?.id === newHash.slice(0),
    );

    const top = refElement?.offsetTop;

    if (containerRef.current) {
      containerRef.current.scrollTo({
        top,
        behavior: 'smooth',
      });

      if (newHash !== hash) {
        navigate(
          newHash,
          {
            replace: true,
          }
        );
      }
    }
  };

  const handleScroll = () => {
    const {
      offsetTop: scrollTop = 0, offsetHeight = 0
    } = containerRef?.current || {};

    sectionRefs.current.forEach((ref, index) => {
      if (ref) {
        const {
          y, height
        } = ref.getBoundingClientRect();

        if (y + height > scrollTop && y <= offsetHeight / 2) {
          const newHash = items[index][hashKey]
            ? `#${items[index][hashKey]}`
            : '';

          if (newHash !== hash) {
            navigate(
              newHash,
              {
                replace: true,
              }
            );
          }
        }
      }
    });
  };

  useEffect(
    () => {
      if (hash) {
        scrollToElement(hash);
      }

      containerRef?.current?.addEventListener(
        'scrollend',
        handleScroll
      );

      return () => {
        containerRef?.current?.removeEventListener(
          'scrollend',
          handleScroll
        );
      };
    },
    []
  );

  const onLinkClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const newHash = event.currentTarget.hash;

    if (newHash) {
      scrollToElement(newHash);
    }
  };

  return {
    sectionRefs,
    onLinkClick,
  };
};
