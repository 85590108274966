import React, {
  FC
} from 'react';
import {
  Link
} from 'react-router-dom';

import {
  ROUTE, formatDateRange, formatNumber
} from 'src/shared/utils';
import {
  InfoIcon,
  MessageIcon,
  PeopleIcon,
  TrendingUpIcon,
} from 'src/shared/icons';
import {
  ActivityTile
} from 'src/entities/Questions';
import {
  CircleButton
} from 'src/shared/components';
import {
  CommunityGroupTooltip, PublicPin
} from 'src/widgets';
import {
  useGetGroupsQuestionsQuery,
  OrganizationGroup,
} from 'src/redux/openapi';
import {
  useTypedSelector
} from 'src/redux';
import {
  languageSelectors
} from 'src/redux/language';

import * as Style from './GroupOrgCard.styles';

interface GroupOrgCardProps {
  group: OrganizationGroup;
}

export const GroupOrgCard: FC<GroupOrgCardProps> = ({
  group
}) => {
  const {
    questionSets,
    location,
    name,
    memberCount,
    commentCount,
    forecastCount,
    id,
    isPublic,
  } = group;

  const sets = questionSets || [];

  const questionsToDisplay = sets.length <= 2 ? sets : sets.slice(
    0,
    2
  );

  const {
    data: groupQuestions
  } = useGetGroupsQuestionsQuery({
    id,
  });

  const language = useTypedSelector(languageSelectors.getLanguage);

  return (
    <Link to={`/${ROUTE.GROUPS}/${id}`}>
      <Style.GroupCard>
        <Style.CardHeader>
          <div>
            <Style.CardLocation>{location}</Style.CardLocation>

            {name}
          </div>

          <div className="flex flex-col gap-1 items-end">
            {isPublic && <PublicPin />}

            <Style.CardForecast>
              <CommunityGroupTooltip
                questions={groupQuestions?.data.questions || []}
              >
                <CircleButton
                  size={24}
                  className="cursor-default"
                >
                  <InfoIcon className="w-4 h-4 text-dim-gray" />
                </CircleButton>
              </CommunityGroupTooltip>
            </Style.CardForecast>
          </div>
        </Style.CardHeader>

        <Style.ActivityRow>
          <ActivityTile
            icon={<PeopleIcon className="w-6 h-6 text-dim-gray" />}
            amount={memberCount}
          />

          <Style.ActivityChild>
            <ActivityTile
              icon={<MessageIcon className="w-6 h-6 text-dim-gray" />}
              amount={formatNumber(commentCount)}
              timeRange="upToday"
            />

            <ActivityTile
              icon={<TrendingUpIcon className="w-6 h-6 text-dim-gray" />}
              amount={formatNumber(forecastCount)}
              timeRange="upToday"
            />
          </Style.ActivityChild>
        </Style.ActivityRow>

        <Style.QuestionsContainer>
          {questionsToDisplay.map(
            ({
              id: questionID,
              title: questionTitle,
              startDate,
              endDate,
              questionsCount,
              activeQuestionsCount,
            }) => {
              return (
                <Style.QuestionTile key={questionID}>
                  <Style.QuestionDates>
                    {formatDateRange({
                      start: startDate,
                      end: endDate,
                      language,
                    })}

                    <Style.QuestionStatistic>
                      {`${activeQuestionsCount}/${questionsCount}`}
                    </Style.QuestionStatistic>
                  </Style.QuestionDates>

                  <Style.QuestionTitle>{questionTitle}</Style.QuestionTitle>
                </Style.QuestionTile>
              );
            },
          )}
        </Style.QuestionsContainer>
      </Style.GroupCard>
    </Link>
  );
};
