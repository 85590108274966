import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const Container = styled.div`
  ${tw`bg-white shadow-menu-shadow p-4 rounded border border-gray-whisper`}
`;

export const Text = styled.p`
  ${tw`text-15-20 font-medium text-dark-gray`}
`;
