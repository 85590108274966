import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const Asterisk = styled.span`
  ${tw`text-red-text-error`}
`;

export const ButtonsContainer = styled.div`
  ${tw`flex justify-end gap-2 mt-8`}
`;

export const InputLabel = styled.label`
  ${tw`font-medium text-grey-dark-40 text-15-20`}
`;

export const FormContainer = styled.form`
  ${tw`flex flex-col gap-8`}
`;
