import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const ContentContainer = styled.div`
  ${tw`flex flex-col gap-6 justify-center items-center`}
`;

export const Title = styled.p`
  ${tw`font-bold text-34-43 text-center`}
`;

export const Subtitle = styled.p`
  ${tw`font-medium text-17-26 text-dark-gray text-center`}
`;

export const ButtonsContainer = styled.div`
  ${tw`flex gap-3 w-full`}
`;
