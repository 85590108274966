import React, {
  FC, useMemo, useState
} from 'react';
import {
  Trans, useTranslation
} from 'react-i18next';
import {
  useParams
} from 'react-router-dom';

import {
  Button,
  Divider,
  ModalContainer,
  Spinner,
  Stepper,
  successfulToast,
  Switcher,
  useStepper,
  warningToast,
} from 'src/shared/components';
import {
  useGetOrgGroupsMergeQuery,
  GroupForMerge,
  usePostOrgGroupsUsersMergeMutation,
} from 'src/redux/openapi';
import {
  ArrowRightIcon, CheckmarkThinIcon
} from 'src/shared/icons';
import {
  useCustomInfiniteScroll
} from 'src/shared/hooks';
import {
  showWarningFromServer
} from 'src/shared/utils';

import {
  getStepsMap, Steps
} from './utils';
import * as Style from './MergeGroupsModal.styles';
import {
  ChooseGroupContent, ChooseMemberContent
} from './ui';
import {
  SelectValue, SelectedUsers
} from './types';

interface MergeGroupsModalProps {
  isOpen: boolean;
  closeModal: () => void;
  currentGroup: GroupForMerge;
}

export const MergeGroupsModal: FC<MergeGroupsModalProps> = ({
  isOpen,
  closeModal,
  currentGroup,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [inactiveChosen, setInactiveChosen] = useState(true);
  const [selectedGroups, setSelectedGroups] = useState<GroupForMerge[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<SelectValue | null>(null);
  const [selectedUsers, setSelectedUsers] = useState<SelectedUsers[]>([]);

  const {
    t
  } = useTranslation();

  const {
    organizationId = ''
  } = useParams();

  const {
    data: groupsData,
    isLoading,
    isFetching,
    isError,
  } = useGetOrgGroupsMergeQuery(
    {
      id: organizationId,
      page: currentPage,
      limit: 10,
    },
    {
      skip: !isOpen || !organizationId,
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    total: totalGroups, groups
  } = useMemo(
    () => groupsData?.data || {
      total: 0,
      groups: [],
    },
    [groupsData],
  );

  const groupsConfig = useCustomInfiniteScroll({
    total: totalGroups,
    currentItems: groups,
    isFetching,
    isLoading,
    isError,
    currentPage,
    setCurrentPage,
  });

  const steps = getStepsMap();

  const {
    completeCurrentStep,
    activeStepId,
    handleNextStep,
    completedStatus,
    setCurrentStep,
    handlePreviousStep,
  } = useStepper(steps);

  const onClose = () => {
    setCurrentStep(0);
    setCurrentPage(1);
    setInactiveChosen(true);
    setSelectedGroups([]);
    setSelectedGroup(null);
    setSelectedUsers([]);
    closeModal();
  };

  const toggleInactiveChosen = () => setInactiveChosen((prev) => !prev);

  const [handleMerge, {
    isLoading: isMergeLoading
  }] = usePostOrgGroupsUsersMergeMutation();

  const onCreateClick = async () => {
    const fromGroups = selectedUsers.map(({
      groupId, users
    }) => ({
      id: groupId,
      userIds: users.map((user) => user.userId),
    }));

    try {
      const response = await handleMerge({
        id: organizationId,
        mergeGroupsIntoGroupBodySchema: {
          fromGroups,
          toGroupId: selectedGroup?.value || '',
          inactivateChosenUsers: inactiveChosen,
        },
      }).unwrap();

      successfulToast(response.message);

      onClose();
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  const onNextStepClick = () => {
    if (activeStepId === Steps.ChooseGroups) {
      if (!selectedGroups.length) {
        warningToast(t('group.selectAtLeastOne'));

        return;
      }

      completeCurrentStep();
      handleNextStep();
    } else {
      const isSelectedUsers = selectedUsers.some((item) => item.users.length);

      if (!isSelectedUsers) {
        warningToast(t('group.chooseAtLeastOneUser'));

        return;
      }

      onCreateClick();
    }
  };

  return (
    <ModalContainer
      isOpen={isOpen}
      modalTitle={t('organisation.mergeGroups')}
      closeModalHandler={onClose}
      modalWidth={916}
    >
      <Style.ContentWrapper>
        <Stepper
          steps={steps}
          activeStepId={activeStepId}
          completedStatus={completedStatus}
        />

        {activeStepId === Steps.ChooseGroups && (
          <ChooseGroupContent
            groupsConfig={groupsConfig}
            currentGroup={currentGroup}
            selectedGroup={selectedGroup}
            setSelectedGroup={setSelectedGroup}
            selectedGroups={selectedGroups}
            setSelectedGroups={setSelectedGroups}
          />
        )}

        {activeStepId === Steps.ChooseMembers && (
          <ChooseMemberContent
            allGroups={selectedGroups}
            currentGroup={{
              id: selectedGroup?.value || '',
              name: selectedGroup?.label || '',
            }}
            selectedItems={selectedUsers}
            setSelectedItems={setSelectedUsers}
          />
        )}
      </Style.ContentWrapper>

      <Divider className="absolute left-0" />

      <Style.Footer>
        {activeStepId === Steps.ChooseMembers && (
          <>
            <Switcher
              isActive={inactiveChosen}
              onChange={toggleInactiveChosen}
              switcherClassName="!w-16"
              label={(
                <Trans
                  i18nKey="group.inactivateChosenMembers"
                  components={{
                    1: <br />,
                  }}
                />
              )}
              labelClassName="mr-auto"
            />

            <Button
              variant="big-grey-bordered"
              className="w-max font-bold text-xl"
              onClick={handlePreviousStep}
            >
              <ArrowRightIcon className="rotate-180" />

              {t('buttons.back')}
            </Button>
          </>
        )}

        <Button
          variant="big-blue"
          className="w-max font-bold text-xl"
          onClick={onNextStepClick}
        >
          {activeStepId === Steps.ChooseMembers ? (
            <>
              {t('buttons.create')}

              {isMergeLoading ? (
                <Spinner
                  size={24}
                  color="inherit"
                />
              ) : (
                <CheckmarkThinIcon />
              )}
            </>
          ) : (
            <>
              {t('buttons.next')}

              <ArrowRightIcon />
            </>
          )}
        </Button>
      </Style.Footer>
    </ModalContainer>
  );
};
