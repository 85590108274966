import {
  useState
} from 'react';

import {
  QuestionCard
} from '../types';

export const useUpdateModal = () => {
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [updatedCard, setUpdatedCard] = useState<QuestionCard | null>(null);

  const toggleUpdateModal = (card?: QuestionCard) => {
    setIsUpdateModalOpen(!!card);
    setUpdatedCard(card || null);
  };

  return {
    isUpdateModalOpen,
    toggleUpdateModal,
    updatedCard,
  };
};
