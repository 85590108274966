import tw from 'twin.macro';
import {
  styled
} from 'styled-components';
import {
  motion
} from 'framer-motion';

export const MainContainer = styled(motion.label)<{
  $withInput: boolean;
  $withBorder: boolean;
  $size: number;
  $padding: number;
  $isHeader?: boolean;
  $isNewUser?: boolean;
}>`
  ${tw`
    flex-1 border border-gray90 rounded-full flex flex-row gap-4 items-center justify-center
    box-border transition-all ease-linear
  `}

  ${({
    $padding
  }) => $padding
    && `
    padding-left: ${$padding}px;
    padding-right: ${$padding}px;
  `}

  ${({
    $isNewUser
  }) => !$isNewUser
    && tw`cursor-pointer focus-within:(border-input-border-blue bg-input-blue)`}

  ${({
    $withInput, $isHeader
  }) => $withInput
    && !$isHeader
    && tw`border-input-border-blue rounded-56px bg-alice-blue px-4`}

  ${({
    $withBorder
  }) => !$withBorder && tw`border-0`}

  ${({
    $size
  }) => `height: ${$size}px; min-width: ${$size}px;`}

  ${({
    $isHeader, $withInput
  }) => $isHeader
    && ($withInput ? tw`gap-2 p-2 bg-alice-blue flex-row-reverse` : tw`border-0`)}
`;

export const InputBlock = styled(motion.input)`
  ${tw`w-full h-full
  text-17 text-gray7 placeholder:text-dim-gray
  outline-none flex-1 transition-all`}

  background-color: transparent;
`;

export const ButtonContainer = styled(motion.button)`
  ${tw`flex items-center justify-center`}
`;

export const Wrapper = styled.div<{
  $shouldGrow?: boolean;
  $withInput: boolean;
}>`
  ${tw`flex`}

  ${({
    $shouldGrow, $withInput
  }) => $shouldGrow && $withInput && tw`grow`}
`;
