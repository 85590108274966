import translationEN from 'src/shared/translations/en.json';
import translationDE from 'src/shared/translations/de.json';

export const resources = {
  en: {
    translation: translationEN,
  },
  de: {
    translation: translationDE,
  },
};
