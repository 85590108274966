import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  Spinner
} from 'src/shared/components';

import * as Style from './LoaderScreen.style';

interface LoaderScreenProps {
  text?: string;
}

export const LoaderScreen: FC<LoaderScreenProps> = ({
  text
}) => {
  const {
    t
  } = useTranslation();

  return (
    <Style.Container>
      <Style.SpinContainer>
        <Style.LoaderText>{`${t('common.Loading')}...`}</Style.LoaderText>

        <Spinner
          size={24}
          centered={false}
        />
      </Style.SpinContainer>

      <Style.Text>{text || t('common.badInternetConnection')}</Style.Text>
    </Style.Container>
  );
};
