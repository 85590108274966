import React, {
  FC, useEffect, useMemo, useState
} from 'react';
import {
  useSearchParams
} from 'react-router-dom';
import {
  useTranslation
} from 'react-i18next';

import {
  Spinner, SwitchButton
} from 'src/shared/components';
import {
  OnboardingQuestionsFilter, PageLayout
} from 'src/widgets';
import {
  Filters
} from 'src/features';
import {
  YourFeed
} from 'src/entities/YourFeed';
import {
  GridIcon, ListIcon
} from 'src/shared/icons';
import {
  Questions
} from 'src/entities/Questions';
import {
  FAVORITE,
  questionView,
  questionsFilterBy,
  topicId,
} from 'src/shared/utils';
import {
  AvailableQuestionSets,
  useGetQuestionSetsAvailableQuery,
} from 'src/redux/openapi';

import * as Style from './OnboardingQuestion.styles';

const switchOptions = [
  {
    id: 0,
    label: <ListIcon />,
  },
  {
    id: 2,
    label: <GridIcon />,
  },
];

enum QuestionsView {
  Table = 'table',
  Cards = 'cards',
}

export const OnboardingQuestion: FC = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();

  const [visibleQuestionSets, setVisibleQuestionSets] = useState<
  AvailableQuestionSets[]
  >([]);

  const [questionSetsPage, setQuestionSetsPage] = useState(1);

  const {
    t
  } = useTranslation();

  const onExpand = () => setIsExpanded((prevExpanded) => !prevExpanded);

  const onFilterClick = (id: string | null) => {
    if (id) {
      searchParams.set(
        topicId,
        id
      );
    } else {
      searchParams.delete(topicId);
    }

    setSearchParams(searchParams);
  };

  const activeFilterId = useMemo(
    () => searchParams.get(topicId),
    [searchParams],
  );

  const isTableView = useMemo(
    () => searchParams.get(questionView) === QuestionsView.Table,
    [searchParams],
  );

  const onSwitch = () => {
    searchParams.set(
      questionView,
      isTableView ? QuestionsView.Cards : QuestionsView.Table,
    );

    if (isTableView) {
      searchParams.delete(questionsFilterBy);
    }

    setSearchParams(searchParams);
  };

  const {
    data: questionSetsData,
    isLoading: isQuestionSetsLoading,
    isFetching: isQuestionSetsFetching,
    isError,
  } = useGetQuestionSetsAvailableQuery(
    {
      page: questionSetsPage,
      limit: 10,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    total: totalSets,
    questionSets,
    hasFavorite,
  } = useMemo(
    () => questionSetsData?.data || {
      total: 0,
      questionSets: [],
      hasFavorite: false,
    },
    [questionSetsData],
  );

  useEffect(
    () => {
      if (questionSetsPage > 1) {
        setVisibleQuestionSets((prev) => [...prev, ...questionSets]);
      } else {
        setVisibleQuestionSets(questionSets);
      }
    },
    [questionSets]
  );

  const isMoreSets = totalSets > visibleQuestionSets.length;

  const onLoadMore = () => {
    if (isQuestionSetsLoading || isQuestionSetsFetching) {
      return;
    }

    setQuestionSetsPage((prev) => prev + 1);
  };

  const filters = useMemo(
    () => {
      const initialFilter: {
        id: null | string;
        label: string;
      }[] = [
        {
          id: null,
          label: t('question.allQuestions'),
        },
      ];

      if (hasFavorite) {
        initialFilter.push({
          id: FAVORITE,
          label: t('common.favorite'),
        });
      }

      initialFilter.push(
        ...visibleQuestionSets.map(({
          id, title
        }) => ({
          id,
          label: title,
        })),
      );

      return initialFilter;
    },
    [hasFavorite, visibleQuestionSets]
  );

  return (
    <PageLayout>
      {isQuestionSetsLoading ? (
        <Spinner />
      ) : (
        <Style.MainContainer>
          <Style.FiltersContainer>
            <Style.Filters className="scroll-hidden">
              <OnboardingQuestionsFilter isTableView={isTableView} />

              <Filters
                activeFilterId={activeFilterId}
                items={filters}
                onFilterClick={onFilterClick}
                loading={isQuestionSetsLoading || isQuestionSetsFetching}
                hasNextPage={isMoreSets}
                onLoadMore={onLoadMore}
                isError={isError}
              />
            </Style.Filters>

            <SwitchButton
              options={switchOptions}
              defaultOption={isTableView ? switchOptions[0] : switchOptions[1]}
              onChange={onSwitch}
            />
          </Style.FiltersContainer>

          <Style.ContentContainer $isExpanded={isExpanded}>
            <Questions
              isTableView={isTableView}
              isExpanded={isExpanded}
            />
          </Style.ContentContainer>

          <Style.FeedContainer $isExpanded={isExpanded}>
            <YourFeed
              isExpanded={isExpanded}
              onExpand={onExpand}
            />
          </Style.FeedContainer>
        </Style.MainContainer>
      )}
    </PageLayout>
  );
};
