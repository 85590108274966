import {
  createSlice
} from '@reduxjs/toolkit';
import type {
  PayloadAction
} from '@reduxjs/toolkit';

import {
  authApi
} from 'src/shared/api';
import {
  Comment
} from 'src/redux/openapi';

export interface CommentsListState {
  comments: Comment[];
  total: number;
  page: number;
}

export interface CommentsListReducer {
  commentsList: CommentsListState;
}

interface RemoveComment {
  commentId: string;
  deletedId: string;
}

export const initialState: CommentsListState = {
  comments: [],
  total: 0,
  page: 1,
};

export const commentsListSlice = createSlice({
  name: 'commentsList',
  initialState,
  reducers: {
    setCommentsList: (state, action: PayloadAction<Comment[]>) => {
      state.comments = action.payload;
    },
    addToCommentsList: (state, action: PayloadAction<Comment[]>) => {
      state.comments = [...state.comments, ...action.payload];
    },
    removeFromCommentsList: (state, action: PayloadAction<RemoveComment>) => {
      if (action.payload.commentId !== action.payload.deletedId) {
        state.comments = state.comments.map((comment) => {
          if (comment.id === action.payload.commentId) {
            return {
              ...comment,
              comments: comment.comments.filter((reply) => {
                return reply.id !== action.payload.deletedId;
              }),
            };
          }

          return comment;
        });
      } else {
        state.comments = state.comments.filter(
          (comment) => comment.id !== action.payload.deletedId,
        );

        state.total -= 1;
      }
    },
    addReplyToCommentsList: (state, action: PayloadAction<Comment>) => {
      state.comments = state.comments.map((comment) => {
        if (comment.id === action.payload.id) {
          return {
            ...action.payload,
          };
        }

        return comment;
      });
    },
    addCommentToCommentsList: (state, action: PayloadAction<Comment>) => {
      state.comments = [action.payload, ...state.comments];
    },
    setTotalOfCommentsList: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    addToTotalOfCommentsList: (state) => {
      state.total += 1;
    },
    setPageOfCommentsList: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.signOut.matchFulfilled,
      () => initialState,
    );
  },
});

export const {
  setCommentsList,
  addToCommentsList,
  addReplyToCommentsList,
  addCommentToCommentsList,
  setTotalOfCommentsList,
  addToTotalOfCommentsList,
  setPageOfCommentsList,
  removeFromCommentsList,
} = commentsListSlice.actions;

export const commentsListReducer = commentsListSlice.reducer;
