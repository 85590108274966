import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  ToastContainer
} from 'react-toastify';
import {
  Provider
} from 'react-redux';
import {
  PersistGate
} from 'redux-persist/integration/react';

import {
  reportWebVitals
} from 'src/app/analytics';
import {
  RoutesProvider
} from 'src/app/providers';

import 'src/shared/styles/index.css';
import {
  persistor, store
} from './redux';
import {
  CloseToastButton
} from './shared/components';
import 'react-toastify/dist/ReactToastify.min.css';
import {
  LocalizationService
} from './shared/services';

LocalizationService.initLocalization();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate
        loading={null}
        persistor={persistor}
      >
        <RoutesProvider />
      </PersistGate>
    </Provider>

    <ToastContainer
      closeButton={CloseToastButton}
      position="top-right"
      autoClose={1000}
      newestOnTop
      pauseOnHover
      hideProgressBar={false}
    />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
