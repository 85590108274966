import React, {
  FC, useEffect
} from 'react';
import {
  Outlet, useNavigate
} from 'react-router-dom';

import './styles.css';

import {
  LoginLeftSide
} from 'src/widgets';
import {
  useGetCurrentUserMutation
} from 'src/shared/api/users/usersApi';
import {
  resetUserData
} from 'src/redux/user';
import {
  ROUTE, isErrorWithMessage
} from 'src/shared/utils';
import {
  useTypedDispatch
} from 'src/redux';
import {
  useGetUserLanguageQuery
} from 'src/redux/openapi';
import {
  useNavigatorOnLine
} from 'src/shared/hooks/useNavigatorOnLine';
import {
  LoaderScreen
} from 'src/shared/components';

export const Login: FC = () => {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();

  const isOnLine = useNavigatorOnLine();

  useGetUserLanguageQuery(
    undefined,
    {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
    }
  );

  const [getCurrentUser, {
    isSuccess: isSuccessCurrentUser
  }] = useGetCurrentUserMutation();

  const loadCurrentUser = async () => {
    try {
      await getCurrentUser().unwrap();
    } catch (error) {
      if (isErrorWithMessage(error)) {
        dispatch(resetUserData());
      }
    }
  };

  useEffect(
    () => {
      loadCurrentUser();
    },
    []
  );

  useEffect(
    () => {
      if (isSuccessCurrentUser) {
        navigate(
          `/${ROUTE.QUESTION}`,
          {
            replace: true,
          }
        );
      }
    },
    [isSuccessCurrentUser]
  );

  return (
    <div className="flex flex-col md:flex-row bg-white min-h-screen">
      <LoginLeftSide />

      <div className="md:flex-1 relative bg-white p-4 md:p-0">
        {isOnLine ? <Outlet /> : <LoaderScreen />}
      </div>
    </div>
  );
};
