import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';

import {
  formatDateRange, getTime
} from 'src/shared/utils';
import {
  Spinner
} from 'src/shared/components';
import {
  OrgVerifications
} from 'src/redux/openapi';

import * as Style from './HistoryList.styles';

interface HistoryListProps {
  hasMore: boolean;
  onLoadMore: () => void;
  data: OrgVerifications;
}

export const HistoryList: FC<HistoryListProps> = ({
  hasMore,
  onLoadMore,
  data,
}) => {
  const {
    t,
    i18n: {
      language
    },
  } = useTranslation();

  return (
    <Style.ListContainer>
      <InfiniteScroll
        pageStart={1}
        loadMore={onLoadMore}
        hasMore={hasMore}
        loader={(
          <Style.SpinnerContainer key="spinner">
            <Spinner size={24} />
          </Style.SpinnerContainer>
        )}
        useWindow={false}
        threshold={50}
        key={language}
        className="flex flex-col gap-2 overflow-auto"
      >
        {data.map(({
          id, type, createdAt
        }, index) => (
          <Style.ListItem key={id + createdAt + index}>
            <Style.ItemIndex>{`${index + 1}.`}</Style.ItemIndex>

            <Style.DateContainer>
              <Style.DateTitle>{`${t('date.date')}:`}</Style.DateTitle>

              {formatDateRange({
                start: createdAt,
                language,
              })}
            </Style.DateContainer>

            <Style.DateContainer>
              <Style.DateTitle>{`${t('date.time')}:`}</Style.DateTitle>

              {getTime(createdAt)}
            </Style.DateContainer>

            <Style.TypeContainer>
              {t(`verification.${type}`)}
            </Style.TypeContainer>
          </Style.ListItem>
        ))}
      </InfiniteScroll>
    </Style.ListContainer>
  );
};
