import React, {
  FC
} from 'react';
import type {
  DotProps
} from 'recharts';

import {
  OWN_PREDICTION, TOTAL_PREDICTION
} from 'src/entities/Graph';

interface GraphDotProps extends DotProps {
  dataKey?: string;
  payload?: Record<string, unknown>;
}

const DEFAULT_RADIUS = 4;

export const GraphDot: FC<GraphDotProps> = ({
  cx = 0,
  cy = 0,
  r: radius = DEFAULT_RADIUS,
  stroke,
  dataKey,
  payload,
}) => {
  const isShown = !!payload?.[String(dataKey)];

  if (!isShown) {
    return null;
  }

  const getAreaDotWidth = () => {
    const totalVotesRadius = payload.votes as number;

    if (totalVotesRadius <= DEFAULT_RADIUS) {
      return 4;
    }

    if (totalVotesRadius > 50) {
      return 50;
    }

    return totalVotesRadius;
  };

  const maxWidthTotal = getAreaDotWidth();

  const getFinalRadiusValue = () => {
    if (dataKey?.startsWith(OWN_PREDICTION)) {
      return DEFAULT_RADIUS;
    }

    if (dataKey?.startsWith(TOTAL_PREDICTION)) {
      return maxWidthTotal;
    }

    return radius;
  };

  const finalRadiusDotValue = getFinalRadiusValue();

  const finalRadiusDotValueDoubled = finalRadiusDotValue * 2;

  return (
    <svg
      x={cx - finalRadiusDotValue}
      y={cy - finalRadiusDotValue}
      width={finalRadiusDotValueDoubled}
      height={finalRadiusDotValueDoubled}
      viewBox={`0 0 ${finalRadiusDotValueDoubled} ${finalRadiusDotValueDoubled}`}
      fill={stroke}
    >
      <circle
        cx={finalRadiusDotValue}
        cy={finalRadiusDotValue}
        r={finalRadiusDotValue / 2}
      />

      {dataKey === TOTAL_PREDICTION && (
        <circle
          cx={finalRadiusDotValue}
          cy={finalRadiusDotValue}
          r={finalRadiusDotValue / 2 + 2}
          opacity={0.4}
        />
      )}
    </svg>
  );
};
