import React, {
  FC, useMemo
} from 'react';
import {
  Controller
} from 'react-hook-form';
import {
  useTranslation
} from 'react-i18next';

import {
  Breadcrumbs, EditQuestionsList, LocationSelect
} from 'src/features';
import {
  CreateEntityLayout,
  InvitedPeopleList,
  InvitePeopleForm,
  CreateEntityHead,
  CreateEntityContent,
} from 'src/widgets';
import {
  themeColors
} from 'src/shared/utils';
import {
  Spinner, Stepper
} from 'src/shared/components';

import * as Style from './CreateGroup.styles';
import {
  useCreateGroup
} from './useCreateGroup';
import {
  FormField
} from './types';

export const CreateGroup: FC = () => {
  const {
    steps,
    handleNextStep,
    completedStatus,
    isLastStep,
    isLastStepCompleted,
    currentStep,
    control,
    register,
    onPeopleInvite,
    activeStepId,
    errors,
    newGroupId,
    invitedPeople,
    questionSets,
    isQuestionSetsLoading,
    refetchInvitedUserData,
    selectConfig,
  } = useCreateGroup();

  const {
    t
  } = useTranslation();

  const nextStepButtonTitle = useMemo(
    () => {
      if (currentStep === 0) {
        return t('buttons.create');
      }

      return !isLastStepCompleted ? t('buttons.skip') : t('buttons.finish');
    },
    [currentStep, isLastStepCompleted]
  );

  return (
    <CreateEntityLayout>
      <CreateEntityHead>
        <Breadcrumbs />

        <Stepper
          steps={steps}
          completedStatus={completedStatus}
          activeStepId={activeStepId}
          nextStepButtonTitle={nextStepButtonTitle}
          isNextButtonOutline={isLastStep && !isLastStepCompleted}
          handleNextStep={handleNextStep}
        />

        {currentStep === 0 && (
          <Style.GroupInfoForm>
            <Style.FieldLabel>
              <p>
                {t('modal.groupName')}

                <Style.Asterisk>{' *'}</Style.Asterisk>
              </p>

              <Style.FieldInput
                placeholder={t(
                  'inputMessages.MAXIMUM_CHARACTERS',
                  {
                    value: 30,
                  }
                )}
                {...register(FormField.GroupName)}
                $withError={!!errors[FormField.GroupName]?.message}
                autoComplete="off"
              />

              {errors[FormField.GroupName]?.message && (
                <Style.ErrorMessage>
                  {errors[FormField.GroupName].message}
                </Style.ErrorMessage>
              )}
            </Style.FieldLabel>

            <Style.FieldLabel>
              <Controller
                name={FormField.GroupLocation}
                control={control}
                render={({
                  field
                }) => (
                  <LocationSelect
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    errorMessage={
                      errors[FormField.GroupLocation]?.value?.message
                    }
                    isRequired
                  />
                )}
              />
            </Style.FieldLabel>
          </Style.GroupInfoForm>
        )}
      </CreateEntityHead>

      <CreateEntityContent>
        {currentStep === 0 && (
          <>
            <Style.SectionHeader>
              {isQuestionSetsLoading ? (
                <Spinner />
              ) : (
                <>
                  {!questionSets.length && (
                    <Style.SectionTitle>
                      {t('group.noQuestionsToChoose')}
                    </Style.SectionTitle>
                  )}

                  {questionSets.length && (
                    <>
                      <Style.SectionTitle>
                        {t('group.chooseQuestionsSet')}
                      </Style.SectionTitle>

                      <Style.HeaderMessage $color={themeColors['dim-gray']}>
                        {`${t('modal.selected')}`}
                        :&nbsp;
                      </Style.HeaderMessage>

                      <Style.HeaderMessage $color={themeColors.gray7}>
                        {selectConfig.selectedItems.length}
                      </Style.HeaderMessage>

                      <Style.HeaderMessage $color={themeColors['grey-dark-40']}>
                        {`/${questionSets.length}`}
                        &nbsp;
                      </Style.HeaderMessage>

                      <Style.HeaderMessage $color={themeColors.gray7}>
                        {t('common.set(s)').toLowerCase()}
                      </Style.HeaderMessage>
                    </>
                  )}
                </>
              )}
            </Style.SectionHeader>

            <EditQuestionsList
              isQuestionsLoading={isQuestionSetsLoading}
              preparedSets={questionSets}
              selectConfig={selectConfig}
            />
          </>
        )}

        {currentStep === 1 && (
          <>
            <Style.SectionHeader>
              <Style.SectionTitleContainer>
                <Style.SectionTitle>
                  {t('organisation.inviteNewMembers')}
                </Style.SectionTitle>

                <Style.SectionSubtitle>
                  <p>{`${t('group.hasToHaveFiveMembers')}.`}</p>

                  {t('group.youCanSkipIt')}
                </Style.SectionSubtitle>
              </Style.SectionTitleContainer>

              <Style.HeaderMessage $color={themeColors['dim-gray']}>
                {`${t('group.invited')}:`}
                &nbsp;
              </Style.HeaderMessage>

              <Style.HeaderMessage $color={themeColors.gray7}>
                {`${invitedPeople.length} ${t('group.user(s)')}`}
              </Style.HeaderMessage>
            </Style.SectionHeader>

            <InvitePeopleForm
              onSubmit={onPeopleInvite}
              groupId={newGroupId}
            />

            {!invitedPeople.length ? (
              <Style.InvitedPeopleEmptyMessage>
                {t('group.invitedUsersEmailsWillShowUpHere')}
              </Style.InvitedPeopleEmptyMessage>
            ) : (
              <InvitedPeopleList
                invitedPeople={invitedPeople}
                newGroupId={newGroupId}
                onInvitationRemove={refetchInvitedUserData}
              />
            )}
          </>
        )}
      </CreateEntityContent>
    </CreateEntityLayout>
  );
};
