import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MainContainer = styled.div`
  ${tw`flex flex-col`}
`;

export const ListItem = styled.div`
  ${tw`
    grid grid-cols-[24px,1fr,1fr] items-center border-b border-b-gray-whisper py-4 gap-3
    text-17-26 text-grey-dark-40 font-medium
  `}
`;

export const Email = styled.p`
  ${tw`text-gray7`}
`;

export const RightElement = styled.div`
  ${tw`flex items-center gap-6 justify-end`}
`;
