import React, {
  FC, PropsWithChildren
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  Tooltip
} from 'src/shared/components';
import {
  StatisticTile
} from 'src/entities/Questions';
import {
  GlobeIcon, PeopleIcon, PersonIcon
} from 'src/shared/icons';
import {
  GroupQuestion
} from 'src/redux/openapi';

import * as Style from './CommunityGroupTooltip.styles';

interface CommunityGroupTooltipProps extends PropsWithChildren {
  questions: GroupQuestion[];
}

export const CommunityGroupTooltip: FC<CommunityGroupTooltipProps> = ({
  children,
  questions,
}) => {
  const {
    t
  } = useTranslation();

  const content = (
    <Style.MainContainer>
      {questions.length > 0 ? (
        <Style.Title>{t('question.questionForecast')}</Style.Title>
      ) : (
        <Style.Title>{t('question.groupDoesntHaveForecasts')}</Style.Title>
      )}

      <Style.QuestionsContainer>
        {!!questions.length
          && questions.map((question) => (
            <Style.QuestionContainer key={question.id}>
              {question.title}

              <Style.StatisticRow>
                <StatisticTile
                  icon={<PersonIcon className="w-4 h-4 text-dim-gray" />}
                  amount={question.userEstimatesAvg}
                  applyMinWidth={false}
                />

                <StatisticTile
                  icon={<GlobeIcon className="w-4 h-4 text-dim-gray" />}
                  amount={question.globalEstimatesAvg}
                  growth={question.globalEstimatesChange}
                  applyMinWidth={false}
                />

                <StatisticTile
                  icon={<PeopleIcon className="w-4 h-4 text-dim-gray" />}
                  amount={question.groupEstimatesAvg}
                  growth={question.groupEstimatesChange}
                  applyMinWidth={false}
                />
              </Style.StatisticRow>
            </Style.QuestionContainer>
          ))}
      </Style.QuestionsContainer>
    </Style.MainContainer>
  );

  return (
    <Tooltip
      message={content}
      variant="light"
      hideOnScroll={false}
    >
      {children}
    </Tooltip>
  );
};
