import tw from 'twin.macro';
import {
  styled
} from 'styled-components';
import {
  Link
} from 'react-router-dom';

const TOP_PADDING = '17px';
const BOTTOM_MARGIN = '31px';

export const HeaderContainer = styled.div`
  ${tw`flex flex-row gap-4 justify-between mb-4`}
`;

export const CardWrapper = styled.div<{ $isComplete?: boolean }>`
  ${tw`grid gap-6`}

  ${({
    $isComplete
  }) => ($isComplete ? tw`grid-cols-group-completed` : tw`grid-cols-2`)}
`;

export const CreateGroupTitle = styled(Link)`
  ${tw`py-2.5 px-4 bg-white text-button-blue text-15-20 font-bold rounded-100px flex flex-row gap-2 items-center border border-button-blue shadow-menu-shadow shrink-0`}
`;

export const SectionsContainer = styled.div<{
  $shift: number;
  $isComplete: boolean;
}>`
  ${tw`grid gap-6`}

  padding-top: ${TOP_PADDING};
  margin-bottom: ${BOTTOM_MARGIN};

  ${({
    $shift
  }) => `grid-template-rows: minmax(620px, calc(100vh - ${$shift}px - ${TOP_PADDING} - ${BOTTOM_MARGIN}));`}

  ${({
    $isComplete
  }) => ($isComplete
    ? tw`grid-cols-[1.5fr,minmax(440px,1fr),320px]`
    : tw`grid-cols-2`)}
`;

export const EmptyMessageContainer = styled.div`
  ${tw`text-center text-17-26 p-4 h-full flex flex-col items-center justify-center`}
`;

export const EmptyMessageTitle = styled.p`
  ${tw`font-bold text-dark-gray mb-2`}
`;

export const EmptyMessageSubtitle = styled.p`
  ${tw`font-medium text-dim-gray mb-6`}
`;

export const EmptyMessageLink = styled(Link)`
  ${tw`rounded-100px border border-light-grey font-bold text-15-20 text-dim-gray py-9px px-15px bg-white`}
`;
