import React, {
  useState, FC, useMemo, useEffect
} from 'react';
import {
  useLocation, useNavigate, useSearchParams
} from 'react-router-dom';
import {
  useTranslation
} from 'react-i18next';

import {
  EditButton,
  EmptyResultsMessage,
  ScrollContainer,
  SearchInput,
  Spinner,
} from 'src/shared/components';
import {
  QuestionsFilter, QuestionsOverviewCard
} from 'src/widgets';
import {
  useGetGroupQuestionSetsQuery,
  useGetOrgGroupsQuestionsActiveQuery,
  UserGroupRole,
  UserGroupRoleType,
} from 'src/redux/openapi';
import {
  QuestionSortBy
} from 'src/shared/api/types';
import {
  FilterBy, SortBy
} from 'src/entities/Questions';
import {
  questionsSortBy,
  questionsFilterBy,
  formatDateRange,
  ROUTE,
} from 'src/shared/utils';
import {
  getQuestionSortBy,
  getGroupQuestionFilter,
} from 'src/widgets/QuestionsFilter/utils';
import {
  useTypedSelector
} from 'src/redux';
import {
  languageSelectors
} from 'src/redux/language';
import {
  FilterAmountTile,
  Filters,
  useFiltersInfiniteScroll,
} from 'src/features';
import {
  useCustomInfiniteScroll, useDebouncedValue
} from 'src/shared/hooks';

import {
  SectionTitle,
  SectionHeader,
  SectionContainer,
  ButtonsContainer,
} from '../Sections.styles';

interface SetsQuestionsSectionProps {
  id: string;
  role: UserGroupRoleType;
}

export const SetsQuestionsSection: FC<SetsQuestionsSectionProps> = ({
  id,
  role,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchParams] = useSearchParams();
  const [activeGroupId, setActiveGroupId] = useState<string | null>(null);
  const [groupsPage, setGroupsPage] = useState(1);
  const [questionSetsPage, setQuestionSetsPage] = useState(1);
  const [wasFilterChanged, setWasFilterChanged] = useState(false);

  const debouncedQuery = () => useDebouncedValue(
    searchQuery,
    500
  );

  const navigate = useNavigate();
  const location = useLocation();

  const toggleEditing = () => {
    navigate(`${location.pathname}/${ROUTE.EDIT_QUESTIONS}`);
  };

  const filters = useMemo(
    () => {
      const sortParam = searchParams.get(questionsSortBy) as SortBy;
      const filterParam = searchParams.get(questionsFilterBy) as FilterBy;

      const sortByResult = getQuestionSortBy<QuestionSortBy>(
        sortParam || SortBy.Newest,
      );

      const filterByResult = filterParam
        ? getGroupQuestionFilter(filterParam)
        : {};

      return {
        ...sortByResult,
        ...filterByResult,
      };
    },
    [searchParams]
  );

  useEffect(
    () => {
      setQuestionSetsPage(1);
    },
    [filters, debouncedQuery]
  );

  const isAdmin = role !== UserGroupRole.USER;

  const {
    data: questionSetsData,
    isLoading: isQuestionsLoading,
    isFetching: isQuestionsSetsFetching,
    isError: isQuestionsSetsError,
  } = useGetGroupQuestionSetsQuery(
    {
      id: activeGroupId as string,
      filters: {
        ...filters,
        query: searchQuery,
      },
      limit: 10,
      page: questionSetsPage,
    },
    {
      skip: !activeGroupId,
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    total: totalQuestionSets, questionSets
  } = useMemo(
    () => questionSetsData?.data || {
      total: 0,
      questionSets: [],
    },
    [questionSetsData],
  );

  const {
    sentryRef,
    allItems: allQuestionsSets,
    displayLoader,
  } = useCustomInfiniteScroll({
    total: totalQuestionSets,
    currentItems: questionSets,
    isFetching: isQuestionsSetsFetching,
    isLoading: isQuestionsLoading,
    isError: isQuestionsSetsError,
    currentPage: questionSetsPage,
    setCurrentPage: setQuestionSetsPage,
  });

  const onFilterClick = (filterId: string | null) => {
    setWasFilterChanged(true);
    setActiveGroupId(filterId);
  };

  useEffect(
    () => {
      if (wasFilterChanged && !isQuestionsSetsFetching) {
        setWasFilterChanged(false);
      }
    },
    [isQuestionsSetsFetching]
  );

  const language = useTypedSelector(languageSelectors.getLanguage);

  const {
    t
  } = useTranslation();

  const {
    data: groupsData,
    isLoading: isFiltersLoading,
    isFetching: isFiltersFetching,
    isError: isFiltersError,
  } = useGetOrgGroupsQuestionsActiveQuery(
    {
      id,
      limit: 10,
      page: groupsPage,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    total: totalGroups, groups
  } = useMemo(
    () => groupsData?.data || {
      total: 0,
      groups: [],
    },
    [groupsData],
  );

  const {
    allItems: allGroups,
    onLoadMore: onLoadGroupsMore,
    hasNextPage: hasGroupsNextPage,
    loading: isGroupsNextLoading,
  } = useFiltersInfiniteScroll({
    total: totalGroups,
    currentItems: groups,
    isFetching: isFiltersLoading,
    isLoading: isFiltersFetching,
    isError: isFiltersError,
    currentPage: groupsPage,
    setCurrentPage: setGroupsPage,
  });

  const groupsFilters = useMemo(
    () => {
      return (
        allGroups.map((group) => ({
          id: group.id,
          label: group.name,
          questionsCount: group.activeQuestionCount,
        })) || []
      );
    },
    [allGroups]
  );

  useEffect(
    () => {
      if (groupsFilters.length) {
        if (!activeGroupId) {
          setActiveGroupId(groupsFilters[0].id);
        }
      }
    },
    [groupsFilters]
  );

  if (!activeGroupId && !isFiltersLoading && !isQuestionsLoading) {
    return (
      <EmptyResultsMessage results={t('common.questions').toLowerCase()} />
    );
  }

  const onSearchQueryChange = (value: string) => {
    setSearchQuery(value);
    setQuestionSetsPage(1);
  };

  return (
    <SectionContainer
      className="max-height-full pb-4"
      id="SectionContainer"
    >
      <SectionHeader className="pb-23px">
        <SectionTitle>{t('question.setsAndQuestions')}</SectionTitle>

        <ButtonsContainer>
          <QuestionsFilter />

          {isAdmin && <EditButton onClick={toggleEditing} />}

          <SearchInput
            onChange={onSearchQueryChange}
            value={searchQuery}
            placeholder={`${t('common.startTyping')}...`}
          />
        </ButtonsContainer>
      </SectionHeader>

      <ScrollContainer className="grow">
        {isFiltersLoading ? (
          <Spinner size={24} />
        ) : (
          <>
            <Filters
              items={groupsFilters}
              onFilterClick={onFilterClick}
              activeFilterId={activeGroupId}
              canShrink
              onLoadMore={onLoadGroupsMore}
              hasNextPage={hasGroupsNextPage}
              loading={isGroupsNextLoading}
              isError={isFiltersError}
              renderRightElement={({
                questionsCount, id: groupId
              }) => {
                const isActive = groupId === activeGroupId;

                return (
                  <FilterAmountTile isActive={isActive}>
                    {questionsCount}
                  </FilterAmountTile>
                );
              }}
            />

            {isQuestionsLoading || wasFilterChanged ? (
              <Spinner size={24} />
            ) : (
              <>
                {!allQuestionsSets.length && (
                  <EmptyResultsMessage
                    results={t('common.questions').toLowerCase()}
                  />
                )}

                {allQuestionsSets.map(
                  ({
                    id: questionSetID,
                    title: questionSetTitle,
                    startDate,
                    endDate,
                    commentCount,
                    questionCount,
                    participantsCount,
                    forecastCount,
                  }) => {
                    const dates = formatDateRange({
                      start: startDate,
                      end: endDate,
                      language,
                    });

                    return (
                      <QuestionsOverviewCard
                        key={questionSetID}
                        title={questionSetTitle}
                        dates={dates}
                        participantsNumber={participantsCount}
                        commentsPerDay={commentCount}
                        activeMembersPerDay={forecastCount}
                        questionCount={questionCount}
                        questionSetId={questionSetID}
                        id={activeGroupId as string}
                        filters={filters}
                        searchQuestion={searchQuery}
                        isAdmin={isAdmin}
                      />
                    );
                  },
                )}

                {displayLoader && (
                  <div
                    ref={sentryRef}
                    className="mt-2"
                  >
                    <Spinner size={24} />
                  </div>
                )}
              </>
            )}
          </>
        )}
      </ScrollContainer>
    </SectionContainer>
  );
};
