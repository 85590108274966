import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const ListContainer = styled.ul`
  ${tw`flex flex-col gap-2 overflow-auto`}
`;

export const ListItem = styled.li`
  ${tw`flex items-center gap-2 p-2 rounded border border-gray94 font-medium text-15-20`}
`;

export const ListItemContent = styled.div`
  ${tw`flex gap-2 text-gray7 items-center`}
`;

export const LightText = styled.p`
  ${tw`text-dim-gray min-w-32px`}
`;
