import {
  NotificationType
} from 'src/shared/api/notifications/types';

export const replyNotifications = [NotificationType.COMMENT_REPLY];

export const actionNotifications = [
  NotificationType.GROUP_INVITATION,
  NotificationType.IMMUTABLE_CHARACTERISTIC_UPDATE,
];

export const groupsNotifications = [
  NotificationType.GROUP_ORGANIZATION_REQUEST,
  NotificationType.GROUP_USER_REMOVAL,
  NotificationType.GROUP_USER_PROMOTION,
];

export const getNotificationByType = (type: NotificationType) => {
  const isReply = replyNotifications.includes(type);
  const isActions = actionNotifications.includes(type);
  const isGroups = groupsNotifications.includes(type);

  return {
    isReply,
    isActions,
    isGroups,
  };
};
