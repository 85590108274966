import {
  io
} from 'socket.io-client';

enum NamespaceSocket {
  Community = 'community',
  Questions = 'questions',
  Notifications = 'notifications',
}

export enum CommunityEventSocket {
  QuestionComments = 'questionComments',
}

export const socketCommunity = io(
  `${process.env.REACT_APP_API_SOCKET_PATH}/${NamespaceSocket.Community}`,
  {
    withCredentials: true,
  },
);
export const socketQuestions = io(
  `${process.env.REACT_APP_API_SOCKET_PATH}/${NamespaceSocket.Questions}`,
  {
    withCredentials: true,
  },
);
export const socketNotifications = io(
  `${process.env.REACT_APP_API_SOCKET_PATH}/${NamespaceSocket.Notifications}`,
  {
    withCredentials: true,
  },
);
