import React, {
  FC, useMemo, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import clsx from 'clsx';
import ReactSelect from 'react-select';

import {
  SelectPaginationMenu, DropdownIndicator
} from 'src/shared/components';
import {
  useGetQuestionDelegationsGroupsQuery
} from 'src/redux/openapi';
import {
  useCustomInfiniteScroll
} from 'src/shared/hooks';

import {
  GroupUsersList
} from '../GroupUsersList';

import * as Style from './AssignFromGroup.styles';

interface AssignFromGroupProps {
  questionId: string;
  onDelegateChange: (id: string | null) => void;
  delegateId: string | null;
}

interface SelectValue {
  label: string;
  value: string;
}

export const AssignFromGroup: FC<AssignFromGroupProps> = ({
  questionId,
  onDelegateChange,
  delegateId,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedGroup, setSelectedGroup] = useState<SelectValue | null>(null);

  const {
    t
  } = useTranslation();

  const {
    data: groupsData,
    isFetching,
    isLoading,
    isError,
  } = useGetQuestionDelegationsGroupsQuery(
    {
      page: currentPage,
      limit: 10,
      id: questionId,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    total: totalGroups, groups
  } = useMemo(
    () => groupsData?.data || {
      total: 0,
      groups: [],
    },
    [groupsData],
  );

  const groupsSelectConfig = useCustomInfiniteScroll({
    total: totalGroups,
    currentItems: groups,
    isFetching,
    isLoading,
    isError,
    currentPage,
    setCurrentPage,
  });

  const selectOptions = groupsSelectConfig.allItems.map(({
    name, id
  }) => ({
    label: name,
    value: id,
  }));

  const onGroupChange = (newValue: SelectValue) => {
    setSelectedGroup(newValue as SelectValue);
    onDelegateChange(null);
  };

  return (
    <div>
      <Style.GroupText>{t('common.group')}</Style.GroupText>

      <ReactSelect
        options={selectOptions}
        isMulti={false}
        hideSelectedOptions={false}
        isClearable={false}
        value={selectedGroup}
        isSearchable={false}
        closeMenuOnSelect
        closeMenuOnScroll={false}
        menuPortalTarget={document.body}
        onChange={(newValue) => onGroupChange(newValue as SelectValue)}
        noOptionsMessage={() => (isLoading ? `${t('common.Loading')}...` : t('empty.nothingFound'))}
        placeholder={`${t('common.select')} ${t('modal.aGroup')}`}
        styles={{
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
          }),
        }}
        components={{
          DropdownIndicator,
          MenuList: SelectPaginationMenu,
        }}
        sentryRef={groupsSelectConfig.sentryRef}
        displayLoader={groupsSelectConfig.displayLoader}
        classNames={{
          control: ({
            menuIsOpen
          }) => clsx(
            `
                px-4 py-2 border outline-0 !rounded-md
                text-17-26 text-dark-gray font-medium
                !shadow-none
              `,
            {
              '!border-gray90': !menuIsOpen,
              '!border-button-blue': menuIsOpen,
            },
          ),
          valueContainer: () => `!flex !p-0 gap-2`,
          indicatorSeparator: () => `hidden`,
          option: ({
            isSelected
          }) => clsx(
            `
                text-17-26 font-medium mb-2 last:mb-0 p-2 rounded hover:!bg-cultured cursor-pointer
              `,
            {
              '!bg-alice-blue !text-button-blue': isSelected,
              '!text-dark-gray !bg-white': !isSelected,
            },
          ),
          menu: () => `p-2 !border-0 rounded !mt-1 !z-10 overflow-auto`,
          menuList: () => `overflow-auto !max-h-192px`,
        }}
      />

      {selectedGroup && (
        <GroupUsersList
          questionId={questionId}
          groupId={selectedGroup.value}
          delegateId={delegateId}
          onDelegateChange={onDelegateChange}
        />
      )}
    </div>
  );
};
