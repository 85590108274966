import React, {
  FC, useState, useEffect, useMemo, useRef
} from 'react';
import {
  Link, useParams, useSearchParams
} from 'react-router-dom';
import {
  useTranslation
} from 'react-i18next';

import {
  useGetComments,
  useGetPollingComments,
  useSendComment,
} from 'src/shared/hooks';
import {
  SendCommentBlock
} from 'src/widgets/SendCommentBlock';
import {
  ROUTE,
  getSearchRegex,
  questionId,
  themeColors,
  commentSearch,
  groupId,
} from 'src/shared/utils';
import {
  SearchButton, Spinner
} from 'src/shared/components';
import {
  CommentsFilterContext, CommunitySearch
} from 'src/widgets';
import {
  Question
} from 'src/redux/openapi';
import {
  useTypedSelector
} from 'src/redux';
import {
  commentsListSelectors
} from 'src/redux/commentsList';

import {
  Title
} from '../Sections.styles';

import * as Style from './CommunityCommentsSection.styles';
import {
  CommentsList
} from './CommentsList';

interface CommunityCommentsSectionProps {
  activeQuestion: Question | null;
  isNoGroups?: boolean;
}

export const CommunityCommentsSection: FC<CommunityCommentsSectionProps> = ({
  activeQuestion,
  isNoGroups,
}) => {
  const {
    t
  } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const [isSearchActive, setIsSearchActive] = useState(false);

  const commentsRef = useRef<HTMLDivElement | null>(null);

  const searchQuery = searchParams.get(commentSearch) || '';
  const activeQuestionId = searchParams.get(questionId) || null;

  const params = useParams();
  const questionIdToSearch = params.id || activeQuestionId;

  const toggleSearch = () => setIsSearchActive((prev) => !prev);

  const {
    addReplyHandler, addNewCommentHandler
  } = useSendComment();

  const {
    loadNextPage: loadPollingNextPage,
    isLoadingComments: isLoadingPolling,
    isLoadingMoreComments: isLoadingPollingMore,
    hasAvailable,
  } = useGetPollingComments(true);

  const comments = useTypedSelector(commentsListSelectors.selectCommentsList);

  const commentsStateTotal = useTypedSelector(
    commentsListSelectors.selectCommentsListTotal,
  );

  const {
    data,
    isLoading: isLoadingCachedComments,
    loadNextPage: loadCachedNextPage,
    isLoadingMoreComments: isLoadingCachedMoreComments,
    refetch,
    total: totalCachedComments,
    hasOnboarded,
  } = useGetComments();

  const groupIdSearch = searchParams.get(groupId);
  const isQuestionQuery = questionIdToSearch && !groupIdSearch;
  const commentsList = isQuestionQuery ? data : comments;

  const commentsTotal = isQuestionQuery
    ? totalCachedComments
    : commentsStateTotal;

  const hasQuestionComments = isQuestionQuery ? hasOnboarded : hasAvailable;

  const loadNextPage = isQuestionQuery
    ? loadCachedNextPage
    : loadPollingNextPage;

  const isLoading = isQuestionQuery
    ? isLoadingCachedComments
    : isLoadingPolling;

  const isLoadingMoreComments = isQuestionQuery
    ? isLoadingCachedMoreComments
    : isLoadingPollingMore;

  const title = activeQuestion?.title || t('comment.allMyComments');
  const questionID = activeQuestion?.id;

  const matches = useMemo(
    () => {
      if (!searchQuery) {
        return 0;
      }

      return commentsList.reduce(
        (count, comment) => {
          const regex = getSearchRegex(searchQuery);

          const matchesCount = comment.text.match(regex)?.length || 0;

          return count + matchesCount;
        },
        0
      );
    },
    [searchParams, commentsList]
  );

  useEffect(
    () => {
      setIsSearchActive(false);

      if (!activeQuestion && activeQuestionId) {
        searchParams.delete(questionId);
        setSearchParams(searchParams);
      }
    },
    [activeQuestion, activeQuestionId]
  );

  return (
    <Style.Section
      id="CommunityCommentsSection"
      $withSearch={isSearchActive}
      className="relative"
    >
      <Style.SectionHeader>
        <Style.HeaderTitleWrapper>
          <Title className="truncate">{title}</Title>

          {questionID && (
            <Link to={`/${ROUTE.QUESTION}/${questionID}`}>
              <Style.Question>
                <Style.QuestionTitle>
                  {t('community.goToQuestionDetailsPage')}
                </Style.QuestionTitle>
              </Style.Question>
            </Link>
          )}
        </Style.HeaderTitleWrapper>

        <Style.ButtonsContainer>
          <SearchButton
            onClick={toggleSearch}
            borderColor={
              isSearchActive ? themeColors['button-blue'] : themeColors.gray90
            }
            iconColor={
              isSearchActive
                ? themeColors['button-blue']
                : themeColors['dim-gray']
            }
          />

          <CommentsFilterContext isNoGroups={isNoGroups} />
        </Style.ButtonsContainer>
      </Style.SectionHeader>

      {isSearchActive && (
        <CommunitySearch
          matches={matches}
          onClose={toggleSearch}
          commentsRef={commentsRef}
          comments={commentsList}
        />
      )}

      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <CommentsList
            commentsRef={commentsRef}
            comments={commentsList}
            isLoading={isLoading}
            loadNextPage={loadNextPage}
            isLoadingMoreComments={isLoadingMoreComments}
            refetch={refetch}
            total={commentsTotal}
            hasQuestionComments={hasQuestionComments}
          />

          <SendCommentBlock
            addReplyHandler={addReplyHandler}
            addNewCommentHandler={addNewCommentHandler}
            containerStyle={{
              padding: '16px',
            }}
          />
        </>
      )}
    </Style.Section>
  );
};
