import React, {
  FC
} from 'react';

import {
  formatDateRange, themeColors
} from 'src/shared/utils';
import {
  useTypedSelector
} from 'src/redux';
import {
  languageSelectors
} from 'src/redux/language';
import {
  ProgressCircle
} from 'src/shared/components';
import {
  AchievedBadgeIcon
} from 'src/shared/icons';

import * as Style from './BadgeCard.style';

interface BadgeCardProps {
  isAchieved?: boolean;
  achievementDate?: string;
  title: string;
  description?: string;
}

export const BadgeCard: FC<BadgeCardProps> = ({
  isAchieved,
  achievementDate,
  title,
  description,
}) => {
  const language = useTypedSelector(languageSelectors.getLanguage);

  const borderColor = isAchieved
    ? themeColors['list-blue']
    : themeColors['alice-blue'];

  return (
    <Style.CardContainer>
      <Style.AchievedProgressWrapper>
        {isAchieved && achievementDate && (
          <Style.AchievedCardDateWrapper>
            <Style.AchievedCardDate>
              {formatDateRange({
                start: achievementDate,
                language,
              })}
            </Style.AchievedCardDate>
          </Style.AchievedCardDateWrapper>
        )}

        <ProgressCircle
          icon={<AchievedBadgeIcon className="text-button-blue" />}
          borderColor={borderColor}
        />
      </Style.AchievedProgressWrapper>

      <Style.CardDescription>
        <Style.CardTitle>{title}</Style.CardTitle>

        {description && <Style.CardSubTitle>{description}</Style.CardSubTitle>}
      </Style.CardDescription>
    </Style.CardContainer>
  );
};
