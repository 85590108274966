import React, {
  FC, useState, useMemo, useEffect
} from 'react';
import {
  useLocation, useNavigate, useSearchParams
} from 'react-router-dom';
import {
  useTranslation
} from 'react-i18next';

import {
  GroupSideBlockContainer
} from 'src/entities/GroupsRoute';
import {
  EmptyResultsMessage,
  ScrollContainer,
  Spinner,
} from 'src/shared/components';
import {
  QuestionsOverviewCard
} from 'src/widgets';
import {
  QuestionSortBy
} from 'src/shared/api/types';
import {
  useGetGroupQuestionSetsQuery
} from 'src/redux/openapi';
import {
  formatDateRange,
  questionsFilterBy,
  questionsSortBy,
  ROUTE,
} from 'src/shared/utils';
import {
  getGroupQuestionFilter,
  getQuestionSortBy,
} from 'src/widgets/QuestionsFilter/utils';
import {
  useTypedSelector
} from 'src/redux';
import {
  languageSelectors
} from 'src/redux/language';
import {
  useCustomInfiniteScroll, useDebouncedValue
} from 'src/shared/hooks';

import {
  FilterBy, SortBy
} from '../Questions';

interface GroupQuestionBlockProps {
  groupId: string;
  isAdmin: boolean;
  isDeactivated?: boolean;
  canOnboardingText?: boolean;
}

export const GroupQuestionsBlock: FC<GroupQuestionBlockProps> = ({
  groupId: id,
  isAdmin,
  isDeactivated,
  canOnboardingText,
}) => {
  const [searchQuestion, setSearchQuestion] = useState('');
  const [searchParams] = useSearchParams();
  const [questionSetsPage, setQuestionSetsPage] = useState(1);

  const debouncedQuery = useDebouncedValue(
    searchQuestion,
    500
  );

  const {
    t
  } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();

  const filters = useMemo(
    () => {
      const sortParam = searchParams.get(questionsSortBy) as SortBy;
      const filterParam = searchParams.get(questionsFilterBy) as FilterBy;

      const sortByResult = getQuestionSortBy<QuestionSortBy>(
        sortParam || SortBy.Newest,
      );

      const filterByResult = filterParam
        ? getGroupQuestionFilter(filterParam)
        : {};

      return {
        ...sortByResult,
        ...filterByResult,
      };
    },
    [searchParams]
  );

  useEffect(
    () => {
      setQuestionSetsPage(1);
    },
    [debouncedQuery, filters]
  );

  const {
    data: questionSetsData,
    isLoading: isQuestionSetsLoading,
    isFetching: isQuestionSetsFetching,
    isError,
  } = useGetGroupQuestionSetsQuery(
    {
      id,
      filters: {
        ...filters,
        query: debouncedQuery,
      },
      limit: 10,
      page: questionSetsPage,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    total: totalSets, questionSets
  } = useMemo(
    () => questionSetsData?.data || {
      total: 0,
      questionSets: [],
    },
    [questionSetsData],
  );

  const {
    sentryRef,
    allItems: visibleQuestionSets,
    displayLoader,
  } = useCustomInfiniteScroll({
    total: totalSets,
    currentItems: questionSets,
    isFetching: isQuestionSetsFetching,
    isLoading: isQuestionSetsLoading,
    isError,
    currentPage: questionSetsPage,
    setCurrentPage: setQuestionSetsPage,
  });

  const handleSearch = (value: string) => {
    setSearchQuestion(value);
  };

  const toggleEditing = () => {
    if (!isDeactivated) {
      navigate(`${location.pathname}/${ROUTE.EDIT_QUESTIONS}`);
    }
  };

  const language = useTypedSelector(languageSelectors.getLanguage);

  return (
    <GroupSideBlockContainer
      isMemberBlock={false}
      searchValue={debouncedQuery}
      handleOpenEdit={toggleEditing}
      handleSetSearchValue={handleSearch}
      title={t('common.questions')}
      isAdmin={isAdmin}
      isDeactivated={isDeactivated}
    >
      <ScrollContainer className="grow">
        {isQuestionSetsLoading ? (
          <Spinner size={24} />
        ) : (
          <>
            {!visibleQuestionSets?.length && (
              <EmptyResultsMessage
                results={t('common.questions').toLowerCase()}
              />
            )}

            {visibleQuestionSets.map(
              ({
                id: questionID,
                title: questionTitle,
                startDate,
                endDate,
                commentCount,
                questionCount,
                participantsCount,
                forecastCount,
                groupStatus,
              }) => {
                const dates = formatDateRange({
                  start: startDate,
                  end: endDate,
                  language,
                });

                return (
                  <QuestionsOverviewCard
                    key={questionID}
                    title={questionTitle}
                    dates={dates}
                    participantsNumber={participantsCount}
                    commentsPerDay={commentCount}
                    activeMembersPerDay={forecastCount}
                    questionSetId={questionID}
                    questionCount={questionCount}
                    status={groupStatus}
                    id={id}
                    filters={filters}
                    searchQuestion={debouncedQuery}
                    isAdmin={isAdmin}
                    canOnboardingText={canOnboardingText}
                  />
                );
              },
            )}

            {displayLoader && (
              <div ref={sentryRef}>
                <Spinner size={24} />
              </div>
            )}
          </>
        )}
      </ScrollContainer>
    </GroupSideBlockContainer>
  );
};
