import {
  useState, useRef
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  successfulToast
} from 'src/shared/components';
import {
  usePostGroupQuestionsMutation,
  OrgToDeactivateQuestions,
  usePatchGroupQuestionsDeactivationMutation,
} from 'src/redux/openapi';
import {
  areAllArraysInObjectEmpty,
  showWarningFromServer,
} from 'src/shared/utils';

import {
  CachedSelectedItem, ItemToDeactivateAll
} from '../types';
import {
  getMergedGroups, getSelectedIds
} from '../utils';

export const useSaveEditQuestionsAllResult = () => {
  const [isSavingInProcess, setIsSavingInProcess] = useState(false);

  const [deactivationList, setDeactivationList] = useState<OrgToDeactivateQuestions | null>(null);

  const resolveRef = useRef<((value: boolean) => void) | null>(null);

  const {
    t
  } = useTranslation();

  const [postApiGroupsByIdQuestions] = usePostGroupQuestionsMutation();

  const [getGroupListToDeactivate] = usePatchGroupQuestionsDeactivationMutation();

  const waitToDeactivationConfirmation = () => {
    return new Promise((resolve) => {
      resolveRef.current = resolve;
    });
  };

  const handleProceed = (shouldProceed = false) => {
    if (resolveRef.current) {
      resolveRef.current(shouldProceed);
      resolveRef.current = null;
    }
  };

  const clearDeactivationList = () => {
    setDeactivationList(null);
  };

  const checkQuestionsToDeactivate = async (
    allSelectedItems: CachedSelectedItem[],
  ) => {
    const listToDeactivateAll: ItemToDeactivateAll[] = [];

    const promises = allSelectedItems.map(async (item) => {
      const selectedList = getSelectedIds(item.selectedItems);

      const listToDeactivate = await getGroupListToDeactivate({
        id: item.id,
        updateGroupQuestionsBodySchema: {
          questionIds: selectedList,
        },
      }).unwrap();

      if (!listToDeactivate.data) {
        return;
      }

      const isItemsToDeactivate = !areAllArraysInObjectEmpty(
        listToDeactivate.data as Record<string, unknown>,
      );

      if (!isItemsToDeactivate) {
        return;
      }

      listToDeactivateAll.push({
        ...listToDeactivate.data,
        groupName: item.name,
      });
    });

    await Promise.all(promises);

    const mergedResults = getMergedGroups(listToDeactivateAll);

    if (!mergedResults.length) {
      return null;
    }

    return mergedResults;
  };

  const saveGroupQuestions = async (id: string, questionIds: string[]) => {
    await postApiGroupsByIdQuestions({
      id,
      updateGroupQuestionsBodySchema: {
        questionIds,
      },
    }).unwrap();
  };

  const handleSave = async (allSelectedItems: CachedSelectedItem[]) => {
    const promises = allSelectedItems.map(({
      selectedItems, id
    }) => {
      const selectedList = getSelectedIds(selectedItems);

      return saveGroupQuestions(
        id,
        selectedList
      );
    });

    await Promise.all(promises);

    successfulToast(t('group.questionsUpdatedSuccessfully'));
  };

  const onSave = async (selectedItems: CachedSelectedItem[]) => {
    try {
      setIsSavingInProcess(true);

      const deactivatedList = await checkQuestionsToDeactivate(selectedItems);

      if (!deactivatedList) {
        await handleSave(selectedItems);

        return;
      }

      setIsSavingInProcess(false);

      setDeactivationList(deactivatedList);

      const confirmResult = await waitToDeactivationConfirmation();

      if (confirmResult) {
        setIsSavingInProcess(true);
        await handleSave(selectedItems);
        clearDeactivationList();
      }
    } catch (error) {
      showWarningFromServer(error);
    } finally {
      setIsSavingInProcess(false);
    }
  };

  return {
    isSavingInProcess,
    onSave,
    deactivationList,
    handleProceed,
    clearDeactivationList,
  };
};
