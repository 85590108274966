import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MainContainer = styled.div`
  ${tw`p-11px rounded border border-gray94 flex-1`}
`;

export const Title = styled.p`
  ${tw`text-black font-semibold leading-6 mb-1`}
`;

export const DescriptionContainer = styled.div`
  ${tw`flex gap-3 items-center mb-3`}
`;

export const DescriptionItem = styled.div`
  ${tw`flex gap-1 items-center text-xs leading-5`}
`;

export const Circle = styled.div<{ $color: string; $size?: number }>`
  ${tw`rounded-full transition-all duration-700 ease-in-out`}

  ${({
    $color, $size = 8
  }) => `
    background-color: ${$color};
    width: ${$size}px;
    height: ${$size}px;
  `}
`;

export const ChartContainer = styled.div`
  ${tw`flex justify-center`}
`;

export const BigCircleContent = styled.div`
  ${tw`relative flex flex-col pt-3 pb-1 items-center justify-between h-full text-13-21 font-medium`}
`;

export const UserValueContainer = styled.div`
  ${tw`z-2 flex flex-col items-center font-semibold leading-6`}
`;

export const UserValue = styled.p<{ $isDark: boolean }>`
  ${({
    $isDark
  }) => ($isDark ? tw`text-gray7` : tw`text-white`)}
`;

export const TotalValue = styled.p`
  ${tw`z-2`}
`;

export const SmallCircleContainer = styled.div`
  ${tw`absolute bottom-0`}
`;
