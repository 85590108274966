import {
  Commendation,
  CommendationType,
  ReplyComment,
  Comment,
} from 'src/redux/openapi';
import {
  BookIcon,
  CompassIcon,
  HeartIcon,
  TrendingUpIcon,
} from 'src/shared/icons';
import {
  isComment
} from 'src/shared/utils';

export const LIKES_CONFIG_MAP = [
  {
    type: CommendationType.LIKE,
    icon: HeartIcon,
    label: 'Like',
  },
  {
    type: CommendationType.RELEVANT,
    icon: CompassIcon,
    label: 'Relevant',
  },
  {
    type: CommendationType.INSIGHTFUL,
    icon: BookIcon,
    label: 'Deep info',
  },
  {
    type: CommendationType.INSPIRING,
    icon: TrendingUpIcon,
    label: 'Made me update estimate',
  },
];

export const getExtendedIcons = (
  currentType: Commendation | null,
  existedTypes: Commendation[] | null,
) => {
  if (!existedTypes) {
    return [];
  }

  return LIKES_CONFIG_MAP.filter(
    ({
      type
    }) => type !== currentType && existedTypes.includes(type),
  ).map(({
    icon, type
  }) => ({
    icon,
    id: type,
  }));
};

export const getMainIcon = (currentType: Commendation | null) => {
  if (!currentType) {
    return HeartIcon;
  }

  return (
    LIKES_CONFIG_MAP.find((commend) => commend.type === currentType)?.icon
    || HeartIcon
  );
};

export const getExtraCommentData = (comment: Comment | ReplyComment) => {
  if (isComment(comment)) {
    const {
      comments, commentsCount, question, forecast, estimatesDiff
    } = comment;

    return {
      comments,
      commentsCount,
      question,
      forecast,
      estimatesDiff,
    };
  }

  return {
    comments: null,
    commentsCount: 0,
    question: null,
    forecast: null,
    estimatesDiff: null,
  };
};

export const getCommentLikesData = (comment: Comment | ReplyComment) => {
  let allLikesCount;
  let currentUserCommendation = null;
  let currentCommendations = null;
  let isUserLike;

  if (isComment(comment)) {
    const {
      commendationsCount,
      userCommendation: currentCommendation,
      commendationTypes,
    } = comment;

    allLikesCount = commendationsCount;
    isUserLike = !!currentCommendation;
    currentUserCommendation = currentCommendation;
    currentCommendations = commendationTypes;
  } else {
    const {
      likes, isLiked: isCurrentLike
    } = comment;

    allLikesCount = likes;
    isUserLike = isCurrentLike;
  }

  return {
    likesCount: allLikesCount,
    userCommendation: currentUserCommendation,
    commendations: currentCommendations,
    isLiked: isUserLike,
  };
};
