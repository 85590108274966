import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const GroupContainer = styled.div`
  ${tw`bg-white px-6 py-4 rounded-md border border-gray94 shadow-card-shadow-default`}
`;
export const GroupHeaderContainer = styled.div`
  ${tw`flex justify-between gap-3 mb-4`}
`;
export const GroupHeaderLeftSide = styled.div`
  ${tw`flex gap-3`}
`;
export const GroupIsAdminContainer = styled.div`
  ${tw`flex items-center`}
`;
export const GroupHeaderRightSide = styled.div`
  ${tw`flex flex-col gap-1 items-end`}
`;

export const GroupDetailsButton = styled.button`
  ${tw`text-button-blue text-15-20 font-bold flex gap-1 items-center p-2 rounded-100px
  hover:text-accent80`}
`;

export const GroupTile = styled.div`
  ${tw`text-xl font-bold text-gray7`}
`;
export const PinContainer = styled.div`
  ${tw`px-2`}
`;

export const GroupDetailTitle = styled.div`
  ${tw`text-22-30 font-bold text-gray7`}
`;
export const GroupLocation = styled.div`
  ${tw`text-13-21 font-medium text-dim-gray`}
`;

export const CardQuestionContainer = styled.div<{ $isInvited?: boolean }>`
  ${tw`rounded`}

  ${({
    $isInvited
  }) => ($isInvited ? tw`bg-alice-blue p-2` : tw`bg-pale-blue px-4 py-3`)}
`;

export const CardStatisticsRow = styled.div`
  ${tw`flex justify-between mb-4`}
`;
export const CardStatisticsRowRight = styled.div`
  ${tw`flex gap-2.5 items-center`}
`;
export const CardStatistics = styled.div`
  ${tw`p-1`}
`;

export const InvitedEmailsBlock = styled.div`
  ${tw`flex justify-between items-center gap-4 pt-3 pb-2 border-b border-b-gray94`}
`;
export const InvitedEmailsList = styled.div`
  ${tw`flex gap-3 items-center`}
`;
export const InvitedEmailContainer = styled.div`
  ${tw`flex flex-col gap-1`}
`;
export const InviteEmail = styled.div<{
  $isTitle?: boolean;
  $isSmall?: boolean;
}>`
  ${tw`font-medium`}

  ${({
    $isTitle
  }) => ($isTitle ? tw`text-gray7` : tw`text-grey-dark-40`)}

  ${({
    $isSmall
  }) => ($isSmall ? tw`text-13-21` : tw`text-17-26`)}
`;

export const ModalRedSpan = styled.span`
  ${tw`text-red-label`}
`;

export const ModalLabelButton = styled.button`
  ${tw`text-15-20 font-medium text-grey-dark-40 mb-2 cursor-default`}
`;

export const ModalGroupedLabel = styled.label`
  ${tw`max-h-376px min-h-48px overflow-y-scroll flex flex-wrap gap-2 p-3 rounded border border-input-border-blue bg-alice-blue`}
`;

export const ModalLabel = styled.label`
  ${tw`block text-15-20 font-medium text-grey-dark-40 mb-2`}
`;

export const ModalTextarea = styled.textarea`
  ${tw`h-48 w-full px-4 py-2 rounded border border-gray94 bg-alice-blue outline-0`}

  resize: none;
`;

export const ModalEmailHookToInvite = styled.input<{
  $isFullWidth?: boolean;
  $isTransparent?: boolean;
}>`
  ${tw`px-3 py-0.5 rounded-123px block`}

  ${({
    $isFullWidth
  }) => ($isFullWidth ? tw`w-full` : tw`w-max`)}

  ${({
    $isTransparent
  }) => ($isTransparent ? tw`border-none outline-0 bg-alice-blue` : tw`bg-list-blue`)}
`;

export const ErrorMessage = styled.div`
  ${tw`text-13-21 font-medium text-red-text-error mt-1`}
`;

export const CardQuestionDates = styled.div`
  ${tw`text-xs leading-5 font-medium text-dim-gray`}
`;

export const CardQuestionTitle = styled.div<{ $isInvited?: boolean }>`
  ${tw`font-bold`}

  ${({
    $isInvited
  }) => ($isInvited ? tw`text-13-21 text-dark-gray mt-1` : tw`text-17-26 text-gray7`)}
`;

export const ModalSaveSpan = styled.span`
  ${tw`text-xl text-white font-bold`}
`;

export const EditQuestionTooltip = styled.p`
  ${tw`text-center max-w-128px`}
`;

export const SideBlockToolsContainer = styled.div`
  ${tw`flex gap-4`}
`;

export const SideBlockTitleContainer = styled.div`
  ${tw`flex gap-2 items-center`}
`;

export const SideBlockMainContainer = styled.div`
  ${tw`p-6 rounded bg-white h-full flex flex-col`}
`;

export const SideBlockContainer = styled.div<{ $isRelative: boolean }>`
  ${tw`h-full`}

  ${({
    $isRelative
  }) => $isRelative && tw`relative`}
`;

export const SideBlockInfoRow = styled.div`
  ${tw`flex justify-between gap-4 mb-6`}
`;
export const VisibleSetContainer = styled.div`
  ${tw`grid grid-cols-2 gap-4`}
`;
