import React, {
  FC, useState
} from 'react';
import ReactDatePicker from 'react-datepicker';
import i18next from 'i18next';

import {
  ClearableSelectFooter
} from 'src/shared/components';

import {
  CalendarHeader
} from './CalendarHeader';
import {
  TDate
} from './types';
import 'react-datepicker/dist/react-datepicker.css';
import './index.css';
import {
  getCorrectDayOfWeek
} from './utils';

interface DatePickerMenuProps {
  onApply: (date: TDate[] | null) => void;
  clearButtonTitle?: string;
  applyButtonTitle?: string;
  selectedDateRange?: TDate[] | null;
}

export const DatePickerMenu: FC<DatePickerMenuProps> = ({
  onApply: setSelectedValue,
  clearButtonTitle,
  applyButtonTitle,
  selectedDateRange,
}) => {
  const startDate = selectedDateRange?.length
    ? new Date(selectedDateRange[0] as Date)
    : null;

  const endDate = selectedDateRange?.length
    ? new Date(selectedDateRange[selectedDateRange.length - 1] as Date)
    : null;

  const [selectedStartDate, setSelectedStartDate] = useState<TDate>(startDate);

  const [selectedEndDate, setSelectedEndDate] = useState<TDate>(endDate);

  const onChange = (dates: TDate[]) => {
    const [start, end] = dates;
    setSelectedStartDate(start);
    setSelectedEndDate(end);
  };

  const onClear = () => {
    setSelectedStartDate(null);
    setSelectedEndDate(null);
    setSelectedValue(null);
  };

  const onApply = () => {
    setSelectedValue([selectedStartDate, selectedEndDate]);
  };

  return (
    <ReactDatePicker
      open
      locale={i18next.language}
      dayClassName={() => 'datepicker-day'}
      popperClassName="datepicker-popper"
      popperPlacement="top-end"
      selected={selectedStartDate}
      onChange={onChange}
      startDate={selectedStartDate}
      endDate={selectedEndDate}
      selectsRange
      calendarStartDay={1}
      showPopperArrow={false}
      formatWeekDay={getCorrectDayOfWeek}
      renderDayContents={(dayOfMonth) => <p>{dayOfMonth}</p>}
      renderCustomHeader={({
        date, decreaseMonth, increaseMonth
      }) => (
        <CalendarHeader
          date={date}
          decreaseMonth={decreaseMonth}
          increaseMonth={increaseMonth}
        />
      )}
    >
      <ClearableSelectFooter
        onClear={onClear}
        onApply={onApply}
        isApplyDisabled={!selectedStartDate}
        clearButtonTitle={clearButtonTitle}
        applyButtonTitle={applyButtonTitle}
      />
    </ReactDatePicker>
  );
};
