import React, {
  FC, ReactNode
} from 'react';

import {
  themeColors
} from 'src/shared/utils';

import {
  Spinner
} from '../Spinner';

import {
  CheckboxType, SelectedState
} from './types';
import {
  checkMarksMap
} from './utils';
import * as Style from './Checkbox.styles';

interface CheckboxProps {
  type: `${CheckboxType}`;
  color?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  isChecked?: boolean;
  size?: number;
  label?: ReactNode;
  isLoading?: boolean;
  disabled?: boolean;
}

export const Checkbox: FC<CheckboxProps> = ({
  type,
  color = themeColors.gray7,
  onChange,
  className,
  isChecked,
  size = 24,
  label,
  isLoading,
  disabled,
}) => {
  const state = isChecked && !isLoading ? SelectedState.CHECKED : SelectedState.UNCHECKED;

  const CheckMark = checkMarksMap[type][state];

  return (
    <Style.CheckboxLabel
      $type={type}
      className={className}
    >
      <input
        type="checkbox"
        className="hidden"
        checked={isChecked}
        onChange={onChange}
        disabled={disabled || isLoading}
        readOnly={!onChange}
      />

      {isLoading ? (
        <Style.LoadingCheckboxWrapper $size={size}>
          <CheckMark
            color={color}
            width={size}
            height={size}
            className="absolute"
          />

          <Spinner
            size={size - 10}
            color={color}
          />
        </Style.LoadingCheckboxWrapper>
      ) : (
        <CheckMark
          color={color}
          width={size}
          height={size}
          className="shrink-0"
        />
      )}

      {label}
    </Style.CheckboxLabel>
  );
};
