import React, {
  FC, useState
} from 'react';
import {
  useNavigate
} from 'react-router-dom';
import {
  useTranslation
} from 'react-i18next';

import {
  ROUTE, getTimeAgo
} from 'src/shared/utils';
import {
  NotificationContainer
} from 'src/entities/NotificationsRoute';
import {
  GroupStats
} from 'src/redux/openapi';

import {
  NotificationGroupCard
} from '../NotificationGroupCard';
import {
  ConfirmButton
} from '../ConfirmButton';
import {
  ShowDetailsButton
} from '../ShowDetailsButton/ShowDetailsButton';

import * as Style from './Notifications.styles';

interface OrgSetupNotificationProps {
  createdAt: string;
  isNew: boolean;
  groupName: string;
  organizationId: string | null;
  getHighlightedText: (text: string) => (string | JSX.Element)[];
  notificationId: string;
}

export const OrgSetupNotification: FC<OrgSetupNotificationProps> = ({
  createdAt,
  isNew,
  groupName,
  getHighlightedText,
  organizationId,
  notificationId,
}) => {
  const [groupStats, setGroupStats] = useState<GroupStats | null>(null);

  const navigate = useNavigate();

  const handleSetUpOrganizationClick = () => {
    if (!organizationId) {
      return;
    }

    navigate(
      `/${ROUTE.ORGANISATION}/${organizationId}/${ROUTE.SETUP_ORGANISATION}`,
    );
  };

  const {
    t
  } = useTranslation();

  return (
    <NotificationContainer $isNew={isNew}>
      <Style.Description>
        <Style.NotificationHeader>
          <Style.NotificationDate>
            {`${getTimeAgo(createdAt)} ${t('date.ago')}`}
          </Style.NotificationDate>
        </Style.NotificationHeader>

        <Style.NotificationText>
          {t('organisation.approvedRequest')}
        </Style.NotificationText>
      </Style.Description>

      {!groupStats ? (
        <ShowDetailsButton
          notificationId={notificationId}
          onDetailsLoaded={setGroupStats}
        />
      ) : (
        <>
          <NotificationGroupCard
            groupName={getHighlightedText(groupName)}
            groupStats={groupStats}
          />

          <Style.ButtonsContainer className="mt-3">
            <ConfirmButton
              variant="big-blue"
              type="button"
              onClick={handleSetUpOrganizationClick}
            >
              {t('organisation.setUp')}
            </ConfirmButton>
          </Style.ButtonsContainer>
        </>
      )}
    </NotificationContainer>
  );
};
