import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MainFormContainer = styled.form`
  ${tw`flex flex-col justify-center items-center gap-4 md:gap-8`}
`;

export const FieldsContainer = styled.div`
  ${tw`flex flex-row gap-1 md:gap-3 mx-auto`}
`;

export const Divider = styled.div`
  ${tw`w-4 md:w-8 h-0.5 rounded-100px bg-gray94 my-auto`}
`;

export const FooterContainer = styled.div`
  ${tw`flex flex-row gap-2 font-['DM_Sans'] font-normal text-base`}
`;

export const ResendButton = styled.button<{ $isResendCodeClicked: boolean }>`
  ${tw`flex gap-1 underline`}

  ${({
    $isResendCodeClicked
  }) => ($isResendCodeClicked
    ? tw`text-grey-dark-40 cursor-default`
    : tw`text-button-blue`)}
`;

export const SubmitButtonContainer = styled.div<{ $width?: number }>`
  ${({
    $width
  }) => $width
    && `
    width: ${$width}px;
  `}
`;
