import React, {
  FC, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  CloseIcon
} from 'src/shared/icons';
import {
  Organization
} from 'src/redux/openapi';
import {
  ReVerification, Whitelist
} from 'src/widgets';
import {
  CircleButton
} from 'src/shared/components';

import * as Style from './OrganizationSettingsModal.style';
import {
  OrgGeneralSettingsTab
} from './OrgGeneralSettingsTab';
import {
  BOTTOM_SPACE,
  MAX_SCREEN,
  MODAL_HEADER_HEIGHT,
  SIDE_SPACE,
  TOP_SPACE,
} from './constants';

enum SettingsTabs {
  General_Settings = 'GeneralSettings',
  Verification = 'Verification',
  Whitelisting = 'Whitelisting',
}

interface OrganizationSettingsModalProps {
  handleCloseModal: () => void;
  organization: Organization;
}

const settingsVariants = [
  {
    label: SettingsTabs.General_Settings,
  },
  {
    label: SettingsTabs.Verification,
  },
  {
    label: SettingsTabs.Whitelisting,
  },
];

export const OrganizationSettingsModal: FC<OrganizationSettingsModalProps> = ({
  handleCloseModal,
  organization,
}) => {
  const {
    t
  } = useTranslation();

  const [isOpenThemeModal, setIsOpenThemeModal] = useState(false);

  const openThemeModalHandler = () => {
    setIsOpenThemeModal(true);
  };

  const closeThemeModalHandler = () => {
    setIsOpenThemeModal(false);
  };

  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  const rightSpacing = windowWidth > MAX_SCREEN
    ? (windowWidth - MAX_SCREEN) / 2 + SIDE_SPACE
    : SIDE_SPACE;

  const orgSettingsHeight = windowHeight - TOP_SPACE - BOTTOM_SPACE;

  const [activeSettingsTab, setActiveSettingsTab] = useState(
    SettingsTabs.General_Settings,
  );

  return (
    <Style.ModalContainer
      $right={rightSpacing}
      $height={orgSettingsHeight}
    >
      <Style.ModalHeader>
        <Style.ModalHeaderTitle>
          {t('organisation.organisationDetails')}
        </Style.ModalHeaderTitle>

        <CircleButton onClick={handleCloseModal}>
          <CloseIcon className="w-6 h-6 text-dark-gray" />
        </CircleButton>
      </Style.ModalHeader>

      <Style.ModalTabsContainer>
        <Style.ModalTabsRow>
          {settingsVariants.map(({
            label
          }) => (
            <Style.ModalTabButton
              key={label}
              type="button"
              onClick={() => setActiveSettingsTab(label)}
            >
              <Style.ModalTabLabel $isActive={label === activeSettingsTab}>
                {t(`organisation.${label}`)}
              </Style.ModalTabLabel>

              <Style.ModalTabBottomLine
                $isActive={label === activeSettingsTab}
              />
            </Style.ModalTabButton>
          ))}
        </Style.ModalTabsRow>
      </Style.ModalTabsContainer>

      {activeSettingsTab === SettingsTabs.General_Settings && (
        <OrgGeneralSettingsTab
          name={organization.name}
          id={organization.id}
          logoUrl={organization.logoUrl}
          containerHeight={orgSettingsHeight - MODAL_HEADER_HEIGHT}
          isOpenModal={isOpenThemeModal}
          closeModalHandler={closeThemeModalHandler}
          openModalHandler={openThemeModalHandler}
        />
      )}

      {activeSettingsTab === SettingsTabs.Verification && (
        <ReVerification id={organization.id} />
      )}

      {activeSettingsTab === SettingsTabs.Whitelisting && (
        <Whitelist organizationId={organization.id} />
      )}
    </Style.ModalContainer>
  );
};
