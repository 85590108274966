import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const ButtonContainer = styled.button<{
  $isActive: boolean;
  $withMenuOpened: boolean;
}>`
  ${tw`
    flex items-center gap-1 border rounded-100px
    px-2 py-1 text-15-20 font-medium bg-white transition z-10
  `}

  ${({
    $isActive
  }) => ($isActive
    ? tw`border-button-blue text-button-blue hover:bg-alice-blue`
    : tw`border-gray90 text-dark-gray hover:bg-cultured`)}

  ${({
    $withMenuOpened, $isActive
  }) => $withMenuOpened && ($isActive ? tw`bg-alice-blue` : tw`bg-cultured`)}
`;

export const IconsContainer = styled.div`
  ${tw`flex !bg-inherit`}
`;

export const IconContainer = styled.div<{
  $zIndex?: number;
  $leftShift?: number;
  $isActive?: boolean;
}>`
  ${tw`p-0.5 rounded-full !bg-inherit`}

  ${({
    $zIndex
  }) => $zIndex && `z-index: ${$zIndex};`}

  ${({
    $leftShift
  }) => $leftShift && `margin-left: -${$leftShift}px;`}
`;
