import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const TableHead = styled.thead`
  ${tw`sticky top-0 bg-white`}
`;

export const TableWrapper = styled.div`
  ${tw`overflow-auto`}
`;

export const TableContainer = styled.table`
  ${tw`w-full grow-0 relative`}
`;

export const HeaderRow = styled.tr`
  ${tw`border-b border-b-gray94`}
`;

export const HeaderCell = styled.th`
  ${tw`text-13-21 text-dim-gray font-bold px-3 py-2 text-left`}

  text-wrap: nowrap;
`;
