import tw from 'twin.macro';
import {
  styled
} from 'styled-components';
import {
  Link
} from 'react-router-dom';

export const Breadcrumb = styled(Link)<{ $isLast: boolean }>`
  ${tw`font-medium text-13-16 md:text-15-20 text-button-blue`}

  ${({
    $isLast
  }) => $isLast && tw`text-dim-gray`}
`;

export const Divider = styled.span`
  ${tw`text-light-blue`}
`;
