import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  Switcher
} from 'src/shared/components';

import * as Style from '../CommentsFilterContext.styles';

interface MyCommentsOnlySectionProps {
  isMyOnly: boolean;
  toggleOnlyMy: () => void;
}

export const MyCommentsOnlySection: FC<MyCommentsOnlySectionProps> = ({
  isMyOnly,
  toggleOnlyMy,
}) => {
  const {
    t
  } = useTranslation();

  return (
    <Style.LabelMyOnly>
      <Style.LabelDatesTitle>
        {t('filters.myCommentsOnly')}
      </Style.LabelDatesTitle>

      <Switcher
        isActive={isMyOnly}
        onChange={toggleOnlyMy}
      />
    </Style.LabelMyOnly>
  );
};
