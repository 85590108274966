import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  PeopleIcon
} from 'src/shared/icons';

interface LoginSocialsInviteProps {
  groupName: string;
  email: string | null;
}

export const LoginSocialsInvite: FC<LoginSocialsInviteProps> = ({
  groupName,
  email,
}) => {
  const {
    t
  } = useTranslation();

  return (
    <div className="flex flex-row gap-2 justify-start border border-input-blue rounded-xl p-2 w-full">
      <div className="flex justify-center items-center p-4 rounded-full bg-alice-blue">
        <PeopleIcon />
      </div>

      {email ? (
        <div className="flex flex-col gap-1">
          <div className="text-base font-semibold text-gray7">
            {`${t('common.group')} ${groupName} ${t(
              'loginSocials.invitesToJoin',
            )}`}
          </div>

          <div className="text-dim-gray font-medium text-xs leading-5">
            {`${t('loginSocials.YourEmail')} ${email}`}
          </div>
        </div>
      ) : (
        <div className="text-base font-semibold text-gray7 my-auto">
          {groupName}
        </div>
      )}
    </div>
  );
};
