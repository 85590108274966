export enum CreateOrganizationFormField {
  OrganizationName = 'organisationName',
  Domain = 'domain',
  MainAdmin = 'mainAdministrator',
  FirstSubAdmin = 'firstSubAdmin',
  SecondSubAdmin = 'secondSubAdmin',
}

export type CreateOrganizationAdmin = {
  email: string;
  role: 'SUPERADMIN';
};
