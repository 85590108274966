import React, {
  FC, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  Avatar,
  ClearableSelectFooter,
  ScrollContainer,
} from 'src/shared/components';
import {
  CheckBoxFalseIcon,
  CheckBoxMinusIcon,
  CheckBoxTrueIcon,
} from 'src/shared/icons';
import {
  TChildCheckbox
} from 'src/widgets/NestedSelectMenu';

interface QuestionsSelectorProps {
  onApply: (value: string[]) => void;
  groups: TChildCheckbox[];
  selectedGroups: string[];
}

export const GroupSelector: FC<QuestionsSelectorProps> = ({
  onApply,
  groups,
  selectedGroups,
}) => {
  const [selectedValues, setSelectedValues] = useState<string[]>(selectedGroups);

  const isAllSelected = selectedValues.length === groups.length;

  const {
    t
  } = useTranslation();

  const onClear = () => {
    setSelectedValues([]);
    onApply([]);
  };

  const onSelect = (id: string) => {
    setSelectedValues((prevValues) => {
      const isSelected = prevValues.includes(id);

      return isSelected
        ? prevValues.filter((value) => value !== id)
        : [...prevValues, id];
    });
  };

  const selectAll = () => {
    if (isAllSelected) {
      setSelectedValues([]);

      return;
    }

    const preparedGroups = groups.map((group) => group.id);
    setSelectedValues(preparedGroups);
  };

  return (
    <div className="w-96 mx-2 p-2 flex flex-col gap-2">
      <ScrollContainer
        edgeHeight={50}
        className="flex flex-col gap-2 max-h-410px"
      >
        <div className="flex items-center gap-2 py-3">
          <p className="grow font-medium leading-6">{t('common.allGroups')}</p>

          <button
            type="button"
            onClick={selectAll}
          >
            {!isAllSelected ? (
              <CheckBoxFalseIcon className="w-6 h-6" />
            ) : (
              <CheckBoxMinusIcon className="w-6 h-6" />
            )}
          </button>
        </div>

        {groups.map(({
          id, label, iconSrc
        }) => (
          <div
            className="flex items-center gap-2 py-3"
            key={id}
          >
            <Avatar
              src={iconSrc}
              userName={label}
              isLogo
              containerClassName="border border-gray-whisper !rounded-full"
            />

            <p className="grow font-medium leading-6">{label}</p>

            <button
              type="button"
              onClick={() => onSelect(id)}
            >
              {!selectedValues.includes(id) ? (
                <CheckBoxFalseIcon className="w-6 h-6" />
              ) : (
                <CheckBoxTrueIcon className="w-6 h-6" />
              )}
            </button>
          </div>
        ))}
      </ScrollContainer>

      <ClearableSelectFooter
        onApply={() => onApply(selectedValues)}
        onClear={onClear}
      />
    </div>
  );
};
