import React, {
  FC
} from 'react';

import {
  useTypedDispatch
} from 'src/redux';
import {
  removeUserFromGroup
} from 'src/redux/organizations';
import {
  CloseIcon
} from 'src/shared/icons';

import * as Style from './GroupCard.styles';

interface GroupUserInListProps {
  groupName: string;
  email: string;
  index: number;
}

export const GroupUserInList: FC<GroupUserInListProps> = ({
  groupName,
  email,
  index,
}) => {
  const dispatch = useTypedDispatch();

  const userIndex = index + 1;

  const handleRemoveUser = () => dispatch(
    removeUserFromGroup({
      group: groupName,
      email,
    }),
  );

  return (
    <Style.UserListItem $index={index}>
      <Style.UserIndex>{`${userIndex}.`}</Style.UserIndex>

      <Style.UserEmail>{email}</Style.UserEmail>

      <button
        type="button"
        onClick={handleRemoveUser}
      >
        <CloseIcon className="text-dim-gray" />
      </button>
    </Style.UserListItem>
  );
};
