import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const CursorContainer = styled.div<{ $coordX: number; $coordY: number }>`
  ${tw`fixed flex items-center justify-center z-9999 w-max bg-button-blue text-white rounded-full text-center pointer-events-none text-15-20 font-medium px-2`}

  ${({
    $coordX, $coordY
  }) => `
    top: ${$coordY}px;
    left: ${$coordX}px;
  `}
`;
