import z from 'zod';

import {
  InputMessages
} from '../../pages/Login/types';

export const emailValidation = z
  .string()
  .email({
    message: InputMessages.ENTER_VALID_EMAIL,
  })
  .min(
    1,
    {
      message: InputMessages.ENTER_MAIL,
    }
  );

export const optionalEmailValidation = z
  .string()
  .email({
    message: InputMessages.ENTER_VALID_EMAIL,
  })
  .or(z.literal(''))
  .optional();

export const mailInListValidation = z.string().email({
  message: InputMessages.INVALID_EMAIL,
});

export const stringValidation = z.string();

export const orgNameValidation = z.string().min(
  1,
  {
    message: InputMessages.ORG_NAME,
  }
);
export const orgDescriptionValidation = z.string().min(
  1,
  {
    message: InputMessages.ORG_DESCRIPTION,
  }
);
export const groupNameValidation = z.string().min(
  1,
  {
    message: InputMessages.GROUP_NAME,
  }
);
export const groupLocationValidation = z.string().min(
  1,
  {
    message: InputMessages.GROUP_LOCATION,
  }
);
export const commentLengthValidation = z.string().min(
  1,
  {
    message: InputMessages.COMMENT_EMPTY,
  }
);
export const groupQuestionsValidation = z.array(
  z.object({
    id: z.string(),
    selectedQuestionsId: z.array(z.string()),
  }),
);

export const emailsSchema = z.object({
  emails: z.array(
    z.object({
      address: mailInListValidation,
    }),
  ),
});

export const emailsUniqueSchema = z.object({
  emails: z
    .array(
      z.object({
        address: mailInListValidation,
      }),
    )
    .refine(
      (items) => new Set(items.map(({
        address
      }) => address)).size === items.length,
      {
        message: InputMessages.UNIQUE_EMAILS,
      },
    ),
});

export const domainValidation = z
  .string()
  .url({
    message: InputMessages.VALID_DOMAIN,
  })
  .or(z.literal(''))
  .optional();
