import React, {
  FC, KeyboardEvent, useEffect, useMemo, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import {
  Controller, useForm
} from 'react-hook-form';
import {
  Link
} from 'react-router-dom';
import {
  zodResolver
} from '@hookform/resolvers/zod';

import {
  CheckmarkThinIcon
} from 'src/shared/icons';
import {
  ROUTE, showWarningFromServer
} from 'src/shared/utils';
import {
  MenuField
} from 'src/pages/Login/types';
import {
  Button,
  PopoverMenu,
  Spinner,
  successfulToast,
} from 'src/shared/components';
import {
  useGetInputValidation
} from 'src/shared/hooks';
import {
  useTypedSelector
} from 'src/redux';
import {
  userSelectors
} from 'src/redux/user';
import {
  useGetUserCurrentEmailsQuery,
  usePatchUserCurrentMutation,
} from 'src/redux/openapi';

import {
  EditPasswordModal
} from '../EditProfileMenu/EditPasswordModal';
import {
  RecoveryCodeModal
} from '../RecoveryCodeModal';

import * as Style from './SecurityTab.style';

interface SecurityTabProps {
  onModalToggle: (isOpen: boolean) => void;
}

export const SecurityTab: FC<SecurityTabProps> = ({
  onModalToggle
}) => {
  const {
    t
  } = useTranslation();

  const {
    name,
    email,
    fullName: userFullName,
  } = useTypedSelector(userSelectors.user);

  const fullNameStateValue = userFullName || '';

  const {
    editProfileSecuritySchema
  } = useGetInputValidation();

  const {
    handleSubmit,
    register,
    reset,
    watch,
    control,
    setValue,
    formState: {
      errors
    },
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      [MenuField.MainEmail]: email,
      [MenuField.Username]: name || '',
      [MenuField.FullName]: fullNameStateValue,
    },
    resolver: zodResolver(editProfileSecuritySchema),
  });

  const {
    data: userEmailsData
  } = useGetUserCurrentEmailsQuery(
    undefined,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const availableEmails = useMemo(
    () => userEmailsData?.data.emails || [],
    [userEmailsData?.data.emails],
  );

  const onEnterClick = ({
    key,
    currentTarget,
  }: KeyboardEvent<HTMLInputElement>) => {
    if (key === 'Enter') {
      currentTarget.blur();
    }
  };

  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [isRecoveryCodeModalOpen, setIsRecoveryCodeModalOpen] = useState(false);

  const togglePasswordModal = () => setIsPasswordModalOpen((prev) => !prev);

  const toggleRecoveryCodeModal = () => {
    setIsRecoveryCodeModalOpen((prev) => !prev);
    togglePasswordModal();
  };

  const closeRecoveryCodeModal = () => setIsRecoveryCodeModalOpen(false);

  useEffect(
    () => {
      const isSomeModalOpen = isPasswordModalOpen || isRecoveryCodeModalOpen;

      onModalToggle(isSomeModalOpen);
    },
    [isPasswordModalOpen, isRecoveryCodeModalOpen]
  );

  const fullNameValue = watch(MenuField.FullName);
  const nameValue = watch(MenuField.Username);
  const emailValue = watch(MenuField.MainEmail);

  const needSaveChanges = fullNameStateValue !== fullNameValue
    || nameValue !== name
    || email !== emailValue;

  const [updateUserData, {
    isLoading: isSubmitting
  }] = usePatchUserCurrentMutation();

  const onSubmit = handleSubmit(async ({
    mainEmail, username, fullName
  }) => {
    const trimmedMail = mainEmail.trim() || undefined;
    const trimmedUsername = username.trim() || undefined;
    const trimmedFullName = fullName.trim() || undefined;

    try {
      const response = await updateUserData({
        updateCurrentUserBodySchema: {
          email: trimmedMail,
          name: trimmedUsername,
          fullName: trimmedFullName,
        },
      }).unwrap();

      successfulToast(response.message);

      const {
        fullName: updatedFullName,
        email: updatedEmail,
        name: updatedUsername,
      } = response.data.user;

      setValue(
        MenuField.FullName,
        updatedFullName || ''
      );

      setValue(
        MenuField.MainEmail,
        updatedEmail
      );

      setValue(
        MenuField.Username,
        updatedUsername
      );
    } catch (error) {
      showWarningFromServer(error);
    }
  });

  return (
    <>
      <Style.ContentContainer>
        <Style.SectionContainer>
          <Style.SectionTitle>{t('editAccount.security')}</Style.SectionTitle>

          <Style.SectionContent>
            <Controller
              name={MenuField.MainEmail}
              control={control}
              render={({
                field
              }) => {
                return (
                  <PopoverMenu
                    placement="bottom-center"
                    customShift={16}
                    renderCustomButton={({
                      toggleMenu,
                      triggerProps,
                      isOpen,
                    }) => (
                      <Style.InputLabel
                        {...triggerProps}
                        onFocus={() => !isOpen && toggleMenu()}
                        onBlur={() => isOpen && toggleMenu()}
                      >
                        {t('common.email')}

                        <Style.Input
                          placeholder={t('editAccount.mainEmail')}
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          onKeyDown={onEnterClick}
                        />

                        {errors[MenuField.MainEmail]?.message && (
                          <Style.ErrorMessage>
                            {errors[MenuField.MainEmail]?.message}
                          </Style.ErrorMessage>
                        )}
                      </Style.InputLabel>
                    )}
                  >
                    <Style.EmailsContainer>
                      {!availableEmails.some(
                        ({
                          email: availableEmail
                        }) => availableEmail === field.value,
                      ) && (
                        <Style.AdditionalEmail $isActive>
                          {field.value}

                          <CheckmarkThinIcon className="text-button-blue" />
                        </Style.AdditionalEmail>
                      )}

                      {availableEmails.map(({
                        email: availableEmail
                      }) => (
                        <Style.AdditionalEmail
                          key={availableEmail}
                          $isActive={field.value === availableEmail}
                          onClick={() => field.onChange(availableEmail)}
                        >
                          {availableEmail}

                          {field.value === availableEmail && (
                          <CheckmarkThinIcon className="text-button-blue" />
                          )}
                        </Style.AdditionalEmail>
                      ))}
                    </Style.EmailsContainer>
                  </PopoverMenu>
                );
              }}
            />

            <Style.FlexRowContainer $alignBase>
              <Style.InputLabel>
                {t('common.username')}

                <Style.Input
                  placeholder={t('common.username')}
                  {...register(MenuField.Username)}
                />

                {errors[MenuField.Username]?.message ? (
                  <Style.ErrorMessage>
                    {errors[MenuField.Username]?.message}
                  </Style.ErrorMessage>
                ) : (
                  <Style.InputHint>
                    {t('editAccount.usernameHint')}
                  </Style.InputHint>
                )}
              </Style.InputLabel>
            </Style.FlexRowContainer>

            <Style.FlexRowContainer>
              <Style.PasswordContent>
                <Style.PasswordContent>
                  {t('common.password')}
                </Style.PasswordContent>

                <Style.PasswordDescription>
                  {t('editAccount.updatePassword')}
                </Style.PasswordDescription>
              </Style.PasswordContent>

              <Style.ActionButton onClick={togglePasswordModal}>
                {t('editAccount.editPassword')}
              </Style.ActionButton>
            </Style.FlexRowContainer>

            <Style.FlexRowContainer $alignBase>
              <Style.InputLabel>
                {t('common.fullName')}

                <Style.Input
                  placeholder={t('common.fullNamePlaceholder')}
                  {...register(MenuField.FullName)}
                />

                {errors[MenuField.FullName]?.message ? (
                  <Style.ErrorMessage>
                    {errors[MenuField.FullName].message}
                  </Style.ErrorMessage>
                ) : (
                  <Style.InputHint>
                    {t('editAccount.informationRequiredStart')}

                    <Link
                      to={`/${ROUTE.TERMS_REGULATIONS}`}
                      className="text-button-blue"
                      target="_blank"
                    >
                      {` ${t('common.TC')} `}
                    </Link>

                    {t('editAccount.informationRequiredEnd')}
                  </Style.InputHint>
                )}
              </Style.InputLabel>
            </Style.FlexRowContainer>
          </Style.SectionContent>

          {needSaveChanges && (
            <Style.MenuFooter>
              <Button
                variant="big-black"
                className="w-max font-bold text-xl"
                onClick={() => reset()}
              >
                {t('buttons.cancel')}
              </Button>

              <Button
                variant="big-blue"
                className="font-bold text-xl !w-179px"
                onClick={onSubmit}
              >
                {isSubmitting ? (
                  <Spinner
                    size={24}
                    color="white"
                  />
                ) : (
                  t('buttons.saveChanges')
                )}
              </Button>
            </Style.MenuFooter>
          )}
        </Style.SectionContainer>
      </Style.ContentContainer>

      <EditPasswordModal
        isOpen={isPasswordModalOpen}
        onClose={togglePasswordModal}
        onPasswordForgot={toggleRecoveryCodeModal}
      />

      <RecoveryCodeModal
        isOpen={isRecoveryCodeModalOpen}
        onClose={closeRecoveryCodeModal}
        goBack={toggleRecoveryCodeModal}
      />
    </>
  );
};
