import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const Container = styled.div`
  ${tw`p-6 bg-white rounded-md border border-gray94`}
`;

export const ResultContainer = styled.div`
  ${tw`flex gap-2 mb-6`}
`;

export const YData = styled.div`
  ${tw`text-15-20 text-gray7 mb-2`}
`;

export const TextResult = styled.div<{ $isBold?: boolean }>`
  ${tw`text-34-43 text-gray7`}

  ${({
    $isBold
  }) => ($isBold ? tw`font-bold` : tw`font-normal`)}
`;

export const ButtonsContainer = styled.div`
  ${tw`flex justify-end gap-4 mt-6`}
`;

export const Results = styled.div`
  ${tw`h-full flex flex-col gap-4 justify-center items-center relative`}
`;

export const ResultTitle = styled.h1`
  ${tw`text-28-36 font-bold text-gray7`}
`;

export const ResultComment = styled.div`
  ${tw`text-base font-medium text-dim-gray`}
`;

export const ResultButtons = styled.div`
  ${tw`absolute right-0 bottom-0 flex gap-4`}
`;
