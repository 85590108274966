import React, {
  FC, useState
} from 'react';
import {
  Controller, useForm
} from 'react-hook-form';
import {
  zodResolver
} from '@hookform/resolvers/zod';
import {
  useParams
} from 'react-router-dom';
import {
  useTranslation
} from 'react-i18next';

import {
  FormField
} from 'src/pages/CreateGroup/types';
import {
  LocationSelect
} from 'src/features';
import {
  Button, Spinner, successfulToast
} from 'src/shared/components';
import {
  usePatchGroupIdMutation,
  usePostOrgGroupMutation,
} from 'src/redux/openapi';
import {
  showWarningFromServer
} from 'src/shared/utils';
import {
  updateOrgGroupName
} from 'src/redux/organizations';
import {
  useTypedDispatch
} from 'src/redux';
import {
  useGetInputValidation
} from 'src/shared/hooks';
import {
  RadialСheckFalseIcon, RadialСheckTrueIcon
} from 'src/shared/icons';

import {
  Group
} from '../../types';

import * as Style from './GroupCard.styles';

interface CreateGroupForm {
  [FormField.GroupName]: string;
  [FormField.GroupLocation]: {
    value: string;
    label: string;
  };
}

interface NewGroupCardProps {
  close: () => void;
  onGroupAdded: (
    id: string,
    name: string,
    location: string,
    isPublic: boolean,
  ) => void;
  isEdit?: boolean;
  groupEdit?: Group;
}

export const NewGroupCard: FC<NewGroupCardProps> = ({
  close,
  onGroupAdded,
  isEdit,
  groupEdit,
}) => {
  const {
    t
  } = useTranslation();

  const {
    createGroupSchema
  } = useGetInputValidation();

  const params = useParams();
  const dispatch = useTypedDispatch();

  const orgId = params.organizationId;

  const {
    name, location
  } = groupEdit || {};

  const {
    control,
    register,
    formState: {
      errors
    },
    handleSubmit,
  } = useForm<CreateGroupForm>({
    mode: 'onTouched',
    defaultValues: {
      [FormField.GroupName]: isEdit && groupEdit ? name : '',
      [FormField.GroupLocation]:
        isEdit && groupEdit && location
          ? {
            value: location,
            label: location,
          }
          : {
            value: '',
            label: '',
          },
    },
    resolver: zodResolver(createGroupSchema),
  });

  const [createNewGroup, {
    isLoading: isCreateGroupLoading
  }] = usePostOrgGroupMutation();

  const [updateGroupInfo, {
    isLoading: isUpdateGroupLoading
  }] = usePatchGroupIdMutation();

  const handleUpdateGroupInfo = async (
    groupId: string,
    groupName: string,
    groupLocation: string,
  ) => {
    try {
      const {
        message,
        data: {
          group
        },
      } = await updateGroupInfo({
        id: groupId,
        updateGroupBodySchema: {
          name: groupName,
          location: groupLocation,
        },
      }).unwrap();

      successfulToast(message);

      dispatch(
        updateOrgGroupName({
          id: group.id,
          name: group.name,
          oldName: groupEdit?.name || '',
        }),
      );

      close();
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  const [isPublic, setIsPublic] = useState(false);

  const handleCreateNewGroup = async (
    orgIdValue: string,
    groupName: string,
    groupLocation: string,
  ) => {
    try {
      const {
        message,
        data: {
          group
        },
      } = await createNewGroup({
        id: orgIdValue,
        createOrganizationGroupBodySchema: {
          name: groupName,
          location: groupLocation,
          isPublic,
          invitationMessage: null,
        },
      }).unwrap();

      successfulToast(message);

      onGroupAdded(
        group.id,
        group.name,
        group.location || '',
        isPublic
      );

      close();
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  const onSubmit = handleSubmit(async (data) => {
    if (
      !data[FormField.GroupName]
      || !data[FormField.GroupLocation].value
      || !orgId
    ) {
      return;
    }

    if (!isEdit) {
      await handleCreateNewGroup(
        orgId,
        data[FormField.GroupName],
        data[FormField.GroupLocation].value,
      );
    }

    if (isEdit && groupEdit) {
      await handleUpdateGroupInfo(
        groupEdit.id,
        data[FormField.GroupName],
        data[FormField.GroupLocation].value,
      );
    }
  });

  const isLoading = isUpdateGroupLoading || isCreateGroupLoading;

  return (
    <Style.CardContainer $isNewCard>
      <Style.NewTitle>
        {isEdit ? t('group.editGroup') : t('organisation.createNewGroup')}
      </Style.NewTitle>

      <div>
        <div>
          <Style.FieldLabel>
            <p>
              {t('modal.groupName')}

              <Style.Asterisk>{' *'}</Style.Asterisk>
            </p>

            <Style.FieldInput
              placeholder={t(
                'inputMessages.MAXIMUM_CHARACTERS',
                {
                  value: 30,
                }
              )}
              {...register(FormField.GroupName)}
              $withError={!!errors[FormField.GroupName]?.message}
              autoComplete="off"
            />

            {errors[FormField.GroupName]?.message && (
              <Style.ErrorMessage>
                {errors[FormField.GroupName].message}
              </Style.ErrorMessage>
            )}
          </Style.FieldLabel>
        </div>

        <Style.TopMargin>
          <Style.FieldLabel>
            <Controller
              name={FormField.GroupLocation}
              control={control}
              render={({
                field
              }) => (
                <LocationSelect
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors[FormField.GroupLocation]?.value?.message}
                  hideIndicators
                  styles={{
                    valueContainer: (baseStyles) => ({
                      ...baseStyles,
                      paddingLeft: 0,
                      paddingRight: 0,
                    }),
                    input: (baseStyles) => ({
                      ...baseStyles,
                      marginLeft: 0,
                      marginRight: 0,
                    }),
                  }}
                  isRequired
                />
              )}
            />
          </Style.FieldLabel>
        </Style.TopMargin>
      </div>

      <Style.ButtonsContainer>
        {!isEdit ? (
          <Style.IsPublicContainer
            type="button"
            onClick={() => setIsPublic((prev) => !prev)}
          >
            {isPublic ? <RadialСheckTrueIcon /> : <RadialСheckFalseIcon />}

            <Style.PublicText>{t('common.publicGroup')}</Style.PublicText>
          </Style.IsPublicContainer>
        ) : (
          <div />
        )}

        <Style.ButtonsRow>
          <Button
            variant="big-grey-bordered"
            onClick={close}
            className="w-max px-4 py-2.5"
          >
            <Style.SpanButtonTitle>{t('buttons.cancel')}</Style.SpanButtonTitle>
          </Button>

          <Button
            variant="big-blue"
            onClick={onSubmit}
            className="w-max !px-4 !py-2.5"
          >
            {isLoading ? (
              <Spinner
                color="white"
                size={24}
              />
            ) : (
              <Style.SpanButtonTitle $isWhite>
                {isEdit ? t('buttons.save') : t('buttons.create')}
              </Style.SpanButtonTitle>
            )}
          </Button>
        </Style.ButtonsRow>
      </Style.ButtonsContainer>
    </Style.CardContainer>
  );
};
