import {
  useState, useMemo, useEffect
} from 'react';

import {
  useGetGroupQuestionSetsAllQuery
} from 'src/redux/openapi';
import {
  useGetQuestionsByQuestionSetIdAllMutation
} from 'src/shared/api/groups/groupsApi';
import {
  showWarningFromServer
} from 'src/shared/utils';

import {
  PreparedQuestionSets
} from '../types';

interface UseGetQuestionsToEdit {
  groupId?: string | null;
}

export const useGetQuestionsToEdit = ({
  groupId = '',
}: UseGetQuestionsToEdit) => {
  const [isQuestionsLoading, setIsQuestionsLoading] = useState(false);
  const [allSets, setAllSets] = useState<PreparedQuestionSets[]>([]);

  const [getGroupQuestionsBySetIdAll] = useGetQuestionsByQuestionSetIdAllMutation();

  const {
    data: questionSetsData,
    isLoading: isQuestionSetsLoading,
    isFetching: isQuestionSetsFetching,
  } = useGetGroupQuestionSetsAllQuery(
    {
      id: groupId || '',
      // TODO: add pagination after BE or design changing
      limit: 5000,
      filters: {
        sortOrder: 'asc',
        sortBy: 'startDate',
      },
    },
    {
      skip: !groupId,
      refetchOnMountOrArgChange: true,
    },
  );

  useEffect(
    () => {
      if (
        isQuestionsLoading
      && !isQuestionSetsLoading
      && !isQuestionSetsFetching
      ) {
        setIsQuestionsLoading(false);
      }
    },
    [isQuestionsLoading, isQuestionSetsLoading, isQuestionSetsFetching]
  );

  const {
    questionSets, activatedQuestions
  } = useMemo(
    () => questionSetsData?.data || {
      total: 0,
      questionSets: [],
      activatedQuestions: [],
    },
    [questionSetsData],
  );

  useEffect(
    () => {
      setAllSets(questionSets);
    },
    [questionSets]
  );

  const onLoadQuestionsBySetId = async (questionSetId: string) => {
    if (!groupId) {
      return [];
    }

    const loadedQuestions = allSets.find(
      (set) => set.id === questionSetId,
    )?.questions;

    if (loadedQuestions) {
      return loadedQuestions;
    }

    try {
      const response = await getGroupQuestionsBySetIdAll({
        id: groupId,
        questionSetId,
        limit: 5000,
      }).unwrap();

      const questions = response?.data?.questions || [];

      setAllSets((prev) => prev.map((set) => {
        return set.id === questionSetId
          ? {
            ...set,
            questions,
          }
          : set;
      }),);

      return questions;
    } catch (error) {
      showWarningFromServer(error);
    }

    return [];
  };

  return {
    preparedSets: allSets,
    activatedQuestions,
    isQuestionsLoading: isQuestionSetsLoading || isQuestionsLoading,
    setIsQuestionsLoading,
    onLoadQuestionsBySetId,
  };
};
