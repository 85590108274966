import {
  useState, useEffect, SetStateAction
} from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';

interface UseInfiniteScrollArgs<T> {
  total: number;
  currentItems: T[];
  isFetching: boolean;
  isLoading: boolean;
  isError?: boolean;
  currentPage: number;
  setCurrentPage: (value: SetStateAction<number>) => void;
  rootMargin?: string;
}

export const useCustomInfiniteScroll = <T>({
  total,
  currentItems,
  isFetching,
  isLoading,
  isError,
  currentPage,
  setCurrentPage,
  rootMargin = '0px 0px 100px 0px',
}: UseInfiniteScrollArgs<T>) => {
  const [allItems, setAllItems] = useState<T[]>([]);

  useEffect(
    () => {
      if (currentPage > 1) {
        setAllItems((prev) => [...prev, ...currentItems]);
      } else {
        setAllItems(currentItems);
      }
    },
    [currentItems]
  );

  const hasNextPage = total > allItems.length;

  const loading = isFetching || isLoading;

  const onLoadMore = () => {
    if (loading) {
      return;
    }

    setCurrentPage((prev) => prev + 1);
  };

  const [sentryRef, {
    rootRef
  }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore,
    disabled: !!isError,
    rootMargin,
  });

  const displayLoader = (hasNextPage || loading) && currentPage !== 1;

  return {
    sentryRef,
    allItems,
    displayLoader,
    rootRef,
    setAllItems,
  };
};

export type UseInfiniteScroll<T> = ReturnType<
  typeof useCustomInfiniteScroll<T>
>;
