import React, {
  FC
} from 'react';

import * as Style from './GroupRoute.styles';

interface GroupCardQuestionBlockProps {
  data: {
    dates: string;
    questionTitle: string;
  };
  isInvited?: boolean;
}

export const GroupCardQuestionBlock: FC<GroupCardQuestionBlockProps> = ({
  data,
  isInvited,
}) => {
  const {
    dates, questionTitle
  } = data;

  return (
    <Style.CardQuestionContainer $isInvited={isInvited}>
      <Style.CardQuestionDates>{dates}</Style.CardQuestionDates>

      <Style.CardQuestionTitle $isInvited={isInvited}>
        {questionTitle}
      </Style.CardQuestionTitle>
    </Style.CardQuestionContainer>
  );
};
