import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MainContainer = styled.div`
  ${tw`w-910px mx-auto bg-white rounded-20px border border-gray94 flex flex-col overflow-hidden h-full`}

  max-height: calc(100vh - 128px);
`;

export const HeaderContainer = styled.div`
  ${tw`flex flex-col gap-6 px-6 pt-6 border-b border-b-gray94`}
`;

export const HeaderTopContainer = styled.div`
  ${tw`flex gap-4 items-center justify-between`}
`;

export const HeaderTitleContainer = styled.div`
  ${tw`flex-1 flex gap-4 items-center`}
`;

export const HeaderTitle = styled.div`
  ${tw`font-bold text-28-36 text-gray7`}
`;

export const HeaderButtonsContainer = styled.div`
  ${tw`flex-1 flex gap-4 justify-end relative`}
`;

export const HeaderTabs = styled.div`
  ${tw`flex gap-4`}
`;

export const LinkTitleContainer = styled.div`
  ${tw`flex gap-2 justify-center items-center min-w-90px`}
`;

export const LinkTitle = styled.p<{ $isActive?: boolean; $isTitle?: boolean }>`
  ${({
    $isTitle
  }) => ($isTitle ? tw`text-17-26` : tw`text-13-21`)}

  ${({
    $isActive
  }) => ($isActive ? tw`font-bold text-button-blue` : tw`font-medium text-dim-gray`)}
`;

export const HeaderButtonLink = styled.button`
  ${tw`flex flex-col gap-2 transition`}
`;

export const LinkCountBlock = styled.div<{ $isActive?: boolean }>`
  ${tw`p-1 w-6 h-6 rounded-100px bg-alice-blue flex items-center justify-center text-13-21`}

  ${({
    $isActive
  }) => ($isActive ? tw`font-bold text-button-blue` : tw`font-medium text-dim-gray`)}
`;

export const EmptyMessageContainer = styled.div`
  ${tw`flex flex-col items-center justify-center gap-2 text-center font-medium text-17-26 text-dim-gray h-full`}
`;

export const EmptyMessageTitle = styled.p`
  ${tw`font-bold text-dark-gray`}
`;

export const LinkFooterBlock = styled.div<{ $isActive?: boolean }>`
  ${tw`h-1 w-full rounded-t-10px`}
  ${({
    $isActive
  }) => ($isActive ? tw`bg-button-blue` : tw`bg-white`)}
`;

export const InputContainer = styled.div`
  ${tw`w-417px flex justify-end`}
`;
