import React, {
  FC, useEffect
} from 'react';
import {
  zodResolver
} from '@hookform/resolvers/zod';
import {
  Controller, useForm
} from 'react-hook-form';
import {
  useTranslation
} from 'react-i18next';

import {
  Button,
  Input,
  InputTextArea,
  InputType,
  LoginSocialsInvite,
  ModalContainer,
  Spinner,
  successfulToast,
  warningToast,
} from 'src/shared/components';
import {
  EmailIcon
} from 'src/shared/icons';
import {
  usePostGroupUpgradeMutation, GroupGeneral
} from 'src/redux/openapi';
import {
  isErrorWithMessage
} from 'src/shared/utils';
import {
  useGetInputValidation
} from 'src/shared/hooks';

import * as Style from './GroupRoute.styles';

interface ModalGroupToOrganizationProps {
  handleCloseModal: () => void;
  handleRequestSuccess: () => void;
  isOpen: boolean;
  groupToModify?: GroupGeneral;
}

enum ModalField {
  OrganizationName = 'organizationName',
  Description = 'description',
}

interface MailFormValuesState {
  [ModalField.OrganizationName]: string;
  [ModalField.Description]: string;
}

export const ModalGroupToOrganization: FC<ModalGroupToOrganizationProps> = ({
  handleCloseModal,
  handleRequestSuccess,
  isOpen,
  groupToModify,
}) => {
  const {
    upgradeGroupToOrgSchema
  } = useGetInputValidation();

  const {
    handleSubmit,
    control,
    formState: {
      errors
    },
    reset,
  } = useForm<MailFormValuesState>({
    mode: 'onTouched',
    defaultValues: {
      [ModalField.OrganizationName]: '',
      [ModalField.Description]: '',
    },
    resolver: zodResolver(upgradeGroupToOrgSchema),
  });

  const {
    t
  } = useTranslation();

  const closeModal = () => {
    reset();
    handleCloseModal();
  };

  const [
    upgradeToOrganization,
    {
      isLoading: isUpgradeLoading, isSuccess: isUpgradeSuccess
    },
  ] = usePostGroupUpgradeMutation();

  const handleSend = async ({
    organizationName,
    description,
  }: {
    organizationName: string;
    description: string;
  }) => {
    if (!isUpgradeLoading && groupToModify) {
      try {
        const response = await upgradeToOrganization({
          id: groupToModify.id,
          upgradeGroupToOrganizationBodySchema: {
            organizationName,
            description,
          },
        }).unwrap();

        successfulToast(response.message);
      } catch (error) {
        if (isErrorWithMessage(error)) {
          warningToast(error.data.message);

          if (error.data.message === t('errors.groupRequestSent')) {
            closeModal();
          }
        }
      }
    }
  };

  useEffect(
    () => {
      if (isUpgradeSuccess) {
        handleRequestSuccess();
      }
    },
    [isUpgradeSuccess]
  );

  return (
    <ModalContainer
      isOpen={isOpen}
      modalTitle={t('modal.upgradeGroupToOrganization')}
      closeModalHandler={closeModal}
      isDarkBackground
    >
      <div className="flex flex-col gap-6">
        <LoginSocialsInvite
          email=""
          groupName={groupToModify ? groupToModify.name : t('modal.groupName')}
        />

        <Controller
          name={ModalField.OrganizationName}
          control={control}
          render={({
            field
          }) => {
            return (
              <Input
                label={t('modal.createOrganizationName')}
                type={InputType.TEXT}
                isRequired
                value={field.value}
                onChange={field.onChange}
                errorMessage={errors.organizationName?.message}
                onBlur={field.onBlur}
                placeholder={t('modal.organizationName')}
              />
            );
          }}
        />

        <div className="flex flex-col">
          <Style.ModalLabel htmlFor="comment">
            {`${t('modal.tellAboutYourOrganization')} `}

            <Style.ModalRedSpan>*</Style.ModalRedSpan>
          </Style.ModalLabel>

          <Controller
            name={ModalField.Description}
            control={control}
            render={({
              field
            }) => {
              return (
                <>
                  <InputTextArea
                    value={field.value}
                    placeholder={t('modal.whyYouUpgradeGroupToOrganization')}
                    onChange={field.onChange}
                    className="h-48 resize-none block w-full px-4 py-2"
                    id="comment"
                    onBlur={field.onBlur}
                  />

                  {errors.description?.message && (
                    <Style.ErrorMessage>
                      {errors.description?.message}
                    </Style.ErrorMessage>
                  )}
                </>
              );
            }}
          />
        </div>

        <div className="flex justify-end items-center ">
          <Button
            variant="big-blue"
            className="w-max"
            onClick={handleSubmit(handleSend)}
          >
            <Style.ModalSaveSpan>
              {t('buttons.sendRequest')}
            </Style.ModalSaveSpan>

            {isUpgradeLoading ? (
              <Spinner
                color="white"
                size={24}
                centered={false}
              />
            ) : (
              <EmailIcon className="text-white" />
            )}
          </Button>
        </div>
      </div>
    </ModalContainer>
  );
};
