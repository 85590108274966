import React, {
  FC, PropsWithChildren
} from 'react';

import * as Style from './Filters.styles';

interface FilterAmountTileProps extends PropsWithChildren {
  isActive?: boolean;
}

export const FilterAmountTile: FC<FilterAmountTileProps> = ({
  children,
  isActive = false,
}) => {
  return (
    <Style.FilterAmountContainer>
      <Style.FilterAmount $isActive={isActive}>{children}</Style.FilterAmount>
    </Style.FilterAmountContainer>
  );
};
