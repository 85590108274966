import React, {
  FC, useEffect, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  ClearableSelectFooter, PopoverMenu
} from 'src/shared/components';
import {
  ArrowRightIcon,
  CheckBoxFalseIcon,
  CheckBoxTrueIcon,
  Logo,
} from 'src/shared/icons';
import {
  forecastsColorValues
} from 'src/shared/utils';
import {
  useTypedSelector
} from 'src/redux';
import {
  groupsSelectors
} from 'src/redux/groups';

import * as Style from './SelectGraphData.styles';
import {
  SelectButton
} from './SelectButton';

interface ItemSelected {
  title: string;
  isSelected: boolean;
}

interface SelectGraphDataProps {
  buttonTitle: string;
  showMyForecast: () => void;
  showAverage: () => void;
  filteredLines: Record<string, boolean>;
  handleChangeFilters: (groups: Record<string, boolean>) => void;
  isMyForecastShow: boolean;
  isAverageForecastShow: boolean;
}

export const SelectGraphData: FC<SelectGraphDataProps> = ({
  buttonTitle,
  filteredLines,
  handleChangeFilters,
  isMyForecastShow,
  isAverageForecastShow,
  showMyForecast,
  showAverage,
}) => {
  const selectedOptions: ItemSelected[] = Object.entries(filteredLines).map(
    ([key, value]) => ({
      title: key,
      isSelected: value,
    }),
  );

  const {
    t
  } = useTranslation();

  const [groupOptions, setGroupOptions] = useState(selectedOptions);
  const [shouldClose, setShouldClose] = useState(false);

  useEffect(
    () => {
      setGroupOptions(selectedOptions);
    },
    [filteredLines]
  );

  const handleChangeGroupFilters = (dataKey: string) => {
    setGroupOptions((prev) => {
      return prev.map((item) => {
        if (item.title === dataKey) {
          return {
            ...item,
            isSelected: !item.isSelected,
          };
        }

        return item;
      });
    });
  };

  const prepareGroupsToSubmit = () => {
    return groupOptions.reduce(
      (acc: Record<string, boolean>, {
        title, isSelected
      }) => {
        return {
          ...acc,
          [title]: isSelected,
        };
      },
      {},
    );
  };

  const resetGroups = () => {
    setGroupOptions((prev) => {
      return prev.map((item) => ({
        ...item,
        isSelected: false,
      }));
    });
  };

  const handleApply = () => {
    handleChangeFilters(prepareGroupsToSubmit());
    setShouldClose(true);
  };

  const handleButtonClick = (func: () => void) => {
    if (shouldClose) {
      setShouldClose(false);
    }

    func();
  };

  const allUserGroups = useTypedSelector(groupsSelectors.groups);

  const formatGroupName = (id: string) => {
    const group = allUserGroups.find((groupInState) => groupInState.id === id);

    return group?.name || 'group';
  };

  return (
    <PopoverMenu
      placement="bottom-start"
      shouldForcedClose={shouldClose}
      renderCustomButton={({
        isOpen, toggleMenu, triggerProps
      }) => (
        <SelectButton
          isOpen={isOpen}
          onButtonClick={() => handleButtonClick(toggleMenu)}
          title={buttonTitle}
          isSelectedValue={!!selectedOptions.length}
          onClear={() => {}}
          {...triggerProps}
        />
      )}
    >
      <Style.MenuContainer>
        <Style.MenuOption
          type="button"
          onClick={showMyForecast}
        >
          {t('select.myForecast')}

          <div>
            {isMyForecastShow ? <CheckBoxTrueIcon /> : <CheckBoxFalseIcon />}
          </div>
        </Style.MenuOption>

        <Style.MenuOption
          type="button"
          onClick={showAverage}
        >
          {t('group.groupsAverage')}

          <div>
            {isAverageForecastShow ? (
              <CheckBoxTrueIcon />
            ) : (
              <CheckBoxFalseIcon />
            )}
          </div>
        </Style.MenuOption>

        <Style.ModalFilterSeparatorLine />

        <Style.ModalFilterTitle>
          {t('common.groupsAndOrganisations')}
        </Style.ModalFilterTitle>

        <PopoverMenu
          placement="right-start"
          applyMaxHeight={false}
          shouldForcedClose={shouldClose}
          renderCustomButton={({
            isOpen, toggleMenu, triggerProps
          }) => (
            <Style.ModalLabel
              onClick={toggleMenu}
              $isActive={isOpen}
              {...triggerProps}
              onMouseEnter={toggleMenu}
            >
              <Style.ModalListItemCount>
                {`${
                  selectedOptions.filter((option) => option.isSelected).length
                }/${selectedOptions.length}`}
              </Style.ModalListItemCount>

              <Style.ModalLabelTitle $isActive={isOpen}>
                {t('common.groups')}
              </Style.ModalLabelTitle>

              <ArrowRightIcon />
            </Style.ModalLabel>
          )}
        >
          <Style.MenuContainer className="!w-318px">
            {groupOptions.map((group, i) => {
              const formattedTitle = formatGroupName(group.title);

              return (
                <Style.MenuOption
                  key={group.title}
                  type="button"
                  onClick={() => handleChangeGroupFilters(group.title)}
                >
                  <div className="flex gap-2 items-center">
                    <div className="rounded-full h-10 w-10 relative">
                      <div className="absolute right-0 bottom-0 block w-2 h-2 rounded-full -translate-x-1.5 -translate-y-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <circle
                            cx="8"
                            cy="8"
                            r="7"
                            fill={
                              forecastsColorValues[i]?.forecastColor || '#000'
                            }
                            stroke={
                              forecastsColorValues[i]?.forecastColorStroke
                              || '#000'
                            }
                            strokeWidth="2"
                          />
                        </svg>
                      </div>

                      <img
                        src={Logo}
                        alt={`${group.title} ${t('common.logo')}`}
                        className="border border-gray-whisper !rounded-full w-10 h-10 shrink-0"
                      />
                    </div>

                    {formattedTitle}
                  </div>

                  <div className="self-center">
                    {group.isSelected ? (
                      <CheckBoxTrueIcon />
                    ) : (
                      <CheckBoxFalseIcon />
                    )}
                  </div>
                </Style.MenuOption>
              );
            })}

            <ClearableSelectFooter
              onApply={handleApply}
              onClear={resetGroups}
              isApplyDisabled={false}
            />
          </Style.MenuContainer>
        </PopoverMenu>
      </Style.MenuContainer>
    </PopoverMenu>
  );
};
