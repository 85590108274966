import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const ContextContainer = styled.div`
  ${tw`w-72 bg-white p-2 rounded shadow-menu-shadow text-left font-medium`}
`;
export const ContextTitle = styled.div`
  ${tw`text-dark-gray text-13-21 px-2 py-1`}
`;

export const ContextActionTitle = styled.div`
  ${tw`text-dark-gray text-17-26 px-2 py-2`}
`;

export const ContextDividerLine = styled.div`
  ${tw`h-px bg-gray94 my-1`}
`;

export const ContextActionButton = styled.button`
  ${tw`flex gap-2 items-center w-full`}
`;
