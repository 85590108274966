import {
  OrganizationGroupsResponseSchema,
  OrganizationUsersResponseSchema,
  OrganizationsResponseSchema,
  OrganizationVerificationsResponseSchema,
  OrganizationInvitationsGroupsResponseSchema,
  OrganizationQuestionsOnboardingTextResponseSchema,
  FetchBatchRemovedOrganizationsQuestionsToDeactivateResponseSchema,
  UpdateOrganizationQuestionsOnboardingTextBodySchema,
  GetApiOrganizationsByIdGroupsUsersDownloadApiArg,
  OrganizationGroupsWithUsersResponseSchema,
  OrganizationAdminsResponseSchema,
  GetMergeGroupsResponseSchema,
  OrganizationGroupsWithActiveQuestionsCountResponseSchema,
} from './organizations';
import {
  GroupStatisticsByNotificationResponseSchema,
  NotificationsResponseSchema,
} from './notifications';
import {
  GroupAllQuestionSetsResponseSchema,
  GroupUsersInvitedResponseSchema,
  GroupUsersResponseSchema,
  GroupsResponseSchema,
  QuestionSetQuestionsResponseSchema as GroupsSetQuestionsResponseSchema,
  ListGroupDeactivationQuestionsResponseSchema,
  QuestionsResponseSchema as GroupQuestionsResponse,
  GetApiGroupsCommunityApiResponse,
  GroupQuestionSetsResponseSchema,
  GetGroupUsersForCreateFromGroupResponseSchema,
} from './groups';
import {
  Items as Comment
} from './comments';
import {
  CurrentUserAchievementsResponseSchema,
  GetCurrentUserCharacteristicsResponseSchema,
  UserScoresResponseSchema,
} from './users';
import {
  CommunityQuestionsResponseSchema,
  Filters as QuestionFilters,
  GetApiQuestionsCommunityApiArg,
  QuestionResponseSchema,
  QuestionStatisticsChartResponseSchema,
  QuestionsActiveStatisticsResponseSchema,
  QuestionsAvailableStatisticsResponseSchema,
  QuestionsResponseSchema,
  GetQuestionDelegationUserResponseSchema,
  GetSurveyQuestionOptionsResponseSchema,
} from './questions';
import {
  AcceptGroupToOrganizationBodySchema,
  AdminOrgsResponseSchema,
  GetUnaffiliatedGroupsResponseSchema,
  GroupOrganizationRequestsResponseSchema,
} from './admin';
import {
  CommunityQuestionSetsResponseSchema,
  FindAllAvailableQuestionSetsResponseSchema,
} from './questionSets';

export type OrganizationGroup =
  OrganizationGroupsResponseSchema['data']['groups'][number];

export type SortOrder = 'asc' | 'desc';

export type Notification =
  NotificationsResponseSchema['data']['notifications'][number];

export type Group = GroupsResponseSchema['data']['groups'][number];
export type GroupOrganizationStatus = Group['organizationStatus'];

export type Organization =
  OrganizationsResponseSchema['data']['organizations'][number];

export type GroupMembers = GroupUsersResponseSchema['data'];

export type OrganizationMembers = OrganizationUsersResponseSchema['data'];

export type OrganizationAdmins = OrganizationAdminsResponseSchema['data'];

export type GroupMember = GroupMembers['members'][number];

export type OrganizationMember = OrganizationMembers['members'][number];

export type GroupAdmin = GroupMembers['admins'][number];

export type OrganizationAdmin = OrganizationAdmins['admins'][number];

export type MembersStatusCount = OrganizationAdmins['status'];

export type GroupQuestionSetQuestion =
  GroupsSetQuestionsResponseSchema['data']['questions'][number];

export type GroupInvitedPerson =
  GroupUsersInvitedResponseSchema['data']['users'][number];

export enum UserGroupRole {
  SUPERADMIN = 'SUPERADMIN',
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export type UserGroupRoleType = keyof typeof UserGroupRole;

export type ReplyComment = Comment['comments'][number];

export type UserScores = UserScoresResponseSchema['data']['scores'];

export type ScoresSets = UserScoresResponseSchema['data']['questionSets'];

export type Question = QuestionsResponseSchema['data']['questions'][number];
export type CurrentQuestion = QuestionResponseSchema['data']['question'];
export type QuestionSurveyQuestion = CurrentQuestion['surveyQuestions'][number];

export type GroupQuestion = GroupQuestionsResponse['data']['questions'][number];

export type ChartDataApi =
  QuestionStatisticsChartResponseSchema['data']['chart'][number];

export type ChartData = Omit<ChartDataApi, 'date'> & {
  date: number;
};

export enum CommendationType {
  LIKE = 'LIKE',
  RELEVANT = 'RELEVANT',
  INSIGHTFUL = 'INSIGHTFUL',
  INSPIRING = 'INSPIRING',
}

export type Commendation = keyof typeof CommendationType;

export interface ErrorResponse {
  data: {
    message: string;
  };
}

export type OrgToDeactivateQuestions =
  FetchBatchRemovedOrganizationsQuestionsToDeactivateResponseSchema['data']['deactivationList'];

export type GroupToDeactivateQuestions =
  ListGroupDeactivationQuestionsResponseSchema['data'];

export type QuestionsToEditData = GroupAllQuestionSetsResponseSchema['data'];

export type ActivatedQuestionsList = QuestionsToEditData['activatedQuestions'];

export type AdminOrganization =
  AdminOrgsResponseSchema['data']['organizations'][number];
export type AdminOrganizationsAdmins = AdminOrganization['admins'];

export type AdminOrganizationRequests =
  GroupOrganizationRequestsResponseSchema['data']['groupRequests'][number];
export type AdminOrgRequestsStatus = AdminOrganizationRequests['status'];

export type AdminUnaffiliatedGroup =
  GetUnaffiliatedGroupsResponseSchema['data']['groups'][number];

export type AcceptOrganizationRequest =
  AcceptGroupToOrganizationBodySchema['organization'];

export type CommunityQuestionSet =
  CommunityQuestionSetsResponseSchema['data']['questionSets'][number];
export type CommunityQuestion =
  CommunityQuestionsResponseSchema['data']['questions'][number];
export type CommunityQuestionsSortBy =
  GetApiQuestionsCommunityApiArg['filters']['sortBy'];
export type CommunityGroup =
  GetApiGroupsCommunityApiResponse['data']['groups'][number];

export type OrgVerifications =
  OrganizationVerificationsResponseSchema['data']['verifications'];
export type OrgVerificationSettings =
  OrganizationVerificationsResponseSchema['data']['verificationSettings'];

export type OrgInvitationsGroup =
  OrganizationInvitationsGroupsResponseSchema['data']['groups'][number];

export type UnreadNotificationsCount =
  NotificationsResponseSchema['data']['unreadCount'];

export type OrgQuestionsOnboardingText =
  OrganizationQuestionsOnboardingTextResponseSchema['data']['questions'][number];
export type OrgSetupQuestionsOnboardingText =
  UpdateOrganizationQuestionsOnboardingTextBodySchema['questions'];

export type DownloadOrgMembersType =
  GetApiOrganizationsByIdGroupsUsersDownloadApiArg['type'];

export type GroupQuestionSet =
  GroupQuestionSetsResponseSchema['data']['questionSets'][number];

export type AvailableQuestionSets =
  FindAllAvailableQuestionSetsResponseSchema['data']['questionSets'][number];

export type QuestionsActiveStatistics =
  QuestionsActiveStatisticsResponseSchema['data']['statistics'][number];
export type QuestionsAvailableStatistics =
  QuestionsAvailableStatisticsResponseSchema['data']['statistics'][number];

export type OrganizationGroupWithUsers =
  OrganizationGroupsWithUsersResponseSchema['data']['groups'][number];

export type UserCharacteristic =
  GetCurrentUserCharacteristicsResponseSchema['data']['characteristics'][number];

export type GroupStats =
  GroupStatisticsByNotificationResponseSchema['data']['group'];

export type AdminNotificationChar = NonNullable<
Extract<Notification['context'], { characteristics: unknown }>
>['characteristics'][number];

export type QuestionsUpdatesPer = QuestionFilters['updatesPer'];

export type GroupForMerge =
  GetMergeGroupsResponseSchema['data']['groups'][number];

export type UserForMerge =
  GetGroupUsersForCreateFromGroupResponseSchema['data']['users'][number];

export type GroupWithActiveCount =
  OrganizationGroupsWithActiveQuestionsCountResponseSchema['data']['groups'][number];

export type OrgGroupStatus = OrgInvitationsGroup['status'];

export type QuestionDelegate =
  GetQuestionDelegationUserResponseSchema['data']['delegate'];

export type SurveyQuestionOption =
  GetSurveyQuestionOptionsResponseSchema['data']['options'][number];

export type Achievements =
  CurrentUserAchievementsResponseSchema['data']['achievements'];
export type LockedAchievements =
  CurrentUserAchievementsResponseSchema['data']['lockedAchievements'];
