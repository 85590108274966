import React, {
  FC,
  useState,
  useEffect,
  useRef,
  MouseEvent,
  FunctionComponent,
  SVGProps,
} from 'react';
import {
  useAnimate
} from 'framer-motion';
import {
  useLocation
} from 'react-router-dom';
import clsx from 'clsx';

import {
  CloseIcon, SearchIcon
} from 'src/shared/icons';
import {
  ROUTE, themeColors
} from 'src/shared/utils';

import * as Style from './SearchInput.styles';

interface SearchInputProps {
  value: string;
  onChange: (v: string) => void;
  placeholder: string;
  withBorder?: boolean;
  size?: number;
  iconSize?: number;
  iconColor?: string;
  activeIconColor?: string;
  isHeader?: boolean;
  onStateChange?: (isOpen: boolean) => void;
  disableBlurring?: boolean;
  withCloseButton?: boolean;
  closeIconPosition?: 'start' | 'end';
  closeIconClassName?: string;
  customCloseIcon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  shouldGrow?: boolean;
  onClose?: () => void;
}

const BORDER_WIDTH = 1;

export const SearchInput: FC<SearchInputProps> = ({
  value,
  onChange,
  placeholder,
  withBorder = true,
  size = 44,
  iconSize = 24,
  iconColor = themeColors['dim-gray'],
  activeIconColor = themeColors['dark-gray'],
  isHeader,
  onStateChange,
  disableBlurring,
  withCloseButton,
  closeIconPosition = 'start',
  closeIconClassName = 'text-gulf-blue w-6 h-6',
  customCloseIcon: CustomCloseIcon = CloseIcon,
  shouldGrow = false,
  onClose,
}) => {
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [scope, animate] = useAnimate();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const location = useLocation();
  const isNewUser = location.pathname.startsWith(`/${ROUTE.CREATE_GROUP}`);

  const defaultPadding = (size - iconSize - (withBorder ? BORDER_WIDTH : 0)) / 2;

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (!isNewUser) {
      e.stopPropagation();
      setIsSearchActive((prev) => !prev);
    }
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }

    setIsSearchActive(false);
  };

  const onBlur = () => {
    if (!disableBlurring) {
      handleClose();
    }
  };

  const onFocus = () => {
    if (onStateChange) {
      onStateChange(true);
    }
  };

  useEffect(
    () => {
      if (onStateChange) {
        onStateChange(isSearchActive);
      }
    },
    [isSearchActive]
  );

  useEffect(
    () => {
      animate(
        scope.current,
        {
          width: isSearchActive ? '100%' : size,
        },
        {
          duration: 0.3,
        },
      );
    },
    [isSearchActive]
  );

  const getSearchIconColor = () => {
    if (isNewUser) {
      return themeColors['gray-whisper'];
    }

    if (isSearchActive) {
      return activeIconColor;
    }

    return iconColor;
  };

  return (
    <Style.Wrapper
      $shouldGrow={shouldGrow}
      $withInput={isSearchActive}
    >
      <Style.MainContainer
        onBlur={onBlur}
        $withBorder={withBorder}
        $withInput={isSearchActive}
        $size={size}
        $padding={defaultPadding}
        $isHeader={isHeader}
        $isNewUser={isNewUser}
        ref={scope}
        animate
      >
        {isSearchActive ? (
          <>
            {withCloseButton && closeIconPosition === 'start' && (
              <CustomCloseIcon
                className={closeIconClassName}
                onMouseDown={handleClose}
              />
            )}

            <Style.InputBlock
              type="text"
              name="search"
              ref={inputRef}
              value={value}
              placeholder={placeholder}
              autoComplete="off"
              onChange={(e) => onChange(e.target.value)}
              onClick={(e) => e.stopPropagation()}
              autoFocus
              onFocus={onFocus}
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              exit={{
                opacity: 0,
              }}
              transition={{
                duration: 0.3,
              }}
            />

            {withCloseButton && closeIconPosition === 'end' && (
              <CustomCloseIcon
                className={closeIconClassName}
                onMouseDown={handleClose}
              />
            )}

            <SearchIcon
              color={isSearchActive ? activeIconColor : iconColor}
              width={iconSize}
              height={iconSize}
            />
          </>
        ) : (
          <button
            type="button"
            onClick={handleClick}
            className={clsx({
              'cursor-default': isNewUser,
            })}
          >
            <SearchIcon
              color={getSearchIconColor()}
              width={iconSize}
              height={iconSize}
            />
          </button>
        )}
      </Style.MainContainer>
    </Style.Wrapper>
  );
};
