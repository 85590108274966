import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import {
  Controller, useForm
} from 'react-hook-form';
import {
  zodResolver
} from '@hookform/resolvers/zod';

import {
  Input
} from 'src/shared/components';
import {
  useGetInputValidation
} from 'src/shared/hooks';
import {
  usePatchOrganizationByIdMutation
} from 'src/redux/openapi';
import {
  showWarningFromServer
} from 'src/shared/utils';
import {
  successfulToast
} from 'src/shared/components/Toasts';

import * as Style from './OrganizationSettingsModal.style';
import {
  OrganizationLogoUpload,
  StickyButtonsFooter,
  OrgEmailSettings,
  OrgThemes,
} from './UI';

interface OrgGeneralSettingsTabProps {
  name: string;
  id: string;
  logoUrl: string | null;
  containerHeight: number;
  isOpenModal: boolean;
  closeModalHandler: () => void;
  openModalHandler: () => void;
}

interface OrgNameForm {
  name: string;
}

export const OrgGeneralSettingsTab: FC<OrgGeneralSettingsTabProps> = ({
  name,
  id,
  logoUrl,
  containerHeight,
  isOpenModal,
  closeModalHandler,
  openModalHandler,
}) => {
  const {
    t
  } = useTranslation();

  const {
    organizationNameSchema
  } = useGetInputValidation();

  const {
    control, formState, watch, setValue
  } = useForm<OrgNameForm>({
    mode: 'onTouched',
    defaultValues: {
      name,
    },
    resolver: zodResolver(organizationNameSchema),
  });

  const nameValue = watch('name');

  const [changeOrgName, {
    isLoading: isChangeOrgNameLoading
  }] = usePatchOrganizationByIdMutation();

  const handleOrgNameChange = async () => {
    if (nameValue === name || formState.errors.name) {
      return;
    }

    try {
      const resp = await changeOrgName({
        id,
        updateOrganizationBodySchema: {
          name: nameValue,
        },
      }).unwrap();

      successfulToast(resp.message);
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  const handleCancelClick = () => setValue(
    'name',
    name
  );

  return (
    <Style.ModalSettingsTabContainer $height={containerHeight}>
      <OrganizationLogoUpload
        id={id}
        logoSrc={logoUrl}
        name={name}
      />

      <Style.ModalInputRow>
        <Controller
          name="name"
          control={control}
          render={({
            field
          }) => {
            return (
              <Input
                label={t('organisation.organisationName')}
                onChange={field.onChange}
                value={field.value}
                errorMessage={formState.errors.name?.message}
                onBlur={field.onBlur}
              />
            );
          }}
        />
      </Style.ModalInputRow>

      <OrgEmailSettings />

      <OrgThemes
        closeModalHandler={closeModalHandler}
        openModalHandler={openModalHandler}
        id={id}
        isOpenModal={isOpenModal}
      />

      {nameValue !== name && (
        <StickyButtonsFooter
          handleCancelClick={handleCancelClick}
          handleOrgNameChange={handleOrgNameChange}
          isChangeOrgNameLoading={isChangeOrgNameLoading}
        />
      )}
    </Style.ModalSettingsTabContainer>
  );
};
