import React, {
  FC, useEffect, useState
} from 'react';

import {
  GroupInvitedPerson
} from 'src/redux/openapi';

import * as Style from './InvitedPeopleList.styles';
import {
  InvitedPeopleListItem
} from './InvitedPeopleListItem';

interface InvitedPeopleListProps {
  invitedPeople: GroupInvitedPerson[];
  newGroupId: string;
  onInvitationRemove: () => void;
}

export const InvitedPeopleList: FC<InvitedPeopleListProps> = ({
  invitedPeople,
  newGroupId,
  onInvitationRemove,
}) => {
  const [currentTime, setCurrentTime] = useState(new Date().getTime());

  useEffect(
    () => {
      const timeInterval = setInterval(
        () => {
          setCurrentTime(new Date().getTime());
        },
        60000
      );

      return () => clearInterval(timeInterval);
    },
    []
  );

  return (
    <Style.MainContainer>
      {invitedPeople.map((invitedPerson, index) => (
        <InvitedPeopleListItem
          key={invitedPerson.email}
          index={index + 1}
          invitedPerson={invitedPerson}
          currentTime={currentTime}
          groupId={newGroupId}
          onInvitationRemove={onInvitationRemove}
        />
      ))}
    </Style.MainContainer>
  );
};
