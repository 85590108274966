import React, {
  FC, useEffect
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import {
  Controller, useForm
} from 'react-hook-form';
import {
  zodResolver
} from '@hookform/resolvers/zod';
import {
  useNavigate
} from 'react-router-dom';

import {
  Button, Divider, InputTextArea, Spinner
} from 'src/shared/components';
import {
  useGetInputValidation
} from 'src/shared/hooks';
import {
  ROUTE, showWarningFromServer
} from 'src/shared/utils';
import {
  useGetQuestionPostEstimationsQuery
} from 'src/redux/openapi';

import * as Style from './FeedbackForm.styles';

const MISSED_RANGE_VALUE = 49;

interface FeedbackFormProps {
  onFormSubmit: (feedback: string) => void;
  isSubmitLoading?: boolean;
  questionId: string;
}

export const FeedbackForm: FC<FeedbackFormProps> = ({
  onFormSubmit,
  isSubmitLoading,
  questionId,
}) => {
  const {
    t
  } = useTranslation();

  const navigate = useNavigate();

  const {
    postEstimationFeedbackSchema
  } = useGetInputValidation();

  const {
    handleSubmit,
    control,
    formState: {
      errors
    },
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      feedback: '',
    },
    resolver: zodResolver(postEstimationFeedbackSchema),
  });

  const onSubmit = handleSubmit(({
    feedback
  }) => onFormSubmit(feedback));

  const {
    data, error, isLoading
  } = useGetQuestionPostEstimationsQuery(
    {
      id: questionId,
    },
    {
      skip: !questionId,
    },
  );

  useEffect(
    () => {
      if (error) {
        showWarningFromServer(error);
        navigate(`${ROUTE.ROOT}`);
      }
    },
    [error]
  );

  if (isLoading) {
    return <Spinner />;
  }

  if (!data) {
    return null;
  }

  const {
    title, setTitle, avg: range
  } = data.data.question;

  const rangeTitle = t(
    'feedback.youHitRangeOfScore',
    {
      range,
    }
  );

  const feedbackDescription = range > MISSED_RANGE_VALUE
    ? t('feedback.missedScoreFeedbackDescription')
    : t('feedback.goodScoreFeedbackDescription');

  return (
    <Style.ContentContainer>
      <Style.Header>
        <Style.Title>{t('feedback.postEstimationFeedback')}</Style.Title>

        <div>
          <Style.QuestionSetTitle>{setTitle}</Style.QuestionSetTitle>

          <Style.QuestionTitle>{title}</Style.QuestionTitle>
        </div>
      </Style.Header>

      <Divider />

      <Style.FormContainer>
        <Style.Label>
          {`${rangeTitle} ${feedbackDescription}`}

          <Controller
            name="feedback"
            control={control}
            render={({
              field: {
                value, onChange, onBlur
              }
            }) => {
              return (
                <>
                  <InputTextArea
                    placeholder={t(
                      'feedback.leaveFeedbackNoLongerThan',
                      {
                        symbols: 150,
                      }
                    )}
                    className="px-4 py-2 h-[180px]"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                  />

                  {!!errors?.feedback?.message && (
                    <Style.ErrorMessage>
                      {errors?.feedback?.message}
                    </Style.ErrorMessage>
                  )}
                </>
              );
            }}
          />
        </Style.Label>

        <Button
          type="submit"
          variant="big-blue"
          className="w-max font-bold text-20-28 self-end whitespace-nowrap"
          onClick={onSubmit}
        >
          {t('buttons.sendFeedback')}

          {isSubmitLoading && (
            <Spinner
              size={20}
              color="inherit"
            />
          )}
        </Button>
      </Style.FormContainer>
    </Style.ContentContainer>
  );
};
