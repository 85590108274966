import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const ItemContainer = styled.div`
  ${tw`flex gap-1 justify-between items-center py-3 border-b border-b-gray94`}
`;

export const ItemIndex = styled.div`
  ${tw`w-8`}
`;

export const Item17Text = styled.div<{ $isBold?: boolean; $isEnd?: boolean }>`
  ${tw`text-gray7 text-17-26`}

  ${({
    $isBold
  }) => ($isBold ? tw`font-bold` : tw`font-medium`)}

  ${({
    $isEnd
  }) => $isEnd && tw`text-end`}
`;

export const Item13Text = styled.div<{ $isRight?: boolean }>`
  ${tw`text-dim-gray text-13-21 font-medium`}
  ${({
    $isRight
  }) => $isRight && tw`text-right`}
`;

export const ItemDates = styled.div`
  ${tw`flex-1 flex flex-col gap-1`}
`;
