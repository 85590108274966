import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

import {
  TOP_SPACE, LEFT_SPACE
} from './constants';

export const CardContainer = styled.div<{
  $isDragging?: boolean;
  $count?: number;
  $isSelected?: boolean;
  $isActiveDrag: boolean;
}>`
  ${tw`p-2 rounded border shadow-card-invite flex flex-col gap-1`}

  ${({
    $isSelected
  }) => ($isSelected ? tw`border-button-blue` : tw`border-gray94`)}

  ${({
    $isDragging
  }) => ($isDragging ? tw`opacity-20 relative` : tw`opacity-100`)}

  ${({
    $isActiveDrag, $isSelected
  }) => $isActiveDrag && $isSelected && tw`opacity-20`}

  ${({
    $count, $isDragging
  }) => $count
    && $isDragging
    && tw`after:(absolute top-1 -left-1 opacity-100 z-9999 bg-button-blue text-white text-15-20 font-medium rounded-100px text-center w-max)`}
`;

export const CardContentContainer = styled.div`
  ${tw`flex gap-2 items-center justify-between`}
`;

export const CharacteristicsContainer = styled.div`
  ${tw`font-medium text-dim-gray text-xs`}
`;

export const DraggedCardContainer = styled.div<{ $top: number; $left: number }>`
  ${tw`fixed w-417px p-2 opacity-20 rounded border border-button-blue shadow-card-invite flex gap-2 items-center justify-between`}

  top: ${({
    $top
  }) => `${$top - TOP_SPACE}px`};

  left: ${({
    $left
  }) => `${$left - LEFT_SPACE}px`};
`;

export const SpaceForIcon = styled.div`
  ${tw`w-6`}
`;

export const CardIndex = styled.div`
  ${tw`w-8 text-15-20 font-medium text-dim-gray shrink-0`}
`;

export const CardDraggable = styled.div`
  ${tw`w-max`}
`;

export const InvitationData = styled.div`
  ${tw`flex-1 flex flex-col gap-0.5`}
`;

export const InvitationEmail = styled.div`
  ${tw`text-15-20 font-medium text-gray7`}
`;

export const InvitationGroup = styled.div<{ $isNotAssign?: boolean }>`
  ${tw` w-max px-2 rounded-100px text-13-21 font-medium`}

  ${({
    $isNotAssign
  }) => ($isNotAssign
    ? tw`bg-alice-blue text-dim-gray`
    : tw`bg-list-blue text-gray7`)}
`;

export const GroupsContainer = styled.div`
  ${tw`flex gap-1 flex-wrap`}
`;
