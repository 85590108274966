import React, {
  FC, Fragment, useState
} from 'react';
import clsx from 'clsx';
import {
  useTranslation
} from 'react-i18next';

import {
  CircleButton,
  Divider,
  MenuContainer,
  MenuItem,
  MenuTitle,
  PopoverMenu,
  warningToast,
} from 'src/shared/components';
import {
  ArrowRightIcon,
  DownloadIcon,
  EmailIcon,
  MoreVerticalIcon,
} from 'src/shared/icons';
import {
  onFileDownload,
  showWarningFromServer,
  themeColors,
} from 'src/shared/utils';
import {
  DownloadOrgMembersType,
  useGetOrgGroupsUsersDownloadMutation,
} from 'src/redux/openapi';

import {
  CommunityActions
} from '../../types';

interface CommunityMoreBtnProps {
  setActionType: (action: CommunityActions) => void;
  organizationId: string;
}

const availableTypes: DownloadOrgMembersType[] = ['csv', 'xlsx'];

// TODO: uncomment RemoveMembers when ready
const actionsButtons = [
  {
    value: CommunityActions.SendMessages,
    icon: EmailIcon,
  },
  {
    value: CommunityActions.DownloadList,
    icon: DownloadIcon,
  },
  // {
  //   value: CommunityActions.RemoveMembers,
  //   icon: PersonDeleteIcon,
  // },
];

export const CommunityMoreBtn: FC<CommunityMoreBtnProps> = ({
  setActionType,
  organizationId,
}) => {
  const {
    t
  } = useTranslation();

  const [shouldClose, setShouldClose] = useState(false);

  const handleButtonClick = (callback: () => void) => {
    if (shouldClose) {
      setShouldClose(false);
    }

    callback();
  };

  const clickHandler = (actionBtn: CommunityActions) => {
    setActionType(actionBtn);

    setShouldClose(true);
  };

  const [downloadAll] = useGetOrgGroupsUsersDownloadMutation();

  const onDownloadAll = async (type: DownloadOrgMembersType) => {
    try {
      const response = await downloadAll({
        id: organizationId,
        type,
      });

      if ('error' in response) {
        if ('data' in response.error) {
          warningToast(JSON.parse(response.error.data as string).message);
        }

        return;
      }

      onFileDownload(
        response.data as Blob,
        `users.${type}`
      );
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  return (
    <PopoverMenu
      placement="bottom-end"
      shouldForcedClose={shouldClose}
      renderCustomButton={({
        isOpen, toggleMenu, triggerProps
      }) => (
        <CircleButton
          onClick={() => handleButtonClick(toggleMenu)}
          borderColor={isOpen ? themeColors['button-blue'] : themeColors.gray90}
          {...triggerProps}
        >
          <MoreVerticalIcon
            className={clsx(
              'w-18px h-18px',
              {
                'text-dark-gray': !isOpen,
                'text-button-blue': isOpen,
              }
            )}
          />
        </CircleButton>
      )}
    >
      <MenuContainer $width={345}>
        {actionsButtons.map(({
          value, icon: Icon
        }) => {
          const isRemoveMembers = value === CommunityActions.RemoveMembers || undefined;

          const isDownload = value === CommunityActions.DownloadList;

          if (isDownload) {
            return (
              <PopoverMenu
                placement="right-start"
                key={value}
                possiblePlacements={['right-start', 'left-start']}
                renderCustomButton={({
                  isOpen, toggleMenu, triggerProps
                }) => (
                  <MenuItem
                    onClick={toggleMenu}
                    {...triggerProps}
                    className={clsx(
                      'hover:bg-alice-blue',
                      {
                        'bg-alice-blue': isOpen,
                      }
                    )}
                  >
                    <DownloadIcon className="w-6 h-6" />

                    <p>{t('group.downloadAllMembers')}</p>

                    <ArrowRightIcon />
                  </MenuItem>
                )}
              >
                <MenuContainer $width={243}>
                  <MenuTitle>{t('common.chooseFormat')}</MenuTitle>

                  {availableTypes.map((type) => (
                    <MenuItem
                      key={type}
                      className="hover:bg-cultured"
                      onClick={() => onDownloadAll(type)}
                      role="link"
                    >
                      {type?.toUpperCase()}
                    </MenuItem>
                  ))}
                </MenuContainer>
              </PopoverMenu>
            );
          }

          return (
            <Fragment key={value}>
              {isRemoveMembers && (
                <Divider
                  $color={themeColors.gray85}
                  className="rounded-10px"
                />
              )}

              <MenuItem
                $color={isRemoveMembers && themeColors['red-text-error']}
                onClick={() => clickHandler(value)}
              >
                <Icon />

                {t(`organisation.${value}`)}
              </MenuItem>
            </Fragment>
          );
        })}
      </MenuContainer>
    </PopoverMenu>
  );
};
