import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const Title = styled.p`
  ${tw`font-bold text-20-24 md:text-22-30 md:mb-6 mb-4`}
`;

export const ContentContainer = styled.div`
  ${tw`flex flex-col gap-6`}
`;
