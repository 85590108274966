import React, {
  FC, PropsWithChildren, ReactNode
} from 'react';

import {
  themeColors
} from 'src/shared/utils';

import * as Style from './ProgressCircle.styles';

interface ProgressCircleProps extends PropsWithChildren {
  progress?: number;
  size?: number;
  strokeWidth?: number;
  bgColor?: string;
  borderColor?: string;
  progressColor?: string;
  icon?: ReactNode;
}

export const ProgressCircle: FC<ProgressCircleProps> = ({
  progress,
  size = 88,
  strokeWidth = 2,
  bgColor = themeColors['alice-blue'],
  borderColor = themeColors['list-blue'],
  progressColor = themeColors['button-blue'],
  icon,
}) => {
  const radius = size / 2;
  const circumference = 2 * Math.PI * radius;

  return (
    <Style.MainContainer>
      <svg
        width={size}
        height={size}
      >
        <defs>
          <filter
            id="innerDropShadow"
            x="-2.00195"
            y="0"
            width="90"
            height="92"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood
              floodOpacity="0"
              result="BackgroundImageFix"
            />

            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />

            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />

            <feOffset
              dx="-2"
              dy="4"
            />

            <feGaussianBlur stdDeviation="4" />

            <feComposite
              in2="hardAlpha"
              operator="arithmetic"
              k2="-1"
              k3="1"
            />

            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.400316 0 0 0 0 0.423083 0 0 0 0 0.48 0 0 0 0.1 0"
            />

            <feBlend
              mode="normal"
              in2="shape"
              result="effect1_innerShadow_6433_71540"
            />
          </filter>
        </defs>

        <circle
          cx={radius}
          cy={radius}
          r={radius - strokeWidth / 2}
          fill={bgColor}
          stroke={borderColor}
          strokeWidth={strokeWidth}
          filter="url(#innerDropShadow)"
        />

        {progress && (
          <circle
            cx={radius}
            cy={radius}
            r={radius - strokeWidth / 2}
            fill="transparent"
            stroke={progressColor}
            strokeWidth={strokeWidth}
            strokeDasharray={circumference}
            strokeDashoffset={circumference * (1 - progress)}
            className="transition-all duration-700 ease-in-out"
          />
        )}
      </svg>

      <Style.ChildrenContainer $radius={radius}>
        {progress !== undefined && <p>{`${(progress * 100).toFixed(1)}%`}</p>}

        {icon}
      </Style.ChildrenContainer>
    </Style.MainContainer>
  );
};
