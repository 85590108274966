import React, {
  FC, useEffect, useMemo, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  Button, Spinner
} from 'src/shared/components';
import {
  useGetUserCurrentCharacteristicsQuery
} from 'src/redux/openapi';
import {
  useCustomInfiniteScroll
} from 'src/shared/hooks';

import * as Style from './CharacteristicsTabContent.styles';
import {
  CharacteristicsListItem, SaveCharacteristicsModal
} from './ui';
import {
  getSelectValues
} from './utils';
import {
  SelectedValue
} from './types';

interface CharacteristicsTabContentProps {
  onModalToggle: (isOpen: boolean) => void;
}

export const CharacteristicsTabContent: FC<CharacteristicsTabContentProps> = ({
  onModalToggle,
}) => {
  const [selectedValues, setSelectedValues] = useState<SelectedValue[]>([]);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [openItemId, setOpenItemId] = useState<string | null>(null);

  const {
    t
  } = useTranslation();

  const {
    data: characteristicsData,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useGetUserCurrentCharacteristicsQuery(
    {
      limit: 20,
      page: currentPage,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    total: totalCharacteristics,
    characteristics: currentCharacteristics,
  } = useMemo(
    () => characteristicsData?.data || {
      total: 0,
      characteristics: [],
    },
    [characteristicsData],
  );

  const toggleItemValues = (itemId?: string) => {
    const isOpened = openItemId === itemId;

    if (!isOpened && itemId) {
      setOpenItemId(itemId);
      onModalToggle(true);
    } else {
      setOpenItemId(null);
      onModalToggle(false);
    }
  };

  const toggleModal = () => {
    setIsSaveModalOpen((prev) => !prev);
    toggleItemValues();
  };

  useEffect(
    () => {
      const values = getSelectValues(currentCharacteristics);

      if (currentPage === 1) {
        setSelectedValues(values);
      } else {
        setSelectedValues((prev) => [...prev, ...values]);
      }
    },
    [currentCharacteristics]
  );

  const {
    sentryRef,
    allItems: characteristics,
    displayLoader,
  } = useCustomInfiniteScroll({
    total: totalCharacteristics,
    currentItems: currentCharacteristics,
    isFetching,
    isLoading,
    isError,
    currentPage,
    setCurrentPage,
  });

  const defaultSelectedCharacteristics = useMemo(
    () => getSelectValues(characteristics),
    [characteristics],
  );

  const resetCharacteristics = () => {
    toggleItemValues();
    setSelectedValues(defaultSelectedCharacteristics);
  };

  useEffect(
    () => {
      onModalToggle(isSaveModalOpen);
    },
    [isSaveModalOpen]
  );

  useEffect(
    () => {
      if (defaultSelectedCharacteristics.length) {
        resetCharacteristics();
      }
    },
    [defaultSelectedCharacteristics]
  );

  const onSuccessSave = () => {
    if (currentPage === 1) {
      refetch();
    } else {
      setCurrentPage(1);
    }
  };

  return (
    <Style.MainContainer>
      <Style.ContentWrapper>
        <Style.Title>{t('editAccount.myCharacteristics')}</Style.Title>

        {!characteristics.length ? (
          <>
            {isLoading && <Spinner size={24} />}

            {!isLoading && (
              <Style.EmptyMessage>
                {t('editAccount.noCharacteristics')}
              </Style.EmptyMessage>
            )}
          </>
        ) : (
          <Style.ListContainer className="scroll-hidden">
            {characteristics.map((characteristic) => {
              const {
                key: {
                  id
                },
                value,
              } = characteristic;

              const selectedValue = selectedValues.find(
                ({
                  id: valueId
                }) => valueId === id,
              )?.selectedOption || {
                value: value.id,
                label: value.value,
              };

              return (
                <CharacteristicsListItem
                  key={id}
                  selectedValue={selectedValue}
                  setSelectedValues={setSelectedValues}
                  characteristic={characteristic}
                  isOpen={openItemId === id}
                  toggleValues={toggleItemValues}
                />
              );
            })}

            {displayLoader && (
              <div ref={sentryRef}>
                <Spinner size={24} />
              </div>
            )}
          </Style.ListContainer>
        )}
      </Style.ContentWrapper>

      {!!characteristics.length && (
        <Style.ButtonsContainer>
          <Button
            variant="big-black"
            className="text-xl font-bold w-max"
            onClick={resetCharacteristics}
          >
            {t('buttons.cancel')}
          </Button>

          <Button
            variant="big-blue"
            className="text-xl font-bold w-max"
            onClick={toggleModal}
          >
            {t('buttons.saveChanges')}
          </Button>
        </Style.ButtonsContainer>
      )}

      <SaveCharacteristicsModal
        onClose={toggleModal}
        isOpen={isSaveModalOpen}
        selectedValues={selectedValues}
        onSuccess={onSuccessSave}
      />
    </Style.MainContainer>
  );
};
