import React, {
  FC
} from 'react';
import {
  Link
} from 'react-router-dom';
import {
  useTranslation
} from 'react-i18next';

import {
  CheckBoxFalseIcon, CheckBoxTrueIcon
} from 'src/shared/icons';
import {
  ROUTE
} from 'src/shared/utils';

import * as Style from './AcceptTermsBlock.styles';

interface AcceptTermsBlockProps {
  termsAccepted: boolean;
  handleTermsAccept: () => void;
  errorMessage?: string;
}

export const AcceptTermsBlock: FC<AcceptTermsBlockProps> = ({
  termsAccepted,
  handleTermsAccept,
  errorMessage,
}) => {
  const {
    t
  } = useTranslation();

  return (
    <div>
      <Style.Container>
        <button
          type="button"
          onClick={handleTermsAccept}
        >
          {termsAccepted ? <CheckBoxTrueIcon /> : <CheckBoxFalseIcon />}
        </button>

        <Style.Paragraph>
          {`${t('terms.iAgreeWith')} `}

          <Link
            to={`/${ROUTE.TERMS_REGULATIONS}`}
            target="_blank"
          >
            <Style.BlueSpan>{t('terms.termsConditions')}</Style.BlueSpan>
          </Link>

          {` ${t('common.and')} `}

          <Link
            to={`/${ROUTE.PRIVACY_POLICY}`}
            target="_blank"
          >
            <Style.BlueSpan>{t('terms.privacyPolicy')}</Style.BlueSpan>
          </Link>
        </Style.Paragraph>
      </Style.Container>

      {errorMessage && <Style.ErrorMessage>{errorMessage}</Style.ErrorMessage>}
    </div>
  );
};
