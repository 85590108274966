enum Links {
  ArqUsageBasics = 'arq-usage-basics',
  UsageContractBasics = 'usage-contract-basics',
  ConsumerWithdrawalRights = 'consumer-withdrawal-rights',
  UserDutiesPlatformSpecifics = 'user-duties-platform-specifics',
  ServiceTermsChanges = 'service-terms-changes',
  FeesBillingPaymentTerms = 'fees-billing-payment-terms',
  DurationTerminationSanctionsRefund = 'duration-termination-sanctions-refund',
  UserContentResponsibilityAvailability = 'user-content-responsibility-availability',
  ArqLiability = 'arq-liability',
  Privacy = 'privacy',
  ApplicableLawJurisdictionMisc = 'applicable-law-jurisdiction-misc',
}

export const termsAndConditions = [
  {
    id: null,
    label: null,
    content: `
      <h1>Allgemeine Gesch&auml;ftsbedingungen</h1>
      <h2>Stand: 30. Mai 2024</h2>
      <p><strong>"ARQ"</strong>&nbsp;bezeichnet im Folgenden den von der arq decisions GmbH betriebenen umfassenden und&nbsp;nachfolgend n&auml;her dargestellten Dienst mit&nbsp;seinen Anwendungen und/oder den Betreiber des umfassenden Dienstes, die arq decisions GmbH &bdquo;AD&ldquo;.</p>
      <h1>Allgemeine Bedingungen f&uuml;r die Nutzung des Dienstes ARQ</h1>
      <p>Der Dienst ARQ wird &uuml;ber Websites im Internet (z. B. arqdecisions.com, arqdecisions.de, fragenentscheiden.io) sowie unter verschiedenen Subdomains und Aliase dieser Domains und &uuml;ber mobile Applikationen (sogenannte "Apps"), Hintergrund-Applikationen, Webservices und Einbindungen in Drittseiten (z. B. per iFrame) erbracht. Alle von arq angebotenen Websites, Subdomains, Aliases, mobile Applikationen, Hintergrund-Applikationen, Webservices und Einbindungen in Drittseiten, auf denen arq zur Verf&uuml;gung steht, werden im Folgenden insgesamt die&nbsp;<strong>"ARQ Website"</strong>&nbsp;genannt.</p>
      <p>Zu den Anwendungen des Dienstes ARQ geh&ouml;ren insbesondere die Prognose Plattform (im Folgenden&nbsp;<strong>"Prognose Plattform"</strong>) und die&nbsp;<strong>"Lern Plattform"</strong> f&uuml;r die Kunden und Nutzer eine Mitgliedschaft erwerben k&ouml;nnen. Einige der Anwendungen treten dabei gegebenenfalls unter anderen Markennamen oder unter Nutzung von anderen ARQ Websites in Erscheinung.</p>
      <p>Diese Allgemeinen Bedingungen f&uuml;r die Nutzung des Dienstes ARQ regeln das Vertragsverh&auml;ltnis zwischen dem Nutzer und der AD, unabh&auml;ngig davon, &uuml;ber welche der ARQ Websites der Nutzer Leistungen von AD in Anspruch nimmt.</p>
      <p>Hinsichtlich der Nutzung einzelner Anwendungen gelten erg&auml;nzend zu diesen Allgemeinen Bedingungen f&uuml;r die Nutzung des Dienstes ARQ gegebenenfalls zus&auml;tzliche Bedingungen (z. B. Kommentar-Regeln f&uuml;r AD News). Im Fall eines Widerspruchs geht die Regelung der zus&auml;tzlichen Bedingungen f&uuml;r die Nutzung der jeweiligen Anwendung diesen Allgemeinen Bedingungen f&uuml;r die Nutzung des Dienstes ARQ vor, sofern dieser Vorrang nicht in den zus&auml;tzlichen Bedingungen f&uuml;r die Nutzung der jeweiligen Anwendung ausgeschlossen ist.</p>
      <p>Die Allgemeinen Bedingungen f&uuml;r die Nutzung des Dienstes ARQ und etwaige zus&auml;tzliche Bedingungen f&uuml;r die Nutzung einzelner Anwendungen werden im Folgenden insgesamt&nbsp;<strong>"AGB"</strong>&nbsp;genannt.</p>
      <p>Der Vertragsschluss erfolgt mit der arq decisions GmbH, Nobistor 16, 22767 Hamburg. Weitere Kontaktdaten, die Handelsregisterdaten sowie der Name einer vertretungsberechtigten Person der arq decisions GmbH k&ouml;nnen dem&nbsp;<a href="https://www.arqdecisions.com/impressum" target="_blank">Impressum</a>&nbsp;entnommen werden.</p>
    `,
  },
  {
    id: Links.ArqUsageBasics,
    label: 'Vertragsgegenstand und Grundlagen der Nutzung von ARQ',
    content: `
      <h2>Allgemein</h2>
      <p><strong>1.1</strong>&nbsp;ARQ ist ein Dienst, der den Zweck verfolgt, durch eine Vielfalt unterschiedlicher Anwendungen zur Verbesserung der Entscheidungskompetenz des Nutzers und seiner Organisationen beizutragen.</p>
      <p><strong>1.2</strong>&nbsp;Angesichts des zunehmenden Verschwimmens der Grenzen zwischen Arbeits- und Privatleben und der Wechselwirkungen zwischen beiden, konzentriert sich ARQ dabei nicht ausschlie&szlig;lich auf den professionellen Kontext, sondern bezieht auch Anwendungen im privaten Kontext mit ein.</p>
      <p><strong>1.3</strong>&nbsp;Insbesondere m&ouml;chte ARQ dem Nutzer neue M&ouml;glichkeiten er&ouml;ffnen (vorwiegend im professionellen Kontext, aber auch im privaten Kontext), Wissensaneignung und -vertiefung erm&ouml;glichen, Netzwerke zu bilden, Informationen demokratisieren, Informationsaustausch f&ouml;rdern und lebenslanges Lernen unterst&uuml;tzen. Um diese Zwecke zu erf&uuml;llen, stellt ARQ dem Nutzer unter anderem auf Basis erhobener Daten bestimmte Informationen, Angebote, Empfehlungen sowie Dienstleistungen bereit und f&ouml;rdert die Interaktion &ndash; innerhalb und au&szlig;erhalb des Netzwerks des Nutzers. Dies erfolgt entsprechend etwaiger Privatsph&auml;re-Einstellungen und Einwilligungserkl&auml;rungen des Nutzers.</p>
      <p><strong>1.4</strong>&nbsp;Die Prognose Plattform ist die Kern-Anwendung des Dienstes ARQ. Die im Rahmen der Plattform vom Nutzer erstellte Profilseite (im Folgenden&nbsp;<strong>"Nutzerprofil"</strong>&nbsp;genannt) stellt die Identit&auml;t dar, mit der der Nutzer innerhalb des Dienstes ARQ, auf den ARQ Websites und in den Anwendungen des Dienstes auftritt und dargestellt wird. Grunds&auml;tzlich ist f&uuml;r die Nutzung von ARQ ein auf der Plattform und au&szlig;erhalb sichtbares, vom Nutzer anonymisiertes Profil erforderlich. Es sind lediglich einzelne Anwendungen des Dienstes ARQ nutzbar, bei denen das Nutzerprofil auf der Plattform nicht sichtbar ist. Ebenso gibt es einzelne Anwendungen des Dienstes ARQ, bei denen der Nutzer nicht mit seinem Nutzerprofil auftritt und dargestellt wird. Es ist jedoch immer eine Registrierung f&uuml;r den Dienst ARQ erforderlich.</p>
      <h2>Prognose Plattform</h2>
      <p><strong>1.5</strong>&nbsp;Die Prognose Plattformbietet dem Nutzer diverse M&ouml;glichkeiten, sich mit dem eigenen Nutzerprofil und mit seinen Aktivit&auml;ten auf den ARQ Websites und au&szlig;erhalb zu pr&auml;sentieren und mit anderen Nutzern und Dritten in Echtzeit zu interagieren. Die volle Wirkung der Plattform kann nur bei Abrufbarkeit des Nutzerprofils auch f&uuml;r Nicht-Nutzer und Auffindbarkeit in Suchmaschinen erreicht werden, weshalb diese externe Abruf- und Auffindbarkeit des Nutzerprofils voreingestellt ist.</p>
      <p><strong>1.6</strong>&nbsp;Ein Nutzer, der sich f&uuml;r Die Prognose Plattform registriert, erwirbt zun&auml;chst eine unentgeltliche Mitgliedschaft auf der Plattform (im Folgenden&nbsp;<strong>"unentgeltliche Mitgliedschaft"</strong>&nbsp;genannt).</p>
      <p><strong>1.7</strong>&nbsp;Der Nutzer hat gegebenenfalls die M&ouml;glichkeit, seine unentgeltliche Mitgliedschaft auf eine entgeltliche Mitgliedschaft auf der Plattform umzustellen (die entgeltliche Mitgliedschaft auf der Plattform wird im Folgenden&nbsp;<strong>"Premium-Mitgliedschaft"</strong>&nbsp;genannt). Die Premium-Mitgliedschaft enth&auml;lt gegen&uuml;ber der unentgeltlichen Mitgliedschaft diverse zus&auml;tzliche und erweiterte Funktionen. Spezielle entgeltliche Mitgliedschaften f&uuml;r einzelne Zielgruppen (z. B. Trainings f&uuml;r Individuen) sind ebenfalls dem Begriff "Premium-Mitgliedschaft" zuzuordnen. Die unentgeltliche Mitgliedschaft und die Premium-Mitgliedschaft werden im Folgenden gemeinsam als&nbsp;<strong>"ARQ Mitgliedschaft"</strong>&nbsp;bezeichnet. Ggf. werden Zusatzpakete zur Premium-Mitgliedschaft angeboten, die der Nutzer zu seiner Premium-Mitgliedschaft hinzubuchen kann.&nbsp;</p>
      <p><strong>1.8</strong>&nbsp;Sofern ARQ dem Nutzer anbietet, eine oder mehrere Zusatzleistungen von Drittanbietern zu seiner ARQ Mitgliedschaft kostenlos oder kostenpflichtig hinzu zu buchen, so kommen diesbez&uuml;glich gesonderte, von der ARQ Mitgliedschaft getrennte Vertr&auml;ge zwischen dem Nutzer und den jeweiligen Drittanbietern zu Stande. Insbesondere haben die &Auml;nderung, Einstellung, sonstige Beendigung oder der Austausch der externen Leistungen oder Leistungsst&ouml;rungen im Verh&auml;ltnis zwischen Nutzer und Drittanbieter keinen Einfluss auf das Vertragsverh&auml;ltnis zwischen ARQ und dem Nutzer hinsichtlich der ARQ Mitgliedschaft. F&uuml;r Zusatzdienstleistungen von Drittanbietern gelten die Gesch&auml;ftsbedingungen und Datenschutzerkl&auml;rungen der jeweiligen Drittanbieter, die selbst Vertrags- und Ansprechpartner der Nutzer sind.</p>
      <h2>Lern Plattform</h2>
      <p><strong>1.9</strong>&nbsp;Bei der Lern Plattform als Teil des umfassenden Dienstes ARQ handelt es sich um eine Online-Plattform, auf der bzw. &uuml;ber die Prognose-, Entscheidungs- und weitere Kompetenz geschult und zertifiziert werden kann. Auf bzw. &uuml;ber&nbsp;die Lern Plattform k&ouml;nnen Unternehmen unter anderem Trainings ver&ouml;ffentlichen und zuweisen, sowie Talente identifizieren. Der umfassende Dienst ARQ unterst&uuml;tzt das Zusammenkommen von Prognose-Talent und Unternehmen. Dies erfolgt unter anderem durch die Empfehlung von Talenten sowie durch die Generierung und Bereitstellung relevanter Informationen und Analysen auf Basis von Daten, die innerhalb der Lern Plattform und ggf. in anderen ARQ-Anwendungen oder au&szlig;erhalb verarbeitet werden.&nbsp;</p>    
    `,
  },
  {
    id: Links.UsageContractBasics,
    label:
      'Nutzungsvoraussetzungen, Vertragsschluss und Zusicherungen bei Vertragsschluss',
    content: `
      <h2>Allgemein</h2>
      <p><strong>2.1</strong>&nbsp;Sofern der Nutzer eine Anwendung des Dienstes ARQ nutzt, die auch f&uuml;r Nutzer ohne ein auf der Plattform sichtbares Nutzerprofil nutzbar ist, wird ein Nutzerkonto f&uuml;r den Nutzer angelegt, falls ein solches noch nicht existiert. Dadurch kommt der Vertrag zur Nutzung von ARQ zwischen dem Nutzer und ARQ zu Stande. Bei dem Nutzerkonto handelt es sich um ein Nutzerprofil, das zun&auml;chst nicht auf der Plattform sichtbar ist. Der Nutzer kann unter seinem Nutzerkonto zun&auml;chst nur diejenigen Anwendungen des Dienstes ARQ nutzen, die auch ohne ein auf der Plattform sichtbares Nutzerprofil nutzbar sind. Wenn der Nutzer Anwendungen des Dienstes ARQ nutzen m&ouml;chte, f&uuml;r die ein auf der Plattform sichtbares Nutzerprofil erforderlich ist, muss er die Sichtbarkeit seines Nutzerprofils auf der Plattform zun&auml;chst best&auml;tigen bzw. freigeben.</p>
      <p><strong>2.2</strong>&nbsp;Die M&ouml;glichkeit zur Nutzung der auf den ARQ Website angebotenen Anwendungen stellt kein Angebot, sondern nur eine Aufforderung zur Abgabe eines Angebots dar. Durch den Abschluss des Buchungsvorgangs gibt der Nutzer ein Angebot zum Abschluss des Vertrages &uuml;ber die Nutzung der entsprechenden ARQ Anwendung ab. ARQ nimmt dieses Angebot des Nutzers durch Bereitstellung der entsprechenden Dienste an. Erst durch diese Annahme kommt der Vertrag zwischen dem Nutzer und ARQ zustande. ARQ ist nicht zum Vertragsschluss verpflichtet. Bei Inanspruchnahme von entgeltlichen Leistungen akzeptiert der Nutzer die damit verbundene Zahlungsverpflichtung durch Klicken auf das in diesem Fall angebotene Best&auml;tigungsfeld.</p>
      <p><strong>2.3</strong>&nbsp;Der Nutzer sichert zu, dass alle von ihm angegebenen Daten wahr und vollst&auml;ndig sind. Der Nutzer ist verpflichtet, die Daten hinsichtlich aller von ihm genutzten Anwendungen w&auml;hrend der gesamten Vertragslaufzeit wahr und vollst&auml;ndig zu halten. F&uuml;r den Fall, dass der Kundenservice von ARQ im Auftrag des Nutzers manuelle &Auml;nderungen an den Daten des Nutzers vornimmt, beh&auml;lt sich ARQ vor, die daraus f&uuml;r ARQ gegebenenfalls entstehenden Kosten dem Nutzer in Rechnung zu stellen, in dem Umfang wie der Nutzer das Kosten ausl&ouml;sende Ereignis zu vertreten hat.</p>
      <p><strong>2.4</strong>&nbsp;Der Nutzer sichert zu, dass er vollj&auml;hrig ist. F&uuml;r den Fall, dass der Nutzer in Rahmen bestimmter Anwendungen f&uuml;r eine juristische Person t&auml;tig wird, sichert der Nutzer zu, bevollm&auml;chtigt zu sein, um im Namen der juristischen Person t&auml;tig zu werden. Der Nutzer wird ARQ auf Anfrage die gem&auml;&szlig; dieser Ziffer zugesicherten Angaben nachweisen.</p>
      <p><strong>2.5</strong>&nbsp;ARQ kann technisch nicht mit Sicherheit feststellen, ob ein auf den ARQ Website registrierter Nutzer tats&auml;chlich diejenige Person darstellt, die der Nutzer vorgibt zu sein. ARQ leistet daher keine Gew&auml;hr f&uuml;r die tats&auml;chliche Identit&auml;t eines Nutzers.</p>
      <h2>Prognose Plattform</h2>
      <p><strong>2.6</strong>&nbsp;F&uuml;r den Erwerb einer ARQ Mitgliedschaft ist eine Registrierung erforderlich, bei der der Nutzer unter anderem ein Passwort w&auml;hlen muss.</p>
      <p><strong>2.7</strong>&nbsp;Der Nutzer ist verpflichtet, das von ihm gew&auml;hlte Passwort geheim zu halten. ARQ wird das Passwort nicht an Dritte weitergeben, nur f&uuml;r den Anmeldevorgang benutzen und den Nutzer &ndash; vom Anmeldevorgang abgesehen &ndash; zu keinem Zeitpunkt nach dem Passwort fragen. Aus Sicherheitsgr&uuml;nden und um Missbrauch vorzubeugen, wird dem Nutzer empfohlen, sein Passwort in regelm&auml;&szlig;igen Abst&auml;nden zu &auml;ndern. Die Sicherung und Aufbewahrung der Zugangsdaten zum ARQ Mitgliedskonto (insbesondere des Passworts) f&auml;llt in den ausschlie&szlig;lichen Verantwortungsbereich des Nutzers.</p>
      <p><strong>2.8</strong>&nbsp;Jeder Nutzer darf nur ein Nutzerprofil anlegen. Der Nutzer darf Dritten nicht gestatten, das eigene Nutzerprofil zu nutzen.</p>    
    `,
  },
  {
    id: Links.ConsumerWithdrawalRights,
    label: 'Widerrufsrecht für Verbraucher',
    content: `
      <h2>Widerrufsbelehrung und Muster-Widerrufsformular</h2>
      <h2>Widerrufsrecht</h2>
      <p>Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gr&uuml;nden diesen Vertrag zu widerrufen. Die Widerrufsfrist betr&auml;gt vierzehn Tage ab dem Tag des Vertragsabschlusses. Um Ihr Widerrufsrecht auszu&uuml;ben, m&uuml;ssen Sie uns</p>
      <p>arq decisions GmbH<br />Nobistor 15<br />22767 Hamburg<br />E-Mail:&nbsp;<a href="mailto:kontaktformular@arqdecisions.com" target="_blank">kontaktformular@arqdecisions.com</a></p>
      <p>mittels einer eindeutigen Erkl&auml;rung (z. B. ein mit der Post versandter Brief, E-Mail oder &uuml;ber unser von jeder Seite aus erreichbare&nbsp;<a href="https://www.arqdecisions.com/kontakt" target="_blank">Kontaktformular</a>) &uuml;ber Ihren Entschluss, diesen Vertrag zu widerrufen, informieren.&nbsp;</p>
      <p>Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung &uuml;ber die Aus&uuml;bung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.</p>
      <h2>Folgen des Widerrufs</h2>
      <p>Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschlie&szlig;lich der Lieferkosten (mit Ausnahme der zus&auml;tzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, g&uuml;nstige Standardlieferung gew&auml;hlt haben), unverz&uuml;glich und sp&auml;testens binnen vierzehn Tagen ab dem Tag zur&uuml;ckzahlen, an dem die Mitteilung &uuml;ber Ihren Widerruf dieses Vertrags bei uns eingegangen ist. F&uuml;r diese R&uuml;ckzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der urspr&uuml;nglichen Transaktion eingesetzt haben, es sei denn, mit ihnen wurde ausdr&uuml;cklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser R&uuml;ckzahlung Entgelte berechnet.</p>
      <p>Haben Sie verlangt, dass die Dienstleistung w&auml;hrend der Widerrufsfrist beginnen soll, so haben Sie uns einen angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem Zeitpunkt, zu dem Sie uns von der Aus&uuml;bung des Widerrufsrechts hinsichtlich dieses Vertrags unterrichten, bereits erbrachte Dienstleistungen im Vergleich zum Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen entspricht.</p>
      <h2>Ausschluss des Widerrufsrechts:</h2>
      <p>Das Widerrufsrecht besteht nicht, sofern Sie bei Abschluss des Rechtsgesch&auml;fts in &uuml;berwiegender Aus&uuml;bung ihrer gewerblichen oder selbst&auml;ndigen beruflichen T&auml;tigkeit handeln und Sie deshalb als Unternehmer anzusehen sind (&sect; 14 BGB).</p>
      <p>Das Widerrufsrecht gegen&uuml;ber der arq decisions GmbH gilt nicht hinsichtlich solcher Vertr&auml;ge, die nicht zwischen Ihnen und der arq decisions GmbH, sondern zwischen Ihnen und einem Dritten zu Stande kommen. Etwaige Widerrufsrechte k&ouml;nnen diesbez&uuml;glich nur gegen&uuml;ber dem Dritten geltend gemacht werden.</p>
      <h2>Weitere wichtige Hinweise:</h2>
      <p>Sie stimmen ausdr&uuml;cklich zu, dass wir vor Ende der Widerrufsfrist mit der Ausf&uuml;hrung der Dienstleistung beginnen.</p>    
    `,
  },
  {
    id: Links.UserDutiesPlatformSpecifics,
    label:
      'Allgemeine Pflichten des Nutzers und besondere Bestimmungen für die Nutzung der Plattform sowie der Arbeitgeberbewertungsplattform',
    content: `
      <p><strong>4.1</strong>&nbsp;Der Nutzer ist verpflichtet,</p>
      <ul>
      <li>(a) ausschlie&szlig;lich wahre und nicht irref&uuml;hrende Angaben zu machen, sowie seinen Klarnamen anzugeben, wobei der Nutzername geltendes Recht beachtend frei gew&auml;hlt werden darf;</li>
      <li>(b) als Profilbild im Nutzerprofil im Rahmen der Plattform nur ein solches Foto zu verwenden und zu &uuml;bermitteln, dessen &ouml;ffentliche Wiedergabe auf den ARQ Websites nach geltendem Recht erlaubt ist.&nbsp;</li>
      <li>(c) bei der Nutzung von ARQ und der Inhalte auf den ARQ Websites geltendes Recht sowie alle Rechte Dritter zu beachten. Es ist dem Nutzer insbesondere Folgendes untersagt:</li>
      <ul>
      <li>Verwendung beleidigender oder verleumderischer Inhalte, unabh&auml;ngig davon, ob diese Inhalte andere Nutzer, ARQ Mitarbeiter oder andere Personen oder Unternehmen betreffen;</li>
      <li>Verwendung pornografischer, gewaltverherrlichender, missbr&auml;uchlicher, sittenwidriger oder Jugendschutzgesetze verletzende Inhalte oder Bewerbung, Angebot und/oder Vertrieb von pornografischen, gewaltverherrlichenden, missbr&auml;uchlichen, sittenwidrigen oder Jugendschutzgesetze verletzende Waren oder Dienstleistungen;</li>
      <li>unzumutbare Bel&auml;stigungen anderer Nutzer, insbesondere durch Spam (vgl. &sect; 7 Gesetz gegen den unlauteren Wettbewerb &ndash; UWG);</li>
      <li>Verwendung von gesetzlich (z. B. durch das Urheber-, Marken-, Patent-, Geschmacksmuster- oder Gebrauchsmusterrecht) gesch&uuml;tzten Inhalten, ohne dazu berechtigt zu sein, oder Bewerbung, Angebot und/oder Vertrieb von gesetzlich gesch&uuml;tzten Waren oder Dienstleistungen, ebenfalls ohne dazu berechtigt zu sein; oder</li>
      <li>Vornahme oder F&ouml;rderung wettbewerbswidriger Handlungen, einschlie&szlig;lich progressiver Kundenwerbung (wie Ketten-, Schneeball- oder Pyramidensysteme);</li>
      </ul>
      <li>(d) die folgenden bel&auml;stigenden Handlungen zu unterlassen, auch wenn diese konkret keine Gesetze verletzen sollten:</li>
      <ul>
      <li>Versendung von Kettenbriefen;</li>
      <li>Durchf&uuml;hrung, Bewerbung und F&ouml;rderung von Strukturvertriebsma&szlig;nahmen (wie Multi-Level-Marketing oder Multi-Level-Network-Marketing); sowie</li>
      <li>Vornahme von anz&uuml;glicher oder sexuell gepr&auml;gter Kommunikation (explizit oder implizit).</li>
      </ul>
      </ul>
      <p><strong>4.2</strong>&nbsp;Folgende Handlungen sind dem Nutzer untersagt:</p>
      <ul>
      <li>(a) Verwendung von Mechanismen, Software oder Scripts in Verbindung mit der Nutzung der ARQ Website. Die direkte oder indirekte Bewerbung oder Verbreitung solcher Mechanismen, Software oder Scripts ist ebenfalls untersagt. Der Nutzer darf jedoch von ARQ autorisierte Schnittstellen oder Software nutzen, bewerben oder verbreiten;</li>
      <li>(b) Blockieren, &Uuml;berschreiben, Modifizieren, Kopieren, soweit dies nicht f&uuml;r die ordnungsgem&auml;&szlig;e Nutzung von ARQ erforderlich ist;</li>
      <li>(c) Verbreitung und &ouml;ffentliche Wiedergabe von Inhalten der ARQ Website oder von anderen Nutzern, au&szlig;er diese Verbreitung und &ouml;ffentliche Wiedergabe ist im Rahmen der jeweiligen ARQ Anwendung vorgesehen oder der andere Nutzer hat der Verbreitung und &ouml;ffentlichen Wiedergabe zugestimmt; und/oder</li>
      <li>(d) jede Handlung, die geeignet ist, die Funktionalit&auml;t der ARQ Infrastruktur zu beeintr&auml;chtigen, insbesondere diese &uuml;berm&auml;&szlig;ig zu belasten.</li>
      </ul>
      <p><strong>4.3</strong>&nbsp;ARQ ist nicht verpflichtet, vom Nutzer bereitgestellte Daten und/oder Informationen anderen Nutzern zur Verf&uuml;gung zu stellen, wenn diese Daten und/oder Informationen nicht im Einklang mit den Ziffern 4.1 und 4.2 stehen. ARQ ist berechtigt, nach den Ziffern 4.1 und 4.2 unzul&auml;ssige Daten und/oder Informationen ohne Vorank&uuml;ndigung gegen&uuml;ber dem Nutzer von den ARQ Website zu entfernen.</p>
      <p><strong>4.4</strong>&nbsp;Der Nutzer erkl&auml;rt sich damit einverstanden, dass sein Score und seine Zertifikate einzeln und in G&auml;nze in Verbindung mit seinem Nutzernamen publik gemacht werden.</p>
      <p><strong>4.5</strong>&nbsp;Der Nutzer erkl&auml;rt sich damit einverstanden, dass seine Kommentare durch ARQ editiert bzw. gek&uuml;rzt werden.</p>
      <p><strong>4.6</strong>&nbsp;Der Nutzer erkl&auml;rt sich damit einverstanden, dass seine hier genannten Inhalte weltweit ausgespielt werden, auch bei Diensten Dritter.</p>
    `,
  },
  {
    id: Links.ServiceTermsChanges,
    label: 'Änderungen der Leistungen sowie der AGB',
    content: `
      <p><strong>4.1</strong>&nbsp;Der Nutzer ist verpflichtet,</p>
      <ul>
      <li>(a) ausschlie&szlig;lich wahre und nicht irref&uuml;hrende Angaben zu machen, sowie seinen Klarnamen anzugeben, wobei der Nutzername geltendes Recht beachtend frei gew&auml;hlt werden darf;</li>
      <li>(b) als Profilbild im Nutzerprofil im Rahmen der Plattform nur ein solches Foto zu verwenden und zu &uuml;bermitteln, dessen &ouml;ffentliche Wiedergabe auf den ARQ Websites nach geltendem Recht erlaubt ist.&nbsp;</li>
      <li>(c) bei der Nutzung von ARQ und der Inhalte auf den ARQ Websites geltendes Recht sowie alle Rechte Dritter zu beachten. Es ist dem Nutzer insbesondere Folgendes untersagt:</li>
      <ul>
      <li>Verwendung beleidigender oder verleumderischer Inhalte, unabh&auml;ngig davon, ob diese Inhalte andere Nutzer, ARQ Mitarbeiter oder andere Personen oder Unternehmen betreffen;</li>
      <li>Verwendung pornografischer, gewaltverherrlichender, missbr&auml;uchlicher, sittenwidriger oder Jugendschutzgesetze verletzende Inhalte oder Bewerbung, Angebot und/oder Vertrieb von pornografischen, gewaltverherrlichenden, missbr&auml;uchlichen, sittenwidrigen oder Jugendschutzgesetze verletzende Waren oder Dienstleistungen;</li>
      <li>unzumutbare Bel&auml;stigungen anderer Nutzer, insbesondere durch Spam (vgl. &sect; 7 Gesetz gegen den unlauteren Wettbewerb &ndash; UWG);</li>
      <li>Verwendung von gesetzlich (z. B. durch das Urheber-, Marken-, Patent-, Geschmacksmuster- oder Gebrauchsmusterrecht) gesch&uuml;tzten Inhalten, ohne dazu berechtigt zu sein, oder Bewerbung, Angebot und/oder Vertrieb von gesetzlich gesch&uuml;tzten Waren oder Dienstleistungen, ebenfalls ohne dazu berechtigt zu sein; oder</li>
      <li>Vornahme oder F&ouml;rderung wettbewerbswidriger Handlungen, einschlie&szlig;lich progressiver Kundenwerbung (wie Ketten-, Schneeball- oder Pyramidensysteme);</li>
      </ul>
      <li>(d) die folgenden bel&auml;stigenden Handlungen zu unterlassen, auch wenn diese konkret keine Gesetze verletzen sollten:</li>
      <ul>
      <li>Versendung von Kettenbriefen;</li>
      <li>Durchf&uuml;hrung, Bewerbung und F&ouml;rderung von Strukturvertriebsma&szlig;nahmen (wie Multi-Level-Marketing oder Multi-Level-Network-Marketing); sowie</li>
      <li>Vornahme von anz&uuml;glicher oder sexuell gepr&auml;gter Kommunikation (explizit oder implizit).</li>
      </ul>
      </ul>
      <p><strong>4.2</strong>&nbsp;Folgende Handlungen sind dem Nutzer untersagt:</p>
      <ul>
      <li>(a) Verwendung von Mechanismen, Software oder Scripts in Verbindung mit der Nutzung der ARQ Website. Die direkte oder indirekte Bewerbung oder Verbreitung solcher Mechanismen, Software oder Scripts ist ebenfalls untersagt. Der Nutzer darf jedoch von ARQ autorisierte Schnittstellen oder Software nutzen, bewerben oder verbreiten;</li>
      <li>(b) Blockieren, &Uuml;berschreiben, Modifizieren, Kopieren, soweit dies nicht f&uuml;r die ordnungsgem&auml;&szlig;e Nutzung von ARQ erforderlich ist;</li>
      <li>(c) Verbreitung und &ouml;ffentliche Wiedergabe von Inhalten der ARQ Website oder von anderen Nutzern, au&szlig;er diese Verbreitung und &ouml;ffentliche Wiedergabe ist im Rahmen der jeweiligen ARQ Anwendung vorgesehen oder der andere Nutzer hat der Verbreitung und &ouml;ffentlichen Wiedergabe zugestimmt; und/oder</li>
      <li>(d) jede Handlung, die geeignet ist, die Funktionalit&auml;t der ARQ Infrastruktur zu beeintr&auml;chtigen, insbesondere diese &uuml;berm&auml;&szlig;ig zu belasten.</li>
      </ul>
      <p><strong>4.3</strong>&nbsp;ARQ ist nicht verpflichtet, vom Nutzer bereitgestellte Daten und/oder Informationen anderen Nutzern zur Verf&uuml;gung zu stellen, wenn diese Daten und/oder Informationen nicht im Einklang mit den Ziffern 4.1 und 4.2 stehen. ARQ ist berechtigt, nach den Ziffern 4.1 und 4.2 unzul&auml;ssige Daten und/oder Informationen ohne Vorank&uuml;ndigung gegen&uuml;ber dem Nutzer von den ARQ Website zu entfernen.</p>
      <p><strong>4.4</strong>&nbsp;Der Nutzer erkl&auml;rt sich damit einverstanden, dass sein Score und seine Zertifikate einzeln und in G&auml;nze in Verbindung mit seinem Nutzernamen publik gemacht werden.</p>
      <p><strong>4.5</strong>&nbsp;Der Nutzer erkl&auml;rt sich damit einverstanden, dass seine Kommentare durch ARQ editiert bzw. gek&uuml;rzt werden.</p>
      <p><strong>4.6</strong>&nbsp;Der Nutzer erkl&auml;rt sich damit einverstanden, dass seine hier genannten Inhalte weltweit ausgespielt werden, auch bei Diensten Dritter.</p>
    `,
  },
  {
    id: Links.FeesBillingPaymentTerms,
    label: 'Entgelte, Abrechnung, Rechnungsstellung, Zahlungsbedingungen',
    content: `
      <h2>Allgemein</h2>
      <p><strong>6.1</strong>&nbsp;Der Nutzer kann Rechnungen mittels der von ARQ angebotenen Zahlungsverfahren begleichen. Kann ein Entgelt nicht eingezogen werden, tr&auml;gt der Nutzer alle ARQ daraus entstehenden Kosten, insbesondere Bankgeb&uuml;hren im Zusammenhang mit der R&uuml;ckgabe von Lastschriften und vergleichbare Geb&uuml;hren, in dem Umfang, wie er das Kosten ausl&ouml;sende Ereignis zu vertreten hat.</p>
      <p><strong>6.2</strong>&nbsp;Es steht ARQ frei, Rechnungen und Zahlungserinnerungen ausschlie&szlig;lich auf elektronischem Weg zu &uuml;bermitteln.</p>
      <p><strong>6.3</strong>&nbsp;ARQ beh&auml;lt sich vor, die Abrechnung jederzeit von Euro auf die jeweilige Landesw&auml;hrung des Nutzers umzustellen, sofern der Nutzer dem nicht widerspricht.</p>
      <p><strong>6.4</strong>&nbsp;Die Entgelte sind, soweit nicht anders geregelt mit Rechnungsstellung f&uuml;r die gesamte Laufzeit bzw. den jeweiligen Abrechnungszeitraum sofort im Voraus zur Zahlung f&auml;llig.</p>
      <h2>Premium-Mitgliedschaft</h2>
      <p><strong>6.5</strong>&nbsp;ARQ beh&auml;lt sich vor, das Entgelt f&uuml;r die Premium-Mitgliedschaft mit Wirkung zum Beginn eines neuen Verl&auml;ngerungszeitraums, der entweder auf den Mindestnutzungszeitraum oder auf den jeweils laufenden Verl&auml;ngerungszeitraum folgt, angemessen zu erh&ouml;hen. Pro Kalenderjahr findet maximal eine Preiserh&ouml;hung statt. In diesem Fall wird ARQ den Nutzer mindestens sechs (6) Wochen vor Ende des Mindestnutzungszeitraums bzw. des jeweils laufenden Verl&auml;ngerungszeitraums entsprechend informieren. Sofern der Nutzer mit dieser Entgelterh&ouml;hung nicht einverstanden ist, kann er dieser binnen drei (3) Wochen per Kontaktformular, Brief oder E-Mail widersprechen. Der Widerspruch entspricht einer K&uuml;ndigung der Premium-Mitgliedschaft durch den Nutzer, so dass die Premium-Mitgliedschaft mit Ablauf des bestehenden Mindestnutzungszeitraums bzw. des laufenden Verl&auml;ngerungszeitraums endet.</p>
      <h2>Besonderheit beim Zahlungsverfahren Kreditkarte</h2>
      <p><strong>6.6</strong>&nbsp;Bei Neukunden mit gew&auml;hlter Zahlart Kreditkarte wird ARQ mit der Testbuchung eines Kleinstbetrags die Kreditkarte des Nutzers &uuml;berpr&uuml;fen und f&uuml;r die sp&auml;tere Abbuchung des Rechnungsbetrags nach Rechnungsstellung verifizieren. Die Testbuchung wird kurzfristig, in der Regel innerhalb von 24 Stunden, wieder freigegeben.</p>
    `,
  },
  {
    id: Links.DurationTerminationSanctionsRefund,
    label:
      'Laufzeit, Beendigung des Vertrags, Sanktionen, Rückzahlung von im Voraus bezahlten Entgelten',
    content: `
      <h2>Allgemein</h2>
      <p><strong>7.1</strong>&nbsp;Wenn im Einzelfall keine besonderen Bestimmungen zur Laufzeit und K&uuml;ndigung einzelner Anwendungen oder ARQ Mitgliedschaften entgegenstehen, kann das Vertragsverh&auml;ltnis von beiden Seiten jederzeit beendet werden.</p>
      <h2>Unentgeltliche Mitgliedschaft</h2>
      <p><strong>7.2</strong>&nbsp;Der Nutzer und ARQ k&ouml;nnen die unentgeltliche Mitgliedschaft jederzeit ohne Angabe von Gr&uuml;nden k&uuml;ndigen. Zum Schutz des Nutzers gegen unbefugte L&ouml;schung seines Nutzerprofils durch Dritte kann ARQ bei der K&uuml;ndigung eine Identit&auml;tsfeststellung durchf&uuml;hren, z. B. durch Abfrage des Benutzernamens und einer auf den ARQ Website registrierten E-Mail-Adresse.</p>
      <h2>Premium-Mitgliedschaft</h2>
      <p><strong>7.3</strong>&nbsp; Die Premium-Mitgliedschaft l&auml;uft zun&auml;chst &uuml;ber den vom Nutzer gebuchten Mindestnutzungszeitraum. Danach verl&auml;ngert sich die Premium-Mitgliedschaft jeweils um Verl&auml;ngerungszeitr&auml;ume der gleichen Dauer, wenn sie nicht fristgem&auml;&szlig; vom Nutzer oder ARQ gek&uuml;ndigt wird. Der Nutzer und ARQ k&ouml;nnen die Premium-Mitgliedschaft ohne Angabe von Gr&uuml;nden mit einer Frist von drei (3) Wochen zum Ablauf des im Registrierungsprozess gebuchten Mindestnutzungszeitraums oder anschlie&szlig;end zum Ablauf eines Verl&auml;ngerungszeitraums per Kontaktformular, Brief oder E-Mail k&uuml;ndigen. Die K&uuml;ndigung kann per Brief, Fax oder E-Mail erfolgen. Dem Nutzer stehen f&uuml;r die K&uuml;ndigung dar&uuml;ber hinaus das Kontaktformular sowie die Online-K&uuml;ndigungsschaltfl&auml;che zur Verf&uuml;gung.&nbsp;Nach der K&uuml;ndigung der Premium-Mitgliedschaft durch den Nutzer oder ARQ bleibt dem Nutzer die unentgeltliche Mitgliedschaft bis zu ihrer Beendigung erhalten, die zus&auml;tzlichen und erweiterten Funktionen der Premium-Mitgliedschaft fallen jedoch weg.</p>
      <h2>K&uuml;ndigung und Sperrung aus wichtigem Grund</h2>
      <p><strong>7.4</strong>&nbsp;Auch bei Vorliegen besonderer Bestimmungen zur Laufzeit und K&uuml;ndigung haben beide Parteien das Recht, das Vertragsverh&auml;ltnis bei Vorliegen eines wichtigen Grundes jederzeit ohne Einhaltung einer Frist au&szlig;erordentlich zu k&uuml;ndigen. Ein wichtiger Grund zur au&szlig;erordentlichen K&uuml;ndigung liegt vor, wenn die Fortsetzung des Vertragsverh&auml;ltnisses bis zum Ablauf der gesetzlichen K&uuml;ndigungsfrist f&uuml;r die k&uuml;ndigende Partei unter Ber&uuml;cksichtigung aller Umst&auml;nde des Einzelfalls und unter Abw&auml;gung der Interessen beider Parteien nicht zumutbar ist. Wichtige Gr&uuml;nde sind f&uuml;r ARQ insbesondere die folgenden Ereignisse:</p>
      <ul>
      <li>(a) Nichteinhaltung gesetzlicher Vorschriften durch den Nutzer;</li>
      <li>(b) Versto&szlig; des Nutzers gegen seine vertraglichen Pflichten;</li>
      <li>(c) der Ruf der auf den ARQ Websites angebotenen Dienste wird durch die Pr&auml;senz des Nutzers erheblich beeintr&auml;chtigt (z. B., wenn sich nach Registrierung des Nutzers herausstellt, dass der Nutzer wegen einer vors&auml;tzlichen Straftat rechtskr&auml;ftig verurteilt ist und anderen Nutzern diese Verurteilung bekannt ist);</li>
      <li>(d) der Nutzer wirbt f&uuml;r Vereinigungen oder Gemeinschaften &ndash; oder deren Methoden oder Aktivit&auml;ten &ndash;, die von Sicherheits- oder Jugendschutzbeh&ouml;rden beobachtet werden;</li>
      <li>(e) der Nutzer sch&auml;digt einen oder mehrere andere Nutzer; oder</li>
      <li>(f) der Nutzer ist Mitglied einer Sekte oder einer in Deutschland umstrittenen Glaubensgemeinschaft.</li>
      </ul>
      <p><strong>7.5</strong>&nbsp;Bei Vorliegen eines wichtigen Grundes nach Ziffer 7.4 kann ARQ unabh&auml;ngig von einer K&uuml;ndigung nach Ziffer 7.4 auch die folgenden Sanktionen gegen den Nutzer verh&auml;ngen:</p>
      <ul>
      <li>(a) L&ouml;schung von Inhalten, die der Nutzer eingestellt hat;</li>
      <li>(b) Sperrung des Zugangs zum Dienst der ARQ Website oder einzelner Anwendungen; oder</li>
      <li>(c) Ausspruch einer Abmahnung.</li>
      </ul>
      <p><strong>7.6</strong>&nbsp;Der Anspruch des Nutzers auf R&uuml;ckzahlung bereits im Voraus bezahlter Entgelte bei K&uuml;ndigung ist ausgeschlossen, es sei denn, der Nutzer k&uuml;ndigt aufgrund eines wichtigen Grundes, der von ARQ zu vertreten ist. In folgenden F&auml;llen ist der Anspruch des Nutzers auf R&uuml;ckzahlung bereits im Voraus bezahlter Entgelte auf jeden Fall ausgeschlossen:</p>
      <ul>
      <li>(a) ARQ k&uuml;ndigt den Vertrag aus wichtigem Grund; oder</li>
      <li>(b) ARQ sperrt den Zugang des Nutzers gem&auml;&szlig; Ziffer 7.5.</li>
      </ul>
    `,
  },
  {
    id: Links.UserContentResponsibilityAvailability,
    label:
      'Verantwortlichkeit für Inhalte, Daten und/oder Informationen der Nutzer, Verfügbarkeit',
    content: `
      <p><strong>8.1</strong>&nbsp;ARQ &uuml;bernimmt keine Verantwortung f&uuml;r die von den Nutzern der ARQ Website bereitgestellten Inhalte, Daten und/oder Informationen sowie f&uuml;r Inhalte auf verlinkten externen Websites. ARQ gew&auml;hrleistet insbesondere nicht, dass diese Inhalte wahr sind, einen bestimmten Zweck erf&uuml;llen oder einem solchen Zweck dienen k&ouml;nnen.</p>
      <p><strong>8.2</strong>&nbsp;Soweit der Nutzer eine gesetzes- oder vertragswidrige Benutzung der ARQ Website (einschlie&szlig;lich der Verwendung von Pseudonymen oder t&auml;uschenden Identit&auml;ten) bemerkt, kann er diese melden.</p>
      <p><strong>8.3</strong>&nbsp;Hinsichtlich Daten oder Inhalten, die gem&auml;&szlig; den AGB, den Datenschutzbestimmungen von ARQ oder den Einstellungen des Nutzers &ouml;ffentlich verf&uuml;gbar sind oder waren und durch einen Dritten gespeichert wurden, trifft ARQ keine Pflicht zur Unterbindung einer solchen Speicherung beim Dritten.</p>
      <p><strong>8.4</strong>&nbsp;ARQ bietet dem Nutzer lediglich eine Plattform und einen definierten Katalog an zu prognostizierenden Fragen an und beteiligt sich grunds&auml;tzlich nicht inhaltlich an der Kommunikation der Nutzer untereinander. Dies gilt nicht, soweit ARQ im Einzelfall die Moderation selbst durchf&uuml;hrt. Sofern die Nutzer &uuml;ber die ARQ Website Vertr&auml;ge untereinander schlie&szlig;en, ist ARQ hieran nicht beteiligt und wird daher kein Vertragspartner. Die Nutzer sind f&uuml;r die Abwicklung und die Erf&uuml;llung der untereinander geschlossenen Vertr&auml;ge allein verantwortlich. ARQ haftet nicht f&uuml;r Pflichtverletzungen der Nutzer aus den zwischen den Nutzern geschlossenen Vertr&auml;gen.</p>
      <p><strong>8.5</strong>&nbsp;Der Nutzer erkennt an, dass eine 100%ige Verf&uuml;gbarkeit der ARQ Website technisch nicht zu realisieren ist. ARQ bem&uuml;ht sich jedoch, die ARQ Website m&ouml;glichst konstant verf&uuml;gbar zu halten. Insbesondere Wartungs-, Sicherheits- oder Kapazit&auml;tsbelange sowie Ereignisse, die nicht im Machtbereich von ARQ stehen (wie z. B. St&ouml;rungen von &ouml;ffentlichen Kommunikationsnetzen, Stromausf&auml;lle etc.), k&ouml;nnen zu St&ouml;rungen oder zur vor&uuml;bergehenden Einstellung des Dienstes auf den ARQ Website f&uuml;hren.</p>
    `,
  },
  {
    id: Links.ArqLiability,
    label: 'Haftung von ARQ',
    content: `
      <p><strong>9.1</strong>&nbsp;ARQ haftet unbeschr&auml;nkt f&uuml;r Sch&auml;den aus der Verletzung des Lebens, des K&ouml;rpers oder der Gesundheit, die auf einer Pflichtverletzung von ARQ, eines gesetzlichen Vertreters oder Erf&uuml;llungsgehilfen von ARQ beruhen sowie f&uuml;r Sch&auml;den, die durch Fehlen einer von ARQ garantierten Beschaffenheit hervorgerufen wurden oder bei arglistigem Verhalten von ARQ. Des Weiteren haftet ARQ unbeschr&auml;nkt f&uuml;r Sch&auml;den, die durch ARQ oder einem ihrer gesetzlichen Vertreter oder Erf&uuml;llungsgehilfen vors&auml;tzlich oder durch grobe Fahrl&auml;ssigkeit verursacht wurden.</p>
      <p><strong>9.2</strong>&nbsp;Bei der leicht fahrl&auml;ssig verursachten Verletzung wesentlicher Vertragspflichten haftet ARQ au&szlig;er in den F&auml;llen der Ziffer 9.1 oder 9.3 der H&ouml;he nach begrenzt auf den vertragstypisch vorhersehbaren Schaden. Wesentliche Vertragspflichten sind abstrakt solche Pflichten, deren Erf&uuml;llung die ordnungsgem&auml;&szlig;e Durchf&uuml;hrung eines Vertrages &uuml;berhaupt erst erm&ouml;glicht und auf deren Einhaltung die Vertragsparteien regelm&auml;&szlig;ig vertrauen d&uuml;rfen. Im &Uuml;brigen ist eine Haftung von ARQ ausgeschlossen.</p>
      <p><strong>9.3</strong>&nbsp;Die Haftung nach dem Produkthaftungsgesetz bleibt unber&uuml;hrt.</p>
    `,
  },
  {
    id: Links.Privacy,
    label: 'Datenschutz',
    content: `
      <p><strong>10.1</strong>&nbsp;Eine Erhebung, Verarbeitung und/oder Nutzung personenbezogener Daten findet nur statt, wenn der Nutzer eingewilligt hat oder dies durch Gesetz erlaubt ist. Eine Verwendung von personenbezogenen Daten aufgrund einer gesetzlichen Erlaubnis erfolgt insbesondere im Zusammenhang mit der Erf&uuml;llung vertraglicher Pflichten von ARQ gegen&uuml;ber den Nutzern. ARQ bietet dem Nutzer eine Vielzahl von Funktionen an, die individuell auf diesen angepasst werden (z. B. stellt ARQ auf Basis erhobener Daten bestimmte Informationen, Angebote, Empfehlungen sowie Dienstleistungen bereit und f&ouml;rdert die Interaktion im Netzwerk des Nutzers). Um die Leistungen erbringen zu k&ouml;nnen, ist eine Verarbeitung und Nutzung von personenbezogenen Daten des Nutzers erforderlich.</p>
      <p><strong>10.2</strong>&nbsp;ARQ bietet Nutzern die M&ouml;glichkeit, anderen Nutzern ihre pers&ouml;nlichen Daten in Teilen oder in G&auml;nze zu &uuml;bermitteln bzw. die Einsicht in die Daten zu widerrufen. Die &Uuml;bermittlung dieser Daten geschieht auf eigene Gefahr.</p>
      <p><strong>10.3</strong>&nbsp;Abh&auml;ngig von der gew&auml;hlten Premium-Mitgliedschaft bzw. des gew&auml;hlten Zusatzpakets werden gegebenenfalls weitere personenbezogene Daten erhoben, verarbeitet und genutzt, um die Leistungen und Funktionen im Zusammenhang mit der gew&auml;hlten Premium-Mitgliedschaft bzw. des gew&auml;hlten Zusatzpakets zu erbringen. Diese Daten werden auch im Falle der Beendigung der Nutzung der gew&auml;hlten Premium-Mitgliedschaft bzw. des gew&auml;hlten Zusatzpakets f&uuml;r die Dauer der ARQ Mitgliedschaft weiter gespeichert, sofern der Nutzer die Daten nicht zuvor selbst l&ouml;scht. Dies erfolgt insbesondere, um dem Nutzer bei einer sp&auml;teren erneuten Nutzung der Premium Mitgliedschaft bzw. des Zusatzpakets zu erm&ouml;glichen, die bereits eingegebenen Daten weiter zu nutzen. Die Geltendmachung der Betroffenenrechte i.S.d. Art. 12 ff. DSGVO bleibt unber&uuml;hrt.&nbsp;</p>
    `,
  },
  {
    id: Links.ApplicableLawJurisdictionMisc,
    label: 'Anwendbares Recht, Gerichtsstand und Sonstiges',
    content: `
      <p><strong>11.1</strong>&nbsp;ARQ kann Unterauftragnehmer einsetzen. ARQ bleibt auch bei Einsetzung von Unterauftragnehmern verantwortlich f&uuml;r die Erf&uuml;llung der durch ARQ &uuml;bernommen Pflichten.</p>
      <p><strong>11.2</strong>&nbsp;ARQ ist berechtigt, Rechte und Pflichten ganz oder teilweise an Dritte zu &uuml;bertragen.</p>
      <p><strong>11.3</strong>&nbsp;ARQ kann Erkl&auml;rungen gegen&uuml;ber dem Nutzer per E-Mail oder Brief &uuml;bermitteln, sofern in den AGB nicht anders geregelt. Au&szlig;erdem kann ARQ Erkl&auml;rungen gegen&uuml;ber dem Nutzer per Mitteilung in das private ARQ Postfach des Nutzers, das Bestandteil der ARQ Mitgliedschaft des Nutzers ist, abgeben.</p>
      <p><strong>11.4</strong>&nbsp;Wir nehmen an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teil. Zust&auml;ndig ist die Universalschlichtungsstelle des Zentrums f&uuml;r Schlichtung e.V., Stra&szlig;burger Stra&szlig;e 8, 77694 Kehl am Rhein.</p>
      <p><strong>11.6</strong>&nbsp;Erf&uuml;llungsort ist der Sitz der arq decisions GmbH.</p>
      <p><strong>11.7</strong>&nbsp;Gerichtsstand f&uuml;r Kaufleute im Sinne des Handelsgesetzbuches (HGB) ist der Sitz von ARQ.</p>
      <p><strong>11.8</strong>&nbsp;Es gilt deutsches Recht unter Ausschluss des internationalen Privatrechts und des ins deutsche Recht &uuml;bernommenen UN-Kaufrechts. Das zwingende Verbraucherschutzrecht desjenigen europ&auml;ischen Mitgliedstaates, in dem ein Nutzer seinen gew&ouml;hnlichen Aufenthalt hat, bleibt unber&uuml;hrt.</p>
    `,
  },
];
