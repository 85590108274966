import React, {
  FC
} from 'react';

import {
  formatDateRange, numberWithPeriods
} from 'src/shared/utils';
import {
  MessageIcon, PeopleIcon, TrendingUpIcon
} from 'src/shared/icons';
import {
  useTypedSelector
} from 'src/redux';
import {
  languageSelectors
} from 'src/redux/language';
import {
  ActivityTile
} from 'src/entities/Questions';
import {
  GroupCardQuestionBlock
} from 'src/entities/GroupsRoute';
import {
  NotificationGroupContainer
} from 'src/entities/NotificationsRoute';
import {
  GroupStats
} from 'src/redux/openapi';

import * as Style from './NotificationGroupCard.styles';

interface NotificationGroupProps {
  groupName: (string | JSX.Element)[];
  groupStats: GroupStats | null;
  withLeftShift?: boolean;
}

export const NotificationGroupCard: FC<NotificationGroupProps> = ({
  groupName,
  groupStats,
  withLeftShift,
}) => {
  const language = useTypedSelector(languageSelectors.getLanguage);

  return (
    <NotificationGroupContainer
      $width={400}
      $leftShift={withLeftShift ? 68 : 0}
    >
      <Style.NotificationGroupTitle>{groupName}</Style.NotificationGroupTitle>

      {groupStats && (
        <>
          <Style.StatsContainer>
            <Style.TileContainer>
              <ActivityTile
                icon={<PeopleIcon className="text-dim-gray w-4 h-4" />}
                amount={numberWithPeriods(groupStats.memberCount)}
                className="text-13-21 text-dark-gray"
              />
            </Style.TileContainer>

            <Style.TileContainer>
              <ActivityTile
                icon={<MessageIcon className="text-dim-gray w-4 h-4" />}
                amount={numberWithPeriods(groupStats.commentCount || 0)}
                className="text-13-21 text-dark-gray"
              />
            </Style.TileContainer>

            <Style.TileContainer>
              <ActivityTile
                icon={<TrendingUpIcon className="text-dim-gray w-4 h-4" />}
                amount={`${groupStats.forecastCount}`}
                className="text-13-21 text-dark-gray"
              />
            </Style.TileContainer>
          </Style.StatsContainer>

          <Style.QuestionsContainer>
            {!!groupStats.questionSets
              && groupStats.questionSets.map(
                ({
                  id, startDate, endDate, title
                }) => (
                  <GroupCardQuestionBlock
                    key={id}
                    data={{
                      dates: formatDateRange({
                        start: startDate,
                        end: endDate,
                        language,
                      }),
                      questionTitle: title,
                    }}
                    isInvited
                  />
                ),
              )}
          </Style.QuestionsContainer>
        </>
      )}
    </NotificationGroupContainer>
  );
};
