import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const ModalLabelButton = styled.button`
  ${tw`text-15-20 font-medium text-grey-dark-40 mb-2 cursor-default`}
`;

export const ModalRedSpan = styled.span`
  ${tw`text-red-label`}
`;

export const ModalGroupedLabel = styled.label`
  ${tw`max-h-376px min-h-48px overflow-y-scroll flex flex-wrap gap-2 p-3 rounded border border-input-border-blue bg-alice-blue`}
`;

export const ModalEmailHookToInvite = styled.input<{
  $isFullWidth?: boolean;
  $isTransparent?: boolean;
}>`
  ${tw`px-3 py-0.5 rounded-123px block`}

  ${({
    $isFullWidth
  }) => ($isFullWidth ? tw`w-full` : tw`w-max max-w-360px truncate`)}

  ${({
    $isTransparent
  }) => ($isTransparent ? tw`border-none outline-0 bg-alice-blue` : tw`bg-list-blue`)}
`;
