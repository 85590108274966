import clsx from 'clsx';
import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  PopoverMenu
} from 'src/shared/components';
import {
  EditPencilIcon, MoreVerticalIcon
} from 'src/shared/icons';

import * as Style from './ContextMenu.style';

interface ContextMenuProps {
  setActiveQuestion: () => void;
}

export const ContextMenu: FC<ContextMenuProps> = ({
  setActiveQuestion
}) => {
  const {
    t
  } = useTranslation();

  return (
    <PopoverMenu
      placement="bottom-end"
      customShift={22}
      renderCustomButton={({
        isOpen, toggleMenu, triggerProps
      }) => (
        <button
          type="button"
          onClick={(event) => {
            event.stopPropagation();
            toggleMenu();
          }}
          {...triggerProps}
        >
          <MoreVerticalIcon
            className={clsx(
              'self-start w-6 h-6',
              {
                'text-dim-gray': !isOpen,
                'text-button-blue': isOpen,
              }
            )}
          />
        </button>
      )}
    >
      <Style.MainContainer>
        <Style.ButtonContainer
          type="button"
          onClick={setActiveQuestion}
        >
          <EditPencilIcon className="w-6 h-6 text-dark-gray" />

          <Style.ButtonTitle>
            {`${t('modal.editOnboardingText')[0]}${t('modal.editOnboardingText')
              .slice(1)
              .toLowerCase()}`}
          </Style.ButtonTitle>
        </Style.ButtonContainer>

        {/* TODO: uncomment after BE api adding */}

        {/* <Style.Divider /> */}

        {/* <Style.ButtonContainer type="button">
          <MinusInCircleIcon className="w-6 h-6 text-red-text-error" />

          <Style.ButtonTitle $isRed>
            {t('modal.removeQuestion')}
          </Style.ButtonTitle>
        </Style.ButtonContainer> */}
      </Style.MainContainer>
    </PopoverMenu>
  );
};
