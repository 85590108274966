import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MenuContainer = styled.div<{ $width: number }>`
  ${tw`bg-white shadow-menu-shadow flex flex-col`}

  ${({
    $width
  }) => `
    width: ${$width}px;
  `}

  max-height: calc(100vh - 220px);
`;

export const MenuContent = styled.div`
  ${tw`max-h-564px overflow-y-scroll grow`}
`;

export const RowContainer = styled.div<{ $withLeftShift?: boolean }>`
  ${tw`flex px-3 py-4 gap-3 items-center`}

  ${({
    $withLeftShift
  }) => $withLeftShift && tw`pl-52px`}
`;

export const ContentContainer = styled.div`
  ${tw`flex gap-2 grow items-center`}
`;

export const TextContainer = styled.div`
  ${tw`flex gap-1 items-center`}
`;

export const TextLabel = styled.p<{ $light?: boolean }>`
  ${tw`font-medium leading-6`}

  ${({
    $light
  }) => $light && tw`text-dim-gray`}
`;

export const IconContainer = styled.div`
  ${tw`rounded-full h-10 w-10`}
`;

export const Divider = styled.div`
  ${tw`mx-4 my-1 bg-gray94 h-px`}
`;
