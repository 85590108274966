import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import {
  t
} from 'i18next';

dayjs.extend(isBetween);

export const formatDateValue = (
  inputValue: (Date | null)[] | null,
  lang: string,
) => {
  if (!inputValue) {
    return t('filters.customDateRange');
  }

  const [start, end] = inputValue;

  if (!start) {
    return t('filters.customDateRange');
  }

  let formattedStart = dayjs(start).locale(lang).format('MMM D, YYYY');

  if (end) {
    const startYear = dayjs(start).format('YYYY');
    const endYear = dayjs(end).format('YYYY');

    if (startYear === endYear) {
      formattedStart = dayjs(start).locale(lang).format('MMM D');
    }

    const formattedEnd = dayjs(end).locale(lang).format('MMM D, YYYY');

    return `${formattedStart} - ${formattedEnd}`;
  }

  return formattedStart;
};
