import React, {
  FC, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import hexToRgba from 'hex-to-rgba';
import {
  Controller, useForm
} from 'react-hook-form';
import {
  zodResolver
} from '@hookform/resolvers/zod';
import clsx from 'clsx';

import {
  Button,
  Input,
  ModalContainer,
  PopoverMenu,
  Spinner,
} from 'src/shared/components';
import {
  ArrowDownIcon, CheckIcon
} from 'src/shared/icons';
import {
  useTypedSelector
} from 'src/redux';
import {
  organizationSelectors
} from 'src/redux/organizations';
import {
  useGetInputValidation
} from 'src/shared/hooks';
import {
  cutRgbInColorName, isHexColor, rgbToRgba
} from 'src/shared/utils';

import {
  RGB,
  colorsWithOpacity,
  modalPlaceholderColorHex,
  modalPlaceholderColorRGB,
} from '../../constants';

import * as Style from './ModalAddNewTheme.style';

interface OrgThemeForm {
  name: string;
  color: string;
}

interface ModalAddNewThemeProps {
  isOpen: boolean;
  isLoading: boolean;
  closeModalHandler: () => void;
  setTheme: (themeColor: string, themeName: string) => void;
}

export const ModalAddNewTheme: FC<ModalAddNewThemeProps> = ({
  isOpen,
  isLoading,
  closeModalHandler,
  setTheme,
}) => {
  const {
    t
  } = useTranslation();

  const themeColor = useTypedSelector(organizationSelectors.orgUsersTheme);
  const modalColorTheme = themeColor?.colorPrimary || modalPlaceholderColorHex;

  const tabName = isHexColor(modalColorTheme) ? t('organisation.hexCode') : RGB;

  const [tabColorType, setTabColorType] = useState(tabName);

  const [shouldClose, setShouldClose] = useState(false);

  const {
    organizationThemeNameSchema
  } = useGetInputValidation();

  const {
    control, formState, handleSubmit, setValue, watch
  } = useForm<OrgThemeForm>({
    mode: 'onTouched',
    defaultValues: {
      name: '',
      color: modalColorTheme,
    },
    resolver: zodResolver(organizationThemeNameSchema),
  });

  const colorValue = watch('color');

  const handleSaveTheme = (data: OrgThemeForm) => {
    setTheme(
      data.color,
      data.name
    );
  };

  const clickHandler = (tabColorNew: string) => {
    if (tabColorNew === tabColorType) {
      setShouldClose(true);

      return;
    }

    setTabColorType(tabColorNew);

    const defaultColor = tabColorNew === RGB ? modalPlaceholderColorRGB : modalPlaceholderColorHex;

    setValue(
      'color',
      defaultColor
    );

    setShouldClose(true);
  };

  const handleButtonClick = (toggle: () => void) => {
    if (shouldClose) {
      setShouldClose(false);
    }

    toggle();
  };

  const isHexColorValue = isHexColor(colorValue);

  return (
    <ModalContainer
      isOpen={isOpen}
      modalTitle={t('organisation.changeBrandColour')}
      closeModalHandler={closeModalHandler}
      modalWidth={728}
    >
      <div className="mb-4">
        <Controller
          name="name"
          control={control}
          render={({
            field
          }) => {
            return (
              <Input
                label=""
                placeholder={t('organisation.enterThemeName')}
                onChange={field.onChange}
                value={field.value}
                errorMessage={formState.errors.name?.message}
                onBlur={field.onBlur}
              />
            );
          }}
        />
      </div>

      <Style.ModalThemeInputContainer>
        <PopoverMenu
          placement="bottom-start"
          shouldForcedClose={shouldClose}
          zIndex={2222}
          renderCustomButton={({
            isOpen: isOpenChoices,
            toggleMenu,
            triggerProps,
          }) => (
            <Style.ColorSelect
              $isOpen={isOpenChoices}
              type="button"
              onClick={() => handleButtonClick(toggleMenu)}
              tabIndex={0}
              {...triggerProps}
            >
              {tabColorType}

              <ArrowDownIcon
                className={clsx(
                  'w-6 h-6',
                  {
                    'rotate-180': isOpenChoices,
                  }
                )}
              />
            </Style.ColorSelect>
          )}
        >
          <Style.MenuContainer>
            {[t('organisation.hexCode'), RGB].map((value) => {
              const isSelected = value === tabColorType;

              return (
                <Style.MenuOption
                  key={value}
                  type="button"
                  onClick={() => clickHandler(value)}
                  $isSelected={isSelected}
                >
                  <Style.MenuText $isSelected={isSelected}>
                    {value}
                  </Style.MenuText>

                  {isSelected && (
                    <CheckIcon className="w-6 h-6 text-button-blue" />
                  )}
                </Style.MenuOption>
              );
            })}
          </Style.MenuContainer>
        </PopoverMenu>

        <Controller
          name="color"
          control={control}
          render={({
            field
          }) => {
            return (
              <Style.ModalThemeInput
                type="text"
                placeholder={
                  tabColorType === RGB
                    ? modalPlaceholderColorRGB
                    : modalPlaceholderColorHex
                }
                onChange={field.onChange}
                value={field.value}
                onBlur={field.onBlur}
              />
            );
          }}
        />
      </Style.ModalThemeInputContainer>

      {formState.errors?.color && (
        <Style.ErrorMessage>
          {`Please enter a valid color value. For example, ${modalPlaceholderColorHex}, or ${modalPlaceholderColorRGB}`}
        </Style.ErrorMessage>
      )}

      <Style.ModalThemeMainColor
        $bgColor={
          isHexColorValue ? hexToRgba(colorValue) : rgbToRgba(
            colorValue,
            '1'
          )
        }
      >
        <Style.ModalColorCardTitle $isBig>
          {t('common.primary')}
        </Style.ModalColorCardTitle>

        <Style.ModalColorCardTitle>{colorValue}</Style.ModalColorCardTitle>
      </Style.ModalThemeMainColor>

      <Style.ModalColorsContainer>
        {colorsWithOpacity.map((opacityColor) => {
          const preparedColor = isHexColorValue
            ? colorValue
            : cutRgbInColorName(colorValue);

          return (
            <Style.ModalColorCard
              key={opacityColor}
              $bgColor={
                isHexColorValue
                  ? hexToRgba(
                    colorValue,
                    `0.${opacityColor}`
                  )
                  : rgbToRgba(
                    colorValue,
                    `0.${opacityColor}`
                  )
              }
            >
              <Style.ModalColorCardTitle>
                {`${Number(opacityColor)}`}
              </Style.ModalColorCardTitle>

              <Style.ModalColorCardTitle>
                {`${preparedColor}`}
              </Style.ModalColorCardTitle>
            </Style.ModalColorCard>
          );
        })}
      </Style.ModalColorsContainer>

      <Style.ModalButtonsContainer>
        <Button
          variant="big-black"
          className="w-max text-xl font-bold"
          onClick={closeModalHandler}
        >
          {t('buttons.cancel')}
        </Button>

        <Button
          variant="big-blue"
          className="w-max text-xl font-bold"
          onClick={handleSubmit(handleSaveTheme)}
        >
          {isLoading ? (
            <Spinner
              size={24}
              color="white"
            />
          ) : (
            t('buttons.saveApply')
          )}
        </Button>
      </Style.ModalButtonsContainer>
    </ModalContainer>
  );
};
