import {
  usePostCommentsByParentModelMutation
} from 'src/shared/api';
import {
  successfulToast
} from 'src/shared/components';

import {
  showWarningFromServer
} from '../utils';

export const useSendComment = () => {
  const [postApiComments, {
    isLoading
  }] = usePostCommentsByParentModelMutation();

  const addReplyHandler = async (
    newComment: string,
    commentIdToReply: string,
  ) => {
    try {
      const response = await postApiComments({
        parentModel: 'comment',
        parentId: commentIdToReply,
        createCommentBodySchema: {
          text: newComment,
        },
      }).unwrap();

      successfulToast(response.message);
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  const addNewCommentHandler = async (newComment: string, parentId: string) => {
    try {
      const response = await postApiComments({
        parentModel: 'question',
        parentId,
        createCommentBodySchema: {
          text: newComment,
        },
      }).unwrap();

      successfulToast(response.message);
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  const addForecastCommentHandler = async (
    forecastId: string,
    text: string,
    isPrivate: boolean,
  ) => {
    try {
      const response = await postApiComments({
        parentModel: 'forecast',
        parentId: forecastId,
        createCommentBodySchema: {
          text,
          isPrivate,
        },
      }).unwrap();

      successfulToast(response.message);
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  return {
    addReplyHandler,
    addNewCommentHandler,
    addForecastCommentHandler,
    isLoadingPostComment: isLoading,
  };
};
