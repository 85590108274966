export enum HTTPMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export interface Response<Data> {
  message: string;
  data: Data;
}

export interface OnlyMessageResponse {
  message: string;
}

export interface DataResponse<Data> {
  data: Data;
}
