import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const ModalTitle = styled.div`
  ${tw`text-34-43 font-bold text-gray7 text-center`}
`;

export const ModalText = styled.div`
  ${tw`text-17-26 font-medium text-dark-gray text-center`}
`;

export const ButtonContainer = styled.div`
  ${tw`mt-6 flex gap-4`}
`;

export const CancelText = styled.span`
  ${tw`text-xl font-bold`}
`;
