import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MainContainer = styled.div`
  ${tw`w-full flex justify-between items-center py-3 border-b border-b-gray94 last:border-b-0`}
`;

export const InfoContainer = styled.div`
  ${tw`flex items-center gap-3 mr-4`}
`;

export const MemberGroup = styled.p`
  ${tw`text-xs font-medium leading-5 text-dim-gray`}
`;

export const MemberName = styled.p`
  ${tw`text-15-20 font-medium text-gray7`}
`;

export const TooltipMessage = styled.p`
  ${tw`max-w-194px text-center text-xs text-white leading-5 font-medium`}
`;

export const ActivityRow = styled.div`
  ${tw`grid grid-cols-2 gap-2.5 text-13-21 mr-4 items-center`}
`;

export const MenuContainer = styled.div`
  ${tw`py-2 flex flex-col gap-1 rounded`}
`;

export const MenuButtonContainer = styled.button<{ $disabled?: boolean }>`
  ${tw`py-2 px-4 flex gap-2 w-250px`}

  ${({
    $disabled
  }) => $disabled && tw`cursor-default`}
`;

export const MenuButtonTitle = styled.p<{ $disabled?: boolean }>`
  ${tw`text-left text-gray7 text-17-26 font-medium`}

  ${({
    $disabled
  }) => $disabled && tw`text-silver-gray`}
`;
