import React, {
  FC
} from 'react';
import {
  Outlet
} from 'react-router-dom';

import {
  PageLayout
} from 'src/widgets';

export const GroupPage: FC = () => {
  return (
    <PageLayout>
      <Outlet />
    </PageLayout>
  );
};
