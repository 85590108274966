import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MainContainer = styled.div`
  ${tw`overflow-auto flex flex-col justify-between h-full`}
`;

export const Title = styled.p`
  ${tw`text-17-26 font-bold mb-6`}
`;

export const EmptyMessage = styled.p`
  ${tw`text-dim-gray text-center`}
`;

export const ContentWrapper = styled.div`
  ${tw`px-6 overflow-auto flex flex-col grow`}
`;

export const ListContainer = styled.div`
  ${tw`overflow-auto flex flex-col`}
`;

export const DisabledListItem = styled.div`
  ${tw`
    flex gap-4 justify-between py-2 px-4 font-medium text-17-26 text-gray7
    border border-gray90 rounded-md mb-4
  `}
`;

export const DaysLeftText = styled.p`
  ${tw`text-grey-dark-40`}
`;

export const CharacteristicContainer = styled.div`
  ${tw`flex flex-col gap-2`}
`;

export const CharacteristicTitle = styled.div`
  ${tw`text-grey-dark-40 font-medium text-15-20`}
`;

export const ButtonsContainer = styled.div`
  ${tw`flex justify-end gap-3 py-4 px-6 mt-auto w-full bg-white border-t border-t-gray-whisper`}
`;
