import {
  useMemo
} from 'react';
import {
  useParams
} from 'react-router-dom';

import {
  useGetGroupQuestionsOnboardingTextQuery,
  useGetQuestionSetsEditingQuery,
} from 'src/redux/openapi';
import {
  useNestedSelect
} from 'src/shared/hooks';
import {
  useStepper
} from 'src/shared/components';
import {
  useSetOnboardingTextList
} from 'src/widgets';

import {
  getStepsMap, Steps, getNextStepButtonConfig
} from '../utils';

export const useEditQuestionsInGroup = () => {
  const params = useParams();

  const {
    groupId = ''
  } = params;

  const steps = getStepsMap(
    false,
    true
  );

  const {
    completeCurrentStep,
    activeStepId,
    isLastStep,
    handleNextStep,
    completedStatus,
  } = useStepper(steps);

  const nextStepButtonConfig = useMemo(
    () => getNextStepButtonConfig({
      isLastStep,
      isAdding: true,
    }),
    [isLastStep],
  );

  const {
    questionSets, isQuestionSetsLoading
  } = useGetQuestionSetsEditingQuery(
    {
      // TODO: add pagination after BE or design changing
      limit: 5000,
      filters: {
        notStatus: 'DEACTIVATED',
      },
      sortBy: 'startDate',
      sortOrder: 'asc',
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({
        data, isLoading
      }) => ({
        questionSets: data?.data.questionSets || [],
        isQuestionSetsLoading: isLoading,
      }),
    },
  );

  const initialSelectedItems = useMemo(
    () => {
      return questionSets
        .filter((set) => set.questions.some((question) => question.isMandatory))
        .map((set) => ({
          parentId: set.id,
          selectedChildrenId: set.questions
            .filter((question) => question.isMandatory)
            .map((question) => question.id),
        }));
    },
    [questionSets]
  );

  const selectConfig = useNestedSelect({
    initialSelectedItems,
  });

  const selectedQuestionsIds = useMemo(
    () => selectConfig.selectedItems.flatMap((items) => items.selectedChildrenId),
    [selectConfig],
  );

  const {
    questionsWithOnboardingText, isQuestionsWithTextLoading
  } = useGetGroupQuestionsOnboardingTextQuery(
    {
      id: groupId,
      questionIds: selectedQuestionsIds,
      limit: 5000,
      sortBy: 'startDate',
      sortOrder: 'asc',
    },
    {
      skip: activeStepId !== Steps.SetOnboardingText || !groupId,
      selectFromResult: ({
        data, isLoading, isFetching
      }) => ({
        questionsWithOnboardingText: data?.data.questions || [],
        isQuestionsWithTextLoading: isLoading || isFetching,
      }),
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    questionsOnboardingText, onTextChange
  } = useSetOnboardingTextList(
    questionsWithOnboardingText,
  );

  const completeWithChangeStep = () => {
    completeCurrentStep();
    handleNextStep();
  };

  const onNextStepClick = () => {
    switch (activeStepId) {
      case Steps.ChooseQuestions:
        return completeWithChangeStep();

      case Steps.SetOnboardingText:
        return completeWithChangeStep();

      default:
        return handleNextStep();
    }
  };

  const isDataLoading = isQuestionSetsLoading || isQuestionsWithTextLoading;

  return {
    isDataLoading,
    nextStepButtonConfig,
    onNextStepClick,
    questionsOnboardingText,
    onTextChange,
    steps,
    activeStepId,
    completedStatus,
  };
};
