import React, {
  FC
} from 'react';

import {
  StatusConfig
} from '../../types';
import {
  getProgressWidthInPercent
} from '../../utils';

import * as Style from './ProgressBar.styles';

interface ProgressBarProps {
  statusConfig: StatusConfig;
}

export const ProgressBar: FC<ProgressBarProps> = ({
  statusConfig
}) => {
  return (
    <Style.ProgressContainer>
      {statusConfig.map(({
        color, label
      }, index) => {
        const widthInPercent = getProgressWidthInPercent(
          statusConfig,
          index
        );

        return (
          <Style.ProgressItem
            $color={color}
            $widthPercent={widthInPercent}
            $index={statusConfig.length - index}
            key={label}
          />
        );
      })}
    </Style.ProgressContainer>
  );
};
