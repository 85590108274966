import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  AchievedBadgeIcon, LockIcon
} from 'src/shared/icons';
import {
  ProgressCircle
} from 'src/shared/components';
import {
  formatDateRange
} from 'src/shared/utils';
import CardPair from 'src/shared/icons/CardPair.png';
import {
  useTypedSelector
} from 'src/redux';
import {
  languageSelectors
} from 'src/redux/language';
import {
  useGetUserAchievementsQuery
} from 'src/redux/openapi';

import * as Style from './BadgesTabContent.styles';

// const RATING = 0.325;

export const BadgesTabContent: FC = () => {
  const language = useTypedSelector(languageSelectors.getLanguage);

  const {
    t
  } = useTranslation();

  const {
    achievements, lockedAchievements
  } = useGetUserAchievementsQuery(
    {
      locked: true,
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({
        data
      }) => ({
        achievements: data?.data.achievements || [],
        lockedAchievements: data?.data.lockedAchievements || [],
      }),
    },
  );

  // TODO: change after BE implementation of TOTAL, ACHIEVED & LOCKED badges, descriptions.
  // const userRating = (RATING * 100).toFixed(1);
  const totalBadges = achievements.length;
  const achievedBadges = achievements.length;

  return (
    <>
      <Style.OverviewContainer>
        <Style.OverviewItem>
          <img
            src={CardPair}
            alt="Card Pair"
            height={88}
            width={88}
          />

          <Style.OverviewItemDescription>
            <Style.OverviewItemTitle>
              {`(${achievedBadges}/${totalBadges})`}
            </Style.OverviewItemTitle>

            <Style.OverviewItemSubTitle>
              {t('profile.totalAchievementsCompleted')}
            </Style.OverviewItemSubTitle>
          </Style.OverviewItemDescription>
        </Style.OverviewItem>

        <Style.OverviewItem>
          {/* TODO: Add after BE implementation */}
          {/* <ProgressCircle
            borderColor={themeColors['input-blue']}
            progress={RATING}
          /> */}

          {/* <Style.OverviewItemDescription>
            <Style.OverviewItemTitle>
              {t('profile.proForecaster')}
            </Style.OverviewItemTitle>

            <Style.OverviewItemSubTitle>
              <Trans
                i18nKey="profile.userRating"
                values={{
                  userRating,
                }}
              />
            </Style.OverviewItemSubTitle>
          </Style.OverviewItemDescription> */}
        </Style.OverviewItem>
      </Style.OverviewContainer>

      <div>
        {!!achievements.length && (
          <Style.BadgesSection>
            <Style.BadgesStatus>{t('profile.Achieved')}</Style.BadgesStatus>

            <Style.AchievedCards className="scroll-hidden">
              {achievements.map(
                ({
                  id,
                  updatedAt,
                  type,
                  achievement: {
                    description, descriptionDe
                  },
                }) => {
                  const achievementDescription = language === 'en' ? description : descriptionDe;

                  return (
                    <Style.CardContainer key={id}>
                      <Style.AchievedProgressWrapper>
                        <Style.AchievedCardDateWrapper>
                          <Style.AchievedCardDate>
                            {formatDateRange({
                              start: updatedAt,
                              language,
                            })}
                          </Style.AchievedCardDate>
                        </Style.AchievedCardDateWrapper>

                        <ProgressCircle
                          icon={
                            <AchievedBadgeIcon className="text-button-blue" />
                          }
                        />
                      </Style.AchievedProgressWrapper>

                      <Style.CardDescription>
                        <Style.CardTitle>{type.toLowerCase()}</Style.CardTitle>

                        {achievementDescription && (
                          <Style.CardSubTitle>
                            {achievementDescription}
                          </Style.CardSubTitle>
                        )}
                      </Style.CardDescription>
                    </Style.CardContainer>
                  );
                },
              )}
            </Style.AchievedCards>
          </Style.BadgesSection>
        )}

        {!!lockedAchievements.length && (
          <Style.BadgesSection>
            <Style.BadgesStatus>{t('profile.Locked')}</Style.BadgesStatus>

            <Style.LockedCards>
              {lockedAchievements.map(
                ({
                  type, description, descriptionDe
                }) => {
                  const achievementDescription = language === 'en' ? description : descriptionDe;

                  return (
                    <Style.CardContainer key={type}>
                      <ProgressCircle
                        progress={undefined}
                        icon={<LockIcon className="text-dark-gray" />}
                        // TODO: uncomment after isMultiple implementation
                        // borderColor={
                        //   !isMultiple ? 'transparent' : themeColors['list-blue']
                        // }
                        borderColor="transparent"
                      />

                      <Style.CardDescription>
                        <Style.CardTitle>{type.toLowerCase()}</Style.CardTitle>

                        {achievementDescription && (
                          <Style.CardSubTitle>
                            {achievementDescription}
                          </Style.CardSubTitle>
                        )}
                      </Style.CardDescription>
                    </Style.CardContainer>
                  );
                },
              )}
            </Style.LockedCards>
          </Style.BadgesSection>
        )}
      </div>
    </>
  );
};
