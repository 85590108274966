import {
  createSlice
} from '@reduxjs/toolkit';
import type {
  PayloadAction
} from '@reduxjs/toolkit';

export enum TimeSpansValues {
  upToday = 'upToday',
  last7Days = 'last7Days',
  last30Days = 'last30Days',
  twoWeeks = 'twoWeeks',
}

export interface TimeSpansState {
  isSelected: boolean;
  value: TimeSpansValues;
}

export interface TimeSpansReducer {
  timeSpans: TimeSpansState[];
}

export const initialState: TimeSpansState[] = [
  {
    isSelected: true,
    value: TimeSpansValues.upToday,
  },
  {
    isSelected: false,
    value: TimeSpansValues.last7Days,
  },
  {
    isSelected: false,
    value: TimeSpansValues.twoWeeks,
  },
  {
    isSelected: false,
    value: TimeSpansValues.last30Days,
  },
];

export const timeSpansSlice = createSlice({
  name: 'timeSpans',
  initialState,
  reducers: {
    setTimeSpansData: (state, action: PayloadAction<TimeSpansState[]>) => action.payload,
    setActiveTimeSpan: (state, action: PayloadAction<string>) => state.map((val) => {
      if (val.value === action.payload) {
        return {
          ...val,
          isSelected: true,
        };
      }

      return {
        ...val,
        isSelected: false,
      };
    }),
    resetTimeSpans: () => initialState,
  },
});

export const {
  setTimeSpansData, setActiveTimeSpan, resetTimeSpans
} = timeSpansSlice.actions;

export const timeSpansReducer = timeSpansSlice.reducer;
