import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const ContentContainer = styled.div<{
  $gap: number;
  $isOnboardText?: boolean;
  $isOnboarding?: boolean;
}>`
  ${tw`flex flex-col h-full`}

  ${({
    $gap
  }) => `gap: ${$gap}px`};

  ${({
    $isOnboardText
  }) => !$isOnboardText && tw`pb-0.5`};
  ${({
    $isOnboarding
  }) => $isOnboarding && tw`md:min-h-196px`};

  & > * {
    ${tw`!grow-0`}
  }
`;

export const Title = styled.p<{
  $withChildren: boolean;
  $withOnboardText?: boolean;
  $isMobile?: boolean;
}>`
  ${tw`text-gray7 font-bold overflow-hidden`}

  display: -webkit-box;
  -webkit-box-orient: vertical;

  ${({
    $withChildren
  }) => ($withChildren
    ? tw`text-lg md:text-xl md:h-7`
    : tw`text-lg md:text-22-30 max-h-full`)};

  ${({
    $withChildren, $withOnboardText, $isMobile
  }) => ($withChildren && !$isMobile
    ? `
    -webkit-line-clamp: 1;
  `
    : `
    -webkit-line-clamp: ${$withOnboardText ? 2 : 3};
  `)};
`;

export const OnboardingText = styled.div`
  ${tw`text-xs leading-5 font-medium text-dim-gray mt-1`}

  -webkit-line-clamp: 2;
`;

export const OnboardingTextTooltip = styled.div`
  ${tw`max-w-200px`}
`;

export const CardInfoBlock = styled.div`
  ${tw`flex-1 grow-0`}
`;

export const Span = styled.span`
  ${tw`pb-0.5 hover:(text-dim-gray bg-dashed-hover bg-bottom bg-repeat-x bg-[length:8px_1px])
  active:(text-gray7 bg-dashed-active bg-bottom bg-repeat-x bg-[length:8px_1px])
  transition-colors
  `}
`;
export const Divider = styled.div`
  ${tw`h-px bg-gray94 mt-auto`}
`;

export const CardButton = styled.button<{
  $backgroundColor: string;
  $textColor: string;
  $withBorder: boolean;
}>`
  ${tw`
    rounded-100px py-2.5 px-4 font-bold text-15
    flex gap-1 items-center self-end
  `}

  ${({
    $withBorder
  }) => $withBorder && tw`border border-gray94`};

  ${({
    $backgroundColor, $textColor
  }) => `
    background-color: ${$backgroundColor};
    color: ${$textColor};
  `};
`;

export const ButtonIcon = styled.div`
  ${tw`w-6 h-6 flex justify-center items-center`}
`;

export const BottomRow = styled.div<{ $withChildren?: boolean }>`
  ${tw`flex justify-between items-center`}

  ${({
    $withChildren
  }) => $withChildren && tw`mt-auto`};
`;

export const BottomButtons = styled.div`
  ${tw`flex gap-2 items-center`}
`;
