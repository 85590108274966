import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

import {
  CheckboxType
} from './types';

export const CheckboxLabel = styled.label<{ $type: `${CheckboxType}` }>`
  ${tw`rounded transition-colors cursor-pointer flex items-center`}

  ${({
    $type
  }) => {
    if ($type === CheckboxType.SQUARE) {
      return `
        ${tw`justify-center hover:bg-dark-blue/30`}
      `;
    }

    return null;
  }}
`;

export const LoadingCheckboxWrapper = styled.div<{ $size: number }>`
  ${tw`flex justify-center items-center w-6 h-6 shrink-0`}

  ${({
    $size
  }) => `
    width: ${$size}px;
    height: ${$size}px;
  `}
`;
