import {
  t
} from 'i18next';

export enum Steps {
  ChooseGroups = 'ChooseGroups',
  ChooseMembers = 'ChooseMembers',
}

export const getStepsMap = () => {
  return [
    {
      id: Steps.ChooseGroups,
      label: t('group.chooseGroups'),
    },
    {
      id: Steps.ChooseMembers,
      label: t('group.chooseMembers'),
    },
  ];
};
