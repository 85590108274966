export enum SelectedState {
  CHECKED = 'checked',
  UNCHECKED = 'unchecked',
}

export enum CheckboxType {
  CHECK_MARK = 'checkMark',
  SQUARE = 'square',
  MINUS = 'minus',
}
