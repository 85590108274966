import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const Container = styled.div`
  ${tw`flex flex-col gap-2 mt-2`}
`;

export const EmptyMessage = styled.p`
  ${tw`m-auto text-dim-gray mt-2`}
`;
