import React, {
  FC, MouseEvent
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  ArrowInCircleUpIcon,
  EditPointedIcon,
  LogOutIcon,
  TrashDeleteIcon,
} from 'src/shared/icons';
import {
  ActionsWithGroup
} from 'src/pages/GroupsOverview/GroupsOverview';
import {
  UserGroupRole, GroupOrganizationStatus
} from 'src/redux/openapi';

import {
  warningToast
} from '../Toasts';

import * as Style from './GroupToOrganizationContext.styles';

interface GroupToOrganizationContextProps {
  openModalConfirm: (action: ActionsWithGroup) => void;
  role?: UserGroupRole;
  organizationStatus: GroupOrganizationStatus;
  groupRole?: UserGroupRole;
}

export const GroupToOrganizationContext: FC<
GroupToOrganizationContextProps
> = ({
  openModalConfirm,
  role = UserGroupRole.USER,
  organizationStatus,
  groupRole,
}) => {
  const handleClick = (
    event: MouseEvent<HTMLButtonElement>,
    option: ActionsWithGroup,
  ) => {
    event.stopPropagation();
    openModalConfirm(option);
  };

  const {
    t
  } = useTranslation();

  const onRequestClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    switch (organizationStatus) {
      case 'PENDING':
        return warningToast(t('errors.groupRequestSent'));
      case 'ACCEPTED':
        return warningToast(t('errors.groupIsPartOfOrg'));
      default:
        return openModalConfirm(ActionsWithGroup.Request);
    }
  };

  const isAdmin = role === UserGroupRole.ADMIN;
  const isSuperAdmin = role === UserGroupRole.SUPERADMIN;

  return (
    <Style.ContextContainer>
      <Style.ContextTitle>{t('group.groupSettings')}</Style.ContextTitle>

      {(isAdmin || isSuperAdmin) && (
        <Style.ContextActionTitle>
          <Style.ContextActionButton
            onClick={(event) => handleClick(
              event,
              ActionsWithGroup.Edit
            )}
          >
            <EditPointedIcon className="text-dark-gray" />

            {t('group.editGroup')}
          </Style.ContextActionButton>
        </Style.ContextActionTitle>
      )}

      {isSuperAdmin && organizationStatus !== 'ACCEPTED' && (
        <Style.ContextActionTitle>
          <Style.ContextActionButton onClick={onRequestClick}>
            <ArrowInCircleUpIcon className="text-dark-gray" />

            {t('group.requestOrganization')}
          </Style.ContextActionButton>
        </Style.ContextActionTitle>
      )}

      <Style.ContextDividerLine />

      {!!groupRole && (
        <Style.ContextActionTitle>
          <Style.ContextActionButton
            onClick={(event) => handleClick(
              event,
              ActionsWithGroup.Leave
            )}
          >
            <LogOutIcon className="text-dark-gray" />

            {t('group.leaveTheGroup')}
          </Style.ContextActionButton>
        </Style.ContextActionTitle>
      )}

      {isSuperAdmin && (
        <Style.ContextActionTitle>
          <Style.ContextActionButton
            onClick={(event) => handleClick(
              event,
              ActionsWithGroup.Delete
            )}
          >
            <TrashDeleteIcon className="text-red-text-error" />

            {t('group.deleteTheGroup')}
          </Style.ContextActionButton>
        </Style.ContextActionTitle>
      )}
    </Style.ContextContainer>
  );
};
