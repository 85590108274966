import {
  createApi
} from '@reduxjs/toolkit/query/react';

import {
  baseQuery
} from '../baseQuery';
import {
  HTTPMethod,
  Response,
  OnlyMessageResponse,
  DataResponse,
} from '../config';

import {
  ResetPasswordRequest,
  RecoveryCodeRequest,
  CreatePasswordRequest,
  GetLoginExistsResponse,
} from './types';

export const authApi = createApi({
  baseQuery,
  reducerPath: 'authApi',
  endpoints: (builder) => ({
    getLoginExists: builder.mutation<
    DataResponse<GetLoginExistsResponse>,
    string
    >({
      query: (login) => `/api/auth/sign-in/exists?login=${login}`,
    }),
    resetPassword: builder.mutation<OnlyMessageResponse, ResetPasswordRequest>({
      query: (body) => ({
        url: '/api/auth/forgot-password/request',
        method: HTTPMethod.POST,
        body,
      }),
    }),
    recoveryCode: builder.mutation<OnlyMessageResponse, RecoveryCodeRequest>({
      query: (body) => ({
        url: '/api/auth/forgot-password/verify',
        method: HTTPMethod.POST,
        body,
      }),
    }),
    resendRecoveryCode: builder.mutation<OnlyMessageResponse, void>({
      query: () => ({
        url: '/api/auth/forgot-password/resend',
        method: HTTPMethod.POST,
      }),
    }),
    createPassword: builder.mutation<
    OnlyMessageResponse,
    CreatePasswordRequest
    >({
      query: (body) => ({
        url: '/api/auth/forgot-password/reset',
        method: HTTPMethod.POST,
        body,
      }),
    }),
    signOut: builder.mutation<Response<void>, void>({
      query: () => ({
        url: '/api/auth/sign-out',
        method: HTTPMethod.POST,
      }),
    }),
  }),
});

export const {
  useResetPasswordMutation,
  useRecoveryCodeMutation,
  useResendRecoveryCodeMutation,
  useCreatePasswordMutation,
  useSignOutMutation,
  useGetLoginExistsMutation,
} = authApi;
