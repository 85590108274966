import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  SurveyQuestionOption
} from 'src/redux/openapi';
import {
  CheckmarkCircleIcon,
  RadialСheckFalseIcon,
  RadialСheckTrueIcon,
} from 'src/shared/icons';

import * as Style from './SurveyButton.styles';

interface SurveyButtonProps {
  option: SurveyQuestionOption;
  isVoted: boolean;
  isSelected: boolean;
  onSelect: (id: string) => void;
  isQuestionDeactivated: boolean;
}

export const SurveyButton: FC<SurveyButtonProps> = ({
  option,
  isVoted,
  isSelected,
  onSelect,
  isQuestionDeactivated,
}) => {
  const {
    isChosen, id, title, votes, votesPercent
  } = option;

  const {
    t
  } = useTranslation();

  const handleRadioClick = () => {
    onSelect(id);
  };

  return (
    <Style.Label
      $isSelected={isChosen || isSelected}
      $isVoted={isVoted || isQuestionDeactivated}
    >
      <Style.RadioInput
        type="radio"
        checked={isSelected}
        onChange={handleRadioClick}
        disabled={isVoted || isQuestionDeactivated}
      />

      <Style.ContentWrapper>
        <Style.IconContainer>
          {((!isVoted && !isSelected) || (isVoted && !isChosen)) && (
            <RadialСheckFalseIcon />
          )}

          {isVoted && isChosen && <CheckmarkCircleIcon />}

          {!isVoted && isSelected && <RadialСheckTrueIcon />}
        </Style.IconContainer>

        <Style.Title>{title}</Style.Title>

        {isVoted && (
          <>
            <Style.VotesAmount>
              {`${votes} ${t(`question.${votes === 1 ? 'vote' : 'votes'}`)}`}
            </Style.VotesAmount>

            <Style.Result>{`${votesPercent}%`}</Style.Result>
          </>
        )}
      </Style.ContentWrapper>

      {isVoted && (
        <Style.SurveyBGResult
          $isSelected={isChosen}
          $width={votesPercent}
        />
      )}
    </Style.Label>
  );
};
