import {
  useDeleteQuestionFavoritesMutation,
  usePostQuestionFavoritesMutation,
} from 'src/redux/openapi';
import {
  successfulToast
} from 'src/shared/components';
import {
  showWarningFromServer
} from 'src/shared/utils';

export const useToggleFavQuestion = (
  isFavorite: boolean,
  callback?: () => void,
) => {
  const [addFavoriteQuestion, {
    isLoading: isLoadingAddFavoriteQuestion
  }] = usePostQuestionFavoritesMutation();

  const [
    removeFavoriteQuestion,
    {
      isLoading: isLoadingRemoveFavoriteQuestion
    },
  ] = useDeleteQuestionFavoritesMutation();

  const isChangingFavQuestion = isLoadingAddFavoriteQuestion || isLoadingRemoveFavoriteQuestion;

  const handleChangeFavQuestion = async (questionID: string) => {
    if (isChangingFavQuestion) {
      return;
    }

    const apiCallback = isFavorite
      ? removeFavoriteQuestion
      : addFavoriteQuestion;

    try {
      const resp = await apiCallback({
        id: questionID,
      }).unwrap();

      successfulToast(resp.message);

      if (callback) {
        callback();
      }
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  return {
    handleChangeFavQuestion,
    isChangingFavQuestion,
  };
};
