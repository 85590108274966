import React, {
  FC
} from 'react';
import clsx from 'clsx';
import {
  useTranslation
} from 'react-i18next';

import {
  ArrowDownIcon
} from 'src/shared/icons';
import {
  themeColors
} from 'src/shared/utils';

import {
  Spinner
} from '../Spinner';

import * as Style from './ShowMoreButton.styles';

interface ShowMoreButtonProps {
  onClick: () => void;
  isAllShown: boolean;
  isFetching?: boolean;
}

export const ShowMoreButton: FC<ShowMoreButtonProps> = ({
  onClick,
  isAllShown,
  isFetching,
}) => {
  const {
    t
  } = useTranslation();

  return (
    <Style.ButtonContainer onClick={onClick}>
      {`${t('common.show')} `}

      {!isAllShown || isFetching ? t('common.more') : t('common.less')}

      {isFetching ? (
        <Spinner
          size={18}
          centered={false}
          color={themeColors['button-blue']}
        />
      ) : (
        <ArrowDownIcon
          className={clsx({
            'rotate-180': isAllShown,
          })}
        />
      )}
    </Style.ButtonContainer>
  );
};
