import React, {
  FC
} from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import {
  useTranslation
} from 'react-i18next';

import {
  Notification
} from 'src/redux/openapi';
import {
  Spinner
} from 'src/shared/components';
import {
  NotificationContainer
} from 'src/entities/NotificationsRoute';

import {
  NotificationItem
} from '../NotificationItem';

interface NotificationsListProps {
  notifications: Notification[];
  onLoadMore: () => void;
  hasMore: boolean;
  query: string;
}

export const NotificationsList: FC<NotificationsListProps> = ({
  notifications,
  onLoadMore,
  hasMore,
  query,
}) => {
  const {
    i18n
  } = useTranslation();

  return (
    <InfiniteScroll
      loadMore={onLoadMore}
      hasMore={hasMore}
      loader={(
        <NotificationContainer key="spinner">
          <Spinner size={24} />
        </NotificationContainer>
      )}
      useWindow={false}
      threshold={100}
      key={i18n.language}
    >
      {notifications.map((notification) => (
        <NotificationItem
          notification={notification}
          key={notification.id}
          query={query}
        />
      ))}
    </InfiniteScroll>
  );
};
