import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

import {
  themeColors
} from 'src/shared/utils';

export const MenuHeader = styled.div`
  ${tw`flex gap-3 items-center pt-4 pb-6 px-6`}
`;

export const UserNameContainer = styled.div`
  ${tw`grow flex flex-col gap-1`}
`;

export const UserName = styled.p`
  ${tw`font-bold text-17-26`}
`;

export const UserDescription = styled.p`
  ${tw`font-medium text-15-20`}
`;

export const MoreMenuContainer = styled.div`
  ${tw`gap-1 flex flex-col p-2 w-243px`}
`;

export const MoreMenuItem = styled.button<{ $color?: string }>`
  ${tw`flex gap-2 items-center font-medium text-17-26 p-2`}

  ${({
    $color
  }) => `color: ${$color || themeColors['dark-gray']};`}
`;

export const Divider = styled.div`
  ${tw`w-full h-px rounded-10px bg-gray94`}
`;
