import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const ModalFixedLayer = styled.div<{ $isHidden?: boolean }>`
  ${({
    $isHidden
  }) => ($isHidden ? tw`hidden` : tw`fixed top-0 left-0 bottom-0 right-0 z-1`)}
`;

export const MenuContainer = styled.div`
  ${tw`
    p-2 flex flex-col gap-1
  `}
`;

export const FilterTitle = styled.div`
  ${tw`text-13-21 text-dark-gray text-left font-medium text-dark-gray px-2 py-1`}
`;

export const Label = styled.label<{ $isActive?: boolean }>`
  ${tw`flex gap-2 p-2 items-center rounded cursor-pointer`}

  ${({
    $isActive
  }) => $isActive && tw`bg-alice-blue`}
`;

export const LabelTitle = styled.div<{ $isActive?: boolean }>`
  ${tw`text-17-26 font-medium grow`}

  ${({
    $isActive
  }) => ($isActive ? tw`text-button-blue` : tw`text-dark-gray`)}
`;

export const FilterSeparatorLine = styled.div`
  ${tw`h-px mx-2 bg-gray94`}
`;

export const LabelDates = styled.label`
  ${tw`flex gap-2 p-2 items-center rounded justify-between cursor-pointer`}
`;

export const LabelDatesTitle = styled.div`
  ${tw`text-base font-medium text-gray7`}
`;

export const LabelMyOnly = styled.label`
  ${tw`px-2 py-3 flex gap-2 items-center justify-between cursor-pointer`}
`;

export const FilterSwitcher = styled.div<{ $isActive?: boolean }>`
  ${tw`
    rounded-55px w-12 p-px after:content-[''] after:block after:w-1/2
    after:bg-white after:pb-50% after:rounded-full after:transition-all
  `}

  ${({
    $isActive
  }) => ($isActive ? tw`bg-button-blue after:translate-x-full` : tw`bg-dim-gray`)}
`;

export const ListItem = styled.div`
  ${tw`p-2 flex justify-between group-hover:bg-alice-blue`}
`;

export const ListItemCount = styled.div`
  ${tw`px-1 rounded-100px group-hover:bg-white bg-input-blue`}
`;
