import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const Header = styled.div`
  ${tw`flex justify-between items-center gap-3 mb-4`}
`;

export const HeaderTitle = styled.div`
  ${tw`flex gap-3 items-center text-xl font-bold text-gray7`}
`;

export const Location = styled.div`
  ${tw`text-13-21 font-medium text-dim-gray`}
`;

export const TooltipMessage = styled.div`
  ${tw`text-center max-w-128px`}
`;

export const ActivityRow = styled.div`
  ${tw`flex justify-between items-center mb-4 p-1`}
`;

export const ActivityChild = styled.div`
  ${tw`flex gap-18px`}
`;

export const QuestionsContainer = styled.div`
  ${tw`grid grid-cols-2 gap-4`}
`;

export const QuestionContainer = styled.div`
  ${tw`rounded bg-pale-blue px-4 py-3`}
`;

export const QuestionDates = styled.div`
  ${tw`text-xs leading-5 font-medium text-dim-gray`}
`;

export const QuestionTitle = styled.div`
  ${tw`text-17-26 font-bold text-gray7`}
`;
