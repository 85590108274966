import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  NotificationContainer,
  NotificationGroupContainer,
} from 'src/entities/NotificationsRoute';
import {
  getTimeAgo
} from 'src/shared/utils';
import {
  Avatar
} from 'src/shared/components';

import * as Style from './Notifications.styles';

interface OrgAdminMsgNotificationsProps {
  isNew: boolean;
  message: string;
  createdAt: string;
  organizationName: string;
  getHighlightedText: (text: string) => (string | JSX.Element)[];
}

export const OrgAdminMsgNotifications: FC<OrgAdminMsgNotificationsProps> = ({
  isNew,
  message,
  createdAt,
  organizationName,
  getHighlightedText,
}) => {
  const {
    t
  } = useTranslation();

  return (
    <NotificationContainer $isNew={isNew}>
      <Style.HeaderWithAvatarWrapper>
        <Style.ImageContainer $isNew={isNew}>
          <Avatar
            size={56}
            src={null}
            userName={organizationName}
          />
        </Style.ImageContainer>

        <Style.Description>
          <Style.NotificationHeader>
            <Style.NotificationDate>
              {`${getTimeAgo(createdAt)} ${t('date.ago')}`}
            </Style.NotificationDate>
          </Style.NotificationHeader>

          <Style.NotificationText>
            <Style.NotificationBoldText>
              {getHighlightedText(organizationName)}
            </Style.NotificationBoldText>

            {` ${t('organisation.adminSendMessage')}`}
          </Style.NotificationText>
        </Style.Description>
      </Style.HeaderWithAvatarWrapper>

      <NotificationGroupContainer
        $wasRead={!isNew}
        $width={468}
        $mediumFont
        $leftShift={68}
      >
        {message}
      </NotificationGroupContainer>
    </NotificationContainer>
  );
};
