import {
  t
} from 'i18next';

export enum SelectOption {
  All,
  MoreThanSixWeeks,
}

export const getSelectOptions = () => [
  {
    value: SelectOption.All,
    label: t('group.allInactiveUsers'),
  },
  {
    value: SelectOption.MoreThanSixWeeks,
    label: t('group.inactiveMoreThan6weeks'),
  },
];
