import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MainContainer = styled.div`
  ${tw`mb-8`}
`;

export const FiltersContainer = styled.div`
  ${tw`overflow-auto mb-8`}
`;

export const SurveyContainer = styled.div`
  ${tw`flex flex-col lg:(grid grid-cols-2) gap-4`}
`;
