import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const TableFooter = styled.div`
  ${tw`flex justify-between p-2 mt-auto border-t border-t-gray94`}
`;

export const PageSelectorContainer = styled.div`
  ${tw`flex gap-2 items-center text-sm text-dim-gray ml-auto`}
`;
