import {
  useState, useRef, useEffect
} from 'react';

import {
  successfulToast
} from 'src/shared/components';
import {
  usePostGroupQuestionsMutation,
  usePatchGroupQuestionsDeactivationMutation,
} from 'src/redux/openapi';
import {
  areAllArraysInObjectEmpty,
  showWarningFromServer,
} from 'src/shared/utils';

import {
  DeactivatedList, SelectedList
} from '../types';

export const useSaveEditQuestionsResult = (id: string) => {
  const [isSavingInProcess, setIsSavingInProcess] = useState(false);

  const [deactivationList, setDeactivationList] = useState<DeactivatedList | null>(null);

  const [postApiGroupsByIdQuestions] = usePostGroupQuestionsMutation({});

  const [getGroupListToDeactivate] = usePatchGroupQuestionsDeactivationMutation();

  const resolveRef = useRef<((value: boolean) => void) | null>(null);

  const checkQuestionsToDeactivate = async (selectedList: SelectedList) => {
    const listToDeactivate = await getGroupListToDeactivate({
      id,
      updateGroupQuestionsBodySchema: selectedList,
    }).unwrap();

    if (!listToDeactivate.data) {
      return null;
    }

    const isItemsToDeactivate = !areAllArraysInObjectEmpty(
      listToDeactivate.data as Record<string, unknown>,
    );

    if (!isItemsToDeactivate) {
      return null;
    }

    return listToDeactivate.data;
  };

  const clearDeactivationList = () => {
    setDeactivationList(null);
  };

  const saveEditResult = async (selectedList: SelectedList) => {
    const response = await postApiGroupsByIdQuestions({
      id,
      updateGroupQuestionsBodySchema: selectedList,
    }).unwrap();

    successfulToast(response.message);
  };

  const handleProceed = (shouldProceed = false) => {
    if (resolveRef.current) {
      resolveRef.current(shouldProceed);
      resolveRef.current = null;
    }
  };

  useEffect(
    () => {
      return () => {
        resolveRef.current = null;
      };
    },
    []
  );

  const waitToDeactivationConfirmation = () => {
    return new Promise((resolve) => {
      resolveRef.current = resolve;
    });
  };

  const onSave = async (questionIds: string[]) => {
    const selectedList = {
      questionIds,
    };

    try {
      setIsSavingInProcess(true);

      const deactivatedList = await checkQuestionsToDeactivate(selectedList);

      if (deactivatedList) {
        setIsSavingInProcess(false);

        setDeactivationList(deactivatedList);

        const confirmResult = await waitToDeactivationConfirmation();

        if (confirmResult) {
          setIsSavingInProcess(true);
          await saveEditResult(selectedList);
          clearDeactivationList();
        }
      } else {
        await saveEditResult(selectedList);
      }
    } catch (error) {
      showWarningFromServer(error);
    } finally {
      setIsSavingInProcess(false);
    }
  };

  return {
    onSave,
    isSavingInProcess,
    handleProceed,
    deactivationList,
    clearDeactivationList,
  };
};
