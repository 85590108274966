import React, {
  PropsWithChildren, FC, useMemo, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  Avatar, Spinner, Tooltip
} from 'src/shared/components';
import {
  Logo
} from 'src/shared/icons';
import {
  useGetQuestionGroupsQuery
} from 'src/redux/openapi';

import * as Style from './InfoTooltip.styles';

interface InfoTooltipProps extends PropsWithChildren {
  questionId: string;
}

export const InfoTooltip: FC<InfoTooltipProps> = ({
  children, questionId
}) => {
  const [wasHovered, setWasHovered] = useState(false);

  const {
    data: groupsData, isLoading
  } = useGetQuestionGroupsQuery(
    {
      id: questionId,
      limit: 20,
    },
    {
      skip: !wasHovered,
      refetchOnMountOrArgChange: true,
    },
  );

  const groups = useMemo(
    () => groupsData?.data?.groups || [],
    [groupsData]
  );

  const {
    t
  } = useTranslation();

  const tooltipMessage = isLoading ? (
    <Spinner size={24} />
  ) : (
    <Style.ContentContainer>
      {!groups.length && t('empty.noInformation')}

      {groups.map(
        ({
          id,
          name,
          groupEstimatesAvg: value,
          groupEstimatesChange: growth,
        }) => (
          <Style.RowContainer key={id}>
            <Avatar
              src={Logo}
              userName={name}
              isLogo
              containerClassName="border border-gray-whisper !rounded-full"
            />

            <Style.GroupName>{name}</Style.GroupName>

            <Style.AmountContainer>
              {`${value}%`}

              {!!growth && (
                <Style.Growth $isIncrease={growth >= 0}>
                  {`(${growth}%)`}
                </Style.Growth>
              )}
            </Style.AmountContainer>
          </Style.RowContainer>
        ),
      )}
    </Style.ContentContainer>
  );

  return (
    <Tooltip
      variant="light"
      hideOnScroll={false}
      messageContainerProps={{
        className: 'w-278px',
      }}
      onHoverCallback={setWasHovered}
      message={tooltipMessage}
    >
      {children}
    </Tooltip>
  );
};
