import React, {
  FC
} from 'react';
import {
  t
} from 'i18next';

import {
  GlobeIcon,
  MessageIcon,
  PeopleIcon,
  PersonIcon,
  TrendingUpIcon,
} from 'src/shared/icons';
import {
  SimpleCard,
  ActivityRow,
  ActivityTile,
  StatisticRow,
  StatisticTile,
} from 'src/entities/Questions';
import {
  ActionButtonProps
} from 'src/widgets';
import {
  themeColors
} from 'src/shared/utils/themeColors';
import {
  ButtonTitles
} from 'src/shared/api/types';
import {
  QuestionsActiveStatistics
} from 'src/redux/openapi';

interface ScoredCardProps {
  card: QuestionsActiveStatistics;
  buttonProps?: ActionButtonProps;
}

export const ScoredCard: FC<ScoredCardProps> = ({
  card, buttonProps
}) => {
  const {
    participantsCount,
    commentsCount,
    updatesCount,
    userEstimatesAvg,
    globalEstimatesAvg,
    groupEstimatesAvg,
    id,
  } = card;

  return (
    <SimpleCard
      card={card}
      buttonProps={{
        buttonTextColor: themeColors['dim-gray'],
        buttonTitle: t(`buttons.${ButtonTitles.Deactivated}`),
        buttonWithBorder: false,
        buttonBackgroundColor: themeColors['gray-whisper'],
        buttonWithIcon: false,
        ...buttonProps,
      }}
    >
      <ActivityRow>
        <ActivityTile
          icon={<PeopleIcon className="text-dim-gray" />}
          amount={participantsCount}
        />

        <ActivityTile
          icon={<MessageIcon />}
          amount={commentsCount}
        />

        <ActivityTile
          icon={<TrendingUpIcon className="text-dim-gray" />}
          amount={updatesCount}
        />
      </ActivityRow>

      {(!!userEstimatesAvg || !!globalEstimatesAvg || !!groupEstimatesAvg) && (
        <StatisticRow>
          <StatisticTile
            amount={userEstimatesAvg}
            icon={<PersonIcon />}
            withBorder
          />

          <StatisticTile
            amount={globalEstimatesAvg}
            icon={<GlobeIcon />}
            withBorder
          />

          <StatisticTile
            amount={groupEstimatesAvg}
            icon={<PeopleIcon className="text-dim-gray" />}
            questionId={id}
            withBorder
          />
        </StatisticRow>
      )}
    </SimpleCard>
  );
};
