import React, {
  ChangeEvent, FC, useEffect, useRef, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import {
  Controller, useForm
} from 'react-hook-form';
import {
  zodResolver
} from '@hookform/resolvers/zod';

import {
  Avatar,
  Button,
  Spinner,
  errorToast,
  successfulToast,
} from 'src/shared/components';
import {
  useTypedSelector
} from 'src/redux';
import {
  userSelectors
} from 'src/redux/user';
import {
  MenuField
} from 'src/pages/Login/types';
import {
  useGetInputValidation
} from 'src/shared/hooks';
import {
  showWarningFromServer
} from 'src/shared/utils';
import {
  usePatchUserCurrentMutation
} from 'src/redux/openapi';

import {
  UserThemes
} from '../UserThemes';
import {
  EditAvatarModal
} from '../EditAvatarModal';

import * as Style from './PublicInfo.style';

interface PublicInfoProps {
  onModalToggle: (isOpen: boolean) => void;
}

export const PublicInfo: FC<PublicInfoProps> = ({
  onModalToggle
}) => {
  const {
    t
  } = useTranslation();

  const {
    avatarUrl,
    name,
    displayName: userDisplayName,
    description,
  } = useTypedSelector(userSelectors.user);

  const stateDescription = description || '';
  const stateDisplayName = userDisplayName || '';

  const {
    editPublicInfoSchema
  } = useGetInputValidation();

  const {
    handleSubmit,
    register,
    reset,
    watch,
    control,
    setValue,
    formState: {
      errors
    },
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      [MenuField.DisplayName]: stateDisplayName,
      [MenuField.ShortDescription]: stateDescription,
    },
    resolver: zodResolver(editPublicInfoSchema),
  });

  const displayNameValue = watch(MenuField.DisplayName);
  const shortDescriptionValue = watch(MenuField.ShortDescription);

  const needSaveChanges = displayNameValue !== stateDisplayName
    || shortDescriptionValue !== stateDescription;

  const [updateUserData, {
    isLoading: isSubmitting
  }] = usePatchUserCurrentMutation();

  const onSubmit = handleSubmit(async ({
    displayName, shortDescription
  }) => {
    const trimmedDisplayName = displayName.trim() || undefined;

    try {
      const response = await updateUserData({
        updateCurrentUserBodySchema: {
          displayName: trimmedDisplayName,
          description: shortDescription,
        },
      }).unwrap();

      successfulToast(response.message);

      const {
        displayName: updatedDisplayName
      } = response.data.user;

      setValue(
        MenuField.DisplayName,
        updatedDisplayName || ''
      );
    } catch (error) {
      showWarningFromServer(error);
    }
  });

  const [newFileSrc, setNewFileSrc] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target?.files?.length) {
      return;
    }

    const selectedFile = event.target.files[0];

    if (!/^image\/(png|jpeg|jpg)$/i.test(selectedFile.type)) {
      errorToast(t('editAccount.selectValidImage'));

      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target?.result as string;

      img.onload = () => {
        if (img.width < 256 || img.height < 256) {
          errorToast(t('editAccount.selectImageError'));
        } else {
          const file = URL.createObjectURL(selectedFile);
          setNewFileSrc(file);
        }
      };
    };

    reader.readAsDataURL(selectedFile);
  };

  const handleFileUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
      fileInputRef.current.click();
    }
  };

  const onAvatarModalClose = () => {
    setNewFileSrc(null);
  };

  useEffect(
    () => {
      const isSomeModalOpen = !!newFileSrc;

      onModalToggle(isSomeModalOpen);
    },
    [newFileSrc]
  );

  return (
    <>
      <Style.ContentContainer>
        <Style.SectionContainer>
          <Style.SectionTitle>{t('editAccount.publicInfo')}</Style.SectionTitle>

          <Style.SectionContent>
            <Style.AvatarRow>
              <Avatar
                src={avatarUrl}
                userName={userDisplayName || name}
                size={72}
              />

              <Style.AvatarSettings>
                <input
                  type="file"
                  accept=".jpg, .jpeg, .png"
                  onChange={handleFileChange}
                  style={{
                    display: 'none',
                  }}
                  ref={fileInputRef}
                />

                <Style.ActionButton onClick={handleFileUpload}>
                  {t('editAccount.uploadAvatar')}
                </Style.ActionButton>

                <Style.AvatarDescription>
                  {t('editAccount.avatarDescription')}
                </Style.AvatarDescription>
              </Style.AvatarSettings>
            </Style.AvatarRow>

            <Style.InputLabel>
              {t('editAccount.displayName')}

              <Style.Input
                placeholder={t('editAccount.displayName')}
                {...register(MenuField.DisplayName)}
              />

              {errors[MenuField.DisplayName]?.message ? (
                <Style.ErrorMessage>
                  {`${errors[MenuField.DisplayName]?.message}`}
                </Style.ErrorMessage>
              ) : (
                <Style.InputHint>{t('editAccount.nameHint')}</Style.InputHint>
              )}
            </Style.InputLabel>

            <Controller
              name={MenuField.ShortDescription}
              control={control}
              render={({
                field
              }) => {
                return (
                  <Style.InputLabel>
                    <Style.FlexRowContainer>
                      {t('editAccount.shortDescription')}

                      <p>{`${field.value.length}/50`}</p>
                    </Style.FlexRowContainer>

                    <Style.Input
                      placeholder={t('editAccount.shortDescription')}
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                    />

                    {errors[MenuField.ShortDescription]?.message && (
                      <Style.ErrorMessage>
                        {`${errors[MenuField.ShortDescription].message}`}
                      </Style.ErrorMessage>
                    )}
                  </Style.InputLabel>
                );
              }}
            />
          </Style.SectionContent>

          {needSaveChanges && (
            <Style.MenuFooter>
              <Button
                variant="big-black"
                className="w-max font-bold text-xl"
                onClick={() => reset()}
              >
                {t('buttons.cancel')}
              </Button>

              <Button
                variant="big-blue"
                className="font-bold text-xl !w-179px"
                onClick={onSubmit}
              >
                {isSubmitting ? (
                  <Spinner
                    size={24}
                    color="white"
                  />
                ) : (
                  t('buttons.saveChanges')
                )}
              </Button>
            </Style.MenuFooter>
          )}

          <UserThemes />
        </Style.SectionContainer>
      </Style.ContentContainer>

      <EditAvatarModal
        isOpen={!!newFileSrc}
        onClose={onAvatarModalClose}
        fileSrc={newFileSrc}
      />
    </>
  );
};
