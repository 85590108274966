import React, {
  FC
} from 'react';
import {
  Trans, useTranslation
} from 'react-i18next';
import {
  useLocation, useNavigate
} from 'react-router-dom';

import {
  useDeleteGroupMutation
} from 'src/shared/api/groups/groupsApi';
import {
  ModalDelete, successfulToast
} from 'src/shared/components';
import {
  ROUTE, showWarningFromServer
} from 'src/shared/utils';

interface DeleteGroupModalProps {
  id?: string | null;
  onSuccessDelete?: () => void;
  closeModal: () => void;
  isOpen: boolean;
}

export const DeleteGroupModal: FC<DeleteGroupModalProps> = ({
  id,
  onSuccessDelete,
  closeModal,
  isOpen,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const isCurrentGroupPage = !!id && location.pathname.includes(id);

  const [deleteGroup, {
    isLoading
  }] = useDeleteGroupMutation();

  const onDelete = async () => {
    if (isLoading || !id) {
      return;
    }

    try {
      const response = await deleteGroup(id).unwrap();

      successfulToast(response?.message);

      if (onSuccessDelete) {
        onSuccessDelete();
      }

      if (isCurrentGroupPage) {
        navigate(`/${ROUTE.GROUPS}`);
      }

      closeModal();
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  const {
    t
  } = useTranslation();

  const title = (
    <Trans
      i18nKey="modal.sureWantToDelete"
      values={{
        type: t('modal.group'),
      }}
    />
  );

  const modalText = (
    <Trans
      i18nKey="modal.afterDeleting"
      values={{
        type: t('modal.aGroup'),
        action: t('modal.informationWillBeLost'),
      }}
    />
  );

  return (
    <ModalDelete
      isOpen={isOpen}
      closeModal={closeModal}
      title={title}
      modalText={modalText}
      onDelete={onDelete}
      isLoading={isLoading}
      modalWidth={512}
    />
  );
};
