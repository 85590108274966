import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const EmailRow = styled.div`
  ${tw`px-6 mb-10`}
`;

export const Title = styled.h3`
  ${tw`text-22-30 font-bold text-gray7`}
`;

export const DaysRow = styled.div`
  ${tw`flex gap-2 items-center mt-6`}
`;

export const DayButton = styled.button<{ $isActive: boolean }>`
  ${tw`flex items-center p-2.5 justify-center rounded-100px bg-white hover:bg-cultured border`}

  ${({
    $isActive
  }) => ($isActive
    ? tw`border-button-blue text-button-blue`
    : tw`border-gray90 text-dark-gray`)}
`;

export const DayName = styled.div`
  ${tw`text-15-20 font-bold w-5`}
`;

export const SubTitle = styled.div`
  ${tw`text-15-20 font-medium text-dim-gray`}
`;
