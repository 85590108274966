import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  PopoverMenu
} from 'src/shared/components';
import {
  ArrowRightIcon
} from 'src/shared/icons';
import {
  TChildCheckbox
} from 'src/widgets/NestedSelectMenu';

import * as Style from '../CommentsFilterContext.styles';

import {
  GroupSelector
} from './GroupSelector';

interface GroupSelectorSectionProps {
  selectedGroups: string[];
  groups: TChildCheckbox[];
  onApply: (value: string[]) => void;
}

export const GroupSelectorSection: FC<GroupSelectorSectionProps> = ({
  selectedGroups,
  groups,
  onApply,
}) => {
  const {
    t
  } = useTranslation();

  return (
    <PopoverMenu
      placement="left-start"
      applyMaxHeight={false}
      renderCustomButton={({
        isOpen, toggleMenu, triggerProps
      }) => (
        <Style.Label
          onClick={toggleMenu}
          $isActive={isOpen}
          {...triggerProps}
        >
          <Style.ListItemCount>
            {`${selectedGroups.length}/${groups.length}`}
          </Style.ListItemCount>

          <Style.LabelTitle $isActive={isOpen}>
            {t('common.groups')}
          </Style.LabelTitle>

          <ArrowRightIcon />
        </Style.Label>
      )}
    >
      <GroupSelector
        groups={groups}
        onApply={onApply}
        selectedGroups={selectedGroups}
      />
    </PopoverMenu>
  );
};
