import dayjs from 'dayjs';
import {
  t
} from 'i18next';

import {
  QuestionsActiveStatistics,
  QuestionsAvailableStatistics,
} from 'src/redux/openapi';

import {
  QuestionCard
} from './types';

export const formatNumber = (value: number) => {
  return value.toLocaleString('en-US').replace(
    /,/g,
    '.'
  );
};

export const getColumnHeaders = (isExtendedTable: boolean) => {
  const headers = [
    {
      id: 0,
      label: `${t('question.questionName')}`,
    },
  ];

  if (!isExtendedTable) {
    return [
      ...headers,
      {
        id: 1,
        label: '',
      },
    ];
  }

  return [
    ...headers,
    {
      id: 1,
      label: `${t('common.forecasts')}`,
    },
    {
      id: 2,
      label: `${t('common.participants')}`,
    },
    {
      id: 3,
      label: `${t('common.comments')}`,
    },
    {
      id: 4,
      label: `${t('common.updates')}`,
    },
    {
      id: 5,
      label: '',
    },
  ];
};

export const getColumnWidths = (isExpanded: boolean, isExtended: boolean) => {
  if (isExpanded && !isExtended) {
    return [47, 53];
  }

  if (!isExpanded && !isExtended) {
    return [35, 65];
  }

  if (isExpanded && isExtended) {
    return [36, 32, 8, 8, 8, 8];
  }

  return [30, 31, 11, 11, 10, 7];
};

export const formatDate = (start: number, finish: number): string => {
  const startDate = dayjs(start);
  const finishDate = dayjs(finish);

  const formattedStartDate = startDate.format('MMM D, YYYY');
  const formattedEndDate = finishDate.format('MMM D, YYYY');

  return `${formattedStartDate} - ${formattedEndDate}`;
};

export const isQuestionWithStatistics = (
  card: QuestionCard,
): card is QuestionsActiveStatistics => {
  return 'userEstimatesAvg' in card;
};

export const isQuestionOnboardingText = (
  card: QuestionCard,
): card is QuestionsAvailableStatistics => {
  return 'onboardingText' in card;
};
