import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const MainContainer = styled.div`
  ${tw`max-h-422px w-344px overflow-auto px-4`}
`;

export const Title = styled.p`
  ${tw`text-gray7 text-base font-semibold pt-4 mb-3`}
`;

export const QuestionsContainer = styled.div`
  ${tw`flex flex-col gap-1`}
`;

export const QuestionContainer = styled.div`
  ${tw`flex flex-col gap-1 text-gray7 text-13-21 font-medium py-4 border-b border-b-gray94 last:border-b-0`}
`;

export const StatisticRow = styled.div`
  ${tw`flex gap-3`}
`;
