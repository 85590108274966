import React, {
  FC, useEffect, useState
} from 'react';
import {
  useNavigate, useParams
} from 'react-router-dom';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import {
  useTranslation
} from 'react-i18next';
import dayjs from 'dayjs';

import {
  Button,
  Input,
  InputType,
  ModalCancelCalibration,
  Spinner,
  successfulToast,
} from 'src/shared/components';
import {
  ClockIcon, CornerLineIcon, DashedLineIcon
} from 'src/shared/icons';
import {
  PageLayout
} from 'src/widgets';
import {
  ROUTE,
  getArrayOfDates,
  showWarningFromServer,
} from 'src/shared/utils';
import {
  useGetCalibrationQuestionsCurrentQuery,
  usePostCalibrationAnswersMutation,
} from 'src/redux/openapi';

import * as Style from './CalibrationQuestion.style';

const TIME_TO_ANSWER = 45;
const TIME_BE_GAP = 2;

export const CalibrationQuestion: FC = () => {
  const params = useParams();
  const navigate = useNavigate();

  const now = dayjs();

  const [lowerPoint, setLowerPoint] = useState(0);
  const [upperPoint, setUpperPoint] = useState(100);

  const [isFinished, setIsFinished] = useState(false);

  const [countDown, setCountDown] = useState(0);

  const {
    calibrationId
  } = params;

  const {
    totalQuestions,
    currentQuestionNumber,
    currentQuestion,
    minYear,
    maxYear,
    refetch: refetchQuestion,
    isLoadingQuestion,
  } = useGetCalibrationQuestionsCurrentQuery(
    {
      id: calibrationId || '',
    },
    {
      skip: !calibrationId,
      selectFromResult: ({
        data, isLoading
      }) => ({
        totalQuestions: data?.data.calibration.totalQuestions || 0,
        currentQuestionNumber: data?.data.calibration.order || 0,
        currentQuestion: data?.data.currentQuestion || null,
        minYear: data?.data.currentQuestion.rangeStart || 0,
        maxYear: data?.data.currentQuestion.rangeEnd || 0,
        isLoadingQuestion: isLoading,
      }),
    },
  );

  const [sendAnswers, {
    isLoading: isLoadingSendAnswers
  }] = usePostCalibrationAnswersMutation();

  useEffect(
    () => {
      if (currentQuestion) {
        setLowerPoint(currentQuestion.rangeStart);
        setUpperPoint(currentQuestion.rangeEnd);
        setIsFinished(false);

        const currentQuestionEndsAt = dayjs(currentQuestion.endAt).diff(
          now,
          'second',
        );

        const questionEndTime = currentQuestionEndsAt > TIME_TO_ANSWER
          ? TIME_TO_ANSWER
          : currentQuestionEndsAt - TIME_BE_GAP;

        if (questionEndTime > 0) {
          setCountDown(questionEndTime);
        }
      }
    },
    [currentQuestion]
  );

  const {
    t
  } = useTranslation();

  const testQuestion = currentQuestion?.title || '';

  const isMoreQuestions = currentQuestionNumber < totalQuestions;

  const ticksDelta = maxYear - minYear;
  const step = ticksDelta / 100;

  const ticks = getArrayOfDates(
    ticksDelta,
    minYear
  );

  const [isOpenModal, setIsOpenModal] = useState(false);

  const goToNextQuestion = () => {
    refetchQuestion();
  };

  const handleClickNextQuestion = async () => {
    if (!calibrationId || !currentQuestion) {
      return;
    }

    try {
      const resp = await sendAnswers({
        id: calibrationId,
        questionId: currentQuestion.id,
        answerCalibrationQuestionBodySchema: {
          rangeStart: lowerPoint,
          rangeEnd: upperPoint,
        },
      }).unwrap();

      successfulToast(resp.message);

      if (!isMoreQuestions) {
        navigate(
          `/${ROUTE.CALIBRATION_TOOL}/${ROUTE.CALIBRATION_RESULTS}/${calibrationId}`,
        );
      }

      setIsFinished(true);
    } catch (error) {
      showWarningFromServer(error);
      navigate(`/${ROUTE.CALIBRATION_TOOL}`);
    }
  };

  useEffect(
    () => {
      if (isFinished && isMoreQuestions) {
        goToNextQuestion();
      }
    },
    [isFinished]
  );

  useEffect(
    () => {
      const interval = setInterval(
        () => {
          setCountDown((prev) => prev - 1);
        },
        1000
      );

      if (countDown === 0 && !isFinished) {
        clearInterval(interval);
        handleClickNextQuestion();
      }

      return () => clearInterval(interval);
    },
    [countDown]
  );

  const handleRangeChanges = ([lower, upper]: [number, number]) => {
    if (lower < upper) {
      setLowerPoint(lower);
    }

    if (upper > lower) {
      setUpperPoint(upper);
    }
  };

  const handleFinishClick = () => {
    setIsOpenModal(true);
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  const handleLowerPointChange = (e: string) => {
    const newPoint = Number(e);

    if (newPoint < upperPoint && newPoint >= minYear) {
      setLowerPoint(newPoint);
    }
  };

  const handleUpperPointChange = (e: string) => {
    const newPoint = Number(e);

    if (newPoint > lowerPoint && newPoint <= maxYear) {
      setUpperPoint(newPoint);
    }
  };

  const isLoading = isLoadingQuestion || isLoadingSendAnswers;

  return (
    <PageLayout>
      <Style.Container>
        <Style.ProgressContainer>
          <Style.ProgressArea>
            <Style.ProgressResult
              $width={currentQuestionNumber / totalQuestions}
            >
              {`${currentQuestionNumber} / ${totalQuestions}`}
            </Style.ProgressResult>
          </Style.ProgressArea>

          <Style.TimerContainer>
            <ClockIcon className="text-dark-gray" />

            <Style.SecondsBlock>
              {`${countDown}${t('date.sec')[0].toLowerCase()}`}
            </Style.SecondsBlock>

            <CornerLineIcon className="text-button-blue absolute top-0 left-0" />
          </Style.TimerContainer>
        </Style.ProgressContainer>

        <Style.Title>{testQuestion}</Style.Title>

        <Style.InputContainer>
          <Style.InputArea>
            <Input
              label={t('calibration.lowerPoint')}
              value={lowerPoint}
              type={InputType.NUMBER}
              onChange={handleLowerPointChange}
              step={step}
            />
          </Style.InputArea>

          <Style.InputDividerContainer>
            <Style.InputDivider />
          </Style.InputDividerContainer>

          <Style.InputArea>
            <Input
              label={t('calibration.upperPoint')}
              value={upperPoint}
              type={InputType.NUMBER}
              onChange={handleUpperPointChange}
              step={step}
            />
          </Style.InputArea>
        </Style.InputContainer>

        <Style.RangeContainer>
          <Style.Relative>
            <Style.TicksContainer>
              {ticks.map((tick) => (
                <Style.Tick
                  $width={step}
                  key={tick}
                >
                  <DashedLineIcon />
                </Style.Tick>
              ))}
            </Style.TicksContainer>

            <RangeSlider
              id="range-slider"
              min={minYear}
              max={maxYear}
              step={step}
              defaultValue={[minYear, maxYear]}
              value={[lowerPoint, upperPoint]}
              onInput={handleRangeChanges}
            />
          </Style.Relative>

          <Style.ContainerX>
            <Style.ContainerXTicks>
              {ticks.map((tick) => (
                <Style.XTick key={tick}>
                  {tick % 10 === 0 ? tick : ''}
                </Style.XTick>
              ))}
            </Style.ContainerXTicks>
          </Style.ContainerX>
        </Style.RangeContainer>

        <Style.ButtonsContainer>
          <Style.FinishButton
            type="button"
            onClick={handleFinishClick}
          >
            {t('buttons.finish')}
          </Style.FinishButton>

          <Button
            type="button"
            variant="big-blue"
            className="w-max text-xl font-bold"
            onClick={handleClickNextQuestion}
            disabled={isLoading}
          >
            {isMoreQuestions ? t('buttons.next') : t('buttons.submit')}

            {isLoading && (
              <Spinner
                color="white"
                size={24}
                centered={false}
              />
            )}
          </Button>
        </Style.ButtonsContainer>
      </Style.Container>

      <ModalCancelCalibration
        isOpen={isOpenModal}
        closeModal={handleCloseModal}
      />
    </PageLayout>
  );
};
