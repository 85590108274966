import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  ModalContainer
} from 'src/shared/components/ModalContainer';
import {
  Button
} from 'src/shared/components/Button';
import {
  Spinner
} from 'src/shared/components/Spinner';

import * as Style from './ModalDeleteTheme.style';

interface ModalDeleteThemeProps {
  closeModal: () => void;
  isOpen: boolean;
  isLoading: boolean;
  handleDeleteClick: () => void;
}

export const ModalDeleteTheme: FC<ModalDeleteThemeProps> = ({
  closeModal,
  isOpen,
  isLoading,
  handleDeleteClick,
}) => {
  const {
    t
  } = useTranslation();

  return (
    <ModalContainer
      modalWidth={517}
      isDarkBackground
      isHeader={false}
      closeModalHandler={closeModal}
      isOpen={isOpen}
    >
      <Style.Title>{t('organisation.wantToDeleteThisTheme')}</Style.Title>

      <Style.Text>{t('organisation.deleteThemeHint')}</Style.Text>

      <Style.ButtonsContainer>
        <Button
          variant="big-black"
          onClick={closeModal}
          className="flex-1 font-bold text-xl"
        >
          {t('buttons.cancel')}
        </Button>

        <Button
          variant="big-red"
          onClick={handleDeleteClick}
          className="flex-1 font-bold text-xl"
        >
          {t('buttons.delete')}

          {isLoading && (
            <Spinner
              color="white"
              size={24}
            />
          )}
        </Button>
      </Style.ButtonsContainer>
    </ModalContainer>
  );
};
