import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const FormContainer = styled.form`
  ${tw`flex flex-col gap-4`}
`;

export const EmailField = styled.span`
  ${tw`
    bg-list-blue rounded-123px px-3 text-center outline-0
    text-17-26 text-dark-gray font-medium h-26px
  `}
`;

export const InputButton = styled.button`
  ${tw`relative flex w-full flex-wrap gap-3 min-h-50px px-15px py-11px bg-alice-blue rounded border border-gray94`}
`;

export const ErrorMessage = styled.p`
  ${tw`absolute bottom-0 left-0 translate-y-full text-xs text-red-text-error`}
`;

export const PlaceHolder = styled.p`
  ${tw`text-17-26 text-dim-gray font-medium`}
`;

export const MessageInputTitle = styled.p`
  ${tw`text-15-20 text-grey-dark-40 font-medium`}
`;

export const MessageInputLabel = styled.label`
  ${tw`flex flex-col gap-2`}
`;

export const MessageInput = styled.textarea`
  ${tw`
    rounded px-15px py-11px bg-alice-blue rounded border border-gray94 outline-0
    text-17-26 text-dark-gray font-medium
    placeholder:(text-dim-gray) resize-none h-50px
  `}
`;

export const SubmitButton = styled.button`
  ${tw`
    flex items-center gap-1 bg-button-blue self-end text-white font-bold py-11px px-23px
    border border-button-blue text-xl rounded-100px transition
    disabled:(bg-light-blue border-light-blue hover:text-white)
    hover:(bg-white text-button-blue)
  `}
`;
