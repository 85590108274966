import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const ContentContainer = styled.div`
  ${tw`px-6 pb-6 flex flex-col gap-10`}
`;

export const SectionContainer = styled.div`
  ${tw`flex flex-col gap-6`}
`;

export const SectionTitle = styled.div`
  ${tw`text-xl text-black font-bold`}
`;

export const SectionContent = styled.div`
  ${tw`flex flex-col gap-4`}
`;

export const AvatarRow = styled.div`
  ${tw`flex gap-3 items-center`}
`;

export const AvatarSettings = styled.div`
  ${tw`flex flex-col gap-2 text-15-20`}
`;

export const ActionButton = styled.button`
  ${tw`
    text-center w-max rounded-100px py-9px px-15px
    border border-gray90 font-bold
  `}
`;

export const AvatarDescription = styled.p`
  ${tw`font-medium text-dim-gray`}
`;

export const InputLabel = styled.label`
  ${tw`flex flex-col gap-2 text-15-20 font-medium text-dim-gray flex-1`}
`;

export const Input = styled.input`
  ${tw`
    outline-0 border border-gray94 rounded py-2.5 px-15px bg-alice-blue
    text-17-26 font-medium text-gray7 placeholder:text-dim-gray
  `}
`;

export const FlexRowContainer = styled.div<{ $alignBase?: boolean }>`
  ${tw`flex justify-between gap-4`}

  ${({
    $alignBase
  }) => ($alignBase ? tw`items-baseline` : tw`items-center`)}
`;

export const ErrorMessage = styled.div`
  ${tw`text-13-21 font-medium text-red-text-error -mt-1`}
`;

export const InputHint = styled.div`
  ${tw`text-13-21 font-medium text-dim-gray -mt-1`}
`;

export const MenuFooter = styled.div`
  ${tw`border-t border-gray94 flex justify-end gap-3 py-4`}
`;
