import React, {
  FC
} from 'react';

import {
  ArqLoginImage
} from 'src/shared/icons';

import * as Style from './LoginLeftSide.styles';

export const LoginLeftSide: FC = () => {
  return (
    <Style.MainContainer>
      {/* TODO: Add Logo component when it will be needed */}
      {/* <Style.LogoContainer>
        <Logo />
      </Style.LogoContainer> */}

      <Style.ChartImageContainer>
        <Style.ImageContainer
          src={ArqLoginImage}
          alt="football"
        />
      </Style.ChartImageContainer>
    </Style.MainContainer>
  );
};
