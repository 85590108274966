import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const Label = styled.label<{
  $isSelected: boolean;
  $isVoted: boolean;
}>`
  ${tw`p-4 rounded border shadow-button-shadow text-17-26 font-medium cursor-pointer relative`}

  ${({
    $isSelected
  }) => ($isSelected
    ? tw`border-button-blue text-gray7`
    : tw`border-gray94 text-dim-gray`)}

  ${({
    $isVoted
  }) => $isVoted && tw`cursor-default`}
`;

export const ContentWrapper = styled.div`
  ${tw`relative z-2 flex items-center gap-2`}
`;

export const Title = styled.p`
  ${tw`grow`}
`;

export const VotesAmount = styled.p`
  ${tw`text-dim-gray hidden md:block shrink-0`}
`;

export const Result = styled.p`
  ${tw`font-bold text-gray7`}
`;

export const SurveyBGResult = styled.div<{
  $isSelected: boolean;
  $width: number;
}>`
  ${tw`absolute top-0 bottom-0 left-0 rounded`}

  ${({
    $isSelected
  }) => ($isSelected ? tw`bg-list-blue` : tw`bg-alice-blue`)}

  width: ${({
    $width
  }) => `${$width}%`};
`;

export const RadioInput = styled.input`
  ${tw`hidden`}
`;

export const IconContainer = styled.div`
  ${tw`shrink-0`}
`;
