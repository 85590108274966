import React, {
  FC, useEffect, useState
} from 'react';
import Cropper, {
  Area
} from 'react-easy-crop';
import {
  useTranslation
} from 'react-i18next';

import {
  Button,
  ModalContainer,
  Slider,
  Spinner,
  successfulToast,
} from 'src/shared/components';
import {
  ZoomInIcon, ZoomOutIcon
} from 'src/shared/icons';
import {
  usePostApiUsersCurrentAvatarMutation
} from 'src/shared/api/users/usersApi';
import {
  showWarningFromServer
} from 'src/shared/utils';

import * as Style from './EditAvatarModal.styles';
import {
  getCroppedImg
} from './utils';

const MAX_ZOOM = 3;
const ZOOM_STEP = 0.1;
const CROPPER_HEIGHT = 496;
const CROPPER_WIDTH = 750;

const DEFAULT_CROP = {
  x: 0,
  y: 0,
};

const CROP_SIZE = 336;

interface EditAvatarModalProps {
  isOpen: boolean;
  onClose: () => void;
  fileSrc: string | null;
}

export const EditAvatarModal: FC<EditAvatarModalProps> = ({
  isOpen,
  onClose,
  fileSrc,
}) => {
  const [crop, setCrop] = useState(DEFAULT_CROP);
  const [zoom, setZoom] = useState(1);
  const [minZoom, setMinZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);

  const {
    t
  } = useTranslation();

  useEffect(
    () => {
      setZoom(1);
      setCroppedAreaPixels(null);
      setCrop(DEFAULT_CROP);
    },
    [isOpen]
  );

  const onLoad = (width: number, height: number) => {
    setMinZoom(CROP_SIZE / (width <= height ? CROPPER_WIDTH : CROPPER_HEIGHT));
  };

  const onCropComplete = (croppedArea: Area, croppedPixels: Area) => {
    setCroppedAreaPixels(croppedPixels);
  };

  const [uploadImage, {
    isLoading
  }] = usePostApiUsersCurrentAvatarMutation();

  const onSubmit = async () => {
    if (fileSrc && croppedAreaPixels) {
      const croppedImg = (await getCroppedImg(
        fileSrc,
        croppedAreaPixels,
      )) as string;

      const blobResponse = await fetch(croppedImg);
      const blob = await blobResponse.blob();

      const formData = new FormData();

      formData.append(
        'avatar',
        blob,
        'avatar.jpeg'
      );

      try {
        const response = await uploadImage(formData).unwrap();

        successfulToast(response.message);
      } catch (error) {
        showWarningFromServer(error);
      }

      onClose();
    }
  };

  const handleZoomOut = () => {
    setZoom((prevZoom) => Math.max(
      minZoom,
      prevZoom - ZOOM_STEP
    ));
  };

  const handleZoomIn = () => {
    setZoom((prevZoom) => Math.min(
      3,
      prevZoom + ZOOM_STEP
    ));
  };

  return (
    <ModalContainer
      isOpen={isOpen}
      closeModalHandler={onClose}
      modalTitle={t('editAccount.uploadAvatar')}
      testId="uploadAvatarModal"
      modalWidth={792}
      shouldCloseOnOverlayClick={false}
      isDarkBackground
    >
      <Style.MainContainer>
        <Style.CropperContainer>
          {fileSrc && (
            <Cropper
              image={fileSrc}
              crop={crop}
              zoom={zoom}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              cropShape="round"
              onMediaLoaded={({
                naturalHeight, naturalWidth
              }) => onLoad(
                naturalWidth,
                naturalHeight
              )}
              cropSize={{
                width: CROP_SIZE,
                height: CROP_SIZE,
              }}
              style={{
                cropAreaStyle: {
                  border: '2px solid #fff',
                },
                containerStyle: {
                  backgroundColor: '#D9D9D9',
                },
              }}
              showGrid={false}
              objectFit="cover"
            />
          )}
        </Style.CropperContainer>

        <Style.FooterContainer>
          <Style.ZoomContainer>
            <button
              type="button"
              onClick={handleZoomOut}
            >
              <ZoomOutIcon className="text-black" />
            </button>

            <Style.SliderContainer>
              <Slider
                value={zoom}
                min={minZoom}
                max={MAX_ZOOM}
                step={ZOOM_STEP}
                onChange={setZoom}
                variant="dot"
              />
            </Style.SliderContainer>

            <button
              type="button"
              onClick={handleZoomIn}
            >
              <ZoomInIcon className="text-black" />
            </button>
          </Style.ZoomContainer>

          <Style.ButtonsContainer>
            <Button
              variant="big-black"
              className="w-max font-bold text-15 !h-10 !px-4 !py-2.5"
              onClick={onClose}
            >
              {t('buttons.cancel')}
            </Button>

            <Button
              variant="big-blue"
              className="font-bold text-15 !w-67px !h-10 !px-4 !py-2.5"
              onClick={onSubmit}
            >
              {isLoading ? (
                <Spinner
                  size={24}
                  color="white"
                />
              ) : (
                t('buttons.save')
              )}
            </Button>
          </Style.ButtonsContainer>
        </Style.FooterContainer>
      </Style.MainContainer>
    </ModalContainer>
  );
};
