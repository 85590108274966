import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';

import {
  PopoverMenu
} from 'src/shared/components';
import {
  CheckBoxFalseIcon,
  CheckBoxTrueIcon,
  RadialСheckFalseIcon,
  RadialСheckTrueIcon,
} from 'src/shared/icons';

import * as Styles from './Selects.styles';
import {
  SelectButton
} from './SelectButton';

interface ItemSelected {
  title: string;
  isSelected: boolean;
}

interface SelectFilterProps {
  buttonTitle: string;
  selectedItems: ItemSelected[];
  onApply: (prevSelected: ItemSelected[]) => void;
  isTimePeriod?: boolean;
}

export const SelectFilter: FC<SelectFilterProps> = ({
  buttonTitle,
  selectedItems,
  onApply,
  isTimePeriod,
}) => {
  const onClear = () => {
    onApply([]);
  };

  const {
    t
  } = useTranslation();

  const handleSelectOption = (val: string) => {
    if (isTimePeriod) {
      onApply(
        selectedItems.map((item) => {
          if (item.title === val) {
            return {
              ...item,
              isSelected: true,
            };
          }

          return {
            ...item,
            isSelected: false,
          };
        }),
      );
    } else {
      onApply(
        selectedItems.map((item) => {
          if (item.title === val) {
            return {
              ...item,
              isSelected: !item.isSelected,
            };
          }

          return item;
        }),
      );
    }
  };

  return (
    <PopoverMenu
      placement="bottom-start"
      renderCustomButton={({
        isOpen, toggleMenu, triggerProps
      }) => (
        <SelectButton
          isOpen={isOpen}
          onButtonClick={toggleMenu}
          title={buttonTitle}
          isSelectedValue={!!selectedItems.length}
          onClear={onClear}
          {...triggerProps}
        />
      )}
    >
      <Styles.MenuContainer>
        <Styles.MenuTitle>
          {isTimePeriod
            ? t('select.chooseForecastRange')
            : t('select.forecastsToDisplay')}
        </Styles.MenuTitle>

        {selectedItems.map((item) => (
          <Styles.MenuOption
            key={item.title}
            type="button"
            onClick={() => handleSelectOption(item.title)}
          >
            {item.title}

            {!isTimePeriod && (
              <div>
                {item.isSelected ? <CheckBoxTrueIcon /> : <CheckBoxFalseIcon />}
              </div>
            )}

            {isTimePeriod && (
              <div>
                {item.isSelected ? (
                  <RadialСheckTrueIcon />
                ) : (
                  <RadialСheckFalseIcon />
                )}
              </div>
            )}
          </Styles.MenuOption>
        ))}
      </Styles.MenuContainer>
    </PopoverMenu>
  );
};
