export enum InputMessages {
  ENTER_MAIL_USERNAME = 'Enter your Email or Username',
  ENTER_USERNAME = 'Enter your Username',
  ENTER_FULL_NAME = 'Enter your Full name',
  ENTER_MAIL = 'Enter your Email',
  ENTER_VALID_EMAIL = 'Enter a valid Email',
  ENTER_PASSWORD = 'Enter your Password',
  ENTER_CORRECT_PASSWORD_LENGTH = 'Password must be at least 8 characters',
  BOTTOM_MAIL_MSG = 'Your email data is completely anonymous',
  BOTTOM_USERNAME_MSG = 'Your unique username will be displayed to others',
  MATCH_PASSWORDS = 'Passwords must match',
  INVALID_EMAIL = 'Invalid email address',
  INVALID_EMAILS = 'Invalid email address(es)',
  UNIQUE_EMAILS = 'Email addresses should be unique',
  AGREE_TO_TERMS = 'Please confirm Terms & Regulations and Privacy Policy to proceed',
  INVALID_USERNAME = 'Username must have no more than 2 words',
  INVALID_FULL_NAME = 'Full name must have at least 2 words',
  ORG_NAME = 'Organization name is required',
  ORG_DESCRIPTION = 'Description is required',
  GROUP_NAME = 'Create the group name before continue',
  GROUP_LOCATION = 'Choose group location before continue',
  COMMENT_EMPTY = 'Comment cannot be empty',
  VALID_DOMAIN = 'Enter a valid domain',
}

export enum InputNames {
  EMAIL = 'email',
  USERNAME = 'username',
  FULL_NAME = 'fullName',
  PASSWORD = 'password',
  CONFIRM_PASS = 'confirmPass',
  AGREEMENT = 'agreement',
}

export enum MenuField {
  DisplayName = 'displayName',
  ShortDescription = 'shortDescription',
  MainEmail = 'mainEmail',
  Username = 'username',
  FullName = 'fullName',
}

export enum FormField {
  CurrentPassword = 'currentPassword',
  NewPassword = 'newPassword',
  RepeatPassword = 'repeatPassword',
}
