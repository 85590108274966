import React, {
  FC, useCallback, useMemo, useState
} from 'react';
import clsx from 'clsx';
import {
  useLocation, useParams, useSearchParams
} from 'react-router-dom';

import {
  MoreIcon,
  PersonAddIcon,
  PersonDoneIcon,
  PersonRemoveIcon,
} from 'src/shared/icons';
import {
  ReportUserModal,
  Spinner,
  successfulToast,
} from 'src/shared/components';
import {
  CommentOptionsContext
} from 'src/widgets';
import {
  questionId,
  groupId,
  showWarningFromServer,
  ROUTE,
  themeColors,
  followingId,
} from 'src/shared/utils';
import {
  useDeleteCommentMutation,
  CommentAuthor,
  usePostUserFollowingsMutation,
  useDeleteUserFollowingsMutation,
} from 'src/redux/openapi';
import {
  useTypedDispatch
} from 'src/redux';
import {
  removeFromCommentsList
} from 'src/redux/commentsList';

import * as Style from './CommentListItem.styles';
import './index.css';

interface CommentItemHeaderProps {
  author: CommentAuthor;
  userId: string;
  commentId: string;
  onCommentDelete: () => void;
  isAdmin: boolean;
}

export const CommentItemHeader: FC<CommentItemHeaderProps> = ({
  author: {
    id: authorId, isFollowed, displayName, name
  },
  userId,
  commentId: id,
  onCommentDelete,
  isAdmin,
}) => {
  const [isCommentSettingsOpen, setIsCommentSettingsOpen] = useState(false);
  const [isReportUserOpen, setIsReportUserOpen] = useState(false);

  const [isFavorite, setIsFavorite] = useState(isFollowed);
  const [isFavoriteOnHover, setIsFavoriteOnHover] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const isFollowersFeed = searchParams.get(followingId);

  const [updateFollowUser, {
    isLoading: isUpdateFollowLoading
  }] = usePostUserFollowingsMutation();

  const [deleteUserFollowings, {
    isLoading: isDeleteUserFollowingsLoading
  }] = useDeleteUserFollowingsMutation();

  const handleChangeFollowUser = useCallback(
    async () => {
      const apiCallback = isFavorite ? deleteUserFollowings : updateFollowUser;

      try {
        const resp = await apiCallback({
          followeeId: authorId,
        }).unwrap();

        successfulToast(resp.message);

        if (isFavorite && isFollowersFeed) {
          searchParams.delete(followingId);
          setSearchParams(searchParams);
        }

        setIsFavorite(!isFavorite);
      } catch (error) {
        showWarningFromServer(error);
      }
    },
    [authorId, isFavorite]
  );

  const params = useParams();

  const {
    pathname
  } = useLocation();

  const isCommunityPage = pathname.startsWith(`/${ROUTE.COMMUNITY}`);

  const closeCommentSettingsHandler = () => {
    setIsCommentSettingsOpen(false);
  };

  const handleOpenReportUser = () => {
    closeCommentSettingsHandler();
    setIsReportUserOpen(true);
  };

  // TODO: delete dispatch after all comments with sockets
  const dispatch = useTypedDispatch();

  const activeQuestionId = searchParams.get(questionId) || null;
  const groupIdSearch = searchParams.get(groupId);
  const questionIdToSearch = params.id || activeQuestionId;

  const isQuestionQuery = questionIdToSearch && !groupIdSearch;

  const [deleteApiCommentsById, {
    isLoading: isDeleteLoading
  }] = useDeleteCommentMutation();

  const handleDeleteComment = async () => {
    try {
      const response = await deleteApiCommentsById({
        id,
      }).unwrap();

      successfulToast(response.message);

      if (!isQuestionQuery) {
        dispatch(
          removeFromCommentsList({
            commentId: response.data.comment.rootComment?.id || id,
            deletedId: id,
          }),
        );
      } else {
        onCommentDelete();
      }
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  const favoriteUserIcon = useMemo(
    () => {
      if (!isFavorite) {
        return (
          <PersonAddIcon
            className={clsx(
              'text-dark-gray',
              !isCommunityPage && 'w-18px h-18px',
            )}
          />
        );
      }

      if (isFavoriteOnHover) {
        return (
          <PersonRemoveIcon
            className={clsx(
              'text-red-text-error',
              !isCommunityPage && 'w-18px h-18px',
            )}
          />
        );
      }

      return (
        <PersonDoneIcon
          className={clsx(
            'text-button-blue',
            !isCommunityPage && 'w-18px h-18px',
          )}
        />
      );
    },
    [isFavorite, isFavoriteOnHover]
  );

  const isUserFollowingsLoading = isDeleteUserFollowingsLoading || isUpdateFollowLoading;

  return (
    <>
      <Style.Header>
        <Style.Name>{displayName || name}</Style.Name>

        <Style.ActionButtons $isCommunity={isCommunityPage}>
          <Style.MoreButton>
            <button
              type="button"
              onClick={() => setIsCommentSettingsOpen((prev) => !prev)}
            >
              <MoreIcon
                className={clsx({
                  'text-dark-gray': !isCommentSettingsOpen,
                  'text-button-blue': isCommentSettingsOpen,
                })}
              />
            </button>

            {isCommentSettingsOpen && (
              <CommentOptionsContext
                handleCloseModal={closeCommentSettingsHandler}
                handleOpenReportUser={handleOpenReportUser}
                isMyComment={authorId === userId}
                deleteComment={handleDeleteComment}
                isLoading={isDeleteLoading}
                isAdmin={isAdmin}
              />
            )}
          </Style.MoreButton>

          {authorId !== userId && (
            <button
              type="button"
              onClick={handleChangeFollowUser}
              onMouseOver={() => setIsFavoriteOnHover(true)}
              onMouseLeave={() => setIsFavoriteOnHover(false)}
              onFocus={() => setIsFavoriteOnHover(true)}
              onBlur={() => setIsFavoriteOnHover(false)}
              disabled={isUserFollowingsLoading}
            >
              {isUserFollowingsLoading ? (
                <Spinner
                  size={20}
                  color={themeColors['dark-gray']}
                />
              ) : (
                favoriteUserIcon
              )}
            </button>
          )}
        </Style.ActionButtons>
      </Style.Header>

      <ReportUserModal
        isOpen={isReportUserOpen}
        memberName={displayName || name}
        onClose={() => setIsReportUserOpen(false)}
        userId={authorId}
      />
    </>
  );
};
