import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const ModalThemeTitleRow = styled.div`
  ${tw`px-6 mb-6 flex justify-between items-center`}
`;

export const ModalThemeTitle = styled.h3`
  ${tw`text-22-30 font-bold text-gray7`}
`;
