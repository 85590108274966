import clsx from 'clsx';
import React, {
  FC
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import {
  useNavigate
} from 'react-router-dom';

import {
  Tooltip
} from 'src/shared/components';
import {
  useMediaQuery
} from 'src/shared/hooks';
import {
  DelegateIcon
} from 'src/shared/icons';
import {
  ROUTE, manageDelegate
} from 'src/shared/utils';

import * as Style from './DelegateButton.styles';

interface DelegateButtonProps {
  hasDelegate: boolean;
  questionId: string;
}

export const DelegateButton: FC<DelegateButtonProps> = ({
  hasDelegate,
  questionId,
}) => {
  const isMobile = useMediaQuery(
    'md',
    'down'
  );

  const {
    t
  } = useTranslation();

  const navigate = useNavigate();

  if (isMobile) {
    return null;
  }

  const onClick = () => {
    navigate(
      `/${ROUTE.QUESTION}/${questionId}`,
      {
        state: {
          [manageDelegate]: true,
        },
      }
    );
  };

  return (
    <Style.ButtonContainer
      type="button"
      onClick={onClick}
    >
      <Tooltip
        message={
          hasDelegate
            ? t('question.alreadyHaveDelegate')
            : t('question.youCanAssignDelegateToQuestion')
        }
        maxWidth={hasDelegate ? 180 : 205}
      >
        <DelegateIcon
          className={clsx({
            'text-button-blue': hasDelegate,
            'text-dim-gray': !hasDelegate,
          })}
        />
      </Tooltip>
    </Style.ButtonContainer>
  );
};
