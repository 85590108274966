import React, {
  FC
} from 'react';
import Modal from 'react-modal';

import {
  CloseIcon
} from 'src/shared/icons';
import {
  themeColors
} from 'src/shared/utils';
import {
  CircleButton
} from 'src/shared/components';
import {
  useMediaQuery
} from 'src/shared/hooks';

import * as Style from './ModalContainer.styles';

interface ModalContainerProps extends Modal.Props {
  modalTitle?: string;
  closeModalHandler: () => void;
  isDarkBackground?: boolean;
  modalWidth?: number;
  isHeader?: boolean;
  isGap?: boolean;
  styles?: Modal.Styles;
  shouldCloseOnOverlayClick?: boolean;
  zIndex?: number;
  fullHeight?: boolean;
}

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.15)',
    zIndex: 1000,
  },
  content: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '634px',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    height: 'max-content',
    maxHeight: '90%',
  },
};

export const ModalContainer: FC<ModalContainerProps> = ({
  children,
  modalTitle,
  closeModalHandler,
  isDarkBackground = false,
  modalWidth,
  isHeader = true,
  isGap = true,
  styles,
  isOpen,
  zIndex,
  fullHeight,
  ...props
}) => {
  const isMobile = useMediaQuery(
    'md',
    'down'
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModalHandler}
      appElement={document.body}
      style={{
        overlay: {
          ...customStyles.overlay,
          backgroundColor: isDarkBackground
            ? 'rgba(0, 0, 0, 0.40)'
            : customStyles.overlay.backgroundColor,
          zIndex: zIndex || customStyles.overlay.zIndex,
        },
        content: {
          ...customStyles.content,
          width: modalWidth ? `${modalWidth}px` : customStyles.content.width,
          height: fullHeight ? '100%' : customStyles.content.height,
          maxHeight: isMobile ? '100%' : customStyles.content.maxHeight,
        },
        ...styles,
      }}
      {...props}
    >
      <Style.ContentContainer $isGap={isGap}>
        {isHeader && (
          <Style.Header>
            <Style.GroupDetailTitle>{modalTitle}</Style.GroupDetailTitle>

            <div>
              <CircleButton
                onClick={closeModalHandler}
                borderColor={themeColors['light-grey']}
              >
                <CloseIcon className="text-dark-gray w-6 h-6" />
              </CircleButton>
            </div>
          </Style.Header>
        )}

        <Style.ChildrenContainer>{children}</Style.ChildrenContainer>
      </Style.ContentContainer>
    </Modal>
  );
};
