import React, {
  useState,
  FC,
  useEffect,
  useCallback,
  ChangeEvent,
} from 'react';
import {
  Trans, useTranslation
} from 'react-i18next';

import {
  Button,
  SliderBlock,
  Spinner,
  successfulToast,
  Switcher,
  Tooltip,
  Checkbox,
} from 'src/shared/components';
import {
  OrgVerifications,
  useGetOrgVerificationsMutation,
  usePostOrgVerificationsManualMutation,
  usePutOrgVerificationsAutomaticMutation,
  OrgVerificationSettings,
} from 'src/redux/openapi';
import {
  getWeekInMsValue,
  getWeekFromMsValue,
  showWarningFromServer,
  getRoundedDifferenceToNow,
  isTodaySameOrBeforeDate,
} from 'src/shared/utils';

import * as Style from './ReVerification.styles';
import {
  HistoryList
} from './ui';

const MIN_VERIFICATION_PERIOD = 4;
const MAX_VERIFICATION_PERIOD = 104;

interface ReVerificationProps {
  id: string;
}

export const ReVerification: FC<ReVerificationProps> = ({
  id
}) => {
  const [isAutoEnabled, setIsAutoEnabled] = useState(false);
  const [periodInWeeks, setPeriodInWeeks] = useState(MIN_VERIFICATION_PERIOD);
  const [isReverifyChecked, setIsReverifyChecked] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [verificationsData, setVerificationsData] = useState<OrgVerifications>(
    [],
  );

  const [totalVerifications, setTotalVerifications] = useState(0);
  const [settings, setSettings] = useState<OrgVerificationSettings>(null);
  const [isLoading, setIsLoading] = useState(false);

  const toggleAutoVerification = () => setIsAutoEnabled((prev) => !prev);

  const toggleReverifyCheck = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();

    setIsReverifyChecked((prev) => !prev);
  };

  const [getOrgVerifications] = useGetOrgVerificationsMutation();

  const getVerifications = async (pageNumber = currentPage) => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    const isFirstPage = pageNumber === 1;

    try {
      const response = await getOrgVerifications({
        id,
        include: isFirstPage ? ['settings'] : undefined,
        page: pageNumber,
        limit: 20,
      }).unwrap();

      const {
        verifications = [],
        verificationSettings,
        total = 0,
      } = response?.data || {};

      if (isFirstPage) {
        setTotalVerifications(total);
        setSettings(verificationSettings);
        setVerificationsData(verifications);
      } else {
        setVerificationsData((prev) => [...prev, ...verifications]);
      }
    } catch (error) {
      showWarningFromServer(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(
    () => {
      getVerifications();
    },
    []
  );

  const onLoadMore = async () => {
    if (!verificationsData.length || isLoading) {
      return;
    }

    await getVerifications(currentPage + 1);
    setCurrentPage((prev) => prev + 1);
  };

  const {
    automaticVerificationFrequency, nextManualVerificationAt
  } = settings || {};

  const canVerifyManually = nextManualVerificationAt
    && isTodaySameOrBeforeDate(nextManualVerificationAt);

  const nextVerificationDate = canVerifyManually
    ? getRoundedDifferenceToNow(nextManualVerificationAt)
    : null;

  const [sendVerification, {
    isLoading: isVerificationSending
  }] = usePostOrgVerificationsManualMutation();

  const onSendVerification = async () => {
    try {
      const response = await sendVerification({
        id,
        createManualVerificationBodySchema: {
          reverifyInactiveOnly: isReverifyChecked,
        },
      }).unwrap();

      successfulToast(response.message);

      getVerifications();
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  const resetValues = useCallback(
    () => {
      setIsAutoEnabled(!!automaticVerificationFrequency);

      if (automaticVerificationFrequency) {
        const weeks = getWeekFromMsValue(automaticVerificationFrequency);
        setPeriodInWeeks(weeks);
      }
    },
    [settings]
  );

  useEffect(
    () => {
      resetValues();
    },
    [resetValues]
  );

  const {
    t
  } = useTranslation();

  const [updateAutomaticVerification, {
    isLoading: isAutoUpdating
  }] = usePutOrgVerificationsAutomaticMutation();

  const onSave = async () => {
    try {
      const response = await updateAutomaticVerification({
        id,
        setOrganizationAutomaticVerificationBodySchema: {
          automaticVerificationFrequencyMs: isAutoEnabled
            ? getWeekInMsValue(periodInWeeks)
            : null,
          automaticVerificationEnabled: isAutoEnabled,
        },
      }).unwrap();

      successfulToast(response.message);

      getVerifications();
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <Style.MainContainer>
        <Style.StretchedRow>
          <Style.Title>{t('verification.reVerification')}</Style.Title>

          <Style.SwitcherContainer>
            {t('verification.setAutoVerification')}

            <Switcher
              isActive={isAutoEnabled}
              onChange={toggleAutoVerification}
            />
          </Style.SwitcherContainer>
        </Style.StretchedRow>

        {isAutoEnabled && (
          <SliderBlock
            variant="big"
            text={t('common.automatically')}
            value={periodInWeeks}
            setValue={setPeriodInWeeks}
            valueDescription={t('date.weeks')}
            minValue={MIN_VERIFICATION_PERIOD}
            maxValue={MAX_VERIFICATION_PERIOD}
          />
        )}

        <Style.StretchedRow>
          <Checkbox
            type="checkMark"
            isChecked={isReverifyChecked}
            label={t('verification.reverifyWhoHasBeenAway')}
            onChange={toggleReverifyCheck}
            className="gap-2"
          />

          <Tooltip
            message={(
              <Style.TooltipMessageWrapper>
                <Trans
                  i18nKey="verification.youCanManuallyVerifyUsers"
                  components={{
                    1: <br />,
                  }}
                />
              </Style.TooltipMessageWrapper>
            )}
            disabled={!nextVerificationDate}
          >
            <Style.SendButton
              disabled={!!nextVerificationDate}
              onClick={onSendVerification}
            >
              {nextVerificationDate
                ? t(
                  'verification.availableIn',
                  {
                    daysCount: nextVerificationDate,
                  }
                )
                : t('verification.sendVerification')}

              {isVerificationSending && (
                <Spinner
                  size={20}
                  color="white"
                />
              )}
            </Style.SendButton>
          </Tooltip>
        </Style.StretchedRow>

        {!!verificationsData?.length && (
          <>
            <Style.Title>{t('verification.verificationHistory')}</Style.Title>

            <HistoryList
              data={verificationsData}
              hasMore={verificationsData.length < totalVerifications}
              onLoadMore={onLoadMore}
            />
          </>
        )}
      </Style.MainContainer>

      <Style.ButtonsContainer>
        <Button
          variant="big-black"
          className="font-bold text-xl !w-max"
          onClick={resetValues}
        >
          {t('buttons.cancel')}
        </Button>

        <Button
          variant="big-blue"
          className="font-bold text-xl !w-max shrink-0 whitespace-nowrap"
          onClick={onSave}
        >
          {t('buttons.saveChanges')}

          {isAutoUpdating && (
            <Spinner
              size={24}
              color="white"
            />
          )}
        </Button>
      </Style.ButtonsContainer>
    </>
  );
};
