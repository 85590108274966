import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const HeaderContainer = styled.div<{ $isTable: boolean }>`
  ${tw`
    flex flex-col md:flex-row md:justify-between gap-y-1 md:gap-x-4
    text-dim-gray font-bold
    flex-wrap overflow-hidden
  `};

  ${({
    $isTable
  }) => ($isTable ? tw`md:text-xs text-13-21` : tw`text-13-21`)}
`;

export const Text = styled.p`
  ${tw`shrink-0 font-bold flex gap-2 items-center truncate`}
`;

export const Date = styled.p`
  ${tw`font-medium whitespace-normal shrink-0`}
`;
