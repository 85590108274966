import React, {
  FC, useState
} from 'react';
import {
  useTranslation
} from 'react-i18next';
import {
  useSearchParams
} from 'react-router-dom';

import {
  Button, Spinner
} from 'src/shared/components';
import {
  ArrowRightIcon, PlayCircleIcon
} from 'src/shared/icons';
import {
  PageLayout
} from 'src/widgets';
import {
  useGetCalibrationsQuery
} from 'src/redux/openapi';

import {
  CalibrationFooterData,
  EmptyResultInfo,
  ModalSetConfidenceRate,
  ResultItem,
} from './ui';
import * as Style from './CalibrationTool.style';

export const CalibrationTool: FC = () => {
  const {
    t
  } = useTranslation();

  const {
    resultCalibrations, isLoadingCalibrations
  } = useGetCalibrationsQuery(
    {
      page: 1,
      limit: 500,
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({
        data, isLoading
      }) => ({
        resultCalibrations: data?.data.calibrations || [],
        isLoadingCalibrations: isLoading,
      }),
    },
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const [isStartCalibration, setIsStartCalibration] = useState(false);

  const toggleModal = () => {
    setIsStartCalibration((prev) => !prev);
  };

  const handleTestRun = () => {
    searchParams.set(
      'test',
      'true'
    );

    setSearchParams(searchParams);

    toggleModal();
  };

  const [isIFrameLoading, setIsIFrameLoading] = useState(true);

  return (
    <PageLayout>
      <Style.HeadContainer>
        <Style.HeadTitle>{t('calibration.calibrationTool')}</Style.HeadTitle>

        <Style.HeadButtonsContainer>
          <Button
            variant="big-blue-outlined"
            className="w-max font-bold text-15-20"
            onClick={handleTestRun}
          >
            {t('calibration.testRun')}

            <ArrowRightIcon className="w-6 h-6 text-button-blue" />
          </Button>

          <Button
            variant="big-blue"
            className="w-max font-bold text-15-20"
            onClick={toggleModal}
          >
            {t('calibration.startCalibration')}

            <PlayCircleIcon />
          </Button>
        </Style.HeadButtonsContainer>
      </Style.HeadContainer>

      <Style.ContentContainer>
        <Style.VideoContainer>
          <Style.VideoContent>
            <Style.VideoWindow
              src="https://www.loom.com/embed/e3ac6d2c159d4f72914b5b9073a9762b?sid=b985d063-6f5c-4f16-b7e1-843fd11373e9"
              frameBorder="0"
              allowFullScreen
              title="Calibration Tool Video"
              onLoad={() => setIsIFrameLoading(false)}
            />

            {isIFrameLoading && (
              <Style.SpinnerContainer>
                <Spinner />
              </Style.SpinnerContainer>
            )}
          </Style.VideoContent>
        </Style.VideoContainer>

        <Style.SideInfoContainer>
          <Style.Subtitle>{t('calibration.resultsList')}</Style.Subtitle>

          <Style.ListContainer>
            {!resultCalibrations.length && <EmptyResultInfo />}

            {isLoadingCalibrations && <Spinner />}

            {!!resultCalibrations.length
              && !isLoadingCalibrations
              && resultCalibrations.map((result, index) => (
                <ResultItem
                  result={result}
                  index={index}
                  key={result.id}
                />
              ))}
          </Style.ListContainer>
        </Style.SideInfoContainer>
      </Style.ContentContainer>

      <CalibrationFooterData />

      <ModalSetConfidenceRate
        isOpen={isStartCalibration}
        closeModal={toggleModal}
      />
    </PageLayout>
  );
};
