import {
  AdminOrganizationRequests,
  AdminUnaffiliatedGroup,
} from 'src/redux/openapi';

export enum EditMenuActions {
  EditOrganisation = 'editOrg',
  EditGroup = 'editGroup',
  Merge = 'merge',
  DeleteOrg = 'deleteOrg',
  DeleteGroup = 'deleteGroup',
  DeleteRequest = 'deleteRequest',
  Request = 'request',
}

export type ActionHandler = (id: string, action: EditMenuActions) => void;

export type ManagementEntity = 'organization' | 'group' | 'groupRequest';

export type SelectOption = {
  value: number;
  label: number;
};

export interface EntityModel {
  id: string;
  isRead?: boolean;
  status?:
  | AdminOrganizationRequests['status']
  | AdminUnaffiliatedGroup['status'];
}

export enum Tabs {
  AllOrganizations,
  GroupsRequests,
  UnaffiliatedGroups,
}
