import React, {
  FC
} from 'react';

import {
  AlertTriangle
} from 'src/shared/icons';

export const BlockedAccountMsg: FC = () => {
  return (
    <div className="flex flex-row px-6 py-3 gap-3 items-center border border-orange-alert rounded">
      <img
        src={AlertTriangle}
        alt="alert"
        className="w-6 h-6"
      />

      <div className="text-15-20 font-medium">
        Your account is blocked by the platform administrator, you can send a
        request for unblocking via email.
      </div>
    </div>
  );
};
