import tw from 'twin.macro';
import {
  styled
} from 'styled-components';
import {
  Link
} from 'react-router-dom';

export const SubTabLink = styled(Link)`
  ${tw`flex flex-col gap-1 p-2 rounded hover:bg-cultured`}
`;

export const SubTabText = styled.div<{ $isActive: boolean }>`
  ${tw`text-base font-medium`}

  ${({
    $isActive
  }) => ($isActive ? tw`text-button-blue` : tw`text-dark-gray`)}
`;

export const SubTabInfo = styled.div`
  ${tw`text-xs leading-5 text-dim-gray`}
`;

export const SubTabs = styled.ul`
  ${tw`hidden flex-col group-hover:flex hover:flex absolute left-0 top-10 gap-1 rounded-md shadow-menu-shadow p-2 bg-white w-243px z-9999`}
`;

export const TooltipInfo = styled.div`
  ${tw`w-155px p-2 text-xs leading-5 font-medium`}
`;

export const Span = styled.span`
  ${tw`flex gap-1 items-center cursor-default`}
`;
