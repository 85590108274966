import React, {
  FC
} from 'react';
import {
  useNavigate, useParams
} from 'react-router-dom';
import {
  useTranslation
} from 'react-i18next';

import {
  ROUTE, showWarningFromServer
} from 'src/shared/utils';
import {
  useCalibrationsFinishedMutation
} from 'src/redux/openapi';

import {
  Button
} from '../Button';
import {
  ModalContainer
} from '../ModalContainer';
import {
  successfulToast
} from '../Toasts';
import {
  Spinner
} from '../Spinner';

import * as Style from './ModalCancelCalibration.style';

interface ModalCancelCalibrationProps {
  closeModal: () => void;
  isOpen: boolean;
}

export const ModalCancelCalibration: FC<ModalCancelCalibrationProps> = ({
  closeModal,
  isOpen,
}) => {
  const navigate = useNavigate();
  const params = useParams();

  const {
    calibrationId
  } = params;

  const [finishCalibration, {
    isLoading
  }] = useCalibrationsFinishedMutation();

  const {
    t
  } = useTranslation();

  const handleFinishClick = async () => {
    if (!calibrationId) {
      return;
    }

    try {
      const resp = await finishCalibration({
        id: calibrationId,
      }).unwrap();

      successfulToast(resp.message);
      closeModal();
      navigate(`/${ROUTE.CALIBRATION_TOOL}`);
    } catch (error) {
      showWarningFromServer(error);
    }
  };

  return (
    <ModalContainer
      modalWidth={498}
      isDarkBackground
      isHeader={false}
      closeModalHandler={() => {
        closeModal();
      }}
      isOpen={isOpen}
    >
      <Style.Title>{t('calibration.calibrationExit')}</Style.Title>

      <Style.Text>{t('calibration.calibrationExitHint')}</Style.Text>

      <Style.ButtonsContainer>
        <Button
          variant="big-black"
          onClick={closeModal}
          className="flex-1 font-bold text-xl"
        >
          {t('buttons.cancel')}
        </Button>

        <Button
          variant="big-red"
          onClick={handleFinishClick}
          className="flex-1 font-bold text-xl"
        >
          {t('buttons.finish')}

          {isLoading && (
            <Spinner
              color="white"
              size={24}
              centered={false}
            />
          )}
        </Button>
      </Style.ButtonsContainer>
    </ModalContainer>
  );
};
