export enum WeekDays {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
}

export const getDayName = (day: number) => {
  switch (day) {
    case 1:
      return WeekDays.Monday;
    case 2:
      return WeekDays.Tuesday;
    case 3:
      return WeekDays.Wednesday;
    case 4:
      return WeekDays.Thursday;
    case 5:
      return WeekDays.Friday;
    case 6:
      return WeekDays.Saturday;
    case 7:
    default:
      return WeekDays.Sunday;
  }
};
