import React, {
  FC
} from 'react';
import RCSlider from 'rc-slider';
import type {
  SliderProps as RCSliderProps
} from 'rc-slider';
import clsx from 'clsx';

import './styles.css';
import {
  themeColors
} from 'src/shared/utils';

type SliderProps = Pick<
RCSliderProps<number>,
'min' | 'max' | 'value' | 'onChange' | 'step' | 'disabled'
> & {
  variant: 'dot' | 'scrollbar';
  size?: 'big' | 'small' | 'label-small';
  isMulti?: boolean;
};

export const Slider: FC<SliderProps> = ({
  onChange,
  variant,
  size = 'big',
  isMulti,
  step = 1,
  ...props
}) => {
  const {
    disabled = false
  } = props;

  const sliderHandleSize = size === 'big' ? '32px' : '24px';
  const sliderHandleMargin = size === 'big' ? '-12px' : '-8px';

  return (
    <div
      className={clsx(
        variant,
        {
          active: !disabled,
          'flex px-10': variant === 'scrollbar',
          'label-slider-rail px-55px': size === 'label-small',
        }
      )}
    >
      <RCSlider
        railStyle={{
          background:
            size !== 'label-small'
              ? themeColors['gray-whisper']
              : 'transparent',
          borderRadius: size !== 'label-small' ? '0 1000px 1000px 0' : '30px',
          height: size !== 'label-small' ? '8px' : '4px',
        }}
        trackStyle={{
          background:
            size !== 'label-small' ? themeColors.gray7 : 'transparent',
          borderRadius: size !== 'label-small' ? '1000px 0 0 1000px' : '30px',
          height: size !== 'label-small' ? '8px' : '2px',
        }}
        handleStyle={{
          background:
            size !== 'label-small' ? themeColors.gray7 : themeColors.gray85,
          width: size !== 'label-small' ? sliderHandleSize : '4px',
          height: size !== 'label-small' ? sliderHandleSize : '120px',
          border: size !== 'label-small' ? '2px solid #fff' : 'none',
          borderRadius: size !== 'label-small' ? '100%' : '30px',
          marginTop: size !== 'label-small' ? sliderHandleMargin : '40px',
          transform:
            size !== 'label-small'
              ? 'rotate(0) translateX(-50%)'
              : `rotate(90deg) translate(${isMulti ? '-25px' : '0'}, -50%)`,
          transformOrigin: size !== 'label-small' ? 'center' : 'top right',
          opacity: size !== 'label-small' ? 1 : 0.5,
        }}
        onChange={(newValue) => {
          if (!onChange) {
            return;
          }

          if (Array.isArray(newValue)) {
            onChange(newValue[0]);

            return;
          }

          onChange(newValue);
        }}
        step={step}
        {...props}
      />
    </div>
  );
};
