import React from 'react';
import {
  createColumnHelper
} from '@tanstack/react-table';
import {
  t
} from 'i18next';

import {
  AdminOrganization,
  AdminOrganizationRequests,
} from 'src/redux/openapi';
import {
  formatDateRange, themeColors
} from 'src/shared/utils';
import {
  AdminOrgRequestsStatus,
  AdminUnaffiliatedGroup,
} from 'src/redux/openapi/endpoints/types';

import * as Style from './OrgManagementTable.styles';
import {
  EditMenu
} from './ui';
import {
  ActionHandler, Tabs
} from './types';

export const getTabsMap = () => [
  {
    id: Tabs.AllOrganizations,
    label: t('organisation.allOrganizations'),
  },
  {
    id: Tabs.GroupsRequests,
    label: t('organisation.groupsRequests'),
  },
  {
    id: Tabs.UnaffiliatedGroups,
    label: t('group.unaffiliatedGroups'),
  },
];

const orgColumnHelper = createColumnHelper<AdminOrganization>();
const requestsColumnHelper = createColumnHelper<AdminOrganizationRequests>();
const groupsColumnHelper = createColumnHelper<AdminUnaffiliatedGroup>();

const getStatusColor = (status: AdminOrgRequestsStatus) => {
  switch (status) {
    case 'PENDING':
      return themeColors['button-blue'];
    case 'REJECTED':
      return themeColors['red-text-error'];
    case 'ACCEPTED':
    default:
      return themeColors['forest-green'];
  }
};

export const getOrganizationCols = (
  onAction: ActionHandler,
  language: string,
) => {
  const columns = [
    orgColumnHelper.accessor(
      'name',
      {
        header: t('organisation.organisationName'),
        footer: (props) => props.column.id,
        size: 338,
        minSize: 128,
        cell: (info) => (
          <Style.NameCellSpan>{info.getValue()}</Style.NameCellSpan>
        ),
      }
    ),
    orgColumnHelper.accessor(
      'admins',
      {
        id: 'admin',
        header: t('common.admin'),
        footer: (props) => props.column.id,
        size: 338,
        minSize: 128,
        cell: (info) => {
          const adminsInfo = info.getValue();

          if (!adminsInfo) {
            return '';
          }

          const superAdmin = adminsInfo?.find(
            ({
              role
            }) => role === 'SUPERADMIN',
          );

          return superAdmin?.email || '';
        },
      }
    ),
    orgColumnHelper.accessor(
      'admins',
      {
        id: 'subAdmins',
        header: t('common.subAdmins'),
        footer: (props) => props.column.id,
        size: 338,
        minSize: 128,
        cell: (info) => {
          const adminsInfo = info.getValue();

          if (!adminsInfo) {
            return '';
          }

          const admins = adminsInfo
            ?.filter(({
              role
            }) => role === 'ADMIN')
            .map((admin) => admin.email)
            .join(', ');

          return admins;
        },
      }
    ),
    orgColumnHelper.accessor(
      'groupsCount',
      {
        header: () => (
          <Style.CenteredCellText>{t('common.groups')}</Style.CenteredCellText>
        ),
        footer: (props) => props.column.id,
        size: 72,
        cell: (info) => (
          <Style.RightAlignedCellText>
            {info.getValue()}
          </Style.RightAlignedCellText>
        ),
      }
    ),
    orgColumnHelper.accessor(
      'membersCount',
      {
        header: () => (
          <Style.CenteredCellText>
            {t('common.usersAmount')}
          </Style.CenteredCellText>
        ),
        footer: (props) => props.column.id,
        size: 108,
        minSize: 108,
        cell: (info) => (
          <Style.RightAlignedCellText>
            {info.getValue()}
          </Style.RightAlignedCellText>
        ),
      }
    ),
    orgColumnHelper.accessor(
      'createdAt',
      {
        header: t('filters.createDate'),
        footer: (props) => props.column.id,
        minSize: 132,
        cell: (info) => formatDateRange({
          start: info.getValue(),
          language,
        }),
      }
    ),
    orgColumnHelper.accessor(
      'id',
      {
        id: 'menu-column',
        header: '',
        footer: (props) => props.column.id,
        size: 48,
        cell: (info) => (
          <EditMenu
            type="organization"
            id={info.getValue()}
            onAction={onAction}
          />
        ),
      }
    ),
  ];

  return columns;
};

export const getGroupRequestsCols = (
  onAction: ActionHandler,
  language: string,
) => {
  const columns = [
    requestsColumnHelper.accessor(
      'group.name',
      {
        header: t('modal.groupName'),
        footer: (props) => props.column.id,
        size: 325,
        minSize: 128,
        cell: (info) => {
          return (
            <Style.NameCellSpan $isUnread={!info.row.original.isRead}>
              {info.getValue()}
            </Style.NameCellSpan>
          );
        },
      }
    ),
    requestsColumnHelper.accessor(
      'group.users',
      {
        id: 'admin',
        header: t('common.admin'),
        footer: (props) => props.column.id,
        size: 325,
        minSize: 128,
        cell: (info) => {
          const adminsInfo = info.getValue();

          if (!adminsInfo) {
            return '';
          }

          const superAdmin = adminsInfo?.find(
            ({
              role
            }) => role === 'SUPERADMIN',
          );

          return superAdmin?.user.email || '';
        },
      }
    ),
    requestsColumnHelper.accessor(
      'group.users',
      {
        id: 'subAdmins',
        header: t('common.subAdmins'),
        footer: (props) => props.column.id,
        size: 325,
        minSize: 128,
        cell: (info) => {
          const adminsInfo = info.getValue();

          if (!adminsInfo) {
            return '';
          }

          const admins = adminsInfo
            ?.filter(({
              role
            }) => role === 'ADMIN')
            .map((admin) => admin.user.email)
            .join(', ');

          return admins;
        },
      }
    ),
    requestsColumnHelper.accessor(
      'group._count.users',
      {
        header: () => (
          <Style.RightAlignedCellText>
            {t('common.usersAmount')}
          </Style.RightAlignedCellText>
        ),
        footer: (props) => props.column.id,
        size: 108,
        minSize: 108,
        cell: (info) => (
          <Style.RightAlignedCellText>
            {info.getValue()}
          </Style.RightAlignedCellText>
        ),
      }
    ),
    requestsColumnHelper.accessor(
      'createdAt',
      {
        header: () => (
          <Style.CenteredCellText>
            {t('filters.requestDate')}
          </Style.CenteredCellText>
        ),
        footer: (props) => props.column.id,
        minSize: 132,
        cell: (info) => (
          <Style.CenteredCellText>
            {formatDateRange({
              start: info.getValue(),
              language,
            })}
          </Style.CenteredCellText>
        ),
      }
    ),
    requestsColumnHelper.accessor(
      'status',
      {
        header: t('filters.requestProcessing'),
        footer: (props) => props.column.id,
        size: 140,
        cell: (info) => {
          const status = info.getValue();
          const color = getStatusColor(status);
          const statusKey = status.toLowerCase() as Lowercase<typeof status>;

          return (
            <Style.Status $color={color}>{t(`common.${statusKey}`)}</Style.Status>
          );
        },
      }
    ),
    requestsColumnHelper.accessor(
      'id',
      {
        id: 'menu-column',
        header: '',
        footer: (props) => props.column.id,
        size: 48,
        cell: (info) => (
          <EditMenu
            type="groupRequest"
            id={info.getValue()}
            onAction={onAction}
          />
        ),
      }
    ),
  ];

  return columns;
};

export const getGroupCols = (onAction: ActionHandler, language: string) => {
  const columns = [
    groupsColumnHelper.accessor(
      'name',
      {
        header: t('modal.groupName'),
        footer: (props) => props.column.id,
        size: 338,
        minSize: 128,
        cell: (info) => (
          <Style.NameCellSpan>{info.getValue()}</Style.NameCellSpan>
        ),
      }
    ),
    groupsColumnHelper.accessor(
      'admins',
      {
        id: 'admin',
        header: t('common.admin'),
        footer: (props) => props.column.id,
        size: 338,
        minSize: 128,
        cell: (info) => {
          const adminsInfo = info.getValue();

          if (!adminsInfo) {
            return '';
          }

          const superAdmin = adminsInfo?.find(
            ({
              role
            }) => role === 'SUPERADMIN',
          );

          return superAdmin?.email || '';
        },
      }
    ),
    groupsColumnHelper.accessor(
      'admins',
      {
        id: 'subAdmins',
        header: t('common.subAdmins'),
        footer: (props) => props.column.id,
        size: 338,
        minSize: 128,
        cell: (info) => {
          const adminsInfo = info.getValue();

          if (!adminsInfo) {
            return '';
          }

          const admins = adminsInfo
            ?.filter(({
              role
            }) => role === 'ADMIN')
            .map((admin) => admin.email)
            .join(', ');

          return admins;
        },
      }
    ),
    groupsColumnHelper.accessor(
      'memberCount',
      {
        header: () => (
          <Style.CenteredCellText>
            {t('common.usersAmount')}
          </Style.CenteredCellText>
        ),
        footer: (props) => props.column.id,
        size: 108,
        minSize: 108,
        cell: (info) => (
          <Style.RightAlignedCellText>
            {info.getValue()}
          </Style.RightAlignedCellText>
        ),
      }
    ),
    groupsColumnHelper.accessor(
      'createdAt',
      {
        header: t('filters.createDate'),
        footer: (props) => props.column.id,
        minSize: 132,
        cell: (info) => formatDateRange({
          start: info.getValue(),
          language,
        }),
      }
    ),
    groupsColumnHelper.accessor(
      'id',
      {
        id: 'menu-column',
        header: '',
        footer: (props) => props.column.id,
        size: 48,
        cell: (info) => (
          <EditMenu
            type="group"
            id={info.getValue()}
            onAction={onAction}
          />
        ),
      }
    ),
  ];

  return columns;
};
