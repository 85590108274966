import {
  useEffect, useMemo, useState
} from 'react';
import {
  useSearchParams
} from 'react-router-dom';
import dayjs from 'dayjs';
import {
  useTranslation
} from 'react-i18next';

import {
  followingId,
  getCurrentSortByValue,
  getFilteredTimeRangeComments,
} from 'src/shared/utils';
import {
  useTypedSelector
} from 'src/redux';
import {
  commentsFilterSelectors
} from 'src/redux/commentsFilters';
import {
  FilterDateType
} from 'src/widgets/CommentsFilterContext/types';
import {
  warningToast
} from 'src/shared/components';
import {
  useGetUserFollowingsCommentsQuery
} from 'src/redux/openapi';

const PAGE_LIMIT = 10;

export const useGetFavUserComments = () => {
  const [searchParams] = useSearchParams();

  const {
    t
  } = useTranslation();

  const [page, setPage] = useState(1);

  const followingUserId = searchParams.get(followingId) || '';

  const feedFilters = useTypedSelector(commentsFilterSelectors.selectAllFeed);

  const {
    showMyOnly, commentsSortBy, commentsTimeRange, selectedDateRange
  } = feedFilters;

  const filters = useMemo(
    () => {
      const isCustomTimeRange = commentsTimeRange === FilterDateType.CUSTOM && selectedDateRange;

      return {
        userOnly: showMyOnly || undefined,
        sortBy: getCurrentSortByValue(commentsSortBy),
        dateStart: isCustomTimeRange
          ? dayjs(selectedDateRange[0]).toDate().toJSON()
          : getFilteredTimeRangeComments(commentsTimeRange),
        dateEnd: isCustomTimeRange
          ? dayjs(selectedDateRange[selectedDateRange.length - 1])
            .toDate()
            .toJSON()
          : undefined,
      };
    },
    [feedFilters]
  );

  useEffect(
    () => {
      setPage(1);
    },
    [feedFilters, followingUserId]
  );

  const {
    data: commentsData,
    isLoading: isLoadingCommentsList,
    isFetching: isLoadingMoreComments,
    refetch: refetchComments,
  } = useGetUserFollowingsCommentsQuery(
    {
      followeeId: followingUserId,
      limit: PAGE_LIMIT * page,
      page: 1,
      endDate: filters.dateEnd,
      startDate: filters.dateStart,
    },
    {
      skip: !followingUserId,
      refetchOnMountOrArgChange: true,
    },
  );

  const loadNextPage = async () => {
    try {
      if (
        commentsData
        && commentsData.data.total > commentsData.data.comments.length
        && !isLoadingMoreComments
      ) {
        setPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
      warningToast(t('errors.errorLoadComments'));
    }
  };

  return {
    commentsList: commentsData?.data.comments || [],
    isLoading: isLoadingCommentsList,
    loadNextPage,
    isLoadingMoreComments,
    refetch: refetchComments,
    total: commentsData?.data.total || 0,
    hasMore:
      (commentsData?.data.total || 0)
      > (commentsData?.data.comments.length || 0),
  };
};
