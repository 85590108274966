import React, {
  FunctionComponent, forwardRef
} from 'react';

import type {
  ButtonProps
} from 'src/shared/components';

import * as Style from './ActivityButton.styles';

type IconType = FunctionComponent<React.SVGProps<SVGSVGElement>>;

interface ActivityButtonProps extends ButtonProps {
  icon: IconType;
  extendedIcons?: {
    icon: IconType;
    id: string;
  }[];
  title?: string | number;
  isActive?: boolean;
  withMenuOpened?: boolean;
}

export const ActivityButton = forwardRef<
HTMLButtonElement,
ActivityButtonProps
>(
  (
    {
      icon: Icon,
      extendedIcons,
      title,
      isActive = false,
      withMenuOpened = false,
      ...props
    },
    ref,
  ) => {
    return (
      <Style.ButtonContainer
        $isActive={isActive}
        $withMenuOpened={withMenuOpened}
        ref={ref}
        {...props}
      >
        <Style.IconsContainer>
          <Style.IconContainer
            $zIndex={extendedIcons ? extendedIcons.length + 1 : 0}
          >
            <Icon className="w-4 h-4" />
          </Style.IconContainer>

          {!!extendedIcons?.length && (
            <>
              {extendedIcons.map(({
                icon: ExtendedIcon, id
              }, index) => (
                <Style.IconContainer
                  $zIndex={extendedIcons.length - index}
                  $leftShift={9}
                  key={id}
                >
                  <ExtendedIcon className="w-4 h-4 text-dark-gray" />
                </Style.IconContainer>
              ))}
            </>
          )}
        </Style.IconsContainer>

        {title && <p>{title}</p>}
      </Style.ButtonContainer>
    );
  },
);
