import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const ButtonContainer = styled.button<{
  $backgroundColor: string;
  $textColor: string;
  $withBorder: boolean;
}>`
  ${tw`
    rounded-100px py-2.5 px-4 font-bold text-15
    flex gap-1 items-center justify-center self-end
  `}

  ${({
    $withBorder
  }) => $withBorder && tw`border border-gray94`};

  ${({
    $backgroundColor, $textColor
  }) => `
    background-color: ${$backgroundColor};
    color: ${$textColor};
  `};
`;

export const ButtonIcon = styled.div`
  ${tw`w-6 h-6 flex justify-center items-center`}
`;
