import React, {
  FC
} from 'react';
import {
  Coordinate
} from 'recharts/types/util/types';
import {
  useTranslation
} from 'react-i18next';

import {
  CheckIcon, TrashIcon
} from 'src/shared/icons';

import * as Style from './DraggableUserCard.style';

interface ActiveDraggedCardProps {
  cursorPosition: Coordinate;
  index: number;
  email: string;
  groups: string[];
  isSelected: boolean;
}

export const ActiveDraggedCard: FC<ActiveDraggedCardProps> = ({
  cursorPosition,
  index,
  email,
  groups,
  isSelected,
}) => {
  const {
    t
  } = useTranslation();

  return (
    <Style.DraggedCardContainer
      $top={cursorPosition.y}
      $left={cursorPosition.x}
    >
      <Style.SpaceForIcon />

      <Style.CardIndex>{`${index + 1}.`}</Style.CardIndex>

      <Style.InvitationData>
        <Style.InvitationEmail>{email}</Style.InvitationEmail>

        <Style.GroupsContainer>
          {!groups.length ? (
            <Style.InvitationGroup $isNotAssign>
              {t('organisation.notAssigned')}
            </Style.InvitationGroup>
          ) : (
            <>
              {groups.map((group) => (
                <Style.InvitationGroup key={group}>
                  {group}
                </Style.InvitationGroup>
              ))}
            </>
          )}
        </Style.GroupsContainer>
      </Style.InvitationData>

      {isSelected && <CheckIcon className="text-button-blue shrink-0" />}

      <TrashIcon className="text-negative80 shrink-0" />
    </Style.DraggedCardContainer>
  );
};
