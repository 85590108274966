import {
  createApi
} from '@reduxjs/toolkit/query/react';

import {
  GetApiGroupsApiArg,
  GetApiGroupsApiResponse,
  GetGroupQuestionSetsAllArg,
  GetGroupQuestionSetsAllResponse,
  GetGroupSetsAndQuestionAllArg,
  GetGroupSetsAndQuestionsAllResponse,
  GetGroupsUsersInviteAcceptArg,
  GetGroupsUsersInviteAcceptResponse,
} from 'src/redux/openapi';

import {
  baseQuery
} from '../baseQuery';
import {
  HTTPMethod, OnlyMessageResponse
} from '../config';

import {
  AddGroupMembersRequest
} from './types';

export const groupsApi = createApi({
  baseQuery,
  reducerPath: 'groupsApi',
  endpoints: (builder) => ({
    deleteGroup: builder.mutation<OnlyMessageResponse, string>({
      query: (id) => ({
        url: `/api/groups/${id}`,
        method: HTTPMethod.DELETE,
      }),
    }),
    addGroupMembers: builder.mutation<
    OnlyMessageResponse,
    AddGroupMembersRequest
    >({
      query: ({
        groupId, body
      }) => ({
        url: `/api/groups/${groupId}/users/invites/emails`,
        method: HTTPMethod.POST,
        body,
      }),
    }),
    getAllGroups: builder.mutation<GetApiGroupsApiResponse, GetApiGroupsApiArg>(
      {
        query: (queryArg) => ({
          url: `/api/groups/`,
          params: {
            page: queryArg.page,
            limit: queryArg.limit,
          },
        }),
      },
    ),
    getQuestionsByQuestionSetIdAll: builder.mutation<
    GetGroupSetsAndQuestionsAllResponse,
    GetGroupSetsAndQuestionAllArg
    >({
      query: (queryArg) => ({
        url: `/api/groups/${queryArg.id}/question-sets/${queryArg.questionSetId}/questions/all`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
          filters: queryArg.filters,
        },
      }),
    }),
    getQuestionSetsAll: builder.mutation<
    GetGroupQuestionSetsAllResponse,
    GetGroupQuestionSetsAllArg
    >({
      query: (queryArg) => ({
        url: `/api/groups/${queryArg.id}/question-sets/all`,
        params: {
          filters: queryArg.filters,
          page: queryArg.page,
          limit: queryArg.limit,
        },
      }),
    }),
    getGroupUserInviteAcceptSession: builder.mutation<
    GetGroupsUsersInviteAcceptResponse,
    GetGroupsUsersInviteAcceptArg
    >({
      query: () => ({
        url: `/api/groups/users/invite/accept/session`,
      }),
    }),
  }),
});

export const {
  useDeleteGroupMutation,
  useAddGroupMembersMutation,
  useGetAllGroupsMutation,
  useGetQuestionsByQuestionSetIdAllMutation,
  useGetQuestionSetsAllMutation,
  useGetGroupUserInviteAcceptSessionMutation,
} = groupsApi;
