export enum TimePeriodOptions {
  OneMonth,
  ThreeMonths,
  SixMonth,
  OneYear,
  ThreeYears,
}

export type TimePeriodSelectOption = {
  label: string;
  value: TimePeriodOptions;
};
