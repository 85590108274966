enum Links {
  ComingSoon = 'coming-soon',
}

export const faq = [
  {
    id: null,
    label: null,
    content: ``,
  },
  {
    id: Links.ComingSoon,
    label: 'FAQ',
    content: `Coming soon...`,
  },
];
