import {
  Area
} from 'react-easy-crop';

const createImage = (url: string): Promise<HTMLImageElement> => new Promise((resolve, reject) => {
  const image = new Image();

  image.addEventListener(
    'load',
    () => resolve(image)
  );

  image.addEventListener(
    'error',
    (error) => reject(error)
  );

  image.src = url;
});

export const getCroppedImg = async (imageSrc: string, pixelCrop: Area) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('Canvas context is not supported.');
  }

  ctx.fillStyle = '#ffffff';

  ctx.fillRect(
    0,
    0,
    canvas.width,
    canvas.height
  );

  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height,
  );

  return new Promise((resolve) => {
    canvas.toBlob(
      (file) => {
        if (!file) {
          throw new Error('Failed to create blob for cropped image.');
        }

        resolve(URL.createObjectURL(file));
      },
      'image/jpeg'
    );
  });
};
