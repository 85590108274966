import tw from 'twin.macro';
import {
  styled
} from 'styled-components';

export const HeadContainer = styled.div`
  ${tw`flex justify-between items-center mb-4`}
`;

export const HeadButtonsContainer = styled.div`
  ${tw`flex gap-2`}
`;

export const ContentContainer = styled.div`
  ${tw`flex gap-6 mb-6`}
`;

export const HeadTitle = styled.div`
  ${tw`text-28-36 font-bold text-gray7`}
`;

export const VideoContainer = styled.div`
  ${tw`w-909px h-536px rounded-lg`}
`;

export const VideoContent = styled.div`
  ${tw`relative pb-59.4% h-0 rounded-lg overflow-hidden`}
`;

export const VideoWindow = styled.iframe`
  ${tw`absolute top-0 left-0 w-full h-full`}
`;

export const SpinnerContainer = styled.div`
  ${tw`absolute top-0 left-0 w-full h-full z-50`}
`;

export const SideInfoContainer = styled.div`
  ${tw`w-443px h-536px bg-white rounded-md shadow-card-shadow-default`}
`;

export const Subtitle = styled.div`
  ${tw`text-22-30 font-bold text-gray7 px-4 py-4`}
`;

export const ListContainer = styled.div`
  ${tw`h-474px overflow-y-auto px-4`}
`;
